import { AttachFile } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import React, { useState, useEffect, useRef } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import { addPolicy, getPolicies } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";

export default function CreatePolicy({ handelCloseAddPolicies, refreshData }) {
  const userDetails = jwtDecode(localStorage.getItem("token"));

  const [policyData, setPolicyData] = useState([]);

  const [serailNumber, setSerialNumber] = useState("");

  useEffect(() => {
    getPolicies(userDetails.orgId)
      .then((response) => {
        console.log("getPolicies", response.data);
        setPolicyData(response.data);
        console.log("getPolicies", response.data.length);
        setSerialNumber(response.data.length + 1);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
      });
  }, [userDetails.orgId]);

  const [loading, setLoading] = useState(false);

  // const [serailNumber, setSerialNumber] = useState("");

  const handleSerialNumber = (event) => {
    const input = event.target.value;
    const numbersOnly = input.replace(/\D/g, "");
    setSerialNumber(numbersOnly);
  };

  const [policyName, setPolicyName] = useState("");

  const handlePolicyName = (event) => {
    // console.log("handlePolicyName", event.target.value);
    setPolicyName(event.target.value);
  };

  // const [isOnboardingCheck, setIsOnboardingCheck] = useState(false);

  // const handleIsOnboardingCheck = (event) => {
  //   console.log('handleIsOnboardingCheck', event.target.value);
  //   setIsOnboardingCheck(event.target.value === "Y" ? true : false);
  // };

  const [selectedValue, setSelectedValue] = useState("Y");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    console.log(event.target.value);
  };

  const inputRefLogo = useRef();
  const [fileData_Base64, setFileData_Base64] = useState("");
  const [fileName, setFileName] = useState("");

  const getStringFromFile = async (e) => {
    console.log(e);
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    // console.log("handleUploadOrgLogo", file);
    setFileData_Base64(await convertFileToBase64_Split(file));
    setFileName(file.name);
  };

  const handleDeleteFile = () => {
    setFileName("");
    setFileData_Base64("");
  };

  const finalSubmit = () => {
    setLoading(true);
    console.log("finalSubmit");

    if (serailNumber === "") {
      setSerialNumber("0");
    }

    if (policyName.trim() === "") {
      toast.error("Please Enter policy Name", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (fileName.trim() === "" && fileData_Base64.trim() === "") {
      toast.error("Please Upload a Pdf file", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (fileName !== "") {
      const extension = fileName.split(".").pop();
      if (extension !== "pdf") {
        toast.error("Please Provide only pdf file", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }
    }

    const reqDto = {
      policy: policyName,
      onboardChecklist: selectedValue,
      fileData: fileData_Base64,
      fileName: fileName,
      slNo: serailNumber,
    };

    console.log("reqDto", reqDto);
    // return ;
    addPolicy(userDetails.userId, userDetails.orgId, reqDto).then(
      (response) => {
        console.log("addPolicy", response.data);
        setLoading(false);
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          refreshData();
          setPolicyName("");
          setSelectedValue("Y");
          setFileData_Base64("");
          setFileName("");
          setSerialNumber("");
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">Add Policy</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseAddPolicies()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            {/* <div className="elementWithBtn">
            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="PreDefineDepartment">
                  Pre Define Department
                </InputLabel>
                <Select
                  className="formInputField"
                  labelId="PreDefineDepartment"
                  id="PreDefineDepartment-checkbox"
                  multiple
                  value={personName}
                  onChange={handleChangeMultiselect}
                  input={<OutlinedInput label="Pre Define Department" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={personName.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button startIcon={<Add />} className="dfultPrimaryBtn ">
              Add
            </Button>
          </div>

          <div className="orDiv">
            <span>OR</span>
          </div> */}

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  label="Sl No"
                  variant="outlined"
                  value={serailNumber}
                  // value={index + 1}
                  className="formTextFieldArea"
                  // onChange={handleSerialNumber}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  label="Enter Policy Name"
                  variant="outlined"
                  value={policyName}
                  className="formTextFieldArea"
                  onChange={handlePolicyName}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <div className="textWithOption">
                <div className="fromText">Is needed while onboarding ?</div>
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup"
                    labelId="setTasktype"
                    name="mcRules"
                    value={selectedValue}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      className="formRadioField"
                      value="Y"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="N"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="formElement">
              <div className="formBtnElement">
                <Button
                  variant="outlined"
                  startIcon={<AttachFile />}
                  className="comBtnOutline"
                  onClick={() => inputRefLogo.current.click()}
                >
                  Upload File
                </Button>
                <input
                  ref={inputRefLogo}
                  type="file"
                  multiple
                  style={{ display: "none" }}
                  onChange={(e) => {
                    getStringFromFile(e);
                    inputRefLogo.current.value = null;
                  }}
                  accept="application/pdf"
                />
              </div>
            </div>

            {fileName && (
              <div class="elementListGrp">
                <div class="elementHeding">Policies Added</div>
                <ul class="elmListInfo">
                  <li>
                    <span className="rolsNm">{fileName}</span>
                    <IconButton className="rmvBtn" onClick={handleDeleteFile}>
                      <Trash />
                    </IconButton>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn" onClick={()=>finalSubmit()}>Save</Button> */}
            <Button
              className="dfultPrimaryBtn"
              onClick={() => finalSubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>Submit</>
              )}
            </Button>
            {/* <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handelCloseCrtDeprtView()}
            >
              Cancel
            </Button> */}
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseAddPolicies()}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
