import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";

function DaTaFormPreview({ DATA_data }) {
  console.log("data data=", DATA_data);

  const totalExpenditure = useMemo(() => {
    const trCost =
      DATA_data.transportCost === "" ? 0 : parseInt(DATA_data.transportCost);
    const accCost =
      DATA_data.accomodationCost === ""
        ? 0
        : parseInt(DATA_data.accomodationCost);
    const meCost = DATA_data.mealCost === "" ? 0 : parseInt(DATA_data.mealCost);
    const othrExpense =
      DATA_data.otherExpenses === "" ? 0 : parseInt(DATA_data.otherExpenses);

    return trCost + accCost + meCost + othrExpense;
  }, [DATA_data]);

  return (
    <>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">Budget Head</div>
        <div class="taskContractNm">{DATA_data.budgetHead.head}</div>
      </div>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">Transport Cost</div>
        <div class="taskContractNm">{DATA_data.transportCost}</div>
      </div>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">Accomodation Cost</div>
        <div class="taskContractNm">{DATA_data.accomodationCost}</div>
      </div>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">Meal Cost</div>
        <div class="taskContractNm">{DATA_data.mealCost}</div>
      </div>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">Other Expenses</div>
        <div class="taskContractNm">{DATA_data.otherExpenses}</div>
      </div>

      <div class="taskElementGrpRow">
        <div class="tskElemHeding">Total Expenses</div>
        <div class="taskContractNm">{DATA_data.totalExpenditure}</div>
      </div>

      <div class="taskElementGrpRow">
        <div class="tskElemHeding">Advance Received</div>
        <div class="taskContractNm">{parseInt(DATA_data.advanceReceived)}</div>
      </div>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">Claimed On Actual Basis</div>
        <div class="taskContractNm">
          {DATA_data.claimOnActalBasis ? "Yes" : "No"}
        </div>
      </div>
      <div class="taskElementGrpRow">
        <div class="tskElemHeding">Claimed On Actual Rate</div>
        <div class="taskContractNm">
          {DATA_data.claimOnActalRate ? "Yes" : "No"}
        </div>
      </div>
    </>
  );
}

export default DaTaFormPreview;
