import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useState } from "react";

export default function SetEPF() {
  const [deductionCycle, setDeductionCycle] = useState("0");
  const [employeeContribution, setEmployeeContribution] = useState("0");
  const [employerContribution, setEmployerContribution] = useState("1");
  const [checked, setChecked] = useState([true, true]);
  const [contributionType, setContributionType] =
    useState("ctEmployeeEmployer");

  const handleContributionType = (event) => {
    setContributionType(event.target.value);
  };

  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event) => {
    setChecked([event.target.checked, checked[1]]);
  };

  const handleChange3 = (event) => {
    setChecked([checked[0], event.target.checked]);
  };

  const children = (
    <div className="checkboxWithlist">
      <FormControlLabel
        className="formRadioField"
        label="Include employer's EDLI contribution in the CTC"
        control={<Checkbox checked={checked[0]} onChange={handleChange2} />}
      />
      <FormControlLabel
        className="formRadioField"
        label="Include admin charges in the CTC"
        control={<Checkbox checked={checked[1]} onChange={handleChange3} />}
      />
    </div>
  );

  const selectdeductionCycle = (event) => {
    setDeductionCycle(event.target.value);
  };

  const selectemployeeContribution = (event) => {
    setEmployeeContribution(event.target.value);
  };

  const selectemployerContribution = (event) => {
    setEmployerContribution(event.target.value);
  };
  return (
    <>
      <div className="payrollContainArea">
        <div className="editPayrollElement">
          <div className="elementFormContainer">
            <div className="tskElemHeding">EPF Details</div>
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    required
                    label="EPF Number"
                    variant="outlined"
                    className="formTextFieldArea"
                    value="MT/KOL/1245845/655"
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="selectdeductionCycle">
                    Deduction Cycle
                  </InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    labelId="selectdeductionCycle"
                    id="deductionCycle-select"
                    value={deductionCycle}
                    label="Deduction Cycle"
                    onChange={selectdeductionCycle}
                  >
                    <MenuItem value={"0"}> Monthly </MenuItem>
                    <MenuItem value={"1"}> Quarterly </MenuItem>
                    <MenuItem value={"2"}> Half-yearly </MenuItem>
                    <MenuItem value={"3"}> Annually </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="tskElemHeding">Employee Contribution</div>
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="selectemployeeContribution">
                  Select Employee Contribution Rate
                </InputLabel>
                <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="selectemployeeContribution"
                  id="employeeContribution-select"
                  value={employeeContribution}
                  label="Select Employee Contribution Rate"
                  onChange={selectemployeeContribution}
                >
                  <MenuItem value={"0"}> 12% of Actual PF Wage </MenuItem>
                  <MenuItem value={"1"}>
                    Restrict Contribution to ₹15000 of PF Wage
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="tskElemHeding">Employer Contribution</div>
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="selectemployerContribution">
                  Select Employer Contribution Rate
                </InputLabel>
                <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="selectemployerContribution"
                  id="employerContribution-select"
                  value={employerContribution}
                  label="Select Employer Contribution Rate"
                  onChange={selectemployerContribution}
                >
                  <MenuItem value={"0"}> 12% of Actual PF Wage </MenuItem>
                  <MenuItem value={"1"}>
                    Restrict Contribution to ₹15000 of PF Wage
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="empContListCheck">
              <div className="formElement">
                <FormControl className="formControl">
                  <FormGroup className="formRadioGroup">
                    <FormControlLabel
                      className="formRadioField"
                      label="Include employer's contributionin the CTC"
                      control={
                        <Checkbox
                          checked={checked[0] && checked[1]}
                          indeterminate={checked[0] !== checked[1]}
                          onChange={handleChange1}
                        />
                      }
                    />
                    {children}
                  </FormGroup>
                </FormControl>
              </div>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <FormGroup className="formRadioGroup">
                  <FormControlLabel
                    className="formRadioField"
                    control={<Checkbox />}
                    label="Override PF contribution rate at employee level"
                  />
                </FormGroup>
              </FormControl>
            </div>

            <div className="tskElemHeding">
              PF Configuration when LOP Applied
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <FormGroup className="formRadioGroup">
                  <FormControlLabel
                    className="formRadioField"
                    control={<Checkbox checked />}
                    label="Pro-rate Restricted PF Wage"
                  />
                </FormGroup>
              </FormControl>
              <div className="textOnlyCheck">
                PF contribution will be pro-rated based on the number of days
                worked by the employee.
              </div>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <FormGroup className="formRadioGroup">
                  <FormControlLabel
                    className="formRadioField"
                    control={<Checkbox checked />}
                    label="Consider all applicable salary components if PF wage is less than ₹15,000 after Loss of Pay."
                  />
                </FormGroup>
              </FormControl>
              <div className="textOnlyCheck">
                PF wage will be computed using the salary earned in that
                particular month (based on LOP) rather than the actual amount
                mentioned in the salary structure.
              </div>
            </div>

            <div className="tskElemHeding">ABRY eligibility</div>
            <div className="formElement bbsolid">
              <FormControl className="formControl">
                <FormGroup className="formRadioGroup">
                  <FormControlLabel
                    className="formRadioField"
                    control={<Checkbox checked />}
                    label="Eligible for ABRY Scheme"
                  />
                </FormGroup>
              </FormControl>
              <div className="textOnlyCheck">
                The EPF contribution of both the employee and employer (with a
                few exceptions) will be paid by the Government for eligible
                employeed who receive up to ₹15,000 im monthly wages.
              </div>
              <div className="formElement ml48">
                <FormControl className="formControl inlineRowElmnt">
                  <FormLabel id="Contribution_Type" className="custFrmlabel">
                    Contribution Type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="Contribution_Type"
                    name="Contribution_Type"
                    className="formRadioGroup"
                    value={contributionType}
                    onChange={handleContributionType}
                  >
                    <FormControlLabel
                      className="formRadioField"
                      value="ctEmployee"
                      control={<Radio />}
                      label="Only Employee"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="ctEmployeeEmployer"
                      control={<Radio />}
                      label="Both Employee and Employer"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="formBtnElement">
              <Button className="dfultPrimaryBtn ">Save</Button>
              <Button className="dfultDarkSecondaryBtn">Cancel</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
