import {
  Add,
  AssignmentTurnedInRounded,
  Biotech,
  ExpandMoreRounded,
  MonitorHeartRounded,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

export default function PossibleDiagnosis({ updateData, data }) {
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [diseasesStatus, setDiseasesStatus] = useState("");
  const [selectedDisease, setSelectedDisease] = useState(null);
  const [diseaseInputString, setDiseaseInputString] = useState();
  const [diseaseList, setDiseaseList] = useState([]);
  const [userDiseaseList, setUserDiseaseList] = useState([]);

  const handleChangeDiseasesStatus = (event) => {
    setDiseasesStatus(event.target.value);
  };

  const initializeForm = () => {
    setDiseasesStatus("");
    setSelectedDisease(null);
    setDiseaseInputString("");
  };

  const suggestedDiseaseList = useMemo(() => {
    const filteredList = getFilteredList(
      diseaseList,
      diseaseInputString,
      "diseaseName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [diseaseInputString, diseaseList]);

  const diseasesString = useMemo(() => {
    const str =
      userDiseaseList.length > 0
        ? userDiseaseList.map((item) => `${item.diseaseName} `).join(", ")
        : "";
    return str;
  }, [userDiseaseList]);

  useEffect(() => {
    getDiseases();
  }, []);

  useEffect(() => {
    if (data.possibleDiagonesis) {
      setUserDiseaseList(data.possibleDiagonesis);
    }
  }, [data]);

  const getDiseases = () => {
    const diseasetring = localStorage.getItem("diseaseList");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);
        localStorage.setItem("diseaseList", JSON.stringify(response.data));
      }
    });
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            Possible Conditions:
            {!accordionExpanded && <span>{diseasesString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="ddBtnGroup flxSPBTW">
            <Tooltip
              arrow
              title="These features are experimental. It would be best if you reviewed and recommended any necessary changes. The data has been generated using AI. "
            >
              <Button
                className="differnDiagnosBtn"
                startIcon={<MonitorHeartRounded />}
              >
                Run Differential Diagnosis
              </Button>
            </Tooltip>
            <div className="reviewBtnGrp">
              <Button
                className="dfultDarkSecondaryBtn"
                startIcon={<AssignmentTurnedInRounded />}
              >
                Protocol
              </Button>
              {/* <Button className="dfultDarkSecondaryBtn" startIcon={<Biotech />}>
                Review Test
              </Button> */}
            </div>
          </div>
          <div className="bb"></div>
          <div className="complaintList">
            <ul>
              {userDiseaseList &&
                userDiseaseList.map((disease, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp">
                        <div className="complstDtl  ">
                          <div className="compntName">
                            {`${disease.diseaseName} [ ${
                              disease.snowmedCt
                                ? `SNOWMED ${disease.snowmedCt} ,`
                                : ""
                            } ICD ${disease.icd11code} ]`}
                          </div>
                          {/* <div className="compinfoGRP">
                            <div className="compStime">
                              <span>Status:</span>
                              <span className="data">{disease.status}</span>
                            </div>
                          </div> */}
                        </div>
                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...userDiseaseList];
                            temp.splice(index, 1);
                            updateData({ possibleDiagonesis: temp });
                            setUserDiseaseList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </li>
                  );
                })}

              {/* <li>
                <div className="complstDtlGrp ">
                  <div className="complstDtl  ">
                    <div className="compntName">
                      Aase Syndrome [ SNOWMED CT-71988008, ICD 10 CD- Q87.2 ]
                    </div>
                    <div className="compinfoGRP">
                      <div className="compStime">
                        <span>Status:</span>
                        <span className="data">Confirm</span>
                      </div>
                    </div>
                  </div>
                  <IconButton className="removeItemBtn">
                    <Trash />
                  </IconButton>
                </div>
              </li> */}
              {/* add new symptoms */}
              <li>
                <div className="addNewformGrp">
                  <div className="formElement">
                    <FormControl className="formControl">
                      {/* <TextField
                        // hiddenLabel
                        label="Diseases Name"
                        required
                        autoComplete="off"
                        placeholder="Search Diseases Name"
                        variant="outlined"
                        className="formTextFieldArea"
                      /> */}

                      <Autocomplete
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedDisease}
                        options={suggestedDiseaseList}
                        inputValue={diseaseInputString}
                        onChange={(e, newValue) => {
                          setSelectedDisease(newValue);
                        }}
                        onInputChange={(e, value, reason) => {
                          // if (e && e.target) {
                          //   setDiseaseInputString(e.target.value);
                          // }
                          setDiseaseInputString(value);

                          // else {
                          //   setSymptomSearchString("");
                          // }
                        }}
                        getOptionLabel={(option) => option.diseaseName}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {item.diseaseName}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder="Search Diseases Name"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  {/* <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel id="since-select-label">Status</InputLabel>
                      <Select
                        labelId="since-select-label"
                        id="since-select"
                        value={diseasesStatus}
                        label="Status"
                        onChange={handleChangeDiseasesStatus}
                        className="formInputField"
                        variant="outlined"
                      >
                        <MenuItem value="Provisional">Provisional </MenuItem>
                        <MenuItem value="Confirm">Confirm</MenuItem>
                        <MenuItem value="Prognosis">Prognosis</MenuItem>
                      </Select>
                    </FormControl>
                  </div> */}
                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    onClick={() => {
                      if (!diseaseInputString || diseaseInputString === "") {
                        toast.error("Please Provide Disease Name");
                        return;
                      }
                      let tempAllergicFood = selectedDisease;
                      if (!tempAllergicFood) {
                        tempAllergicFood = {
                          id: uuidv4(),
                          diseaseName: diseaseInputString,
                          icd11code: "",
                          snowmedCt: "",
                          locallyAdded: true,
                          showInDiagonesis: true,
                          status: "Provisional",
                        };
                      } else {
                        tempAllergicFood = {
                          ...tempAllergicFood,
                          showInDiagonesis: true,
                          status: "Provisional",
                        };
                      }
                      setUserDiseaseList((prev) => {
                        const temp = [...prev, tempAllergicFood];
                        updateData({ possibleDiagonesis: temp });
                        return temp;
                      });
                      initializeForm();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
