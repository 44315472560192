import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { NumericFormatCustom } from "./NumericFormatCustom";

export const AdvanceIncomingForm = ({ state, dispatch, budgetHeadList }) => {
  return (
    <>
      <div className="formElementGroup">
        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Advance"
              name="numberformat"
              id="Transport_Cost"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              variant="outlined"
              defaultValue={0}
              className="formTextFieldArea"
              value={state.formDataCopy.advanceAmount}
              onChange={(e) => {
                let temp = { ...state.formDataCopy };
                temp.advanceAmount = e.target.value;
                dispatch({ type: "advanceFormUpdate", payload: temp });
              }}
            />
          </FormControl>
        </div>
        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Expected Cost"
              name="numberformat"
              id="Transport_Cost"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              variant="outlined"
              defaultValue={0}
              className="formTextFieldArea"
              value={state.formDataCopy.advanceEstimatedCost}
            />
          </FormControl>
        </div>
        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="selectLedgerHead">Budget Head*</InputLabel>

            <Select
              className="formInputField"
              variant="outlined"
              labelId="selectLedgerHead"
              id="select-LedgerHead"
              label="Budget Head"
              value={state.formDataCopy.budgetHeadId}
            >
              {budgetHeadList.length > 0 &&
                budgetHeadList.map((itemName, index) => (
                  <MenuItem
                    value={itemName.id}
                    key={itemName.head + "_" + index}
                  >
                    {itemName.head}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
};
