import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

export const StatutoryPreview = ({ statutoryList }) => {
  useEffect(() => {
    console.log("statutoryList =", statutoryList);
  }, []);

  return (
    <div className="indentFormtable">
      <div className="indtableResponsive">
        <table>
          <thead>
            <tr>
              {/* <th className="indntQunt">Category</th> */}
              <th className="indntQunt">Type</th>
              <th className="indntQunt">Due Date</th>
              <th className="indntQunt">Amount</th>
              <th className="indntQunt">Description</th>
              <th className="indntQunt">Document</th>
            </tr>
          </thead>
          <tbody>
            {statutoryList &&
              statutoryList.map((item) => {
                return (
                  <tr>
                    <td>
                      <div className="textOnly">{item.type.name}</div>
                    </td>
                    {/* <td>
                      <div className="textOnly">{item.subCategory}</div>
                    </td> */}
                    <td>
                      <div className="textOnly">
                        {dayjs(item.dueDate).format("DD-MM-YYYY")}
                      </div>
                    </td>

                    <td>
                      <div className="textOnly">{item.amount}</div>
                    </td>

                    <td>
                      <div className="textOnly">{item.description}</div>
                    </td>
                    <td>
                      <div className="textOnly">
                        {item.document && item.document.fileName}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
