import { DoNotDisturb, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";

export default function SetPT() {
  return (
    <>
      <div className="payrollContainArea">
        <div className="editPayrollElement">
          <div className="elementFormContainer">
            <div className="tskElemHeding">EPF Details</div>
            <div className="OnlyText">
              This tax is levied on an employee's income by the State
              Government. tax slabs differ in each state.
            </div>
            <div className="PTContainerGrp">
              <div className="PTElementInd">
                <div className="PTHeader">
                  <div className="PTTitle">Head Office</div>
                  <div className="PTEdit">
                    <IconButton>
                      <Edit />
                    </IconButton>
                  </div>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">PT Number</span>
                  <span className="PTEItmSC">-</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">State</span>
                  <span className="PTEItmSC">West Bengal</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">Deduction Cycle</span>
                  <span className="PTEItmSC">Half Yearly</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC"> PT Slabs</span>
                  <span className="PTEItmSC viewSlab">View Tax Slabs</span>
                </div>
              </div>
              <div className="PTElementInd">
                <div className="PTHeader">
                  <div className="PTTitle">Hyderabad</div>
                  <div className="PTEdit">
                    <IconButton>
                      <Edit />
                    </IconButton>
                  </div>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">PT Number</span>
                  <span className="PTEItmSC">-</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">State</span>
                  <span className="PTEItmSC">Telangana</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC">Deduction Cycle</span>
                  <span className="PTEItmSC">Monthly</span>
                </div>
                <div className="PTEItem">
                  <span className="PTEItmFC"> PT Slabs</span>
                  <span className="PTEItmSC viewSlab">View Tax Slabs</span>
                </div>
              </div>
              <div className="PTElementInd">
                <div className="PTHeader">
                  <div className="PTTitle">Delhi</div>
                </div>
                <div className="PTEItemWithNoPT">
                  <span className="PTEItmFCNoPT">
                    <DoNotDisturb />
                  </span>
                  <span className="PTEItmSCNoPT">
                    Professional Tax is not applicable for your work location in
                    Delhi
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
