import {
  Add,
  ExpandMoreRounded,
  MonitorHeartRounded,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { getFilteredList, getHealthDto } from "./consultationUtil";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export default function Comorbidity({ updateData, data }) {
  const [since, setSince] = useState("");
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [selectedDisease, setSelectedDisease] = useState(null);
  const [diseaseInputString, setDiseaseInputString] = useState("");
  const [diseaseList, setDiseaseList] = useState([]);
  const [userDiseaseList, setUserDiseaseList] = useState([]);
  const [diseaseSince, setDiseaseSicne] = useState(null);

  const initializeForm = () => {
    setSelectedDisease(null);
    setDiseaseInputString("");
    setSince("");
  };

  const suggestedDiseaseList = useMemo(() => {
    const filteredList = getFilteredList(
      diseaseList,
      diseaseInputString,
      "diseaseName"
    );
    return filteredList.slice(0, 10);
  }, [diseaseInputString, diseaseList]);

  const diseaseString = useMemo(() => {
    const str =
      userDiseaseList.length > 0
        ? userDiseaseList
            .map((item) => `${item.disease.diseaseName} (${item.since})`)
            .join(", ")
        : "";
    return str;
  }, [userDiseaseList]);

  useEffect(() => {
    if (data.comorbidity) {
      setUserDiseaseList(data.comorbidity);
    }

    getSymptomSince();
    getDiseases();
  }, []);

  const getSymptomSince = () => {
    const symptomString = localStorage.getItem("symptomSince");
    if (symptomString) {
      setDiseaseSicne(JSON.parse(symptomString));
      return;
    }
    const reqDto = getHealthDto();
    DashboardServices.getSymptomSince(reqDto).then((response) => {
      if (response.data) {
        setDiseaseSicne(response.data);
        localStorage.setItem("symptomSince", JSON.stringify(response.data));
      }
    });
  };

  const getDiseases = () => {
    const diseasetring = localStorage.getItem("diseaseList");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);
        localStorage.setItem("diseaseList", JSON.stringify(response.data));
      }
    });
  };

  const handleChangeSince = (event) => {
    setSince(event.target.value);
  };
  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            Comorbidity:
            {!accordionExpanded && <span>{diseaseString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="complaintList">
            <ul>
              {userDiseaseList &&
                userDiseaseList.map((item, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp">
                        <div className="complstDtl  ">
                          <div className="compntName">
                            {item.disease.diseaseName}
                          </div>
                          <div className="compinfoGRP">
                            <div className="compStime">
                              <span>Since:</span>
                              <span className="data">{item.since}</span>
                            </div>
                          </div>
                        </div>
                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...userDiseaseList];
                            temp.splice(index, 1);
                            updateData({ comorbidity: temp });
                            setUserDiseaseList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </li>
                  );
                })}
              {/* <li>
                <div className="complstDtlGrp ">
                  <div className="complstDtl  ">
                    <div className="compntName">Abdominal Colic / Cramps.</div>
                    <div className="compinfoGRP">
                      <div className="compStime">
                        <span>Since:</span>
                        <span className="data">4 Days</span>
                      </div>
                    </div>
                  </div>
                  <IconButton className="removeItemBtn">
                    <Trash />
                  </IconButton>
                </div>
              </li> */}
              {/* add new symptoms */}
              <li>
                <div className="addNewformGrp">
                  <div className="formElement">
                    <FormControl className="formControl">
                      {/* <TextField
                        // hiddenLabel
                        label="Diseases "
                        required
                        autoComplete="off"
                        placeholder="Enter Diseases"
                        variant="outlined"
                        className="formTextFieldArea"
                      /> */}

                      <Autocomplete
                        freeSolo
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedDisease}
                        options={suggestedDiseaseList}
                        inputValue={diseaseInputString}
                        onChange={(e, newValue) => {
                          setSelectedDisease(newValue);
                        }}
                        onInputChange={(e, value, reason) => {
                          // if (e && reason === "input") {
                          //   setDiseaseInputString(e.target.value);
                          // } else if (reason === "reset") {
                          //   setDiseaseInputString(value);
                          // } else if (reason === "clear") {
                          //   setDiseaseInputString(value);
                          // }
                          setDiseaseInputString(value);

                          // else {
                          //   setAllergicFoodSearchString("");
                          // }
                        }}
                        getOptionLabel={(option) => option.diseaseName}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {item.diseaseName}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder="Enter Diseases*"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel id="since-select-label">Since*</InputLabel>
                      <Select
                        labelId="since-select-label"
                        id="since-select"
                        value={since}
                        label="Since"
                        onChange={handleChangeSince}
                        className="formInputField"
                        variant="outlined"
                      >
                        {diseaseSince &&
                          diseaseSince.map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                      </Select>
                    </FormControl>
                  </div>

                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    onClick={() => {
                      if (!diseaseInputString || diseaseInputString === "") {
                        toast.error("Please Provide Disease Name");
                        return;
                      }
                      if (since === "") {
                        toast.error("Please Select Since");
                        return;
                      }
                      let tempSelectedDisease = selectedDisease;
                      if (!tempSelectedDisease) {
                        tempSelectedDisease = {
                          id: uuidv4(),
                          diseaseName: diseaseInputString,
                          locallyAdded: true,
                        };
                      }
                      setUserDiseaseList((prev) => {
                        const temp = [
                          ...prev,
                          {
                            disease: tempSelectedDisease,
                            since,
                          },
                        ];

                        updateData({ comorbidity: temp });
                        initializeForm();
                        return temp;
                      });
                    }}
                  >
                    Add
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
