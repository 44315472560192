import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import "../css/FaceCaptureComponent.css";
// import { publicUrl } from "../../http-common";
// import MeetingService from "../../services/MeetingService";
import { toast } from "react-toastify";
// import LinearWithValueLabel from "./LinearProgressWithLabel";
import { FaceLivenessAwsAmplify } from "./FaceLiveness";
// import { /Xlg> } from "iconoir-react";
import { publicUrl } from "../../../constants/url_provider";
import DashboardServices from "../../../services/DashboardServices";
import LinearWithValueLabel from "../../../utils/LinearProgressWithLabel";
import { XLg } from "react-bootstrap-icons";
import { getLoginUserDetails } from "../../../utils/Utils";
import { ConfirmProvider, useConfirm } from "material-ui-confirm";

const FaceCaptureComponent = ({
  open,
  onClose,
  faceId,
  setFaceId,
  closeDialog,
  setShowRegFace,
  // loginUserDetails,
}) => {
  const loginUserDetails = getLoginUserDetails();
  const [currentImage, setCurrentImage] = useState(null);
  const [instructionIndex, setInstructionIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [progMessage, setProgMessage] = useState("");
  const [progColor, setProgColor] = useState("white");
  const [livenessPassed, setLivenessPassed] = useState(false);

  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const isCameraOpen = useRef(false);
  const registerButtonRef = useRef(false);
  const deleteFaceRef = useRef(false);
  const instructionImages = [
    `${publicUrl}/FaceRecog.png`,
    "path/to/instruction2.jpg",
    "path/to/instruction3.jpg",
    "path/to/instruction4.jpg",
  ];

  useEffect(() => {
    if (faceId == null) {
      // if (open) {
      //   openCamera();
      // } else {
      //   closeCamera();
      // }
    } else {
    }
    return () => {
      closeCamera();
    };
  }, [open]);

  const openCamera = async () => {
    try {
      streamRef.current = await navigator.mediaDevices.getUserMedia({
        video: true,
      });

      if (videoRef.current) {
        videoRef.current.srcObject = streamRef.current;
        isCameraOpen.current = true;
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const closeCamera = () => {
    if (isCameraOpen.current) {
      // Stop the camera stream if it's open
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      isCameraOpen.current = false;
    }
  };

  const [pictureTaken, setPictureTaken] = React.useState(false);
  const takePicture = () => {
    // closeCamera();
    setPictureTaken(true);
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    const capturedImage = canvas.toDataURL("image/png");

    setCurrentImage(capturedImage);
  };

  const retryBtnRef = useRef(null);

  const submitImage = async (capturedImage) => {
    // const canvas = document.createElement("canvas");
    // const context = canvas.getContext("2d");
    // canvas.width = videoRef.current.videoWidth;
    // canvas.height = videoRef.current.videoHeight;
    // context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    // const capturedImage = canvas.toDataURL("image/png");

    // setCurrentImage(capturedImage);

    console.log("capturedImage", capturedImage);
    setProgress(20);
    setLivenessPassed(true);
    // registerButtonRef.current.disabled = true;
    // registerButtonRef.current.style.backgroundColor = "gray !important";
    // registerButtonRef.current.textContent = "Please Wait...";
    // registerButtonRef.current.style.cursor = "not-allowed";

    // retryBtnRef.current.style.cursor = "not-allowed";
    // retryBtnRef.current.disabled = true;

    let facesList = [];
    facesList.push(capturedImage);
    let reqDto = {
      userFaces: facesList,
    };
    // toast.info("Checking image");
    setProgMessage("Face liveness test passed....");
    //test login

    // const res798789 = await MeetingService.loginUsingFace(
    //   "miniontest",
    //   reqDto
    // );
    // console.log("res798789", res798789);
    // return;
    //test login

    const responseCheckImage = await DashboardServices.checkImage(
      loginUserDetails.userId,
      loginUserDetails.orgId,
      reqDto
    );
    console.log("responseCheckImage", responseCheckImage.data);
    // toast.info("Checking facial biometrics uniqueness");
    setProgMessage("Checking facial biometrics uniqueness...");
    if (responseCheckImage.data.returnValue === "1") {
      setProgress(33);
      const responseCheckColForImg =
        await DashboardServices.checkCollectionForImage(
          loginUserDetails.userId,
          loginUserDetails.orgId,
          reqDto
        );
      console.log("responseCheckColForImg", responseCheckColForImg.data);
      if (responseCheckColForImg.data.returnValue === "1") {
        //same image found
        toast.error(
          "These biometric are associated with another account please continue with that account."
        );
        onClose();
      } else if (responseCheckColForImg.data.returnValue === "-1") {
        toast.error("Something went wrong");
      } else {
        setProgress(66);
        setProgMessage(
          "Facial biometric uniqueness confirmed, registering face now..."
        );
        setProgColor("limegreen");
        // toast.info(
        //   "facial biometric uniqueness confirmed, registering face now "
        // );
        const responseAddFaceToCol =
          await DashboardServices.addFaceToCollection(
            loginUserDetails.userId,
            loginUserDetails.orgId,
            reqDto
          );
        if (responseAddFaceToCol.data.returnValue === "1") {
          setProgress(100);
          setProgMessage("Facial Biometrics added to account successfully");
          setProgColor("limegreen");

          // closeCamera();
          setTimeout(() => {
            toast.success("Facial Biometrics added to account successfully");
            // closeDialog();
            setFaceId(responseAddFaceToCol.data.message);
            onClose();
          }, 1000);
          return;
        } else {
          toast.error("Something went wrong");
          onClose();
        }
        console.log("responseCheckImage", responseAddFaceToCol.data);
      }
    } else {
      toast.error(responseCheckImage.data.message);
      onClose();
    }

    setProgress(0);
    // registerButtonRef.current.disabled = false;
    // registerButtonRef.current.style.backgroundColor = "#e4ebf5 !important";
    // registerButtonRef.current.textContent = "Register Face";
    // registerButtonRef.current.style.cursor = "pointer";

    // retryBtnRef.current.style.cursor = "pointer";
    // retryBtnRef.current.disabled = false;

    // Move to the next instruction image
    // setInstructionIndex(
    //   (prevIndex) => (prevIndex + 1) % instructionImages.length
    // );

    // // If we have captured four images, close the modal
    // if (instructionIndex === 3) {
    //   onClose();
    // }
  };

  const confirm = useConfirm();

  return (
    <Dialog
      open={open}
      onClose={() => {
        console.log("dialogContentFaceLiveness closed");
      }}
      maxWidth="sm"
    >
      <DialogContent className="dialogContentFaceLiveness">
        {!livenessPassed && (
          <div
            role="button"
            style={{ cursor: "pointer" }}
            className="closeDilog"
            onClick={() => {
              // closeCamera();

              if (faceId != null) {
                onClose();
              } else {
                confirm({
                  description: "Cancel facial biometrics registration process?",
                })
                  .then(() => {
                    onClose();
                  })
                  .catch(() => {
                    console.log("Face liveness test cancelled");
                  });
              }
            }}
          >
            <XLg />
          </div>
        )}
        {!livenessPassed && faceId == null && (
          <>
            <FaceLivenessAwsAmplify
              onDone={(data) => {
                console.log(data);
                if (data.returnValue === "1") {
                  // setCurrentImage(data.image);
                  submitImage(data.image);
                  // setLivenessPassed(true);
                  // setProgress(80);
                } else {
                  onClose();
                }
              }}
              onClose={onClose}
              onRetry={() => {
                onClose();
                // closeCamera();
                setTimeout(() => {
                  setShowRegFace(true);
                }, 1000);
              }}
            />
          </>
        )}
        {livenessPassed && progress > 0 && (
          <>
            <p
              style={{
                fontSize: "14px",
                margin: "5px",
              }}
            >
              {progMessage}
            </p>
            <LinearWithValueLabel prog={progress} />
          </>
        )}
        {faceId != null && (
          <div className="container">
            <div className="left">
              {" "}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ paddingBottom: "10px" }}
                // sx={{ marginBottom: "22px !important", textAlign: "center" }}
              >
                Your Facial biometrics are already registered, you can delete
                and register again
              </Typography>
            </div>
            <div
              className="middle"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="dialogActions"
                onClick={async () => {
                  onClose();
                }}
                // style={{ backgroundColor: "#e4ebf5", borderRadius: "10px" }}
              >
                <Button className="dfultDarkSecondaryBtn">Close</Button>
              </div>
              <div
                style={{ backgroundColor: "#e4ebf5", borderRadius: "10px" }}
                className="dialogActions"
                onClick={async () => {
                  confirm({
                    description: "Confirm facial biometrics deletion?",
                  })
                    .then(async () => {
                      deleteFaceRef.current.disabled = true;

                      deleteFaceRef.current.textContent = "Please Wait...";
                      deleteFaceRef.current.style.cursor = "not-allowed";
                      toast.info("deleting face, please wait....");
                      const resDelFace = await DashboardServices.deleteFace(
                        loginUserDetails.orgId,
                        loginUserDetails.userId
                      );
                      console.log(resDelFace);
                      if (resDelFace.data.returnValue === "1") {
                        toast.success("Face deleted successfully");
                        setFaceId(null);
                        onClose();
                      } else {
                        toast.error("Something went wrong");
                        deleteFaceRef.current.disabled = false;
                        deleteFaceRef.current.textContent = "Delete Face";
                        deleteFaceRef.current.style.cursor = "pointer";
                      }
                    })
                    .catch(() => {
                      console.log("facial biometrics deletion cancelled");
                    });
                }}
              >
                <Button className="dfultPrimaryBtn" ref={deleteFaceRef}>
                  Delete Face
                </Button>
              </div>
            </div>
            {/* <div className="dialogActions" onClick={() => {}}>
              
            </div> */}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FaceCaptureComponent;
