import { Add, AttachFile } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import { useRef, useState, useEffect, useContext } from "react";
import { Download, Trash, XLg } from "react-bootstrap-icons";
import {
  getActivityList,
  uploadTemplate,
  getActiveTemplatesOfOrganization,
  deactivateTemplate,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import {
  MAX_FILE_SIZE_TEMPLATE,
  VALID_FILE_EXTENSION_TEMPLATE,
} from "../../../../constants/const_values";
import { toast } from "react-toastify";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import { v4 as uuidv4 } from "uuid";
import DocumentTemplateViewer from "../../../task/view/component/DocumentTemplateViewer";
import { AddTabContext } from "../../../dashboard/view/DashboardPage";
// import useFileUpload from "react-use-file-upload";

export default function ViewActivityTemplate({
  handelCloseViewActivityTemplate,
  refreshActivityTemplateData,
  showSelectedViewActivityTemplate,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));

  const { addTab } = useContext(AddTabContext);

  console.log(
    "showSelectedViewActivityTemplate",
    showSelectedViewActivityTemplate
  );

  const [selectedActivity, setSelectedActivity] = useState({
    name: showSelectedViewActivityTemplate.activity,
    id: null,
  });

  console.log("selectedActivity", selectedActivity);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  //////////////////////////////////////////////////////////////////

  const [activeTemplateDataOfOrg, setActiveTemplateDataOfOrg] = useState([]);

  useEffect(() => {
    fetchActiveTemplateData();
  }, [userDetails.orgId]);

  const fetchActiveTemplateData = () => {
    getActiveTemplatesOfOrganization(userDetails.orgId).then((response) => {
      console.log("getActiveTemplatesOfOrganisation", response.data);
      setActiveTemplateDataOfOrg(response.data);
    });
  };

  // useEffect(()=>{
  //   getActiveTemplatesOfOrganization(userDetails.orgId)
  //   .then((response)=>{
  //     console.log('getActiveTemplatesOfOrganisation' , response.data)
  //     setActiveTemplateDataOfOrg(response.data);
  //   })
  // },[userDetails.orgId]);

  useEffect(() => {
    if (selectedActivity.name !== "") {
      console.log("Selected Activity:", selectedActivity.name);
      console.log("Active Template Data of Org:", activeTemplateDataOfOrg);

      const filteredTemplates = activeTemplateDataOfOrg.filter(
        (template) => template.activity === selectedActivity.name
      );

      console.log("Filtered Templates:", filteredTemplates);

      const files = filteredTemplates.map((template) => {
        return new File(["content"], template.fileDisplayName, {
          type: "text/plain",
          lastModified: new Date(),
        });
      });

      console.log("Files:", files);

      setUploadedFiles(files);
    }
  }, [selectedActivity.name, activeTemplateDataOfOrg]);

  console.log("uploadedFiles", uploadedFiles);

  /////////////////////////////////////////////////////////////////

  const [activityListData, setActivityListData] = useState([]);

  const [selectedTaskMasterId, setSelectedTaskMasterId] = useState(0);

  const [selectedFileObj, setSelectedFileObj] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getActivityList(userDetails.orgId).then((response) => {
      console.log("getActivityList", response.data);
      setActivityListData(response.data);
    });
  }, []);

  const fileUploadRef = useRef(null);

  // const handleFileUpload = async (e) =>{
  //   console.log(e);
  //   if (!e.target.files) {
  //     return;
  //   }
  //   const files = Array.from(e.target.files);
  //   console.log("handleFileUpload", files);

  //   const validFiles = [];

  //   for (let i = 0; i < files.length; i++) {
  //     if (files[i].size > 1024 * 1024 * MAX_FILE_SIZE_TEMPLATE) {
  //       toast.warning(
  //         `'${files[i].name}' needs to be less than ${MAX_FILE_SIZE_TEMPLATE}MB`,
  //         {
  //           position: toast.POSITION.TOP_RIGHT,
  //         }
  //       );
  //     } else {
  //       validFiles.push(files[i]);
  //       try {
  //         const fileData = await convertFileToBase64_Split(files[i]);
  //         setSelectedFileObj({ [files[i].name]: fileData });
  //       } catch (error) {
  //         console.error("Error converting file to base64:", error);
  //       }
  //     }
  //   }

  // setUploadedFiles(validFiles);
  // };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    console.log("handleFileUpload", files);

    const validFiles = [];
    const promises = [];

    for (const file of files) {
      if (file.size > 1024 * 1024 * MAX_FILE_SIZE_TEMPLATE) {
        toast.warning(
          `'${file.name}' needs to be less than ${MAX_FILE_SIZE_TEMPLATE}MB`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        validFiles.push(file);
        promises.push(
          convertFileToBase64_Split(file)
            .then((fileData) => {
              setSelectedFileObj((prevSelectedFileObj) => ({
                ...prevSelectedFileObj,
                [file.name]: fileData,
              }));
            })
            .catch((error) => {
              console.error("Error converting file to base64:", error);
            })
        );
      }
    }

    await Promise.all(promises);

    setUploadedFiles([...uploadedFiles, ...validFiles]);
  };

  // const handleDeleteFile = (index) => {
  //   const updatedFiles = [...uploadedFiles];
  //   updatedFiles.splice(index, 1);
  //   setUploadedFiles(updatedFiles);
  // };

  const handleDeleteFile = (fileData, index) => {
    console.log("handleFileDownload", fileData);
    console.log("selectedActivity", selectedActivity);

    const fileName = fileData.name;
    console.log("fileName", fileName);

    const matchingTemplate = activeTemplateDataOfOrg.find(
      (template) =>
        template.fileDisplayName === fileName &&
        template.activity === selectedActivity.name
    );

    if (matchingTemplate) {
      const templateId = matchingTemplate.templateId;
      console.log("File templateId:", templateId);

      deactivateTemplate(userDetails.userId, templateId)
        .then((response) => {
          console.log("data deleted", response.data);
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            refreshActivityTemplateData();
            fetchActiveTemplateData();
            const updatedFiles = [...uploadedFiles];
            updatedFiles.splice(index, 1);
            setUploadedFiles(updatedFiles);
            // fetchTemplateData();
            // setOpenModal(false);
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.error("Error deactivating template:", error);
        });
    } else {
      const updatedFiles = [...uploadedFiles];
      updatedFiles.splice(index, 1);
      setUploadedFiles(updatedFiles);
    }
  };

  const handleDragEnter = (e) => {
    console.log("handleDragEnter");
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e) => {
    console.log("handleDragOver");
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    console.log("handleDrop");
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    console.log("dragged files", files);

    const validFiles = [];
    const promises = [];

    for (const file of files) {
      if (file.size > 1024 * 1024 * MAX_FILE_SIZE_TEMPLATE) {
        toast.warning(
          `'${file.name}' needs to be less than ${MAX_FILE_SIZE_TEMPLATE}MB`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else if (
        !VALID_FILE_EXTENSION_TEMPLATE.includes(
          file.name.split(".").pop().toLowerCase()
        )
      ) {
        toast.warning(`Invalid File Type of '${file.name}'`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        validFiles.push(file);
        promises.push(
          convertFileToBase64_Split(file)
            .then((fileData) => {
              setSelectedFileObj((prevSelectedFileObj) => ({
                ...prevSelectedFileObj,
                [file.name]: fileData,
              }));
            })
            .catch((error) => {
              console.error("Error converting file to base64:", error);
            })
        );
      }
    }

    await Promise.all(promises);

    setUploadedFiles([...uploadedFiles, ...validFiles]);
  };

  const top100Films = [
    { label: "AGM" },
    { label: "Board Meeting" },
    { label: "Appointment" },
  ];

  const finalsubmit = () => {
    setLoading(true);
    console.log("finalsubmit");

    if (selectedTaskMasterId == 0) {
      toast.error("Please Select Work", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (Object.keys(selectedFileObj).length === 0) {
      toast.error("Please upload at least one file", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const reqDto = {
      orgId: userDetails.orgId,
      taskMasterId: selectedTaskMasterId,
      fileNameKeyFieDataValue: selectedFileObj,
    };
    console.log("reqDto", reqDto);

    uploadTemplate(userDetails.userId, reqDto).then((response) => {
      if (response.data.returnValue == "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        refreshActivityTemplateData();
        setSelectedTaskMasterId(0);
        setSelectedFileObj({});
        setUploadedFiles([]);
        setSelectedActivity({
          name: "",
          id: null,
        });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setLoading(false);
    });
  };

  // const handleViewFile = (file) => {
  //   console.log('handleViewFile', file );

  //   console.log('userDetails', userDetails)

  //   const fileName = file.name;
  //   console.log("fileName", fileName);

  //   const matchingTemplate = activeTemplateDataOfOrg.find(
  //     (template) =>
  //       template.fileDisplayName === fileName &&
  //       template.activity === selectedActivity.name
  //   );

  //   if (matchingTemplate) {
  //     const templateId = matchingTemplate.templateId;
  //     console.log("File templateId:", templateId);
  //     const FileNewName = matchingTemplate.fileDisplayName

  //     addTab({
  //       key: uuidv4(),
  //       title: `File_${FileNewName}`,
  //       content: <DocumentTemplateViewer file={value} />,
  //       isDraggable: true,
  //     });

  //   // return (
  //   //   <DocumentEditor
  //   //     officeUrl={`${collaboraDomain}/browser/abd9205/cool.html?WOPISrc=${apiEndPoint}/wopi/files/${templateId}`}
  //   //     fileId={templateId}
  //   //     userId={userDetails.userId}
  //   //     sharedBy={null}
  //   //     fullScreen={null}
  //   //     fileName={FileNewName}
  //   //   />

  //   // );
  // };
  // };

  const handleViewFile = (file) => {
    const matchingTemplate = activeTemplateDataOfOrg.find(
      (template) =>
        template.fileDisplayName === file.name &&
        template.activity === selectedActivity.name
    );

    if (matchingTemplate) {
      console.log("Matching template found:", matchingTemplate);
      const tabKey = uuidv4();
      addTab({
        key: tabKey,
        title: `File_${matchingTemplate.fileDisplayName}`,
        content: (
          <DocumentTemplateViewer file={matchingTemplate} tabKey={tabKey} />
        ),
        isDraggable: true,
      });
    } else {
      console.log(
        "No matching template found for file:",
        file.name,
        "and activity:",
        selectedActivity.name
      );
    }

    console.log("userDetails:", userDetails);
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">View Activity Template</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseViewActivityTemplate()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  disablePortal
                  id="selectActivity"
                  className="formAutocompleteField"
                  variant="outlined"
                  options={activityListData}
                  getOptionLabel={(option) => option.name}
                  value={selectedActivity}
                  onChange={(e, data) => {
                    setSelectedTaskMasterId(parseInt(data.id));
                    setSelectedActivity(data);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="formAutoComInputField autocompFildPlaceholder"
                      {...params}
                      label="Select Activity"
                    />
                  )}
                  disabled
                />
              </FormControl>
            </div>

            {/* on darg file upload start */}

            {/* <div
             
              style={{
                border: "2px solid #545454",
                borderRadius: "12px",
                padding: "10px",
                color: "#ffffff",
                width: "-webkit-fill-available",
              }}
              disabled
            >
              <h1
                style={{
                  margin: "0px",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                Upload Files
              </h1>

            
              <div className="form-container">
               
                <div 
                >
                  <p>{`**Maximum File Size Allowed ${MAX_FILE_SIZE_TEMPLATE}MB, Extensions Allowed ${VALID_FILE_EXTENSION_TEMPLATE.map(
                    (item) => ` ${item}`
                  )}`}</p>
                  <ul>
                    
                  
                  </ul>
                </div>

              
                <div>
                  <p
                    style={{
                      display: "block",
                      position: "relative",
                      opacity: "0.6",
                      margin: "10px",
                      textAlign: "center",
                      fontStyle: "italic",
                    }}
                  >
                    Drag and drop files here
                  </p>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => fileUploadRef.current.click()}
                    sx={{ marginBottom: "10px" }}
                    style={{
                      backgroundColor: "#707070",
                      borderRadius: "20px",
                      width: "100%",
                    }}
                    disabled
                  >
                    Select files
                  </Button>

                
                  <input
                    ref={fileUploadRef}
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    accept=".doc, .docx, .docm, .ppt, .pptx, .pptm, .txt, .pdf, .xls, .xlsx"
                    onChange={(e) => {
                      handleFileUpload(e);
                      fileUploadRef.current.value = null;
                    }}
                    disabled
                  />
                </div>
              </div>
            </div> */}

            {/* on darg file upload end */}

            <div class="elementListGrp">
              <div class="elementHeding">Template Added</div>
              <ul class="elmListInfo">
                {uploadedFiles.map((file, index) => (
                  <li key={index}>
                    <span
                      className="rolsNm"
                      onClick={() => handleViewFile(file)}
                    >
                      {file.name}
                    </span>
                    {/* <IconButton className="downlBtn">
                      <Download />
                    </IconButton> */}
                    <IconButton
                      className="rmvBtn"
                      onClick={() => handleDeleteFile(file, index)}
                    >
                      <Trash />
                    </IconButton>
                  </li>
                ))}

                {/* <li>
                <span className="rolsNm">Template 1.pptx</span>
                <IconButton className="downlBtn">
                  <Download />
                </IconButton>
                <IconButton className="rmvBtn">
                  <Trash />
                </IconButton>
                
              </li> */}
                {/* <li>
                <span className="rolsNm">Template 2.docs</span>
                <IconButton className="downlBtn">
                  <Download />
                </IconButton>
                <IconButton className="rmvBtn">
                  <Trash />
                </IconButton>
              </li> */}
              </ul>
            </div>
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button
              className="dfultPrimaryBtn"
              onClick={() => finalsubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>Save</>
              )}
            </Button> */}
            {/* {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseViewActivityTemplate()}
              >
                Cancel
              </Button>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
}
