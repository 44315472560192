import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  AttachFile,
  Audiotrack,
  CancelOutlined,
  CheckCircle,
  DescriptionOutlined,
  PowerSettingsNew,
  Refresh,
  Send,
  ShortcutOutlined,
  VideoCall,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { getFormattedDate, getLoginUserDetails } from "../../../../utils/Utils";
import DashboardServices from "../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import { People, XLg } from "react-bootstrap-icons";
import TemplateList from "./TemplateList";
import ForwardTaskModal from "./ForwardTaskModal";
import LinkTaskModal from "./LinkTaskModal";
import DisagreeTask from "./DisagreeTask";
import AssigneeModal from "./AssigneeModal";
import SignOff from "./SignOff";
import RightFlotingContainer from "./RightFlotingContainer";
import RightTaskFileUpload from "./RightTaskFileUpload";
import {
  clearSelectedTask,
  setSelectedTask,
} from "../../../../redux/reducers/rightSideTaskPanelSlice";
import isAssignedByMe from "../../taskUtil";

export default function TaskInformation({
  data,
  refreshData,
  removeFromStore,
}) {
  const [showFileModal, setShowFileUploadModal] = useState(false);

  const dispatch = useDispatch();
  const loginUserDetail = getLoginUserDetails();
  const fileInputRef = useRef(null);

  const progress = useRef(0);
  const [progressWidth, setProgressWidth] = useState(20);
  const [dateRemaning, setDateRemaning] = useState(70);

  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);

  const [showAddressConcern, setShowAddressConcern] = useState(false);
  const [addressConcernRemark, setAddressConcernRemark] = useState("");

  const [assigneeList, setAssigneeList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [reassignUser, setReassignUser] = useState(null);

  const [showForwardTask, setShowForwardTask] = useState(false);
  const [forwardReason, setForwardReason] = useState("");

  const [showCreateLinkTask, setShowCreateLinkTask] = useState(false);
  const [linkTaskDescription, setLinkTaskDescription] = useState("");
  const [linkDocuments, setLinkDocuments] = useState([]);

  const [tempOfOrgList, setTemplateOfOrgList] = useState([]);
  const [addedTemplateList, setAddedTemplateList] = useState([]);
  const [recommendedTemplateList, setRecommendedTemplateList] = useState([]);
  const [showAddTemplate, setShowAddTemplate] = useState(false);

  const [anchorUl, setAnchorUl] = React.useState(null);
  const openUl = Boolean(anchorUl);
  const ulId = openUl ? "simple-popover" : undefined;
  const fileDocuments = useRef(null);
  const commentInputRef = useRef();

  const [showDisagreeModal, setShowDisAgreeModal] = useState(false);

  const [showReassignTask, setShowReassignTask] = useState(false);

  const numberList = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const [tempUpdatePercent, setTempUpdatePercent] = useState(0);

  const [reassignReason, setReassignReason] = useState("");
  const [showAssigneeList, setShowAssigneeList] = useState(false);

  const [showAudioModal, setShowAudioModal] = useState(false);
  const audioUrl = useRef();

  console.log("incoming completed");

  const handleCloseUl = () => {
    setAnchorUl(null);
  };

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const fileElementRef = useRef(null);
  const [filearrowDisable, setFilearrowDisable] = useState(true);
  const filehandleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setFilearrowDisable(true);
      } else {
        setFilearrowDisable(false);
      }
    }, speed);
  };

  const [fileDivWidth, setFileDivWidth] = useState(0);

  useEffect(() => {
    if (fileElementRef.current) {
      setFileDivWidth(fileElementRef.current.offsetWidth);
    }
  }, [fileElementRef]);

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  const handleSubmitReassignTask = (e) => {
    let validForm = true;
    if (reassignReason === "") {
      toast.error("Please Provide Reassign Reason");
      validForm = false;
    }
    if (reassignUser === null || reassignUser.length === 0) {
      toast.error("Please Select Reassign User List");
      validForm = false;
    }
    if (!validForm) {
      return;
    }
    console.log("user id=", reassignUser);
    const reqdto = {
      taskDetailId: data.taskId,
      toUserId: reassignUser.userId,
      reassignReason: reassignReason,
      progress: 0,
    };

    DashboardServices.reassignTask(loginUserDetail.userId, reqdto).then(
      (response) => {
        if (response.data.returnValue === "1") {
          toast.success(response.data.message);
          dispatch(clearSelectedTask());
          refreshData();
        } else {
          toast.error(response.data.message);
        }
      }
    );
  };

  const intervalProgressor = (timeDelay) => {
    const intervalId = setInterval(() => {
      console.log("interval progressor=", progressWidth);
      if (progress.current < 100) {
        setProgressWidth((prev) => prev + 1);
        progress.current = progress.current + 1;
      } else {
        clearInterval(intervalId);
      }
    }, timeDelay);
  };

  const getTaskDifference = () => {
    // const time1=task.taskCreationTime
    // const time2 =task.taskCompletionTime
    const creationTime = new Date(data && data.createdAt);
    const completionTime = new Date(data && data.completionTime);
    const currentTime = new Date();

    //check if already time passed

    const diffFromCurrent = currentTime - completionTime;
    if (diffFromCurrent > 0) {
      setDateRemaning(100);
      return;
    }

    // Calculate the time difference in milliseconds
    const totalTimeDifference = completionTime - creationTime;
    const timeDifferenceFromNow = currentTime - creationTime;
    const milisecondPerPercentIncrease = totalTimeDifference / 100;
    const currentPercent = (timeDifferenceFromNow * 100) / totalTimeDifference;
    setDateRemaning(
      Math.floor(currentPercent) < 100 ? Math.floor(currentPercent) : 100
    );
    progress.current = Math.floor(currentPercent);
    const nextPercent = Math.ceil(currentPercent);
    const difference = nextPercent - currentPercent;
    const diffInMilisecond = milisecondPerPercentIncrease * difference;
    const clearTimeoutVal = setTimeout(() => {
      if (currentPercent < 100) {
        console.log("before interval progress", milisecondPerPercentIncrease);
        clearTimeout(clearTimeoutVal);
        setDateRemaning((prev) => prev + 1);
        progress.current = progress.current + 1;
        if (milisecondPerPercentIncrease > 0) {
          intervalProgressor(milisecondPerPercentIncrease);
        }
      }
    }, diffInMilisecond);
    console.log("currentPercent", currentPercent);
    console.log(
      "milisecondPerPercentIncrease = ",
      milisecondPerPercentIncrease / 1000
    );
  };

  const handleChangeFile = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
    }));

    console.log("reqDto = ", reqDto);

    DashboardServices.uploadDocuments(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      console.log("document sent is ", response.data);
      if (response.data) {
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setSelectedTask(tempData));
      }
    });
    console.log("file document = ", fileDocuments.current);
  };

  useEffect((e) => {
    setProgressWidth(data.progress);
    getTaskDifference();

    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );
  }, []);

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp">
              <span style={{ color: "white" }}> For Information Only</span>
            </div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn">
                <IconButton
                  aria-label="User"
                  onClick={(e) => {
                    // setOpenUserList(true);
                    // handleClickUl(e);
                    setShowAssigneeList(true);
                  }}
                >
                  <Tooltip title="User" arrow>
                    <People />
                  </Tooltip>
                </IconButton>
                <Popover
                  id={ulId}
                  open={openUl}
                  anchorEl={anchorUl}
                  onClose={handleCloseUl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ul>
                    {assigneeList &&
                      assigneeList.map((assignee) => <li>{assignee.name}</li>)}
                  </ul>
                </Popover>
              </div>

              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    DashboardServices.deleteDashboardDataById(data.id).then(
                      (response) => {
                        if (response.data === 1) {
                          // toast.success("Information Removed Successfully");
                          refreshData();
                        } else {
                          toast.error("Something Went Wrong");
                        }
                        dispatch(clearSelectedTask());
                      }
                    );
                    refreshData();
                    dispatch(clearSelectedTask());
                  }}
                >
                  <Tooltip title="Close" arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                <div className="taskTitleContrct">
                  {/* <div className="taskID">PT0023</div> */}
                  <div className="taskTitleNM">{data && data.taskName}</div>
                </div>
                <div className="taskElementGrp">
                  <div className="tskElemHeding">Description</div>
                  <div className="tskElemntInfo">
                    {data && data.taskDescription}
                  </div>
                </div>

                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Audio Message</div>
                    <div className="tskElemntInfo">
                      <div className="tskElemntInfo">
                        <IconButton
                          className="elemntMrFileBtn"
                          variant="outlined"
                          onClick={(e) => {
                            DashboardServices.generatePreSignedUrlS3Object(
                              data.audioNoteFileName,
                              loginUserDetail.orgId
                            ).then((response) => {
                              audioUrl.current = response.data;
                              setShowAudioModal(true);
                            });
                          }}
                        >
                          <Audiotrack />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                )}

                {data &&
                  data.contractName &&
                  data.contractName.trim() !== "" && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">Contract</div>
                      <div className="taskContractNm">
                        {data && data.contractName}
                      </div>
                    </div>
                  )}

                {/* Templates start */}

                {data.templates && data.templates.length > 0 && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Template</div>

                    <div class="elementFileListedGrp">
                      <div className="horaizonScroll">
                        <IconButton
                          className="goPrevousBtn"
                          onClick={() => {
                            handleHorizantalScroll(
                              elementRef.current,
                              25,
                              200,
                              -10
                            );
                          }}
                          disabled={arrowDisable}
                        >
                          <ArrowBackIosNew />
                        </IconButton>
                        <div class="elemntFilsgrp" ref={elementRef}>
                          {data &&
                            data.templates.map((template) => (
                              <div className="elemntFile">
                                <Tooltip
                                  title={template.fileDisplayName}
                                  arrow
                                  className="elmFileTolTip"
                                >
                                  <span className="elemntFilIoc">
                                    <DescriptionOutlined />
                                  </span>
                                  <span className="elemntFilNm">
                                    {template.fileDisplayName}
                                  </span>
                                </Tooltip>
                              </div>
                            ))}
                        </div>
                        {tempDivWidth > 270 && (
                          <IconButton
                            className="goNextBtn"
                            onClick={() => {
                              handleHorizantalScroll(
                                elementRef.current,
                                25,
                                200,
                                10
                              );
                            }}
                          >
                            <ArrowForwardIos />
                          </IconButton>
                        )}
                      </div>
                      {/* <div class="elmntAddMrFiles">
                      <Button
                        className="elemntMrFileBtn"
                        variant="outlined"
                        onClick={handleAddTemplate}
                      >
                        <Plus />
                      </Button>
                    </div> */}
                    </div>
                  </div>
                )}

                {/* Templates end */}

                {/* <TemplateList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleAddTemplate={handleAddTemplate}
                /> */}

                {/* files start */}

                {data.documents && data.documents.length > 0 && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Files</div>

                    <div class="elementFileListedGrp">
                      <div className="horaizonScroll">
                        <IconButton
                          className="goPrevousBtn"
                          onClick={() => {
                            filehandleHorizantalScroll(
                              fileElementRef.current,
                              25,
                              200,
                              -10
                            );
                          }}
                          disabled={filearrowDisable}
                        >
                          <ArrowBackIosNew />
                        </IconButton>

                        <div class="elemntFilsgrp" ref={fileElementRef}>
                          {data &&
                            data.documents &&
                            data.documents.map((doc) => (
                              <div className="elemntFile">
                                <Tooltip
                                  title={doc.fileDisplayName}
                                  arrow
                                  className="elmFileTolTip"
                                >
                                  <span className="elemntFilIoc">
                                    <DescriptionOutlined />
                                  </span>
                                  <span className="elemntFilNm">
                                    {doc.fileDisplayName}
                                  </span>
                                </Tooltip>
                              </div>
                            ))}
                        </div>
                        {fileDivWidth > 370 && (
                          <IconButton
                            className="goNextBtn"
                            onClick={() => {
                              filehandleHorizantalScroll(
                                fileElementRef.current,
                                25,
                                200,
                                10
                              );
                            }}
                          >
                            <ArrowForwardIos />
                          </IconButton>
                        )}
                      </div>
                      {/* <div class="elmntAddMrFiles">
                      <Button
                        className="elemntMrFileBtn"
                        variant="outlined"
                        onClick={handleAddnewFile}
                      >
                        <Plus />
                      </Button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleChangeFile}
                        style={{ display: "none" }}
                      ></input>
                    </div> */}
                    </div>
                  </div>
                )}

                {/* <FileList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleChangeFile={handleChangeFile}
                  setShowFileUploadModal={setShowFileUploadModal}
                /> */}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Priority</div>
                  <div className="tskPrioArea">
                    {/* statUrgent, statNormal, statImportant  used these three for diffrent priority */}
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class="priStat">
                        {data.priority === 0
                          ? "Regular"
                          : data.priority === 1
                          ? "Important"
                          : "Critical"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Activity</div>
                    <div className="tskElemntInfo">
                      {data && data.activityName}
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Assigned By</div>
                    <div className="tskElemntInfo">
                      {data && isAssignedByMe(data.assignByUserId)
                        ? "Me"
                        : data.assignByUserName}
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Assigned to</div>
                    <div className="tskElemntInfo">
                      {data && data.assignByUserName}
                    </div>
                  </div> */}
                  {data.acceptRejectFlag === "A" &&
                    data.approveRejectConcernedPostponedByUserName &&
                    data.approveRejectConcernedPostponedByUserName.trim() !==
                      "" && (
                      <div className="taskElementGrp">
                        <div className="tskElemHeding">Approved By</div>
                        <div className="tskElemntInfo">
                          {data &&
                            data.approveRejectConcernedPostponedByUserName}
                        </div>
                      </div>
                    )}
                </div>

                <div className="taskElementsGrpWithTwo">
                  {data.approvalRmks && data.approvalRmks.trim() !== "" && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">Approval Remark</div>
                      <div className="tskElemntInfo">
                        {data && data.approvalRmks}
                      </div>
                    </div>
                  )}
                </div>

                {data && data.comments && data.comments.length > 0 && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Last updated</div>
                    <div className="tskElemntInfo">
                      {`${
                        data.comments[data.comments.length - 1]
                          .commentsByUserName
                      } - ${getFormattedDate(
                        data.comments[data.comments.length - 1].commentsAt
                      )}`}
                    </div>
                  </div>
                )}

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showAddressConcern && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Address Concern</div>

              <div className="modInputFild">
                <TextField
                  id="outlined-basic"
                  label="Concern Remark"
                  variant="outlined"
                  value={addressConcernRemark}
                  onChange={(e) => setAddressConcernRemark(e.target.value)}
                />
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    if (addressConcernRemark.trim() === "") {
                      toast.error("Please provide your concern response");
                      return;
                    }

                    DashboardServices.addressConcernOfTask(
                      data.taskId,
                      loginUserDetail.userId,
                      addressConcernRemark
                    ).then((response) => {
                      if (response.data && response.data.returnValue === "1") {
                        toast.success(response.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                        setShowAddressConcern(false);
                        dispatch(clearSelectedTask());
                      } else {
                        toast.error(response.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      }
                      setAddressConcernRemark("");
                      refreshData();
                      // setSelectedUsers([]);
                    });
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowAddressConcern(false);
                    setAddressConcernRemark("");
                    setSelectedUsers([]);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showForwardTask && (
          <ForwardTaskModal
            data={data}
            userList={userList}
            refreshData={refreshData}
            handleClose={() => {
              setShowForwardTask(false);
            }}
          />
        )}

        {showReassignTask && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Reassing Task</div>

              <div className="elementFormContainer">
                <div className="modInputFild" style={{ marginTop: "10px" }}>
                  Select Your Progress Of Task
                </div>

                <div
                  className="workUpdateBtnGrp"
                  style={{ marginBottom: "10px" }}
                >
                  {numberList &&
                    numberList.map((number) => (
                      <Button
                        className={`${
                          tempUpdatePercent === number ? "activeStatus" : ""
                        }`}
                        variant="contained"
                        // disabled={number <= updatePercentValue}
                        onClick={(e) => {
                          setTempUpdatePercent(number);
                          console.log("");
                        }}
                      >
                        {number}
                      </Button>
                    ))}
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label="Reassign Reason *"
                      variant="outlined"
                      multiline
                      rows={4}
                      maxRows={7}
                      value={reassignReason}
                      onChange={(e) => setReassignReason(e.target.value)}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    {/* <InputLabel id="adduser">Add User</InputLabel> */}
                    <Autocomplete
                      id="adduser-select"
                      disableClearable
                      className="modelformAutocompleteField"
                      variant="outlined"
                      options={userList}
                      getOptionLabel={(option) => option.userName}
                      getOptionSelected={(option) => {
                        console.log("option is ", option);
                        return option.userId;
                      }}
                      onChange={(e, newValue) => {
                        console.log("new value = ", newValue);
                        setReassignUser(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ color: "black" }}
                          {...params}
                          placeholder="Assign User *"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    handleSubmitReassignTask();
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowReassignTask(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showCreateLinkTask && (
          <LinkTaskModal
            userList={userList}
            data={data}
            handleClose={(e) => setShowCreateLinkTask(false)}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}

        {showDisagreeModal && (
          <DisagreeTask
            assignByUserId={data.assignByUserId}
            userList={userList}
            onClose={() => {
              setShowDisAgreeModal(false);
            }}
            refreshData={refreshData}
            taskDetailId={data.taskId}
          />
        )}
        {showAssigneeList && (
          <AssigneeModal
            assigneeList={assigneeList}
            handleClose={(e) => setShowAssigneeList(false)}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Audio Message</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          </Box>
        )}
      </div>
      {showAddTemplate && (
        <RightFlotingContainer
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={() => {
            setShowAddTemplate(false);
            if (addedTemplateList && addedTemplateList.length > 0) {
              const temp = [...addedTemplateList];
              temp.forEach((template) => {
                template.taskDetailId = data.taskId;
              });
              DashboardServices.addTaskTemplate(
                loginUserDetail.userId,
                addedTemplateList,
                "OLD"
              ).then((response) => {
                console.log("addTaskTemplate", response.data);
                const tempData = { ...data };
                if (tempData.templates) {
                  tempData.templates = [
                    ...tempData.templates,
                    ...addedTemplateList,
                  ];
                } else {
                  tempData.templates = [...addedTemplateList];
                }
                dispatch(setSelectedTask(tempData));
              });
            }
          }}
          mode={"old"}
        />
      )}

      {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={(e) => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )}
    </>
  );
}
