import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filesInfo: [],
};

const driveSlice = createSlice({
  name: "drive",
  initialState,
  reducers: {
    addFileToQueue(state, action) {
      const { fileName, folderName } = action.payload;
      state.message_queue.push({
        fileName,
        folderName,
        progress: 0,
        status: "uploading",
      });
    },
    clearFileData(state, action) {
      const { index } = action.payload;
      let temp = [...state];
      temp.splice(index);
      return temp;
    },
  },
});

export default driveSlice;
export const { addFileToQueue, clearFileData } = driveSlice.actions;
