import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getCallingCodeList,
  getUnits,
  getAllDepartment,
  getPositionsOfOrganization,
  addEmployee,
  getOrgEmployees,
  getEmploymentTypes,
  // getRightsToSelect,
  getStaticDDOfOrg,
  getUserPositions,
  editEmployee,
  getPositionsOfUnit,
  getReportingPositionEmployee,
  getShifts,
  getUserShifts,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import DateUtils from "../../../../utils/DateUtils";
import { toast } from "react-toastify";
import { validateEmail } from "../../../../utils/Utils";
import { styled } from "styled-components";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EditPeople({
  handelCloseEditEmployee,
  refreshViewPeopleData,
  fetchDashBoardData,
  editEmployeesData,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));

  console.log("editEmployeesData", editEmployeesData);

  console.log("userDetails", userDetails);

  const [loading, setLoading] = useState(false);

  const [joiningdate, setjoiningdate] = useState(new Date());
  const handleJoiningDate = (date) => {
    console.log("new date = ", date);
    setjoiningdate(date);
  };

  // useEffect(() => {
  //   const inputDate = editEmployeesData.joiningDate;
  //   let tempDate = new Date();
  //   const [day , month, year] = inputDate.split('-');
  //   // tempDate.setDate(day)
  //   // tempDate.setMonth(parseInt(month)-1)
  //   tempDate.setFullYear(parseInt(year), parseInt(month)-1 , day);
  //   setjoiningdate(tempDate);
  // }, [editEmployeesData]);

  useEffect(() => {
    const inputDate = editEmployeesData?.joiningDate;

    if (inputDate) {
      let tempDate = new Date();
      const [day, month, year] = inputDate.split("-");
      tempDate.setFullYear(parseInt(year), parseInt(month) - 1, parseInt(day));
      setjoiningdate(tempDate);
    }
  }, [editEmployeesData]);

  console.log("joiningDate", joiningdate);

  const [primaryPosdate, setPrimaryPosdate] = useState(new Date());
  const [formattedprimaryPosdate, setFormattedPrimaryPosdate] = useState("");

  const [selectedEmployeeShift, setSelectedEmployeeShift] = useState([]);
  const [shiftList, setShiftList] = useState([]);

  const handleJoiningprimaryPosdate = (date) => {
    console.log("new date = ", date);
    setPrimaryPosdate(date);
    const formatDate = DateUtils.getDateInDDMMYYYY(date);
    setFormattedPrimaryPosdate(formatDate);
  };

  console.log("FirstPositionDate", formattedprimaryPosdate);

  const [openAddNewPosition, setOpenAddNewPosition] = useState(false);
  const handelOpenNewPosition = () => {
    setOpenAddNewPosition(true);
  };

  const [emplyTitle, setEmplyTitle] = useState(editEmployeesData.title);

  const handleChangeEmplyTitle = (event) => {
    console.log("handleChangeEmplyTitle", event.target.value);
    setEmplyTitle(event.target.value);
  };

  const [emplyFirstName, setEmplyFirstName] = useState(
    editEmployeesData.firstName
  );

  const handleChangeEmplyFirstName = (event) => {
    console.log("handleChangeEmplyFirstName", event.target.value);
    setEmplyFirstName(event.target.value);
  };

  const handleChangeEmploymentType = (event) => {
    console.log("handleChangeEmploymentType", event.target.value);
    const {
      target: { value },
    } = event;

    // const shiftString = shiftList.map((item) => item.shiftId===);

    setSelectedEmployeeShift(value);
  };

  const [emplyMiddleName, setEmplyMiddleName] = useState(
    editEmployeesData.middleName
  );

  const handleChangeEmplyMiddleName = (event) => {
    console.log("handleChangeEmplyMiddleName", event.target.value);
    setEmplyMiddleName(event.target.value);
  };

  const [emplyLastName, setEmplyLastName] = useState(
    editEmployeesData.lastName
  );

  const handleChangeEmplyLastName = (event) => {
    console.log("handleChangeEmplyLastName", event.target.value);
    setEmplyLastName(event.target.value);
  };

  const [emplyPhnCC, setEmplyPhnCC] = useState(
    editEmployeesData.officialContactCallingNo
  );
  const [cdCodeList, setCdCodeList] = useState([]);

  const handleChangeemplyPhnCC = (event) => {
    setEmplyPhnCC(event.target.value);
  };

  useEffect(() => {
    getUserShifts(editEmployeesData.userId).then((response) => {
      setSelectedEmployeeShift(response.data);
    });
    if (editEmployeesData.unitId) {
      fetchPositionsOfUnit(editEmployeesData.unitId);
    }
    getShifts(userDetails.orgId, userDetails.userId).then((response) => {
      if (response.data) {
        setShiftList(response.data);
      }
    });

    getCallingCodeList().then((response) => {
      console.log("getCallingCodeList", response.data);
      setCdCodeList(response.data);
    });
  }, []);

  const [emplyGender, setEmplyGender] = useState(editEmployeesData.gender);

  const handleChangeEmplyGender = (event) => {
    console.log("handleChangeEmplyGender", event.target.value);
    setEmplyGender(event.target.value);
  };

  const [emplyEmail, setEmplyEmail] = useState(
    editEmployeesData.officialEmailId
  );

  const handleChangeEmplyEmail = (event) => {
    console.log("handleChangeEmplyEmail", event.target.value);
    setEmplyEmail(event.target.value);
  };

  const [emplyPhoneNumber, setEmplyPhoneNumber] = useState(
    editEmployeesData.officialContactNo
  );

  const handleChangeEmplyPhoneNumber = (event) => {
    const input = event.target.value;
    const numbersOnly = input.replace(/\D/g, "");
    setEmplyPhoneNumber(numbersOnly);
    console.log("numbersOnly", numbersOnly);
  };

  const [emplymentType, setEmplymentType] = useState(
    editEmployeesData.employmentType
  );
  // const [emplymentTypeInput, setEmplymentTypeInput] = useState("");

  const [emplyTypes, setEmplyTypes] = useState([]);

  // useEffect(() => {
  //   getEmploymentTypes(userDetails.orgId).then((response) => {
  //     console.log("Get Employment Types", response.data);
  //     setEmplyTypes(response.data);
  //   });
  // }, [userDetails.orgId]);
  useEffect(() => {
    getStaticDDOfOrg(userDetails.orgId, "SALARY_TYPE").then((response) => {
      console.log("SALARY_TYPE", response.data);
      setEmplyTypes(response.data);
    });
  }, []);

  // const handleChangeEmplyType = (event , newValue) => {
  //   console.log("handleChangeEmplyType", newValue);
  //   setEmplymentType(newValue);
  // };

  // const handleChangeEmplyTypeInput = (event , newValue) => {
  //   console.log("handleChangeEmplyType", newValue);
  //   setEmplymentTypeInput(newValue);
  // };

  const handleChangeEmplyType = (event) => {
    setEmplymentType(event.target.value);
  };

  // const handleChangeEmplyTypeInput = (event, value) => {
  //   setEmplymentTypeInput(value);
  // };

  const filterEmplyOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return []; // If no input value, return all options
    }
    return options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const [emplymentId, setEmplymentId] = useState(editEmployeesData.employeeId);

  const handleChangeEmplyId = (event) => {
    console.log("handleChangeEmplyId", event.target.value);
    setEmplymentId(event.target.value);
  };

  const [emplymentStatus, setEmplymentStatus] = useState(
    editEmployeesData.empStatus
  );

  const [emplyStatusData, setEmplyStatusData] = useState([]);

  useEffect(() => {
    getStaticDDOfOrg(userDetails.orgId, "EMPLOYMENT_STATUS").then(
      (response) => {
        console.log("EMPLOYMENT_STATUS", response.data);
        setEmplyStatusData(response.data);
      }
    );
  }, []);

  const handleChangeEmplyStatus = (event) => {
    console.log("handleChangeEmplyStatus", event.target.value);
    setEmplymentStatus(event.target.value);
  };

  // const handleChangeEmplyStatusInput = (event , newValue) => {
  //   console.log("handleChangeEmplyStatus", newValue);
  //   setEmplymentStatusInput(newValue);
  // };

  const [emplyeeUnits, setEmplyeeUnits] = useState(editEmployeesData.unitId);
  const [unitsData, setUnitData] = useState([]);

  useEffect(() => {
    getUnits(userDetails.orgId)
      .then((response) => {
        console.log("getUnits", response.data);
        const sortedUnits = response.data.sort((a, b) => a.unitId - b.unitId);
        setUnitData(sortedUnits);
        // setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        // setLoading(false);
      });
  }, [userDetails.orgId]);

  const fetchPositionsOfUnit = (unitId) => {
    getPositionsOfUnit(unitId).then((response) => {
      // allPositionData
      //
      if (response.data) {
        setAllPositionData(response.data);
      }
    });
  };

  const handleChangEemplyeeUnits = (event) => {
    const unitId = event.target.value;
    setEmplyeeUnits(unitId);
    if (unitId) {
      fetchPositionsOfUnit(unitId);
    }
  };

  const [departmentData, setDepartmentData] = useState([]);
  const [emplyeeDepartment, setEmplyeeDepartment] = useState(
    editEmployeesData.functionId
  );

  useEffect(() => {
    getAllDepartment(userDetails.orgId)
      .then((response) => {
        console.log("getAllDepartment", response.data);
        setDepartmentData(response.data);
        // setFilteredDepartmentData(response.data);
        // setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        // setLoading(false);
      });
  }, [userDetails.orgId]);

  const handleChangemplyeeDepartment = (event) => {
    console.log("handleChangemplyeeDepartment", event.target.value);
    setEmplyeeDepartment(event.target.value);
  };

  const [reportingHeadData, setReportingHeadData] = useState([]);
  const [emplyeeReportingHead, setEmplyeeReportingHead] = useState({});
  const [selectedReportHeadId, setSelectedReportHeadId] = useState("");
  const [reportingHeadInputValue, setReportingHeadInputValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    getOrgEmployees(userDetails.orgId)
      .then((response) => {
        console.log("getAllDepartment", response.data);
        setReportingHeadData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
      });
  }, [userDetails.orgId]);

  useEffect(() => {
    console.log("reportingHeadData in UseEffect", reportingHeadData);

    const matchingReportingHead = reportingHeadData.find(
      (head) => head.userId === editEmployeesData.reportingHeadUserId
    );

    console.log("matchingReportingHead", matchingReportingHead);

    if (matchingReportingHead) {
      const obj = {
        userId: matchingReportingHead.userId,
        userName: matchingReportingHead.userName,
        userDtl: matchingReportingHead.userDtl || "",
        profileUrl: matchingReportingHead.profileUrl || "",
        userType: matchingReportingHead.userType || "",
      };
      setEmplyeeReportingHead(obj);
      setSelectedReportHeadId(obj.userId);
    } else {
      setEmplyeeReportingHead({});
      setSelectedReportHeadId("");
    }
  }, [reportingHeadData, editEmployeesData.reportingHeadUserId]);

  // const handleChangemplyeeReportingHead = (event , newInputValue) => {
  //   console.log("handleChangemplyeeDepartment", event.target.value , newInputValue);
  //   setEmplyeeReportingHead(newInputValue);
  // };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  // const filterOptions = (options, { inputValue }) => {
  //   return options.filter(
  //     (option) =>
  //       option.userName.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
  //   );
  // };

  const filterOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return []; // If no input value, return all options
    }
    return options.filter((option) =>
      option.userName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const [emplyeePosition, setEmplyeePosition] = useState("");
  const [allPositionData, setAllPositionData] = useState([]);
  const [emplyeeName, setEmplyeeName] = useState("");

  const [additionalPositions, setAdditionalPositions] = useState([]);

  const [emplyPositionData, setEmplyPositionData] = useState([]);

  useEffect(() => {
    if (editEmployeesData && editEmployeesData.userId) {
      let isMounted = true;

      getUserPositions(editEmployeesData.userId)
        .then((response) => {
          if (isMounted) {
            console.log("response.data", response.data);
            setEmplyPositionData(response.data);
          }
        })
        .catch((error) => {
          if (isMounted) {
            console.error("Error fetching user positions:", error);
          }
        });

      return () => {
        isMounted = false;
      };
    }
  }, [editEmployeesData]);

  console.log("emplyPositionData", emplyPositionData);

  ///////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const primaryPos = emplyPositionData.find(
      (pos) => pos.positionType === "Primary"
    );
    const additionalPos = emplyPositionData.filter(
      (pos) => pos.positionType === "Additional"
    );

    if (primaryPos) {
      setEmplyeePosition(primaryPos.positionId);
      const inputDate = primaryPos?.effectiveDate;
      if (inputDate) {
        let tempDate = new Date();
        const [day, month, year] = inputDate.split("-");
        tempDate.setFullYear(
          parseInt(year),
          parseInt(month) - 1,
          parseInt(day)
        );
        setPrimaryPosdate(tempDate);
      }
    }

    if (additionalPos.length > 0) {
      setAdditionalPositions(
        additionalPos.map((pos) => ({
          id: pos.id,
          positionId: pos.positionId,
          effectiveDate: new Date(
            pos.effectiveDate.split("-").reverse().join("-")
          ),
        }))
      );
    }
  }, [emplyPositionData]);

  ////////////////////////////////////////////////////////////////////////////////////////

  // useEffect(() => {
  //   getPositionsOfOrganization(userDetails.orgId)
  //     .then((response) => {
  //       setAllPositionData(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching positions", error);
  //     });
  // }, [userDetails.orgId]);

  const handleChangEmplyeePosition = (event) => {
    const newValue = event.target.value;
    console.log("handleChangEmplyeePosition", newValue);

    getReportingPositionEmployee(newValue).then((response) => {
      if (response.data) {
        console.log("response data =", response.data);

        if (response.data) {
          setSelectedReportHeadId(response.data.userId);
          setEmplyeeReportingHead(response.data);
        }
      }
    });
    setEmplyeePosition(newValue);
    const selectedPosition = allPositionData.find(
      (position) => position.positionId === newValue
    );
    if (selectedPosition) {
      setEmplyeeName(selectedPosition.position);
      console.log("selectedPosition.position", selectedPosition.position);
    }
  };

  const [additionalPosition, setAdditionalPosition] = useState("");
  // const handleChangadditionalPosition = (event) => {
  //   setAdditionalPosition(event.target.value);
  // };

  const positionDetails = {
    positionId: 0,
    positionType: "",
    effectiveDate: "",
  };

  const [secondPosdate, setSecondPosdate] = useState(new Date());

  // const handleAddPosition = () => {
  //   setAdditionalPositions([...additionalPositions, positionDetails]);
  // };

  const handleAddPosition = () => {
    setAdditionalPositions([...additionalPositions, { ...positionDetails }]);
  };

  // const handleRemovePosition = (index) => {
  //   setAdditionalPositions([...additionalPositions.slice(0, index), ...additionalPositions.slice(index + 1)]);
  // };

  const handleRemovePosition = (index) => {
    const updatedPositions = [...additionalPositions];
    updatedPositions.splice(index, 1);
    setAdditionalPositions(updatedPositions);
  };

  // const handleChangadditionalPosition = (index, event) => {
  //   const newPositions = [...additionalPositions];
  //   newPositions[index].position = event.target.value;
  //   setAdditionalPositions(newPositions);
  // };

  const handleChangadditionalPosition = (index, event) => {
    const positionId = event.target.value;
    const position = allPositionData.find(
      (pos) => pos.positionId === positionId
    );
    const newPositions = [...additionalPositions];
    // const formattedDate = DateUtils.getDateInDDMMYYYY(newPositions[index].date);
    newPositions[index] = {
      ...newPositions[index],
      positionId: position.positionId,
      positionType: "Additional",
      // effectiveDate: formattedDate
    };
    setAdditionalPositions(newPositions);
  };

  // const handleSecondPosdateChange = (index, date) => {
  //   const newPositions = [...additionalPositions];
  //   // const formattedDate = DateUtils.getDateInDDMMYYYY(date);
  //   newPositions[index].effectiveDate = date;
  //   setAdditionalPositions(newPositions);
  // };

  const handleSecondPosdateChange = (index, date) => {
    const newPositions = [...additionalPositions];
    newPositions[index] = {
      ...newPositions[index],
      effectiveDate: date,
    };
    setAdditionalPositions(newPositions);
  };

  console.log("additionalPositions", additionalPositions);

  console.log("emplymentType", emplymentType);

  // console.log("emplymentTypeInput", emplymentTypeInput);

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const finalSubmit = () => {
    setLoading(true);

    let positions = [];

    const primaryPos = allPositionData.find(
      (pos) => pos.positionId === emplyeePosition
    );
    if (primaryPos) {
      console.log("primaryPosition =", primaryPos);
      positions = [
        {
          id:
            emplyPositionData.find(
              (item) => item.positionId === emplyeePosition
            )?.id || 0,
          positionId: emplyeePosition,
          positionType: "Primary",
          effectiveDate: formatDate(primaryPosdate),
          positionName: primaryPos?.position || "",
        },
        ...additionalPositions.map((pos) => ({
          id: pos.id || 0,
          positionId: pos.positionId,
          positionType: "Additional",
          effectiveDate: formatDate(pos.effectiveDate),
          positionName:
            allPositionData.find((p) => p.positionId === pos.positionId)
              ?.position || "",
        })),
      ];
    }

    if (emplyEmail.trim() === "") {
      toast.error("Please Enter the Email", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (!validateEmail(emplyEmail)) {
      toast.error("Please check the Email", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (emplyPhoneNumber.trim() === "") {
      toast.error("Please Enter phone Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (emplyPhoneNumber.length > 15) {
      toast.error("phone Number should be of 15 Digit", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (emplymentType === "" || !emplymentType) {
      toast.error("Please enter salary type");
      setLoading(false);
      return;
    }

    if ((emplymentId && emplymentId === "") || !emplymentId) {
      toast.error("Please enter Employee Id");
      setLoading(false);
      return;
    }

    if (positions.length === 0) {
      toast.error("Please Select Position");
      setLoading(false);
      return;
    }

    const reqDto = {
      title: emplyTitle,
      positions: positions,
      functionId: emplyeeDepartment,
      gender: emplyGender,
      employmentType: emplymentType,
      empStatus: emplymentStatus,
      joiningDate: formatDate(joiningdate),
      officialContactNo: emplyPhoneNumber,
      officialContactCallingNo: emplyPhnCC,
      unitId: emplyeeUnits,
      reportingHeadUserId: selectedReportHeadId,
      userId: editEmployeesData.userId,
      orgId: userDetails.orgId,
      employeeId: emplymentId,
      officialMailId: emplyEmail,
      shiftIds: selectedEmployeeShift,
    };

    console.log("reqDto", reqDto);

    // setLoading(false);
    // return;

    editEmployee(userDetails.userId, reqDto)
      .then((response) => {
        console.log("editEmployee", response.data);
        setLoading(false);
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          refreshViewPeopleData();
          handelCloseEditEmployee();
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#4c4c4c",
      color: "white",
      maxWidth: 300,
      fontSize: "14px",
      border: "1px solid #4c4c4c",
    },
  }));

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">Edit Employee</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseEditEmployee()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              {loading ? (
                <div className="meetingVEContainer">
                  <div className="center-progress" style={{ height: "65vh" }}>
                    <CircularProgress sx={{ marginTop: "180px" }} />
                  </div>
                </div>
              ) : (
                <div className="elementFormContainer">
                  <div class="tskElemHeding">Employee Demography</div>
                  <div className="formElementGrp">
                    <div className="formElement mxW-100">
                      <FormControl className="formControl">
                        <InputLabel id="demo-simple-select-label">
                          Title
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={emplyTitle}
                          label="Title"
                          onChange={handleChangeEmplyTitle}
                          className="formInputField"
                          variant="outlined"
                          // disabled
                        >
                          <MenuItem value="Mr">Mr.</MenuItem>
                          <MenuItem value="Ms">Ms.</MenuItem>
                          <MenuItem value="Mrs">Mrs.</MenuItem>
                          <MenuItem value="Miss">Miss</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label="First name"
                          required
                          placeholder="First Name"
                          variant="outlined"
                          value={emplyFirstName}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyFirstName}
                          disabled
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label="Middle name"
                          placeholder="Middle Name"
                          variant="outlined"
                          value={emplyMiddleName}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyMiddleName}
                          disabled
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          required
                          label="Last name"
                          placeholder="Last Name"
                          variant="outlined"
                          value={emplyLastName}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyLastName}
                          disabled
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div className="formElementGrp">
                    <div className="formElement mxW-100">
                      <FormControl className="formControl">
                        <InputLabel id="demo-simple-select-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={emplyGender}
                          label="Title"
                          onChange={handleChangeEmplyGender}
                          className="formInputField"
                          variant="outlined"
                        >
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label="Official Email Id"
                          required
                          autoComplete="off"
                          placeholder="Official Email Id"
                          variant="outlined"
                          value={emplyEmail}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyEmail}
                          // disabled
                          disabled={editEmployeesData.officialEmailId !== ""}
                        />
                      </FormControl>
                    </div>

                    <div className="formElement mxW-100">
                      <FormControl className="formControl">
                        <InputLabel id="ccode">Country Code</InputLabel>
                        <Select
                          labelId="ccode"
                          id="demo-simple-select"
                          value={emplyPhnCC}
                          label="Country Code"
                          onChange={handleChangeemplyPhnCC}
                          className="formInputField"
                          variant="outlined"
                        >
                          {/* <MenuItem value="91">+91</MenuItem>
                        <MenuItem value="1">+1</MenuItem>
                        <MenuItem value="374">+374</MenuItem> */}
                          {cdCodeList.map((cdCodeList) => (
                            <MenuItem key={cdCodeList} value={cdCodeList}>
                              {cdCodeList}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          required
                          autoComplete="off"
                          label="Official Phone Number"
                          variant="outlined"
                          value={emplyPhoneNumber}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyPhoneNumber}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div class="tskElemHeding mt10">Employement Demography</div>

                  <div className="formElementGrp flex-wrap">
                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel
                          id="employeeJoingDate"
                          className="setCompletionDate"
                        >
                          Joining Date
                        </InputLabel>
                        <ReactDatePicker
                          showIcon
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          labelId="employeeJoingDate"
                          className="formDatePicker"
                          selected={joiningdate}
                          // minDate={new Date()}
                          maxDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            handleJoiningDate(date);
                          }}
                        />
                      </FormControl>
                    </div>

                    {/* <div className="formElement width13">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          required
                          label="Employment Type"
                          placeholder="Employment Type"
                          variant="outlined"
                          value={emplymentType}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyType}
                        />
                      </FormControl>
                    </div> */}

                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel id="selectUnits">Salary Type *</InputLabel>
                        <Select
                          required
                          labelId="selectUnits"
                          id="employee-units"
                          value={emplymentType}
                          label="Salary Type *"
                          onChange={handleChangeEmplyType}
                          className="formInputField"
                          variant="outlined"
                        >
                          {emplyTypes.map((value) => (
                            <MenuItem key={value.id} value={value.id}>
                              {value.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    {/* <div className="formElement width13">
                      <FormControl className="formControl">
                        <Autocomplete
                          disablePortal
                          id="selectEmployee"
                          className="formAutocompleteField"
                          variant="outlined"
                          options={emplyTypes}
                          getOptionLabel={(option) => option}
                          value={emplymentType}
                          inputValue={emplymentTypeInput}
                          onInputChange={handleChangeEmplyTypeInput}
                          filterOptions={filterEmplyOptions}
                          onChange={handleChangeEmplyType}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Salary Type *"
                              variant="outlined"
                              className="formAutoComInputField autocompFildPlaceholder"
                            />
                          )}
                        />
                      </FormControl>
                    </div> */}

                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label="Employee Id"
                          required
                          autoComplete="off"
                          placeholder="Employee Id"
                          variant="outlined"
                          value={emplymentId}
                          className="formTextFieldArea"
                          onChange={handleChangeEmplyId}
                          // disabled
                        />
                      </FormControl>
                    </div>

                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel id="employeeStstus">
                          Employement Status
                        </InputLabel>
                        <Select
                          labelId="employeeStstus"
                          id="employee-Ststus"
                          value={emplymentStatus}
                          label="Employement Status"
                          onChange={handleChangeEmplyStatus}
                          className="formInputField"
                          variant="outlined"
                        >
                          {/* <MenuItem value="In Probation">In Probation</MenuItem>
                          <MenuItem value="Confirmed">Confirmed</MenuItem> */}
                          {emplyStatusData.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                              {status.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel id="selectUnits">Select Unit </InputLabel>
                        <Select
                          labelId="selectUnits"
                          id="employee-units"
                          value={emplyeeUnits}
                          label="Select Unit"
                          onChange={handleChangEemplyeeUnits}
                          className="formInputField"
                          variant="outlined"
                        >
                          <MenuItem key={"null_unit"} value={null}>
                            {"Select Unit"}
                          </MenuItem>
                          {unitsData.map((unit, index) => (
                            <MenuItem key={index} value={unit.unitId}>
                              {unit.unitName}
                            </MenuItem>
                          ))}
                          {/* <MenuItem value="kolkataho">Kolkata HO</MenuItem>
                        <MenuItem value="delhi">Delhi</MenuItem>
                        <MenuItem value="pune">Pune</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>

                    {/* <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel id="employeeDepartment">
                          Department
                        </InputLabel>
                        <Select
                          labelId="employeeDepartment"
                          id="employee-Position"
                          value={emplyeeDepartment}
                          label="Department "
                          onChange={handleChangemplyeeDepartment}
                          className="formInputField"
                          variant="outlined"
                        >
                          
                          {departmentData.map((department) => (
                            <MenuItem
                              key={department.functionId}
                              value={department.functionId}
                            >
                              {department.functionName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div> */}
                  </div>

                  <div class="tskElemHeding mt10">Employee Position</div>

                  <div className="formElementGrp">
                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel id="primaryPosition">
                          Primary Position
                        </InputLabel>
                        <Select
                          labelId="primaryPosition"
                          id="employee-Position"
                          value={emplyeePosition}
                          label="Select Position *"
                          onChange={handleChangEmplyeePosition}
                          className="formInputField"
                          variant="outlined"
                        >
                          {allPositionData.map((position) => (
                            <MenuItem
                              key={position.positionId}
                              value={position.positionId}
                            >
                              {position.position}
                            </MenuItem>
                          ))}

                          {/* <MenuItem value="manager">Manager</MenuItem>
                        <MenuItem value="teamLead">Team lead</MenuItem>
                        <MenuItem value="developer">Developer</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <InputLabel
                          id="primPosition"
                          className="setCompletionDate"
                        >
                          With Effect From
                        </InputLabel>
                        <ReactDatePicker
                          showIcon
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          labelId="primPosition"
                          className="formDatePicker"
                          selected={primaryPosdate}
                          // minDate={new Date()}
                          maxDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            handleJoiningprimaryPosdate(date);
                          }}
                        />
                      </FormControl>
                    </div>

                    <div className="addNewPosition">
                      <HtmlTooltip title="Additional Position">
                        <Button
                          startIcon={<Add />}
                          className="dfultPrimaryBtn"
                          // onClick={() => handelOpenNewPosition()}
                          onClick={handleAddPosition}
                        >
                          <span className="addPosBtnPrm">
                            Additional Position
                          </span>
                        </Button>
                      </HtmlTooltip>
                    </div>
                  </div>

                  {additionalPositions.map((position, index) => (
                    <div key={index} className="formElementGrp">
                      <div className="formElement width13">
                        <FormControl className="formControl">
                          <InputLabel id={`addPosition-${index}`}>
                            Additional Position
                          </InputLabel>
                          <Select
                            required
                            labelId={`addPosition-${index}`}
                            value={position.positionId}
                            label="Select Position"
                            onChange={(event) =>
                              handleChangadditionalPosition(index, event)
                            }
                            className="formInputField"
                            variant="outlined"
                          >
                            {allPositionData.map((position) => (
                              <MenuItem
                                key={position.positionId}
                                value={position.positionId}
                              >
                                {position.position}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="formElement width13">
                        <FormControl className="formControl">
                          <InputLabel
                            id={`secondPosition-${index}`}
                            className="setCompletionDate"
                          >
                            With Effect From
                          </InputLabel>
                          <ReactDatePicker
                            showIcon
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            showMonthDropdown
                            labelId={`secondPosition-${index}`}
                            className="formDatePicker"
                            selected={position.effectiveDate}
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) =>
                              handleSecondPosdateChange(index, date)
                            }
                          />
                        </FormControl>
                      </div>

                      <div className="addNewPosition tblActionBtnGrp">
                        {/* <HtmlTooltip title="Additional Position">
                          <Button
                            startIcon={<Add />}
                            className="dfultPrimaryBtn"
                            onClick={handleAddPosition}
                          >
                            <span className="addPosBtnPrm">
                              Addition Position
                            </span>
                          </Button>
                        </HtmlTooltip> */}
                        <IconButton
                          onClick={() => handleRemovePosition(index)}
                          className="removeRowBtn"
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </div>
                  ))}

                  <div class="tskElemHeding mt10"></div>

                  <div className="formElement ">
                    <FormControl className="formControl">
                      <Autocomplete
                        noOptionsText="Please type to search"
                        // autoHighlight={true}
                        // disablePortal
                        id="selectEmployee"
                        className="formAutocompleteField"
                        variant="outlined"
                        // freeSolo
                        options={reportingHeadData}
                        getOptionLabel={(option) =>
                          option && option.userName && option.userDtl
                            ? `${option.userName}  ( ${option.userDtl} )`
                            : option && option.userName
                            ? `${option.userName} `
                            : ""
                        }
                        value={emplyeeReportingHead}
                        inputValue={inputValue}
                        onInputChange={handleInputChange}
                        filterOptions={filterOptions}
                        onChange={(e, data) => {
                          if (data && data.userId) {
                            setSelectedReportHeadId(data.userId);
                            setEmplyeeReportingHead(data);
                          } else {
                            setSelectedReportHeadId(null);
                            setEmplyeeReportingHead({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="formAutoComInputField autocompFildPlaceholder"
                            {...params}
                            label="Reporting Head"
                            variant="outlined"
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="Employment_Type">Shift</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="Employment_Type"
                        id="EmploymentType"
                        multiple
                        value={selectedEmployeeShift}
                        onChange={handleChangeEmploymentType}
                        input={<OutlinedInput label="Salary Type" />}
                        renderValue={(selected) => {
                          const sList = selectedEmployeeShift.map((shift) => {
                            const index = shiftList.findIndex(
                              (item) => item.shiftId === shift
                            );
                            if (index >= 0) {
                              return shiftList[index].name;
                            }
                            return "";
                          });

                          return sList.join(", ");
                        }}
                        MenuProps={MenuProps}
                      >
                        {shiftList.map((shift) => (
                          <MenuItem key={shift.shiftId} value={shift.shiftId}>
                            <Checkbox
                              checked={
                                selectedEmployeeShift.indexOf(shift.shiftId) >
                                -1
                              }
                            />
                            <ListItemText primary={shift.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              {/* <Button className="dfultPrimaryBtn" onClick={() => finalSubmit()}>
                Save
              </Button> */}
              <Button
                className="dfultPrimaryBtn"
                onClick={() => finalSubmit()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>Submit</>
                )}
              </Button>
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handelCloseEditEmployee()}
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
