import { AttachFile } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Eye, Trash, XLg } from "react-bootstrap-icons";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import { toast } from "react-toastify";
import {
  getEmployees,
  getRightsToSelect,
  addContract,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import {
  VALID_FILE_EXTENSION_MEETING,
  MAX_FILE_SIZE_MEETING,
} from "../../../../constants/const_values";
import { useTranslation } from "react-i18next";

export default function AddContract({ handelCloseAddContract , refreshContractData , fetchDashBoardData}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [contractName, SetContractName] = useState("");
  const [contractResponsiblePerson, setContractResponsiblePerson] =
    useState("");
  const [contractTypeData, setContractTypeData] = useState([]);
  const [contractType, setContractType] = useState("");
  const [contractTypeInput, setContractTypeInput] = useState("");
  const [contractOwnerData, setContractOwnerData] = useState([]);
  const [contractOwner, setContractOwner] = useState("");
  const [contractOwnerInput, setContractOwnerInput] = useState("");
  const holidayImageRef = useRef(null);
  const [fileData_Base64, setFileData_Base64] = useState("");
  const [fileName, setFileName] = useState("");

  const handleContractName = (e) => {
    console.log("handleContractName", e.target.value);
    SetContractName(e.target.value);
  };

  const handleContractResponsiblePerson = (e) => {
    console.log("handleContractResponsiblePerson", e.target.value);
    setContractResponsiblePerson(e.target.value);
  };

  useEffect(() => {
    getRightsToSelect("CONTRACT_TYPE").then((response) => {
      console.log("CONTRACT_TYPE", response.data);
      setContractTypeData(response.data);
    });
  }, []);

  const handleContractType = (e, newValue) => {
    console.log("handleContractType", newValue);
    setContractType(newValue);
  };

  const handleContractTypeInput = (e, newValue) => {
    console.log("handleContractType", newValue);
    setContractTypeInput(newValue);
  };

  useEffect(() => {
    getEmployees(userDetails.orgId)
      .then((response) => {
        console.log("getEmployees", response.data);
        setContractOwnerData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
      });
  }, []);

  const handleContractOwner = (e, newValue) => {
    console.log("handleContractOwner", newValue);
    setContractOwner(newValue);
  };

  const handleContractOwnerInput = (e, newValue) => {
    console.log("handleContractOwnerInput", newValue);
    setContractOwnerInput(newValue);
  };

  const filterEmplyOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return [];
    }
    return options.filter((option) =>
      option.personName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  // const handleImageUpload = async (e) => {
  //
  //   console.log(e);
  //   if (!e.target.files) {
  //     return;
  //   }
  //   const file = e.target.files[0];
  //   console.log("handleUploadOrgLogo", file);
  //   setFileData_Base64(await convertFileToBase64_Split(file));

  //   setFileName(file.name);
  // };

  //   const handleImageUpload = async (e) => {
  //     console.log(e);
  //     if (!e.target.files) {
  //       return;
  //     }
  //     const file = e.target.files[0];
  //     console.log("handleUploadOrgLogo", file);
  //     const imageValue = await convertFileToBase64_Split(file);
  //     // setFileData_Base64(`data:image/png;base64,${imageValue}`)
  //     setFileData_Base64(await convertFileToBase64_Split(file));
  //     setFileName(file.name);
  //   };

  const handleImageUpload = async (e) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    console.log("handleUploadOrgLogo", file);

    if (file.size > MAX_FILE_SIZE_MEETING * 1024 * 1024) {
      toast.error("File size cannot exceed 5MB", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setFileData_Base64(await convertFileToBase64_Split(file));
    setFileName(file.name);
  };

  const handleDeleteFile = () => {
    setFileName("");
    setFileData_Base64("");
  };

  const finalSubmit = () => {
    console.log("final submit");
    setLoading(true);

    console.log("contractOwner", contractOwner);
    console.log("contractOwnerInput", contractOwnerInput);

    let contractOwnerUserId = "";
    let contractOwnerUserName = "";

    const contractOwnerName = contractOwnerData.find(
      (owner) => owner.personName === contractOwnerInput
    );

    if (contractOwnerName) {
      contractOwnerUserId = contractOwnerName.userId;
      contractOwnerUserName = contractOwnerName.personName;
    }

    const contractActualType = contractTypeInput !== "" ? contractTypeInput : contractType
    
    if (contractName.trim() === "") {
        toast.error("Please Enter Contract Name", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      };

    if (contractResponsiblePerson.trim() === "") {
        toast.error("Please Enter Responsible Person", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
    }

    if (typeof contractActualType !== 'string' || contractActualType.trim() === "") {
      toast.error("Please Enter Contract Type", {
          position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
  }
  
    if (contractOwnerUserName.trim() === "") {
        toast.error("Please Enter Contract Owner", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
    }


    const reqDto = {
      id: 0,
      contractName: contractName,
      orgId: userDetails.orgId,
      contractType: contractActualType,
      responsiblePerson: contractResponsiblePerson,
      contractOwnerUserId: contractOwnerUserId,
      contractOwnerUserName: contractOwnerUserName,
      fileName: fileName,
      fileDataLink: fileData_Base64,
    };
    console.log("reqDto", reqDto);

    addContract(userDetails.userId, reqDto).then((response) => {
      console.log("addContract", response.data);
      if (response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchDashBoardData();
        refreshContractData();
        setLoading(false);
        SetContractName("");
        setContractType("");
        setContractTypeInput("");
        setContractOwner("");
        setFileData_Base64("");
        setFileName("");
        setContractResponsiblePerson("");
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("add_contract")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddContract()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            {loading ? (
              <div className="meetingVEContainer">
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              </div>
            ) : (
              <div className="rfContContainDetails">
                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={t("contract_name")}
                        required
                        variant="outlined"
                        value={contractName}
                        className="formTextFieldArea"
                        onChange={handleContractName}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={t("responsible_person_name")}
                        required
                        variant="outlined"
                        value={contractResponsiblePerson}
                        className="formTextFieldArea"
                        onChange={handleContractResponsiblePerson}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        disablePortal
                        id="selectEmployee"
                        className="formAutocompleteField"
                        variant="outlined"
                        options={contractTypeData}
                        getOptionLabel={(option) => option.name || ""}
                        value={contractType}
                        inputValue={contractTypeInput}
                        onInputChange={handleContractTypeInput}
                        //   filterOptions={filterEmplyOptions}
                        onChange={handleContractType}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("contract_type")}
                            required
                            variant="outlined"
                            className="formAutoComInputField autocompFildPlaceholder"
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  {/* <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="SelectActivity">Contract Owner</InputLabel>
                    <Select
                      labelId="SelectActivity"
                      id="Select-Activity"
                      value={contractOwner}
                      label="Select Activity"
                      onChange={handleContractOwner}
                      className="formInputField"
                      variant="outlined"
                    >
                      {contractOwnerData.map((contractOwner) => (
                        <MenuItem
                          key={contractOwner.userId}
                          value={contractOwner.userId}
                        >
                          {contractOwner.personName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div> */}

                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        noOptionsText="No option found"
                        disablePortal
                        id="selectEmployee"
                        className="formAutocompleteField"
                        variant="outlined"
                        options={contractOwnerData}
                        getOptionLabel={(option) => option.personName || ""}
                        value={contractOwner}
                        inputValue={contractOwnerInput}
                        onInputChange={handleContractOwnerInput}
                        filterOptions={filterEmplyOptions}
                        onChange={handleContractOwner}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("contract_owner")}
                            required
                            variant="outlined"
                            className="formAutoComInputField autocompFildPlaceholder"
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <div className="formBtnElement">
                    <Button
                      variant="outlined"
                      startIcon={<AttachFile />}
                      className="comBtnOutline"
                      onClick={() => holidayImageRef.current.click()}
                    >
                      {t("attach_file")}
                    </Button>
                    <input
                      ref={holidayImageRef}
                      type="file"
                      accept={VALID_FILE_EXTENSION_MEETING}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleImageUpload(e);
                        holidayImageRef.current.value = null;
                      }}
                    />
                  </div>
                  {fileName && (
                    <div class="elementListGrp">
                      <ul class="elmListInfo">
                        <li>
                          <span className="rolsNm">{fileName}</span>
                          <IconButton
                            className="rmvBtn"
                            onClick={handleDeleteFile}
                          >
                            <Trash />
                          </IconButton>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                disabled={loading}
                onClick={() => finalSubmit()}
              >
                {" "}
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>{t("submit")}</>
                )}
              </Button>
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handelCloseAddContract()}
                >
                  {t("cancel")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
