import {
  Add,
  ExpandMoreRounded,
  MonitorHeartRounded,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { v4 as uuidv4 } from "uuid";
import { getHealthDto, MAX_SUGGESTED_RESULT } from "./consultationUtil";
import { toast } from "react-toastify";

export default function Symptoms({ updateData, data, extraData }) {
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [severity, setSeverity] = useState("");
  const [since, setSince] = useState("");
  const [selectedSymptom, setSelectedSymptom] = useState(null);
  const [symptomSearchString, setSymptomSearchString] = useState("");

  const [symptomList, setSymptomList] = useState([]);

  const [symptomSinceList, setSymptomSinceList] = useState([]);
  const [severityList, setSeverityList] = useState([]);

  const [userSymptomList, setUserSymptomList] = useState([]);

  const initializeForm = () => {
    setSeverity("");
    setSince("");
    setSelectedSymptom(null);
    setSymptomSearchString("");
  };

  const symptomString = useMemo(() => {
    const str =
      userSymptomList.length > 0
        ? userSymptomList
            .map(
              (item) =>
                `${item.selectedSymptom.symptomName}(${item.severity}, ${item.since} )`
            )
            .join(", ")
        : "";
    return str;
  }, [userSymptomList]);

  const getFilteredList = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      return data.slice(0, MAX_SUGGESTED_RESULT);
    }
    const startsWithList = [];
    const containsList = [];

    data.forEach((element) => {
      if (
        element.symptomName.toLowerCase().startsWith(searchString.toLowerCase())
      ) {
        startsWithList.push(element);
      } else if (
        element.symptomName.toLowerCase().includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });
    return [...startsWithList, ...containsList];
  };

  const suggestedSymptopList = useMemo(() => {
    const symptomIdsList = userSymptomList.map(
      (item) => item.selectedSymptom.id
    );
    let filteredData = symptomList.filter((symptom) => {
      let symptomVal = "C";
      if (extraData.queuePatientGender === "Male") {
        symptomVal = "M";
      }
      if (extraData.queuePatientGender === "Female") {
        symptomVal = "F";
      }
      const alreadyTaken = symptomIdsList.some((id) => id === symptom.id);
      return (
        !alreadyTaken &&
        (symptom.gender === symptomVal || symptom.gender === "C")
      );
    });
    const filteredList = getFilteredList(filteredData, symptomSearchString);
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [symptomSearchString, symptomList, userSymptomList]);

  const handleChangeSince = (event) => {
    setSince(event.target.value);
  };

  const handleChangeSeverity = (event) => {
    setSeverity(event.target.value);
  };

  useEffect(() => {
    if (!data.symptoms) {
      if (extraData && extraData.reqValue && extraData.reqValue.symptoms) {
        const tempArray = extraData.reqValue.symptoms.map((item) => {
          let tempSymptom = {
            id: item.symptomId,
            symptomName: item.symptomName,
          };

          const temp = {
            selectedSymptom: tempSymptom,
            severity: item.severity,
            since: item.since,
          };
          return temp;
        });

        setUserSymptomList((prev) => {
          const temp = [...prev, ...tempArray];
          updateData({ symptoms: temp });
          return temp;
        });
      }
    }

    if (data.symptoms) {
      setUserSymptomList(data.symptoms);
    }

    getSymptomSince();
    getSymptomSeverity();
    getSymptoms();
  }, []);

  const handleAddSymptom = () => {
    if (!symptomSearchString || symptomSearchString === "") {
      toast.error("Please select symptom");
      return;
    }
    if (!severity || severity === "") {
      toast.error("Please Select severity");
      return;
    }
    // if (!since || since === "") {
    //   toast.error("Please Select since");
    //   return;
    // }
    let tempSymptom = selectedSymptom;
    if (!tempSymptom) {
      tempSymptom = {
        id: uuidv4(),
        symptomName: symptomSearchString,
        locallyAdded: true,
      };
    }
    const dto = {
      selectedSymptom: tempSymptom,
      severity,
      since,
    };
    setUserSymptomList((prev) => {
      const temp = [...prev, dto];
      updateData({ symptoms: temp });
      return temp;
    });
    initializeForm();
  };

  const getSymptomSince = () => {
    const reqDto = getHealthDto();
    DashboardServices.getSymptomSince(reqDto).then((response) => {
      if (response.data) {
        setSymptomSinceList(response.data);
      }
    });
  };

  const getSymptomSeverity = () => {
    const reqDto = getHealthDto();
    DashboardServices.getSymptomSeverity(reqDto).then((response) => {
      if (response.data) {
        setSeverityList(response.data);
      }
    });
  };

  const getSymptoms = () => {
    const symptomString = localStorage.getItem("symptomList");
    if (symptomString) {
      setSymptomList(JSON.parse(symptomString));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getSymptoms(reqDto).then((response) => {
      if (response.data) {
        setSymptomList(response.data);
        localStorage.setItem("symptomList", JSON.stringify(response.data));
      }
    });
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            Symptoms:
            {!accordionExpanded && <span>{symptomString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="complaintList">
            <ul>
              {userSymptomList &&
                userSymptomList.map((symptom, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp">
                        <div className="complstDtl  ">
                          <div className="compntName">
                            {symptom.selectedSymptom.symptomName}
                          </div>
                          <div className="compinfoGRP">
                            <div className="compSeverity">
                              {/* <span>Severity:</span> */}
                              <span className="data">{symptom.severity}</span>
                            </div>
                            <div className="compStime">
                              {/* <span>Since:</span> */}
                              <span className="data">{symptom.since}</span>
                            </div>
                          </div>
                        </div>
                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...userSymptomList];
                            temp.splice(index, 1);
                            updateData({ symptoms: temp });
                            setUserSymptomList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </li>
                  );
                })}

              {/* add new symptoms */}
              <li>
                <div className="addNewformGrp">
                  <div className="formElement">
                    {/* <FormControl className="formControl">
                      <TextField
                        label="Symptoms"
                        required
                        autoComplete="off"
                        placeholder="Enter Symptoms"
                        variant="outlined"
                        className="formTextFieldArea"
                      />
                    </FormControl> */}

                    <FormControl className="formControl">
                      <Autocomplete
                        freeSolo
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedSymptom}
                        options={suggestedSymptopList}
                        inputValue={symptomSearchString}
                        onChange={(e, newValue) => {
                          setSelectedSymptom(newValue);
                        }}
                        onInputChange={(e, value, reason) => {
                          if (e && reason === "input") {
                            console.log("changed input =", value);
                            setSymptomSearchString(e.target.value);
                          } else if (reason === "reset") {
                            setSymptomSearchString(value);
                          } else if (reason === "clear") {
                            setSymptomSearchString(value);
                          }
                          // else {
                          //   setSymptomSearchString("");
                          // }
                        }}
                        getOptionLabel={(option) => option.symptomName}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {`${item.symptomName} (${item.gender})`}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder="Symptom Name*"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel id="severity-select-label">
                        Severity*
                      </InputLabel>
                      <Select
                        labelId="severity-select-label"
                        id="severity-select"
                        value={severity}
                        label="Severity"
                        onChange={handleChangeSeverity}
                        className="formInputField"
                        variant="outlined"
                      >
                        {severityList &&
                          severityList.map((item) => {
                            return (
                              <MenuItem value={item} key={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel id="since-select-label">Since</InputLabel>
                      <Select
                        labelId="since-select-label"
                        id="since-select"
                        value={since}
                        label="Since"
                        onChange={handleChangeSince}
                        className="formInputField"
                        variant="outlined"
                      >
                        {symptomSinceList &&
                          symptomSinceList.map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                      </Select>
                    </FormControl>
                  </div>
                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    onClick={handleAddSymptom}
                  >
                    Add
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
