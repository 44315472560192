import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class WebsiteTemplateService {
  getLatestOrgCreatedDtls(userId) {
    return http.get(`/org/getLatestOrgCreatedDtls/${userId}`, {
      headers: authHeader(),
    });
  }

  selectWebsiteTemplate(reqDto) {
    return http.put(`/org/selectWebsiteTemplate`, reqDto, {
      headers: authHeader(),
    });
  }

  getAllWebsiteTemplates() {
    return http.get(`/misc/getWebsiteTemplates`, {
      headers: authHeader(),
    });
  }

  changeWebsiteTemplate(reqDto) {
    return http.post(`/org/changeWebsiteTemplate`, reqDto, {
      headers: authHeader(),
    });
  }
}
export default new WebsiteTemplateService();
