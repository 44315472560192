import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { getLoginUserDetails } from "../../../utils/Utils";
import RightSideMeetingsContainer from "../../dashboard/view/component/right-panel/RightSideMeetingsContainer";
export default function SchRightMeetingDtlsContainer({
  selectedData,
  setSelectedData,
  closeFromDashboard,
  setEventData,
}) {
  return (
    <>
      <div
        className="SchduleRightFlotingContainer"
        style={{ width: "30vw !important" }}
      >
        <div className="flotingAreaContainer">
          <RightSideMeetingsContainer
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            closeFromDashboard={closeFromDashboard}
            setEventData={setEventData}
          />
        </div>
      </div>
    </>
  );
}
