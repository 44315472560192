import React, { useEffect, useMemo, useState } from "react";
import ConvenientTimeView from "../../../../scheduler/view/component/calendar/ConvenientTimeView";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { Box, Button, Modal } from "@mui/material";

function CreateConvenientMeetingWrapper({
  selectedUsers,
  handleClose,
  hideForm,
  setHideForm,
  setSelectedConvenientTime,
}) {
  const loginUserDetail = getLoginUserDetails();
  const [currentWeek, setCurrentWeek] = React.useState([]);
  const [slotDuration, setSlotDuration] = React.useState(15);
  const [startTime, setStartTime] = React.useState(0); //0 for 12:00 AM
  const [endTime, setEndTime] = React.useState(24); //23 for 11:00 PM
  const [rows, setRows] = React.useState([
    ...Array((endTime - startTime) * (60 / slotDuration)).keys(),
  ]); //23 for 11:00 PM
  const [cols, setCols] = React.useState([...Array(currentWeek.length).keys()]); //23 for 11:00 PM
  const [weekStartDay, setWeekStartDay] = React.useState(1);

  const [defaultWorkWeek, setDefaultWorkWeek] = React.useState([
    {
      id: 1,
      day: `Monday`,
    },
    {
      id: 2,
      day: `Tuesday`,
    },
    {
      id: 3,
      day: `Wednesday`,
    },
    {
      id: 4,
      day: `Thursday`,
    },
    {
      id: 5,
      day: `Friday`,
    },
  ]);

  const [meetingStartTime, meetingEndTime] = useMemo(() => {
    let time = new Date();
    let minute = time.getMinutes();
    let hour = time.getHours();
    if (minute < 15) {
      minute = 15;
    } else if (minute < 30) {
      minute = 30;
    } else if (minute < 45) {
      minute = 45;
    } else {
      minute = 0;
      hour = (hour + 1) % 24;
    }
    time.setHours(hour);
    time.setMinutes(minute);

    const meetingEndTime = new Date(time.getTime() + 30 * 60000);
    return [time, meetingEndTime];
  }, []);

  useEffect(() => {
    console.log("createConvenient meeting modal opened");
    const selectedDate = new Date();

    let day = selectedDate.getDay();
    let diff = null;
    if (weekStartDay > day) {
      diff =
        selectedDate.getDate() - day - (day === 10 ? -6 : 7 - weekStartDay);
    } else {
      diff = selectedDate.getDate() - day + (day === 10 ? -6 : weekStartDay);
    }
    let currentWeekTemp2 = [];
    let tempDate = new Date(new Date());
    currentWeekTemp2.push(new Date(tempDate.setDate(diff)));
    for (let counter = 0; counter < 6; counter++) {
      var date = new Date(currentWeekTemp2[counter]);
      date.setDate(currentWeekTemp2[counter].getDate() + 1);
      currentWeekTemp2.push(date);
    }
    console.log("currentWeek empty useEffect", currentWeekTemp2);

    setCurrentWeek(currentWeekTemp2);
    setCols([...Array(currentWeekTemp2.length).keys()]);
  }, []);

  return (
    <>
      <ConvenientTimeView
        usersList={selectedUsers.map((user) => user.userId)}
        meetingStartTime={meetingStartTime}
        meetingEndTime={meetingEndTime}
        // meetingStartTime={new Date()}
        // meetingEndTime={new Date()}
        loginUser={loginUserDetail}
        startTime={startTime}
        endTime={endTime}
        rows={rows}
        slotDuration={slotDuration}
        cols={cols}
        defaultWorkWeek={defaultWorkWeek}
        viewForm={(data) => {
          if (data === "select") {
            setHideForm(false);
          } else {
            setHideForm(!hideForm);
          }
        }}
        hideForm={hideForm}
        setSelectedConvenientTime={setSelectedConvenientTime}
        handleClose={handleClose}
      />
    </>
  );
}

export default CreateConvenientMeetingWrapper;
