import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import {
  getWorksDetailOnWeekendHoliday,
  submitWorksDetailOnWeekendHoliday,
} from "../../../../../services/SchedulerService";
import { Cancel } from "@mui/icons-material";

function WorkOnHoliDay({ openDialog, dated, closeDialog, loginUser }) {
  // const userDetails = loginUser
  const [dataList, setDataList] = useState([]);
  const [radioBoxList, setRadioBoxList] = useState([]);
  const [selectedData, setSelectedData] = useState("");

  useEffect(() => {
    if (openDialog) {
      getWorksDetailOnWeekendHoliday(loginUser.userId, dated).then(
        (response) => {
          if (response.data) {
            if (response.data.length > 0) {
              let tmpRadioList = [];
              response.data.forEach((data) => {
                tmpRadioList.push(data.radio);
              });
              setRadioBoxList(tmpRadioList);
            } else {
              setRadioBoxList([]);
            }
            setDataList(response.data);
          }
        }
      );
    }
  }, [openDialog]);

  const handleClickSubmit = () => {
    const val = JSON.parse(selectedData);
    if (val) {
      const { radio, text, leaveRuleId } = val;
      const reqDto = {
        radio,
        text,
        leaveRuleId,
      };
      submitWorksDetailOnWeekendHoliday(
        loginUser.orgId,
        loginUser.userId,
        dated,
        reqDto
      ).then((response) => {
        if (response.data) {
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            closeDialog();
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    }
  };

  return (
    <>
      <div
        role="button"
        style={{ cursor: "pointer" }}
        className="closeDilog"
        onClick={closeDialog}
      >
        <Cancel />
      </div>
      <Grid
        container
        spacing={1}
        mb={1}
        mt={1}
        className="addLeaveRuleContainer"
      >
        {dataList.length > 0 && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {dataList.map((item) => {
                  return (
                    <>
                      <FormControlLabel
                        control={
                          <>
                            {item.radio === "Y" ? (
                              <Checkbox
                                key={JSON.stringify(item)}
                                checked={JSON.stringify(item) === selectedData}
                                onClick={() => {
                                  console.log(
                                    "setSelectedData",
                                    JSON.stringify(item)
                                  );
                                  setSelectedData(JSON.stringify(item));
                                }}
                              />
                            ) : (
                              <span></span>
                            )}
                          </>
                        }
                        label={
                          <>
                            <p>{item.text}</p>
                          </>
                        }
                      />
                    </>
                  );
                })}
              </FormControl>
            </Grid>
          </>
        )}

        {selectedData !== "" &&
          radioBoxList.length > 1 &&
          !radioBoxList.includes("N") && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <Button
                  className="btn primaryBtn mt30"
                  onClick={handleClickSubmit}
                >
                  submit
                </Button> */}
                <Button
                  className="dfultPrimaryBtn"
                  onClick={handleClickSubmit}
                  // ref={saveBtnRef}
                >
                  Submit
                </Button>
              </div>
            </Grid>
          )}
      </Grid>
    </>
  );
}

export default WorkOnHoliDay;
