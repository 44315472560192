import axios from "axios";
import { apiEndPoint } from "../constants/url_provider";

const http = axios.create({
  baseURL: apiEndPoint,
  headers: {},
});

http.defaults.headers = {
  "Content-Type": "application/json",
};

export default http;
