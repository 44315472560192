import React, { useEffect, useState, forwardRef } from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

const IntegerTextField = forwardRef(
  ({ value, onChange, defaultValue = "", ...props }, ref) => {
    const [localValue, setLocalValue] = useState(defaultValue);
    const integerRegEx = /^\d*$/;

    useEffect(() => {
      if (checkValue(value)) {
        let temp = value !== "" ? parseInt(value, 10) : "";
        setLocalValue(temp);
      }
    }, [value]);

    const checkValue = (value) => {
      return integerRegEx.test(value);
    };

    const handleChange = (event) => {
      const newValue = event.target.value;
      if (checkValue(newValue)) {
        let temp = newValue !== "" ? parseInt(newValue, 10) : "";
        setLocalValue(temp);
        onChange(temp);
      }
    };

    return (
      <TextField
        value={localValue}
        type="text"
        {...props}
        onChange={handleChange}
        inputRef={ref}
      />
    );
  }
);

IntegerTextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IntegerTextField;
