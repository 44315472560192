import {
  Add,
  AssignmentTurnedInRounded,
  Biotech,
  Edit,
  ExpandMoreRounded,
  MonitorHeartRounded,
  Save,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Download, Trash } from "react-bootstrap-icons";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { getHealthDto, MAX_SUGGESTED_RESULT } from "./consultationUtil";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export default function Diagnosis({ updateData, data, extraData }) {
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [diseasesStatus, setDiseasesStatus] = useState("");
  const [selectedDisease, setSelectedDisease] = useState(null);
  const [diseaseInputString, setDiseaseInputString] = useState("");
  const [diseaseList, setDiseaseList] = useState([]);
  const [userDiseaseList, setUserDiseaseList] = useState([]);

  const [userPossbileDiseaseList, setUserPossibleDiseaseList] = useState([]);
  const [userDiagonesisList, setUserDiagonesisList] = useState([]);

  const getFilteredList = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      return data.slice(0, MAX_SUGGESTED_RESULT);
    }
    const startsWithList = [];
    const containsList = [];

    data.forEach((element) => {
      if (
        element.diseaseName.toLowerCase().startsWith(searchString.toLowerCase())
      ) {
        startsWithList.push(element);
      } else if (
        element.diseaseName.toLowerCase().includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });
    return [...startsWithList, ...containsList];
  };

  const initializeForm = () => {
    setDiseasesStatus("");
    setSelectedDisease(null);
    setDiseaseInputString("");
  };

  const handleDeleteDisease = (disease) => {
    const { isProvisional, posIndex } = disease;
    let temp = [];

    if (isProvisional) {
      temp = [...userPossbileDiseaseList];
      temp[posIndex].showInDiagonesis = false;
    } else {
      temp = [...userDiagonesisList];
      temp.splice(posIndex, 1);
    }

    if (isProvisional) {
      updateData({ possibleDiagonesis: temp });
      setUserPossibleDiseaseList(temp);
    } else {
      setUserDiagonesisList(temp);
      updateData({ diagonesis: temp });
    }
  };

  const suggestedDiseaseList = useMemo(() => {
    const filteredList = getFilteredList(diseaseList, diseaseInputString);
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [diseaseInputString, diseaseList]);

  const diagonesisString = useMemo(() => {
    const str =
      userDiseaseList.length > 0
        ? userDiseaseList
            .map((item) => `${item.diseaseObj.diseaseName} (${item.status})`)
            .join(", ")
        : "";
    return str;
  }, [userDiseaseList]);

  useEffect(() => {
    // if (!data || !data.diagonesis) {
    //   if (extraData && extraData.reqValue && extraData.reqValue.diseases) {
    //     const tempArray = extraData.reqValue.diseases.map((item, index) => {
    //       let tempDisease = {
    //         id: uuidv4(),
    //         diseaseName: item.diseaseName,
    //         icd11code: item.icd11code,
    //         snowmedCt: item.snowmedCt,
    //       };

    //       const temp = {
    //         diseaseObj: tempDisease,
    //         status: item.nature,
    //         isProvisional: false,
    //         posIndex: userDiagonesisList.length + index,
    //       };
    //       return temp;
    //     });

    //     setUserDiagonesisList((prev) => {
    //       const temp = [...prev, ...tempArray];
    //       updateData({ diagonesis: temp });
    //       return temp;
    //     });
    //   }
    // }

    getDiseases();
  }, []);

  useEffect(() => {
    if (data.possibleDiagonesis && data.possibleDiagonesis.length > 0) {
      setUserPossibleDiseaseList(data.possibleDiagonesis);
    }

    if (data.diagonesis) {
      setUserDiagonesisList(data.diagonesis);
    }
  }, [data]);

  useEffect(() => {
    let userPossibleDiseaseTemp = [];
    let userDiagonesisTemp = [];
    if (userPossbileDiseaseList && userDiagonesisList) {
      userPossibleDiseaseTemp = userPossbileDiseaseList
        .filter((item) => item.showInDiagonesis === true)
        .map((item, index) => {
          return {
            diseaseObj: {
              id: item.id,
              diseaseName: item.diseaseName,
              icd11code: item.icd11code,
              snowmedCt: item.snowmedCt,
              locallyAdded: item.locallyAdded,
            },
            showInDiagonesis: item.showInDiagonesis,
            status: item.status,
            isProvisional: true,
            posIndex: index,
            isEditing: false,
            updateData: { status: item.status },
          };
        });

      userDiagonesisTemp = userDiagonesisList.map((item, index) => {
        return {
          ...item,
          showInDiagonesis: true,
          isProvisional: false,
          posIndex: index,
          isEditing: false,
          updateData: { status: item.status },
        };
      });
    }

    setUserDiseaseList([...userPossibleDiseaseTemp, ...userDiagonesisTemp]);
  }, [userPossbileDiseaseList, userDiagonesisList]);

  const getDiseases = () => {
    const diseasetring = localStorage.getItem("diseaseList");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);
        localStorage.setItem("diseaseList", JSON.stringify(response.data));
      }
    });
  };

  const handleChangeDiseasesStatus = (event) => {
    setDiseasesStatus(event.target.value);
  };

  const handleUpdateStatus = (event, index) => {
    const temp = [...userDiseaseList];
    temp[index].updateData.status = event.target.value;
    setUserDiseaseList(temp);
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            Diagnosis:
            {!accordionExpanded && <span>{diagonesisString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="complaintList">
            <ul>
              {userDiseaseList &&
                userDiseaseList.map((disease, index) => {
                  return (
                    <li>
                      {!disease.isEditing && (
                        <div className="complstDtlGrp">
                          <div className="complstDtl  ">
                            <div className="compntName">
                              {`${disease.diseaseObj.diseaseName} [ ${
                                disease.diseaseObj.snowmedCt
                                  ? `SNOWMED ${disease.diseaseObj.snowmedCt} ,`
                                  : ""
                              } ICD ${disease.diseaseObj.icd11code} ]`}
                            </div>
                            {!disease.isEditing && (
                              <div className="compinfoGRP">
                                <div className="compStime">
                                  {/* <span>Status:</span> */}
                                  <span className="data">{disease.status}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {!disease.isEditing && (
                            <IconButton
                              className="removeItemBtn"
                              onClick={(e) => {
                                const temp = [...userDiseaseList];
                                temp[index].isEditing = true;
                                const diseaseTemp = diseaseList.find(
                                  (item) => item.id === disease.diseaseObj.id
                                );
                                if (diseaseTemp) {
                                  temp[index].updateData = {
                                    updatedDisease: diseaseTemp,
                                    updatedInput: diseaseTemp.diseaseName,
                                    status: disease.status,
                                  };
                                } else {
                                  temp[index].updateData = {
                                    updatedDisease: null,
                                    updatedInput:
                                      disease.diseaseObj.diseaseName,
                                    status: disease.status,
                                  };
                                }
                                setUserDiseaseList(temp);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          )}
                          {/* {disease.isEditing && (
                            <IconButton
                              className="removeItemBtn"
                              onClick={(e) => {
                                const temp = [...userDiseaseList];
                                temp[index].isEditing = false;
                                setUserDiseaseList(temp);
                              }}
                            >
                              <Download />
                            </IconButton>
                          )} */}
                          <IconButton
                            className="removeItemBtn"
                            onClick={() => {
                              handleDeleteDisease(disease);
                              // const { isProvisional, posIndex } = disease;
                              // let temp = [];

                              // if (isProvisional) {
                              //   temp = [...userPossbileDiseaseList];
                              // } else {
                              //   temp = [...userDiagonesisList];
                              // }
                              // temp.splice(posIndex, 1);

                              // if (isProvisional) {
                              //   updateData({ possibleDiagonesis: temp });
                              //   setUserPossibleDiseaseList(temp);
                              // } else {
                              //   const tempDiagonesisList = [...userDiseaseList];
                              //   tempDiagonesisList.splice(posIndex, 1);

                              //   const updateTemp = tempDiagonesisList.map(
                              //     (item) => {
                              //       const {
                              //         isProvisional,
                              //         posIndex,
                              //         diseaseObj,
                              //         status,
                              //       } = item;
                              //       return { diseaseObj, status };
                              //     }
                              //   );
                              //   setUserDiseaseList(tempDiagonesisList);
                              //   updateData({ diagonesis: updateTemp });
                              //   setUserDiagonesisList(temp);
                              // }
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      )}

                      {disease.isEditing && (
                        <div className="complstDtlGrp">
                          <div className="addNewformGrp">
                            <div className="formElement">
                              <span>{disease.diseaseObj.diseaseName}</span>
                            </div>

                            <div className="formElement mxW-150">
                              <FormControl className="formControl">
                                <InputLabel id="since-select-label">
                                  Status
                                </InputLabel>
                                <Select
                                  labelId="since-select-label"
                                  id="since-select"
                                  value={disease.updateData.status}
                                  label="Status"
                                  onChange={(e) => {
                                    handleUpdateStatus(e, index);
                                  }}
                                  className="formInputField"
                                  variant="outlined"
                                >
                                  <MenuItem value="Provisional">
                                    Provisional{" "}
                                  </MenuItem>
                                  <MenuItem value="Confirm">Confirm</MenuItem>
                                  <MenuItem value="Prognosis">
                                    Prognosis
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          {disease.isEditing && (
                            <IconButton
                              className="removeItemBtn"
                              onClick={(e) => {
                                handleDeleteDisease(disease);
                                // let temp = [];
                                // if (disease.isProvisional) {
                                //   temp = [...userPossbileDiseaseList];
                                // } else {
                                //   temp = [...userDiagonesisList];
                                // }
                                // temp[disease.posIndex].isEditing = false;
                                // temp[disease.posIndex].status =
                                //   disease.updateData.status;
                                // temp.updateData = undefined;

                                // if (disease.isProvisional) {
                                //   setUserPossibleDiseaseList(temp);
                                //   updateData({ possibleDiagonesis: temp });
                                // } else {
                                //   setUserDiseaseList(temp);
                                //   updateData({ diagonesis: temp });
                                // }
                              }}
                            >
                              <Save />
                            </IconButton>
                          )}
                          <IconButton
                            className="removeItemBtn"
                            onClick={() => {
                              handleDeleteDisease();
                              // ;
                              // const { isProvisional, posIndex } = disease;
                              // let temp = [];

                              // if (isProvisional) {
                              //   temp = [...userPossbileDiseaseList];
                              // } else {
                              //   temp = [...userDiagonesisList];
                              // }
                              // temp.splice(posIndex, 1);

                              // if (isProvisional) {
                              //   updateData({ possibleDiagonesis: temp });
                              //   setUserPossibleDiseaseList(temp);
                              // } else {
                              //   setUserDiagonesisList(temp);
                              //   updateData({ diagonesis: temp });
                              // }
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      )}
                    </li>
                  );
                })}

              {/* add new symptoms */}
              <li>
                <div className="addNewformGrp">
                  <div className="formElement">
                    <FormControl className="formControl">
                      {/* <TextField
                        // hiddenLabel
                        label="Diseases Name"
                        required
                        autoComplete="off"
                        placeholder="Search Diseases Name"
                        variant="outlined"
                        className="formTextFieldArea"
                      /> */}

                      <Autocomplete
                        freeSolo
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedDisease}
                        options={suggestedDiseaseList}
                        inputValue={diseaseInputString}
                        onChange={(e, newValue) => {
                          setSelectedDisease(newValue);
                        }}
                        onInputChange={(e, value, reason) => {
                          // if (e && e.target) {
                          //   setDiseaseInputString(e.target.value);
                          // }
                          setDiseaseInputString(value);
                          // else {
                          //   setSymptomSearchString("");
                          // }
                        }}
                        getOptionLabel={(option) => option.diseaseName}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {item.diseaseName}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder="Disease Name"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel id="since-select-label">Status</InputLabel>
                      <Select
                        labelId="since-select-label"
                        id="since-select"
                        value={diseasesStatus}
                        label="Status"
                        onChange={handleChangeDiseasesStatus}
                        className="formInputField"
                        variant="outlined"
                      >
                        <MenuItem value="Provisional">Provisional </MenuItem>
                        <MenuItem value="Confirm">Confirm</MenuItem>
                        <MenuItem value="Prognosis">Prognosis</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    // onClick={() => {
                    //   const dto = {
                    //     diseaseObj: selectedDisease,
                    //     status: diseasesStatus,
                    //   };

                    //   setUserDiseaseList((prev) => [...prev, dto]);
                    //   initializeForm();
                    // }}

                    onClick={() => {
                      let tempDisease = selectedDisease;
                      if (!tempDisease) {
                        if (!diseaseInputString || diseaseInputString === "") {
                          toast.error("Please Provide Disease Name");
                          return;
                        }
                        tempDisease = {
                          id: uuidv4(),
                          diseaseName: diseaseInputString,
                          icd11code: "",
                          snowmedCt: "",
                          locallyAdded: true,
                        };
                      }
                      setUserDiagonesisList((prev) => {
                        const temp = [
                          ...prev,
                          {
                            diseaseObj: tempDisease,
                            status: diseasesStatus,
                            isProvisional: false,
                            posIndex: prev.length,
                            showInDiagonesis: true,
                            updateData: { status: diseasesStatus },
                          },
                        ];
                        updateData({ diagonesis: temp });
                        return temp;
                      });
                      initializeForm();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
