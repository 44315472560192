import { AlarmOutlined } from "@mui/icons-material";
import { Button, Container, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  getMyEventsInDateRanges,
  getNextEvent,
} from "../services/SchedulerService";
import { getLoginUserDetails } from "../utils/Utils";
import DateUtils from "../utils/DateUtils";
import BottomMenuAppList from "./BottomMenuAppList";
import BottomMenuQuickActions from "./BottomMenuQuickActions";
import { publicUrl } from "../constants/url_provider";
import "./css/bottomMenuShortcut.css";

export default function BottomMenuShortcut() {
  const loginUser = getLoginUserDetails();
  useEffect(() => {
    const fetchData = async () => {};

    fetchData();
  }, []);

  return (
    <>
      <div className="bottomMnuPopover">
        <div class="bottomMnuPopoverContainer">
          <BottomMenuAppList />
          <BottomMenuQuickActions />
        </div>
      </div>
    </>
  );
}
