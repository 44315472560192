import React from "react";

import { PictureAsPdfSharp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Download, ShareAndroid } from "iconoir-react";
import { downloadFileFromBase64 } from "../../../utils/Utils";

export default function EmailAttachFiles({
  messageId,
  name,
  attachmentId,
  mimeType,
}) {
  return (
    <>
      <div className="mailFile">
        <a className="mlfileAnc" href="#" target="blank">
          <div>
            <div className="fASG"></div>
            <div className="mfaVY mfaZn">
              <div className="mfaZm"></div>
            </div>

            <div className="mfaSH">
              {" "}
              {/* <img
                alt=""
                className="mfaQG mfaYB"
                src={publicUrl + "/zoyel_elements/thumb.webp"}
              /> */}
              <PictureAsPdfSharp className="mfaQG mfaYB" />
              <div className="mfaYy">
                <div className="mfaYA">
                  <PictureAsPdfSharp className="mfaSM" />
                </div>
                <div className="mfaYz">
                  <div className="mfa12">
                    <div className="mfaQA">
                      <span className="mfaV3">{name}</span>
                    </div>
                    <div className="mfaYp">
                      <span className="mfSaH2Ve">498 KB</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mfaSI">
              <div className="mfaSJ"></div>
            </div>
          </div>
        </a>
        <div className="mlfAct">
          <div className="mfdnbt">
            <IconButton aria-label="download" size="small">
              <Download
                fontSize="small"
                onClick={async () => {
                  console.log("downloading");
                  // downloadFileFromBase64(attachmentId, name);
                  const attachmentResponse =
                    await window.gapi.client.gmail.users.messages.attachments.get(
                      {
                        userId: "me",
                        messageId: messageId,
                        id: attachmentId,
                      }
                    );

                  console.log("attachment response ", attachmentResponse);

                  const decodedData = atob(
                    attachmentResponse.result.data
                      .replace(/-/g, "+")
                      .replace(/_/g, "/")
                  );

                  // Convert the decoded attachmentId to a Uint8Array
                  const arrayBuffer = new Uint8Array(decodedData.length);
                  for (let i = 0; i < decodedData.length; ++i) {
                    arrayBuffer[i] = decodedData.charCodeAt(i);
                  }

                  // Create a Blob with specified MIME type
                  const blob = new Blob([arrayBuffer], {
                    type: "application/octet-stream",
                  });

                  // Create a download link
                  const downloadLink = document.createElement("a");
                  downloadLink.href = URL.createObjectURL(blob);
                  downloadLink.download = name;
                  downloadLink.textContent = "Download File";

                  // Append the link to the document
                  document.body.appendChild(downloadLink);

                  // Trigger the click event to start the download
                  downloadLink.click();
                }}
              />
            </IconButton>
          </div>
          <div className="mfshbt">
            <IconButton aria-label="Share" size="small">
              <ShareAndroid fontSize="small" />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
}
