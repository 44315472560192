import {
  Add,
  ArrowBack,
  Edit,
  Search,
  SwapHoriz,
  Verified,
  Visibility,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import {
  deactivateItem,
  getItems,
  verifyItem,
} from "../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { useState } from "react";
import { toast } from "react-toastify";
import RightFlotingAnalyticsDashboard from "../RightFlotingAnalyticsDashboard";
import { ReplaceItemModal } from "./ReplaceItemModal";
import { useMemo } from "react";

export default function ViewItems({
  handelOpenAddItems,
  handelCloseViewItems,
  setEditItem,
  shouldUpdateItemList,
  closeAll
}) {
  const loginUserDetail = getLoginUserDetails();
  const [itemList, setItemList] = useState([]);
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const [originalItemToReplace, setOriginalItemToReplace] = useState(null);
  const [searchString, setSearchString] = useState("");

  const filteredItemList = useMemo(() => {
    if (searchString === "") {
      return [...itemList];
    }
    const temp = itemList.filter((item) => {
      return item.itemName.toLowerCase().includes(searchString.toLowerCase());
    });
    return temp;
  }, [searchString, itemList]);

  const getAllItems = () => {
    getItems(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        setItemList(response.data);
      }
    });
  };

  useEffect(() => {
    getAllItems();
  }, [shouldUpdateItemList]);

  const handleAddDeactivateItem = (itemId) => {
    deactivateItem(itemId, loginUserDetail.userId).then((response) => {
      if (response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        getAllItems();
      } else {
        toast.error(response.data.message);
      }
    });
  };

  const handleClickVerifyButton = (itemId) => {
    verifyItem(itemId, loginUserDetail.userId).then((response) => {
      if (response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        getAllItems();
      } else {
        toast.error(response.data.message);
      }
    });
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton onClick={() => {handelCloseViewItems(); closeAll();}}>
            <ArrowBack />
          </IconButton>
          Items List
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder="Search Items"
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    handelOpenAddItems();
                  }}
                >
                  Add Items
                </Button>
              </div>
            </div>

            <div className="cuContainArea FRItem">
              <div className="TableContainer">
                <table className="taskListTable">
                  <thead className="taskListtableHeader">
                    <tr>
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Category</th>
                      <th>Type</th>
                      <th>HSNCD</th>
                      <th>UOM</th>
                      <th>Manufacturer</th>
                      <th>Expirable</th>
                      <th>Status</th>
                      <th className="width100"></th>
                    </tr>
                  </thead>
                  <tbody className="scrolableTableBody">
                    {filteredItemList &&
                      filteredItemList.map((item) => (
                        <>
                          <tr>
                            <td>
                              <div className="tskLstTaskNM">
                                {item.itemName}
                              </div>
                            </td>
                            <td>
                              <Tooltip title={item.itemName} arrow>
                                <div className="tskLstTaskDescription">
                                  {item.itemDescription}
                                </div>
                              </Tooltip>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">
                                {item.category}
                              </div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">{item.type}</div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">{item.hsnCd}</div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">
                                {item.defaultUom}
                              </div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">
                                {item.manufacturer}
                              </div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">
                                {item.expirable === "Y" ? "Yes" : "No"}
                              </div>
                            </td>

                            {item.verified === "Y" && (
                              <>
                                <td>
                                  <div className="tskLstTaskNM">
                                    <Verified />
                                    Verified
                                  </div>
                                </td>

                                <td>
                                  <div className="tblActionBtnGrp">
                                    <IconButton
                                      onClick={(e) => {
                                        console.log("hello");
                                        setEditItem(item);
                                        handelOpenAddItems();
                                      }}
                                    >
                                      <Edit />
                                    </IconButton>
                                    <IconButton
                                      className="removeRowBtn"
                                      onClick={(e) => {
                                        handleAddDeactivateItem(item.itemId);
                                      }}
                                    >
                                      <Trash />
                                    </IconButton>
                                  </div>
                                </td>
                              </>
                            )}

                            {item.verified === "P" && (
                              <>
                                <td>
                                  <div className="tskLstTaskNM">
                                    <Button
                                      className="addNRwBtn "
                                      onClick={(e) => {
                                        handleClickVerifyButton(item.itemId);
                                      }}
                                    >
                                      Verify
                                    </Button>
                                  </div>
                                </td>
                                <td>
                                  <div className="tblActionBtnGrp">
                                    <Button
                                      className="addNRwBtn"
                                      startIcon={<SwapHoriz />}
                                      onClick={(e) => {
                                        setShowReplaceModal(true);
                                        setOriginalItemToReplace(item);
                                      }}
                                    >
                                      Replace
                                    </Button>
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {showReplaceModal && (
          <RightFlotingAnalyticsDashboard>
            <ReplaceItemModal
              handelCloseReplaceItems={(e) => {
                setShowReplaceModal(false);
              }}
              itemList={itemList}
              originalItem={originalItemToReplace}
              refreshData={() => {
                getAllItems();
              }}
            />
          </RightFlotingAnalyticsDashboard>
        )}
      </div>
    </>
  );
}
