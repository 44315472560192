import React, { useEffect, useRef, useState } from "react";
import "../../css/task.css";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  AttachFile,
  Audiotrack,
  CancelOutlined,
  CheckCircle,
  DescriptionOutlined,
  Help,
  Send,
  ShortcutOutlined,
  VideoCall,
} from "@mui/icons-material";
import { Diagram3, People, Plus, XLg } from "react-bootstrap-icons";
import user1 from "../../images/2.jpg";
import user2 from "../../images/3.jpg";
import {
  clearSelectedTask,
  setSelectedTask,
} from "../../../../redux/reducers/rightSideTaskPanelSlice";
import { useDispatch } from "react-redux";
import { getFormattedDate, getLoginUserDetails } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import DashboardServices from "../../../../services/DashboardServices";
import FileUpload from "../../../../common/FileUpload";
import RightFlotingContainer from "./RightFlotingContainer";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import ForwardTaskModal from "./ForwardTaskModal";
import LinkTaskModal from "./LinkTaskModal";
import AssigneeModal from "./AssigneeModal";
import TemplateList from "./TemplateList";
import FileList from "./FileList";
import RightTaskFileUpload from "./RightTaskFileUpload";
import { Refresh } from "iconoir-react";
import ReassignTaskModal from "./Modals/ReassignTaskModal";
import isAssignedByMe from "../../taskUtil";
import { useContext } from "react";
import { homeContainerContext } from "../../../dashboard/view/component/HomeContainer";
import TaskService from "../../../../services/TaskService";
import MeetingModal from "./Modals/MeetingModal";
export default function TaskAddressConcern({
  data,
  refreshData,
  removeFromStore,
}) {
  const context = useContext(homeContainerContext);
  const { setShowConvenientMeetingView } = context;
  const convinientSelecteduser = context.setSelectedUsers;
  const setMeetingList = useRef();
  const [showMeetingModal, setShowMeetingsModal] = useState(false);

  const [showFileModal, setShowFileUploadModal] = useState(false);

  const scrollRef = useRef();
  const dispatch = useDispatch();
  const loginUserDetail = getLoginUserDetails();
  const fileInputRef = useRef(null);

  const progress = useRef(0);
  const [progressWidth, setProgressWidth] = useState(20);
  const [dateRemaning, setDateRemaning] = useState(70);

  const elementRef = useRef(null);

  const [showAddressConcern, setShowAddressConcern] = useState(false);
  const [addressConcernRemark, setAddressConcernRemark] = useState("");

  const [assigneeList, setAssigneeList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});

  const [showCreateLinkTask, setShowCreateLinkTask] = useState(false);

  const [tempOfOrgList, setTemplateOfOrgList] = useState([]);
  const [addedTemplateList, setAddedTemplateList] = useState([]);
  const [recommendedTemplateList, setRecommendedTemplateList] = useState([]);
  const [showAddTemplate, setShowAddTemplate] = useState(false);

  const [anchorUl, setAnchorUl] = React.useState(null);
  const openUl = Boolean(anchorUl);
  const ulId = openUl ? "simple-popover" : undefined;
  const fileDocuments = useRef(null);
  const commentInputRef = useRef();
  const [showAssigneeList, setShowAssigneeList] = useState(false);

  const [showAudioModal, setShowAudioModal] = useState(false);
  const audioUrl = useRef();

  const [showReassignTask, setShowReassignTask] = useState(false);

  const handleCloseUl = () => {
    setAnchorUl(null);
  };

  const clearAllModals = () => {
    setShowCreateLinkTask(false);
    setShowAddressConcern(false);
    setShowReassignTask(false);
    setShowAssigneeList(false);
  };

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  const handleAddTemplate = async () => {
    const value = await DashboardServices.getAllTemplatesOfOrganization(
      loginUserDetail.orgId
    );
    setTemplateOfOrgList(value.data);

    const response = await DashboardServices.getTemplatesOfSubActivity(
      data.activityId,
      0
    );
    let notaddedTemplateList = [];
    if (response.data != null && response.data.length > 0) {
      response.data.forEach((template) => {
        let isTemplateAdded = false;
        addedTemplateList.forEach((addedTemplate) => {
          if (template.templateId === addedTemplate.templateId) {
            isTemplateAdded = true;
          }
        });
        if (!isTemplateAdded) {
          notaddedTemplateList.push(template);
        }
      });
      setRecommendedTemplateList(notaddedTemplateList);
    }
    console.log("hello");
    setShowAddTemplate(true);
  };

  const sendComment = (e) => {
    if (!data) {
      toast.error("something went wrong ");
      return;
    }
    const comment = commentInputRef.current.value;
    const reqDto = {
      taskDetailId: data.taskId,
      progress: data.progress,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };
    console.log("reqDto = ", reqDto);
    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      DashboardServices.sendTaskComments(loginUserDetail.userId, reqDto).then(
        (response) => {
          console.log("out response data= ", response);
          if (response.data == 1) {
            const tempData = { ...data };
            tempData.comments = [
              ...tempData.comments,
              {
                comments: comment,
                commentsType: null,
                commentsByUserName: loginUserDetail.userName,
                commentsByUserProfileUrl: loginUserDetail.userProfileImage,
                progress: data.progress,
                commentsAt: commentsAt,
              },
            ];
            dispatch(setSelectedTask(tempData));
            commentInputRef.current.value = "";
            const timeoutValue = setTimeout(() => {
              scrollRef.current.scrollIntoView({ behavior: "smooth" });
              clearTimeout(timeoutValue);
            }, 500);
          } else {
            toast.error("something went wrong");
          }
        }
      );
    }
  };

  const intervalProgressor = (timeDelay) => {
    const intervalId = setInterval(() => {
      console.log("interval progressor=", progressWidth);
      if (progress.current < 100) {
        setProgressWidth((prev) => prev + 1);
        progress.current = progress.current + 1;
      } else {
        clearInterval(intervalId);
      }
    }, timeDelay);
  };

  const getTaskDifference = () => {
    // const time1=task.taskCreationTime
    // const time2 =task.taskCompletionTime
    const creationTime = new Date(data && data.createdAt);
    const completionTime = new Date(data && data.completionTime);
    const currentTime = new Date();

    //check if already time passed

    const diffFromCurrent = currentTime - completionTime;
    if (diffFromCurrent > 0) {
      setDateRemaning(100);
      return;
    }

    // Calculate the time difference in milliseconds
    const totalTimeDifference = completionTime - creationTime;
    const timeDifferenceFromNow = currentTime - creationTime;
    const milisecondPerPercentIncrease = totalTimeDifference / 100;
    const currentPercent = (timeDifferenceFromNow * 100) / totalTimeDifference;
    setDateRemaning(
      Math.floor(currentPercent) < 100 ? Math.floor(currentPercent) : 100
    );
    progress.current = Math.floor(currentPercent);
    const nextPercent = Math.ceil(currentPercent);
    const difference = nextPercent - currentPercent;
    const diffInMilisecond = milisecondPerPercentIncrease * difference;
    const clearTimeoutVal = setTimeout(() => {
      if (currentPercent < 100) {
        console.log("before interval progress", milisecondPerPercentIncrease);
        clearTimeout(clearTimeoutVal);
        setDateRemaning((prev) => prev + 1);
        progress.current = progress.current + 1;
        if (milisecondPerPercentIncrease > 0) {
          intervalProgressor(milisecondPerPercentIncrease);
        }
      }
    }, diffInMilisecond);
    console.log("currentPercent", currentPercent);
    console.log(
      "milisecondPerPercentIncrease = ",
      milisecondPerPercentIncrease / 1000
    );
  };

  const handleChangeFile = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
    }));

    DashboardServices.uploadDocuments(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      console.log("document sent is ", response.data);
      if (response.data) {
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setSelectedTask(tempData));
      }
    });
    console.log("file document = ", fileDocuments.current);
  };

  useEffect((e) => {
    setProgressWidth(data.progress);
    getTaskDifference();

    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );
  }, []);

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp">
              <div className="taskVDContBtn">
                <Button
                  variant="contained"
                  startIcon={<Help />}
                  onClick={(e) => {
                    clearAllModals();
                    setShowAddressConcern(true);
                  }}
                >
                  Address Concern
                </Button>
              </div>
            </div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn">
                <IconButton
                  aria-label="Forward"
                  onClick={(e) => {
                    clearAllModals();
                    setShowReassignTask(true);
                  }}
                >
                  <Tooltip title="Reassign" arrow>
                    <Refresh />
                  </Tooltip>
                </IconButton>
              </div>

              <div className="taskActBtn">
                <IconButton
                  aria-label="Link Task"
                  onClick={(e) => {
                    clearAllModals();
                    setShowCreateLinkTask(true);
                  }}
                >
                  <Tooltip title="Link Task" arrow>
                    <Diagram3 />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="taskActBtn">
                <IconButton
                  aria-label="Meeting"
                  onClick={(e) => {
                    TaskService.getEventsofTask(data.taskId).then(
                      (response) => {
                        console.log("event task =", response.data);
                        setMeetingList.current = response.data;
                        if (response.data && response.data.length > 0) {
                          setShowMeetingsModal(true);
                        } else {
                          setShowConvenientMeetingView(true);
                          setSelectedUsers(assigneeList);
                        }
                      }
                    );
                  }}
                >
                  <Tooltip title="Meeting" arrow>
                    <VideoCall />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="taskActBtn">
                <IconButton
                  aria-label="User"
                  onClick={(e) => {
                    // setOpenUserList(true);
                    // handleClickUl(e);
                    clearAllModals();
                    setShowAssigneeList(true);
                  }}
                >
                  <Tooltip title="User" arrow>
                    <People />
                  </Tooltip>
                </IconButton>
                <Popover
                  id={ulId}
                  open={openUl}
                  anchorEl={anchorUl}
                  onClose={handleCloseUl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ul>
                    {assigneeList &&
                      assigneeList.map((assignee) => <li>{assignee.name}</li>)}
                  </ul>
                  {/* <Typography sx={{ p: 2 }}>
                    The content of the Popover.
                  </Typography> */}
                </Popover>
              </div>

              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    setShowConvenientMeetingView(false);
                    convinientSelecteduser([]);
                    dispatch(clearSelectedTask());
                  }}
                >
                  <Tooltip title="Close" arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                <div className="taskTitleContrct">
                  {/* <div className="taskID">PT0023</div> */}
                  <div className="taskTitleNM">{data && data.taskName}</div>
                </div>
                <div className="taskElementGrp">
                  <div className="tskElemHeding">Description</div>
                  <div className="tskElemntInfo">
                    {data && data.taskDescription}
                  </div>
                </div>

                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Audio Message</div>
                    <div className="tskElemntInfo">
                      <div className="tskElemntInfo">
                        <IconButton
                          className="elemntMrFileBtn"
                          variant="outlined"
                          onClick={(e) => {
                            DashboardServices.generatePreSignedUrlS3Object(
                              data.audioNoteFileName,
                              loginUserDetail.orgId
                            ).then((response) => {
                              audioUrl.current = response.data;
                              setShowAudioModal(true);
                            });
                          }}
                        >
                          <Audiotrack />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                )}

                {data &&
                  data.contractName &&
                  data.contractName.trim() !== "" && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">Contract</div>
                      <div className="taskContractNm">
                        {data && data.contractName}
                      </div>
                    </div>
                  )}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Status</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // ps50 ps80 ps100
                          className="progress-done ps50"
                          style={{ width: `${progressWidth}%`, opacity: 1 }}
                        >
                          <span className="pwCount">{progressWidth}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Timeline</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // up50 up80 up100
                          className={`progress-done ${
                            data && dateRemaning < 50
                              ? "up50"
                              : dateRemaning < 80
                              ? "up80"
                              : "up100"
                          }`}
                          style={{ width: `${dateRemaning}%`, opacity: 1 }}
                        ></div>
                      </div>
                    </div>
                    <div className="tskDtSE">
                      <div className="tskDTStart">
                        {getFormattedDate(data.taskCreationTime)}
                      </div>
                      <div className="tskDTEnd">
                        {getFormattedDate(data.taskCompletionTime)}
                      </div>
                    </div>
                  </div>
                </div>

                <TemplateList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleAddTemplate={handleAddTemplate}
                />

                <FileList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleChangeFile={handleChangeFile}
                  setShowFileUploadModal={setShowFileUploadModal}
                />

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Priority</div>
                  <div className="tskPrioArea">
                    {/* statUrgent, statNormal, statImportant  used these three for diffrent priority */}
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class="priStat">
                        {data.priority === 0
                          ? "Regular"
                          : data.priority === 1
                          ? "Important"
                          : "Critical"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Activity</div>
                    <div className="tskElemntInfo">
                      {data && data.activityName}
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Concern Raised By</div>
                    <div className="tskElemntInfo">
                      {data &&
                      isAssignedByMe(
                        data.approveRejectConcernedPostponedByUserId
                      )
                        ? "Me"
                        : data.approveRejectConcernedPostponedByUserName}
                    </div>
                  </div>
                </div>

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Last updated</div>
                  <div className="tskElemntInfo">
                    {data &&
                      data.comments &&
                      data.comments.length &&
                      `${
                        data.comments[data.comments.length - 1]
                          .commentsByUserName
                      } - ${getFormattedDate(
                        data.comments[data.comments.length - 1].commentsAt
                      )}`}
                  </div>
                </div>

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>
                <div ref={scrollRef}></div>
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="tskInputGrp">
              <input
                ref={commentInputRef}
                type="text"
                className="tskComntInput"
                placeholder="Update comment here"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    console.log("Enter key pressed");
                    sendComment(event);
                  }
                }}
              />
              <IconButton className="tskComtSndBtn" onClick={sendComment}>
                <Send />
              </IconButton>
            </div>
          </div>
        </div>
        {showAddressConcern && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Address Concern</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label="Concern Remark"
                      variant="outlined"
                      multiline
                      rows={4}
                      maxRows={7}
                      value={addressConcernRemark}
                      onChange={(e) => setAddressConcernRemark(e.target.value)}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    if (addressConcernRemark.trim() === "") {
                      toast.error("Please provide your concern response");
                      return;
                    }

                    DashboardServices.addressConcernOfTask(
                      data.taskId,
                      loginUserDetail.userId,
                      addressConcernRemark
                    ).then((response) => {
                      if (response.data && response.data.returnValue === "1") {
                        toast.success(response.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                        setShowAddressConcern(false);
                        dispatch(clearSelectedTask());
                      } else {
                        toast.error(response.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      }
                      setAddressConcernRemark("");
                      refreshData();
                      // setSelectedUsers([]);
                    });
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowAddressConcern(false);
                    setAddressConcernRemark("");
                    setSelectedUsers([]);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showMeetingModal && (
          <MeetingModal
            handleClose={(e) => setShowMeetingsModal(false)}
            assigneeList={assigneeList}
            taskId={data.taskId}
          />
        )}

        {showAssigneeList && (
          <AssigneeModal
            assigneeList={assigneeList}
            handleClose={(e) => setShowAssigneeList(false)}
          />
        )}

        {showCreateLinkTask && (
          <LinkTaskModal
            userList={userList}
            data={data}
            handleClose={(e) => setShowCreateLinkTask(false)}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}

        {showReassignTask && (
          <ReassignTaskModal
            userList={userList}
            assigneeList={assigneeList}
            data={data}
            refreshData={refreshData}
            handleClose={(e) => setShowReassignTask(false)}
            removeFromStore={removeFromStore}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Audio Message</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          </Box>
        )}
      </div>
      {showAddTemplate && (
        <RightFlotingContainer
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={() => {
            setShowAddTemplate(false);
            if (addedTemplateList && addedTemplateList.length > 0) {
              const temp = [...addedTemplateList];
              temp.forEach((template) => {
                template.taskDetailId = data.taskId;
              });
              DashboardServices.addTaskTemplate(
                loginUserDetail.userId,
                addedTemplateList,
                "OLD"
              ).then((response) => {
                console.log("addTaskTemplate", response.data);
                const tempData = { ...data };
                if (tempData.templates) {
                  tempData.templates = [
                    ...tempData.templates,
                    ...addedTemplateList,
                  ];
                } else {
                  tempData.templates = [...addedTemplateList];
                }
                dispatch(setSelectedTask(tempData));
              });
            }
          }}
          mode={"old"}
        />
      )}

      {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={(e) => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )}
    </>
  );
}
