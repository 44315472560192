import {
  Button,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useMemo } from "react";
import { XLg } from "react-bootstrap-icons";
import isAssignedByMe from "../../../taskUtil";
import { useState } from "react";
import { useEffect } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { toast } from "react-toastify";

function RightSideMultipleConcern({ selectedTasks, handleClose, refreshData }) {
  const loginUserDetail = getLoginUserDetails();
  const [concernReasonList, setConcernReasonList] = useState([]);
  const shouldShowConcernButton = useMemo(() => {
    let valid = true;
    for (let item of concernReasonList) {
      if (item === "") {
        valid = false;
        break;
      }
    }
    return valid;
  }, [concernReasonList]);

  useEffect(() => {
    setConcernReasonList(selectedTasks.map((task) => ""));
  }, [selectedTasks]);

  const raiseConcerns = async () => {
    console.log("concern is raising");
    const res = await Promise.all(
      selectedTasks.map((task, index) => {
        return new Promise((resolve, reject) => {
          DashboardServices.concernTask(
            task.taskId,
            loginUserDetail.userId,
            concernReasonList[index]
          ).then((response) => {
            if (response.data && response.data.returnValue === "1") {
              resolve("ok");
            } else {
              toast.error(`Could not raise concern for ${task.taskName}`);
            }
          });
        });
      })
    );
    refreshData();
  };

  return (
    <>
      <div className="RightFlotingContainerArea">
        <div className="taskViewPannel">
          <div className="taskViewContainer">
            <div className="taskHeaderPanel">
              <div className="taskTitleContrct">
                <div className="taskTitleNM">{`Concern ${selectedTasks.length} Tasks `}</div>
              </div>
              <div className="taskControlBtnGrp"></div>

              <div className="taskActionBtnGrp">
                <div className="taskActBtn closeBtnD10">
                  <IconButton aria-label="close" onClick={handleClose}>
                    <Tooltip title="Close" arrow>
                      <XLg />
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
            </div>

            <div className="taskContain">
              <div className="taskContainDetail">
                <div className="taskContDtlContainer">
                  {selectedTasks &&
                    selectedTasks.length > 0 &&
                    selectedTasks.map((task, index) => {
                      return (
                        <>
                          <div className="multipleUpdateTaskCard">
                            <div className="taskTitleContrct">
                              <div className="taskHeadingNM">
                                {task.taskName}
                              </div>
                            </div>
                            <div className=" commonUpdate">
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <TextField
                                    // sx={{ color: "white" }}
                                    label="Concern Reason*"
                                    variant="outlined"
                                    className="descptionTextFild"
                                    multiline
                                    rows={3}
                                    maxRows={7}
                                    value={concernReasonList[index]}
                                    // value={reason}
                                    onChange={(e) => {
                                      const temp = [...concernReasonList];
                                      temp[index] = e.target.value;
                                      setConcernReasonList(temp);
                                    }}
                                  />
                                </FormControl>
                              </div>
                              {/* <div className="taskElementGrp">
                                <div className="tskElemHeding">Created By</div>
                                <div className="tskElemntInfo">
                                  {task && isAssignedByMe(task.createdByUserId)
                                    ? "Me"
                                    : task.createdByUserName}
                                </div>
                              </div> */}
                              <div
                                className="tskCretdByElmnt"
                                style={{ color: "#fff" }}
                              >
                                <span className="assuTag">Created By : </span>
                                <span className="assuName">
                                  {task && isAssignedByMe(task.createdByUserId)
                                    ? "Me"
                                    : task.createdByUserName}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="taskFooterPanel">
              <div className="formBtnElement">
                <Button
                  disabled={!shouldShowConcernButton}
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    raiseConcerns();
                    handleClose();
                  }}
                >
                  Concern
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={(e) => handleClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RightSideMultipleConcern;
