import React, { useEffect, useState } from "react";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { XLg } from "react-bootstrap-icons";
import isAssignedByMe from "../../../taskUtil";
import DashboardServices from "../../../../../services/DashboardServices";
import { toast } from "react-toastify";

function RightSideTaskForward({ selectedTasks, handleClose, refreshData }) {
  const [forwardToList, setForwardToList] = useState([]);
  const loginUserDetail = getLoginUserDetails();
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    setForwardToList(selectedTasks.map((task) => null));

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );
  }, []);

  const forwardTask = async () => {
    const res = await Promise.all(
      selectedTasks.map((task, index) => {
        return new Promise((resolve, reject) => {
          // const reqDto = {
          //   postponedReason: dele[index].reason,
          //   postponedTill: dele[index].postponeTill,
          // };

          const reqDto = {
            taskDetailId: task.taskId,
            forwardToUserId: forwardToList[index].userId,
          };
          DashboardServices.forwardApprovedTask(
            loginUserDetail.userId,
            reqDto
          ).then((response) => {
            if (response.data && response.data.returnValue === "1") {
              resolve("ok");
              handleClose();
            } else {
              resolve(null);
              toast.error(`Could Not Forward Task ${task.taskName}`);
            }
          });
        });
      })
    );
    const valid = true;
    for (let i = 0; i < res.length; i++) {
      if (res[i] !== "ok") {
        valid = false;
      }
    }
    if (valid) {
      toast.success("All Task Forwarded Successfully");
    }
    refreshData();
  };

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskTitleContrct">
              <div className="taskTitleNM">{`Forward ${selectedTasks.length} Tasks `}</div>
            </div>
            <div className="taskControlBtnGrp"></div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn closeBtnD10">
                <IconButton aria-label="close" onClick={handleClose}>
                  <Tooltip title="Close" arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>

          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {selectedTasks &&
                  selectedTasks.length > 0 &&
                  selectedTasks.map((task, index) => {
                    return (
                      <>
                        <div className="multipleUpdateTaskCard">
                          <div className="taskTitleContrct">
                            <div className="taskHeadingNM">{task.taskName}</div>
                          </div>
                          <div className="commonUpdate">
                            <div className="formElement">
                              <FormControl className="formControl">
                                <Autocomplete
                                  id="adduser-select"
                                  disableClearable
                                  className="formAutocompleteField"
                                  variant="outlined"
                                  value={
                                    forwardToList &&
                                    forwardToList.length > 0 &&
                                    forwardToList[index]
                                  }
                                  options={userList}
                                  getOptionLabel={(option) => option.userName}
                                  getOptionSelected={(option) => {
                                    console.log("option is ", option);
                                    return option.userId;
                                  }}
                                  onChange={(e, newValue) => {
                                    console.log("new value = ", newValue);
                                    const temp = [...forwardToList];
                                    temp[index] = newValue;
                                    setForwardToList(temp);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      className="formAutoComInputField autocompFildPlaceholder"
                                      placeholder="Assign User*"
                                      InputProps={{
                                        ...params.InputProps,
                                        type: "search",
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </div>

                            {/* <div className="taskElementGrp">
                            <div className="tskElemHeding">Created By</div>
                            <div className="tskElemntInfo">
                              {task && isAssignedByMe(task.createdByUserId)
                                ? "Me"
                                : task.createdByUserName}
                            </div>
                          </div> */}
                            <div
                              className="tskCretdByElmnt"
                              style={{ color: "#fff" }}
                            >
                              <span className="assuTag">Created By :</span>
                              <span className="assuName">
                                {task && isAssignedByMe(task.createdByUserId)
                                  ? "Me"
                                  : task.createdByUserName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={(e) => {
                  forwardTask();
                }}
              >
                Reassign
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={(e) => handleClose()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RightSideTaskForward;
