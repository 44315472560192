import { Mic, PeopleRounded, Verified } from "@mui/icons-material";
import { Camera, DoubleCheck, Page, VideoCamera } from "iconoir-react";
import React from "react";
import { getDateInDDMMYYAMPM } from "../../../../utils/Utils";
import { MESSAGE_DELETED } from "../../../../constants/const_string";

export default function ContactCard({ contact, active, online, onClick }) {
  const renderLastMessage = () => {
    switch (contact?.lastMessageType) {
      case "photo":
        if (contact.lastMessageDeleted === "Y") {
          return MESSAGE_DELETED;
        } else if (contact?.lastMessageContent === "") {
          return "Photo";
        } else {
          return contact?.lastMessageContent;
        }

      case "document":
        if (contact.lastMessageDeleted === "Y") {
          return MESSAGE_DELETED;
        } else if (contact?.lastMessageContent === "") {
          return "Document";
        } else {
          return contact?.lastMessageContent;
        }

      case "audio":
        if (contact.lastMessageDeleted === "Y") {
          return MESSAGE_DELETED;
        } else if (contact?.lastMessageContent === "") {
          return "Audio";
        } else {
          return contact?.lastMessageContent;
        }

      case "video":
        if (contact.lastMessageDeleted === "Y") {
          return MESSAGE_DELETED;
        } else if (contact?.lastMessageContent === "") {
          return "Video";
        } else {
          return contact?.lastMessageContent;
        }

      case "text":
        if (contact.lastMessageDeleted === "Y") {
          return MESSAGE_DELETED;
        } else if (contact?.lastMessageContent !== "") {
          return contact?.lastMessageContent;
        } else {
          return "";
        }

      case "info":
        return contact?.lastMessageContent !== ""
          ? contact?.lastMessageContent
          : "";

      default:
        return "";
    }
  };

  return (
    <div
      className={`chatContactList ${active && `activeChat`}`}
      onClick={onClick}
    >
      <div className="contUserImg">
        <img alt="" src={contact?.chatProfileUrl} />
        {online && <span className="usrActStatus" />}
      </div>

      <div className="userDtlWlMsg">
        <div className="usrNmDT">
          <div className="usrNmIn">
            {contact?.chatName}
            {contact?.chatType === "I" && <Verified />}
            {contact?.chatType === "G" && <PeopleRounded />}
          </div>
          {!online && (
            <div className="msgDTIn">
              {!active &&
              contact?.lastSeenTime != null &&
              contact?.lastSeenTime !== "" ? (
                getDateInDDMMYYAMPM(contact?.lastSeenTime)
              ) : (
                <>
                  <span>&nbsp;</span>
                </>
              )}
            </div>
          )}
        </div>

        <div className="usrLstMsgDtStus">
          {contact?.lastMessageType !== "info" && (
            <div className="usrStusIcon">
              <DoubleCheck />
            </div>
          )}

          <div className="usrStusIcon">
            {contact?.lastMessageType === "photo" && <Camera />}
            {contact?.lastMessageType === "document" && <Page />}
            {contact?.lastMessageType === "audio" && <Mic />}
            {contact?.lastMessageType === "video" && <VideoCamera />}
          </div>

          <div className="lstMsgShw">{renderLastMessage()}</div>
          {contact?.unreadChatCount > 0 && (
            <div className="unRdMsgCunt">{contact?.unreadChatCount}</div>
          )}
        </div>
      </div>
    </div>
  );
}
