import React from "react";

export default function RadialProgressBar({ profileProgress }) {
  const radius = 22; // radius of the circle
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (profileProgress / 100) * circumference;
  return (
    <>
      <svg className="radial-progress" height="50" width="50">
        <circle className="radial-progress-bg" cx="24" cy="24" r={radius} />
        <circle
          className="radial-progress-bar"
          cx="24"
          cy="24"
          r={radius}
          style={{
            strokeDasharray: `${circumference} ${circumference}`,
            strokeDashoffset: offset,
          }}
        />
        <text
          x="24"
          y="24"
          textAnchor="middle"
          dominantBaseline="middle"
          dy=".3em"
        >
          {profileProgress}%
        </text>
      </svg>
    </>
  );
}
