import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  editOrg,
  getAllTimeZones,
  getCountryList,
  getCurrencyList,
  updateLogo,
  updateOrgDetails,
} from "../../../../services/AdminService";
import { AttachFile } from "@mui/icons-material";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import DateUtils from "../../../../utils/DateUtils";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

export default function UpdateOrganization({
  handelCloseEditUpdateOrg,
  showUpdateOrgData,
  refreshOrganisationData,
}) {
  console.log("showUpdateOrgData", showUpdateOrgData);

  const userDetails = jwtDecode(localStorage.getItem("token"));

  const inputRefLogo = useRef();
  const [fileData_Base64, setFileData_Base64] = useState("");
  const [fileName, setFileName] = useState("");

  const [orgLogo, setOrgLogo] = useState(showUpdateOrgData.orgLogo);
  const [commonName, setCommonName] = useState(showUpdateOrgData.commonName);
  const [orgName, setOrgName] = useState(showUpdateOrgData.orgName);
  const [firstTimeUpdate, setFirstTimeUpdate] = useState(true);
  const [regBuildingNameNo, setRegBuildingNameNo] = useState(
    showUpdateOrgData.regBuildingNameNo
  );
  const [regArea, setRegArea] = useState(showUpdateOrgData.regArea);
  const [regCity, setRegCity] = useState(showUpdateOrgData.regCity);
  const [regPinCd, setRegPinCd] = useState(showUpdateOrgData.regPinCd);
  const [corrBuildingNameNo, setCorrBuildingNameNo] = useState(
    showUpdateOrgData.corrBuildingNameNo
  );
  const [corrArea, setCorrArea] = useState(showUpdateOrgData.corrArea);
  const [corrPinCd, setCorrPinCd] = useState(showUpdateOrgData.corrPinCd);
  const [corrCity, setCorrCity] = useState(showUpdateOrgData.corrCity);
  const [corrCountryCode, setCorrCountryCode] = useState(
    showUpdateOrgData.corrCountryCode
  );
  const [corrCountryInput, setCorrCountryInput] = useState("");
  const [currencyList, setCurrencyList] = useState([]);

  const [unitName, setUnitName] = useState(showUpdateOrgData.unitName);
  const [userPan, setUserPan] = useState(showUpdateOrgData.pan);
  const [userTan, setUserTan] = useState(showUpdateOrgData.tan);
  const [epfNo, setEpfNo] = useState(showUpdateOrgData.epfNo);
  const [esiNo, setEsiNo] = useState(showUpdateOrgData.esiNo);
  const [gstin, setGstin] = useState(showUpdateOrgData.gstin);

  const [facebookLink, setFacebookLink] = useState(
    showUpdateOrgData.facebookLink
  );
  const [instagramLink, setInstagramLink] = useState(
    showUpdateOrgData.instagramLink
  );
  const [linkedinLink, setLinkedinLink] = useState(
    showUpdateOrgData.linkedinLink
  );
  const [twitterLink, setTwitterLink] = useState(showUpdateOrgData.twitterLink);

  const [balanceSheetOfOwn, setBalanceSheetOfOwn] = useState(
    showUpdateOrgData.ownBalanceSheet || "N"
  );

  const [statutory, setStatutory] = useState(
    showUpdateOrgData.statutoryCompliances || "N"
  );

  const [localTax, setLocalTax] = useState(showUpdateOrgData.localTax || "N");

  const [currency, setCurrency] = useState(showUpdateOrgData.currency);

  const handleUserPan = (e) => {
    setUserPan(e.target.value);
  };

  const handleUpdateLogo = async (e) => {
    // console.log("handleUpdateLogo", e);
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    console.log("handleFileUpload", file);

    const fileData = await convertFileToBase64_Split(file);
    const fileName = file.name;
    setOrgLogo(`data:image/png;base64,${fileData}`);
    setFileData_Base64(fileData);
    setFileName(fileName);
  };

  const handleOrgName = (e) => {
    console.log("handleOrgName", e.target.value);
    setOrgName(e.target.value);
    if (firstTimeUpdate) {
      setCommonName(e.target.value);
    }
  };

  const handleCommonName = (e) => {
    setCommonName(e.target.value);
    setFirstTimeUpdate(false);
  };

  const [registerOffAddress, setRegisterOffAddress] = useState(
    showUpdateOrgData.orgRegAddress
  );

  const handleRegisterOffAddress = (e) => {
    console.log("handleRegisterOffAddress", e.target.value);
    setRegisterOffAddress(e.target.value);
  };

  const [email, setEmail] = useState(showUpdateOrgData.orgEmailId);

  const handleEmail = (e) => {
    console.log("handleEmail", e.target.value);
    setEmail(e.target.value);
  };

  const [contactNo, setContactNo] = useState(showUpdateOrgData.contactNo);

  const handleContactNo = (e) => {
    console.log("handleContactNo", e.target.value);
    setContactNo(e.target.value);
  };

  const [organizationType, setOrganizationType] = useState(
    showUpdateOrgData.orgType
  );

  const handleOrganizationType = (e) => {
    console.log("handleOrganizationType", e.target.value);
    setOrganizationType(e.target.value);
  };

  const [startDate, setStartDate] = useState(new Date());

  // useEffect(() => {
  //   const inputDate = showUpdateOrgData.doi;
  //   let tempDate = new Date();
  //   const [day, month, year] = inputDate.split("-");
  //   tempDate.setFullYear(parseInt(year), parseInt(month) - 1, day);
  //   setStartDate(tempDate);
  // }, [showUpdateOrgData]);

  useEffect(() => {
    const inputDate = showUpdateOrgData.doi;
    if (inputDate) {
      const [day, month, year] = inputDate.split("-");
      const tempDate = new Date(`${year}-${month}-${day}`);
      if (!isNaN(tempDate)) {
        setStartDate(tempDate);
      } else {
        console.error("Invalid date value:", inputDate);
      }
    }
  }, [showUpdateOrgData]);

  const changeCurrentDate = (newDate) => {
    console.log("changeCurrentDate", newDate);
    setStartDate(newDate);
  };

  const [authorizedPerson, setAuthorizedPerson] = useState(
    showUpdateOrgData.authPerson
  );

  const handleAuthorizedPerson = (e) => {
    console.log("handleAuthorizedPerson", e.target.value);
    setAuthorizedPerson(e.target.value);
  };

  const [authorizedContact, setAuthorizedContact] = useState(
    showUpdateOrgData.authPerContactNo
  );

  const handleAuthorizedContact = (e) => {
    console.log("handleAuthorizedContact", e.target.value);
    setAuthorizedContact(e.target.value);
  };

  const [tradeLicense, setTradeLicense] = useState(
    showUpdateOrgData.tradeLicNo
  );

  const handleTradeLicense = (e) => {
    console.log("handleTradeLicense", e.target.value);
    setTradeLicense(e.target.value);
  };

  const [companyIdentification, setCompanyIdentification] = useState(
    showUpdateOrgData.cin
  );

  const handleCompanyIdentification = (e) => {
    console.log("handleCompanyIdentification", e.target.value);
    setCompanyIdentification(e.target.value);
  };

  const [taxNo, setTaxNo] = useState(showUpdateOrgData.taxNo);

  const handleTaxNo = (e) => {
    console.log("handleTaxNo", e.target.value);
    setTaxNo(e.target.value);
  };

  const [correspondenceAddress, setCorrespondenceAddress] = useState(
    showUpdateOrgData.corrAddress
  );

  const handleCorrespondenceAddress = (e) => {
    console.log("handleCorrespondenceAddress", e.target.value);
    setCorrespondenceAddress(e.target.value);
  };

  const [timezone, setTimezone] = useState("");
  const [timezoneInput, setTimezoneInput] = useState("");
  const [timeZoneData, setTimeZoneData] = useState([]);

  useEffect(() => {
    getAllTimeZones().then((response) => {
      console.log("Zone Data", response.data);
      setTimeZoneData(response.data);
    });
    getCurrencyList().then((response) => {
      console.log("getCurrencyList", response.data);
      setCurrencyList(response.data);
    });
    getCountryList().then((response) => {
      console.log(response.data);
      setCountryData(response.data);
    });
  }, []);

  useEffect(() => {
    const currentTimeZone = timeZoneData.find(
      (value) => value.name === showUpdateOrgData.timeZone
    );
    setTimezoneInput(currentTimeZone ? currentTimeZone.name : "");
    setTimezone(currentTimeZone);
  }, [showUpdateOrgData, timeZoneData]);

  const handleTimezone = (e, newValue) => {
    console.log("handleTimezone", newValue);
    setTimezone(newValue);
  };

  const handleTimezoneInput = (e, newValue) => {
    console.log("handleTimezoneInput", newValue);
    setTimezoneInput(newValue);
  };

  // const filterOptions = (options, { inputValue }) => {
  //   if (!inputValue) {
  //     return [];
  //   }
  //   return options.filter((option) =>
  //     option.name.toLowerCase().includes(inputValue.toLowerCase())
  //   );
  // };

  const [country, setCountry] = useState("");
  const [countryInput, setCountryInput] = useState("");
  const [countryData, setCountryData] = useState([]);

  // useEffect(() => {
  //   getCountryList().then((response) => {
  //     console.log(response.data);
  //     setCountryData(response.data);
  //   });
  // }, []);

  useEffect(() => {
    const currentCountry = countryData.find(
      (value) => value.countryCode === showUpdateOrgData.countryCode
    );
    setCountryInput(currentCountry ? currentCountry.countryName : "");
    setCountry(currentCountry);

    const corrCountryValue = countryData.find(
      (value) => value.countryCode === showUpdateOrgData.corrCountryCode
    );
    setCorrCountryCode(corrCountryValue);
    setCountryInput(currentCountry ? currentCountry.countryName : "");
  }, [showUpdateOrgData, countryData]);

  const handleCountry = (e, newValue) => {
    console.log("handleCountry", newValue);
    setCountry(newValue);
  };

  const handleCountryInput = (e, newValue) => {
    console.log("handleCountryInput", newValue);
    setCountryInput(newValue);
  };

  const finalSubmit = () => {
    console.log("final Submit");

    const CountryValue = countryInput !== "" ? countryInput : country;

    console.log("CountryValue", CountryValue);

    const currentCountry = countryData.find(
      (value) => value.countryName === CountryValue
    );
    const currentCountryCode = currentCountry ? currentCountry.countryCode : "";
    const corrCountryCodeVal = corrCountryCode
      ? corrCountryCode.countryCode
      : "";

    const reqDto = {
      orgId: showUpdateOrgData.orgId || userDetails.orgId,
      orgName: orgName,
      // orgRegAddress: registerOffAddress,
      orgEmailId: email || "",
      cin: companyIdentification,
      countryCode: currentCountryCode !== "" ? currentCountryCode : "",
      contactNo: contactNo,
      doi: DateUtils.getDateInDDMMYYYY(startDate),
      orgType: organizationType,
      tradeLicNo: tradeLicense,
      // corrAddress: correspondenceAddress,
      authPerson: authorizedPerson,
      authPerCountryCode: showUpdateOrgData.authPerCountryCode,
      authPerContactNo: authorizedContact,
      taxNo: taxNo,
      userId: userDetails.userId,
      timeZone: timezoneInput !== "" ? timezoneInput : timezone.name,
      websiteTemplateDtlList: showUpdateOrgData.websiteTemplateDtlList,
      // subDomain: showUpdateOrgData.subDomain,
      orgLogo: showUpdateOrgData.orgLogo,
      templateId: showUpdateOrgData.templateId,
      orgDefaultLang: showUpdateOrgData.orgDefaultLang,

      singleUnit: showUpdateOrgData.singleUnit,
      commonName: commonName,
      regBuildingNameNo: regBuildingNameNo,
      regArea: regArea,
      regPinCd: regPinCd,
      regCity: regCity,
      corrBuildingNameNo: corrBuildingNameNo,
      corrArea: corrArea,
      corrPinCd: corrPinCd,
      corrCountryCode: corrCountryCodeVal !== "" ? corrCountryCodeVal : "",
      corrCity: corrCity,
      regAddressId: showUpdateOrgData.regAddressId,
      corrAddressId: showUpdateOrgData.corrAddressId,
      unitId: showUpdateOrgData.unitId || 40,
      unitName: unitName,
      ownBalanceSheet: balanceSheetOfOwn,
      statutoryCompliances: statutory,
      localTax: localTax,
      gstin: gstin,
      currency: currency,
      pan: userPan,
      tan: userTan,
      epfNo: epfNo,
      esiNo: esiNo,
      latitude: showUpdateOrgData.latitude,
      longitude: showUpdateOrgData.longitude,
      facebookLink: facebookLink,
      twitterLink: twitterLink,
      instagramLink: instagramLink,
      linkedinLink: linkedinLink,
    };

    console.log("reqDto", reqDto);

    // return;

    const fileReqDto = {
      fileName: fileName,
      fileData: fileData_Base64,
    };

    if (fileName !== "" && fileName !== "") {
      updateLogo(userDetails.orgId, userDetails.userId, fileReqDto).then(
        (response) => {
          console.log(response.data);
          if (response.data) {
            if (response.data.returnValue === "1") {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              refreshOrganisationData();
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        }
      );
    }

    // updateOrgDetails(reqDto, userDetails.userId).then((response) => {
    //   if (response.data) {
    //     if (response.data.returnValue === "1") {
    //       toast.success(response.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //       refreshOrganisationData();
    //       handelCloseEditUpdateOrg();
    //     } else {
    //       toast.error(response.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     }
    //   }
    // });

    editOrg(userDetails.userId, reqDto).then((response) => {
      if (response.data) {
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          refreshOrganisationData();
          handelCloseEditUpdateOrg();
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    });
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">Edit Organization</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseEditUpdateOrg()}
          >
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer">
          <div className="formElement">
            <div className="formBtnElement">
              <img
                src={orgLogo}
                alt="orgLogo"
                onError={(e) => {
                  console.error("Error loading image:", e.target.src);
                }}
                style={{ maxHeight: "150px" }}
              />
              <Button
                variant="outlined"
                startIcon={<AttachFile />}
                className="comBtnOutline"
                onClick={() => inputRefLogo.current.click()}
              >
                Change Logo
              </Button>
              <input
                ref={inputRefLogo}
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={(e) => {
                  handleUpdateLogo(e);
                  inputRefLogo.current.value = null;
                }}
                // accept="image"
              />
            </div>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Organization Name"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={orgName}
                onChange={handleOrgName}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Common Name"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={commonName}
                onChange={handleCommonName}
                InputLabelProps={{
                  shrink: commonName && commonName.trim() !== "" ? true : false,
                }}
              />
            </FormControl>
          </div>
          {/* <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Registered Office Address"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={registerOffAddress}
                onChange={handleRegisterOffAddress}
              />
            </FormControl>
          </div> */}

          <div class="tskElemHeding mt10">Registered Address</div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Housing No,Building Name"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={regBuildingNameNo}
                onChange={(e) => {
                  setRegBuildingNameNo(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Area,Colony"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={regArea}
                onChange={(e) => {
                  setRegArea(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="City"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={regCity}
                onChange={(e) => {
                  setRegCity(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement ">
            <FormControl className="formControl">
              <Autocomplete
                id="selectActivity"
                className="formAutocompleteField"
                variant="outlined"
                options={countryData}
                getOptionLabel={(option) => option.countryName || ""}
                value={country}
                inputValue={countryInput}
                onInputChange={handleCountryInput}
                onChange={handleCountry}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    className="formAutoComInputField autocompFildPlaceholder"
                    {...params}
                    label="Country"
                  />
                )}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Pin"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={regPinCd}
                onChange={(e) => {
                  setRegPinCd(e.target.value);
                }}
              />
            </FormControl>
          </div>

          <div class="tskElemHeding mt10">Corresponding Address</div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Housing No,Building Name"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={corrBuildingNameNo}
                onChange={(e) => {
                  setCorrBuildingNameNo(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Area,Colony"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={corrArea}
                onChange={(e) => {
                  setCorrArea(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="City"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={corrCity}
                onChange={(e) => {
                  setCorrCity(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement ">
            <FormControl className="formControl">
              <Autocomplete
                id="selectActivity"
                className="formAutocompleteField"
                variant="outlined"
                options={countryData}
                getOptionLabel={(option) => option.countryName || ""}
                value={corrCountryCode}
                inputValue={corrCountryInput}
                onInputChange={(e, newValue) => {
                  setCorrCountryInput(newValue);
                }}
                onChange={(e, newValue) => setCorrCountryCode(newValue)}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    className="formAutoComInputField autocompFildPlaceholder"
                    {...params}
                    label="Country"
                  />
                )}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Pin"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={corrPinCd}
                onChange={(e) => {
                  setCorrPinCd(e.target.value);
                }}
              />
            </FormControl>
          </div>

          <div class="tskElemHeding mt10">Organization Info</div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Organization Email Id"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={email}
                onChange={handleEmail}
                disabled
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Organization Contact No"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={contactNo}
                onChange={handleContactNo}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="select_depart">Organization Type</InputLabel>
              <Select
                className="formInputField"
                variant="outlined"
                labelId="select_depart"
                id="slect-SelectDepartment"
                value={organizationType}
                label="Select Department"
                onChange={handleOrganizationType}
              >
                <MenuItem key={""} value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem key={"OT0001"} value={"OT0001"}>
                  Public Limited Company
                </MenuItem>
                <MenuItem key={"OT0002"} value={"OT0002"}>
                  Private Limited Company
                </MenuItem>
                <MenuItem key={"OT0003"} value={"OT0003"}>
                  Joint-Venture Company
                </MenuItem>
                <MenuItem key={"OT0004"} value={"OT0004"}>
                  Partnership Firm
                </MenuItem>
                <MenuItem key={"OT0005"} value={"OT0005"}>
                  One Person Company
                </MenuItem>
                <MenuItem key={"OT0006"} value={"OT0006"}>
                  Sole Proprietorship
                </MenuItem>
                <MenuItem key={"OT0007"} value={"OT0007"}>
                  Branch Office
                </MenuItem>
                <MenuItem key={"OT0008"} value={"OT0008"}>
                  Non-Government Organization (NGO)
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="fromDate" className="setCompletionDate">
                Date Of Incorporation
              </InputLabel>
              <ReactDatePicker
                labelId="fromDate"
                className="formDatePicker"
                dateFormat="dd/MM/yyyy"
                showIcon
                selected={startDate}
                onChange={(date) => changeCurrentDate(date)}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Authorized Person"
                variant="outlined"
                className="formTextFieldArea"
                value={authorizedPerson}
                onChange={handleAuthorizedPerson}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Authorized Person Contact No"
                variant="outlined"
                className="formTextFieldArea"
                value={authorizedContact}
                onChange={handleAuthorizedContact}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Trade License No"
                variant="outlined"
                className="formTextFieldArea"
                value={tradeLicense}
                onChange={handleTradeLicense}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Company Identification No"
                variant="outlined"
                className="formTextFieldArea"
                value={companyIdentification}
                onChange={handleCompanyIdentification}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Tax No"
                variant="outlined"
                className="formTextFieldArea"
                value={taxNo}
                onChange={handleTaxNo}
              />
            </FormControl>
          </div>
          {/* <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Address For Correspondence"
                variant="outlined"
                className="formTextFieldArea"
                value={correspondenceAddress}
                onChange={handleCorrespondenceAddress}
              />
            </FormControl>
          </div> */}
          <div className="formElement ">
            <FormControl className="formControl">
              <Autocomplete
                id="selectActivity"
                className="formAutocompleteField"
                variant="outlined"
                options={timeZoneData}
                getOptionLabel={(option) => option.name || ""}
                value={timezone}
                inputValue={timezoneInput}
                onInputChange={handleTimezoneInput}
                onChange={handleTimezone}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    className="formAutoComInputField autocompFildPlaceholder"
                    {...params}
                    label="Timezone"
                  />
                )}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Facebook Link"
                variant="outlined"
                className="formTextFieldArea"
                value={facebookLink}
                onChange={(e) => setFacebookLink(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Instagram Link"
                variant="outlined"
                className="formTextFieldArea"
                value={instagramLink}
                onChange={(e) => setInstagramLink(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Linkedin Link"
                variant="outlined"
                className="formTextFieldArea"
                value={linkedinLink}
                onChange={(e) => setLinkedinLink(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Twitter Link"
                variant="outlined"
                className="formTextFieldArea"
                value={twitterLink}
                onChange={(e) => setTwitterLink(e.target.value)}
              />
            </FormControl>
          </div>

          {showUpdateOrgData.singleUnit === "Y" && (
            <>
              <div class="tskElemHeding mt10">Unit Info</div>

              <div className="formElement">
                <div className="textWithOption">
                  <div className="fromText">Balance sheet maintain by own</div>
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="setTasktype"
                      value={balanceSheetOfOwn}
                      onChange={(e) => {
                        setBalanceSheetOfOwn(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="Y"
                        control={<Radio />}
                        label="Y"
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="N"
                        control={<Radio />}
                        label="N"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="formElement">
                <div className="textWithOption">
                  <div className="fromText">Statutory compliances</div>
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="setTasktype"
                      value={statutory}
                      onChange={(e) => {
                        setStatutory(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="Y"
                        control={<Radio />}
                        label="Y"
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="N"
                        control={<Radio />}
                        label="N"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="formElement">
                <div className="textWithOption">
                  <div className="fromText">Local tax applicable</div>
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="setTasktype"
                      value={localTax}
                      onChange={(e) => setLocalTax(e.target.value)}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="Y"
                        control={<Radio />}
                        label="Y"
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="N"
                        control={<Radio />}
                        label="N"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="Defult_Currency">
                      Default Currency
                    </InputLabel>
                    <Select
                      className="formInputField"
                      variant="outlined"
                      labelId="Defult_Currency"
                      id="compTime-select"
                      value={currency}
                      // value='usd'
                      label="Defult Currency"
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      {/* <MenuItem value={"inr"}>IND Rupee</MenuItem>
                  <MenuItem value={"usd"}> USD </MenuItem> */}
                      {currencyList.map((currency) => (
                        <MenuItem key={currency} value={currency}>
                          {currency}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="formElement width50p">
                <FormControl className="formControl">
                  <TextField
                    label="Unit Name"
                    variant="outlined"
                    className="formTextFieldArea"
                    value={unitName}
                    onChange={(e) => setUnitName(e.target.value)}
                  />
                </FormControl>
              </div>

              <div className="formElementGroup">
                <div className="formElement width50p">
                  <FormControl className="formControl">
                    <TextField
                      label="PAN Id"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={userPan}
                      onChange={(e) => handleUserPan(e)}
                    />
                  </FormControl>
                </div>
                <div className="formElement width50p">
                  <FormControl className="formControl">
                    <TextField
                      label="TAN Id"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={userTan}
                      onChange={(e) => setUserTan(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="formElementGroup">
                <div className="formElement width50p">
                  <FormControl className="formControl">
                    <TextField
                      label="EPF No"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={epfNo}
                      onChange={(e) => setEpfNo(e.target.value)}
                    />
                  </FormControl>
                </div>
                <div className="formElement width50p">
                  <FormControl className="formControl">
                    <TextField
                      label="ESI No"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={esiNo}
                      onChange={(e) => setEsiNo(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="formElementGroup">
                <div className="formElement width50p">
                  <FormControl className="formControl">
                    <TextField
                      label="GST No"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={gstin}
                      onChange={(e) => setGstin(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button className="dfultPrimaryBtn" onClick={() => finalSubmit()}>
              Save
            </Button>
            <Button className="dfultDarkSecondaryBtn">Cancel</Button>
          </div>
        </div>
      </div>
    </>
  );
}
