import React, { useEffect, useState } from "react";
import { RfpForm } from "./RfpForm";
import DateUtils from "../../../../../utils/DateUtils";
import { PoForm } from "./PoForm";
import { GrnForm } from "./GrnForm";
import { PaymentAgainstReimbursementForm } from "./PaymentAgainstReimbursementForm";
import { PaymentAgainstSIP } from "./PaymentAgainstSIP";
import { SIPInfoForm } from "./SIPInfoForm";
import { SortListResume } from "./SortListResume";
import { InterviewFeedbackForm } from "./InterviewFeedbackForm";
import { PaymentAgainstAdvance } from "./PaymentAgainstAdvance";

export const PurchaseFormRenderer = ({
  taskNature,
  taskId,
  formData,
  handleClose,
  handleSubmitPO,
  data,
}) => {
  const [viewOnlyIndDetails, setViewOnlyIndDetails] = useState(undefined);
  useEffect(() => {
    console.log("purchaseIndent=");
  }, []);

  return (
    <>
      {taskNature === "SUBMIT_RFP" && (
        <RfpForm
          taskId={taskId}
          taskNature={taskNature}
          formData={formData}
          handleClose={handleClose}
        />
      )}

      {taskNature === "PO_APPROVAL_REQUEST" && (
        <>
          <PoForm
            taskNature={taskNature}
            formData={formData}
            handleClose={handleClose}
            handleSubmitPO={handleSubmitPO}
          />
        </>
      )}

      {taskNature === "GRN_ENTRY" && (
        <>
          <GrnForm
            taskId={taskId}
            formDataTrans={data.formDataTransId}
            taskNature={taskNature}
            formData={formData}
            handleClose={handleClose}
          />
        </>
      )}

      {taskNature === "PAYMENT_AGAINST_REIMBURSEMENT_REQUEST" && (
        <PaymentAgainstReimbursementForm
          taskId={taskId}
          formDataTrans={data.formDataTransId}
          taskNature={taskNature}
          formData={formData}
          handleClose={handleClose}
        />
      )}

      {taskNature === "PAYMENT_AGAINST_ADVANCE_REQUEST" && (
        <PaymentAgainstAdvance
          taskId={taskId}
          formDataTrans={data.formDataTransId}
          taskNature={taskNature}
          formData={formData}
          handleClose={handleClose}
        />
      )}

      {taskNature === "PAYMENT_AGAINST_SIP_REQUEST" && (
        <PaymentAgainstSIP
          taskId={taskId}
          formDataTrans={data.formDataTransId}
          taskNature={taskNature}
          formData={formData}
          handleClose={handleClose}
        />
      )}

      {taskNature === "SEARCH_MANPOWER" && (
        <SIPInfoForm
          taskId={taskId}
          formDataTrans={data.formDataTransId}
          taskNature={taskNature}
          formData={formData}
          handleClose={handleClose}
        />
      )}

      {taskNature === "SHORTLIST_RESUMES" && (
        <SortListResume
          taskId={taskId}
          formDataTrans={data.formDataTransId}
          taskNature={taskNature}
          formData={formData}
          handleClose={handleClose}
        />
      )}

      {taskNature === "INTERVIEW_FEEDBACK" && (
        <InterviewFeedbackForm
          taskId={taskId}
          formDataTrans={data.formDataTransId}
          taskNature={taskNature}
          formData={formData}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
