import { Box, Button, IconButton } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import mtLogo from "../../../images/mtlogo.png";
import { Business, Call, Email, Language } from "@mui/icons-material";
import DateUtils from "../../../../../utils/DateUtils";
import { getTempNo, issueLoi } from "../../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { toast } from "react-toastify";

export default function PreviewLOI({
  showPreviewLOIModel,
  handelClosePreviewLOI,
  previewData,
  onSubmitSuccess,
  activity,
}) {
  const loginUserDetails = getLoginUserDetails();
  const [pageId, setPageId] = useState(1);
  const [tempNo, setTempNo] = useState();

  const salaryInfo = useMemo(() => {
    const temp = [];
    const tempMap = new Map();

    console.log("salaryInfoList= ", previewData.salaryInfoList);
    previewData.salaryInfoList &&
      previewData.salaryInfoList.forEach((element) => {
        if (element.salaryHeadGroup) {
          const headData = tempMap.get(element.salaryHeadGroup);
          if (headData) {
            tempMap.set(element.salaryHeadGroup, {
              anualTotal: headData.anualTotal + element.annual,
              monthlyTotal: headData.monthlyTotal + element.amount,
              headList: [...headData.headList, element],
            });
          } else {
            tempMap.set(element.salaryHeadGroup, {
              anualTotal: element.annual,
              monthlyTotal: element.amount,
              headList: [element],
            });
          }
        }
      });

    const salaryInfoData = Array.from(tempMap);
    console.log("salaryInfoData=", salaryInfoData);
    return salaryInfoData;
  }, [previewData]);

  const handleSubmitLOI = () => {
    const reqDocs = previewData.staticDodList.map((dod) => dod.id);
    // const salaryBrkUps=previewData.salaryInfoList.map(item=>({
    //   salaryHeadId: 0,
    //       salaryHeadName: item.salaryHead,
    //       salaryHeadGroupName: "string",
    //       pc: 0,
    //       pcOnSalaryHeadId: 0,
    //       amount: 0,
    //       monthly: 0,
    //       yearly: 0,
    // }))
    const salaryBrkUps = [];

    previewData.salaryInfoList.forEach((element) => {
      const temp = {
        salaryHeadId: element.salaryHeadId,
        salaryHeadName: element.salaryHeadName,
        salaryHeadGroupName: element.salaryHeadGroup,
        pc: element.calculationPercentage,
        pcOnSalaryHeadId: element.calculationBasisHeadId,
        amount: element.amount,
        monthly: element.amount,
        yearly: element.annual,
      };
      salaryBrkUps.push(temp);
    });
    const candidate = {
      title: previewData.employeeTitle,
      firstName: previewData.firstName,
      middleName: previewData.middleName,
      lastName: previewData.lastName,
      email: previewData.email,
      addressLine1: previewData.address1,
      addressLine2: previewData.address2,
      pinCode: previewData.pin,
      countryCode: previewData.country.countryCode,
      stateId: previewData.state.id,
      city: previewData.city,
    };

    const reqDto = {
      candidate: candidate,
      functionId: previewData.department.functionId,
      positionId: previewData.position.positionId,
      unitId: previewData.location.unitId,
      expiryDate: DateUtils.getDateInDDMMYYYY(previewData.loiexpdate),
      joiningDate: DateUtils.getDateInDDMMYYYY(previewData.joiningdate),
      reqDocs: reqDocs,
      employmentType: previewData.employmentTitle,
      grossPerMnth: parseInt(previewData.gpm.replace(/,/g, "")),
      ctc: parseInt(previewData.ctc.replace(/,/g, "")),
      totalAmount: parseInt(previewData.gpm.replace(/,/g, "")),
      saveReqDocs: previewData.saveReqDocs,
      activityId: activity.id,
      activityName: activity.name,
      salaryBrkUps: salaryBrkUps,
    };

    issueLoi(loginUserDetails.userId, loginUserDetails.orgId, reqDto).then(
      (response) => {
        if (response.data.returnValue === "1") {
          toast.success(response.data.message);
          onSubmitSuccess();
        } else {
          toast.error(response.data.message);
        }
      }
    );
  };

  function convertNumberToWords(num) {
    if (!num) {
      return;
    }
    let number;
    console.log("nubmers =", typeof number);
    const numberArray = num.split(",");
    let str = "";
    numberArray.forEach((element) => {
      str += element;
    });
    number = parseInt(str);
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    function convertThreeDigits(num) {
      let str = "";

      if (num >= 100) {
        str += units[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
      }

      if (num >= 11 && num <= 19) {
        str += teens[num - 10] + " ";
      } else if (num === 10 || num >= 20) {
        str += tens[Math.floor(num / 10)] + " ";
        num %= 10;
      }

      if (num >= 1 && num <= 9) {
        str += units[num] + " ";
      }

      return str;
    }

    if (number === 0) {
      return "Zero";
    }

    let words = "";
    let crore = Math.floor(number / 10000000);
    number %= 10000000;
    let lakh = Math.floor(number / 100000);
    number %= 100000;
    let thousand = Math.floor(number / 1000);
    let remainder = number % 1000;

    if (crore > 0) {
      words += convertThreeDigits(crore) + "Crore ";
    }

    if (lakh > 0) {
      words += convertThreeDigits(lakh) + "Lac ";
    }

    if (thousand > 0) {
      words += convertThreeDigits(thousand) + "Thousand ";
    }

    if (remainder > 0) {
      words += convertThreeDigits(remainder);
    }

    return words.trim() + " only";
  }

  const calculatePerMonthTotal = (itemList) => {
    const initialValue = 0;
    const totalValue = itemList.reduce(
      (accumulator, current) => accumulator + current[1].monthlyTotal,
      initialValue
    );
    return totalValue;
  };

  const calculatePerYearTotal = (itemList) => {
    const initialValue = 0;
    const totalValue = itemList.reduce(
      (accumulator, current) => accumulator + current[1].anualTotal,
      initialValue
    );
    return totalValue;
  };

  useEffect(() => {
    getTempNo("LOI", loginUserDetails.orgId).then((response) => {
      setTempNo(response.data);
    });
  }, []);

  return (
    <>
      <div
        className="modelContainer previewDocument"
        open={showPreviewLOIModel}
      >
        <Box className="modelContain animated fullwidthModel">
          <IconButton className="closeModelIoc" onClick={handelClosePreviewLOI}>
            <XLg />
          </IconButton>

          <div className="fwModelContainArea">
            <div className="orgDocheaderPrv">
              <div className="orgDocHedLogo">
                <img src={previewData.orgData.orgLogo} alt="org Logo" />
                <div>{previewData.orgData.orgName}</div>
              </div>
              <div className="orgDocHedInfo">
                <div className="orgDocHedInfoElement">
                  <span>{previewData.orgData.contactNo}</span>
                  <span>
                    <Call />
                  </span>
                </div>
                <div className="orgDocHedInfoElement">
                  <span>{previewData.orgData.orgEmailId}</span>
                  <span>
                    <Email />
                  </span>
                </div>
                <div className="orgDocHedInfoElement">
                  <span>{previewData.orgData.subDomain}.zoyel.one</span>
                  <span>
                    <Language />
                  </span>
                </div>
              </div>
            </div>

            {pageId === 1 && (
              <>
                <div className="orgUsrDemogrphy">
                  <div className="orgUserEmpInfo">
                    <div className="loiGret">To</div>
                    <div className="loiGret">{`${previewData.employeeTitle}. ${previewData.firstName} ${previewData.middleName} ${previewData.lastName}`}</div>
                    <div className="loiGret adlinOne">{`${previewData.address1},`}</div>
                    <div className="loiGret adlinTwo">
                      {`${previewData.address2},`}
                    </div>
                    <div className="loiGret adlinTwo">{`${previewData.city},${previewData.state.name}`}</div>
                    <div className="loiGret adlinThree">{`${previewData.pin}, ${previewData.country.countryName}`}</div>
                  </div>
                  <div className="orgLoiDT">
                    <div className="LoiMemoNo">{tempNo}</div>
                    <div className="LoiDate">
                      Date:{" "}
                      <span>{DateUtils.getDateInFormalFormat(new Date())}</span>
                    </div>
                  </div>
                </div>

                <div className="loiContain">
                  <p className="preSubHead">Sub : Letter of Intent</p>
                  <p>Dear Mr. Sanket Santra,</p>
                  <p>
                    With reference to your application and further discussion
                    you had with us, we are pleased to offer you the position of
                    <span>
                      {previewData.position && previewData.position.position}
                    </span>
                    –
                    <span>
                      {" "}
                      {previewData.department &&
                        previewData.department.functionName}
                    </span>{" "}
                    in
                    <span>{previewData.orgData.orgName}</span> at our
                    <span>
                      {previewData.location.unitName},
                      {
                        previewData.location.organizationAddressDto
                          .addressDisplay
                      }
                    </span>
                    .Your proposed compensation package is annexed herewith.
                  </p>
                  <p>
                    We would like you to join with us on or before
                    <span>
                      {DateUtils.getDateInFormalFormat(previewData.loiexpdate)}
                    </span>{" "}
                    failing which; this Letter of Intent stands withdrawn,
                    unless a new date is mutually agreed upon, by us in writing.
                  </p>
                  <p>
                    A formal Appointment Letter will be issued to you on your
                    joining the organization, subject to fulfillment of
                    educational qualification, working experience and others
                    furnished by you in your resume submitted to us.
                  </p>
                  {/* <p>
                    You will be entitled to an annual compensation of INR
                    <span>
                      {`${previewData.ctc} (${convertNumberToWords(
                        previewData.ctc
                      )})`}
                    </span>
                    comprising of a
                    {previewData.salaryInfoList &&
                      previewData.salaryInfoList.map((info, index) => (
                        <>
                          {index !== 0 && <>,</>}
                          <span>
                            {info.salaryHeadName} of Rs {info.amount}
                          </span>
                        </>
                      ))}
                    .
                  </p> */}
                </div>
                <div className="docReqArea">
                  <p className="highText">
                    On the date of your joining, you will have to provide the
                    signed and scanned copies of the following documents:
                  </p>
                  <ul>
                    {previewData.staticDodList &&
                      previewData.staticDodList.map((staticDod) => (
                        <li>{staticDod.name}.</li>
                      ))}
                    {/* <li>
                  Copies of Educational Certificates attested by Gazetted
                  Officer.
                </li>
                <li>
                  Relieving certificate from the previous employer / Acceptance
                  ofresignation
                </li> */}
                  </ul>
                  <p>
                    This Letter of Offer is conditional, subject to the above
                    being received and verified by the appropriate authorities
                    (including Police Verification). Please sign the duplicate
                    copy of this letter as a token of your acceptance and return
                    the same to us.
                  </p>
                </div>

                <div className="loiFooter">
                  <p>Look forward to working with you.</p>
                  <p>Regards,</p>
                  <p>{loginUserDetails.userName}</p>
                  <p className="highText">Team HR</p>
                  <p className="highText">{previewData.orgData.orgName}</p>
                </div>
              </>
            )}

            {pageId === 2 && (
              <>
                <table className="salaryTable">
                  <tbody>
                    <tr>
                      <th colSpan={4}>Remuneration Package</th>
                    </tr>
                    <tr>
                      <td colSpan={2}>Name</td>
                      <td
                        colSpan={2}
                      >{`${previewData.employeeTitle}. ${previewData.firstName} ${previewData.middleName} ${previewData.lastName}`}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>Position</td>
                      <td colSpan={2}>
                        {previewData.position && previewData.position.position}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>Department</td>
                      <td colSpan={2}>
                        {previewData.department &&
                          previewData.department.functionName}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>Company</td>
                      <td colSpan={2}>{previewData.orgData.orgName}</td>
                    </tr>
                    <tr>
                      <th colSpan={2}>Salary Head</th>
                      <th colSpan={1}>PM(per month)</th>
                      <th colSpan={1}>PA(per annum)</th>
                    </tr>
                    {salaryInfo &&
                      salaryInfo.map((info) => {
                        return (
                          <>
                            <tr>
                              <th colSpan={2}>{info[0]}</th>
                              <th colSpan={1} className="tabAlignCent">
                                {info[1].monthlyTotal}
                              </th>
                              <th colSpan={1} className="tabAlignCent">
                                {info[1].anualTotal}
                              </th>
                            </tr>
                            {info[1].headList.map((head) => {
                              return (
                                <>
                                  <tr>
                                    <td colSpan={2}>{head.salaryHeadName}</td>
                                    <td colSpan={1} className="tabAlignCent">
                                      {head.amount}
                                    </td>
                                    <td colSpan={1} className="tabAlignCent">
                                      {head.annual}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </>
                        );
                      })}

                    <tr>
                      <td colSpan={4}> </td>
                    </tr>
                    <tr>
                      <th colSpan={2}>Total</th>
                      <th colSpan={1}>{calculatePerMonthTotal(salaryInfo)}</th>
                      <th colSpan={1}>{calculatePerYearTotal(salaryInfo)}</th>
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            <div className="modActionBtnGrp actionsCreateOrg">
              {pageId === 1 && (
                <>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={handelClosePreviewLOI}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={(e) => {
                      setPageId(2);
                    }}
                  >
                    Next Page
                  </Button>
                </>
              )}

              {pageId === 2 && (
                <>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={(e) => {
                      setPageId(1);
                    }}
                  >
                    Prev Page
                  </Button>

                  <Button
                    className="dfultPrimaryBtn"
                    // onClick={handleSubmit(onSubmit)}
                    onClick={handleSubmitLOI}
                  >
                    Submit
                  </Button>
                </>
              )}
            </div>
          </div>
        </Box>
      </div>
    </>
  );
}
