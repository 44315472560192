import { AccountTree, Add } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Modal,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import { getUnits, deactivateUnit } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

export default function DefineUnits({
  handleOpenMenuButton,
  handleOpenUnitTreeView,
  refreshUnit,
  handleOpenUpdateUnits,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const [unitsData, setUnitData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUnitData();
  }, [refreshUnit]);

  const fetchUnitData = () => {
    getUnits(userDetails.orgId)
      .then((response) => {
        console.log("getUnits", response.data);
        const sortedUnits = response.data.sort((a, b) => a.unitId - b.unitId);
        setUnitData(sortedUnits);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  // const handleDelete = (unitId) =>{
  //   console.log('handleDelete', unitId)
  //   deactivateUnit(unitId , userDetails.userId)
  //   .then((response)=>{
  //     console.log('data deleted', response.data);
  //     if (response.data.returnValue === "1") {
  //       toast.success(response.data.message , {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       fetchUnitData();
  //     }
  //   })
  //   }

  const [openModal, setOpenModal] = useState(false);

  const [unitToDelete, setUnitToDelete] = useState(null);

  const handleDelete = (unitId) => {
    console.log("handleDelete", unitId);
    setUnitToDelete(unitId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    if (unitToDelete) {
      deactivateUnit(unitToDelete, userDetails.userId).then((response) => {
        console.log("data deleted", response.data);
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchUnitData();
          setOpenModal(false); // Close the modal after deletion
        }
      });
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="createUnitsArea">
        <div className="cuHeaderGrp">
          <div className="cuhederTxt">Unit List</div>
          <div className="addUnitsBtn">
            <Button
              startIcon={<AccountTree />}
              className="dfultDarkSecondaryBtn"
              onClick={() => handleOpenUnitTreeView()}
            >
              Unit Tree
            </Button>
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => handleOpenMenuButton()}
            >
              Add Units
            </Button>
          </div>
        </div>
        <div className="cuContainArea">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : unitsData.length === 0 ? (
            <div class="noDataCard ">
              <span>Oops!!</span>No Units Define yet !!
              <br />
              <span className="sallMsg">
                Click Add Units Button to create new Units
              </span>
            </div>
          ) : (
            <div className="cuCardList">
              {unitsData.map((unit, index) => (
                <Card key={index} className="cardContent">
                  <CardContent>
                    <div className="CUcardTitle">{unit.unitName}</div>

                    {/* <div className="CUCardLocation">
                      {unit.organizationAddressDto.buildingNameNo +
                        ", " +
                        unit.organizationAddressDto.area +
                        ", " +
                        unit.organizationAddressDto.city +
                        ", " +
                        unit.organizationAddressDto.stateName +
                        ", " +
                        unit.organizationAddressDto.pinCd}
                    </div> */}

                    <div className="CUCardLocation">
                      {`${
                        unit.organizationAddressDto.buildingNameNo
                          ? unit.organizationAddressDto.buildingNameNo + ", "
                          : ""
                      }
                      ${
                        unit.organizationAddressDto.area
                          ? unit.organizationAddressDto.area + ", "
                          : ""
                      }${
                        unit.organizationAddressDto.city
                          ? unit.organizationAddressDto.city + ", "
                          : ""
                      }${
                        unit.organizationAddressDto.stateName
                          ? unit.organizationAddressDto.stateName + ", "
                          : ""
                      }${
                        unit.organizationAddressDto.pinCd
                          ? unit.organizationAddressDto.pinCd + ", "
                          : ""
                      }`}
                    </div>
                    {unit.timezone && (
                      <div className="cuCheckItem">
                        Timezone: <span>{unit.timezone}</span>
                      </div>
                    )}

                    <div className="cuCheckItem">
                      Cost Center:{" "}
                      <span>{unit.costCenter === "Y" ? "Yes" : "No"}</span>
                    </div>

                    <div className="cuCheckItem">
                      Profit Center:{" "}
                      <span>{unit.profitCenter === "Y" ? "Yes" : "No"}</span>
                    </div>

                    <div className="cuCheckItem">
                      Balance sheet maintain by own:{" "}
                      <span>{unit.ownBalanceSheet === "Y" ? "Yes" : "No"}</span>
                    </div>

                    <div className="cuCheckItem">
                      Statutory compliances:{" "}
                      <span>
                        {unit.statutoryCompliances === "Y" ? "Yes" : "No"}
                      </span>
                    </div>

                    <div className="cuCheckItem">
                      Local tax applicable:{" "}
                      <span>{unit.localTax === "Y" ? "Yes" : "No"}</span>
                    </div>

                    <div className="cuCheckItem">
                      Default Currency:{" "}
                      <span>
                        {unit.currency && unit.currency.toUpperCase()}
                      </span>
                    </div>

                    {unit.pan && unit.pan.trim() !== "" && (
                      <div className="cuCheckItem">
                        PAN: <span>{unit.pan}</span>
                      </div>
                    )}
                    {unit.tan && unit.tan.trim() !== "" && (
                      <div className="cuCheckItem">
                        TAN: <span>{unit.tan}</span>
                      </div>
                    )}
                    {unit.epfNo && unit.epfNo.trim() !== "" && (
                      <div className="cuCheckItem">
                        EPF No: <span>{unit.epfNo}</span>
                      </div>
                    )}
                    {unit.gstin && unit.gstin.trim() !== "" && (
                      <div className="cuCheckItem">
                        GST No: <span>{unit.gstin}</span>
                      </div>
                    )}
                  </CardContent>
                  <CardActions>
                    <div className="taskVDContBtn">
                      <Button
                        variant="contained"
                        startIcon={<Trash />}
                        onClick={() => handleDelete(unit.unitId)}
                      >
                        Delete Unit
                      </Button>
                    </div>
                    <div className="taskVDContBtn">
                      <Button
                        variant="outlined"
                        startIcon={<PencilSquare />}
                        onClick={() => handleOpenUpdateUnits(unit)}
                      >
                        Edit Unit
                      </Button>
                    </div>
                  </CardActions>
                </Card>
              ))}
            </div>
          )}

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                // width: "250px",
                // height: "250px",
                maxWidth: "600px",
                minHeight: "150px",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <h2 id="modal-modal-title">Do you want to delete this Unit ?</h2>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  // variant="contained"
                  // color="secondary"
                  className="dfultPrimaryBtn"
                  onClick={confirmDelete}
                >
                  Yes
                </Button>
                <Button
                  // variant="contained"
                  className="dfultDarkSecondaryBtn"
                  onClick={handleCloseModal}
                >
                  No
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
