import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUserRoleBasedJWTToken,
  getUserRoleBasedJWTTokenGuest,
  getUserRoleBasedJWTTokenNew,
} from "../../services/VideoConferenceService";
import { getLoginUserDetails } from "../../utils/Utils";
const initialState = {
  isFetching: false,
  isError: false,
  errorMessage: "",
};

export const generateJWTToken = createAsyncThunk(
  "/video/getVideoRoomToken",
  async (data, thunkAPI) => {
    console.log("videoConferenceSlice - data ", data);
    // const res = await VideoConferenceService.generateJWTToken(data);
    const loginuserDetails = getLoginUserDetails();
    let isAllowed = localStorage.getItem(`M~${data}`) !== null;

    if (isAllowed) {
      const reqDto = {
        personName: loginuserDetails.userName,
      };
      const res = await getUserRoleBasedJWTTokenNew(
        data,
        loginuserDetails.userId,
        reqDto,
        "Y",
        loginuserDetails.userType === "GUEST" ? "Y" : "N"
      );
      return res.data;
    } else {
      if (loginuserDetails.userType === "GUEST") {
        const reqDto = {
          personName: loginuserDetails.userName,
        };
        const res = await getUserRoleBasedJWTTokenGuest(data, reqDto);
        return res.data;
      } else {
        const res = await getUserRoleBasedJWTToken(
          data,
          loginuserDetails.userId
        );
        return res.data;
      }
    }
  }
);

export const videoConferenceSlice = createSlice({
  name: "VideoConference",
  initialState: 0,
  reducers: {
    // Reducer comes here
  },
  extraReducers: {
    [generateJWTToken.fulfilled]: (state, { payload }) => {
      console.log("fulfilled", payload);
    },
    [generateJWTToken.pending]: (state) => {
      state.isFetching = true;
    },
    [generateJWTToken.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
  },
});
//export default videoConferenceSlice
