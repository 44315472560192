import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expList: [],
  capList: [],
};
const operatingExpListSlice = createSlice({
  name: "operatingExpList",
  initialState,
  reducers: {
    setOperatingExpList(state, action) {
      return action.payload;
    },
    addOperatingExpListItem(state, action) {
      state.expList = [...state.expList, ...action.payload];
    },
    deleteOperatingExpListItem(state, action) {
      state.expList = state.expList.filter((item) => item !== action.payload);
    },
    setCapitalExpList(state, action) {
      return action.payload;
    },
    addCapitalExpListItem(state, action) {
      state.capList = [...state.capList, ...action.payload];
    },
    deleteCapitalExpListItem(state, action) {
      state.capList = state.capList.filter((item) => item !== action.payload);
    },
  },
});

export const {
  setOperatingExpList,
  addOperatingExpListItem,
  deleteOperatingExpListItem,
  setCapitalExpList,
  addCapitalExpListItem,
  deleteCapitalExpListItem,
} = operatingExpListSlice.actions;

export default operatingExpListSlice.reducer;
