import React, { useEffect, useState, useRef } from "react";
import userImage from "../assets/image/userimage.png";

import { Button, IconButton, TextareaAutosize } from "@mui/material";
import { MoreHoriz, Trash } from "iconoir-react";
import EmailMultipleThreads from "./EmailMultipleThreads";
import { ReactMultiEmail } from "react-multi-email";
import EmailUtil from "./EmailUtil";
import { Attachment, Cancel } from "@mui/icons-material";
import { getValidExtentions } from "../../../utils/Utils";
import { toast } from "react-toastify";
import MailRTE from "../../RTE/MailRTE";

export default function EmailReply({
  closeReplayMail,
  originalMailId,
  threadId,
  replyTo,
  ccTo,
  userId,
  message,
  subject,
  mode,
  htmlMsg,
  textMsg,
}) {
  const [fileData, setFileData] = useState(null);
  const fileUploadRef = useRef();
  const [toEmails, setToEmails] = useState([]);
  const [toCc, setToCc] = useState([]);
  const [showEmlThred, setShowEmlThred] = useState(false);
  const toggleEmlThred = () => {
    setShowEmlThred(!showEmlThred);
  };
  const [rteHtml, setRteHtml] = useState("");

  const textRef = useRef();

  const formatTimeToHumanReadable = (unixTimeStamp) => {};

  const generateHtmlReplyBody = () => {
    console.log("Message =", message);
    const parser = new DOMParser();
    const doc = parser.parseFromString(message.body, "text/html");
    const lastMessage = doc.querySelector("body").innerHTML;

    // console.log("body = ", msg);
    // console.log("history = ", message.history);

    let msgToSent = textRef.current.value;

    const msgParts = msgToSent.split("\n");
    let htmlMsg = "";
    for (const msg of msgParts) {
      // htmlMsg += `<div dir="ltr">${msg}</div><br>`;
      htmlMsg += msg === "" ? "<br>" : msg + "<br>";
    }
    // if (msgParts.length > 1) {
    htmlMsg = `<div dir="ltr">${htmlMsg}</div><br>`;
    // }
    htmlMsg = rteHtml;

    console.log("html msg =", htmlMsg);

    const { userName, email } = EmailUtil.extractNameAndEmailFromNameEmail(
      message.sender
    );

    const element = `${htmlMsg}<div class="gmail_quote">
    <div dir="ltr" class="gmail_attr">On ${EmailUtil.formatDate(
      message.dateString
    )} ${userName} &lt;<a href="mailto:${email}">${email}</a>&gt; wrote:<br>
    </div>
    <blockquote class="gmail_quote" style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">
      <div dir="ltr">${lastMessage}</div>
      <br>
      ${message.history ? message.history : ""}
    </blockquote>
  </div>`;
    console.log("value= ", element);
    return element;
  };

  // const formatDate = (dateString) => {
  //   const formattedTime = dateString
  //     .toLocaleString("en-US", {
  //       weekday: "long",
  //       year: "numeric",
  //       month: "short",
  //       day: "numeric",
  //       hour: "numeric",
  //       minute: "numeric",
  //       hour12: true,
  //     })
  //     .replace("at", "");
  //   return formattedTime;
  // };
  const generateTextReplyBody = () => {
    const { msg, historyMsg } = message.parsedMail;
    // console.log("message=", msg);
    // console.log("history=", historyMsg);
    let replyStr = textRef.current.value;
    replyStr += `\nOn ${message.dateString} ${message.sender} \nwrote:\n\n>${msg}\n`;
    const lines = historyMsg.split("\n");
    for (const line of lines) {
      replyStr += `>${line}\n`;
    }
    console.log(" reply string = ", replyStr);
    return replyStr;
  };

  const handleReply = () => {
    const initialValue = "";
    const receiverEmailString = toEmails.reduce(
      (accumulator, currentVal, currentIndex) => {
        if (currentIndex > 0) {
          return accumulator + "," + currentVal;
        }
        return currentVal;
      },
      ""
    );

    const ccReceiverEmailString = toCc.reduce(
      (accumulator, currentVal, currentIndex) => {
        if (currentIndex > 0) {
          return accumulator + "," + currentVal;
        }
        return currentVal;
      },
      ""
    );

    console.log("email string=", receiverEmailString);
    replyToAllUsers(
      userId,
      threadId,
      originalMailId,
      textRef.current.value,
      receiverEmailString,
      ccReceiverEmailString
    );
  };

  const replyToAllUsers = async (
    userId,
    threadId,
    originalMessageId,
    replyBody,
    originalRecipients,
    ccRecipient,
    bccRecipient
  ) => {
    try {
      // Create the reply message
      const textReplyBody = generateTextReplyBody();
      let htmlReplyBody = "";
      if (mode === "forward") {
        htmlReplyBody = htmlMsg;
      } else {
        htmlReplyBody = generateHtmlReplyBody();
      }
      // const boundary = "multipart_boundary";
      const boundary = EmailUtil.generateRandomBoundary();
      const multipartMessage = `MIME-Version: 1.0\r\nContent-Type: multipart/alternative; boundary=${boundary}\r\n\r\n`;
      const plainTextPart = `--${boundary}\r\nContent-Type: text/plain; charset="UTF-8"\r\n\r\n${textReplyBody}\r\n\r\n`;
      const htmlPart = `--${boundary}\r\nContent-Type: text/html; charset="UTF-8"\r\n\r\n${htmlReplyBody}\r\n\r\n`;
      const endBoundary = `--${boundary}--\r\n`;
      const fullMessage =
        multipartMessage + plainTextPart + htmlPart + endBoundary;

      if (originalRecipients && originalRecipients.length === 0) {
        toast.error("Could Not detect receivers");
        return;
      }
      const toHeader =
        originalRecipients && originalRecipients.length > 0
          ? `To: ${originalRecipients}\r\n`
          : "";
      const ccHeader = ccRecipient ? `Cc: ${ccRecipient}` : "";
      // const bccHeader = bccRecipient ? `Bcc: ${message.bcc.join(",")}` : "";
      console.log("to header= ", toHeader);
      // const eml = `From: ${userId}\r\nTo: ${originalRecipients}\r\nIn-Reply-To: <${originalMessageId}>\r\n\r\n${fullMessage}`;

      let attachmentBody = [];

      fileData &&
        fileData.forEach((file) => {
          console.log("file = ", file);

          attachmentBody = [
            ...attachmentBody,
            `--${boundary}`,
            `Content-Type: ${file.mimeType}; name="${file.name}"`,
            "Content-Transfer-Encoding: base64",
            `Content-Disposition: attachment; filename="${file.name}"`,
            "",
            file.data,
          ];
        });

      const eml = [
        `Content-Type: multipart/mixed; boundary=${boundary}`,
        "MIME-Version: 1.0",
        `References:${originalMessageId}`,
        `In-Reply-To: ${originalMessageId}`,
        `Subject: ${mode === "forward" ? "Fwd" : "Re"}:${subject}`,
        toHeader,
        ccHeader,
        "",
        // `--${boundary}`,
        // 'Content-Type: text/plain; charset="UTF-8"',
        // "",
        // "This is text reply",
        // "",
        `--${boundary}`,
        'Content-Type: text/html; charset="UTF-8"',
        "",
        htmlReplyBody,
        "",
        ...attachmentBody,
        `--${boundary}--`,
      ].join("\r\n");

      console.log("eml = ", eml);
      const replyMessage = {
        threadId: threadId,
        // labelIds: ["INBOX"],
        raw: btoa(eml),
      };
      // return;

      // Send the reply using gapi.client.gmail.users.messages.send
      const response = await window.gapi.client.gmail.users.messages.send({
        userId: userId,
        resource: replyMessage,
      });

      console.log("Reply sent:", response.result);
    } catch (error) {
      console.error("Error replying to message:", error);
    }
  };

  useEffect(() => {
    console.log("mail id", originalMailId);
    console.log("threadId", threadId);
    console.log("replyTo", replyTo);
    console.log("userId", userId);
    if (mode === "forward") {
      textRef.current.value = textMsg;
    }
    setToEmails([...replyTo]);
    setToCc([...ccTo]);
    textRef.current.focus();
  }, []);
  return (
    <>
      <div className="emlRplyContainer">
        <div className="showMlDetlCntnr">
          <div className="showMlSummry">
            <div className="mlBsInfoArea">
              <div className="mlbiImg">
                <div className="mlbiimgPosition">
                  <img src={userImage} className="senderImg" alt="sender img" />
                </div>
              </div>
              <div className="mlbiSenderInfo">
                <ReactMultiEmail
                  placeholder="To"
                  emails={toEmails}
                  onChange={(_emails) => {
                    setToEmails(_emails);
                  }}
                  autoFocus={true}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        <div data-tag-item>{email}</div>
                        <span
                          className="removeMailId"
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                        >
                          <Cancel />
                        </span>
                      </div>
                    );
                  }}
                />
              </div>
              {console.log("ccto value = ", ccTo)}

              {ccTo && ccTo.length > 0 && (
                <div className="mlbiSenderInfo">
                  <ReactMultiEmail
                    placeholder="Cc"
                    emails={toCc}
                    onChange={(_emails) => {
                      setToCc(_emails);
                    }}
                    autoFocus={true}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span
                            className="removeMailId"
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            <Cancel />
                          </span>
                        </div>
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="mlreplyBdy">
            <div className="mlinsdDtlwa">
              <div className="rplymailBody">
                <div className="composeMailBody">
                  <TextareaAutosize
                    className="textAreaAutosize"
                    aria-label="minimum height"
                    minRows={10}
                    placeholder=""
                    ref={textRef}
                  />
                  {
                    <MailRTE
                      initialHtml={"<h1> Hello Muntaj </h1>"}
                      setRteHtml={setRteHtml}
                    />
                  }
                </div>
              </div>
              {/* when multiple replay are present  start*/}
              <div className="mlshmlt">
                <IconButton
                  aria-label="view More"
                  size="small"
                  onClick={toggleEmlThred}
                >
                  <MoreHoriz fontSize="small" />
                </IconButton>
              </div>

              {/* {showEmlThred && (
                <div className="mlmltThread">
                  <EmailMultipleThreads />
                </div>
              )} */}

              {/* when multiple replay are present  start*/}

              <div className="composeMailAction">
                <div className="compMailActGrp">
                  <div className="composeSendMail">
                    <Button
                      variant="contained"
                      className="sendeCompMailBtn"
                      onClick={(e) => {
                        console.log(
                          "sent button clicked ",
                          textRef.current.value
                        );
                        // generateTextReplyBody();
                        // generateHtmlReplyBody();
                        handleReply();
                      }}
                    >
                      Send
                    </Button>
                  </div>
                  <div className="composeMailAttacthedFile">
                    <Attachment
                      onClick={(e) => {
                        fileUploadRef.current.click();
                        console.log("open file and get base64 string");
                      }}
                    />
                    <input
                      ref={fileUploadRef}
                      multiple
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        let files = Array.from(e.target.files);
                        console.log(files);
                        const fileList = [];

                        const promises = files.map((file) => {
                          const reader = new FileReader();

                          return new Promise((resolve) => {
                            reader.onloadend = () => {
                              const base64Data = reader.result;
                              const data = base64Data.split(",")[1];
                              const mimeType = base64Data
                                .split(";")[0]
                                .split(":")[1];
                              fileList.push({
                                name: file.name,
                                data: data,
                                mimeType,
                              });
                              resolve();
                            };
                            reader.readAsDataURL(file);
                          });
                        });

                        Promise.all(promises).then(() => {
                          setFileData(fileList);
                        });
                      }}
                      // accept=".xlsx,.csv,.pptx,.docx,.xls,.pdf,.txt,image/*"
                      accept={getValidExtentions() + "image/*"}
                    />
                  </div>
                </div>
                <div className="composeMailDelete">
                  <Trash
                    onClick={() => {
                      closeReplayMail();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
