import React, { useEffect, useRef, useState } from "react";
import "../../../css/calendar.css";
import { Box, Button, IconButton, Modal, Tooltip } from "@mui/material";
import { ClipboardCheck, PersonDown, Plus } from "react-bootstrap-icons";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import DateUtils, { weekDays } from "../../../../../utils/DateUtils";
import WorkOnHoliDay from "./WorkOnHoliDay";
import DashboardServices from "../../../../../services/DashboardServices";
import MarkAttendance from "./MarkAttendance";

export default function WeekView({
  currentWeek,
  selectedDate,
  events,
  holidayList,
  leaveList,
  defaultWorkWeek,
  loginUser,
  startTime,
  endTime,
  showWeekView,
  rows,
  slotDuration,
  cols,
  setSelectedEvent,
  setAdditionalEventDetails,
  setShowAdditionalEventDetails,
  showAdditionalEventDetails,
  setSelectedConvenientTime,
}) {
  const [sortedEventList, setSortedEventList] = useState([]);
  const [tdWidth, setTdWidth] = useState(null);
  const tdRef = useRef(null);

  useEffect(() => {
    console.log("use effect called", events, selectedDate, currentWeek);
    const sortedList = [...events].sort((a, b) => {
      return new Date(a.start) - new Date(b.start);
    });

    console.log("sortedList", new Date().getTime(), sortedList);

    const updateTdWidth = () => {
      if (tdRef.current) {
        const width = tdRef.current.clientWidth;
        setTdWidth(width);
      }
    };
    updateTdWidth();
    window.addEventListener("resize", updateTdWidth);
    setSortedEventList(sortedList);
    generateEventsDisplayMap();
    return () => {
      window.removeEventListener("resize", updateTdWidth);
    };
  }, [events]);

  const displayLeftSideTime = () => {
    const items = [];
    for (let count = startTime; count <= endTime; count++) {
      if (count === 24) {
        continue;
      }
      items.push(
        <>
          <tr>
            <td class="e-time-slots">
              <span>
                {DateUtils.tConvert(
                  count > 9 ? count + ":00" : "0" + count + ":00"
                )}
              </span>
            </td>
          </tr>
          <tr>
            <td class="e-time-slots"></td>
          </tr>
          <tr>
            <td class="e-time-slots"></td>
          </tr>
          <tr>
            <td class="e-time-cells e-time-slots"></td>
          </tr>
        </>
      );
    }
    return items;
  };

  function generateTimeIntervalsRoundedTo15Minutes() {
    const intervals = [];
    const totalMinutesInDay = 24 * 60;

    const currentHours = 0;
    const currentMinutes = 0;

    const remainingMinutesInDay =
      totalMinutesInDay - (currentHours * 60 + currentMinutes);

    const nextMultipleOf15 = Math.ceil(currentMinutes / 15) * 15;
    // console.log(nextMultipleOf15);

    for (
      let minutes = nextMultipleOf15;
      currentHours + Math.floor(minutes / 60) <= 23 && minutes % 60 <= 45;
      minutes += 15
    ) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;

      let period = "AM";
      let formattedHours = currentHours + Math.floor(minutes / 60);

      if (formattedHours >= 12) {
        period = "PM";
        formattedHours = formattedHours === 12 ? 12 : formattedHours - 12;
      }

      const formattedTime = `${String(formattedHours).padStart(
        2,
        "0"
      )}:${String(mins).padStart(2, "0")} ${period}`;

      intervals.push({ time: formattedTime, available: true });
    }

    return intervals;
  }

  const [eventsDisplayMap, setEventsDisplayMap] = useState(new Map());
  const [eventsToDisplay, setEventsToDisplay] = useState(new Map());
  const [refresh, setRefresh] = useState(0);

  // useEffect(() => {
  //   generateEventsDisplayMap();
  // }, [selectedDate]);

  // storedEventDetails = {
  //   eventDto: eventDto,
  //   topOffset: topOffset,
  //   height: height,
  //   left: left,
  // };

  // timeSlotDto.eventDtls = storedEventDetails;

  const displayEvents = () => {
    return eventsToDisplay;
  };

  // useEffect(() => {
  //   console.log("eventsDisplayMap", eventsDisplayMap);

  //   const items = [];
  //   if (eventsDisplayMap == null || eventsDisplayMap.size === 0) {
  //     return;
  //   }
  //   let weekIndex = 0;
  //   for (weekIndex = 0; weekIndex < currentWeek.length; weekIndex++) {
  //     const weekDay = DateUtils.getDateInYYYYMMDD(currentWeek[weekIndex]);

  //     let timeSlotListFirstSlot = eventsDisplayMap.get(
  //       weekDay + "_1"
  //     )?.timeSlots;

  //     const firstSlotWeekDay = [];
  //     let currentEventDto = null;

  //     for (let index = 0; index < timeSlotListFirstSlot?.length; index++) {
  //       const timeSlotDto = timeSlotListFirstSlot[index];
  //       if (
  //         !timeSlotDto.available &&
  //         (currentEventDto == null ||
  //           currentEventDto.eventId !== timeSlotDto.eventDtls.eventDto.eventId)
  //       ) {
  //         currentEventDto = timeSlotDto.eventDtls.eventDto;
  //         console.log(
  //           "currentEventDto",
  //           currentEventDto,
  //           timeSlotDto.eventDtls,
  //           `${timeSlotDto.eventDtls.topOffset}px !important`
  //         );

  //         let style = {
  //           top: timeSlotDto.eventDtls.topOffset + "px",
  //           height: timeSlotDto.eventDtls.height + "px",
  //           left: timeSlotDto.eventDtls.left + "px",
  //           width: timeSlotDto.eventDtls.width + "px",
  //         };

  //         console.log("style---->>>>>>>>", style);

  //         firstSlotWeekDay.push(
  //           <>
  //             <div
  //               className="e-appointment e-lib e-draggable  "
  //               style={style}
  //               key={weekDay + "_1"}
  //             >
  //               <Tooltip
  //                 title={`${
  //                   currentEventDto.title
  //                 } ${DateUtils.displayDateMonthViewToolTip(currentEventDto)}`}
  //                 arrow
  //               >
  //                 <div className="e-appointment-details myCalendar">
  //                   <div
  //                     className="e-subject"
  //                     style={{
  //                       maxHeight: `${timeSlotDto.eventDtls.height}px !important`,
  //                     }}
  //                   >
  //                     {`${currentEventDto.title}`}
  //                   </div>
  //                   <div
  //                     className="e-time"
  //                     style={{ whiteSpace: "break-spaces" }}
  //                   >
  //                     {DateUtils.displayDateMonthViewToolTip(currentEventDto)}
  //                   </div>
  //                 </div>
  //               </Tooltip>
  //             </div>
  //           </>
  //         );
  //       }
  //     }

  //     let timeSlotListSecondSlot = eventsDisplayMap.get(
  //       weekDay + "_2"
  //     )?.timeSlots;

  //     const secondSlotWeekDay = [];
  //     let currentEventDtoSec = null;
  //     for (let index = 0; index < timeSlotListSecondSlot?.length; index++) {
  //       const timeSlotDto = timeSlotListSecondSlot[index];

  //       if (
  //         !timeSlotDto.available &&
  //         (currentEventDtoSec == null ||
  //           currentEventDtoSec.eventId !==
  //             timeSlotDto.eventDtls.eventDto.eventId)
  //       ) {
  //         currentEventDtoSec = timeSlotDto.eventDtls.eventDto;
  //         console.log("currentEventDtoSec", currentEventDtoSec);
  //         let style = {
  //           top: timeSlotDto.eventDtls.topOffset + "px",
  //           height: timeSlotDto.eventDtls.height + "px",
  //           left: timeSlotDto.eventDtls.left + "px",
  //           width: timeSlotDto.eventDtls.width + "px",
  //         };
  //         secondSlotWeekDay.push(
  //           <>
  //             <div
  //               className="e-appointment e-lib e-draggable  "
  //               style={style}
  //               key={weekDay + "_2"}
  //             >
  //               <Tooltip
  //                 title={`${
  //                   currentEventDtoSec.title
  //                 } ${DateUtils.displayDateMonthViewToolTip(currentEventDto)}`}
  //                 arrow
  //               >
  //                 <div className="e-appointment-details myCalendar">
  //                   <div
  //                     className="e-subject"
  //                     style={{
  //                       maxHeight: `${timeSlotDto.eventDtls.height}px !important`,
  //                     }}
  //                   >
  //                     {`${currentEventDtoSec.title}`}
  //                   </div>
  //                   <div
  //                     className="e-time"
  //                     style={{ whiteSpace: "break-spaces" }}
  //                   >
  //                     {DateUtils.displayDateMonthViewToolTip(
  //                       currentEventDtoSec
  //                     )}
  //                   </div>
  //                 </div>
  //               </Tooltip>
  //             </div>
  //           </>
  //         );
  //       }
  //     }

  //     let timeSlotListThirdSlot = eventsDisplayMap.get(
  //       weekDay + "_3"
  //     )?.timeSlots;

  //     const thirdSlotWeekDay = [];
  //     // let currentEventDtoSec = null;
  //     for (let index = 0; index < timeSlotListThirdSlot?.length; index++) {
  //       const timeSlotDto = timeSlotListThirdSlot[index];

  //       if (timeSlotDto.eventList && timeSlotDto.eventList.length > 0) {
  //         let hours = parseInt(timeSlotDto.time.split(":")[0]);
  //         if (timeSlotDto.time.split(" ")[1] === "PM") {
  //           hours += 12;
  //         }
  //         let topOffset = ((60 * hours) / 15) * 21;
  //         // let left = weekIndex * tdWidth;
  //         let left = (tdWidth - 60) / 2 + (tdWidth - 60) / 2;
  //         let style = {
  //           top: topOffset + "px",
  //           height: "42px",
  //           position: "absolute",
  //           // maxHeight: "42px",
  //           left: left + "px",
  //           // width: timeSlotDto.eventDtls.width + "px",
  //         };
  //         thirdSlotWeekDay.push(
  //           <Button
  //             className="e-appointment-details moreEvent"
  //             sx={style}
  //             key={weekDay + "_3"}
  //           >
  //             <div className="e-subject-more" style={{ maxHeight: "42px" }}>
  //               +{timeSlotDto.eventList.length}
  //             </div>
  //           </Button>
  //         );
  //       }
  //     }

  //     items.push(
  //       <>
  //         <td className="e-day-wrapper" key={weekIndex}>
  //           <div className="e-appointment-wrapper" id="e-appointment-wrapper-0">
  //             {firstSlotWeekDay}
  //             {secondSlotWeekDay}
  //             {thirdSlotWeekDay}
  //           </div>
  //         </td>
  //       </>
  //     );
  //   }
  //   console.log(
  //     "refresh---->",
  //     new Date().getMinutes(),
  //     new Date().getSeconds(),
  //     refresh,
  //     items
  //   );
  //   setEventsToDisplay(items);
  //   setRefresh(refresh + 1);

  //   // eventsDisplayMap.forEach((value, key) => {
  //   //   console.log("Key:", key);
  //   //   console.log("Value:", value.timeSlots);

  //   //   items.push(
  //   //     <>
  //   //       <td className="e-day-wrapper">
  //   //         <div className="e-appointment-wrapper" id="e-appointment-wrapper-0">
  //   //           {value.timeSlots.map((timeString) => {
  //   //             return <></>;
  //   //           })}
  //   //         </div>
  //   //       </td>
  //   //     </>
  //   //   );

  //   //   // value.forEach(object => {
  //   //   //   console.log(object);
  //   //   // });
  //   // });
  // }, [eventsDisplayMap]);

  useEffect(() => {
    if (sortedEventList.length > 0) {
      generateEventsDisplayMap();
    }
  }, [sortedEventList]);

  const generateEventsDisplayMap = async () => {
    console.log(
      "generateEventsDisplayMap called---->1",
      new Date().getTime(),
      currentWeek.length,
      sortedEventList
    );
    // setEventsDisplayMap(null);
    // setEventsToDisplay(null);
    let timeDivMap = new Map();

    for (let index = 0; index < currentWeek.length; index++) {
      const element = currentWeek[index];
      let weekDayDateYYYYMMDD = DateUtils.getDateInYYYYMMDD(element);

      timeDivMap.set(weekDayDateYYYYMMDD + "_1", {
        timeSlots: generateTimeIntervalsRoundedTo15Minutes(),
      });

      timeDivMap.set(weekDayDateYYYYMMDD + "_2", {
        timeSlots: generateTimeIntervalsRoundedTo15Minutes(),
      });

      timeDivMap.set(weekDayDateYYYYMMDD + "_3", {
        timeSlots: generateTimeIntervalsRoundedTo15Minutes(),
      });
    }

    for (let index = 0; index < currentWeek.length; index++) {
      const element = currentWeek[index];
      let weekDayDateYYYYMMDD = DateUtils.getDateInYYYYMMDD(element);

      generateWeekDayData(index, weekDayDateYYYYMMDD, timeDivMap);
      // console.log(
      //   "generateEventsDisplayMap called---->1.1",
      //   weekDayDateYYYYMMDD + "_1",
      //   timeDivMap.get(weekDayDateYYYYMMDD + "_1").timeSlots
      // );
      // console.log(
      //   "generateEventsDisplayMap called---->1.2",
      //   weekDayDateYYYYMMDD + "_2",
      //   timeDivMap.get(weekDayDateYYYYMMDD + "_2").timeSlots
      // );
      // console.log(
      //   "generateEventsDisplayMap called---->1.3",
      //   weekDayDateYYYYMMDD + "_3",
      //   timeDivMap.get(weekDayDateYYYYMMDD + "_3").timeSlots
      // );
    }
    // console.log("generateEventsDisplayMap called--->1.4", timeDivMap);
    console.log("generateEventsDisplayMap called---->3", new Date().getTime());
    generateDisplayData(timeDivMap);
    // setRefresh(refresh + 1);

    // return timeDivMap;
  };

  //function to print data --->
  const generateDisplayData = (timeDivMap) => {
    console.log(
      "generateEventsDisplayMap called---->4",
      new Date().getTime(),
      currentWeek.length,
      sortedEventList.length
    );
    console.log("eventsDisplayMap", timeDivMap);

    const items = [];
    if (timeDivMap == null || timeDivMap.size === 0) {
      return;
    }
    let weekIndex = 0;
    for (weekIndex = 0; weekIndex < currentWeek.length; weekIndex++) {
      const weekDay = DateUtils.getDateInYYYYMMDD(currentWeek[weekIndex]);

      let timeSlotListFirstSlot = timeDivMap.get(weekDay + "_1")?.timeSlots;

      const firstSlotWeekDay = [];
      let currentEventDto = null;

      for (let index = 0; index < timeSlotListFirstSlot?.length; index++) {
        const timeSlotDto = timeSlotListFirstSlot[index];
        if (
          !timeSlotDto.available &&
          (currentEventDto == null ||
            currentEventDto.eventId !== timeSlotDto.eventDtls.eventDto.eventId)
        ) {
          currentEventDto = timeSlotDto.eventDtls.eventDto;
          console.log(
            "currentEventDto",
            currentEventDto,
            timeSlotDto.eventDtls,
            `${timeSlotDto.eventDtls.topOffset}px !important`
          );

          let style = {
            top: timeSlotDto.eventDtls.topOffset + "px",
            height: timeSlotDto.eventDtls.height + "px",
            left: timeSlotDto.eventDtls.left + "px",
            width: timeSlotDto.eventDtls.width + "px",
          };

          console.log("style---->>>>>>>>", style);

          firstSlotWeekDay.push(
            <>
              <div
                className="e-appointment e-lib e-draggable  "
                style={style}
                key={weekDay + "_1"}
              >
                <Tooltip
                  title={`${
                    currentEventDto.title
                  } ${DateUtils.displayDateMonthViewToolTip(currentEventDto)}`}
                  arrow
                >
                  <div
                    className={`e-appointment-details ${
                      currentEventDto.source === "google"
                        ? "gCalendar"
                        : "myCalendar"
                    } `}
                    onClick={() => {
                      setSelectedEvent(timeSlotDto.eventDtls.eventDto);
                    }}
                  >
                    <div
                      className="e-subject"
                      style={{
                        maxHeight: `${timeSlotDto.eventDtls.height}px !important`,
                      }}
                    >
                      {`${currentEventDto.title}`}
                    </div>
                    <div
                      className="e-time"
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      {DateUtils.displayDateMonthViewToolTip(currentEventDto)}
                    </div>
                  </div>
                </Tooltip>
              </div>
            </>
          );
        }
      }

      let timeSlotListSecondSlot = timeDivMap.get(weekDay + "_2")?.timeSlots;

      const secondSlotWeekDay = [];
      let currentEventDtoSec = null;
      for (let index = 0; index < timeSlotListSecondSlot?.length; index++) {
        const timeSlotDto = timeSlotListSecondSlot[index];

        if (
          !timeSlotDto.available &&
          (currentEventDtoSec == null ||
            currentEventDtoSec.eventId !==
              timeSlotDto.eventDtls.eventDto.eventId)
        ) {
          currentEventDtoSec = timeSlotDto.eventDtls.eventDto;
          console.log("currentEventDtoSec", currentEventDtoSec);
          let style = {
            top: timeSlotDto.eventDtls.topOffset + "px",
            height: timeSlotDto.eventDtls.height + "px",
            left: timeSlotDto.eventDtls.left + "px",
            width: timeSlotDto.eventDtls.width + "px",
          };
          secondSlotWeekDay.push(
            <>
              <div
                className="e-appointment e-lib e-draggable  "
                style={style}
                key={weekDay + "_2"}
              >
                <Tooltip
                  title={`${
                    currentEventDtoSec.title
                  } ${DateUtils.displayDateMonthViewToolTip(
                    currentEventDtoSec
                  )}`}
                  arrow
                >
                  <div
                    className="e-appointment-details myCalendar"
                    onClick={() => {
                      setSelectedEvent(timeSlotDto.eventDtls.eventDto);
                    }}
                  >
                    <div
                      className="e-subject"
                      style={{
                        maxHeight: `${timeSlotDto.eventDtls.height}px !important`,
                      }}
                    >
                      {`${currentEventDtoSec.title}`}
                    </div>
                    <div
                      className="e-time"
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      {DateUtils.displayDateMonthViewToolTip(
                        currentEventDtoSec
                      )}
                    </div>
                  </div>
                </Tooltip>
              </div>
            </>
          );
        }
      }

      let timeSlotListThirdSlot = timeDivMap.get(weekDay + "_3")?.timeSlots;

      const thirdSlotWeekDay = [];
      // let currentEventDtoSec = null;
      for (let index = 0; index < timeSlotListThirdSlot?.length; index++) {
        const timeSlotDto = timeSlotListThirdSlot[index];

        if (timeSlotDto.eventList && timeSlotDto.eventList.length > 0) {
          let hours = parseInt(timeSlotDto.time.split(":")[0]);
          if (timeSlotDto.time.split(" ")[1] === "PM") {
            hours += 12;
          }
          let topOffset = ((60 * hours) / 15) * 21;
          // let left = weekIndex * tdWidth;
          let left = (tdWidth - 60) / 2 + (tdWidth - 60) / 2;
          let style = {
            top: topOffset + "px",
            height: "42px",
            position: "absolute",
            // maxHeight: "42px",
            left: left + "px",
            // width: timeSlotDto.eventDtls.width + "px",
          };
          thirdSlotWeekDay.push(
            <Button
              className="e-appointment-details moreEvent"
              sx={style}
              key={weekDay + "_3"}
              onClick={() => {
                if (showAdditionalEventDetails) {
                  setShowAdditionalEventDetails(false);
                } else {
                  setAdditionalEventDetails(timeSlotDto.eventList);
                  setShowAdditionalEventDetails(true);
                }
              }}
            >
              <div className="e-subject-more" style={{ maxHeight: "42px" }}>
                +{timeSlotDto.eventList.length}
              </div>
            </Button>
          );
        }
      }

      items.push(
        <>
          <td className="e-day-wrapper" key={weekIndex}>
            <div className="e-appointment-wrapper" id="e-appointment-wrapper-0">
              {firstSlotWeekDay}
              {secondSlotWeekDay}
              {thirdSlotWeekDay}
            </div>
          </td>
        </>
      );
    }
    // console.log(
    //   "refresh---->",
    //   new Date().getMinutes(),
    //   new Date().getSeconds(),
    //   refresh,
    //   items
    // );
    setEventsToDisplay(items);
    // setRefresh(refresh + 1);
  };

  const generateWeekDayData = (weekIndex, weekDayYYYYMMDD, timeDivMap) => {
    // const items = [];
    console.log("generateEventsDisplayMap called---->2", new Date().getTime());
    for (let index = 0; index < sortedEventList.length; index++) {
      const eventDto = sortedEventList[index];
      let eventDateYYYYMMDD = DateUtils.getDateInYYYYMMDD(
        new Date(eventDto.start)
      );
      if (weekDayYYYYMMDD === eventDateYYYYMMDD) {
        generateDayData(eventDto, weekIndex, weekDayYYYYMMDD, timeDivMap);
      }
    }
    // return items;
  };

  const generateDayData = (
    eventDto,
    weekIndex,
    weekDayYYYYMMDD,
    timeDivMap
  ) => {
    const items = [];

    if (timeDivMap.has(weekDayYYYYMMDD + "_1")) {
      let timeSlotsList = timeDivMap.get(weekDayYYYYMMDD + "_1").timeSlots;

      let storedEventDetails = null;
      for (let index = 0; index < timeSlotsList.length; index++) {
        const timeSlotDto = timeSlotsList[index];

        if (storedEventDetails) {
          // console.log(
          //   "isEventJSXStore",
          //   DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)),
          //   timeSlotDto.time,
          //   DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)) ===
          //     timeSlotDto.time
          // );
          if (
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)) ===
            timeSlotDto.time
          ) {
            timeSlotDto.available = false;
            timeSlotDto.eventDtls = storedEventDetails;

            index = timeSlotsList.length;
            break;
          } else {
            timeSlotDto.available = false;
            timeSlotDto.eventDtls = storedEventDetails;
          }
        } else {
          if (
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.start)) ===
            timeSlotDto.time
          ) {
            if (timeSlotDto.available) {
              let topOffset =
                (DateUtils.timeToMinutes(new Date(eventDto.start)) / 15) * 21;
              let height = "32px";
              if (
                DateUtils.getDateInYYYYMMDD(new Date(eventDto.start)) !==
                DateUtils.getDateInYYYYMMDD(new Date(eventDto.end))
              ) {
                var endOfDay = new Date(eventDto.start);
                endOfDay.setHours(23, 59, 0, 0);
                var difference = endOfDay - new Date(eventDto.start);

                // Convert the difference to hours, minutes, and seconds
                var minutesDifference = Math.floor(
                  (difference % (1000 * 60 * 60)) / (1000 * 60)
                );

                height = (minutesDifference / 15) * 21;
              } else {
                height =
                  (DateUtils.getDifferenceInMinutes(
                    eventDto.start,
                    eventDto.end
                  ) /
                    15) *
                  21;
              }

              // let left = weekIndex * tdWidth;

              storedEventDetails = {
                eventDto: eventDto,
                topOffset: topOffset,
                height: height,
                left: 0,
              };

              timeSlotDto.eventDtls = storedEventDetails;
              timeSlotDto.available = false;
            } else if (timeDivMap.has(weekDayYYYYMMDD + "_2")) {
              addEventInSecondSlotForTheDay(
                eventDto,
                weekIndex,
                weekDayYYYYMMDD,
                timeDivMap,
                timeSlotDto
              );
            }
          } else {
          }
        }
      }
    }

    return items;
  };

  const addEventInSecondSlotForTheDay = (
    eventDto,
    weekIndex,
    weekDayYYYYMMDD,
    timeDivMap,
    firstTimeSlotDto
  ) => {
    const items = [];

    let storedEventDetails = null;

    if (timeDivMap.has(weekDayYYYYMMDD + "_2")) {
      let timeSlotsList = timeDivMap.get(weekDayYYYYMMDD + "_2").timeSlots;

      for (let index = 0; index < timeSlotsList.length; index++) {
        const timeSlotDto = timeSlotsList[index];
        if (storedEventDetails) {
          console.log(
            "isEventJSXStore",
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)),
            timeSlotDto.time,
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)) ===
              timeSlotDto.time
          );
          if (
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)) ===
            timeSlotDto.time
          ) {
            timeSlotDto.available = false;
            timeSlotDto.eventDtls = storedEventDetails;

            index = timeSlotsList.length;
            break;
          } else {
            timeSlotDto.available = false;
            timeSlotDto.eventDtls = storedEventDetails;
          }
        } else {
          if (
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.start)) ===
            timeSlotDto.time
          ) {
            if (timeSlotDto.available) {
              let topOffset =
                (DateUtils.timeToMinutes(new Date(eventDto.start)) / 15) * 21;

              // let height =
              //   (DateUtils.getDifferenceInMinutes(
              //     eventDto.start,
              //     eventDto.end
              //   ) /
              //     15) *
              //   21;

              let height = "32px";
              if (
                DateUtils.getDateInYYYYMMDD(new Date(eventDto.start)) !==
                DateUtils.getDateInYYYYMMDD(new Date(eventDto.end))
              ) {
                var endOfDay = new Date(eventDto.start);
                endOfDay.setHours(23, 59, 0, 0);
                var difference = endOfDay - new Date(eventDto.start);

                // Convert the difference to hours, minutes, and seconds
                var minutesDifference = Math.floor(
                  (difference % (1000 * 60 * 60)) / (1000 * 60)
                );

                height = (minutesDifference / 15) * 21;
              } else {
                height =
                  (DateUtils.getDifferenceInMinutes(
                    eventDto.start,
                    eventDto.end
                  ) /
                    15) *
                  21;
              }

              // let left = weekIndex * tdWidth;
              let left = (tdWidth - 60) / 2;
              storedEventDetails = {
                eventDto: eventDto,
                topOffset: topOffset,
                height: height,
                left: left,
                width: (tdWidth - 60) / 2,
              };
              timeSlotDto.eventDtls = storedEventDetails;
              timeSlotDto.available = false;
              firstTimeSlotDto.eventDtls.width = (tdWidth - 60) / 2;
            } else if (timeDivMap.has(weekDayYYYYMMDD + "_3")) {
              let timeSlotsList = timeDivMap.get(
                weekDayYYYYMMDD + "_3"
              ).timeSlots;
              let timeFormat = DateUtils.formatDateTo12HourFormat(
                new Date(eventDto.start)
              );

              console.log(
                "eventDto 3--->>>>>>>>",
                weekDayYYYYMMDD + "_3",
                eventDto,
                timeFormat,
                timeSlotsList
              );

              for (let index = 0; index < timeSlotsList.length; index++) {
                const thirdSlot = timeSlotsList[index];

                if (
                  thirdSlot.time.split(":")[0] === timeFormat.split(":")[0] &&
                  thirdSlot.time.split(" ")[0].split(":")[1] === "00" &&
                  ((timeFormat.split(" ")[1] === "PM" &&
                    thirdSlot.time.split(" ")[1] === "PM") ||
                    (timeFormat.split(" ")[1] === "AM" &&
                      thirdSlot.time.split(" ")[1] === "AM"))
                ) {
                  console.log(
                    "eventDto 3--->>>>>>>> 2",
                    weekDayYYYYMMDD + "_3",
                    eventDto,
                    timeFormat,
                    timeSlotsList
                  );
                  if (thirdSlot.eventList && thirdSlot.eventList.length > 0) {
                    thirdSlot.eventList.push(eventDto);
                  } else {
                    let tempList = [];
                    tempList.push(eventDto);
                    thirdSlot.eventList = tempList;
                  }
                }
              }
            }
          } else {
          }
        }
      }
    }

    return items;
  };

  const [showWorkOnHoliDayModal, setShowWorkOnHoliDayModal] = useState({
    status: false,
    date: "",
  });

  return (
    <>
      <div className="weekViewContainer">
        <div className="scheduler-wrapper">
          <div className="scheduler-header">
            <div className="overview-content">
              <div className="left-panel">
                <div className="overview-scheduler">
                  <div className="e-control e-schedule e-lib schedule-overview e-keyboard">
                    <div className="e-table-container">
                      <div className="e-table-wrap e-vertical-view e-week-view e-current-panel">
                        <ScrollSync>
                          <table className="e-schedule-table e-outer-table">
                            <tbody>
                              <tr>
                                <td className="e-left-indent">
                                  <div className="e-left-indent-wrap">
                                    <table className="e-schedule-table ">
                                      <tbody>
                                        {/* <tr>
                                          <td className="e-resource-cells e-disable-dates"></td>
                                        </tr>
                                        <tr>
                                          <td className="e-header-cells e-disable-dates"></td>
                                        </tr> */}
                                        {/* <tr>
                                          <td
                                            className="e-all-day-cells e-disable-dates e-animate"
                                            // style={{ height: "2.36667em" }}
                                          >
                                            <div className="e-all-day-appointment-section e-appointment-expand e-icons e-disable"></div>
                                          </td>
                                        </tr> */}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>

                                <td>
                                  <div className="e-date-header-container">
                                    <div
                                      className="e-date-header-wrap e-all-day-auto"
                                      style={{ borderRightWidth: "0px" }}
                                    >
                                      <table className="e-schedule-table ">
                                        <tbody>
                                          <tr className="e-header-row">
                                            {currentWeek.map((weekDay) => {
                                              // console.log(weekDay);
                                              return (
                                                <td
                                                  colspan="1"
                                                  className="e-header-cells"
                                                >
                                                  <div>
                                                    <div className="cell_Date_Days">
                                                      {/* <div className="cell_Date todayDD"> */}
                                                      <div
                                                        class={`cell_Date ${
                                                          DateUtils.isToday(
                                                            weekDay
                                                          )
                                                            ? "todayDD"
                                                            : ""
                                                        }`}
                                                      >
                                                        {DateUtils.formatDayWithLeadingZero(
                                                          weekDay
                                                        )}
                                                      </div>
                                                      <div className="date-text cell_Days">
                                                        {/* {weekDay.toLocaleDateString(
                                                          "en-US",
                                                          {
                                                            weekday: "short",
                                                            month: "short",
                                                            year: "numeric",
                                                          }
                                                        )} */}
                                                        {DateUtils.getDateDisplayWeekViewHeader(
                                                          weekDay
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              );
                                            })}
                                            {/* <td
                                              colspan="1"
                                              className="e-header-cells  bgHoliday"
                                            >
                                              <div>
                                                <div className="cell_Date_Days">
                                                  <div className="cell_Date">
                                                    29
                                                  </div>
                                                  <div className="date-text cell_Days">
                                                    Mon - Jan - 2024
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td
                                              colspan="1"
                                              className="e-header-cells"
                                            >
                                              <div>
                                                <div className="cell_Date_Days">
                                                  <div className="cell_Date">
                                                    30
                                                  </div>
                                                  <div className="date-text cell_Days">
                                                    Tus - Jan - 2024
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td
                                              colspan="1"
                                              className="e-header-cells"
                                            >
                                              <div>
                                                <div className="cell_Date_Days">
                                                  <div className="cell_Date">
                                                    31
                                                  </div>
                                                  <div className="date-text cell_Days">
                                                    Wed - Jan - 2024
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td
                                              colspan="1"
                                              className="e-header-cells"
                                            >
                                              <div>
                                                <div className="cell_Date_Days">
                                                  <div className="cell_Date">
                                                    01
                                                  </div>
                                                  <div className="date-text cell_Days">
                                                    Thu - Feb - 2024
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td
                                              colspan="1"
                                              className="e-header-cells"
                                            >
                                              <div>
                                                <div className="cell_Date_Days">
                                                  <div className="cell_Date todayDD">
                                                    02
                                                  </div>
                                                  <div className="date-text cell_Days">
                                                    Fri - Feb - 2024
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td
                                              colspan="1"
                                              className="e-header-cells bgWekOff"
                                            >
                                              <div>
                                                <div className="cell_Date_Days">
                                                  <div className="cell_Date">
                                                    03
                                                  </div>
                                                  <div className="date-text cell_Days">
                                                    Sat - Feb - 2024
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td
                                              colspan="1"
                                              className="e-header-cells bgWekOff"
                                            >
                                              <div>
                                                <div className="cell_Date_Days">
                                                  <div className="cell_Date">
                                                    04
                                                  </div>
                                                  <div className="date-text cell_Days">
                                                    Sun - Feb - 2024
                                                  </div>
                                                </div>
                                              </div>
                                            </td> */}
                                          </tr>
                                          <tr className="e-all-day-row">
                                            {currentWeek.map((date, index) => {
                                              let className = "";
                                              let leaveName = null;
                                              let holidayName = null;
                                              let currentDay =
                                                date.getFullYear() +
                                                "-" +
                                                (date.getMonth() + 1 > 9
                                                  ? date.getMonth() + 1
                                                  : "0" +
                                                    (date.getMonth() + 1)) +
                                                "-" +
                                                (date.getDate() > 9
                                                  ? date.getDate()
                                                  : "0" + date.getDate());
                                              let holidays = holidayList.map(
                                                ({ id }) => id
                                              );

                                              let leave = leaveList.map(
                                                ({ id }) => id
                                              );

                                              let workingDays =
                                                defaultWorkWeek.map(
                                                  ({ day }) => day
                                                );

                                              if (
                                                holidays.indexOf(currentDay) !==
                                                -1
                                              ) {
                                                className =
                                                  "e-all-day-cells e-animate bgHoliday";
                                                holidayName =
                                                  holidayList.filter(
                                                    (holiday) =>
                                                      holiday.id === currentDay
                                                  );
                                              } else if (
                                                leave.indexOf(currentDay) !== -1
                                              ) {
                                                className =
                                                  "e-all-day-cells e-animate bgOnLeav";
                                                leaveName = leaveList.filter(
                                                  (leave) =>
                                                    leave.id === currentDay
                                                );
                                              } else if (
                                                workingDays.indexOf(
                                                  weekDays[date.getDay()]
                                                ) === -1
                                              ) {
                                                className =
                                                  "e-all-day-cells e-animate bgWekOff";
                                              } else {
                                                className =
                                                  "e-all-day-cells e-animate";
                                                holidayName = "";
                                              }

                                              return (
                                                <td
                                                  key={index}
                                                  colspan="1"
                                                  className={className}
                                                >
                                                  <div>
                                                    <div>
                                                      <div className="date-text">
                                                        {/* working on holiday button start */}
                                                        {DateUtils.isToday(
                                                          date
                                                        ) && (
                                                          <MarkAttendance
                                                            loginUser={
                                                              loginUser
                                                            }
                                                          />
                                                        )}
                                                        {(className.includes(
                                                          "bgWekOff"
                                                        ) ||
                                                          className.includes(
                                                            "bgHoliday"
                                                          )) &&
                                                          loginUser.orgId !==
                                                            "ORG000000000000" && (
                                                            <>
                                                              <Tooltip title="Mark As Working Day ">
                                                                <IconButton
                                                                  onClick={() => {
                                                                    setShowWorkOnHoliDayModal(
                                                                      {
                                                                        status: true,
                                                                        date: DateUtils.getDateInDDMMYYYY(
                                                                          date
                                                                        ),
                                                                      }
                                                                    );
                                                                  }}
                                                                  sx={{
                                                                    color:
                                                                      "white !important",
                                                                    left: "40% !important",
                                                                    width:
                                                                      "20px !important",
                                                                    padding:
                                                                      "2px !important",
                                                                  }}
                                                                >
                                                                  <ClipboardCheck />
                                                                </IconButton>
                                                              </Tooltip>
                                                            </>
                                                          )}
                                                        <br></br>
                                                        {/* {date.toLocaleDateString(
                                                          "en-US",
                                                          {
                                                            weekday: "long",
                                                          }
                                                        )} */}
                                                        {null != holidayName &&
                                                          holidayName.length >
                                                            0 &&
                                                          holidayName[0] &&
                                                          `${holidayName[0].name}`}
                                                        {null != leaveName &&
                                                          leaveName.length >
                                                            0 &&
                                                          leaveName[0] &&
                                                          `${leaveName[0].name}`}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              );
                                            })}
                                            {/* <td
                                              colspan="1"
                                              className="e-all-day-cells e-animate bgHoliday"
                                              style={{ height: "2.16667em" }}
                                            ></td>
                                            <td
                                              colspan="1"
                                              className="e-all-day-cells e-animate"
                                              style={{ height: "2.16667em" }}
                                            ></td>
                                            <td
                                              colspan="1"
                                              className="e-all-day-cells e-animate"
                                              style={{ height: "2.16667em" }}
                                            ></td>
                                            <td
                                              colspan="1"
                                              className="e-all-day-cells e-animate"
                                              style={{ height: "2.16667em" }}
                                            ></td>
                                            <td
                                              colspan="1"
                                              className="e-all-day-cells e-animate"
                                              style={{ height: "2.16667em" }}
                                            ></td>
                                            <td
                                              colspan="1"
                                              className="e-all-day-cells e-animate bgWekOff"
                                              style={{ height: "2.16667em" }}
                                            ></td>
                                            <td
                                              colspan="1"
                                              className="e-all-day-cells e-animate bgWekOff"
                                              style={{ height: "2.16667em" }}
                                            ></td> */}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="rightTimeCell">
                                  <ScrollSyncPane group="vertical">
                                    <div className="e-time-cells-wrap leftRightCalHeight">
                                      {/* <div
                                        className="e-current-time"
                                        style={{ top: "1147px" }}
                                      >
                                        01:45 PM
                                      </div> */}
                                      <table className="e-schedule-table ">
                                        <tbody>{displayLeftSideTime()}</tbody>
                                        {/* <tbody>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>12:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>1:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>2:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>3:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>4:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>5:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>6:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>7:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>8:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>9:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>10:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>11:00 AM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>12:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>1:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>2:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>3:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>4:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>5:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>6:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>7:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>8:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>9:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>10:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots">
                                              <span>11:00 PM</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-slots"></td>
                                          </tr>
                                          <tr>
                                            <td className="e-time-cells e-time-slots"></td>
                                          </tr>
                                        </tbody> */}
                                      </table>
                                    </div>
                                  </ScrollSyncPane>
                                </td>

                                <td>
                                  <ScrollSyncPane group="vertical">
                                    <div
                                      className="e-content-wrap classicSchdulerRespons leftRightCalHeight"
                                      //   style={{
                                      //     // height: "407px",
                                      //     overflowY: "auto",
                                      //   }}
                                    >
                                      <table
                                        className="e-schedule-table e-content-table classicSchdulerTable"
                                        role="grid"
                                      >
                                        <thead>
                                          <tr>
                                            {displayEvents()}
                                            {/* {currentWeek && displayEvents()} */}
                                            {/* <td className="e-day-wrapper">
                                              <div
                                                className="e-appointment-wrapper"
                                                id="e-appointment-wrapper-0"
                                              >
                                                <div
                                                  className="e-appointment e-lib e-draggable "
                                                  style={{
                                                    top: "840px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details gCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "42px",
                                                      }}
                                                    >
                                                      Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee)
                                                    </div>
                                                    <div className="e-time">
                                                      (10:00 AM-10:30 AM)
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="e-appointment e-lib e-draggable "
                                                  style={{
                                                    top: "1260px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details myCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "42px",
                                                      }}
                                                    >
                                                      UX Discussion
                                                    </div>
                                                    <div className="e-time">
                                                      (3:00 PM-3:30 PM)
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "1512px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details gCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "42px",
                                                      }}
                                                    >
                                                      Zoyel Development
                                                    </div>
                                                    <div className="e-time">
                                                      (6:00 PM-7:00 PM)
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "1604.4px",
                                                    height: "42px",
                                                    left: "11.1px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details myCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "42px",
                                                      }}
                                                    >
                                                      Instant Meeting
                                                    </div>
                                                    <div className="e-time">
                                                      (7:06 PM-7:36 PM)
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td> */}
                                            {/* <td className="e-day-wrapper">
                                              <div
                                                className="e-appointment-wrapper"
                                                id="e-appointment-wrapper-1"
                                              >
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "840px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <Tooltip
                                                    title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                    arrow
                                                  >
                                                    <div className="e-appointment-details gCalendar">
                                                      <div
                                                        className="e-subject"
                                                        style={{
                                                          maxHeight: "42px",
                                                        }}
                                                      >
                                                        Morning Meeting
                                                        (Applicable for Work
                                                        From Home Employee)
                                                      </div>
                                                    </div>
                                                  </Tooltip>

                                                  <Tooltip
                                                    title="Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee) (10:00 AM - 10:30 AM)"
                                                    arrow
                                                  >
                                                    <div className="e-appointment-details myCalendar">
                                                      <div
                                                        className="e-subject"
                                                        style={{
                                                          maxHeight: "42px",
                                                        }}
                                                      >
                                                        Morning Meeting
                                                        (Applicable for Work
                                                        From Home Employee)
                                                      </div>
                                                    </div>
                                                  </Tooltip>

                                                  <Button className="e-appointment-details moreEvent">
                                                    <div
                                                      className="e-subject-more"
                                                      style={{
                                                        maxHeight: "42px",
                                                      }}
                                                    >
                                                      +3
                                                    </div>
                                                  </Button>
                                                </div>
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "1260px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details myCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "42px",
                                                      }}
                                                    >
                                                      UX Discussion
                                                    </div>
                                                    <div className="e-time">
                                                      (3:00 PM-3:30 PM)
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "1512px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details gCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "84px",
                                                      }}
                                                    >
                                                      Zoyel Development
                                                    </div>
                                                    <div className="e-time">
                                                      (6:00 PM-7:00 PM)
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td className="e-day-wrapper">
                                              <div
                                                className="e-appointment-wrapper"
                                                id="e-appointment-wrapper-2"
                                              >
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "840px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details myCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "84px",
                                                      }}
                                                    >
                                                      Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee)
                                                    </div>
                                                    <div className="e-time">
                                                      (10:00 AM-10:30 AM)
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "1344px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details gCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "84px",
                                                      }}
                                                    >
                                                      UX Discussion
                                                    </div>
                                                    <div className="e-time">
                                                      (4:00 PM-4:30 PM)
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "1512px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details myCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "84px",
                                                      }}
                                                    >
                                                      Zoyel Development
                                                    </div>
                                                    <div className="e-time">
                                                      (6:00 PM-7:00 PM)
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td className="e-day-wrapper">
                                              <div
                                                className="e-appointment-wrapper"
                                                id="e-appointment-wrapper-3"
                                              >
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "840px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details gCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "84px",
                                                      }}
                                                    >
                                                      Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee)
                                                    </div>
                                                    <div className="e-time">
                                                      (10:00 AM-10:30 AM)
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "1512px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details myCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "84px",
                                                      }}
                                                    >
                                                      Zoyel Development
                                                    </div>
                                                    <div className="e-time">
                                                      (6:00 PM-7:00 PM)
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td className="e-day-wrapper">
                                              <div
                                                className="e-appointment-wrapper"
                                                id="e-appointment-wrapper-4"
                                              >
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "840px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details gCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "42px",
                                                      }}
                                                    >
                                                      Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee)
                                                    </div>
                                                    <div className="e-time">
                                                      (10:00 AM-10:30 AM)
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "1512px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details myCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "84px",
                                                      }}
                                                    >
                                                      Zoyel Development
                                                    </div>
                                                    <div className="e-time">
                                                      (6:00 PM-7:00 PM)
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td className="e-day-wrapper">
                                              <div
                                                className="e-appointment-wrapper"
                                                id="e-appointment-wrapper-5"
                                              >
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "840px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details myCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "42px",
                                                      }}
                                                    >
                                                      Morning Meeting
                                                      (Applicable for Work From
                                                      Home Employee)
                                                    </div>
                                                    <div className="e-time">
                                                      (10:00 AM-10:30 AM)
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="e-appointment e-lib e-draggable  "
                                                  style={{
                                                    top: "1512px",
                                                    height: "42px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  <div className="e-appointment-details gCalendar">
                                                    <div
                                                      className="e-subject"
                                                      style={{
                                                        maxHeight: "84px",
                                                      }}
                                                    >
                                                      Zoyel Development
                                                    </div>
                                                    <div className="e-time">
                                                      (6:00 PM-7:00 PM)
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td className="e-day-wrapper">
                                              <div
                                                className="e-appointment-wrapper"
                                                id="e-appointment-wrapper-6"
                                              ></div>
                                            </td> */}
                                          </tr>
                                          {/* <tr>
                                            <td className="e-timeline-wrapper">
                                              <div
                                                class="e-previous-timeline"
                                                style={{ top: "1154px" }}
                                              ></div>
                                            </td>
                                            <td className="e-timeline-wrapper">
                                              <div
                                                class="e-previous-timeline"
                                                style={{ top: "1154px" }}
                                              ></div>
                                            </td>
                                            <td className="e-timeline-wrapper">
                                              <div
                                                class="e-previous-timeline"
                                                style={{ top: "1154px" }}
                                              ></div>
                                            </td>
                                            <td className="e-timeline-wrapper">
                                              <div
                                                class="e-previous-timeline"
                                                style={{ top: "1154px" }}
                                              ></div>
                                            </td>
                                            <td className="e-timeline-wrapper">
                                              <div
                                                class="e-current-timeline"
                                                style={{ top: "1154px" }}
                                              ></div>
                                            </td>
                                            <td className="e-timeline-wrapper">
                                              <div
                                                class="e-previous-timeline"
                                                style={{ top: "1154px" }}
                                              ></div>
                                            </td>
                                            <td className="e-timeline-wrapper">
                                              <div
                                                class="e-previous-timeline"
                                                style={{ top: "1154px" }}
                                              ></div>
                                            </td>
                                          </tr> */}
                                        </thead>
                                        <tbody>
                                          {rows.map((row, rowIndex) => {
                                            //console.log("row", rows);
                                            //console.log("cols", cols);
                                            let noOfDivisions = parseInt(
                                              60 / slotDuration
                                            );
                                            // let rownumber = row % noOfDivisions;
                                            // let rowWidth = (21 * 4) / noOfDivisions;

                                            let className =
                                              "e-work-cells e-alternate-cells";

                                            if (noOfDivisions === 4) {
                                              className =
                                                "e-work-cells e-alternate-cells" +
                                                " firstSlot";
                                            } else if (noOfDivisions === 2) {
                                              className =
                                                "e-work-cells e-alternate-cells" +
                                                " secondSlot";
                                            } else if (noOfDivisions === 1) {
                                              className =
                                                "e-work-cells e-alternate-cells" +
                                                " thirdSlot";
                                            }

                                            let startHour = startTime;
                                            let startMinute =
                                              row * slotDuration;
                                            if (slotDuration === 15) {
                                              if (row === 0) {
                                                startHour = 0;
                                              } else if (row % 4 === 0) {
                                                // startHour = row / 4;
                                              }
                                            } else if (slotDuration === 30) {
                                              if (row === 0) {
                                                startHour = 0;
                                              } else if (row % 2 === 0) {
                                                // startHour = row / 2;
                                              }
                                            } else if (slotDuration === 60) {
                                              if (row === 0) {
                                                startHour = 0;
                                              } else if (row % 1 === 0) {
                                                // startHour = row / 1;
                                              }
                                            }
                                            // console.log(
                                            //   "uuuuuuiiiiiiiiiiiiiiii",
                                            //   "row",
                                            //   row,
                                            //   "startHour",
                                            //   startHour
                                            // );

                                            // let timeValue = new Date(startTime);

                                            // startTime.setHours()

                                            return (
                                              <tr>
                                                {cols.map((col, colIndex) => {
                                                  let timeValueDate = new Date(
                                                    currentWeek[col]
                                                  );

                                                  timeValueDate.setHours(
                                                    startHour,
                                                    startMinute,
                                                    0,
                                                    0
                                                  );

                                                  let fromTime = new Date(
                                                    timeValueDate
                                                  ).toLocaleTimeString(
                                                    "en-US",
                                                    {
                                                      hour: "2-digit",
                                                      minute: "2-digit",
                                                      hour12: true,
                                                    }
                                                  );

                                                  let endTimeStart = new Date(
                                                    timeValueDate
                                                  );

                                                  endTimeStart.setMinutes(
                                                    endTimeStart.getMinutes() +
                                                      slotDuration
                                                  );

                                                  let toTime = new Date(
                                                    endTimeStart
                                                  ).toLocaleTimeString(
                                                    "en-US",
                                                    {
                                                      hour: "2-digit",
                                                      minute: "2-digit",
                                                      hour12: true,
                                                    }
                                                  );

                                                  let timeValue = {
                                                    convStartDate:
                                                      currentWeek[col],
                                                    convFromTime: fromTime,
                                                    convToTime: toTime,
                                                    // difference: slotDuration,
                                                  };

                                                  // console.log(
                                                  //   "timeValue------------------->>>>>>>>>>>>>>>>>>>",
                                                  //   timeValue
                                                  // );

                                                  let tempClassName = className;

                                                  // let selDate =
                                                  //   DateUtils.getDateInDDMMYYYY(
                                                  //     currentWeek[col]
                                                  //   );

                                                  let currentDay =
                                                    currentWeek[
                                                      col
                                                    ].getFullYear() +
                                                    "-" +
                                                    (currentWeek[
                                                      col
                                                    ].getMonth() +
                                                      1 >
                                                    9
                                                      ? currentWeek[
                                                          col
                                                        ].getMonth() + 1
                                                      : "0" +
                                                        (currentWeek[
                                                          col
                                                        ].getMonth() +
                                                          1)) +
                                                    "-" +
                                                    (currentWeek[
                                                      col
                                                    ].getDate() > 9
                                                      ? currentWeek[
                                                          col
                                                        ].getDate()
                                                      : "0" +
                                                        currentWeek[
                                                          col
                                                        ].getDate());

                                                  let colOrgHoliday =
                                                    "bgHoliday";
                                                  let colOrgHLeave = "bgOnLeav";
                                                  let colOrgWeekOff =
                                                    "bgWekOff";
                                                  let holidays =
                                                    holidayList.map(
                                                      ({ id }) => id
                                                    );
                                                  let leave = leaveList.map(
                                                    ({ id }) => id
                                                  );
                                                  let workingDays =
                                                    defaultWorkWeek.map(
                                                      ({ day }) => day
                                                    );
                                                  if (
                                                    holidays.indexOf(
                                                      currentDay
                                                    ) !== -1
                                                  ) {
                                                    tempClassName =
                                                      className +
                                                      " " +
                                                      colOrgHoliday;
                                                  } else if (
                                                    leave.indexOf(
                                                      currentDay
                                                    ) !== -1
                                                  ) {
                                                    tempClassName =
                                                      className +
                                                      " " +
                                                      colOrgHLeave;
                                                  } else if (
                                                    workingDays.indexOf(
                                                      weekDays[
                                                        currentWeek[
                                                          col
                                                        ].getDay()
                                                      ]
                                                    ) === -1
                                                  ) {
                                                    tempClassName =
                                                      className +
                                                      " " +
                                                      colOrgWeekOff;
                                                  } else {
                                                    tempClassName = className;
                                                  }

                                                  if (
                                                    DateUtils.getDifferenceInMinutes(
                                                      timeValueDate,
                                                      new Date()
                                                    ) <= 0
                                                  ) {
                                                    tempClassName +=
                                                      " tdCellsSelectable";
                                                  }

                                                  return (
                                                    <>
                                                      <td
                                                        className={
                                                          false
                                                            ? `${tempClassName} selected`
                                                            : `${tempClassName}`
                                                        }
                                                        aria-selected="false"
                                                        colspan="1"
                                                        // name="Hello"
                                                        ref={tdRef}
                                                        // class={tempClassName}
                                                        //style={style}
                                                        // style={{
                                                        //   cursor:
                                                        //     "pointer !important",
                                                        // }}
                                                        aria-label={
                                                          timeValue.convStartDate +
                                                          timeValue.convFromTime +
                                                          timeValue.convToTime
                                                        }
                                                        onClick={() => {
                                                          if (
                                                            DateUtils.getDifferenceInMinutes(
                                                              timeValueDate,
                                                              new Date()
                                                            ) <= 0
                                                          ) {
                                                            setSelectedConvenientTime(
                                                              timeValue
                                                            );
                                                          }
                                                        }}
                                                      ></td>
                                                    </>
                                                  );
                                                })}
                                              </tr>
                                            );
                                            // } else {
                                            //   return (
                                            //     <tr>
                                            //       {cols.map((col) => {
                                            //         return (
                                            //           <>
                                            //             <td
                                            //               aria-selected="false"
                                            //               colspan="1"
                                            //               class="e-work-cells"
                                            //             ></td>
                                            //           </>
                                            //         );
                                            //       })}
                                            //     </tr>
                                            //   );
                                            // }
                                          })}
                                        </tbody>
                                        {/* <tbody>
                                          <tr>
                                            <td
                                              ref={tdRef}
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot  bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgHoliday"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                            <td
                                              className="e-work-cells e-alternate-cells firstSlot bgWekOff"
                                              aria-selected="false"
                                              colspan="1"
                                            ></td>
                                          </tr>
                                        </tbody> */}
                                      </table>
                                    </div>
                                  </ScrollSyncPane>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </ScrollSync>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showWorkOnHoliDayModal.status && (
        <Modal
          open={showWorkOnHoliDayModal.status}
          onClose={() => setShowWorkOnHoliDayModal({ status: false, date: "" })}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="coustomModel"
        >
          <Box
            className="modelBox"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxHeight: "70vh",
              width: "700px",
              bgcolor: "background.paper",
              boxShadow: 24,
            }}
          >
            <WorkOnHoliDay
              openDialog={showWorkOnHoliDayModal.status}
              dated={showWorkOnHoliDayModal.date}
              closeDialog={() =>
                setShowWorkOnHoliDayModal({ status: false, date: "" })
              }
              loginUser={loginUser}
            />
          </Box>
        </Modal>
      )}
    </>
  );
}
