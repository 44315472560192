import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import "../css/profile.css";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { CloudUpload, Dash, Plus, XLg } from "react-bootstrap-icons";
import { preBuildAvtarBase64 } from "./preBuildAvtarBase64";
import AvatarEditor from "react-avatar-editor";
import { convertFileToBase64_WithOutSplit } from "../../../utils/FileUtils";
import { useTranslation } from "react-i18next";
import DashboardServices from "../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../utils/Utils";
import Loaders from "../../../utils/Loaders";

export const UploadProfileImage = ({ closeUploadModal, setUserProfile }) => {
  const loginUserDetail = getLoginUserDetails();
  const [imageType, setImageType] = useState("prebuilt");
  const [imageSource, setImageSource] = useState("");
  const { t } = useTranslation();
  const [newFileName, setNewFileName] = useState("");
  const [selectedPrebuildImage, setSelectedPrebuildImage] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [scale, setScale] = useState(1);

  let editor = "";

  const setEditorRef = (ed) => {
    editor = ed;
  };

  // const editorRef = useRef(null);

  const handelFileChanges = async (e) => {
    const file = e.target.files[0];
    console.log("handelFileChanges", file);
    const base64String = await convertFileToBase64_WithOutSplit(file);
    setNewFileName(file.name);
    setImageSource(base64String);
  };

  const handleSavePreBuildAvtar = (e) => {
    // setSelectedPreBuildAvatar({ fileName, fileData });
    const avatar = selectedPrebuildImage;
    const fileName = avatar.name;
    const fileData = avatar.src.split(",")[1];
    if (selectedPrebuildImage && selectedPrebuildImage.fileName !== "") {
      DashboardServices.updateProfileImage(
        loginUserDetail.userId,
        loginUserDetail.orgId,
        { fileName, fileData }
        // selectedPrebuildImage
      ).then((response) => {
        // console.log("response", response.data);
        setUserProfile(response.data);
        closeUploadModal(e);
      });
    }
  };

  const handleSaveCropedImage = (e) => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      const raw64 = croppedImg.split(",")[1];
      if (newFileName !== "" && croppedImg) {
        const reqDto = {
          fileName: newFileName,
          fileData: raw64,
        };
        // console.log("reqDto", currentUser.userId, currentUser.orgId, reqDto);
        DashboardServices.updateProfileImage(
          loginUserDetail.userId,
          loginUserDetail.orgId,
          reqDto
        ).then((response) => {
          // console.log("response", response);
          setUserProfile(response.data);
          closeUploadModal(e);
        });
      }
    }
  };

  const selectImageType = (event) => {
    const value = event.target.value;
    setImageType(value);
  };

  //   const handleUploadProfilePic = (e) => {
  //     DashboardServices.handleUploadProfilePic(
  //       loginUserDetail.userId,
  //       loginUserDetail.orgId,
  //       reqDto
  //     );
  //   };

  const handleClickPreBuildAvatar = (data, index) => {
    setSelectedImageIndex(index);
    setSelectedPrebuildImage(data);
  };

  const handleZoomIn = () => {
    setScale(prevScale => Math.min(prevScale + 0.1, 3));
  };

  const handleZoomOut = () => {
    setScale(prevScale => Math.max(prevScale - 0.1,  0.1));
  };

  // const handleScaleChange = ( event , newValue) => {
  //   setScale(newValue);
  // };

  // const handleWheel = (e) => {
  //   e.preventDefault();
  //   const newScale = scale + e.deltaY * -0.01;
  //   setScale(Math.min(Math.max(newScale, 1), 5));
  // };

  // const handleWheel = (e) => {
  //   e.preventDefault();
  //   const zoomFactor = e.deltaY * 0.01;

  //   // Calculate the new scale based on the zoom factor
  //   let newScale = scale + zoomFactor * scale;

  //   // Adjust the zooming speed based on the current scale level
  //   if (newScale > 1 && newScale < 3) {
  //     setScale(Math.min(Math.max(newScale, 1), 3));
  //   } else if (newScale <= 1) {
  //     setScale(1);
  //   } else if (newScale >= 3) {
  //     setScale(3);
  //   }
  // };

  // const handleTouchStart = (e) => {
  //   if (e.touches.length === 2) {
  //     const [touch1, touch2] = e.touches;
  //     const distance = Math.hypot(
  //       touch1.clientX - touch2.clientX,
  //       touch1.clientY - touch2.clientY
  //     );
  //     e.currentTarget.dataset.distance = distance;
  //   }
  // };

  // const handleTouchMove = (e) => {
  //   if (e.touches.length === 2) {
  //     const [touch1, touch2] = e.touches;
  //     const distance = Math.hypot(
  //       touch1.clientX - touch2.clientX,
  //       touch1.clientY - touch2.clientY
  //     );
  //     const scaleFactor = distance / e.currentTarget.dataset.distance;
  //     setScale((prevScale) => Math.min(Math.max(prevScale * scaleFactor, 1), 3));
  //     e.currentTarget.dataset.distance = distance;
  //   }
  // };

  useEffect(() => {
    console.log("selected value =");
  }, []);
  return (
    <>
      <div className="flotingProfilecontainer">
        <div className="profileAreaContainer">
          <div className="profileheader">
            <div className="proheadNm">Profile Image Upload</div>
            <IconButton className="CloseBtn " onClick={closeUploadModal}>
              <XLg />
            </IconButton>
          </div>
          <div className="profileDtlContainer">
            <div className="profileDtlContainerArea">
              <div className="proUserDtlStat">
                <div className="formElement">
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="setTasktype"
                      value={imageType}
                      onChange={selectImageType}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="prebuilt"
                        control={<Radio />}
                        label="Prebuild Avatar"
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="fromFile"
                        control={<Radio />}
                        label="Upload Pic"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              {imageType === "prebuilt" &&
                preBuildAvtarBase64 &&
                preBuildAvtarBase64.length > 0 && (
                  <ul className="preBuildImgList">
                    {preBuildAvtarBase64.map((avatar, index) => (
                      <li
                        onClick={(e) =>
                          handleClickPreBuildAvatar(avatar, index)
                        }
                        className={`${
                          selectedImageIndex === index ? "imgSelected" : ""
                        }`}
                      >
                        <img src={avatar.src} alt={avatar.name} />
                      </li>
                    ))}
                  </ul>
                )}

              {imageType === "fromFile" && (
                <div className="formElement">
                  <FormGroup className="profUpProImg">
                    <input
                      id="coustFileInput"
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={handelFileChanges}
                    />
                    <label
                      htmlFor="coustFileInput"
                      className="coustFileInputBtn"
                    >
                      <CloudUpload />
                      {t("upload")}
                    </label>
                  </FormGroup>
                  <>
                    {imageSource !== "" && (
                      <>
                        <div
                          className="proImgPicker"
                          //  onWheel={handleWheel} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}
                        >
                          <AvatarEditor
                            ref={setEditorRef}
                            image={imageSource}
                            width={250}
                            height={250}
                            borderRadius={"150"}
                            color={[51, 51, 51, 0.6]}
                            scale={scale}
                            rotate={0}
                          />
                        </div>
                        {/* <div className="proImgPicker">
                            <Slider
                              value={scale}
                              min={1}
                              max={3}
                              step={0.1}
                              onChange={handleScaleChange}
                              aria-labelledby="Zoom"
                            />
                          </div> */}
                        <div className="proZoomPicker">
                        <Tooltip title="Zoom Out">
                        <IconButton className="drivInfoBtn" onClick={handleZoomOut} style={{ marginRight: '8px' }}>
                        <Dash />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Zoom In" style={{ marginLeft : '225px' }}>
                         <IconButton className="drivInfoBtn" onClick={handleZoomIn} >
                          <Plus />
                        </IconButton>
                        </Tooltip>
                      </div>
                      </>
                    )}
                  </>
                </div>
              )}
            </div>
          </div>

          {imageType === "fromFile" && imageSource !== "" && (
            <div className="profileFooter">
              <div className="formBtnElement">
                <Button
                  className="dfultPrimaryBtn "
                  onClick={handleSaveCropedImage}
                >
                  Save
                </Button>
              </div>
            </div>
          )}

          {imageType === "prebuilt" && selectedPrebuildImage && (
            <div className="profileFooter">
              <div className="formBtnElement">
                <Button
                  className="dfultPrimaryBtn "
                  onClick={handleSavePreBuildAvtar}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
