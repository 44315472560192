import dayjs from "dayjs";
import React from "react";

export const ReimbursementPreview = ({ reimbursementList }) => {
  return (
    <>
      <div className="indentFormtable">
        <div className="indtableResponsive">
          <table>
            <thead>
              <tr>
                <th className="indntQunt">Description</th>
                <th className="indntQunt">Reason</th>
                <th className="indntQunt">Invoice/Receipt No</th>
                <th className="indntQunt">Invoice Date</th>
                <th className="indntQunt">GRN</th>
                <th className="indntQunt">Tax Amount</th>
                <th className="indntQunt">Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {reimbursementList &&
                reimbursementList.map((item) => {
                  return (
                    <tr>
                      <td>
                        <div className="textOnly">{item.itemDescriptions}</div>
                      </td>
                      <td>
                        <div className="textOnly">{item.reason}</div>
                      </td>
                      <td>
                        <div className="textOnly">{item.invoiceRcptNo}</div>
                      </td>

                      <td>
                        <div className="textOnly">
                          {dayjs(item.completionDate).format("DD-MM-YYYY")}
                        </div>
                      </td>

                      <td>
                        <div className="textOnly">{item.grn}</div>
                      </td>
                      <td>
                        <div className="textOnly">{item.tax}</div>
                      </td>
                      <td>
                        <div className="textOnly">{item.total}</div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
