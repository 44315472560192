import React, { useContext } from "react";
import { AddTabContext } from "./DashboardPage";
import { getLoginUserDetails } from "../../../utils/Utils";
import AppUtils from "../../../utils/AppUtils";
import { v4 as uuidv4 } from "uuid";
import TaskTabContainer from "../../task/view/TaskTabContainer";
import { ListTask } from "react-bootstrap-icons";
import EmailContainerWrapper from "../../email/view/EmailContainerWrapper";
import {
  AdminPanelSettings,
  CalendarMonth,
  ChatBubbleOutline,
  EmailOutlined,
  Event,
  PlaylistAddOutlined,
  StorageOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import ChatContainer from "../../chat/view/ChatContainer";
import SchedulerContainer from "../../scheduler/view/SchedulerContainer";
import { ConfirmProvider } from "material-ui-confirm";
import MeetingsTabContainer from "../../scheduler/view/component/meetings/view/MeetingsTabContainer";
import DriveContainer from "../../drive/view/DriveContainer";
import CreateTaskContainer from "../../task/view/CreateTaskContainer";
import Admin from "../../admin/view/Admin";
import { VendorMenu } from "../../vendor/VendorMenu";
// import "./styles.css";

function NewTabContent({ tabIndex }) {
  const { addTab } = useContext(AddTabContext);
  const loginUser = getLoginUserDetails();
  const loginUserDetail = getLoginUserDetails();

  return (
    <>
      <div className="bottomMnuPopover">
        <div class="bottomMnuPopoverContainer">
          <div class="apps">
            <h2>Apps</h2>
            <ul>
              {!AppUtils.isPersonal(loginUser) && (
                <li
                  onClick={() => {
                    addTab({
                      key: uuidv4(),
                      title: "Task",
                      content: <TaskTabContainer />,
                      isDraggable: true,
                      type: "New",
                      tabIndex: tabIndex,
                    });
                  }}
                >
                  <ListTask className="btmMenuAppsIcon" /> Task
                </li>
              )}
              <li
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: "Email",
                    content: <EmailContainerWrapper />,
                    isDraggable: true,
                    type: "New",
                    tabIndex: tabIndex,
                  });
                }}
              >
                <EmailOutlined className="btmMenuAppsIcon" /> Email
              </li>
              <li
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: "Chat",
                    content: <ChatContainer />,
                    isDraggable: true,
                    type: "New",
                    tabIndex: tabIndex,
                  });
                }}
              >
                <ChatBubbleOutline className="btmMenuAppsIcon" /> Chat
              </li>

              <li
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: "Scheduler",
                    content: <SchedulerContainer />,
                    isDraggable: true,
                    type: "New",
                    tabIndex: tabIndex,
                  });
                }}
              >
                <CalendarMonth className="btmMenuAppsIcon" /> Scheduler
              </li>
              <li
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: "Meeting Room",
                    content: (
                      <ConfirmProvider>
                        <MeetingsTabContainer />
                      </ConfirmProvider>
                    ),
                    isDraggable: true,
                    type: "New",
                    tabIndex: tabIndex,
                  });
                }}
              >
                <VideocamOutlined className="btmMenuAppsIcon" /> Meeting Room
              </li>
              <li
                onClick={() => {
                  const tabkey = uuidv4();
                  console.log("key", tabkey);
                  addTab({
                    key: tabkey,
                    title: "Drive",
                    content: <DriveContainer addTab={addTab} tabkey={tabkey} />,
                    isDraggable: true,
                    type: "New",
                    tabIndex: tabIndex,
                    driveKey: tabkey,
                  });
                }}
              >
                <StorageOutlined className="btmMenuAppsIcon" /> Drive
              </li>

              {loginUserDetail.userType === "ADMIN" &&
                !AppUtils.isPersonal(loginUser) && (
                  <li
                    onClick={() => {
                      const tabkey = uuidv4();
                      console.log("key", tabkey);
                      addTab({
                        key: tabkey,
                        title: "Admin",
                        content: <Admin />,
                        isDraggable: true,
                        type: "New",
                        tabIndex: tabIndex,
                        driveKey: tabkey,
                      });
                    }}
                  >
                    <AdminPanelSettings className="btmMenuAppsIcon" /> Admin
                  </li>
                )}

              {loginUserDetail.userType === "EXTERNAL" &&
                loginUserDetail.externalCategory === "vendor" && (
                  <li
                    onClick={() => {
                      const tabkey = uuidv4();
                      console.log("key", tabkey);
                      addTab({
                        key: tabkey,
                        title: "Vendor Menu",
                        content: <VendorMenu />,
                        isDraggable: true,
                        type: "New",
                        tabIndex: tabIndex,
                        driveKey: tabkey,
                      });
                    }}
                  >
                    <StorageOutlined className="btmMenuAppsIcon" /> Vendor Menu
                  </li>
                )}
            </ul>
          </div>
          <div class="quick-actions">
            <h2>Quick Actions</h2>
            <ul>
              {!AppUtils.isPersonal(loginUser) && (
                <li
                  onClick={() => {
                    addTab({
                      key: uuidv4(),
                      title: "Create Task",
                      content: <CreateTaskContainer />,
                      isDraggable: true,
                      type: "New",
                      tabIndex: tabIndex,
                    });
                  }}
                >
                  <PlaylistAddOutlined className="btmMenuAppsIcon" /> Create a
                  new task
                </li>
              )}
              <li
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: "Scheduler",
                    content: (
                      <SchedulerContainer
                        bottomShortcutDto={{ eventType: "Event" }}
                      />
                    ),
                    isDraggable: true,
                    type: "New",
                    tabIndex: tabIndex,
                  });
                }}
              >
                <Event className="btmMenuAppsIcon" /> Create a new event
              </li>
              <li
                onClick={() => {
                  const key = uuidv4();
                  addTab({
                    key: key,
                    title: "Instant Meeting",
                    content: (
                      <ConfirmProvider>
                        <MeetingsTabContainer
                          startInstantMeeting={true}
                          tabKey={key}
                        />
                      </ConfirmProvider>
                    ),
                    isDraggable: true,
                    type: "New",
                    tabIndex: tabIndex,
                  });
                }}
              >
                <VideocamOutlined className="btmMenuAppsIcon" />
                Start Instant meeting
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewTabContent;
