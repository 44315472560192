import { configureStore } from "@reduxjs/toolkit";
import mailSlice from "./reducers/mailSlice";
import dashboardMailSlice from "./reducers/dashboardMailSlice";
import taskSlice from "./reducers/taskSlice";
import rightSideTaskPanelSlide from "./reducers/rightSideTaskPanelSlice";
import taskListSlice from "./reducers/taskTab";
import operatingExpListReducer from "./reducers/operatingExpListSlice"


const combinedReducer = {
  mail: mailSlice.reducer,
  dashboardMail: dashboardMailSlice.reducer,
  task: taskSlice.reducer,
  taskPanel: rightSideTaskPanelSlide.reducer,
  taskList: taskListSlice.reducer,
  operatingExpList: operatingExpListReducer,
};

const store = configureStore({
  reducer: combinedReducer,
  devTools: true,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(thunk, logger),
});
export default store;
