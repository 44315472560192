import { Add, Edit, Save, Settings } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import {
  getEmployeeSalaryComponents,
  getSalaryConfigMaster,
  updateEmployeeAccountDetails,
  submitEmpSalaryComponent,
  getSalaryComponents,
  getPayrollEmployees
} from "../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../utils/Utils";
import ReactDatePicker from "react-datepicker";
import DateUtils from "../../../../utils/DateUtils";
import { FloatTextField } from "../../../../common/FloatTextField";

export default function ViewPayrollSalaryStructure({
  handelCloseDetailPayroll,
  selectedEmPayroll,
  refreshEmployPayrollData,
}) {
  const userDetails = getLoginUserDetails();

  // console.log('userDetails' , userDetails)

  const [selectedEmployPayrollValue, setSelectedEmployPayrollValue] =
    useState(null);

  const [userAccountNumber, setUserAccountNumber] = useState(null);
  const [userConfirmAccountNumber, setUserConfirmAccountNumber] =
    useState(null);
  const [userBankName, setUserBankName] = useState(null);
  const [userIfscCode, setUserIfscCode] = useState(null);

  const [salaryConfigMasterList, setSalaryConfigMasterList] = useState([]);
  const [salaryConfigGrouped, setSalaryConfigGrouped] = useState({});
  const [userSalaryComponentList, setUserSalaryComponentList] = useState([]);

  const [selectedSalaryComponent, setSelectedSalaryComponent] = useState(null);
  const [userSelectedConfigIdList, setUserSelectedConfigIdList] = useState([]);

  const [editACMode, setEditACMode] = useState(false);
  const [editSalary, setEditSalary] = useState(false);

  const [addnewSalaryHead, setAddnewSalaryHead] = useState({});
  const [newSalaryHeadId, setNewSalaryHeadId] = useState("");
  const [emplyConfigData, setemplyConfigData] = useState([]);
  const [emplySalaryCompData, setemplySalaryCompData] = useState([]);
  const [newSalaryheadData, setNewSalaryHeadData] = useState([]);
  const [groupedConfigurations, setGroupedConfigurations] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [payrollDataOfOrg, setPayrollDataOfOrg] = useState([]);
  const [currentCTC, setCurrentCTC] = useState(selectedEmPayroll.currentCTC);

  // const [inputValues, setInputValues] = useState({});
  const [reasonForEdit, setReasonForEdit] = useState("");
  const [effectivedate, setEffectivedate] = useState(new Date());
  const [emplyCTCData, setEmplyCTCData] = useState(0);

  const [openConfigBox, setOpenConfigBox] = useState(false);

  const [salaryCompData, setSalaryCompData] = useState([]);
  const [salaryCompFilterData, setSalaryCompFilterData] = useState([]);

  const [refreshCount, setRefreshCount] = useState(1);

  const handleAddNewHead = () => {
    if (
      !addnewSalaryHead ||
      !addnewSalaryHead.componentName ||
      addnewSalaryHead.componentName.trim() === ""
    ) {
      toast.error("Please Select New Head", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const extcomp = [...emplySalaryCompData, ...newSalaryheadData];

    console.log('emplySalaryCompData' , emplySalaryCompData);
    console.log('newSalaryheadData' , newSalaryheadData);
    console.log('extcomp', extcomp);

    const existingComponent = extcomp.find(
      (item) => item.componentName === addnewSalaryHead.componentName
    );

    if (existingComponent) {
      toast.error("Salary head already exists", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Adding new salary head:", addnewSalaryHead);
      // setNewSalaryHeadData(addnewSalaryHead);
      setNewSalaryHeadData((prev) => [...prev, addnewSalaryHead]);
      setAddnewSalaryHead({});
    }
  };

  useEffect(() => {
    if (newSalaryheadData.length > 0) {
      console.log('emplySalaryCompData -----' , emplySalaryCompData)
      calculate([...emplySalaryCompData]);
    }
  }, [newSalaryheadData]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getSalaryConfigMaster(userDetails.orgCountryCode);
      if (res && res.data) {
        setSalaryConfigMasterList(res.data);
        setemplyConfigData(res.data);
        const groupData = res.data.reduce((acc, item) => {
          const key = item.radioSelectionName || "null";
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        }, {});
        console.log("groupedData", groupData);
        setSalaryConfigGrouped(groupData);
      }
    };

    if (selectedEmPayroll) {
      setSelectedEmployPayrollValue(selectedEmPayroll);
      fetchData();
    }
  }, [selectedEmPayroll]);


  // const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    // if(emplyCTCData && !isDataFetched){
    getSalaryComponents(
      userDetails.orgId,
      userDetails.userId,
      userDetails.orgCountryCode
    ).then((response) => {
      if (response.data) {
        setSalaryCompData(response.data);
        setSalaryCompFilterData(
          response.data.map((item) => {
            return { ...item, amount: 0 };
          })
        );
        // setIsDataFetched(true);
      }
    })
    .catch((error) => {
      console.error("Error fetching salary components:", error);
    });
    // }
  }, [userDetails.userId]);

  const fetchData = async () => {
    const res = await getEmployeeSalaryComponents(selectedEmPayroll.userId);
    if (res) {
      calculate(res.data);
      setemplySalaryCompData(res.data);
    }
  };

  useEffect(() => {
    fetchData();
    // const fetchData = async () => {
    //   const res = await getEmployeeSalaryComponents(selectedEmPayroll.userId);
    //   if (res) {
    //     calculate(res.data);
    //     setemplySalaryCompData(res.data);
    //   }
    // };
   
    if (selectedEmployPayrollValue) {
      setUserAccountNumber(selectedEmployPayrollValue.accountNo);
      setUserConfirmAccountNumber(selectedEmployPayrollValue.accountNo);
      setUserBankName(selectedEmployPayrollValue.bankName);
      setUserIfscCode(selectedEmployPayrollValue.ifscCd);
      fetchData();
    }
  }, [selectedEmployPayrollValue , selectedEmPayroll]);

  // newSalaryheadData, salaryCompFilterData

  useEffect(() => {
    if (salaryCompData && emplySalaryCompData) {
      if (emplySalaryCompData.length === 0) {
        setemplySalaryCompData(salaryCompData);
        calculate(salaryCompData);
      }
    }
  }, [salaryCompData, emplySalaryCompData ]);

  // const calculate = (dataList) => {
  //   const finalDataList = [];
  //   let totalCtc = emplyCTCData || 0;
  //   let updatedBasicAmt = 0;
  //   let totalYearlyAmount = 0;

  //   const newlist = dataList.length !== 0 ? dataList : salaryCompFilterData;

  //   const newDataList = newSalaryheadData.some(item => item.componentName)
  //     ? [...newlist, ...newSalaryheadData]
  //     : newlist;

  //   console.log('newDataList', newDataList);

  //   const sanitizeValue = (value) => {
  //     const num = parseFloat(value);
  //     return isNaN(num) || value == null || value === 'NaN' ? 0 : num;
  //   };

  //   const removeDecimals = (value) => {
  //     Math.floor(value)
  //   } ;

  //   const sortedDataList = newDataList.reduce((acc, item) => {
  //     const category = item.category;
  //     if (!acc[category]) {
  //       acc[category] = [];
  //     }
  //     acc[category].push(item);
  //     return acc;
  //   }, {});

  //   Object.keys(sortedDataList).forEach((category) => {
  //     sortedDataList[category].forEach((item) => {
  //       item.dataType = "_data_";
  //       item.monthlyAmount = 0;
  //       item.annualAmount = 0;
  //       item.updatedValueMonthly = 0;
  //       item.updatedValueYearly = 0;

  //       item.amount = sanitizeValue(item.amount);

  //       if (item.periodicity === "Monthly") {
  //         item.monthlyAmount = item.amount;
  //         item.annualAmount = removeDecimals(item.amount * 12);
  //       }
  //       if (item.periodicity === "Annual") {
  //         item.annualAmount = removeDecimals(item.amount);
  //       }

  //       finalDataList.push(item);
  //     });
  //     finalDataList.push({ dName: category, annualAmount: 0 });
  //   });

  //   const index = finalDataList.findIndex(
  //     ({ componentName, calculationType }) =>
  //       componentName === "Basic" || calculationType === "% of CTC"
  //   );
  //   if (index !== -1) {
  //     const { flatAmountPc } = finalDataList[index];
  //     console.log('totalCtc' , totalCtc);
  //     console.log('flatAmountPc' , flatAmountPc) ;
  //     // updatedBasicAmt = removeDecimals((totalCtc * sanitizeValue(flatAmountPc)) / 100);
  //     updatedBasicAmt = (totalCtc * flatAmountPc) / 100;
  //     console.log('updatedBasicAmt' , updatedBasicAmt);
  //     finalDataList[index].updatedValueMonthly = removeDecimals(updatedBasicAmt / 12);
  //     console.log('finalDataList[index].updatedValueMonthly' , finalDataList[index].updatedValueMonthly , removeDecimals(updatedBasicAmt / 12))
  //     finalDataList[index].updatedValueYearly = updatedBasicAmt;
  //     console.log('finalDataList[index].updatedValueYearly' , finalDataList[index].updatedValueYearly , updatedBasicAmt)
  //     totalYearlyAmount += updatedBasicAmt;
  //   }

  //   console.log('finalDataList ------->>>>>>>' , finalDataList);

  //   if (updatedBasicAmt > 0) {
  //     let flatWithNullAmtList = [];
  //     for (let i = 0; i < finalDataList.length; i++) {
  //       const { componentName, calculationType, flatAmountPc, periodicity } =
  //         finalDataList[i];
  //       if (componentName !== "Basic") {
  //         if (calculationType === "% of Basic") {
  //           const amt = removeDecimals((updatedBasicAmt * sanitizeValue(flatAmountPc)) / 100);
  //           finalDataList[i].updatedValueMonthly = removeDecimals(amt / 12);
  //           finalDataList[i].updatedValueYearly = amt;
  //           totalYearlyAmount += amt;
  //         }
  //         if (calculationType === "% of PF Wages") {
  //           let total = 0;
  //           let tempOptionalList = [];
  //           for (let j = 0; j < finalDataList.length; j++) {
  //             if (
  //               finalDataList[j].configs &&
  //               finalDataList[j].configs.length > 0
  //             ) {
  //               for (let k = 0; k < finalDataList[j].configs.length; k++) {
  //                 if (
  //                   finalDataList[j].configs[k].rule ===
  //                   "Always Consider for EPF Contribution"
  //                 ) {
  //                   total += sanitizeValue(finalDataList[j].amount);
  //                 }
  //                 if (
  //                   finalDataList[j].configs[k].rule ===
  //                   "Consider for EPF Contribution only when PF Wage is less than 15000"
  //                 ) {
  //                   tempOptionalList.push(finalDataList[j]);
  //                 }
  //               }
  //             }
  //           }
  //           if (total < 15000) {
  //             tempOptionalList = tempOptionalList.sort(
  //               (a, b) => sanitizeValue(a.amount) - sanitizeValue(b.amount)
  //             );
  //             total = _addMore(total, tempOptionalList);
  //           }
  //           const amt = removeDecimals((total * 12) / 100);
  //           finalDataList[i].updatedValueMonthly = amt;
  //           finalDataList[i].updatedValueYearly = removeDecimals(amt * 12);
  //           totalYearlyAmount += removeDecimals(amt * 12);
  //         }
  //         if (calculationType === "Flat" && flatAmountPc) {
  //           if (periodicity === "Annual") {
  //             finalDataList[i].updatedValueYearly = removeDecimals(sanitizeValue(flatAmountPc));
  //             totalYearlyAmount += removeDecimals(sanitizeValue(flatAmountPc));
  //           }
  //           if (periodicity === "Monthly") {
  //             finalDataList[i].updatedValueMonthly = removeDecimals(sanitizeValue(flatAmountPc));
  //             totalYearlyAmount += removeDecimals(sanitizeValue(flatAmountPc) * 12);
  //           }
  //         }
  //         if (calculationType === "Flat" && flatAmountPc === null) {
  //           flatWithNullAmtList.push(finalDataList[i]);
  //         }
  //       }
  //     }
  //     if (flatWithNullAmtList && flatWithNullAmtList.length > 0) {
  //       const hikeCtc = totalCtc;
  //       const remainingAmt = hikeCtc - totalYearlyAmount;
  //       const totalOfFlatAmount = flatWithNullAmtList.reduce(
  //         (pre, cur) => pre + sanitizeValue(cur.amount),
  //         0
  //       );

  //       for (let i = 0; i < flatWithNullAmtList.length; i++) {
  //         const index = finalDataList.findIndex(
  //           ({ employeeSalaryComponentId }) =>
  //             employeeSalaryComponentId ===
  //             flatWithNullAmtList[i].employeeSalaryComponentId
  //         );
  //         if (index !== -1) {
  //           let value = removeDecimals(
  //             (remainingAmt * sanitizeValue(flatWithNullAmtList[i].amount)) /
  //             totalOfFlatAmount
  //           );
  //           if (flatWithNullAmtList[i].periodicity === "Monthly") {
  //             finalDataList[index].updatedValueMonthly = removeDecimals(value / 12);
  //             finalDataList[index].updatedValueYearly = value;
  //           }
  //           if (flatWithNullAmtList[i].periodicity === "Annual") {
  //             finalDataList[index].updatedValueYearly = value;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   console.log("finalDataList", finalDataList);
  //   console.log('inputValues', inputValues);
  //   setUserSalaryComponentList(finalDataList);
  // };

  // useEffect(() =>{
  //   calculate();
  // },[selectedEmployPayrollValue, newSalaryheadData , salaryCompFilterData , emplyCTCData])

  const calculate = (dataList) => {
    const finalDataList = [];
    console.log('qwerttyyyyyyy' , emplyCTCData , selectedEmPayroll , emplySalaryCompData)
    // let totalCtc = emplyCTCData > 0 ? emplyCTCData : selectedEmPayroll ? selectedEmPayroll.currentCTC : 0;
    let totalCtc = emplySalaryCompData.length === 0 && !editSalary ? 0 : emplyCTCData > 0 ? emplyCTCData : selectedEmPayroll ? selectedEmPayroll.currentCTC : 0;
    let updatedBasicAmt = 0;
    let totalYearlyAmount = 0;

    console.log('dataList' , dataList);

    const newlist = dataList;

    console.log('newSalaryheadData' , newSalaryheadData)

    const newDataList = newSalaryheadData.some((item) => item.componentName)
      ? [...newlist, ...newSalaryheadData]
      : newlist;

    console.log('newDataList' , newDataList);

    const sortedDataList = newDataList.reduce((acc, item) => {
      const category = item.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});
    Object.keys(sortedDataList).forEach((category, index) => {
      sortedDataList[category].forEach((item) => {
        item.dataType = "_data_";
        item.monthlyAmount = 0;
        item.annualAmount = 0;
        // item.updatedValueMonthly = 0;
        // item.updatedValueYearly = 0;

        if (item.periodicity && item.periodicity === "Monthly") {
          if (item.amount) {
            item.monthlyAmount = item.amount;
            item.annualAmount = item.amount * 12;
          }
        }
        if (item.periodicity && item.periodicity === "Annually") {
          if (item.amount) {
            item.annualAmount = item.amount;
            // item.annualAmount = item.amount;
            // item.annualAmount = item.amount;
          }
        }
        // totalCtc += item.annualAmount;
        finalDataList.push(item);
      });
      finalDataList.push({ dName: category, annualAmount: 0 });
    });

    if (totalCtc && totalCtc > 0) {
      const index = finalDataList.findIndex(
        ({ componentName, calculationType }) =>
          componentName === "Basic" || calculationType === "% of CTC"
      );
      if (index !== -1) {
        const { flatAmountPc } = finalDataList[index];
        updatedBasicAmt = (totalCtc * flatAmountPc) / 100;
        finalDataList[index].monthlyAmount = Math.floor(updatedBasicAmt / 12);
        finalDataList[index].annualAmount = Math.floor(updatedBasicAmt);
        // totalYearlyAmount += updatedBasicAmt;
      }
      if (updatedBasicAmt > 0) {
        // let flatWithNullAmtList = [];
        for (let i = 0; i < finalDataList.length; i++) {
          const { componentName, calculationType, flatAmountPc, periodicity } =
            finalDataList[i];
          if (componentName !== "Basic") {
            if (calculationType === "% of Basic") {
              const amt = (updatedBasicAmt * flatAmountPc) / 100;
              finalDataList[i].monthlyAmount = Math.floor(amt / 12);
              finalDataList[i].annualAmount = Math.floor(amt);
              // totalYearlyAmount += amt;
            }
            if (calculationType === "% of PF Wages") {
              let total = 0;
              let tempOptionalList = [];
              for (let j = 0; j < finalDataList.length; j++) {
                if (
                  finalDataList[j].configs &&
                  finalDataList[j].configs.length > 0
                ) {
                  for (let k = 0; k < finalDataList[j].configs.length; k++) {
                    if (
                      finalDataList[j].configs[k].rule ===
                      "Always Consider for EPF Contribution"
                    ) {
                      total += finalDataList[j].amount || 0;
                    }
                    if (
                      finalDataList[j].configs[k].rule ===
                      "Consider for EPF Contribution only when PF Wage is less than 15000"
                    ) {
                      tempOptionalList.push(finalDataList[j]);
                    }
                  }
                }
              }
              if (total < 15000) {
                tempOptionalList = tempOptionalList.sort(
                  (a, b) => a.amount > b.amount
                );
                total = _addMore(total, tempOptionalList);
              }
              const amt = (total * 12) / 100;
              finalDataList[i].monthlyAmount = Math.floor(amt);
              finalDataList[i].annualAmount = Math.floor(amt * 12);
            }
            if (calculationType === "Flat" && flatAmountPc) {
              if (periodicity == "Annually") {
                finalDataList[i].annualAmount = flatAmountPc || 0;
              }
              if (periodicity == "Monthly") {
                finalDataList[i].monthlyAmount = flatAmountPc || 0;
              }
            }
            // if (calculationType === "Flat" && flatAmountPc === null) {
            //   flatWithNullAmtList.push(finalDataList[i]);
            // }
          }
        }
        

        let flatIndex = -1;
for (let i = 0; i < finalDataList.length; i++) {
    if (finalDataList[i].calculationType === "Flat" && (!finalDataList[i].flatAmountPc || finalDataList[i].flatAmountPc === 0)) {
        flatIndex = i;
        break;
    }
}

if (flatIndex !== -1) {
  const remainingAmt = totalCtc - finalDataList.reduce((acc, item) => acc + (item.annualAmount || 0), 0);
  console.log('remainingAmt' , remainingAmt);
  console.log('totalCtc' , totalCtc);
  console.log('totalAmount' , finalDataList.reduce((acc, item) => acc + (item.annualAmount || 0), 0))
    if (remainingAmt > 0) {
        if (finalDataList[flatIndex].periodicity === "Annually") {
            finalDataList[flatIndex].annualAmount = remainingAmt;
        } else if (finalDataList[flatIndex].periodicity === "Monthly") {
            finalDataList[flatIndex].monthlyAmount = Math.floor(remainingAmt / 12)
            finalDataList[flatIndex].annualAmount = remainingAmt;
        }
    }
}
        // if (flatWithNullAmtList && flatWithNullAmtList.length > 0) {
        //   const hikeCtc = totalCtc ;
        //   console.log('hikeCtc' , hikeCtc)
        //   const remainingAmt = hikeCtc - totalYearlyAmount;
        //   console.log('remainingAmt' , remainingAmt) ;
        //   const totalOfFlatAmount = flatWithNullAmtList.reduce(
        //     (pre, cur) => pre + cur.amount,
        //     0
        //   );

        //   for (let i = 0; i < flatWithNullAmtList.length; i++) {
        //     const index = finalDataList.findIndex(
        //       ({ employeeSalaryComponentId }) =>
        //         employeeSalaryComponentId ===
        //         flatWithNullAmtList[i].employeeSalaryComponentId
        //     );
        //     if (index !== -1) {
        //       let value =
        //       Math.floor((remainingAmt * flatWithNullAmtList[i].amount) /
        //         totalOfFlatAmount);
        //       if (flatWithNullAmtList[i].periodicity === "Monthly") {
        //         finalDataList[index].updatedValueMonthly =  Math.floor(value / 12);
        //         finalDataList[index].updatedValueYearly =  Math.floor(value);
        //       }
        //       if (flatWithNullAmtList[i].periodicity === "Annual") {
        //         finalDataList[index].updatedValueYearly =  Math.floor(value);
        //       }
        //     }
        //   }
        // }
      }

      // // console.log('updatedValueMonthly' , updatedValueMonthly);
      // // console.log('updatedValueYearly' , updatedValueYearly);
      // console.log("finalDataList", finalDataList);
      // console.log('inputValues' , inputValues)
    }

    setUserSalaryComponentList(finalDataList);
  };

  const _addMore = (total, tempOptionalList) => {
    let t = total;
    if (total < 15000) {
      for (let i = 0; i < tempOptionalList.length; i++) {
        t += tempOptionalList[i].amount || 0;
        if (t < 15000) {
          t = _addMore(t, tempOptionalList);
        }
      }
    }
    return t;
  };

  useEffect(() => {
    calculate(emplySalaryCompData);
  }, [emplyCTCData  , editSalary]);

  console.log("salaryCompData", salaryCompData);

  const _showCategoryWiseTotal = (category, periodicity) => {
    let total = 0;
    for (let i = 0; i < userSalaryComponentList.length; i++) {
      if (userSalaryComponentList[i].category === category) {
        if (periodicity === "M") {
          if (userSalaryComponentList[i].monthlyAmount === 0) {
            total = 0;
            break;
          }
          total += userSalaryComponentList[i]?.monthlyAmount;
        }
        if (periodicity === "Y") {
          total += userSalaryComponentList[i]?.annualAmount;
        }
      }
    }
    return total > 0 ? total.toFixed(2) : "";
  };

  // useEffect(() => {
  //   console.log("selectedSalaryComponent", selectedSalaryComponent);
  //   if (selectedSalaryComponent) {
  //     setUserSelectedConfigIdList(
  //       selectedSalaryComponent.configs.map(({ configId }) => configId)
  //     );
  //   }
  // }, [selectedSalaryComponent]);

  useEffect(() => {
    console.log("selectedSalaryComponent", selectedSalaryComponent);
    if (selectedSalaryComponent) {
      const matchedGroupedConfig = groupedConfigurations.find(
        (gc) =>
          gc.salaryComponentId === selectedSalaryComponent.salaryComponentId
      );
      if (matchedGroupedConfig) {
        setUserSelectedConfigIdList(matchedGroupedConfig.configs);
      } else {
        setUserSelectedConfigIdList(
          selectedSalaryComponent.configs.map(({ configId }) => configId)
        );
      }
    }
  }, [selectedSalaryComponent, groupedConfigurations]);

  const isRadioGroupChecked = (name) => {
    console.log("isRadioGroupChecked name", name);
    let checked = false;
    let idList = [];
    for (let i = 0; i < salaryConfigMasterList.length; i++) {
      if (salaryConfigMasterList[i].radioSelectionName === name) {
        idList.push(salaryConfigMasterList[i].configId);
      }
    }
    if (idList.length > 0) {
      for (let i = 0; i < idList.length; i++) {
        if (userSelectedConfigIdList.includes(idList[i])) {
          checked = true;
        }
      }
    }
    return checked;
  };

  const radioValue = (name) => {
    console.log("radioValue name", name);
    let value = 0;
    let idList = [];
    for (let i = 0; i < salaryConfigMasterList.length; i++) {
      if (salaryConfigMasterList[i].radioSelectionName === name) {
        idList.push(salaryConfigMasterList[i].configId);
      }
    }
    if (idList.length > 0) {
      for (let i = 0; i < idList.length; i++) {
        if (userSelectedConfigIdList.includes(idList[i])) {
          value = idList[i];
        }
      }
    }
    return value;
  };


  const fetchPayrollData = () => {
    getPayrollEmployees(userDetails.orgId)
      .then((response) => {
        console.log('fetchPayrollData', response.data);
        setPayrollDataOfOrg(response.data);

        // Find the matching user by userId
        const matchingEmployee = response.data.find(
          (employee) => employee.userId === selectedEmPayroll.userId
        );

        if (matchingEmployee) {
          setCurrentCTC(matchingEmployee.currentCTC);
        } else {
          console.warn('No matching employee found');
        }
      })
      .catch((error) => {
        console.error('Error fetching units', error);
      });
  };

  console.log('currentCTC' , currentCTC)

  // const onChangeRadio = (name, value) => {
  //   console.log('onChangeRadio name' , name);
  //   let idList = [];
  //   for (let i = 0; i < salaryConfigMasterList.length; i++) {
  //     if (salaryConfigMasterList[i].radioSelectionName === name) {
  //       idList.push(salaryConfigMasterList[i].configId);
  //     }
  //   }
  //   let tempList = userSelectedConfigIdList;
  //   for (let i = 0; i < idList.length; i++) {
  //     tempList = tempList.filter((x) => x !== idList[i]);
  //   }
  //   tempList.push(value);
  //   setUserSelectedConfigIdList(tempList);
  // };

  // const onChangeRadio = (name, value) => {
  //   console.log('onChangeRadio name', name);
  //   let idList = [];
  //   for (let i = 0; i < salaryConfigMasterList.length; i++) {
  //     if (salaryConfigMasterList[i].radioSelectionName === name) {
  //       idList.push(salaryConfigMasterList[i].configId);
  //     }
  //   }

  //   // Create a new array to avoid direct state mutation
  //   let tempList = [...userSelectedConfigIdList];

  //   // Filter out any existing ids from the same radio group
  //   for (let i = 0; i < idList.length; i++) {
  //     tempList = tempList.filter((x) => x !== idList[i]);
  //   }

  //   // Add the newly selected value
  //   tempList.push(parseInt(value)); // Ensure value is added as an integer

  //   // Update state
  //   setUserSelectedConfigIdList(tempList);
  // };

  const onChangeCheckbox = (name, checked) => {
    console.log("onChangeCheckbox", name, checked);
    console.log("salaryConfigMasterList", salaryConfigMasterList);

    const configId = salaryConfigMasterList.find(
      (item) => item.radioSelectionName === name
    )?.configId;

    if (configId === undefined) {
      console.log(`No configId found for radioSelectionName: ${name}`);
      return;
    }

    let tempList = [...userSelectedConfigIdList];
    console.log("tempList", tempList);

    if (checked) {
      tempList.push(configId);
      console.log("tempList", tempList);
    } else {
      tempList = tempList.filter((x) => x !== configId);
      console.log("tempList", tempList);
    }

    setUserSelectedConfigIdList(tempList);
  };

  const onChangeRadio = (name, value) => {
    console.log("onChangeRadio", name, value);
    let idList = [];
    for (let i = 0; i < salaryConfigMasterList.length; i++) {
      if (salaryConfigMasterList[i].radioSelectionName === name) {
        idList.push(salaryConfigMasterList[i].configId);
      }
    }

    let tempList = [...userSelectedConfigIdList];

    for (let i = 0; i < idList.length; i++) {
      tempList = tempList.filter((x) => x !== idList[i]);
    }

    tempList.push(parseInt(value));
    setUserSelectedConfigIdList(tempList);
  };

  const handleACEditClick = () => {
    if (!editACMode === true) {
      setEditACMode(!editACMode);
    } else {
      if (userAccountNumber.trim() === "") {
        toast.error("Please enter Account Number", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (userAccountNumber !== userConfirmAccountNumber) {
        toast.error(
          "Account Number and Confirm Account Number should be the same",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        return;
      }

      if (userBankName.trim() === "") {
        toast.error("Please enter the bank name", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (userIfscCode.trim() === "") {
        toast.error("Please enter the IFSC code", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      toast.info("Please wait ....", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const updatedEmPayroll = {
        ...selectedEmployPayrollValue,
        accountNo: userAccountNumber,
        bankName: userBankName,
        ifscCd: userIfscCode,
      };

      const reqDto = {
        userId: selectedEmployPayrollValue.userId,
        accountNo: userAccountNumber,
        bankName: userBankName,
        ifscCd: userIfscCode,
        beneficiaryName: selectedEmployPayrollValue.personName,
      };

      console.log("reqDto", reqDto);

      updateEmployeeAccountDetails(userDetails.userId, reqDto)
        .then((response) => {
          if (response.data === 1) {
            toast.success("Employee Bank Details Updated successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setEditACMode(!editACMode);
            refreshEmployPayrollData();
            setSelectedEmployPayrollValue(updatedEmPayroll);
          } else {
            toast.error("Failed to Update Employee Bank Details", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating Employee Bank Details:", error);
          toast.error(
            "An error occurred while updating Employee Bank Details",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
    }
  };

  const handleReasonForEditChng = (event) => {
    console.log(event.target.value);
    setReasonForEdit(event.target.value);
  };

  const handleCTCDataChnge = (event) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setEmplyCTCData(numericValue);
  };

  // useEffect(() => {
  //   // Initialize inputValues based on userSalaryComponentList
  //     // console.log('updatedValueMonthly' , updatedValueMonthly);
  //   // console.log('updatedValueYearly' , updatedValueYearly);
  //   console.log('')
  //   setInputValues(
  //     userSalaryComponentList.map((item) => ({
  //       updatedValueMonthly: item.updatedValueMonthly,
  //       updatedValueYearly: item.updatedValueYearly,
  //     }))
  //   );
  // }, [userSalaryComponentList]);

  console.log("inputValues", inputValues);

  const handleInputChange = (e, index, fieldName) => {
    console.log("handleInputChange", e.target.value);
    console.log("index", index);
    console.log("fieldName", fieldName);
    console.log("userSalaryComponentList", userSalaryComponentList);
    const { value } = e.target;
    const newInputValues = [...inputValues];
    newInputValues[index] = {
      ...newInputValues[index],
      [fieldName]: value,
    };
    console.log("newInputValues", newInputValues);
    setInputValues(newInputValues);
  };

  console.log("inputValues", inputValues);

  // const totalMonthlyAmount = userSalaryComponentList.reduce((total, item) => {
  //   return total + (item.updatedValueMonthly || 0);
  // }, 0);

  // const totalAnnualAmount = userSalaryComponentList.reduce((total, item) => {
  //   return total + (item.updatedValueYearly || 0);
  // }, 0);

  const [totalMonthlyAmount, setTotalMonthlyAmount] = useState(0);
  const [totalAnnualAmount, setTotalAnnualAmount] = useState(0);

  // useEffect(() => {
  //   const totalMonthly = userSalaryComponentList.reduce((total, item) => {
  //     return total + (item.updatedValueMonthly || 0);
  //   }, 0);

  //   const totalAnnual = userSalaryComponentList.reduce((total, item) => {
  //     return total + (item.updatedValueYearly || 0);
  //   }, 0);

  //   setTotalMonthlyAmount(totalMonthly);
  //   setTotalAnnualAmount(totalAnnual);
  // }, [userSalaryComponentList]);

  const handleEffectiveDate = (date) => {
    console.log("new date = ", date);
    setEffectivedate(date);
  };

  // const [groupedConfigurations, setGroupedConfigurations] = useState([]);

  // const SelectedConfigData = () => {
  //   console.log("SelectedConfigData");
  //   console.log("userSelectedConfigIdList", userSelectedConfigIdList);
  //   console.log("selectedSalaryComponent", selectedSalaryComponent);

  //   const salaryComponentData = {
  //     salaryComponentId: selectedSalaryComponent.salaryComponentId,
  //     configs: userSelectedConfigIdList,
  //   };

  //   // Example usage of the data
  //   console.log("salaryComponentData", salaryComponentData);

  //   // Update the state with the new salaryComponentData
  //   setGroupedConfigurations((prevConfigurations) => {
  //     const existingIndex = prevConfigurations.findIndex(
  //       (config) =>
  //         config.salaryComponentId === salaryComponentData.salaryComponentId
  //     );

  //     if (existingIndex !== -1) {
  //       // Update existing salaryComponentId
  //       const updatedConfigurations = [...prevConfigurations];
  //       updatedConfigurations[existingIndex] = {
  //         ...updatedConfigurations[existingIndex],
  //         configs: salaryComponentData.configs,
  //       };
  //       return updatedConfigurations;
  //     } else {
  //       // Add new salaryComponentData
  //       return [...prevConfigurations, salaryComponentData];
  //     }
  //   });

  //   // setSelectedSalaryComponent(null);
  //   setOpenConfigBox(false);
  // };

  const initializeGroupedConfigurations = (data) => {
    console.log("data", data);
    const initialVal = [...data, ...newSalaryheadData];
    const initialConfigurations = initialVal
      .filter((item) => item.configs && item.configs.length > 0)
      .map((item) => ({
        salaryComponentId: item.salaryComponentId,
        configs: item.configs.map((config) => config.configId),
      }));

    setGroupedConfigurations(initialConfigurations);
  };

  useEffect(() => {
    initializeGroupedConfigurations(emplySalaryCompData);
  }, [emplySalaryCompData]);

  const SelectedConfigData = () => {
    console.log("SelectedConfigData");
    console.log("userSelectedConfigIdList", userSelectedConfigIdList);
    console.log("selectedSalaryComponent", selectedSalaryComponent);

    const salaryComponentData = {
      salaryComponentId: selectedSalaryComponent.salaryComponentId,
      configs: userSelectedConfigIdList,
    };

    console.log("salaryComponentData", salaryComponentData);

    console.log("salaryComponentData", salaryComponentData);

    setGroupedConfigurations((prevConfigurations) => {
      const existingIndex = prevConfigurations.findIndex(
        (config) =>
          config.salaryComponentId === salaryComponentData.salaryComponentId
      );

      if (existingIndex !== -1) {
        const updatedConfigurations = [...prevConfigurations];
        updatedConfigurations[existingIndex] = {
          ...updatedConfigurations[existingIndex],
          configs: salaryComponentData.configs,
        };
        return updatedConfigurations;
      } else {
        return [...prevConfigurations, salaryComponentData];
      }
    });
    setOpenConfigBox(false);
    // setUserSelectedConfigIdList(tempList);
  };

  console.log("groupedConfigurations", groupedConfigurations);

  // const deleteRow = (index) => {
  //   const updatedList = userSalaryComponentList.filter(
  //     (item, idx) => idx !== index
  //   );
  //   setUserSalaryComponentList(updatedList);
  // };

  console.log('emplySalaryCompData' , emplySalaryCompData);
  console.log('newSalaryheadData' , newSalaryheadData);

  const deleteRow = (index , salaryComponentId) => {
    console.log('deleteRow' ,salaryComponentId)
    const updatedList = userSalaryComponentList.filter(
      (item, idx) => item.salaryComponentId !== salaryComponentId
    );
    setUserSalaryComponentList(updatedList);

    console.log('emplySalaryCompData' , emplySalaryCompData);
    console.log('newSalaryheadData' , newSalaryheadData);
  
    const updatedsalaryList = emplySalaryCompData.filter(
      (item, idx) => item.salaryComponentId !== salaryComponentId
    );
    console.log('emplySalaryCompData' , emplySalaryCompData);
    setemplySalaryCompData(updatedsalaryList);

    const updatedNewList = newSalaryheadData.filter(
      (item, idx) => item.salaryComponentId !== salaryComponentId
    );
    console.log('newSalaryheadData' , newSalaryheadData)
    setNewSalaryHeadData(updatedNewList);
  };

  const handleFinalSubmitForSalaryStructure = () => {
    console.log("handleFinalSubmitForSalaryStructure");
    if (!editSalary === true) {
      setEditSalary(!editSalary);
    } else {
      const components = userSalaryComponentList
        .filter((item) => item.dataType === "_data_")
        .map((item) => {
          const amount =
            item.periodicity === "Monthly"
              ? parseFloat(
                  inputValues[userSalaryComponentList.indexOf(item)]
                    ?.monthlyAmount || item.monthlyAmount
                )
              : parseFloat(
                  inputValues[userSalaryComponentList.indexOf(item)]
                    ?.annualAmount || item.annualAmount
                );

          console.log("amount", amount);

          const configData = groupedConfigurations.find(
            (config) => config.salaryComponentId === item.salaryComponentId
          );

          const uniqueConfigIds = configData 
          ? Array.from(new Set(configData.configs)) 
          : [];


          return {
            componentId: item.salaryComponentId ? item.salaryComponentId : 0,
            amount: isNaN(amount) ? 0 : amount,
            configIds: uniqueConfigIds ,
          };
        })
        .filter(
          (component) => component.amount !== 0 && component.amount != null
        );

      if (reasonForEdit.trim() === "") {
        toast.error("Please enter the Reason For Edit", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const totalAnnualAmount = userSalaryComponentList.reduce(
        (acc, item) => acc + (item.annualAmount || 0),
        0
      );
      
      // if (emplyCTCData >0 && parseInt(emplyCTCData) !== Math.floor(totalAnnualAmount)) {
      //   toast.error("CTC and Total Annual amount should be the same", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      //   return;
      // }      

      toast.info("Please wait ....", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const reqDto = {
        reason: reasonForEdit,
        userId: selectedEmployPayrollValue.userId,
        effectiveDate: DateUtils.getDateInDDMMYYYY(effectivedate),
        components: components,
      };

      console.log("reqDto", reqDto);

      // return;

      submitEmpSalaryComponent(userDetails.orgId, userDetails.userId, reqDto)
        .then((response) => {
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setEditSalary(!editSalary);
            refreshEmployPayrollData();
            setReasonForEdit("");
            setEffectivedate(new Date());
            setGroupedConfigurations([]);
            setNewSalaryHeadData([]);
            setSelectedSalaryComponent(null);
            setUserSelectedConfigIdList([]);
            fetchData();
            fetchPayrollData();
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.error("Error submitting salary component:", error);
        });
    }
  };

  // const handleFinalSubmitForSalaryStructure = () => {
  //   console.log('handleFinalSubmitForSalaryStructure');
  //   if (!editSalary === true) {
  //     setEditSalary(!editSalary);
  //   } else {

  //   const components = userSalaryComponentList
  //     .filter(item => item.dataType === '_data_')
  //     .map(item => {

  //       const amount = item.periodicity === "Monthly"
  //         ? parseFloat(inputValues[userSalaryComponentList.indexOf(item)]?.monthlyAmount || item.monthlyAmount)
  //         : parseFloat(inputValues[userSalaryComponentList.indexOf(item)]?.annualAmount || item.annualAmount);

  //       return {
  //         componentId: item.salaryComponentId,
  //         amount: isNaN(amount) ? 0 : amount,
  //         configIds: []
  //       };
  //     });

  //   const reqDto = {
  //     reason: reasonForEdit,
  //     userId: selectedEmployPayrollValue.userId,
  //     effectiveDate: DateUtils.getDateInDDMMYYYY(effectivedate),
  //     components: components,
  //   };

  //   console.log('reqDto', reqDto);

  //   submitEmpSalaryComponent(userDetails.org , userDetails.userId , reqDto)
  //   .then((response) => {
  //     console.log('response.data' , response.data);
  //     if (response.data.returnValue === "1") {
  //       toast.success(response.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       setEditSalary(!editSalary);
  //     };
  //   })

  // }

  // };

  console.log('userSalaryComponentList' , userSalaryComponentList)

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          {/* header start */}
          <div className="rfContHeader">
            <div className="rfcHeadText">Employee Salary Structure</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseDetailPayroll()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          {/* header end */}

          {/* body start */}
          <div className="rfContContain">
            {selectedEmployPayrollValue && (
              <div className="rfContContainDetails">
                <div className="elementFormContainer payrollEditor">
                  {/* personal details start */}
                  <div className="pmsDetailInfoGrp">
                    <div className="pmsDtlIndElement">
                      <div className="pmsDtlInfoTitle"> Name :</div>
                      <div className="pmsDtlInfoDescn">
                        <span>
                          {selectedEmployPayrollValue?.personName || "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="pmsDtlIndElement">
                      <div className="pmsDtlInfoTitle"> ID :</div>
                      <div className="pmsDtlInfoDescn">
                        <span>
                          {selectedEmployPayrollValue?.employeeId || "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="pmsDtlIndElement">
                      <div className="pmsDtlInfoTitle"> Position :</div>
                      <div className="pmsDtlInfoDescn">
                        <span>
                          {selectedEmployPayrollValue?.positionName || "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="pmsDtlIndElement">
                      <div className="pmsDtlInfoTitle"> Salary Type :</div>
                      <div className="pmsDtlInfoDescn">
                        <span>
                          {selectedEmployPayrollValue?.employmentType || "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="pmsDtlIndElement">
                      <div className="pmsDtlInfoTitle">
                        {" "}
                        Employement Status :
                      </div>
                      <div className="pmsDtlInfoDescn">
                        <span>
                          {selectedEmployPayrollValue?.empStatus || "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="pmsDtlIndElement">
                      <div className="pmsDtlInfoTitle"> Current CTC :</div>
                      <div className="pmsDtlInfoDescn">
                        <span>

                          {currentCTC || "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* personal details end */}

                  {/* account details start */}
                  <div className="tskElemHeding epfEsiSetingsGrp">
                    <span>Account Details</span>
                    <IconButton onClick={handleACEditClick}>
                      {editACMode ? <Save /> : <Edit />}
                    </IconButton>
                  </div>
                  <div className="pmsDetailInfoGrp">
                    <div className="pmsDtlIndElement">
                      <div className="pmsDtlInfoTitle"> Account No :</div>
                      {!editACMode && (
                        <div className="pmsDtlInfoDescn">
                          <span>
                            {selectedEmployPayrollValue?.accountNo || "N/A"}
                          </span>
                        </div>
                      )}

                      {editACMode && (
                        <>
                          <div className="formElement width13">
                            <FormControl className="formControl">
                              <TextField
                                label="Account No*"
                                variant="outlined"
                                className="formTextFieldArea"
                                value={userAccountNumber}
                                onChange={(event) => {
                                  const numericValue =
                                    event.target.value.replace(/[^0-9]/g, "");
                                  setUserAccountNumber(numericValue);
                                }}
                                type="password"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  onCopy: (e) => e.preventDefault(),
                                  onPaste: (e) => e.preventDefault(),
                                  onCut: (e) => e.preventDefault(),
                                }}
                                autoComplete="cc-number"
                                // autoComplete="new-password"
                              />
                            </FormControl>
                          </div>
                          <div className="formElement width13">
                            <FormControl className="formControl">
                              <TextField
                                label="Confirm Account No*"
                                variant="outlined"
                                className="formTextFieldArea"
                                value={userConfirmAccountNumber}
                                onChange={(event) => {
                                  const numericValue =
                                    event.target.value.replace(/[^0-9]/g, "");
                                  setUserConfirmAccountNumber(numericValue);
                                }}
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  onCopy: (e) => e.preventDefault(),
                                  onPaste: (e) => e.preventDefault(),
                                  onCut: (e) => e.preventDefault(),
                                }}
                                autoComplete="off"
                              />
                            </FormControl>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="pmsDtlIndElement">
                      <div className="pmsDtlInfoTitle"> Bank Name :</div>
                      {!editACMode && (
                        <div className="pmsDtlInfoDescn">
                          {/* <span>{coupon.coustomCode.charAt(0) + "XXX" + coupon.coustomCode.slice(-1)}</span> */}
                          <span>
                            {selectedEmployPayrollValue?.bankName || "N/A"}
                          </span>
                        </div>
                      )}
                      {editACMode && (
                        <div className="formElement width13">
                          <FormControl className="formControl">
                            <TextField
                              label="Bank Name"
                              required
                              variant="outlined"
                              className="formTextFieldArea"
                              value={userBankName}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^[a-zA-Z\s]*$/.test(value)) {
                                  setUserBankName(value);
                                }
                              }}
                              autoComplete="off"
                            />
                          </FormControl>
                        </div>
                      )}
                    </div>
                    <div className="pmsDtlIndElement">
                      <div className="pmsDtlInfoTitle"> IFSC Code :</div>
                      {!editACMode && (
                        <div className="pmsDtlInfoDescn">
                          <span>
                            {selectedEmployPayrollValue?.ifscCd || "N/A"}
                          </span>
                        </div>
                      )}
                      {editACMode && (
                        <div className="formElement width13">
                          <FormControl className="formControl">
                            <TextField
                              label="IFSC Code"
                              required
                              variant="outlined"
                              className="formTextFieldArea"
                              value={userIfscCode}
                              onChange={(event) =>
                                setUserIfscCode(event.target.value)
                              }
                              autoComplete="off"
                            />
                          </FormControl>
                        </div>
                      )}
                    </div>
                    <div className="pmsDtlIndElement">
                      <div className="pmsDtlInfoTitle"> Beneficiary Name :</div>

                      <div className="pmsDtlInfoDescn">
                        <span>
                          {selectedEmployPayrollValue?.personName || "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* account details end */}

                  {/* pms configure start */}
                  <div className="bbmp12 mt10 mb50">
                    <div className="tskElemHeding epfEsiSetingsGrp mt10">
                      <span>Salary Structure</span>
                      <IconButton
                        onClick={() => handleFinalSubmitForSalaryStructure()}
                      >
                        {editSalary ? <Save /> : <Edit />}
                      </IconButton>
                    </div>

                    {editSalary && (
                      <>
                        <div className="elementWithBtn width100p ">
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Give a reasons for edit*"
                                variant="outlined"
                                className="formTextFieldArea"
                                value={reasonForEdit}
                                onChange={handleReasonForEditChng}
                                autoComplete="off"
                              />
                            </FormControl>
                          </div>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel
                                id="effectiveDate"
                                className="setCompletionDate"
                              >
                                Effective Date
                              </InputLabel>
                              <ReactDatePicker
                                toggleCalendarOnIconClick
                                // showYearDropdown
                                // yearDropdownItemNumber={100}
                                // scrollableYearDropdown
                                // showMonthDropdown
                                showIcon
                                labelId="effectiveDate"
                                className="formDatePicker"
                                selected={effectivedate}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => {
                                  handleEffectiveDate(date);
                                }}
                              />
                            </FormControl>
                          </div>
                        </div>
                        <div className="elementWithBtn width100p ">
                          <div className="formElement ">
                            <FormControl className="formControl">
                              <InputLabel id="newsalryhead">
                                Select to add new head
                              </InputLabel>
                              <Select
                                className="formInputField"
                                labelId="newsalryhead"
                                id="new-salryhead"
                                value={addnewSalaryHead}
                                label="Select to add new head"
                                onChange={(e) =>
                                  setAddnewSalaryHead(e.target.value)
                                }
                              >
                                {/* <MenuItem value={"basic"}>Basic</MenuItem>
                                <MenuItem value={"hra"}>HRA</MenuItem>
                                <MenuItem value={"olaw"}>OALW</MenuItem> */}
                                {salaryCompData.map((value) => (
                                  <MenuItem
                                    key={value.salaryComponentId}
                                    value={value}
                                  >
                                    {value.componentName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          <Button
                            startIcon={<Add />}
                            className="dfultPrimaryBtn "
                            onClick={handleAddNewHead}
                          >
                            Add
                          </Button>
                        </div>
                        <div className="elementWithBtn width100p ">
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="CTC*"
                                variant="outlined"
                                className="formTextFieldArea"
                                value={emplyCTCData}
                                onChange={handleCTCDataChnge}
                                autoComplete="off"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                }}
                              />
                            </FormControl>
                          </div>
                          <div className="formElement"></div>
                        </div>
                      </>
                    )}

                    <div class="indentFormtable mt10 ">
                      <div class="indtableResponsive">
                        {refreshCount > 0 && (
                          <table>
                            <thead>
                              <tr>
                                <th></th>
                                <th
                                  className="curntStrct textCenter"
                                  colSpan={2}
                                >
                                  Amount
                                </th>
                              </tr>
                              <tr>
                                <th>Salary head</th>
                                <th className="curntStrct">Monthly</th>
                                <th className="curntStrct">Annually</th>
                              </tr>
                            </thead>
                            <tbody>
                              {userSalaryComponentList &&
                                userSalaryComponentList.map((item, index) => (
                                  <>
                                    {item.dataType === "_data_" ? (
                                      <tr key={`${index}_${item.dataType}`}>
                                        <td>
                                          <div className="shwithConfig">
                                            <div className="textOnly">
                                              {item.componentName}
                                            </div>
                                            <Tooltip
                                              title="Configuration Head "
                                              arrow
                                            >
                                              <IconButton
                                                onClick={() => {
                                                  setSelectedSalaryComponent(
                                                    item
                                                  );
                                                  setOpenConfigBox(true);
                                                }}
                                              >
                                                <Settings />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        </td>
                                        <td>
                                          {!editSalary ? (
                                            <div className="curntStrct">
                                              {item.monthlyAmount}
                                            </div>
                                          ) : (
                                            <>
                                              {item.periodicity ===
                                                "Monthly" && (
                                                <div className="formElement formTextRight">
                                                  <FormControl className="formControl">
                                                    <FloatTextField
                                                      variant="outlined"
                                                      className="formTextFieldArea"
                                                      placeholder="Enter Amount"
                                                      value={item.monthlyAmount }
                                                      onChange={(val) => {
                                                        console.log('val' , val)
                                                        const value =
                                                          parseFloat(val);
                                                        if (value) {
                                                          item.monthlyAmount =
                                                            value;
                                                          item.annualAmount =
                                                            value * 12;
                                                          setRefreshCount(
                                                            (r) => r + 1
                                                          );
                                                        }else{
                                                          item.monthlyAmount =
                                                            0;
                                                          item.annualAmount =
                                                            0;
                                                          setRefreshCount(
                                                            (r) => r + 1
                                                          );
                                                        }
                                                      }}
                                                      autoComplete="off"
                                                    />
                                                  </FormControl>
                                                </div>
                                              )}
                                            </>
                                          )}

                                          {/* {editSalary &&
                                          item.periodicity === "Annual" && (
                                            <div className="curntStrct">
                                              {item.updatedValueMonthly}
                                            </div>
                                          )} */}

                                          {/* {editSalary &&
                                          item.periodicity === "Monthly" && (
                                            
                                          )} */}
                                        </td>
                                        {/* <td>
                                        <div className="curntStrct">
                                          {item.annualAmount}
                                        </div>
                                      </td> */}
                                        <td>
                                          {!editSalary ? (
                                            <div className="curntStrct">
                                              {item.annualAmount}
                                            </div>
                                          ) : (
                                            <>
                                              {item.periodicity !==
                                                "Annually" && (
                                                <div className="curntStrct">
                                                  {item.annualAmount}
                                                </div>
                                              )}
                                            </>
                                          )}

                                          {editSalary &&
                                            item.periodicity === "Annually" && (
                                              <div className="formElement formTextRight">
                                                <FormControl className="formControl">
                                                  <FloatTextField
                                                    variant="outlined"
                                                    className="formTextFieldArea"
                                                    placeholder="Enter Amount"
                                                    value={
                                                      item.annualAmount || 0
                                                    }
                                                    onChange={(val) => {
                                                      const value =
                                                        parseFloat(val);
                                                      if (value) {
                                                        item.annualAmount =
                                                          value;
                                                        setRefreshCount(
                                                          (r) => r + 1
                                                        );
                                                      }else{
                                                        item.annualAmount =
                                                        0;
                                                      setRefreshCount(
                                                        (r) => r + 1
                                                      );
                                                      }
                                                    }}
                                                    autoComplete="off"
                                                  />
                                                </FormControl>
                                              </div>
                                            )}

                                          {/* {editSalary &&
                                          item.periodicity === "Monthly" && (
                                            <div className="curntStrct">
                                              
                                              {inputValues[index]?.monthlyAmount ? inputValues[index].monthlyAmount * 12 : item.annualAmount}
                                            </div>
                                          )} */}
                                          {/* {editSalary &&
                                          item.periodicity === "Monthly" && (
                                            <div className="curntStrct">
                                              {inputValues[index]?.updatedValueMonthly
                                                ? inputValues[index]
                                                    .updatedValueMonthly * 12
                                                : isNaN(item.updatedValueYearly)
                                                ? '0'
                                                : item.updatedValueYearly}
                                            </div>
                                          )} */}

                                          {/* {editSalary &&
                                          item.periodicity === "Annual" && (
                                            <div className="formElement formTextRight">
                                              <FormControl className="formControl">
                                                <TextField
                                                  variant="outlined"
                                                  className="formTextFieldArea"
                                                  placeholder="Enter Amount"
                                                  value={
                                                    inputValues[index]
                                                      ?.updatedValueYearly || 0
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      e,
                                                      index,
                                                      "updatedValueYearly"
                                                    )
                                                  }
                                                  autoComplete="off"
                                                />
                                              </FormControl>
                                            </div>
                                          )} */}
                                        </td>
                                        {editSalary && (
                                          <td>
                                            <div className="tblActionBtnGrp">
                                              <IconButton
                                                className="removeRowBtn"
                                                onClick={() => deleteRow(index , item.salaryComponentId)}
                                              >
                                                <Trash />
                                              </IconButton>
                                            </div>
                                          </td>
                                        )}
                                      </tr>
                                    ) : (
                                      <tr
                                        className="salGropuHead darkrow"
                                        key={index}
                                      >
                                        <td>
                                          <div className="textOnly">
                                            {item.dName}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="curntStrct">
                                            {_showCategoryWiseTotal(
                                              item.dName,
                                              "M"
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="curntStrct">
                                            {_showCategoryWiseTotal(
                                              item.dName,
                                              "Y"
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                ))}
                              <tr className="emptyrow">
                                <td colSpan={3}></td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>
                                  <div className="textOnly">Total</div>
                                </th>
                                <th>
                                  <div className="curntStrct">
                                    {userSalaryComponentList
                                      .reduce(
                                        (acc, item) =>
                                          (acc += item.monthlyAmount || 0),
                                        0
                                      )
                                      .toFixed(2)}
                                  </div>
                                </th>
                                <th>
                                  <div className="curntStrct">
                                    {userSalaryComponentList
                                      .reduce(
                                        (acc, item) =>
                                          (acc += item.annualAmount || 0),
                                        0
                                      )
                                      .toFixed(2)}
                                  </div>
                                </th>
                              </tr>
                            </tfoot>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* pms configure end */}
                </div>
              </div>
            )}
          </div>
          {/* body end */}

          {/* footer start */}
          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseDetailPayroll()}
              >
                Cancel
              </Button>
            </div>
          </div>
          {/* footer end */}

          {/* {selectedSalaryComponent && (
            <>
              <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
                <div className="addMoreAgendaModel">
                  <div className="modelTitle">Salary head configuration</div>

                  <div className="elementFormContainer">
                    <div className="empContListCheck ">
                      {Object.keys(salaryConfigGrouped).map((key, index) => (
                        <div className="formElement">
                          <FormControl className="formControl">
                            <FormGroup className="formRadioGroup gap0DC">
                              {key === "null" ? (
                                <>
                                  {salaryConfigGrouped[key].length > 0 &&
                                    salaryConfigGrouped[key].map((item) => (
                                      <FormControlLabel
                                        className="formRadioField"
                                        label={item.rule}
                                        value={item}
                                        control={
                                          <Checkbox
                                            checked={userSelectedConfigIdList.includes(
                                              item.configId
                                            )}
                                            onChange={(e) => {
                                              if (
                                                userSelectedConfigIdList.includes(
                                                  item.configId
                                                )
                                              ) {
                                                setUserSelectedConfigIdList(
                                                  (r) =>
                                                    r.filter(
                                                      (x) => x !== item.configId
                                                    )
                                                );
                                              } else {
                                                setUserSelectedConfigIdList(
                                                  (r) => [...r, item.configId]
                                                );
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    ))}
                                </>
                              ) : (
                                <>
                                  <FormControlLabel
                                    className="formRadioField"
                                    label={key}
                                    value={key}
                                    control={
                                      <Checkbox
                                        checked={isRadioGroupChecked(key)}
                                      />
                                    }
                                  />
                                  {salaryConfigGrouped[key] &&
                                    salaryConfigGrouped[key].length > 0 && (
                                      <div className="checkboxWithlist">
                                        <RadioGroup
                                          className="formRadioGroup gap0DC"
                                          name="paytype"
                                          value={radioValue(key)}
                                          onChange={(e) =>
                                            onChangeRadio(key, e.target.value)
                                          }
                                        >
                                          {salaryConfigGrouped[key].map(
                                            (item, idx) => (
                                              <FormControlLabel
                                                key={idx}
                                                className="formRadioField"
                                                control={<Radio />}
                                                label={item.rule}
                                                value={item.configId}
                                                // checked={userSelectedConfigIdList.includes(
                                                //   item.configId
                                                // )}
                                                // onChange={(e) => {
                                                //   if (
                                                //     userSelectedConfigIdList.includes(
                                                //       item.configId
                                                //     )
                                                //   ) {
                                                //     setUserSelectedConfigIdList(
                                                //       (r) =>
                                                //         r.filter(
                                                //           (x) =>
                                                //             x !== item.configId
                                                //         )
                                                //     );
                                                //   } else {
                                                //     setUserSelectedConfigIdList(
                                                //       (r) => [
                                                //         ...r,
                                                //         item.configId,
                                                //       ]
                                                //     );
                                                //   }
                                                // }}
                                              />
                                            )
                                          )}
                                        </RadioGroup>
                                      </div>
                                    )}
                                </>
                              )}
                            </FormGroup>
                          </FormControl>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="modActionBtnGrp">
                    <Button
                      className="dfultPrimaryBtn"
                      onClick={() => {
                        console.log(userSelectedConfigIdList);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      className="dfultDarkSecondaryBtn"
                      onClick={() => {
                        setSelectedSalaryComponent(null);
                        setUserSelectedConfigIdList([]);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Box>
            </>
          )} */}

          {openConfigBox && (
            <>
              <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
                <div className="addMoreAgendaModel">
                  <div className="modelTitle">Salary head configuration</div>

                  <div className="elementFormContainer">
                    <div className="empContListCheck ">
                      {Object.keys(salaryConfigGrouped).map((key, index) => (
                        <div className="formElement" key={index}>
                          <FormControl className="formControl">
                            <FormGroup className="formRadioGroup gap0DC">
                              {key === "null" ? (
                                <>
                                  {salaryConfigGrouped[key].length > 0 &&
                                    salaryConfigGrouped[key].map((item) => (
                                      <FormControlLabel
                                        key={item.configId}
                                        className="formRadioField"
                                        label={item.rule}
                                        value={item.configId}
                                        control={
                                          <Checkbox
                                            checked={userSelectedConfigIdList.includes(
                                              item.configId
                                            )}
                                            onChange={(e) => {
                                              if (
                                                userSelectedConfigIdList.includes(
                                                  item.configId
                                                )
                                              ) {
                                                setUserSelectedConfigIdList(
                                                  (r) =>
                                                    r.filter(
                                                      (x) => x !== item.configId
                                                    )
                                                );
                                              } else {
                                                setUserSelectedConfigIdList(
                                                  (r) => [...r, item.configId]
                                                );
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    ))}
                                </>
                              ) : (
                                <>
                                  <FormControlLabel
                                    className="formRadioField"
                                    label={key}
                                    value={key}
                                    control={
                                      <Checkbox
                                        checked={isRadioGroupChecked(key)}
                                        onChange={(e) =>
                                          onChangeCheckbox(
                                            key,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    }
                                  />
                                  {salaryConfigGrouped[key] &&
                                    salaryConfigGrouped[key].length > 0 && (
                                      <div className="checkboxWithlist">
                                        <RadioGroup
                                          className="formRadioGroup gap0DC"
                                          name={key}
                                          value={radioValue(key)}
                                          onChange={(e) =>
                                            onChangeRadio(key, e.target.value)
                                          }
                                        >
                                          {salaryConfigGrouped[key].map(
                                            (item, idx) => (
                                              <FormControlLabel
                                                key={idx}
                                                className="formRadioField"
                                                control={<Radio />}
                                                label={item.rule}
                                                value={item.configId}
                                                disabled={
                                                  !isRadioGroupChecked(key)
                                                }
                                              />
                                            )
                                          )}
                                        </RadioGroup>
                                      </div>
                                    )}
                                </>
                              )}
                            </FormGroup>
                          </FormControl>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="modActionBtnGrp">
                    <Button
                      className="dfultPrimaryBtn"
                      onClick={() => SelectedConfigData()}
                    >
                      Save
                    </Button>
                    <Button
                      className="dfultDarkSecondaryBtn"
                      // onClick={() => {
                      //   setSelectedSalaryComponent(null);
                      //   setUserSelectedConfigIdList([]);
                      // }}
                      onClick={() => setOpenConfigBox(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Box>
            </>
          )}
        </div>
      </div>
    </>
  );
}
