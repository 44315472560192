import { Add, ExpandMoreRounded, HealthAndSafety } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { getHealthDto, MAX_SUGGESTED_RESULT } from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { toast } from "react-toastify";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const timings = [
  "Stat",
  "Before Breakfast",
  "After Breakfast",
  "Before Lunch",
  "After Lunch",
];

export default function Medication({ updateData, data }) {
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [accordionString, setAccordionString] = useState("");

  const [dosage, setDosage] = useState("");
  const [mForm, setMForm] = useState("");
  const [frequency, setFrequency] = useState("");
  const [regimen, setRegimen] = useState("");

  const [mTiming, setMTiming] = useState([]);
  const [doctorGenericList, setDoctorGenericList] = useState([]);
  const [genericList, setGenericList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  const [genericSearchInput, setGenericSearchInput] = useState("");
  const [selectedGeneric, setSelectedGeneric] = useState(null);
  const [medicationNote, setMedicationNote] = useState("");

  const [medicationList, setMedicationList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);

  const [dosageList, setDosageList] = useState([]);
  const [suggestedDosage, setSuggestedDosage] = useState([]);

  // const [routeList,setRoutelist]=

  //Brand item

  const [suggestedBrand, setSuggestedBrand] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandInputString, setBrandInputString] = useState("");

  const getFilteredList = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      const temp = data.slice(0, MAX_SUGGESTED_RESULT);
      return { startsWithList: temp, containsList: [] };
    }
    const startsWithList = [];
    const containsList = [];

    data.forEach((element) => {
      if (
        element.generic.toLowerCase().startsWith(searchString.toLowerCase())
      ) {
        startsWithList.push(element);
      } else if (
        element.generic.toLowerCase().includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });
    return { startsWithList, containsList };
  };

  const getFilteredBrand = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      return data.slice(0, MAX_SUGGESTED_RESULT);
    }
    const startsWithList = [];
    const containsList = [];

    data.forEach((element) => {
      if (
        element.brandName.toLowerCase().startsWith(searchString.toLowerCase())
      ) {
        startsWithList.push(element);
      } else if (
        element.brandName.toLowerCase().includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });
    return [...startsWithList, ...containsList];
  };

  const setUniqueDoses = () => {
    const uniqueDosage = {};
    dosageList.forEach((item) => {
      if (!uniqueDosage[item.dosage]) {
        uniqueDosage[item.dosage] = item;
      }
    });
    const uniqueItems = Object.values(uniqueDosage);
    setSuggestedDosage(uniqueItems);
    return uniqueItems;
  };

  const suggestedMedicineList = useMemo(() => {
    const genericListFilteredData = getFilteredList(
      genericList,
      genericSearchInput
    );
    const doctorFilteredData = getFilteredList(
      doctorGenericList,
      genericSearchInput
    );
    const tempValue = [
      ...doctorFilteredData.startsWithList,
      ...genericListFilteredData.startsWithList,
      ...doctorFilteredData.containsList,
      ...genericListFilteredData.containsList,
    ];
    if (tempValue.length === 0) {
      setSuggestedBrand([]);
      setBrandInputString("");
      setSelectedBrand(null);
      setUniqueDoses();
    }
    return tempValue.slice(0, 10);
  }, [genericSearchInput, doctorGenericList, genericList]);

  // const suggestedBrand = useMemo(() => {
  //   const tempBrandList = getFilteredBrand(brandList, brandInputString);
  //   return tempBrandList.slice(0, MAX_SUGGESTED_RESULT);
  // }, [brandList, brandInputString]);

  const updateSuggestedBrand = (data, str) => {
    const tempBrandList = getFilteredBrand(data, str);
    setSuggestedBrand(tempBrandList.slice(0, MAX_SUGGESTED_RESULT));
  };

  useEffect(() => {
    if (data.medication) {
      setMedicationList(data.medication);
    }
    updateSuggestedBrand(brandList, brandInputString);
  }, [brandList, brandInputString]);

  useEffect(() => {
    let doctorGenList = JSON.parse(localStorage.getItem("doctor_generic"));
    setDoctorGenericList(doctorGenList);
    let genList = JSON.parse(localStorage.getItem("generic"));
    setGenericList(genList);
    getBrands();
    getFrequencies();
    getDurations();
    getFormDosages();
  }, []);

  const getFrequencies = () => {
    const diseasetring = localStorage.getItem("frequencyList");
    if (diseasetring) {
      setFrequencyList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getFrequencies(reqDto).then((response) => {
      if (response.data) {
        setFrequencyList(response.data);
        localStorage.setItem("frequencyList", JSON.stringify(response.data));
      }
    });
  };

  const getDurations = () => {
    const diseasetring = localStorage.getItem("durationList");
    if (diseasetring) {
      setDurationList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getDurations(reqDto).then((response) => {
      if (response.data) {
        setDurationList(response.data);
        localStorage.setItem("durationList", JSON.stringify(response.data));
      }
    });
  };

  const getFormDosages = () => {
    const diseasetring = localStorage.getItem("dosageList");
    if (diseasetring) {
      setDosageList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getFormDosages(reqDto).then((response) => {
      if (response.data) {
        setDosageList(response.data);
        localStorage.setItem("dosageList", JSON.stringify(response.data));
      }
    });
  };

  const getBrands = () => {
    const brandString = localStorage.getItem("brandList");
    if (brandString) {
      setBrandList(JSON.parse(brandString));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getBrands(reqDto).then((response) => {
      if (response.data) {
        setBrandList(response.data);
        localStorage.setItem("brandList", JSON.stringify(response.data));
      }
    });
  };

  const handleChangemTiming = (event) => {
    const {
      target: { value },
    } = event;
    setMTiming(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeDosage = (event) => {
    setDosage(event.target.value);
  };

  const handleChangemForm = (event) => {
    setMForm(event.target.value);
  };

  const handleChangeFrequency = (event) => {
    setFrequency(event.target.value);
  };

  const handleChangeRegimen = (event) => {
    setRegimen(event.target.value);
  };

  const initializeForm = () => {
    setDosage("");
    setMForm("");
    setFrequency("");
    setRegimen("");
    setGenericSearchInput("");
    setSelectedGeneric(null);
    setMedicationNote("");
    setMTiming([]);
    setSelectedBrand(null);
    setBrandInputString("");
    setSuggestedDosage([]);
  };

  const setDosageAccordingToFormId = (formId) => {
    const dosageId = dosageList.filter((item) => item.formId === formId);
    setSuggestedDosage(dosageId);
    // return dosageId;
  };

  const handleAddMedication = () => {
    if (
      !selectedGeneric &&
      (!genericSearchInput || genericSearchInput === "")
    ) {
      toast.error("Please Select Medicine Name");
      return;
    }
    if (!dosage || dosage === "") {
      toast.error("Please Select Dosage");
      return;
    }

    if (!frequency || frequency === "") {
      toast.error("Please Select Frequency");
      return;
    }

    if (!regimen || regimen === "") {
      toast.error("Please Select Duration");
      return;
    }

    const tempFreq = frequencyList.find((item) => item.id === frequency);
    const tempDuration = durationList.find((item) => item.id === regimen);
    let genericName = selectedGeneric;
    if (!genericName) {
      genericName = {
        id: 0,
        generic: genericSearchInput,
        formId: -1,
        locallyAdded: true,
      };
    }

    const dto = {
      frequency: tempFreq,
      dosage,
      root: mForm,
      note: medicationNote,
      duration: tempDuration,
      timing: mTiming,
      genericName,
      brandName: selectedBrand,
    };
    setMedicationList((prev) => {
      const temp = [...prev, dto];
      updateData({ medication: temp });
      return temp;
    });
    initializeForm();
  };

  useEffect(() => {
    const str = medicationList
      .map(
        (item) =>
          `${item.genericName.generic} (${item.dosage.dosage}- ${item.frequency.frequency} - ${item.duration.durationName})`
      )
      .join(", ");
    setAccordionString(str);
  }, [medicationList]);

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            Medication:
            {/* <span>symptoms gose here when acordion not expend</span> */}
            {!accordionExpanded && <span>{accordionString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="addNewformGrp">
            <div className="formElement">
              {/* <FormControl className="formControl">
                <TextField
                  // hiddenLabel
                  label="Search by Generic"
                  autoComplete="off"
                  variant="outlined"
                  className="formTextFieldArea"
                  value={genericSearchInput}
                  onChange={(e) => {
                    setGenericSearchInput(e.target.value);
                  }}
                />
              </FormControl> */}
              <FormControl className="formControl">
                <Autocomplete
                  freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedGeneric}
                  options={suggestedMedicineList}
                  inputValue={genericSearchInput}
                  onChange={(e, newValue) => {
                    setSelectedGeneric(newValue);
                    if (newValue) {
                      const { id, formId } = newValue;
                      setDosageAccordingToFormId(formId);
                      const tempBrand = brandList.filter(
                        (item) => item.genericId === id
                      );
                      if (tempBrand && tempBrand.length > 0) {
                        setSuggestedBrand(
                          tempBrand.slice(0, MAX_SUGGESTED_RESULT)
                        );
                      }
                    } else {
                      setBrandInputString("");
                      setSelectedBrand(null);
                    }
                  }}
                  onInputChange={(e, value, reason) => {
                    setGenericSearchInput(value);
                    if (value === "" || !value) {
                      // setBrandInputString("");
                      updateSuggestedBrand(brandList, "");
                    }
                    if (reason === "clear") {
                      setSuggestedDosage([]);
                    }
                  }}
                  getOptionLabel={(option) => option.generic}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.id}>
                        {item.generic}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder="Generic Name"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                {/* <TextField
                  // hiddenLabel
                  label="Search by Brand"
                  autoComplete="off"
                  variant="outlined"
                  className="formTextFieldArea"
                /> */}

                <Autocomplete
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedBrand}
                  options={suggestedBrand}
                  inputValue={brandInputString}
                  onChange={(e, newValue) => {
                    setSelectedBrand(newValue);
                    if (newValue) {
                      const { genericId, formId } = newValue;
                      setDosageAccordingToFormId(formId);
                      let tempDoctorGeneric = doctorGenericList.find(
                        (item) => item.id === genericId
                      );
                      if (tempDoctorGeneric) {
                        setSelectedGeneric(tempDoctorGeneric);
                      } else {
                        tempDoctorGeneric = genericList.find(
                          (item) => item.id === genericId
                        );
                        if (tempDoctorGeneric) {
                          setSelectedGeneric(tempDoctorGeneric);
                        } else {
                          toast.error(
                            "This Brand's Generic name is not present in the table"
                          );
                        }
                      }
                    }
                  }}
                  onInputChange={(e, value) => {
                    setBrandInputString(value);
                  }}
                  getOptionLabel={(option) => option.brandName}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.id}>
                        {item.brandName}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder="Search by Brand"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className="formElementGroup ">
            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="severity-select-label">Dosage*</InputLabel>
                <Select
                  labelId="severity-select-label"
                  id="severity-select"
                  required
                  value={dosage}
                  label="Dosage"
                  onChange={handleChangeDosage}
                  className="formInputField"
                  variant="outlined"
                >
                  {suggestedDosage &&
                    suggestedDosage.map((item) => {
                      return <MenuItem value={item}>{item.dosage} </MenuItem>;
                    })}
                  {/* <MenuItem value="2">Two</MenuItem>
                  <MenuItem value="3"> Three </MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="Form-select-label">Route</InputLabel>
                <Select
                  labelId="Form-select-label"
                  id="Form-select"
                  value={mForm}
                  label="Form"
                  onChange={handleChangemForm}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="Oral">Oral</MenuItem>
                  <MenuItem value="Inject">Inject</MenuItem>
                  <MenuItem value="Apply"> Apply </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="frequency-select-label">Frequency*</InputLabel>
                <Select
                  labelId="frequency-select-label"
                  id="frequency-select"
                  value={frequency}
                  label="Frequency"
                  onChange={handleChangeFrequency}
                  className="formInputField"
                  variant="outlined"
                >
                  {frequencyList &&
                    frequencyList.map((item) => {
                      return (
                        <MenuItem value={item.id}>{item.frequency} </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="regimen-select-label">Duration*</InputLabel>
                <Select
                  labelId="regimen-select-label"
                  id="regimen-select"
                  value={regimen}
                  label="Duration"
                  onChange={handleChangeRegimen}
                  className="formInputField"
                  variant="outlined"
                >
                  {durationList &&
                    durationList.map((item) => {
                      return (
                        <MenuItem value={item.id}>
                          {item.durationName}{" "}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>

            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="demo-multiple-checkbox-label">
                  Timing
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={mTiming}
                  onChange={handleChangemTiming}
                  input={<OutlinedInput label="Timing" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  className="formInputField"
                  variant="outlined"
                >
                  {timings.map((time) => (
                    <MenuItem key={time} value={time}>
                      <Checkbox checked={mTiming.indexOf(time) > -1} />
                      <ListItemText primary={time} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="addNewformGrp">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  // hiddenLabel
                  label="Notes"
                  autoComplete="off"
                  variant="outlined"
                  value={medicationNote}
                  onChange={(e) => {
                    setMedicationNote(e.target.value);
                  }}
                  className="formTextFieldArea"
                />
              </FormControl>
            </div>

            <Button
              className="dfultPrimaryBtn "
              startIcon={<Add />}
              onClick={handleAddMedication}
            >
              Add
            </Button>
          </div>
          <div className="bb"></div>
          <div className="complaintList mt10">
            <ul>
              {medicationList &&
                medicationList.map((item, index) => {
                  return (
                    <>
                      <li>
                        <div className="complstDtlGrp ">
                          <div className="complstDtl">
                            <div className="compntName ">
                              {item.genericName.generic}
                            </div>
                            <div className="compntName BrandMed">
                              {item.brandName && item.brandName.brandName}
                            </div>
                            <div className="compinfoGRP">
                              <div className="compStime">
                                <span>Dosage:</span>
                                <span className="data">
                                  {item.dosage.dosage}
                                </span>
                              </div>
                              <div className="compStime">
                                <span>Frequency:</span>
                                <span className="data">
                                  {item.frequency.frequency}
                                </span>
                              </div>
                              <div className="compStime">
                                <span>Duration:</span>
                                <span className="data">
                                  {item.duration.durationName}
                                </span>
                              </div>

                              {item.timing && item.timing.length !== 0 && (
                                <div className="compStime">
                                  <span>Time:</span>
                                  <span className="data">
                                    {item.timing.join(", ")}
                                  </span>
                                </div>
                              )}
                              {item.note && item.note !== "" && (
                                <div className="compStime">
                                  <span>Notes:</span>
                                  <span className="data">{item.note}</span>
                                </div>
                              )}
                            </div>
                          </div>
                          <IconButton
                            className="removeItemBtn"
                            onClick={() => {
                              const temp = [...medicationList];
                              temp.splice(index, 1);
                              updateData({ medication: temp });
                              setMedicationList(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      </li>
                    </>
                  );
                })}

              {/* <li>
                <div className="complstDtlGrp ">
                  <div className="complstDtl">
                    <div className="compntName ">
                      ONDANSETRON HYDROCHLORIDE 2 MG + PARACETAMOL 125 MG/5ML
                      SUSPENSION
                    </div>
                    <div className="compntName BrandMed">
                      SATRON PLUS 30 ML SUSP
                    </div>
                    <div className="compinfoGRP">
                      <div className="compStime">
                        <span>Dosage:</span>
                        <span className="data">1</span>
                      </div>
                      <div className="compStime">
                        <span>Frequency:</span>
                        <span className="data">BID</span>
                      </div>
                      <div className="compStime">
                        <span>Duration:</span>
                        <span className="data">6 Days</span>
                      </div>
                      <div className="compStime">
                        <span>Time:</span>
                        <span className="data">Stat, After Breakfast</span>
                      </div>
                      <div className="compStime">
                        <span>Notes:</span>
                        <span className="data">Drink Lots of Water</span>
                      </div>
                    </div>
                  </div>
                  <IconButton className="removeItemBtn">
                    <Trash />
                  </IconButton>
                </div>
              </li> */}
            </ul>
          </div>
          <div className="bb"></div>
          <div className="ddBtnGroup flxSPBTW">
            <Tooltip arrow title="DRUG SAFETY CHECK ">
              <Button
                className="differnDiagnosBtn"
                startIcon={<HealthAndSafety />}
              >
                DRUG SAFETY CHECK
              </Button>
            </Tooltip>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
