import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Trash } from "react-bootstrap-icons";
import DashboardServices from "../../../../../../../services/DashboardServices";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";

import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

export default function FoodAllergy({ updateData, data }) {
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [selectedAllergicFood, setSelectedAllergicFood] = useState(null);
  const [allergicFoodSearchString, setAllergicFoodSearchString] = useState("");

  const [allergicFoodList, setAllergicFoodList] = useState([]);
  const [userAllergicFoodList, setUserAllergicFoodList] = useState([]);

  const initializeForm = () => {
    setSelectedAllergicFood(null);
    setAllergicFoodSearchString("");
  };

  const suggestedFood = useMemo(() => {
    const filteredList = getFilteredList(
      allergicFoodList,
      allergicFoodSearchString,
      "foodName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [allergicFoodSearchString, allergicFoodList]);

  const allergicFoodString = useMemo(() => {
    const str =
      userAllergicFoodList.length > 0
        ? userAllergicFoodList.map((item) => item.foodName).join(", ")
        : "";
    return str;
  }, [userAllergicFoodList]);

  useEffect(() => {
    if (data.allergicFood) {
      setUserAllergicFoodList(data.allergicFood);
    }
    getFoodAllergies();
  }, []);

  const getFoodAllergies = () => {
    const foodAlleergyString = localStorage.getItem("foodAllergies");
    if (foodAlleergyString) {
      setAllergicFoodList(JSON.parse(foodAlleergyString));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getFoodAllergies(reqDto).then((response) => {
      if (response.data) {
        setAllergicFoodList(response.data);
        localStorage.setItem("foodAllergies", JSON.stringify(response.data));
      }
    });
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            Food Allergy:
            {!accordionExpanded && <span>{allergicFoodString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="addNewformGrp">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <TextField
                  // hiddenLabel
                  label="Food Name"
                  autoComplete="off"
                  variant="outlined"
                  className="formTextFieldArea"
                /> */}

                <Autocomplete
                  freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedAllergicFood}
                  options={suggestedFood}
                  inputValue={allergicFoodSearchString}
                  onChange={(e, newValue) => {
                    setSelectedAllergicFood(newValue);
                  }}
                  onInputChange={(e, value, reason) => {
                    if (e && reason === "input") {
                      setAllergicFoodSearchString(e.target.value);
                    } else if (reason === "reset") {
                      setAllergicFoodSearchString(value);
                    } else if (reason === "clear") {
                      setAllergicFoodSearchString(value);
                    }
                    // else {
                    //   setAllergicFoodSearchString("");
                    // }
                  }}
                  getOptionLabel={(option) => option.foodName}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.id}>
                        {item.foodName}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder="Food Name"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <Button
              className="dfultPrimaryBtn "
              startIcon={<Add />}
              onClick={() => {
                let tempAllergicFood = selectedAllergicFood;
                if (!tempAllergicFood) {
                  if (
                    !allergicFoodSearchString ||
                    allergicFoodSearchString === ""
                  ) {
                    toast.error("Please Provide Food Name");
                    return;
                  }
                  tempAllergicFood = {
                    id: uuidv4(),
                    foodName: allergicFoodSearchString,
                    locallyAdded: true,
                  };
                }
                setUserAllergicFoodList((prev) => {
                  const temp = [...prev, tempAllergicFood];
                  updateData({ allergicFood: temp });
                  return temp;
                });
                initializeForm();
              }}
            >
              Add
            </Button>
          </div>
          <div className="bb"></div>
          <div className="complaintList mt10">
            <ul>
              {userAllergicFoodList &&
                userAllergicFoodList.map((food, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp ">
                        <div className="complstDtl">
                          <div className="compntName ">{food.foodName}</div>
                        </div>
                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...userAllergicFoodList];
                            temp.splice(index, 1);
                            updateData({ allergicFood: temp });
                            setUserAllergicFoodList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
