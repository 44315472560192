import { Add, Edit, Search } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import {
  getSalaryHeads,
  deactivategetSalaryHead,
} from "../../../../services/AdminService";

export default function DefineSalaryHead({
  handelOpenCreateSalaryHead,
  refreshSalaryHead,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const [salaryHeadData, setSalaryHeadData] = useState([]);
  const [filteredSalaryHeadData, setFilteredSalaryHeadData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const [salaryHeadToDelete, setSalaryHeadToDelete] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSalaryHeaddata();
  }, [refreshSalaryHead]);

  const fetchSalaryHeaddata = () => {
    getSalaryHeads(userDetails.orgId)
      .then((response) => {
        console.log("getSalaryHeads", response.data);
        setSalaryHeadData(response.data);
        setFilteredSalaryHeadData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = salaryHeadData.filter(
      (SalaryHead) =>
        SalaryHead.salaryHeadName &&
        SalaryHead.salaryHeadName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredSalaryHeadData(filteredData);
  };

  const handleDelete = (salaryHeadId) => {
    console.log("handleDelete", salaryHeadId);
    setSalaryHeadToDelete(salaryHeadId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    if (salaryHeadToDelete) {
      deactivategetSalaryHead(salaryHeadToDelete, userDetails.userId).then(
        (response) => {
          console.log("data deleted", response.data);
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchSalaryHeaddata();
            setOpenModal(false); // Close the modal after deletion
          }
        }
      );
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          {/* <div className="cuhederTxt">Departmment List</div> */}
          <div className="searchTasklist">
            <input
              type="text"
              class="serchTaskInput"
              placeholder="Search Salary Head"
              value={searchQuery}
              onChange={handleSearch}
            />
            <IconButton className="seacrhIcon">
              <Search />
            </IconButton>
          </div>

          <div className="addUnitsBtn">
            {/* <Button
              startIcon={<AccountTree />}
              className="dfultDarkSecondaryBtn"
              // onClick={() => handleOpenUnitTreeView()}
            >
              Departmment Tree
            </Button> */}
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => handelOpenCreateSalaryHead()}
            >
              Add Salary Head
            </Button>
          </div>
        </div>

        <div className="cuContainArea">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : salaryHeadData.length === 0 ? (
            <div class="noDataCard ">
              <span>Oops!!</span>No Salary Head Define yet !!
              <br />
              <span className="sallMsg">
                Click Add Salary Head Button to add new Salary Head
              </span>
            </div>
          ) : (
            <div className="TableContainer">
              <table className="taskListTable">
                <thead className="taskListtableHeader">
                  <tr>
                    <th>Order</th>
                    <th>Salary Head Name</th>
                    <th>Calculation Basis</th>
                    <th>Percentage %</th>
                    <th>Group Name</th>
                    <th className="width100">Action</th>
                  </tr>
                </thead>
                <tbody className="scrolableTableBody">
                  {filteredSalaryHeadData.map((head, index) => (
                    <tr key={head.salaryHeadId}>
                      <td>
                        <div className="tskLstTaskNM">{head.slNo}</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {head.salaryHeadName}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {head.calculationBasisSalaryHeadName}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {head.calculationPercentage}%
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {head.salaryHeadGroup}
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          {/* <IconButton>
                            <Edit />
                          </IconButton> */}
                          <IconButton
                            className="removeRowBtn"
                            onClick={() => handleDelete(head.salaryHeadId)}
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  ))}

                  {/* <tr>
                  <td>
                    <div className="tskLstTaskNM">1.</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">HRA</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">Basic</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">30%</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">Gross Per Month (GPM)</div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <IconButton>
                        <Edit />
                      </IconButton>
                      <IconButton className="removeRowBtn">
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                </tbody>
              </table>
            </div>
          )}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                // width: "250px",
                // height: "250px",
                maxWidth: "600px",
                minHeight: "150px",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <h2 id="modal-modal-title">
                Do you want to delete this SalaryHead ?
              </h2>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  // variant="contained"
                  // color="secondary"
                  className="dfultPrimaryBtn"
                  onClick={confirmDelete}
                >
                  Yes
                </Button>
                <Button 
                // variant="contained" 
                className="dfultDarkSecondaryBtn"
                onClick={handleCloseModal}>
                  No
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
