import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taskList: [],
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setTasks: (state, action) => {
      state.taskList = action.payload;
    },
  },
});

export default taskSlice;
export const { setTasks } = taskSlice.actions;
