export const enLang = {
  // new dashboard start
  open_setting: "Open Setting",
  accounts: "Accounts",
  scheduler: "Scheduler",
  manage_tasks: "Manage Tasks",
  manage_meetings: "Manage Meetings",
  chat: "Chat",
  drive: "Drive",
  google_search: "Google Search",
  sheets: "Sheets",
  word_processor: "Word Processor",
  slides: "Slides",
  analysis: "Analysis",
  profile: "Profile",
  sign_out: "Sign Out",
  show_hide: "Show/Hide",
  minimize: "Minimize",
  maximize: "Maximize",
  close: "Close",
  meeting: "Meeting",
  pad: "Pad",
  white_board: "WhiteBoard",
  task: "Task",
  pending_task: "Pending Task",
  completed_task: "Completed Task",
  concern: "Concern",
  delayed_task: "Delayed Task",
  under_process: "Under Process",
  create_new_task: "Create New Task",
  choose_department: "Choose Department",
  choose_activity: "Choose Activity",
  activity: "Activity",
  select_contract: "Select Contract",
  description: "Description",
  fill_the_form: "Fill The Form",
  upload: "Upload",
  assign_user: "Assign User",
  select_time: "Select Time",
  select_priority: "Select Priority",
  select_meeting: "Select Meeting",
  select_template: "Select Template",
  assign_user_to_selected_task: "Assign user to selected Task",
  select_date_time: "Select Completion  Date Time",
  added_templates: "Added Templates",
  available_templates: "Available Templates",
  view_template: "View Template",
  full_screen: "Full Screen",
  exit_full_screen: "Exit Full Screen",
  close_template: "Close Template",
  no_files_uploaded: "No Files uploaded",
  my_accounts: "My Accounts",
  notifications: "Notifications",
  opened_files: "Opened Files",
  active_files: "Active Files",
  active_file: "Active File",
  opened: "Opened",
  // new dashboard end

  // user profile start
  change_avtar: "Change Avatar",
  edit: "Edit",
  first_name: "First Name",
  middle_name: "Middle Name",
  last_name: "Last Name",
  cancel: "Cancel",
  save: "Save",
  update_your_photo_and_personal_details:
    "Update Your Photo and Personal Details",
  employee_id: "Employee Id",
  department: "Department",
  designations: "Designations",
  joining_date: "Joining Date",
  reporting_head: "Reporting Head",
  date_of_birth: "Date of Birth",
  martial_status: "Marital Status",
  choose_martial_status: "Choose Marital Status",
  select: "Select",
  single: "Single",
  married: "Married",
  widowed: "Widowed",
  divorced: "Divorced",
  separated: "Separated",
  official_phone_no: "Official Phone No",
  personal_phone_no: "Personal Phone No",
  emergency_phone_no: "Emergency Phone No",
  official_email_id: "Official Email Id",
  personal_email_id: "Personal Email Id",
  password: "Password",
  reset_password: "Reset Password",
  current_password: "Current Password",
  new_password: "New Password",
  confirm_password: "Confirm Password",
  language: "Language",
  select_languages: "Select Languages",
  skills: "Skills",
  add_skills: "Add Skills",
  choose_your_own_avtar: "Choose Your Own Avatar",
  our_pre_biuld_avtar: "Pre Built",
  upload_form_pc: "Upload",
  avtar_by_ai: "AI Avatar",
  ai_avtar_coomiing_soon: " AI Avatar Coming Soon!",
  // user profile end

  // task container start
  please_give_a_comment: "Please Give a Comment",
  please_give_a_concern: "Please Give a Concern",
  your_progress_of_task_cant_not_decrease:
    "Your Progress Of Task Can't not Decrease",
  please_type_your_reason: "Please Type Your Reason",
  please_select_a_user: "Please Select a User",
  no_templates_found: "No Templates Found",
  created_by: "Created By",
  name: "Name",
  work: "Work",
  task: "Task",
  status: "Status",
  detail: "Detail",
  add_concern_here: "Add concern here",
  comments: "Comments",
  view: "View",
  add_link_task: "Add Link Task",
  transfer: "Transfer",
  meetings: "Meetings",
  from: "From",
  templates: "Templates",
  link_task: "Link Task",
  submit: "Submit",
  ok: " OK",
  assignd_to: "Assigned to",
  re_assign: "Re-Assign",
  disagree: "Disagree",
  sign_off: "Sign Off",
  view_attachment: "View Attachment",
  view_task_history: "View Task History",
  reason_for_transfer: "Reason For Transfer",
  manage_your_files: "Manage Your Files",
  upload_document: "Upload Document",
  file_details: "File Details",
  please_enter_subTask_details: "Please Enter SubTask Details",
  please_select_users: "Please Select Users",
  please_enter_task_description: "Please Enter Task Description",
  create_link_task: "Create Link Task",
  linked_task: "Linked Task",
  // task container end

  gender: "Gender",
  job_description: "Job Description",
  timezone: "Timezone",
  current_address: "Current Address",
  permanent_address: "Permanent Address",
  guardian_name: "Guardian Name",
  guardian_relationship: "Guardian Relationship",
  spouse_Name: "Spouse Name",
  blood_groud: "Blood Groud",
  religion: "Religion",
  bank_name: "Bank Name",
  ifsc_code: "IFSC Code",
  bank_branch: "Bank Branch",
  beneficiary_name: "Beneficiary Name",
  bic_code: "BIC Code",
  swift_code: "Swift Code",
  iban_number: "IBAN Number",
  highest_qualification: "Highest Qualification",
  highest_qualification_year: "Highest Qualification Year",
  highest_qualification_institute: "Highest Qualification Institute",
  kra: "KRA",
  experiences: "Experiences",
  govt_ids: "Govt Ids",
  highest_qualification_certificate: "Highest Qualification Certificate",
  fitness_certificate: "Fitness Certificate",
  job_title: "Job Title",
  nature_of_appointment: "NATURE OF APPOINTMENT",
  type_of_position: "TYPE OF POSITION",
  new: "New",
  replacement: "Replacement",
  location_required_for: "LOCATION REQUIRED FOR",
  qualification: "QUALIFICATION",
  reset_form: "Reset Form",
  event: "Event",
  booked_event: "Booked Event",
  canceled_event: "Cancelled Event",
  reschedule_event: "Reschedule Event",
  contract_wise_pending_task: "Contract wise pending task",
  organization_tree: "Organization Tree",
  employee_with: "Employee with",
  most: "Most",
  few: "Few",
  no: "No",
  last_7_days: "Last 7 Days",
  last_30_days: "Last 30 Days",
  last_60_days: "Last 60 Days",
  activity_wise_completed_tasks: "Activity Wise Completed Tasks",
  title_wise_events: "Title Wise Events",
  day_wise_completed_task: "Day Wise Completed Task",
  day_wise_events: "Day Wise Events",
  routine: "Routine",
  important: "Important",
  critical: "Critical",
  timeline: "Timeline",

  please_enter_room_id: "Please enter room Id",
  room_number: "Room Number *",
  todays_meetings: "Todays Meetings",
  join: "Join",
  schedule_meeting: "Schedule Meeting",
  start_instant_meeting: "Start Instant Meeting",

  meeting_details: "Meeting Details",
  please_enter_meeting_topic: "Please enter meeting topic",
  start_meeting: "Start Meeting",
  end_meeting: "End Meeting",

  please_change: "Please change",
  from_first_day_of_week_before_removing_it_from_working_day:
    "from FIrst Day Of Week before removing it from working day",
  title: "Title",
  start_time: "Start Time",
  end_time: "End Time",
  duration: "Duration",
  location: "Location",
  new_event: "New Event",
  new_recurring_event: "New Recurring Event",
  new_meeting: "New Meeting",
  day: "Day",
  week: "Week",
  month: "Month",
  agenda: "Agenda",
  timeline_views: "Timeline Views",
  grouping: "Grouping",
  gridlines: "Gridlines",
  row_auto_height: "Row Auto Height",
  tooltip: "Tooltip",
  allow_multi_drag: "Allow Multi Drag",
  settings: "Settings",
  previous: "Previous",
  next: "Next",
  current_event: "Current Event",
  today: "Today",
  my_calendar: "My Calendar",
  holiday: "Holiday",
  week_off: "Week Off",
  on_leave: "On Leave",

  first_day_of_week: "First Day of Week",
  work_week: "Work Week",
  slot_duration: "Slot Duration",
  select_slot_duration: "Select Slot Duration",
  private_notes: "Private Notes",
  meeting_notes: "Meeting Notes",
  reschedule: "Reschedule",

  add_private_notes: "Add Private Notes",
  add: "Add",

  end_time_should_be_after_start_time: "End time should be after start time",
  please_select_participants: "Please select participants",
  event_title: "Event Title",
  event_description: "Event Description",
  event_location: "Event Location",
  event_start_time: "Event Start Time",
  event_end_time: "Event End Time",
  recurring_event_details: "Recurring Event Details",
  event_days: "Event Days",
  repeat_until: "Repeat Until",
  modify_event: "Modify Event",
  cancel_event: "Cancel Event",
  cancellation_reason: "Cancellation Reason",
  reschedule_reason: "Reschedule Reason",
  event_date: "Event Date",

  file_name: "Enter File Name",
  choose_a_file: "Choose a file",
  please_enter_a_valid_file_name: "Please enter a valid file name!",
  please_enter_a_valid_file_name_special_characters_are_not_allowed:
    "Please enter a valid file name, special characters are not allowed",
  already_exists_replace_file_all_data_will_be_lost:
    "already exists! Replace file, all data will be lost",

  please_enter_your_email: "Please Enter Your Email",
  please_enter_your_password: "Please Enter Your Password",
  invalid_credentials: "Invalid Credentials",
  please_wait_redirecting_to_your_organization_home_page:
    "Please wait, redirecting to your organization home page",
  otp_sent_to_email_id_successfully: "OTP sent to email id successfully",
  please_enter_otp: "Please enter OTP",
  otp_verified_successfully: "OTP verified successfully",
  please_enter_password: "Please enter Password",
  please_enter_confirm_password: "Please enter confirm Password",
  password_and_confirm_password_does_not_match:
    "Password and confirm password does not match",
  password_changed_successfully_redirecting:
    "Password changed successfully, redirecting...",

  lets_get_you: "Let's get you",
  on_the_digital_highway: "on the digital highway",
  login: "Login",
  register: "Register",
  digitize: "DIGITIZE",
  processize: "PROCESSIZE",
  collaborate: "COLLABORATE",
  deliver: "DELIVER",
  create_magic: "Create magic",
  features: "FEATURES",
  virtual_presence: "Virtual Presence",
  create_your_web_presence_with_a_few_clicks_no_need_for_domains_webhosting_or_it_infra:
    "Create your web presence with a few clicks. No need for domains, webhosting or IT Infra",
  organization: "Organization",
  create_your_organization_add_people_assign_departments_functions_and_go_live:
    "Create your organization, add people, assign departments & functions and go live",
  task_management: "Task Management",
  easily_create_tasks_and_monitor_them_remove_management_overheads:
    "Easily create tasks and monitor them. Remove management overheads",
  compliances: "Compliances",
  hold_board_agm_meetings_issue_agenda_take_polls_record_them_keep_track_of_compliances:
    " Hold Board / AGM meetings, issue Agenda, take polls, record them . Keep track of compliances",
  productivity: "Productivity",
  from_word_processor_to_spreadsheet_presenter_all_productivity_tools_built_in_with_drive_and_business_templates:
    "From word processor to spreadsheet & presenter - all productivity tools built in with Drive and business templates",
  collaboration: "Collaboration",
  complete_collaboration_suite_without_subscribing_to_any_other_provider:
    "Complete collaboration suite without subscribing to any other provider",
  security: "Security",
  ensure_data_security_with_best_of_class_network_security_encryption_and_data_safety:
    "Ensure data security with best of class network security, encryption and data safety",
  time_space_language: "Time, space & language",
  with_multi_language_timezone_support_let_work_go_with_teams_clients_and_vendors_across_the_world:
    "With multi language, time zone support, let work go with teams, clients and vendors across the world",
  meetings_phygital: "Meetings - Phygital",
  synchronous_and_asynchronous_meetings_scheduled_and_instant_with_private_rooms_and_webinars:
    "Synchronous and asynchronous meetings - scheduled and instant with private rooms and webinars",
  complete_workflows: "Complete Workflows",
  hr_accounts_finance_business_sales_marketing_customizable_workflows:
    "HR, Accounts, Finance, Business, Sales & Marketing, customizable workflows",
  kill_wastage: "Kill wastage",
  with_task_linked_meetings_and_result_based_task_stop_the_drift_and_save_crucial_time:
    " With task linked meetings and Result based Tasks, stop the drift and save crucial time",
  analytics: "Analytics",
  manage_by_easily_monitoring_what_is_happening_and_allocate_resources_time_money_more_effectively:
    "Manage by easily monitoring what is happening and allocate resources , time & money more effectively",
  api_driven_menus_for_integrating_existing_tools_or_external_mail_messaging_etc_build_on_your_strengths_and_covers_your_gaps:
    "API driven menus for integrating existing tools or external mail, messaging etc. Build on your strengths and covers your gaps",
  goals: "GOALS",
  effectivity: "Effectivity",
  remove_unnecessary_work_and_useless_meetings_with_our_effectivity_tools:
    "Remove unnecessary work and useless meetings with our effectivity tools",
  transcendence: "Transcendence",
  with_ahlans_asynchronous_and_synchronous_collaboration_suite_allow_work_to_happen_with_and_against_time_zones_from_anywhere:
    "With Ahlan's asynchronous and synchronous collaboration suite, allow work to happen with and against time zones from anywhere",
  save_the_climate: "Save the Climate",
  stop_unnecessary_use_of_energy_paper_travel_to_protect_our_world_without_it_our_businesses_do_not_exist:
    "Stop unnecessary use of energy , paper & travel to protect our world. Without it our businesses do not exist",
  get_in_touch: "GET IN TOUCH",
  mailing_address: "Mailing Address",
  email_address: "Email Address",
  phone_number: "Phone Number",
  socials: "Socials",
  please_rotate_your_device: "Please rotate your device",
  we_dont_support_landscape_mode_yet_please_go_back_to_portrait_mode_for_the_best_experience:
    " We don't support landscape mode yet. Please go back to portrait mode for the best experience",
  forgot_password: "Forgot Password",
  dont_have_an_account_sign_up: "Don't have an account? Sign Up",
  proceed: "PROCEED",
  already_have_an_account_sign_in: "Already have an account? Sign in",
  sign_up: "SIGN UP",
  send_otp: "Send OTP",
  submit_otp: "Submit OTP",
  change: "CHANGE",

  //////
  task_information: "Indent Information",
  event_times: "Event Times",
  start_time: "Start Time",
  end_time: "End Time",
  create_org: "Create Organization",
  please_select_progress: "Please Select Progress",
  confirmation: "Confirmation",
  plese_enter_concern: "Please Enter Concern",
  please_enter_comment: "Please Enter Comment",
  select_your_progress_of_task: "Select Your Progress Of Task",
  recommended_templates: "Recommended Templates",
  more_templates: "More Templates",
  list_of_meetings: "List Of Meetings",
  add_internal_user: "Add Internal User",
  add_external_user: "Add External User",
  attend: "Attend",
  unable_to_attend: "Unable to Attend",
  add_contacts: "Add Contacts",
  admin_dashboard: "Admin Dashboard",
  // create_org: "Create Organization",
  create_and_open_file: "Create and Open File",
  record_audio_note: "Record Audio Note",
  audio_note: "Audio Note",

  // dynamic response message
  something_went_wrong: "Something went wrong",
  link_task_not_completed_against_this_task:
    "Link task not completed against this task",
  task_completed_successfully: "Task completed successfully",
  concern_raised_successfully: "Concern Raised Successfully",
  approved_successfully: "Approved successfully",
  task_progress_report_submitted_successfully:
    "Task progress report submitted successfully",
  please_select_contacts: "Please select contacts",
  vendor_partner_added_successfully: "Vendor/Partner added Successfully",
  email_address_already_exists: "Email Address Already Exists",

  //dashboard tooltips
  add_function: "Add Function",
  list_of_function: "List Of Function",
  add_people: "Add People",
  list_of_people: "List Of People",
  add_cost_center: "Add Cost Center",
  add_products: "Add Products",
  list_of_products: "List Of Products",
  add_services: "Add Services",
  list_of_services: "List Of Services",
  service_faq: "Service FAQ",
  add_template: "Add Template",
  list_of_template: "List Of Template",
  add_contracts: "Add Contracts",
  list_of_contracts: "List Of Contracts",
  add_accounts_and_invoices: "Add Accounts and Invoices",
  list_of_accounts_and_invoices: "List of Accounts and Invoices",
  edit_website: "Edit Website",
  drive_details: "Drive Details",
  please_wait: "Please Wait",
  copy_event_details: "Copy Event Details",
  add_Items: "Add Items",
  list_of_Items: "List of Items",
  verified_items: "Verified Items",
  non_verified_items: "Non Verified Items",
  custom_date: "Custom Date",
  enter_meeting: "Enter Meeting",
  zoyel_assistant: "Zoyel Assistant",
  enter_zoyel_mode: "Enter Zoyel mode",
  remove_notification: "Remove notification",
  back_to_normal_mode: "Back to Normal Mode",
  switch_account: "Switch account",
  max_length_15: "Maximum length is 15",
  physical_meeting: "Physical Meeting",
  start_convenient_meeting: "Start Convenient Meeting",
  select_and_drag_to_set_you_timing: "Select and drag to set the timing",
  approved_by: "Approved By",
  task_has_been_cancelled: "Task has been cancelled",
  task_has_been_closed: "Task has been closed",
  task_has_been_completed: "Task has been completed",
  documents_uploaded: "Documents Uploaded",
  please_fill_the_form_correctly: "Please Fill The Form Correctly",
  document_viewer: "Document Viewer",
  reject: "Reject",
  add_rejection_reason_here: "Add Rejection Reason Here",
  rejected_by: "Rejected By",
  rejection_reason: "Rejection Reason",
  add_cancelation_reason_here: "Add Cancelation Reason Here",
  change_mode: "Change Mode",
  word: "Word",
  assistant: "Assistant",

  menu: "Menu",
  open_main_chat_window: "Open Main Chat Window",
  view_form: "View Form",
  postponed: "Postponed",
  postpone_by: "Postponed By",
  add_postponed_details_here: "Add Postponed Details Here",
  postponed_reason: "Postponed Reason",
  postponed_date: "Postponed Date",
  please_select_correct_date: "Please Select Correct Date",
  postpone_till: "Postpone Till",
  reopen_postpone_task: "Reopen Postpone Task",
  password_changed_successfully: "Password Changed Successfully",
  password_changed_successfully_please_login:
    "Password Changed Successfully, Please login...",

    //////////Analytical Dashboard/////////////
    dashboard : "Dashboard" ,
    oops : "Oops!!" ,
    permission_for_dashboard_modules : "Permission for Dashboard modules not found, please contact your Administrator" ,
    attendance : "Attendance" ,
    view : "View" ,
    add : "Add" ,
    budget : "Budget" ,
    contract : "Contract" ,
    external_user : "External User" ,
    no_of_user : " No of User" ,
    holiday_working_day : "Holiday / Working Day" ,
    no_of_holiday : "No of Holiday" ,
    items : "Items" ,
    leave_rules : "Leave Rules" ,
    payroll : "Payroll" ,
    active_employee : "Active Employee" ,
    tenure : "Tenure" ,
    employee : "Employee" ,
    attrition : "Attrition" ,
    routine_work : "Routine Work" ,
    add_budget : "Add Budget" ,
    consolidated : " Consolidated " ,
    unit_wise : "Unit wise" ,
    select_unit : "Select unit" ,
    budget_name : "Budget Name" ,
    from_date : "From Date" ,
    to_date : " To Date" ,
    capital_expenditure_head : "Capital Expenditure Head" ,
    operating_expenditure_head : "Operating Expenditure Head" ,
    submit : "Submit" ,
    cancel : "Cancel" ,
    add_contract : "Add Contract" ,
    contract_name : "Contract Name" ,
    responsible_person_name : "Responsible Person Name" ,
    contract_type : "Contract Type" ,
    contract_owner : "Contract Owner" ,
    attach_file : "Attach File" ,
    add_external_user : "Add External User" , 
    select_user_type : "Select User Type" , 
    vendor : 'Vendor' ,
    partner : "Partner" ,
    client : "Client" ,
    adviser : "Adviser" ,
    board_Member : "Board Member" ,
    consultant : "Consultant" ,
    title : "Title" ,
    mr : "Mr" ,
    ms : "Ms" ,
    mrs : "Mrs" ,
    miss : "Miss" ,
    first_name : "First name" ,
    middle_name : "Middle name" ,
    last_name : "Last name" ,
    official_email_id : "Official Email Id" ,
    country_code : "Country Code" , 
    phone_number : "Phone Number" ,
    full_address : "Full Address" ,
    fax_number : "Fax Number" ,
    gst_number : "GST Number" ,
    add_holiday : "Add Holiday" ,
    holiday_name : "Holiday Name" ,
    holiday_date : "Holiday Date" ,
    holiday_description_for_email : "Holiday Description for Email" ,
    attach_image_for_emailer : "Attach Image for Emailer" ,
    preview : "Preview" ,
    holiday_emailer_preview : "Holiday Emailer Preview" ,
    greeting : "Greeting !!!" ,
    item_type : "Item Type" , 
    goods : "Goods" ,
    service : "Service" ,
    item_name : "Item Name" ,
    description : "Description" ,
    category : "Category" ,
    add_category : "Add Category" ,
    default_uom : "Default UOM" ,
    uom : "UOM" ,
    add_uom : "Add UOM" ,
    manufacturer : "Manufacturer" ,
    add_manufacturer : "Add Manufacturer" ,
    hsn_code : "HSN Code" ,
    expirable : "Expirable" ,
    warrantee_guarantee_sla : "Warrantee/Guarantee/SLA" ,
    tangible : "Tangible" ,
    intangible : "Intangible" ,
    category_name : "Category Name" ,
    add_item_type : "Add Item Type" ,
    enter_uom : "Enter UOM" ,
    manufacturer_name : "Manufacturer Name" ,
    add_leave_rules : "Add Leave Rules" ,
    select_enter_leave_type : "Select / Enter Leave Type" ,
    select_gender : "Select Gender" ,
    employee_status : "Employee Status" ,
    salary_type : "Salary Type" ,
    required_documents : "Required Documents" ,
    example_file_names : "Ex: File One, File Two, etc." ,
    this_is_need_for_supportive_documents : "This is need for supportive documents, add file names separated by a comma for eg: File One, File Two, etc",
    number_of_leaves : "Number of Leaves" ,
    Leave_calculation_can_be_done_either_based_on_total_number_of_leaves : "Leave calculation can be done either based on Total Number of leaves, leaves accrued monthly or leaves accrued yearly(if  any leave is accrued monthly then enter the value in Leave Accrued Monthly text box and keep the rest of the text boxes Blank i.e. (Total Number of Leaves and Leaves Accrued Yearly blank)" ,
    leave_accrued_monthly : "Leave Accrued Monthly" ,
    leave_accrued_yearly : "Leave Accrued Yearly" ,
    minimum_leaves : "Minimum Leaves" ,
    minimum_number_of_leaves_an_employee_may_apply_for_a_particular_leave_type : "Minimum number of leaves an employee may apply for a particular leave type" ,
    maximum_leaves : "Maximum Leaves" ,
    Maximum_number_of_leaves_an_employee_may_apply_at_a_stretch : "Maximum number of leaves an employee may apply at a stretch" ,
    allow_half_day : "Allow Half Day" ,
    if_half_day_is_applicable_for_this_leave_type : "If half day is applicable for this leave type " ,
    combination_with_other_leaves : "Combination with Other Leaves" ,
    other_leave_type_that_may_be_combined_with_this_leave_type_as_per_the_organizational_leave_policy_for_eg_medical_sick_leave_may_be_combined_with_leave_x_leave_Y : "Other leave type that may be combined with this leave type as per the organizational leave policy( for eg. Medical / Sick leave may be combined with leave X & leave Y.)" ,
    minimum_employment_tenure_needed : "Minimum Employment Tenure Needed" ,





    //////////////////////////////////////////
};
