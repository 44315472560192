import { Edit, OpenInNew } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import { Eye } from "iconoir-react";
import React, { useState } from "react";
import { XLg } from "react-bootstrap-icons";
import PrescriptionEdit from "./consultation/PrescriptionEdit";
import PrescriptionView from "./consultation/PrescriptionView";
import { toast } from "react-toastify";

export default function DoctorConsultations(props) {
  // coustom tooltip start
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 320,
      minWidth: 180,
      maxHeight: 200,
      overflow: "overlay",
      padding: "16px 20px",
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  // coustom tooltip end

  const [showPrescription, setShowPrescription] = useState(true);
  const [prescriptionData, setPrescriptionData] = useState({});

  const updateData = (data) => {
    // toast.success("successfully added data");
    console.log("data =", data);
    setPrescriptionData((prev) => ({ ...prev, ...data }));
  };

  const validatePescriptionData = () => {
    //Validate Symptoms
    let isValid = true;
    if (!prescriptionData.symptoms || prescriptionData.symptoms.length === 0) {
      toast.error("Symptoms cannot be empty.");
      isValid = false;
    }

    if (
      prescriptionData.referType &&
      prescriptionData.referType === "otherSpeciality" &&
      (!prescriptionData.referDoctorSpeciality ||
        prescriptionData.referDoctorSpeciality === "")
    ) {
      toast.error("Please Select Doctor Speciality");
      isValid = false;
    }

    // if (
    //   !prescriptionData.riskFactor ||
    //   prescriptionData.riskFactor.length === 0
    // ) {
    //   toast.error("Riskfactor cannot be empty.");
    //   isValid = false;
    // }

    // if (
    //   !prescriptionData.investigation ||
    //   prescriptionData.investigation.length === 0
    // ) {
    //   toast.error("Investigation cannot be empty.");
    //   isValid = false;
    // }

    // if (
    //   !prescriptionData.diagonesis ||
    //   prescriptionData.diagonesis.length === 0
    // ) {
    //   toast.error("Diagonesis cannot be empty.");
    //   isValid = false;
    // }

    // if (
    //   !prescriptionData.procedure ||
    //   prescriptionData.procedure.length === 0
    // ) {
    //   toast.error("Procedure cannot be empty.");
    //   isValid = false;
    // }

    if (!isValid) {
      return false;
    }
    return true;
  };

  const handleClickShowPrescription = () => {
    const isValid = validatePescriptionData();
    if (isValid) {
      setShowPrescription(!showPrescription);
    }
  };
  return (
    <>
      <div className="meetRightHeader">
        <HtmlTooltip
          title={
            <>
              <div className="centerDetail">
                <div className="centerName">Consultation With Zoyel HO</div>
                <div className="centrDtlInd">
                  <span className="hedingCCD">Technician :</span>
                  <span className="ccInfoData">
                    {props.extraData && props.extraData.queuePatientName}
                  </span>
                </div>
                <div className="centrDtlInd">
                  <span className="hedingCCD">Contact :</span>
                  <span className="ccInfoData">+917384979442</span>
                </div>
                <div className="centrDtlInd">
                  <span className="hedingCCD">Location :</span>
                  <span className="ccInfoData">
                    1104, The Galleria, Miniontech, New Town, Kolkata, 700156,
                    IND.
                  </span>
                </div>
              </div>
            </>
          }
        >
          <div className="metRtTitle">
            Consultation With Zoyel HO Center - (Kolkata)
          </div>
        </HtmlTooltip>

        <div className="metRtAction">
          <IconButton aria-label="openInNewTab">
            <OpenInNew />
          </IconButton>
          <IconButton
            aria-label="Close"
            onClick={() => props.handleMenuCloseButton()}
          >
            <XLg />
          </IconButton>
        </div>
      </div>
      {showPrescription ? (
        <PrescriptionEdit
          updateData={updateData}
          data={prescriptionData}
          extraData={props.extraData}
        />
      ) : (
        <PrescriptionView data={prescriptionData} extraData={props.extraData} />
      )}
      {/* <PrescriptionEdit />
      <PrescriptionView /> */}

      <div className="MeetRightFooter">
        <div className="meetRightFootBtnGrp">
          <Button
            className="dfultPrimaryBtn"
            startIcon={showPrescription ? <Eye /> : <Edit />}
            onClick={handleClickShowPrescription}
          >
            Prescription
          </Button>
          <Button
            className="dfultDarkSecondaryBtn"
            onClick={() => props.handleMenuCloseButton()}
          >
            Close
          </Button>
        </div>
      </div>
    </>
  );
}
