import React, { useEffect, useRef, useState } from "react";
import "../../../css/task.css";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import { CheckCircle, ListAlt, Send, VideoCall } from "@mui/icons-material";
import { ArrowLeft, People, Play, Plus, XLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormattedDate,
  getLoginUserDetails,
} from "../../../../../utils/Utils";
import DashboardServices from "../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import {
  clearTaskModalData,
  moveToPreviousTask,
  pushLinkTask,
  setTaskModalData,
} from "../../../../../redux/reducers/taskTab";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import TemplateList from "../TemplateList";
import ForwardTaskModal from "../ForwardTaskModal";
import FileList from "../FileList";
import LinkTaskModal from "../LinkTaskModal";
import AssigneeModal from "../AssigneeModal";
import SignOff from "../SignOff";
import DisagreeTask from "../DisagreeTask";
import RightFlotingContainer from "../RightFlotingContainer";
import RightTaskFileUpload from "../RightTaskFileUpload";
import { LinkedTaskModule } from "../LinkedTaskModule";
import MeetingModal from "../Modals/MeetingModal";
import { useContext } from "react";
import { taskTabContext } from "../../TaskTabContainer";
import TaskService from "../../../../../services/TaskService";
import { useTimeProgressor } from "../hooks/useTimerProgressor";
import { clearSelectedTask } from "../../../../../redux/reducers/rightSideTaskPanelSlice";
import { IncomingFormPreview } from "../Forms/IncomingFormPreview";
import { PurchaseFormRenderer } from "../Forms/PurchaseFormRenderer";
export default function TaskTabCompleted({
  data,
  refreshData,
  removeFromStore,
}) {
  // const { setShowConvenientMeetingView, setSelectedUsers } =
  //   useContext(taskTabContext);

  const context = useContext(taskTabContext);
  const { setShowConvenientMeetingView } = context;
  const convinientSelecteduser = context.setSelectedUsers;

  const [showFileModal, setShowFileUploadModal] = useState(false);
  const scrollRef = useRef();

  const dispatch = useDispatch();
  const loginUserDetail = getLoginUserDetails();

  const progress = useRef(0);

  const dateRemaning = useTimeProgressor(data.createdAt, data.completionTime);

  const elementRef = useRef(null);

  const [showAddressConcern, setShowAddressConcern] = useState(false);
  const [addressConcernRemark, setAddressConcernRemark] = useState("");

  const [assigneeList, setAssigneeList] = useState([]);
  const [userList, setUserList] = useState([]);

  const [showForwardTask, setShowForwardTask] = useState(false);

  const [showCreateLinkTask, setShowCreateLinkTask] = useState(false);

  const [tempOfOrgList, setTemplateOfOrgList] = useState([]);
  const [addedTemplateList, setAddedTemplateList] = useState([]);
  const [recommendedTemplateList, setRecommendedTemplateList] = useState([]);
  const [showAddTemplate, setShowAddTemplate] = useState(false);

  const [anchorUl, setAnchorUl] = React.useState(null);
  const openUl = Boolean(anchorUl);
  const ulId = openUl ? "simple-popover" : undefined;
  const fileDocuments = useRef(null);
  const commentInputRef = useRef();

  const [showDisagreeModal, setShowDisAgreeModal] = useState(false);

  const [showAssigneeList, setShowAssigneeList] = useState(false);

  const [showAudioModal, setShowAudioModal] = useState(false);
  const audioUrl = useRef();

  const links = useSelector((state) => state.taskList.taskLinks);
  const setMeetingList = useRef();

  const gotoPreviousTAsk = () => {
    dispatch(moveToPreviousTask());
  };

  console.log("incoming completed");

  const handleCloseUl = () => {
    setAnchorUl(null);
  };

  const [formData, setFormData] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const [showMeetingModal, setShowMeetingsModal] = useState(false);

  const handleLinkTaskClick = async (task) => {
    // console.log("task id =", taskId);
    const values = await DashboardServices.getTaskById(task.taskId);
    dispatch(pushLinkTask(data));
    dispatch(setTaskModalData({ ...task, ...values.data }));
  };

  const handleAddTemplate = async () => {
    const value = await DashboardServices.getAllTemplatesOfOrganization(
      loginUserDetail.orgId
    );
    setTemplateOfOrgList(value.data);

    const response = await DashboardServices.getTemplatesOfSubActivity(
      data.activityId,
      0
    );
    let notaddedTemplateList = [];
    if (response.data != null && response.data.length > 0) {
      response.data.forEach((template) => {
        let isTemplateAdded = false;
        addedTemplateList.forEach((addedTemplate) => {
          if (template.templateId === addedTemplate.templateId) {
            isTemplateAdded = true;
          }
        });
        if (!isTemplateAdded) {
          notaddedTemplateList.push(template);
        }
      });
      setRecommendedTemplateList(notaddedTemplateList);
    }
    console.log("hello");
    setShowAddTemplate(true);
  };

  // const clearAllModals = () => {
  //   setShowAssigneeList(false);
  // };

  const handleClickFormButton = () => {
    fetchExpenseApprovalRequest();
  };

  const sendComment = (e) => {
    if (!data) {
      toast.error("something went wrong ");
      return;
    }
    const comment = commentInputRef.current.value;
    const reqDto = {
      taskDetailId: data.taskId,
      progress: data.progress,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };
    console.log("reqDto = ", reqDto);
    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      DashboardServices.sendTaskComments(loginUserDetail.userId, reqDto).then(
        (response) => {
          console.log("out response data= ", response);
          if (response.data == 1) {
            const tempData = { ...data };
            tempData.comments = [
              ...tempData.comments,
              {
                comments: comment,
                commentsType: null,
                commentsByUserName: loginUserDetail.userName,
                commentsByUserProfileUrl: loginUserDetail.userProfileImage,
                progress: data.progress,
                commentsAt: commentsAt,
              },
            ];
            dispatch(setTaskModalData(tempData));
            commentInputRef.current.value = "";

            const timeoutValue = setTimeout(() => {
              scrollRef.current.scrollIntoView({ behavior: "smooth" });
              clearTimeout(timeoutValue);
            }, 500);
          } else {
            toast.error("something went wrong");
          }
        }
      );
    }
  };

  const fetchExpenseApprovalRequest = () => {
    // TaskService.getIndentItemsByIndentId(data.formDataTransId).then(
    //   (response) => {
    //     if (response.data) {
    //       setFormData(response.data);
    //       setShowForm(true);
    //     }
    //   }
    // );

    TaskService.getExpenseApprovalRequest(data.formDataTransId).then(
      (response) => {
        if (response.data) {
          setFormData(response.data);
          setShowForm(true);
        }
      }
    );
  };

  const handleChangeFile = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
    }));

    console.log("reqDto = ", reqDto);

    DashboardServices.uploadDocuments(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      console.log("document sent is ", response.data);
      if (response.data) {
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setTaskModalData(tempData));
      }
    });
    console.log("file document = ", fileDocuments.current);
  };

  useEffect((e) => {
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );
  }, []);

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp">
              <div className="taskVDContBtn">
                <Button
                  disabled
                  variant="contained"
                  startIcon={<CheckCircle />}
                >
                  Completed
                </Button>
              </div>
            </div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn">
                <IconButton
                  aria-label="Meeting"
                  onClick={(e) => {
                    TaskService.getEventsofTask(data.taskId).then(
                      (response) => {
                        console.log("event task =", response.data);
                        setMeetingList.current = response.data;
                        if (response.data && response.data.length > 0) {
                          setShowMeetingsModal(true);
                        } else {
                          setShowConvenientMeetingView(true);
                          convinientSelecteduser(assigneeList);
                        }
                      }
                    );
                  }}
                >
                  <Tooltip title="Meeting" arrow>
                    <VideoCall />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="taskActBtn">
                <IconButton
                  aria-label="User"
                  onClick={(e) => {
                    // setOpenUserList(true);
                    // handleClickUl(e);
                    setShowAssigneeList(true);
                  }}
                >
                  <Tooltip title="User" arrow>
                    <People />
                  </Tooltip>
                </IconButton>
                <Popover
                  id={ulId}
                  open={openUl}
                  anchorEl={anchorUl}
                  onClose={handleCloseUl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ul>
                    {assigneeList &&
                      assigneeList.map((assignee) => <li>{assignee.name}</li>)}
                  </ul>
                  {/* <Typography sx={{ p: 2 }}>
                    The content of the Popover.
                  </Typography> */}
                </Popover>
              </div>

              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    setShowConvenientMeetingView(false);
                    convinientSelecteduser([]);
                    dispatch(clearTaskModalData());
                  }}
                >
                  <Tooltip title="Close" arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {links && links.length > 0 && (
                  <div className="linktaskBreadCrumb">
                    <IconButton onClick={gotoPreviousTAsk}>
                      <ArrowLeft />
                    </IconButton>
                    <div className="prentTaskNM">
                      {links &&
                        links.length > 0 &&
                        links[links.length - 1].taskName}
                    </div>
                  </div>
                )}
                <div className="taskTitleContrct">
                  {/* <div className="taskID">PT0023</div> */}
                  <div className="taskTitleNM">{data && data.taskName}</div>
                </div>
                <div className="taskElementGrp">
                  <div className="tskElemHeding">Description</div>
                  <div className="tskElemntInfo">
                    {data && data.taskDescription}
                  </div>
                </div>

                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <div className="tskElemntInfo">
                      <div className="playAudioNotes">
                        <IconButton
                          variant="outlined"
                          onClick={(e) => {
                            DashboardServices.generatePreSignedUrlS3Object(
                              data.audioNoteFileName,
                              loginUserDetail.orgId
                            ).then((response) => {
                              audioUrl.current = response.data;
                              setShowAudioModal(true);
                            });
                          }}
                        >
                          <Play />
                        </IconButton>
                        <span>Play Audio Message</span>
                      </div>
                    </div>
                  </div>
                )}

                {data &&
                  data.contractName &&
                  data.contractName.trim() !== "" && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">Contract</div>
                      <div className="taskContractNm">
                        {data && data.contractName}
                      </div>
                    </div>
                  )}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Timeline</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // up50 up80 up100
                          className={`progress-done ${
                            data && dateRemaning < 50
                              ? "up50"
                              : dateRemaning < 80
                              ? "up80"
                              : "up100"
                          }`}
                          style={{ width: `${dateRemaning}%`, opacity: 1 }}
                        ></div>
                      </div>
                    </div>
                    <div className="tskDtSE">
                      <div className="tskDTStart">
                        {getFormattedDate(data.createdAt)}
                      </div>
                      <div className="tskDTEnd">
                        {getFormattedDate(data.completionTime)}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Templates start */}

                <TemplateList
                  data={data}
                  // tempDivWidth={tempDivWidth}
                  handleAddTemplate={handleAddTemplate}
                />

                {/* Templates end */}

                {/* files start */}

                <FileList
                  data={data}
                  handleChangeFile={handleChangeFile}
                  setShowFileUploadModal={setShowFileUploadModal}
                />

                {/* files end */}

                {data &&
                  data.formDataTransId &&
                  data.formDataTransId !== "" &&
                  data.formDataTransId !== "0" && (
                    <div className="taskElementGrp">
                      <Tooltip title="Form Data">
                        <IconButton
                          className="elemntMrFileBtn"
                          variant="outlined"
                          onClick={handleClickFormButton}
                        >
                          <ListAlt />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Priority</div>
                  <div className="tskPrioArea">
                    {/* statUrgent, statNormal, statImportant  used these three for diffrent priority */}
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class="priStat">
                        {data.priority === 0
                          ? "Regular"
                          : data.priority === 1
                          ? "Important"
                          : "Critical"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Activity</div>
                    <div className="tskElemntInfo">
                      {data && data.activityName}
                    </div>
                  </div>
                </div>

                {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Assigned to</div>
                    <div className="tskElemntInfo">
                    {data && data.assignByUserName}
                    </div>
                  </div> */}
                {data.acceptRejectFlag === "A" &&
                  data.approveRejectConcernedPostponedByUserName &&
                  data.approveRejectConcernedPostponedByUserName.trim() !==
                    "" && (
                    <div className="taskElementsGrpWithTwo">
                      <div className="taskElementGrp">
                        <div className="tskElemHeding">Approved By</div>
                        <div className="tskElemntInfo">
                          {data &&
                            data.approveRejectConcernedPostponedByUserName}
                        </div>
                      </div>
                    </div>
                  )}

                <div className="taskElementsGrpWithTwo">
                  {data.approvalRmks && data.approvalRmks.trim() !== "" && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">Approval Remark</div>
                      <div className="tskElemntInfo">
                        {data && data.approvalRmks}
                      </div>
                    </div>
                  )}
                </div>

                <LinkedTaskModule
                  data={data}
                  handleLinkTaskClick={(data) => handleLinkTaskClick(data)}
                />

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Last updated</div>
                  <div className="tskElemntInfo">
                    {data &&
                      data.comments &&
                      data.comments.length &&
                      `${
                        data.comments[data.comments.length - 1]
                          .commentsByUserName
                      } - ${getFormattedDate(
                        data.comments[data.comments.length - 1].commentsAt
                      )}`}
                  </div>
                </div>

                {/* <div className="taskElementGrp">
                  <div className="tskElemHeding">Description</div>
                  <div className="tskElemntInfo">
                    Installing Adobe Creative Suite on my machine is crucial for
                    maximizing efficiency and ensuring seamless collaboration
                    within our creative projects.
                  </div>
                </div> */}

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>
                <div ref={scrollRef}></div>
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="tskInputGrp">
              <input
                ref={commentInputRef}
                type="text"
                className="tskComntInput"
                placeholder="Update comment here"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    console.log("Enter key pressed");
                    sendComment(event);
                  }
                }}
              />
              <IconButton className="tskComtSndBtn" onClick={sendComment}>
                <Send />
              </IconButton>
            </div>
          </div>
        </div>
        {showAddressConcern && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Address Concern</div>

              <div className="modInputFild">
                <TextField
                  id="outlined-basic"
                  label="Concern Remark"
                  variant="outlined"
                  value={addressConcernRemark}
                  onChange={(e) => setAddressConcernRemark(e.target.value)}
                />
              </div>

              {/* <div className="formElement">
                <FormControl className="formControl">
                  <Autocomplete
                    id="adduser-select"
                    className="formAutocompleteField"
                    disableClearable
                    variant="outlined"
                    options={getFilteredData()}
                    getOptionLabel={(option) => option.userName}
                    getOptionSelected={(option) => {
                      console.log("option is ", option);
                      return option.userId;
                    }}
                    value={selectedUsers}
                    onChange={(e, newValue) => {
                      console.log("new value = ", newValue);
                      setSelectedUsers(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="formAutoComInputField"
                        placeholder="Search User"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div> */}

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    if (addressConcernRemark.trim() === "") {
                      toast.error("Please provide your concern response");
                      return;
                    }

                    // const reqDto = {
                    //   comments: addressConcernRemark,
                    //   taskDetailId: data.taskId,
                    //   // progress: data.progress,
                    //   orgId: loginUserDetail.orgId,
                    //   previousAssigness:
                    //     assigneeList && assigneeList.length > 0
                    //       ? assigneeList.map((item) => item.id)
                    //       : [],
                    //   currentAssigness:
                    //     selectedUsers.length > 0
                    //       ? selectedUsers
                    //       : assigneeList.map((item) => item.id),
                    // };

                    DashboardServices.addressConcernOfTask(
                      data.taskId,
                      loginUserDetail.userId,
                      addressConcernRemark
                    ).then((response) => {
                      if (response.data && response.data.returnValue === "1") {
                        toast.success(response.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                        setShowAddressConcern(false);
                        dispatch(clearTaskModalData());
                      } else {
                        toast.error(response.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      }
                      setAddressConcernRemark("");
                      refreshData();
                      // setSelectedUsers([]);
                    });
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowAddressConcern(false);
                    setAddressConcernRemark("");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        )}

        {/* {showForwardTask && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Forward Task</div>
              <div
                className="modInputFild"
                style={{ border: "1px solid black" }}
              >
                <TextField
                  sx={{ color: "balck !important" }}
                  label="Forward Remark"
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={forwardReason}
                  onChange={(e) => setForwardReason(e.target.value)}
                />
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <Autocomplete
                    id="adduser-select"
                    disableClearable
                    className="formAutocompleteField"
                    variant="outlined"
                    options={getFilteredUser()}
                    getOptionLabel={(option) => option.userName}
                    getOptionSelected={(option) => {
                      console.log("option is ", option);
                      return option.userId;
                    }}
                    onChange={(e, newValue) => {
                      console.log("new value = ", newValue);
                      setSelectedUsers(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="formAutoComInputField"
                        placeholder="Search User"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    handleSubmitTransferTask();
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowForwardTask(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        )} */}
        {showForwardTask && (
          <ForwardTaskModal
            data={data}
            userList={userList}
            refreshData={refreshData}
            handleClose={() => {
              setShowForwardTask(false);
            }}
          />
        )}

        {showCreateLinkTask && (
          <LinkTaskModal
            userList={userList}
            data={data}
            handleClose={(e) => setShowCreateLinkTask(false)}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}

        {showDisagreeModal && (
          <DisagreeTask
            assignByUserId={data.assignByUserId}
            userList={userList}
            onClose={() => {
              setShowDisAgreeModal(false);
            }}
            refreshData={refreshData}
            taskDetailId={data.taskId}
          />
        )}
        {showAssigneeList && (
          <AssigneeModal
            assigneeList={assigneeList}
            handleClose={(e) => setShowAssigneeList(false)}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Audio Message</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  Ok
                </Button>
                {/* <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  Ok
                </Button> */}
              </div>
            </div>
          </Box>
        )}
      </div>
      {showMeetingModal && (
        <MeetingModal
          handleClose={(e) => setShowMeetingsModal(false)}
          assigneeList={assigneeList}
          taskId={data.taskId}
        />
      )}

      {showAddTemplate && (
        <RightFlotingContainer
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={() => {
            setShowAddTemplate(false);
            if (addedTemplateList && addedTemplateList.length > 0) {
              const temp = [...addedTemplateList];
              temp.forEach((template) => {
                template.taskDetailId = data.taskId;
              });
              DashboardServices.addTaskTemplate(
                loginUserDetail.userId,
                addedTemplateList,
                "OLD"
              ).then((response) => {
                console.log("addTaskTemplate", response.data);
                const tempData = { ...data };
                if (tempData.templates) {
                  tempData.templates = [
                    ...tempData.templates,
                    ...addedTemplateList,
                  ];
                } else {
                  tempData.templates = [...addedTemplateList];
                }
                dispatch(setTaskModalData(tempData));
              });
            }
          }}
          mode={"old"}
        />
      )}

      {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={(e) => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )}

      {showForm && data.taskNature === "EXPENSE_APPROVAL_REQUEST" && (
        <IncomingFormPreview
          taskId={data.taskId}
          taskNature={data.taskNature}
          taskCreatedBy={data.assignByUserId}
          formData={formData}
          handleClose={() => {
            setShowForm(false);
          }}
          handleSubmitValue={() => {
            setShowForm();
            dispatch(clearSelectedTask());
            refreshData();
          }}
        />
      )}
      {showForm && data.taskNature === "PO_APPROVAL_REQUEST" && (
        <PurchaseFormRenderer
          data={data}
          taskId={data.taskId}
          taskNature={data.taskNature}
          formData={formData}
          handleClose={() => {
            setShowForm(false);
          }}
          handleSubmitPO={(date) => {
            if (date) {
              // let reqDto = {
              //   taskDetailId: data.taskId,
              //   orgId: loginUserDetail.orgId,
              //   documents: [],

              //   taskCreator: "N",
              //   taskForwordToUserIdAfterApproval: "",
              //   additionalInfo: {
              //     "Effective Date": date,
              //   },
              //   progress: data.progress,
              //   concern: data.concern,
              //   comments: data.comments,
              //   submitButtonLabel: data.submitButtonLabel,
              //   partialSubmitChecked: data.progress === 100 ? "N" : "Y",
              //   previousAssigness: [],
              //   currentAssigness: [],
              // };

              const reqDto = {
                taskDetailId: data.taskId,
                taskForwordToUserIdAfterApproval: "",
                approvalRmks: "Approving Purchase Request",
              };

              console.log("reqdto =", reqDto);
              // return;
              DashboardServices.approveTask(
                loginUserDetail.userId,
                reqDto
              ).then((response) => {
                // handleTaskSubmission(response);
                if (response && response.data) {
                  toast.success(response.data.message);
                  dispatch(clearSelectedTask());
                  refreshData();
                } else {
                  toast.error(response.data.message);
                }
              });
            }
          }}
        />
      )}
    </>
  );
}
