import React, { useRef } from "react";
import { Apple, GooglePlay } from "react-bootstrap-icons";
import appDnCTA from "../../images/appDownloadCTA.png";
// import featursCTA from "../../images/featursImg.jpg";
import featursCTATask from "../../images/HomeTabTask.png";
import featursCTASchedular from "../../images/SchedulerWeek.png";
import TaskZoom from "../../images/taskZoom.png";
import SchedularZoom from "../../images/ShedularZoom.png";
import "../../css/features.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CalendarMonth } from "@mui/icons-material";
import { useState } from "react";

// clients logo
import cl1 from "../../images/clientLogo/1.jpg";
import cl2 from "../../images/clientLogo/2.jpg";
import cl3 from "../../images/clientLogo/3.jpg";
import cl4 from "../../images/clientLogo/4.jpg";
import cl5 from "../../images/clientLogo/5.jpg";
import cl6 from "../../images/clientLogo/6.jpg";
import cl7 from "../../images/clientLogo/7.jpg";
import cl8 from "../../images/clientLogo/8.jpg";
import cl9 from "../../images/clientLogo/9.jpg";
import cl10 from "../../images/clientLogo/10.jpg";

export default function HomePage({ clientRef }) {
  const latestNewsSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    setScrollPosition(scrollTop);
  };

  return (
    <>
      <section className="heroSectionLP">
        <div className="overlayScreen"></div>
        <video autoPlay loop muted>
          <source
            src="https://videos.pexels.com/video-files/5788981/5788981-hd_1920_1080_25fps.mp4"
            type="video/mp4"
          />
        </video>
      </section>

      <section className="showFuntionSec">
        <div className="funtionDescribeText">
          <div className="fdtContainer">
            <div className="fdtTitle">
              {scrollPosition < 90
                ? "Use Tasks to manage your organisation"
                : "Multiple calendars in a single view"}
            </div>
            <div className="fdtSubtitle">
              {scrollPosition < 90
                ? "Register today and move to a more collaborative and efficient environment."
                : "You can import and manage your other calendars from a single interface"}
            </div>
          </div>

          <svg
            width="250"
            height="200"
            viewBox="0 0 250 200"
            xmlns="http://www.w3.org/2000/svg"
            className="connectWithZoom"
            style={
              scrollPosition < 90
                ? {
                    position: "absolute",
                    right: "-119px",
                    top: "34%",
                    transform: "rotate(-9deg)",
                    transition: "all .6s",
                  }
                : {
                    position: "absolute",
                    right: "-119px",
                    top: "38%",
                    transform: "rotate(9deg)",
                    transition: "all .6s",
                  }
            }
          >
            <circle cx="5" cy="100" r="5" fill="#fff"></circle>
            <path
              d="M5,100 Q135,160 245,100"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            ></path>
            <circle cx="245" cy="100" r="5" fill="#fff"></circle>
          </svg>

          <svg
            className="zoomFeatures"
            width="200"
            height="200"
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
            style={
              scrollPosition < 90
                ? {
                    position: "absolute",
                    right: "-287px",
                    zIndex: "1",
                    top: "25%",
                    transition: "all .6s",
                  }
                : {
                    position: "absolute",
                    right: "-291px",
                    zIndex: "1",
                    top: "34%",
                    transition: "all .6s",
                  }
            }
          >
            {/* Outer circle */}
            <circle
              cx="100"
              cy="100"
              r="90"
              fill="none"
              stroke="#ffffff"
              strokeWidth="4"
            />

            {/* Inner circle with image and stroke */}
            <clipPath id="circleClip">
              <circle cx="100" cy="100" r="90" />
            </clipPath>
            {/* Image with stroke */}
            <g clipPath="url(#circleClip)">
              <circle
                cx="100"
                cy="100"
                r="90"
                fill="none"
                stroke="#ffffff"
                strokeWidth="2"
              />
              <image
                x="10"
                y="10"
                width="180"
                height="180"
                xlinkHref={scrollPosition < 90 ? TaskZoom : SchedularZoom}
              />
            </g>
          </svg>
        </div>

        <div className="showFuntionContainer">
          <div className="device-mockup imac mb-small">
            <div className="device">
              <div
                className="screen"
                style={{ overflowY: "auto", pointerEvents: "all" }}
                onScroll={handleScroll}
              >
                <img
                  src={
                    scrollPosition < 90 ? featursCTATask : featursCTASchedular
                  }
                  alt="featuresImg"
                />
                <img
                  src={
                    scrollPosition >= 90 ? featursCTASchedular : featursCTATask
                  }
                  alt="featuresImg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="appDownloadSec">
        <div className="leftArea">
          <div className="appDownloadInfo">
            <div className="adnTitle">
              Join the Zoyel Network and <span>streamline</span> your business
            </div>
            <div className="adnText">
              In today's fast-paced and competitive business environment,
              efficiency and innovation are crucial for success. The Zoyel
              Network offers a comprehensive solution designed to help your
              business thrive. By joining the Zoyel Network, you can streamline
              your operations, enhance productivity, and unlock new growth
              opportunities.
            </div>
            <div className="adnLinkGrp">
              <a href="https://zoyel.one/" target="blank">
                <div className="appStore appleStore">
                  <div className="appstoreIcon">
                    <Apple />
                  </div>
                  <div className="appstoreText">
                    <div className="subHeadTxt">APP COMING SOON</div>
                    {/* <div className="headTxt">App Store</div> */}
                  </div>
                </div>
              </a>
              <a href="https://zoyel.one/" target="blank">
                <div className="appStore googleStore">
                  <div className="appstoreIcon">
                    <GooglePlay />
                  </div>
                  <div className="appstoreText">
                    <div className="subHeadTxt">App coming soon</div>
                    {/* <div className="headTxt">Google Play</div> */}
                  </div>
                </div>
              </a>
            </div>
            {/* <div className="adnInfo">
              <div className="adnInfoElement">
                <span className="highlighted counter">1 M</span>
                <span className="elmtxt">Downloads</span>
              </div>
              <div className="adnInfoElement">
                <span className="highlighted counter">520K</span>
                <span className="elmtxt">Active Users</span>
              </div>
              <div className="adnInfoElement">
                <span className="highlighted counter">4.6</span>
                <span className="elmtxt">34K Ratings</span>
              </div>
            </div> */}
          </div>
        </div>
        <div className="rightArea">
          <div className="adnImgPresent">
            <img src={appDnCTA} alt="appDownloadCTA" />
          </div>
        </div>
      </section>

      <section className="latestNewsSec">
        <div className="latestNewsSecContainer mapBg">
          <div className="sectionTitle">
            Latest <span>News</span>
          </div>

          <div className="lnSliderContainer">
            <Slider {...latestNewsSettings}>
              <div>
                <div className="sliderElement">
                  <div className="slidNewsImg">
                    <img
                      src="https://images.unsplash.com/photo-1665686308827-eb62e4f6604d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Zoyel-featured"
                    />
                  </div>
                  <div className="slidTitle">Zoyel featured in SecCon 2024</div>
                  <div className="slidTxt">
                    Zoyel proudly took center stage at SecCon 2024, the premier
                    conference for security and technology professionals. This
                    year, Zoyel showcased its latest advancements in
                    cybersecurity and business solutions, drawing significant
                    attention from industry leaders and experts.
                  </div>
                  <div className="slidPublishDT">
                    <CalendarMonth />
                    <span>12th February 2024</span>
                  </div>
                </div>
              </div>

              <div>
                <div className="sliderElement">
                  <div className="slidNewsImg">
                    <img
                      src="https://images.unsplash.com/photo-1483058712412-4245e9b90334?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Zoyel-featured"
                    />
                  </div>
                  <div className="slidTitle">
                    How Tasks save time and make teams effective
                  </div>
                  <div className="slidTxt">
                    Zoyel One is a powerful task management and collaboration
                    platform designed to streamline operations and enhance team
                    productivity. Here’s how using Zoyel One can save time and
                    make your teams more effective:
                  </div>
                  <div className="slidPublishDT">
                    <CalendarMonth />
                    <span>6th February 2024</span>
                  </div>
                </div>
              </div>

              <div>
                <div className="sliderElement">
                  <div className="slidNewsImg">
                    <img
                      src="https://images.unsplash.com/photo-1594122230689-45899d9e6f69?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Zoyel-featured"
                    />
                  </div>
                  <div className="slidTitle">
                    Zoyel One: The All-in-One Business Solution
                  </div>
                  <div className="slidTxt">
                    Zoyel One is revolutionizing the way businesses operate by
                    offering a comprehensive, all-in-one solution that caters to
                    every aspect of modern business management. Here’s why Zoyel
                    One stands out as the ultimate choice for businesses seeking
                    efficiency, growth, and innovation:
                  </div>
                  <div className="slidPublishDT">
                    <CalendarMonth />
                    <span>28th January 2024</span>
                  </div>
                </div>
              </div>

              <div>
                <div className="sliderElement">
                  <div className="slidNewsImg">
                    <img
                      src="https://images.unsplash.com/photo-1651341050677-24dba59ce0fd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Zoyel-featured"
                    />
                  </div>
                  <div className="slidTitle">
                    Zoyel One set for record growth in 2024
                  </div>
                  <div className="slidTxt">
                    Zoyel One is rapidly expanding its presence in new and
                    existing markets. The platform's versatility and
                    adaptability make it an attractive choice for businesses of
                    all sizes, from startups to large enterprises, across
                    various industries.
                  </div>
                  <div className="slidPublishDT">
                    <CalendarMonth />
                    <span>21th January 2024</span>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      {/* 
      <section
        className="clientSec"
        ref={clientRef}
        style={{ display: "none !important" }}
      >
        <div className="ourClientContainer">
          <div className="sectionTitle">
            Our <span>Clients</span>
          </div>

          <div className="cleintsDtlsWrap">
            <div className="clientInfo">
              <div className="ciElemnt">
                <div className="cliNumber">105</div>
                <div className="cliText">Clients</div>
              </div>
              <div className="ciElemnt">
                <div className="cliNumber">21</div>
                <div className="cliText">Countries</div>
              </div>
            </div>
            <div className="clientsLogos">
              <div class="hexagon-gallery">
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl1} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl2} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl3} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl4} alt="some" />
                  </div>
                </div>
                <div class="hex hex-hide">
                  <div className="cleintLogo">
                    <img src={cl5} alt="some" />
                  </div>
                </div>
                <div class="hex hex-noImg">
                  <div className="cleintLogo">
                    <img src={cl6} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl7} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl8} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl9} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl10} alt="some" />
                  </div>
                </div>
                <div class="hex hex-hide">
                  <div className="cleintLogo">
                    <img src={cl2} alt="some" />
                  </div>
                </div>
                <div class="hex hex-noImg">
                  <div className="cleintLogo">
                    <img src={cl3} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl4} alt="some" />
                  </div>
                </div>
                <div class="hex hex-hide">
                  <div className="cleintLogo">
                    <img src={cl5} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl6} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl7} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl8} alt="some" />
                  </div>
                </div>
                <div class="hex">
                  <div className="cleintLogo">
                    <img src={cl9} alt="some" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
