import React, { useEffect, useRef, useState } from "react";
import { AddQuotation } from "./AddQuotation";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { Bank, Check, CloudUpload, Trash, XLg } from "react-bootstrap-icons";
import TaskService from "../../../../../services/TaskService";
import { VendorPaymentMethods } from "./VendorPaymentMethods";
import {
  Add,
  Cancel,
  CancelOutlined,
  Description,
  Edit,
  Gavel,
} from "@mui/icons-material";
import { CustomModal } from "../Modals/CustomModal";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { getStaticDDOfOrg } from "../../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import {
  MAX_FILE_SIZE_ADD_VENDOR_QUOTATION,
  VALID_FILE_EXTENSION_ADD_VENDOR_QUOTATION,
} from "../../../../../constants/const_values";
import dayjs from "dayjs";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import ReactDatePicker from "react-datepicker";

export const QuotationContainer = ({
  itemList,
  setItemList,
  rfpVendorId,
  vendorUserId,
  handleSubmitData,
  expenseId,
  closeModal,
}) => {
  const { register, handleSubmit, control, reset, setValue } = useForm();
  const loginUserDetail = getLoginUserDetails();
  const [refreshCount, setRefreshCount] = useState(1);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [selectedMethodId, setSelectedMethodId] = useState(0);
  const [addQuotationFile, setAddQuotationFile] = useState({
    fileName: "",
    fileData: "",
  });

  const [vendorTAC, setVendorTAC] = useState([]);

  const [termsAndConditionList, setTermsAndConditionList] = useState([]);

  const [addTaxDetails, setAddTaxDetails] = useState({
    sgstPc: 0,
    cgstPc: 0,
    igstPc: 0,
    sgstAmt: 0,
    cgstAmt: 0,
    igstAmt: 0,
    taxPc: 0,
    taxAmt: 0,
  });
  const [showAddTaxDialog, setShowAddTaxDialog] = useState(false);
  const [taxRefreshCount, setTaxRefreshCount] = useState(1);

  const [selectedItem, setSelectedItem] = useState(undefined);
  const [showAddVarianceDialog, setShowAddVarianceDialog] = useState(false);
  const [otherCharges, setOtherCharges] = useState([]);
  const [showAddOtherCharges, setShowAddOtherCharges] = useState(false);
  const [otherChargesDescription, setOtherChargesDescription] = useState("");
  const [otherChargesValue, setOtherChargesValue] = useState(0);
  const [veriance, setVeriance] = useState("");
  const [verianceSpecification, setVerianceSpecification] = useState("");
  const [showBankDetails, setShowBankDetails] = useState(false);
  const inputFileRef = useRef(null);
  const [showTermsAndCondDialog, setShowTermsAndCondDialog] = useState(false);
  const [isAcceptedTermsAndCond, setIsAcceptedTermsAndCond] = useState(false);
  const [advancedChecked, setAdvanceChecked] = useState(false);
  const [advanceAmount, setAdvanceAmount] = useState("");
  const [rejectionIndex, setRejectionIndex] = useState(null);
  const [showAddRejectionCauseModal, setShowAddRejectionCauseModal] =
    useState(false);
  const [showAddTAC, setShowAddTAC] = useState(false);
  const [deleteTACData, setDeleteTACData] = useState(undefined);
  const [
    showDeletionConfirmationDialogue,
    setShowDeletionConfirmationDialogue,
  ] = useState(false);
  const [hasSeenTAC, setHasSeenTAC] = useState(false);
  const [typeList, setTypeList] = useState([]);

  const [editTAC, setEditTAC] = useState(false);
  const [editTACData, setEditTACData] = useState(undefined);
  const [submittingQuotation, setSubmittingQuotation] = useState(false);

  const calculateQuotationValue = (item) => {
    let value = 0;
    value = item.quotationQty * item.quotationRate + item.taxAmt;
    item.quotationValue = value;
    return value ? value : 0;
  };

  const calculateTotal = (qotationList, otherChargesList) => {
    let val = 0;
    val = val + calculateSubTotal(qotationList);
    otherChargesList.forEach((item) => {
      val = val + parseFloat(item.chargesAmount);
    });
    return val;
  };

  useEffect(() => {
    getStaticDDOfOrg(loginUserDetail.orgId, "TERMS_CONDITIONS_TYPE").then(
      (response) => {
        if (response) {
          setTypeList(response.data);
        } else {
          toast.error(
            "Did not found Terms And Conditions Types for This Organization"
          );
        }
      }
    );
  }, []);

  const handleSubmitAddCondition = (data) => {
    if (data.slNo <= 0) {
      toast.error(`Please Enter SlNo.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (data.termsConditionsType === "") {
      toast.error(`Please Enter Terms & Conditions Type.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (data.heading === "") {
      toast.error(`Please Enter Terms & Conditions Heading.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (data.condition === "") {
      toast.error(`Please Enter Terms & Conditions Body.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (editTAC === true) {
        let index = -1;
        for (let i = 0; i < vendorTAC.length; i++) {
          if (
            vendorTAC[i].slNo === editTACData.slNo &&
            vendorTAC[i].termsConditions === editTACData.termsConditions &&
            vendorTAC[i].termsConditionsHeading ===
              editTACData.termsConditionsHeading
          ) {
            index = i;

            break;
          }
        }
        if (index !== -1) {
          let temp = vendorTAC;
          temp[index].slNo = data.slNo;
          temp[index].addedByVendor = "Y";
          temp[index].termsConditionsHeading = data.termsConditionsHeading;
          temp[index].termsConditions = data.termsConditions;

          setVendorTAC([...temp]);
        }
        index = -1;
        for (let i = 0; i < termsAndConditionList.length; i++) {
          if (
            termsAndConditionList[i].slNo === editTACData.slNo &&
            termsAndConditionList[i].termsConditions ===
              editTACData.termsConditions &&
            termsAndConditionList[i].termsConditionsHeading ===
              editTACData.termsConditionsHeading
          ) {
            index = i;

            break;
          }
        }
        if (index !== -1) {
          let temp = termsAndConditionList;
          temp[index].slNo = data.slNo;
          temp[index].addedByVendor = "Y";
          temp[index].termsConditionsHeading = data.termsConditionsHeading;
          temp[index].termsConditions = data.termsConditions;
          setTermsAndConditionList([...temp]);
        }
        setShowAddTAC(false);
        setEditTAC(false);
        setEditTACData(undefined);
        return;
      }

      const reqDto = {
        addedByVendor: "Y",
        slNo: data.slNo,
        termsConditionsHeading: data.heading,
        termsConditions: data.condition,
        termsConditionsType: data.termsConditionsType,
        accepted: "Y",
        id: 0,
        acceptRejectFlag: "Y",
        // transType: "RFP",
      };
      setVendorTAC((prev) => [...prev, reqDto]);

      const tmpTacList = [...termsAndConditionList, ...[reqDto]];
      setTermsAndConditionList(tmpTacList);
      // setOrgTacList(tmpTacList);
      // setSelectedConditionList([...selectedConditionList, ...[reqDto]]);
      // setDefaultSlNo(tmpTacList.length + 1);
      reset({
        slNo: tmpTacList.length + 1,
        termsConditionsHeading: "",
        termsConditions: "",
      });
      // setShowAddDialog(false);
      setShowAddTAC(false);
    }
  };

  const handelSubmitVendorQuotation = () => {
    if (itemList.length === 0) {
      toast.error(`No Item Available To Add`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (selectedMethodId === 0) {
      toast.error(`Please Select Payment Method`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      addQuotationFile.fileName === "" &&
      addQuotationFile.fileData === ""
    ) {
      toast.error(`Please Upload Quotation File`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let itemsQuotation = [];

      for (let i = 0; i < itemList.length; i++) {
        const element = itemList[i];

        if (element.quotationQty <= 0) {
          toast.error(
            `Quotation Quantity  can't be less then or equal 0 on row no.  ${
              i + 1
            }`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          break;
        } else if (element.quotationRate <= 0) {
          toast.error(
            `Quotation rate can't be less then or equal 0 on row no.  ${i + 1}`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          break;
        }
        if (
          advancedChecked &&
          (parseInt(advanceAmount) === 0 || advanceAmount === "")
        ) {
          toast.error("Advance Amount Cannot be Zero or Empty", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        } else {
          itemsQuotation.push({
            indentDtlId: element.expenseDtlId,
            quotationQty: element.quotationQty,
            quotationRate: element.quotationRate,
            quotationValue: element.quotationValue,
            varianceSpeciation: element.varianceSpeciation,
            variance: element.variance,
            sgstPc: element.sgstPc,
            cgstPc: element.cgstPc,
            igstPc: element.igstPc,
            sgstAmt: element.sgstAmt,
            cgstAmt: element.cgstAmt,
            igstAmt: element.igstAmt,
            taxPc: element.taxPc,
            taxAmt: element.taxAmt,
            quotationUnitCost: element.quotationValue / element.quotationQty,
          });
        }
      }

      const termsAndConditions = termsAndConditionList.map((item) => {
        const { accepted, ...restData } = item;
        return { ...restData, acceptRejectFlag: accepted === "Y" ? "Y" : "N" };
      });

      if (itemList.length === itemsQuotation.length) {
        const reqDto = {
          itemsQuotation,
          fileName: addQuotationFile.fileName,
          fileData: addQuotationFile.fileData,
          invRfpVendorId: rfpVendorId,
          orgId: loginUserDetail.orgId,
          paymentMethodId: selectedMethodId,
          advancePaymentRequired: `${advancedChecked ? "Y" : "N"}`,
          advancePaymentPc: advanceAmount,
          vendorTermsConditions: termsAndConditions,
          itemCharges: otherCharges,
          deliveryDate: dayjs(deliveryDate).format("DD-MM-YYYY"),
        };

        // console.log("req Dto=", reqDto);

        // return;

        setSubmittingQuotation(true);
        TaskService.addVendorQuotation(loginUserDetail.userId, reqDto)
          .then((response) => {
            if (response.data) {
              if (response.data.returnValue === "1") {
                toast.success(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                setAddQuotationFile({
                  fileName: "",
                  fileData: "",
                });
                setAddVarianceDetails({
                  variance: "",
                  varianceSpeciation: "",
                });
                setAddTaxDetails({
                  sgstPc: 0,
                  cgstPc: 0,
                  igstPc: 0,
                  sgstAmt: 0,
                  cgstAmt: 0,
                  igstAmt: 0,
                  taxPc: 0,
                  taxAmt: 0,
                });
                setSelectedMethodId(0);
                handleSubmitData();
              } else {
                toast.error(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
              // setLoading(false);
            }
          })
          .finally(() => {
            setSubmittingQuotation(false);
          });
      }
    }
  };

  const handleChangeAddTax = (itemId) => {
    const index = itemList.findIndex((obj) => obj.itemId === itemId);
    const tempList = [...itemList];

    tempList[index]["sgstPc"] = parseInt(addTaxDetails.sgstPc);
    tempList[index]["sgstAmt"] = parseFloat(addTaxDetails.sgstAmt);

    tempList[index]["cgstPc"] = parseInt(addTaxDetails.cgstPc);
    tempList[index]["cgstAmt"] = parseFloat(addTaxDetails.cgstAmt);

    tempList[index]["igstPc"] = parseInt(addTaxDetails.igstPc);
    tempList[index]["igstAmt"] = parseFloat(addTaxDetails.igstAmt);

    tempList[index]["taxPc"] = parseInt(addTaxDetails.taxPc);
    tempList[index]["taxAmt"] = parseFloat(addTaxDetails.taxAmt);

    tempList[index]["newQuotationValue"] =
      parseFloat(tempList[index].quotationValue) +
      parseFloat(addTaxDetails.taxAmt);

    setItemList(tempList);

    setShowAddTaxDialog(false);
    setSelectedItem(undefined);
    setAddTaxDetails({
      sgstPc: 0,
      cgstPc: 0,
      igstPc: 0,
      taxPc: 0,
      sgstAmt: 0,
      cgstAmt: 0,
      igstAmt: 0,
      taxAmt: 0,
    });
    // setRefreshCount(refreshCount + 1);
  };

  const handleChangeFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file.size > 1024 * 1024 * MAX_FILE_SIZE_ADD_VENDOR_QUOTATION) {
      toast.error(
        `Max File Size Allowed ${MAX_FILE_SIZE_ADD_VENDOR_QUOTATION}MB`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (
      !VALID_FILE_EXTENSION_ADD_VENDOR_QUOTATION.includes(
        file.name.split(".").pop()
      )
    ) {
      toast.error(`Invalid File Extension`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success(`${file.name} Attached`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setAddQuotationFile({
        fileName: file.name,
        fileData: await convertFileToBase64_Split(file),
      });
    }

    inputFileRef.current.value = null;
  };

  const handleChangeGstAmount = (amount, totalAmount, key) => {
    const percent = (100 * parseFloat(amount)) / parseFloat(totalAmount);

    let obj = {};
    if (key === "sgstAmt") {
      obj["sgstPc"] = percent;
      obj["sgstAmt"] = amount;
      obj["cgstPc"] = percent;
      obj["cgstAmt"] = amount;
      obj["igstPc"] = 0;
      obj["igstAmt"] = 0;
      obj["taxPc"] = percent * 2;
      obj["taxAmt"] = amount * 2;
    }

    if (key === "igstAmt") {
      obj["sgstPc"] = 0;
      obj["sgstAmt"] = 0;
      obj["cgstPc"] = 0;
      obj["cgstAmt"] = 0;
      obj["igstPc"] = percent;
      obj["igstAmt"] = amount;
      obj["taxPc"] = percent;
      obj["taxAmt"] = amount;
    }

    if (key === "taxAmt") {
      obj["sgstPc"] = 0;
      obj["sgstAmt"] = 0;
      obj["cgstPc"] = 0;
      obj["cgstAmt"] = 0;
      obj["igstPc"] = 0;
      obj["igstAmt"] = 0;
      obj["taxPc"] = percent;
      obj["taxAmt"] = amount;
    }

    setAddTaxDetails({
      ...addTaxDetails,
      ...obj,
    });
    setTaxRefreshCount(taxRefreshCount + 1);
  };

  const [addVarianceDetails, setAddVarianceDetails] = useState({
    variance: "",
    varianceSpeciation: "",
  });

  const calculateSubTotal = (itemList) => {
    let val = 0;
    itemList.forEach((item) => {
      const tempVal = calculateQuotationValue(item);
      val = val + tempVal;
    });
    return val;
  };

  const handleChangeGstPercent = (percent, amount, key) => {
    const tmpAmount = parseFloat(percent);

    if (isNaN(tmpAmount) || tmpAmount > 100) {
      return;
    }
    let amt = (parseFloat(amount) / 100) * parseFloat(percent);
    let tempPercent = percent;
    if (!amt) {
      amt = 0;
    }

    if (!percent) {
      tempPercent = 0;
    }

    let obj = {};

    if (key === "sgstPc") {
      obj["sgstPc"] = tempPercent;
      obj["sgstAmt"] = amt;
      obj["cgstPc"] = tempPercent;
      obj["cgstAmt"] = amt;
      obj["igstPc"] = 0;
      obj["igstAmt"] = 0;
      obj["taxPc"] = tempPercent * 2;
      obj["taxAmt"] = amt * 2;
    }

    if (key === "igstPc") {
      obj["sgstPc"] = 0;
      obj["sgstAmt"] = 0;
      obj["cgstPc"] = 0;
      obj["cgstAmt"] = 0;
      obj["igstPc"] = tempPercent;
      obj["igstAmt"] = amt;
      obj["taxPc"] = tempPercent;
      obj["taxAmt"] = amt;
    }

    if (key === "taxPc") {
      obj["sgstPc"] = 0;
      obj["sgstAmt"] = 0;
      obj["cgstPc"] = 0;
      obj["cgstAmt"] = 0;
      obj["igstPc"] = 0;
      obj["igstAmt"] = 0;
      obj["taxPc"] = tempPercent;
      obj["taxAmt"] = amt;
    }

    setAddTaxDetails({
      ...addTaxDetails,
      ...obj,
    });
    setTaxRefreshCount((prev) => prev + 1);
  };

  const handleChangeAddVariance = (itemId) => {
    const index = itemList.findIndex((obj) => obj.itemId === itemId);
    const temp = [...itemList];
    temp[index]["variance"] = addVarianceDetails.variance;
    temp[index]["varianceSpeciation"] = addVarianceDetails.varianceSpeciation;

    setItemList(temp);
    setShowAddVarianceDialog(false);
    setSelectedItem(undefined);
    setAddVarianceDetails({ variance: "", varianceSpeciation: "" });
  };

  return (
    <>
      <div className="modelContainer" style={{ zIndex: "9999" }}>
        <Box className="modelContain animated width90vwModel  ">
          <IconButton
            className="closeModelIoc"
            onClick={(e) => {
              closeModal();
            }}
          >
            <XLg />
          </IconButton>

          <div className="fwModelContainArea">
            <div className="modHeader">
              <div className="modHeaderText">Add Quotation </div>
            </div>

            <div className="modBody">
              <div className="modResptable">
                <table>
                  <thead>
                    <tr>
                      <th className="minWidth160">Item Name</th>
                      <th>Description</th>
                      <th>Variance</th>
                      <th>Indent Qty</th>
                      <th>Quotation Qty *</th>
                      <th>Quotation Rate *</th>
                      <th>Tax</th>
                      <th>Quotation Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemList.map((item) => {
                      return (
                        <tr key={item.itemId}>
                          <td>{item.itemName}</td>

                          <td>{item.itemDescription}</td>

                          <td>
                            <Tooltip
                              title={
                                item.variance &&
                                item.variance !== "" &&
                                item.varianceSpeciation &&
                                item.varianceSpeciation !== "" && (
                                  <ul
                                    style={{
                                      listStyleType: "none",
                                      textAlign: "justify",
                                      marginLeft: "-35px",
                                    }}
                                  >
                                    <li>Variance : {item.variance}</li>
                                    <li>
                                      Variance Speciation :{" "}
                                      {item.varianceSpeciation}
                                    </li>
                                  </ul>
                                )
                              }
                            >
                              <Description
                                cursor={"pointer"}
                                onClick={() => {
                                  setSelectedItem(item);
                                  setShowAddVarianceDialog(true);
                                  setAddVarianceDetails({
                                    variance: item.variance,
                                    varianceSpeciation: item.varianceSpeciation,
                                  });
                                }}
                                className={
                                  item.variance && item.variance !== ""
                                    ? `selectedIcon`
                                    : ``
                                }
                              />
                            </Tooltip>
                          </td>

                          <td>{item.indentQty}</td>

                          <td>
                            {refreshCount >= 0 && (
                              <TextField
                                size="small"
                                type="number"
                                inputProps={{ min: 0 }}
                                value={refreshCount > 0 && item.quotationQty}
                                onChange={(e) => {
                                  item.quotationQty = parseInt(e.target.value);
                                  item.sgstPc = 0;
                                  item.cgstPc = 0;
                                  item.igstPc = 0;
                                  item.taxPc = 0;
                                  item.sgstAmt = 0;
                                  item.cgstAmt = 0;
                                  item.igstAmt = 0;
                                  item.taxAmt = 0;
                                  setRefreshCount(refreshCount + 1);
                                }}
                              />
                            )}
                          </td>

                          <td>
                            {refreshCount >= 0 && (
                              <TextField
                                size="small"
                                type="number"
                                step={1.0}
                                inputProps={{ min: 0 }}
                                value={refreshCount > 0 && item.quotationRate}
                                onChange={(e) => {
                                  item.quotationRate = parseFloat(
                                    e.target.value
                                  );
                                  item.sgstPc = 0;
                                  item.cgstPc = 0;
                                  item.igstPc = 0;
                                  item.taxPc = 0;
                                  item.sgstAmt = 0;
                                  item.cgstAmt = 0;
                                  item.igstAmt = 0;
                                  item.taxAmt = 0;
                                  setRefreshCount(refreshCount + 1);
                                }}
                              />
                            )}
                          </td>

                          <td>
                            <div
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              {item.taxAmt !== 0 && item.taxAmt}
                              <Tooltip title={item.taxAmt && item.taxAmt}>
                                {item.taxAmt && item.taxAmt > 0 ? (
                                  <IconButton
                                    cursor={"pointer"}
                                    className="selectedIcon"
                                    onClick={() => {
                                      setShowAddTaxDialog(true);
                                      setSelectedItem(item);
                                      setAddTaxDetails({
                                        sgstPc: item.sgstPc,
                                        cgstPc: item.cgstPc,
                                        igstPc: item.igstPc,
                                        taxPc: item.taxPc,
                                        sgstAmt: item.sgstAmt,
                                        cgstAmt: item.cgstAmt,
                                        igstAmt: item.igstAmt,
                                        taxAmt: item.taxAmt,
                                      });
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    cursor={"pointer"}
                                    onClick={() => {
                                      setShowAddTaxDialog(true);
                                      setSelectedItem(item);
                                      setAddTaxDetails({
                                        sgstPc: item.sgstPc,
                                        cgstPc: item.cgstPc,
                                        igstPc: item.igstPc,
                                        taxPc: item.taxPc,
                                        sgstAmt: item.sgstAmt,
                                        cgstAmt: item.cgstAmt,
                                        igstAmt: item.igstAmt,
                                        taxAmt: item.taxAmt,
                                      });
                                    }}
                                  >
                                    <Add />
                                  </IconButton>
                                )}
                              </Tooltip>
                            </div>
                          </td>

                          <td>
                            {refreshCount > 0 && calculateQuotationValue(item)}
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan={6}></td>
                      <td colSpan={1}>Sub Total</td>
                      <td colSpan={1}>{calculateSubTotal(itemList)}</td>
                    </tr>

                    {otherCharges &&
                      otherCharges.length > 0 &&
                      otherCharges.map((item) => {
                        return (
                          <tr>
                            <td colSpan={6}></td>
                            <td colSpan={1}>{item.chargesLabel}</td>
                            <td colSpan={1}>
                              {item.chargesAmount}
                              <span style={{ float: "right" }}>
                                <Tooltip title="Remove" arrow>
                                  <Trash
                                    height={"1.2rem"}
                                    width={"1.2rem"}
                                    cursor={"pointer"}
                                    onClick={() => {
                                      let tmpList = otherCharges.filter(
                                        (obj) =>
                                          JSON.stringify(obj) !==
                                          JSON.stringify(item)
                                      );
                                      setOtherCharges(tmpList);
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    {showAddOtherCharges && (
                      <tr>
                        <td colSpan={3}></td>
                        <td colSpan={2}>
                          <TextField
                            size="small"
                            label={"Charge Description"}
                            value={otherChargesDescription}
                            onChange={(e) => {
                              setOtherChargesDescription(e.target.value);
                            }}
                          />
                        </td>
                        <td colSpan={2}>
                          <TextField
                            type="number"
                            size="small"
                            label={"Price"}
                            value={otherChargesValue}
                            onChange={(e) => {
                              setOtherChargesValue(e.target.value);
                            }}
                          />
                        </td>
                        <td colSpan={1}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Tooltip title="Add Charge">
                              <div className={`formBtnElement`}>
                                <Button
                                  className="comBtnOutline"
                                  onClick={() => {
                                    const temp = {
                                      chargesLabel: otherChargesDescription,
                                      chargesAmount:
                                        parseFloat(otherChargesValue),
                                    };
                                    if (otherChargesDescription.trim() !== "") {
                                      setOtherCharges((prev) => [
                                        ...prev,
                                        temp,
                                      ]);
                                      setOtherChargesDescription("");
                                      setOtherChargesValue(0);
                                      setShowAddOtherCharges(false);
                                    }
                                  }}
                                >
                                  Save
                                </Button>
                              </div>
                            </Tooltip>
                            <Tooltip title="Close">
                              <Button
                                onClick={() => {
                                  setShowAddOtherCharges(false);
                                }}
                              >
                                <Cancel />
                              </Button>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    )}

                    {!showAddOtherCharges && (
                      <tr>
                        <td colSpan={7}></td>
                        <td colSpan={1}>
                          <Tooltip title="Add Other Charges">
                            <IconButton
                              disabled={showAddOtherCharges}
                              onClick={() => {
                                setShowAddOtherCharges(true);
                              }}
                              // className="comBtnOutline"
                            >
                              <Add />
                            </IconButton>
                          </Tooltip>
                        </td>
                        {/* <TableCell colSpan={1}>12457</TableCell> */}
                      </tr>
                    )}

                    <tr>
                      <td colSpan={6}></td>
                      <td colSpan={1}>Total</td>
                      <td colSpan={1}>
                        {calculateTotal(itemList, otherCharges)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="formElementGroup mdButtonGroup">
                <div className={`formBtnElement`}>
                  <Button
                    variant="outlined"
                    startIcon={<Bank />}
                    className={`comBtnOutline ${
                      selectedMethodId !== 0 ? "comBtnActiveStatus" : ""
                    }`}
                    onClick={() => setShowBankDetails(true)}
                  >
                    Banking Details
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<CloudUpload />}
                    className={`comBtnOutline ${
                      addQuotationFile.fileName !== ""
                        ? "comBtnActiveStatus"
                        : ""
                    } `}
                    onClick={() => inputFileRef.current.click()}
                  >
                    Upload
                  </Button>
                  <input
                    type="file"
                    ref={inputFileRef}
                    hidden
                    onChange={handleChangeFileUpload}
                  />
                  <div className="UplodedFilNm">
                    <span className="flnm">{addQuotationFile.fileName}</span>
                    {addQuotationFile.fileName !== "" && (
                      <IconButton
                        onClick={() => {
                          setAddQuotationFile({
                            fileName: "",
                            fileData: "",
                          });
                        }}
                      >
                        <Trash />
                      </IconButton>
                    )}
                  </div>
                  <Button
                    variant="outlined"
                    startIcon={<Gavel />}
                    className={`comBtnOutline ${
                      hasSeenTAC ? "comBtnActiveStatus" : ""
                    }  `}
                    onClick={() => {
                      TaskService.getTermsConditionsByRfpVendorId(
                        rfpVendorId
                      ).then((response) => {
                        if (response.data) {
                          const temp = response.data.map((item) => ({
                            ...item,
                            accepted: "Y",
                          }));
                          setTermsAndConditionList([...temp, ...vendorTAC]);
                          setIsAcceptedTermsAndCond(true);
                          // reset({ slNo: response.data.length + 1 });
                        }
                      });
                      setShowTermsAndCondDialog(true);
                    }}
                  >
                    T & C
                  </Button>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel
                      id="setCompletionDate"
                      className="setCompletionDate"
                    >
                      Delivery Date
                    </InputLabel>
                    <ReactDatePicker
                      showIcon
                      labelId="setCompletionDate"
                      className="formDatePicker"
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      selected={deliveryDate}
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        setDeliveryDate(date);
                      }}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="formElementGroup mdButtonGroup">
                <div className="formElement">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={advancedChecked}
                        onChange={(e) => {
                          setAdvanceChecked(e.target.checked);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Advance Needed"
                  />
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label="Advance Payment (in %)"
                      variant="outlined"
                      autoComplete="off"
                      disabled={!advancedChecked}
                      value={advanceAmount}
                      multiline
                      onChange={(e) => {
                        const val = parseInt(e.target.value);
                        if (e.target.value === "") {
                          setAdvanceAmount("");
                          return;
                        }
                        if (isNaN(val)) {
                          setAdvanceAmount("");
                        }
                        if (val < 100) {
                          setAdvanceAmount(val);
                        }
                      }}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="modActionBtnGrp actionsCreateOrg">
                <Button
                  className="dfultDarkSecondaryBtn  "
                  variant="outlined"
                  onClick={(e) => {
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="dfultPrimaryBtn "
                  variant="outlined"
                  onClick={(e) => {
                    if (showAddOtherCharges) {
                      toast.error("Please save the additional charges", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    } else {
                      handelSubmitVendorQuotation(e);
                    }
                  }}
                  disabled={submittingQuotation}
                >
                  {!submittingQuotation && "Submit"}
                  {submittingQuotation && (
                    <CircularProgress size={24} color="inherit" />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </div>

      {selectedItem && (
        <>
          {showAddVarianceDialog && (
            <>
              <CustomModal
                modalTitle="Add Veriance"
                style={{ zIndex: 10001 }}
                closeModal={() => {
                  setShowAddVarianceDialog(false);
                }}
                // className={"width90vwModel"}
              >
                <div className="modBody">
                  <div className="formElementGroup">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          required
                          className="modelTextFild"
                          label="Variance"
                          variant="outlined"
                          value={addVarianceDetails.variance}
                          onChange={(e) =>
                            setAddVarianceDetails({
                              ...addVarianceDetails,
                              ...{ variance: e.target.value },
                            })
                          }
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          required
                          className="modelTextFild"
                          label="Variance Specification"
                          variant="outlined"
                          value={addVarianceDetails.varianceSpeciation}
                          onChange={(e) =>
                            setAddVarianceDetails({
                              ...addVarianceDetails,
                              ...{ varianceSpeciation: e.target.value },
                            })
                          }
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="modActionBtnGrp actionsCreateOrg">
                  <Button className="dfultDarkSecondaryBtn">Cancel</Button>
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={() => handleChangeAddVariance(selectedItem.itemId)}
                  >
                    Submit
                  </Button>
                </div>
              </CustomModal>
            </>
          )}
        </>
      )}

      {showAddTaxDialog && (
        <CustomModal
          modalTitle="Add Tax Details"
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowAddTaxDialog(false);
          }}
          className={"width90vwModel"}
        >
          <div className="modBody">
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    className="modelTextFild"
                    step={1.0}
                    inputProps={{ min: 0 }}
                    size="small"
                    label="SGST %"
                    value={addTaxDetails.sgstPc}
                    onChange={(e) =>
                      handleChangeGstPercent(
                        e.target.value,
                        selectedItem.quotationQty * selectedItem.quotationRate,
                        "sgstPc"
                      )
                    }
                  />
                </FormControl>
              </div>
              <div className="formElement">
                {taxRefreshCount > 0 && (
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      type="number"
                      step={1.0}
                      inputProps={{ min: 0 }}
                      size="small"
                      label="SGST Amount "
                      value={addTaxDetails.sgstAmt}
                      onChange={(e) =>
                        handleChangeGstAmount(
                          e.target.value,
                          selectedItem.quotationQty *
                            selectedItem.quotationRate,
                          "sgstAmt"
                        )
                      }
                    />
                  </FormControl>
                )}
              </div>

              <div className="formElement">
                {taxRefreshCount > 0 && (
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      type="number"
                      step={1.0}
                      inputProps={{ min: 0 }}
                      size="small"
                      label="CGST %"
                      value={addTaxDetails.cgstPc}
                      onChange={(e) =>
                        handleChangeGstPercent(
                          e.target.value,
                          selectedItem.quotationQty *
                            selectedItem.quotationRate,
                          "sgstPc"
                        )
                      }
                    />
                  </FormControl>
                )}
              </div>

              <div className="formElement">
                {taxRefreshCount > 0 && (
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      type="number"
                      step={1.0}
                      inputProps={{ min: 0 }}
                      size="small"
                      label="CGST Amount"
                      value={addTaxDetails.cgstAmt}
                    />
                  </FormControl>
                )}
              </div>

              <div className="formElement">
                {taxRefreshCount > 0 && (
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      type="number"
                      step={1.0}
                      inputProps={{ min: 0 }}
                      size="small"
                      label="IGST %"
                      value={addTaxDetails.igstPc}
                      onChange={(e) =>
                        handleChangeGstPercent(
                          e.target.value,
                          selectedItem.quotationQty *
                            selectedItem.quotationRate,
                          "igstPc"
                        )
                      }
                    />
                  </FormControl>
                )}
              </div>

              <div className="formElement">
                {taxRefreshCount > 0 && (
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      type="number"
                      step={1.0}
                      inputProps={{ min: 0 }}
                      size="small"
                      label="IGST Amount"
                      value={addTaxDetails.igstAmt}
                      onChange={(e) =>
                        handleChangeGstAmount(
                          e.target.value,
                          selectedItem.quotationQty *
                            selectedItem.quotationRate,
                          "igstAmt"
                        )
                      }
                    />
                  </FormControl>
                )}
              </div>
            </div>
          </div>
          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => setShowAddTaxDialog(false)}
            >
              Cancel
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={() => handleChangeAddTax(selectedItem.itemId)}
            >
              Submit
            </Button>
          </div>
        </CustomModal>
      )}

      {showBankDetails && (
        <VendorPaymentMethods
          closeModal={() => {
            setShowBankDetails(false);
          }}
          selectedMethodId={selectedMethodId}
          setSelectedMethodId={setSelectedMethodId}
          vendorUserId={vendorUserId}
        />
      )}

      {showTermsAndCondDialog && (
        <CustomModal
          modalTitle="Terms And Conditions"
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowTermsAndCondDialog(false);
          }}
          className={"width90vwModel"}
        >
          <div className="modBody">
            <div className="modResptable">
              <table>
                <thead>
                  <tr>
                    <th className="mw80">Sl No.</th>
                    <th className="minWidth160">Type</th>
                    <th className="minWidth160">Heading</th>
                    <th>Terms And Condition</th>
                    {/* <th className="minWidth160">Rejection Reason</th> */}
                    <th className="minWidth160">Accept/Reject</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {termsAndConditionList &&
                    termsAndConditionList.map((condition, index) => (
                      <tr>
                        <td>{condition.slNo}</td>
                        <td>{condition.termsConditionsType}</td>
                        <td>{condition.termsConditionsHeading}</td>
                        <td>{condition.termsConditions}</td>
                        {/* <td>
                          {condition.rejectionReason &&
                            condition.rejectionReason}
                        </td> */}

                        <td>
                          {condition.addedByVendor &&
                            condition.addedByVendor !== "Y" && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {/* <Tooltip title="Accept">
                                  <IconButton
                                    onClick={(e) => {
                                      const temp = [...termsAndConditionList];
                                      temp[index].accepted = "Y";
                                      setTermsAndConditionList(temp);
                                      setHasSeenTAC(true);
                                    }}
                                  >
                                    <Check />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Reject">
                                  <IconButton
                                    onClick={(e) => {
                                      //
                                      setRejectionIndex(index);
                                      setShowAddRejectionCauseModal(true);
                                    }}
                                  >
                                    <CancelOutlined />
                                  </IconButton>
                                </Tooltip> */}

                                <Switch
                                  className="switch"
                                  inputProps={{ "aria-label": "controlled" }}
                                  checked={
                                    termsAndConditionList[index].accepted ===
                                    "Y"
                                  }
                                  onClick={(e) => {
                                    const temp = [...termsAndConditionList];
                                    temp[index].accepted =
                                      temp[index].accepted === "N" ? "Y" : "N";
                                    setTermsAndConditionList(temp);
                                    setHasSeenTAC(true);
                                  }}
                                  // onChange={(e) => {
                                  //   ;
                                  //   const temp = [...termsAndConditionList];
                                  //   temp[index].accepted = e.target.checked
                                  //     ? "Y"
                                  //     : "N";
                                  //   setTermsAndConditionList(temp);
                                  //   setHasSeenTAC(true);
                                  // }}
                                />
                              </div>
                            )}
                        </td>

                        <td>
                          {condition.addedByVendor === "Y" && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Tooltip
                                title="Delete Terms And Conditions"
                                placement="left"
                              >
                                <Button
                                  onClick={() => {
                                    setDeleteTACData(condition);
                                    setShowDeletionConfirmationDialogue(true);
                                    const tempTerm = [...termsAndConditionList];
                                    tempTerm.splice(index, 1);
                                    setTermsAndConditionList(tempTerm);
                                  }}
                                >
                                  <Trash />
                                </Button>
                              </Tooltip>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="mt20">
              <Button
                className="dfultDarkSecondaryBtn "
                onClick={() => {
                  setValue("slNo", termsAndConditionList.length + 1);
                  setValue("termsConditionsHeading", "");
                  setValue("termsConditions", "");
                  setEditTAC(false);
                  setEditTACData(undefined);
                  setShowAddTAC(true);
                }}
              >
                Add Vendor T & C
              </Button>
            </div>
          </div>
        </CustomModal>
      )}

      {showAddTAC && (
        <>
          <Box
            className="ModelBox "
            sx={{ boxShadow: 24, p: 4, zIndex: 10002 }}
          >
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Add Terms And Condition</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label="Sl No"
                      variant="outlined"
                      multiline
                      // rows={3}
                      {...register("slNo")}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="setActivity">Type</InputLabel>
                    <Controller
                      name="termsConditionsType"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          className="modelSelectFild"
                          label="Activity"
                          variant="outlined"
                          labelId="setActivity"
                          id="activity-select"
                          {...field}
                        >
                          <MenuItem value={""}>{"--Select Type--"}</MenuItem>
                          {typeList &&
                            typeList.length > 0 &&
                            typeList.map((item) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                          {/* <MenuItem value={"Appointment"}>Appointment</MenuItem>
                      <MenuItem value={"Board Meeting"}>Board Meeting</MenuItem>
                      <MenuItem value={"Budget Requisition"}>
                        Budget Requisition
                      </MenuItem> */}
                        </Select>
                      )}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label="Heading"
                      variant="outlined"
                      multiline
                      // rows={3}
                      {...register("heading")}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label="Condition"
                      variant="outlined"
                      multiline
                      rows={3}
                      {...register("condition")}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={handleSubmit(handleSubmitAddCondition)}
                >
                  Add
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowAddTAC(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        </>
      )}

      {showAddRejectionCauseModal && (
        <CustomModal
          modalTitle="Terms And Conditions"
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowAddRejectionCauseModal(false);
          }}
          className={"width90vwModel"}
        >
          <div className="modBody"></div>
        </CustomModal>
      )}
    </>
  );
};
