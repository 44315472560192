import { useEffect, useRef, useState } from "react";

export const useTimeProgressor = (startTime, endTime) => {
  const [dateRemaining, setDateRemaning] = useState(0);
  const timerId = useRef(null);

  useEffect(() => {
    if (startTime && endTime) {
      getTaskDifference();
    }
    return () => {
      console.log("clearing interval");
      if (timerId.current) {
        clearInterval(timerId.current);
      }
    };
  }, [startTime, endTime]);

  const intervalProgressor = (timeDelay) => {
    timerId.current = setInterval(() => {
      setDateRemaning((prev) => {
        console.log("interval progressor=", prev);
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(timerId.current);
          return prev;
        }
      });
    }, timeDelay);
  };

  const getTaskDifference = () => {
    // const time1=task.taskCreationTime
    // const time2 =task.taskCompletionTime
    const creationTime = new Date(startTime);
    const completionTime = new Date(endTime);
    const currentTime = new Date();

    //check if already time passed

    const diffFromCurrent = currentTime - completionTime;
    if (diffFromCurrent > 0) {
      setDateRemaning(100);
      return;
    }

    // Calculate the time difference in milliseconds
    const totalTimeDifference = completionTime - creationTime;
    const timeDifferenceFromNow = currentTime - creationTime;
    const milisecondPerPercentIncrease = totalTimeDifference / 100;
    const currentPercent = (timeDifferenceFromNow * 100) / totalTimeDifference;
    setDateRemaning(
      Math.floor(currentPercent) < 100 ? Math.floor(currentPercent) : 100
    );
    const nextPercent = Math.ceil(currentPercent);
    const difference = nextPercent - currentPercent;
    const diffInMilisecond = milisecondPerPercentIncrease * difference;
    const clearTimeoutVal = setTimeout(() => {
      if (currentPercent < 100) {
        console.log("before interval progress", milisecondPerPercentIncrease);
        clearTimeout(clearTimeoutVal);
        setDateRemaning((prev) => prev + 1);
        if (milisecondPerPercentIncrease > 0) {
          intervalProgressor(milisecondPerPercentIncrease);
        }
      }
    }, diffInMilisecond);
    console.log("currentPercent", currentPercent);
    console.log(
      "milisecondPerPercentIncrease = ",
      milisecondPerPercentIncrease / 1000
    );
  };

  return dateRemaining;
};
