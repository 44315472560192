import { Add, ArrowBack, Edit, Search, Visibility } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Eye, Trash } from "react-bootstrap-icons";
import ShowLeaveBalance from "./model/ShowLeaveBalance";
import {
  getLeaveRulesOfOrg,
  deactivateLeaveRule,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

export default function ViewLeaveRules({
  handelOpenAddLeaveRules,
  handelCloseViewLeaveRules,
  refreshAddLeaveRules,
  handleOpenUpdateLeaveRule,
  handleOpenViewSelectedLeaveRule ,
  closeAll
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const [loading, setLoading] = useState(true);
  const [showLeaveBalance, setShowLeaveBalance] = useState(false);
  const handelOpenLeaveBalanceModel = () => {
    setShowLeaveBalance(true);
  };
  const handelCloseLeaveBalanceModel = () => {
    setShowLeaveBalance(false);
  };
  const [leaveRuleData, setLeaveRuleData] = useState([]);
  const [filterLeaveRuleData, setFilterLeaveRuleData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [leaveRuleToDelete, setLeaveRuleToDelete] = useState(null);

  useEffect(() => {
    fetchLeaveRuleData();
  }, [refreshAddLeaveRules]);

    const fetchLeaveRuleData = () => {
    getLeaveRulesOfOrg(userDetails.orgId).then((response) => [
      console.log("getLeaverules", response.data),
      setLeaveRuleData(response.data),
      setFilterLeaveRuleData(response.data),
      setLoading(false),
    ]);
  }


  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = leaveRuleData.filter(
      (rule) =>
        rule.leaveTypeDisplay &&
        rule.leaveTypeDisplay.toLowerCase().includes(query.toLowerCase())
    );

    setFilterLeaveRuleData(filteredData);
  };

  const handleDelete = (leaveRuleId) => {
    console.log("handleDelete", leaveRuleId);
    setLeaveRuleToDelete(leaveRuleId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    if (leaveRuleToDelete) {
      deactivateLeaveRule(leaveRuleToDelete, userDetails.userId).then(
        (response) => {
          console.log("data deleted", response.data);
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchLeaveRuleData();
            setOpenModal(false);
          }
        }
      );
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton onClick={() => {handelCloseViewLeaveRules(); closeAll();}}>
            <ArrowBack />
          </IconButton>
          Leave Rules
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder="Search Leave Rules"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Eye />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenLeaveBalanceModel()}
                >
                  Leave Balance
                </Button>
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddLeaveRules()}
                >
                  Leave Rules
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : leaveRuleData.length === 0 ? (
                <div class="noDataCard ">
                  <span>Oops!!</span>No Leave Rule Define yet !!
                  <br />
                  <span className="sallMsg">
                    Click Add Leave Rule Button to create Leave Rule
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>Leave Type</th>
                        <th>Gender</th>
                        <th>Employee Status</th>
                        <th>Employment Type</th>
                        <th>Minimum Leaves</th>
                        <th>Min. Employment Days</th>
                        <th>Min. Notice Days</th>
                        <th className="width100"></th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                      {filterLeaveRuleData.map((rule) => (
                        <tr key={rule.id}>
                          <td>
                            <div className="tskLstTaskNM">
                              {rule.leaveTypeDisplay}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">{rule.gender}</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">{rule.empStatus}</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {rule.salaryType}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {rule.minDays}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {rule.minEmploymentDays}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {rule.minNoticeDays}
                            </div>
                          </td>
                          <td>
                            <div className="tblActionBtnGrp">
                              <IconButton onClick={()=>handleOpenViewSelectedLeaveRule(rule)}>
                                <Visibility />
                              </IconButton>
                              <IconButton onClick={()=>handleOpenUpdateLeaveRule(rule)}>
                                <Edit />
                              </IconButton>
                              <IconButton onClick={() => handleDelete(rule.id)}>
                                <Trash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">Casual Leave</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Male, Female</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Confirmed</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Payroll</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">12</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">180</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">3</div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Visibility />
                          </IconButton>
                          <IconButton>
                            <Edit />
                          </IconButton>
                          <IconButton>
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                  </table>
                </div>
              )}
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // width: "180px",
                    // height: "180px",
                    maxWidth: "600px",
                    minHeight: "150px",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 id="modal-modal-title">
                    Do you want to delete this Leave Rule ?
                  </h2>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      // variant="contained"
                      // color="secondary"
                      className="dfultPrimaryBtn"
                      onClick={confirmDelete}
                    >
                      Yes
                    </Button>
                    <Button 
                    // variant="contained" 
                    className="dfultDarkSecondaryBtn"
                    onClick={handleCloseModal}>
                      No
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>

      {showLeaveBalance && (
        <ShowLeaveBalance
          showLeaveBalance={showLeaveBalance}
          handelCloseLeaveBalanceModel={handelCloseLeaveBalanceModel}
        />
      )}
    </>
  );
}
