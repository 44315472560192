import {
  Add,
  AttachFile,
  CancelOutlined,
  CloseFullscreen,
  DescriptionOutlined,
  Edit,
  OpenInFull,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { NumericFormat } from "react-number-format";

import PropTypes from "prop-types";
import { Check, Trash, XLg } from "react-bootstrap-icons";
import { Controller, useForm } from "react-hook-form";
import TaskService from "../../../../../services/TaskService";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { toast } from "react-toastify";
import GoodsServicesForm from "./GoodsServicesForm";
import dayjs from "dayjs";
import GoodsAndServicesFormPreview from "./GoodsAndServicesFormPreview";
import DaTaForm from "./DaTaForm";
import DaTaFormPreview from "./DaTaFormPreview";
import ReimbursementForm from "./ReimbursementForm";
import { Expand } from "iconoir-react";
import ManpowerForm from "./ManpowerForm";
import { StatutoryAndInsurancePaymentForm } from "./StatutoryAndInsurancePaymentForm";
import { AdvanceForm } from "./AdvanceForm";
import { useMemo } from "react";
import { FormPreview } from "./FormPreview";
import {
  getExpenseApprover,
  getPositionsOfOrganization,
  getStaticDDOfOrg,
} from "../../../../../services/AdminService";
import DashboardServices from "../../../../../services/DashboardServices";
import DateUtils from "../../../../../utils/DateUtils";
import { useRef } from "react";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import { TermsAndConditions } from "./TermsAndConditions";
import { VendorPaymentMethods } from "./VendorPaymentMethods";
import { CustomModal } from "../Modals/CustomModal";
import { getNumFromStr } from "../../../taskUtil";

const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      // prefix="NO:"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function GoodsExpenditureApproval({
  handleClose,
  updateFormData,
  isExpanded,
  setIsExpanded,
  contract,
  taskFormData,
  handleSubmitPurchaseForm,
}) {
  const loginUserDetail = getLoginUserDetails();
  const recurringDocumentRef = useRef();
  const [recurringDocument, setRecurringDocument] = useState(null);
  const [expenseApprover, setExpenseApprover] = useState();
  const { control, handleSubmit, register, setValue, watch, getValues, reset } =
    useForm();
  const {
    ExpenditureGoods,
    ProcessDirect,
    purpose,
    expenditureType,
    // costCenter,
    recurringPeriod,
    ceoe,
  } = watch();
  const [recurring, setRecurring] = useState(false);
  const [refresh, setRefresh] = useState(1);

  const [selectedVendor, setSelectedVendor] = useState(null);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [tAcModalCheck, setTacModalCheck] = useState(false);
  const [hasSeenTAC, setHasSeenTAC] = useState(false);
  const [orgTacList, setOrgTacList] = useState([]);
  const [selectedConditionList, setSelectedConditionList] = useState([]);
  const [showPaymentMehtod, setShowPaymentMehtod] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState(0);
  const [advancedChecked, setAdvanceChecked] = useState(false);
  const [advanceAmount, setAdvanceAmount] = useState("");
  const [otherCharges, setOtherCharges] = useState([]);
  const [showTermsAndCondDialog, setShowTermsAndCondDialog] = useState(false);
  const [termsAndConditionList, setTermsAndConditionList] = useState([]);
  const [rejectionIndex, setRejectionIndex] = useState(null);
  const [showAddRejectionCauseModal, setShowAddRejectionCauseModal] =
    useState(false);

  const [editTAC, setEditTAC] = useState(false);
  const [editTACData, setEditTACData] = useState(undefined);
  const [deleteTACData, setDeleteTACData] = useState(undefined);
  const [
    showDeletionConfirmationDialogue,
    setShowDeletionConfirmationDialogue,
  ] = useState(false);
  const [showAddTAC, setShowAddTAC] = useState(false);

  const [vendorTAC, setVendorTAC] = useState([]);
  const [typeList, setTypeList] = useState([]);

  const computedList = useMemo(() => {
    let filtered = [];
    if (
      autocompleteInputValue.length > 0 &&
      autocompleteInputValue.trim() !== ""
    ) {
      filtered = vendorList.filter((option) =>
        option.userName
          .toLowerCase()
          .includes(autocompleteInputValue.toLowerCase())
      );
    }

    return filtered;
  }, [autocompleteInputValue, vendorList]);

  useEffect(() => {
    console.log("recurring  =", recurring);
    setRefresh((prev) => prev + 1);
  }, [recurring]);

  useEffect(() => {
    handleUpdateExpenditureGoods();
  }, [ExpenditureGoods]);

  const handleUpdateExpenditureGoods = () => {
    setRecurring(false);
    setTableDataList([]);
    setDATAdata([]);
    setStatutoryList([]);
    setManpowerList([]);
    setReimbursementList([]);
  };

  const getFileDataObj = async (file) => {
    const dto = {
      fileName: file.name,
      fileDataLink: await convertFileToBase64_Split(file),
    };
    return dto;
  };

  const getBudgetHeadName = (headId) => {
    const filtered = filteredBudgetHeadList.find((item) => {
      return item.id === headId;
    });
    if (filtered) {
      return filtered.head;
    } else {
      return "";
    }
  };

  const getContractName = (contractId) => {
    const filtered = contractList.find((item) => {
      return item.id === contractId;
    });
    if (filtered) {
      return filtered.name;
    } else {
      return "";
    }
  };

  const getCostCenterName = (centerId) => {
    const filtered = costCenterList.find((item) => {
      return item.id === centerId;
    });
    if (filtered) {
      return filtered.name;
    } else {
      return "";
    }
  };
  const getBillToShipToName = (billToShiptoId) => {
    const filtered = billToShipToList.find((item) => {
      return item.id === billToShiptoId;
    });
    if (filtered) {
      return filtered.unitName;
    } else {
      return "";
    }
  };

  const handleSubmitAddCondition = (data) => {
    console.log("condition data=", data);
    console.log(vendorTAC);
    if (data.slNo <= 0) {
      toast.error(`Please Enter SlNo.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (data.termsConditionsType === "") {
      toast.error(`Please Enter Terms & Conditions Type.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (data.heading === "") {
      toast.error(`Please Enter Terms & Conditions Heading.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (data.condition === "") {
      toast.error(`Please Enter Terms & Conditions Body.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (editTAC === true) {
        let index = -1;
        for (let i = 0; i < vendorTAC.length; i++) {
          if (
            vendorTAC[i].slNo === editTACData.slNo &&
            vendorTAC[i].termsConditions === editTACData.termsConditions &&
            vendorTAC[i].termsConditionsHeading ===
              editTACData.termsConditionsHeading
          ) {
            index = i;
            console.log("element  found", index);
            break;
          }
        }
        if (index !== -1) {
          let temp = vendorTAC;
          temp[index].slNo = data.slNo;
          temp[index].termsConditionsHeading = data.termsConditionsHeading;
          temp[index].termsConditions = data.termsConditions;
          temp[index].addedByVendor = "Y";
          temp[index].editedTermsConditions = null;
          temp[index].acceptRejectFlag = "N";
          setVendorTAC([...temp]);
        }
        index = -1;
        for (let i = 0; i < termsAndConditionList.length; i++) {
          if (
            termsAndConditionList[i].slNo === editTACData.slNo &&
            termsAndConditionList[i].termsConditions ===
              editTACData.termsConditions &&
            termsAndConditionList[i].termsConditionsHeading ===
              editTACData.termsConditionsHeading
          ) {
            index = i;
            console.log("element  found tac list", index);
            break;
          }
        }
        if (index !== -1) {
          let temp = termsAndConditionList;
          temp[index].slNo = data.slNo;
          temp[index].addedByVendor = "Y";
          temp[index].termsConditionsHeading = data.termsConditionsHeading;
          temp[index].termsConditions = data.termsConditions;
          setTermsAndConditionList([...temp]);
        }
        setShowAddTAC(false);
        setEditTAC(false);
        setEditTACData(undefined);
        return;
      }

      const reqDto = {
        id: 0,
        addedByVendor: "Y",
        slNo: data.slNo,
        termsConditionsHeading: data.heading,
        termsConditions: data.condition,
        termsConditionsType: data.termsConditionsType,
        editedTermsConditions: null,
        acceptRejectFlag: "N",
        // transType: "RFP",
      };
      setVendorTAC((prev) => [...prev, reqDto]);

      const tmpTacList = [...termsAndConditionList, ...[reqDto]];
      setTermsAndConditionList(tmpTacList);
      // setOrgTacList(tmpTacList);
      // setSelectedConditionList([...selectedConditionList, ...[reqDto]]);
      // setDefaultSlNo(tmpTacList.length + 1);
      setValue("slNo", tmpTacList.length + 1);
      setValue("termsConditionsHeading", "");
      setValue("termsConditions", "");
      setValue("termsConditionsType", "");
      // reset({
      //   slNo: tmpTacList.length + 1,
      //   termsConditionsHeading: "",
      //   termsConditions: "",
      // });
      // setShowAddDialog(false);
      setShowAddTAC(false);
    }
  };

  const handleChangeAutoCompleteInputValue = (event, newInputValue) => {
    setAutocompleteInputValue(newInputValue);
  };

  const [ledgerHeadList, setLedgerHeadList] = useState([]);
  // const [filteredBudgetHeadList, setFilteredBudgetHeadList] = useState([]);
  // useEffect(() => {
  //   if (ceoe === "CapitalExpenditure") {
  //     setFilteredBudgetHeadList(
  //       ledgerHeadList.filter((item) => item.category === "Capital Expense")
  //     );
  //   } else {
  //     setFilteredBudgetHeadList(
  //       ledgerHeadList.filter((item) => item.category === "Operating Expense")
  //     );
  //   }
  // }, [ceoe, ledgerHeadList]);
  const filteredBudgetHeadList = useMemo(() => {
    console.log("ceoe value from filtered = ", ceoe);

    if (ceoe === "CapitalExpenditure") {
      return ledgerHeadList.filter(
        (item) => item.category === "Capital Expense"
      );
    } else {
      return ledgerHeadList.filter(
        (item) => item.category === "Operating Expense"
      );
    }
  }, [ledgerHeadList, ceoe]);
  const [showPreview, setShowPreview] = useState(false);
  const [formData, setFormData] = useState(null);
  const [DATA_data, setDATAdata] = useState(null);
  const [reimbursementList, setReimbursementList] = useState([]);
  const [manpowerList, setManpowerList] = useState([]);
  const [statutoryList, setStatutoryList] = useState([]);

  const fetchLedgerHeadData = () => {
    TaskService.getBudgetHeads(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        console.log("fetchLedgerHeadData", response.data);
        setLedgerHeadList(response.data);
      }
    });
  };

  const [contractList, setContractList] = useState([]);
  const recurringPeriodList = [
    "Daily",
    "Weekly",
    "Monthly",
    "Quarterly",
    "Yearly",
  ];

  const [tableDataList, setTableDataList] = useState([]);

  const [expectedDate, setExpectedDate] = useState(new Date());
  const [repeatUntil, setRepeatUntill] = useState(new Date());

  const [positionList, setPositionList] = useState([]);

  const [costCenterList, setCostCenterList] = useState([]);
  const [billToShipToList, setBillToShipToList] = useState([]);

  // only number start

  const initializeForm = () => {
    // setValue("ExpenditureGoods", "goods");
  };

  const getNumberFromString = (numString) => {
    const stringList = numString.split("");
    let num = "";
    stringList.forEach((element) => {
      num += element;
    });
    return parseInt(num);
  };

  // const getNumFromStr = (numString) => {
  //   if (typeof numString === "number") {
  //     return numString;
  //   }
  //   const stringList = numString.split(",");
  //   let num = "";
  //   stringList.forEach((element) => {
  //     num += element;
  //   });
  //   return parseInt(num);
  // };

  const formReqDto = () => {
    const data = getValues();
    const {
      purpose,
      expenditureType,
      ExpenditureGoods,
      contract,
      costCenter,
      billTo,
      shipTo,
      ProcessDirect,
      recurringPeriod,
      ceoe,
      serviceAcceptingPerson,
      directPurchaseReason,
      advance,
      expectedCost,
      budgetHead,
    } = data;

    let serviceGoods = [];
    let manPowers = [];
    let sips = [];
    let serviceGoodsReim = [];
    let daTaObject = {};
    let totalAmount = 0;
    if (
      expenditureType !== "Reimbursement" &&
      (ExpenditureGoods === "goods" || ExpenditureGoods === "services")
    ) {
      serviceGoods = tableDataList.map((tableItem) => {
        totalAmount += parseInt(tableItem.estimatedValue);
        return {
          itemId: tableItem.itemId,
          uom: tableItem.uom,
          indentQty: tableItem.indentQty,
          currentStk: tableItem.currentStk,
          prePurshaseRate: tableItem.prePurshaseRate,
          estimatedRate: parseInt(tableItem.estimatedRate),
          estimatedValue: parseInt(tableItem.estimatedValue),
          itemDescription: tableItem.itemDescription,
          budgetHeadId: tableItem.purpose.id,
          itemName: tableItem.itemName,
          budgetHeadName: tableItem.purpose.head,
          expenseDtlId: 0,
        };
      });
    }

    if (ExpenditureGoods === "Manpower") {
      manPowers = manpowerList.map((man) => {
        const maxCtc = getNumberFromString(man.maxCtc);
        const units = getNumberFromString(man.units);
        const estimatedValue = maxCtc * units;
        totalAmount += estimatedValue;
        return {
          positionId: man.position.positionId,
          appointmentNature: man.appoinmentNature,
          noOfPosition: units,
          maxCtc: maxCtc,
          estimatedValue: estimatedValue,
          jdKra: man.jdKra,
          budgetHeadId: man.budgetHead.id,
        };
      });
    }

    if (ExpenditureGoods === "StatutoryInsurancePayments") {
      sips = statutoryList.map((sItem) => {
        totalAmount += parseInt(sItem.amount);
        return {
          type: sItem.type.name,
          description: sItem.description,
          dueDate: dayjs(sItem.dueDate).format("DD-MM-YYYY"),
          sipFileName: (sItem.document && sItem.document.fileName) || "",
          sipFileDataLink:
            (sItem.document && sItem.document.fileDataLink) || "",
          amount: parseInt(sItem.amount),
          budgetHeadId: sItem.budgetHead.id,
          budgetHeadName: sItem.budgetHead.head || "",
          expenseDtlId: 0,
        };
      });
    }

    if (
      expenditureType === "Reimbursement" &&
      (ExpenditureGoods === "goods" || ExpenditureGoods === "services")
    ) {
      serviceGoodsReim = reimbursementList.map((reimbursement) => {
        totalAmount += parseInt(reimbursement.total);
        return {
          srnGrnNo: reimbursement.grn,
          reason: reimbursement.reason,
          invoceRcptNo: reimbursement.invoiceRcptNo,
          invoceRcptDt: dayjs(reimbursement.completionDate).format(
            "DD-MM-YYYY"
          ),
          fileName:
            (reimbursement.document && reimbursement.document.fileName) || "",
          fileDataLink:
            (reimbursement.document && reimbursement.document.fileDataLink) ||
            "",
          taxAmount: reimbursement.tax,
          totalAmount: reimbursement.total,
          description: reimbursement.itemDescriptions,
          budgetHeadId: reimbursement.budgetHead.id || 0,
          budgetHeadName: reimbursement.budgetHead.head || "",
          expenseDtlId: 0,
        };
      });
    }
    daTaObject = {
      daTaTravelDateStart: "",
      daTaTravelDateEnd: "",
      // daTaBudgetHeadId: 0,
      daTaTransportCosts: 0,
      daTaAccommodationCosts: 0,
      daTaMealCosts: 0,
      daTaOtherExpensesAmt: 0,
      daTaTotalCost: 0,
      daTaAdvanceReceived: 0,
      taDaSupportiveDocuments: [],
      claimOnActalRate: "",
      claimOnActalBasis: "",
    };

    if (ExpenditureGoods === "DA/TA") {
      daTaObject = {
        daTaTravelDateStart:
          dayjs(DATA_data.startDate).format("DD-MM-YYYY") || "",
        daTaTravelDateEnd: dayjs(DATA_data.endDat).format("DD-MM-YYYY") || "",
        // daTaBudgetHeadId: DATA_data.budgetHead.id || 0,
        daTaTransportCosts: parseInt(DATA_data.transportCost) || 0,
        daTaAccommodationCosts: parseInt(DATA_data.accomodationCost) || 0,
        daTaMealCosts: parseInt(DATA_data.mealCost) || 0,
        daTaOtherExpensesAmt: parseInt(DATA_data.otherExpenses),
        daTaTotalCost: DATA_data.totalExpenditure,
        daTaAdvanceReceived: parseInt(DATA_data.advanceReceived),
        taDaSupportiveDocuments: DATA_data.documents.map((docmnt) => ({
          fileName: docmnt.fileName,
          fileData: docmnt.fileDataLink,
        })),
        claimOnActalRate: DATA_data.claimOnActalRate ? "Y" : "N",
        claimOnActalBasis: DATA_data.claimOnActalBasis ? "Y" : "N",
      };
    }

    let reqDto = {
      expenseCategory: expenditureType,
      expenseType: ExpenditureGoods,
      capexOpex: ceoe,
      process: ProcessDirect === "Process" ? "Y" : "N",
      directReason: directPurchaseReason || "",
      purpose,
      contractId: contract || 0,
      costCenter: parseInt(costCenter) || 0,
      billTo: parseInt(billTo) || 0,
      shipTo: parseInt(shipTo) || 0,
      serviceReceivingPosition:
        expenditureType !== "Reimbursement" && ExpenditureGoods === "services"
          ? serviceAcceptingPerson.positionId
          : 0,
      scheduledDate: dayjs(expectedDate).format("DD-MM-YYYY"),
      estimatedCost: totalAmount,
      recurring: recurring ? "Y" : "N",
      recurringPeriod: !recurring ? "" : recurringPeriod,
      recurringUntil: !recurring ? "" : dayjs(repeatUntil).format("DD-MM-YYYY"),
      recurringDocumentFileName:
        recurring && recurringDocument ? recurringDocument.fileName : "",
      recurringDocumentFileData:
        recurring && recurringDocument ? recurringDocument.fileDataLink : "",
      serviceGoods: serviceGoods,

      manPowers: manPowers,
      sips: sips,
      serviceGoodsReim: serviceGoodsReim,
      advanceAmount: (advance && getNumFromStr(advance)) || 0,
      budgetHeadId:
        budgetHead ||
        (DATA_data && DATA_data.budgetHead && DATA_data.budgetHead.id) ||
        0,
      budgetHeadName:
        (budgetHead && getBudgetHeadName(budgetHead)) ||
        (DATA_data && DATA_data.budgetHead && DATA_data.budgetHead.head) ||
        "",
      advanceEstimatedCost: (expectedCost && getNumFromStr(expectedCost)) || 0,
      // expenseApprover: expenseApprover.userId,

      contractName: getContractName(contract),
      costCenterName: getCostCenterName(costCenter),
      billToName: getBillToShipToName(parseInt(billTo)),
      shipToName: getBillToShipToName(parseInt(shipTo)),
      serviceReceivingPositionName: "string",
      approvedByName: "string",
      createdByName: loginUserDetail.userName,
      approvalRmks: "",
      expenseId: 0,
      expenseNo: "",
      requestTime: "",
      approvedById: "",
      createdById: loginUserDetail.userId,
      taskDetailId: 0,
      orgId: loginUserDetail.orgId,

      advancePaymentRequired: advancedChecked ? "Y" : "N",
      advancePaymentPc: advancedChecked ? advanceAmount : 0,
      vendorTermsConditions: termsAndConditionList,
      itemCharges: otherCharges,

      changesInStandardTermsConditions: "No",
      vendorId: selectedVendor ? selectedVendor.userId : "",
    };
    reqDto = { ...reqDto, ...daTaObject };
    // ;
    if (ExpenditureGoods === "advance") {
      reqDto.estimatedCost = getNumFromStr(expectedCost) || 0;
    }
    if (ExpenditureGoods === "DA/TA") {
      reqDto.estimatedCost = DATA_data.totalExpenditure;
    }

    // console.log("reqDto=", reqDto);

    return reqDto;
  };

  const handleClickSubmit = () => {
    const reqDto = formReqDto();
    reqDto.expenseApprover = expenseApprover.userId;
    console.log("reqDto", reqDto);
    // return;
    handleSubmitPurchaseForm(reqDto);
  };

  const handleShowTaCDialog = (e) => {
    if (!hasSeenTAC) {
      setSelectedConditionList([...orgTacList]);
    }

    if (selectedVendor) {
      // setShowTaCDialog({
      //   status: e.target.checked,
      //   idList: [userId],
      //   names: userName,
      // });
      setTacModalCheck(e.target.checked);
      setShowTermsAndCondDialog(e.target.checked);

      setHasSeenTAC(true);
    } else {
      toast.error(`Please Select Vendor(s)`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    initializeForm();
    TaskService.getContracts(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        setContractList(response.data);
        setValue("contract", contract);
      }
    });

    TaskService.getCostCenterOfOrg(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        const userCostCenter = localStorage.getItem("COST_CENTER_ID");
        console.log("user cost center", typeof userCostCenter);
        if (userCostCenter !== "0") {
          setValue("costCenter", userCostCenter);
        }
        setCostCenterList(response.data);
      }
    });
    TaskService.getOrgAddresses(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        setBillToShipToList(response.data);
      }
    });

    getPositionsOfOrganization(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        setPositionList(response.data);
      }
    });

    fetchLedgerHeadData();
    const costCenterValue = localStorage.getItem("COST_CENTER_ID");
    if (costCenterValue != null || costCenterValue !== undefined) {
      setValue("costCenter", costCenterValue);
      // setRerender((prev) => prev + 1);
    }
  }, []);

  const validateManPower = (data) => {
    if (purpose.trim() === "") {
      toast.error("Purpose Cannot be Empty");
      return false;
    } else if (
      data.costCenter === "" ||
      data.costCenter === null ||
      data.costCenter === 0
    ) {
      toast.error("Please Select Cost Center");
      return false;
    }
    if (manpowerList && manpowerList.length === 0) {
      toast.error("Please Provide Atleast One Manpower Data");
      return false;
    } else {
      return true;
    }
  };

  const validateStatutory = (data) => {
    if (purpose.trim() === "") {
      toast.error("Purpose Cannot be Empty");
      return false;
    } else if (
      data.costCenter === "" ||
      data.costCenter === null ||
      data.costCenter === 0
    ) {
      toast.error("Please Select Cost Center");
      return false;
    }
    if (statutoryList && statutoryList.length === 0) {
      toast.error("Please Provide Atleast One Manpower Data");
      return false;
    } else {
      return true;
    }
  };

  const validateReimbursement = (data) => {
    if (purpose.trim() === "") {
      toast.error("Purpose Cannot be Empty");
      return false;
    } else if (
      data.costCenter === "" ||
      data.costCenter === null ||
      data.costCenter === 0
    ) {
      toast.error("Please Select Cost Center");
      return false;
    }
    if (reimbursementList && reimbursementList.length === 0) {
      toast.error("Please Provide Atleast One Reimbursement Data");
      return false;
    } else {
      return true;
    }
  };

  const validateDaTa = (data, DATA_data) => {
    const calculateTotal = () => {
      // ;
      const trCost = DATA_data.transportCost;
      const accCost = DATA_data.accomodationCost;
      const meCost = DATA_data.mealCost;
      const othrExpense = DATA_data.otherExpenses;
      return trCost + accCost + meCost + othrExpense - advanceAmount;
    };
    if (purpose.trim() === "") {
      toast.error("Purpose Cannot be Empty");
      return false;
    } else if (
      data.costCenter === "" ||
      data.costCenter === null ||
      data.costCenter === 0
    ) {
      toast.error("Please Select Cost Center");
      return false;
    }
    if (!DATA_data) {
      toast.error("Please fill the form properly");
      return false;
    } else if (parseInt(DATA_data.advanceReceived) > calculateTotal()) {
      toast.error("Advance Amount Cannot Be More Than Reimbursement Amount");
      return false;
    } else {
      return true;
    }
  };

  const validateForm = () => {
    const data = getValues();
    console.log("data =", data);
    if (data.ExpenditureGoods === "Manpower") {
      return validateManPower(data);
    }
    if (data.ExpenditureGoods === "StatutoryInsurancePayments") {
      return validateStatutory(data);
    }

    if (data.ExpenditureGoods === "advance") {
      // ;
      if (purpose.trim() === "") {
        toast.error("Purpose Cannot be Empty");
        return false;
      } else if (
        data.costCenter == "" ||
        data.costCenter == null ||
        data.costCenter == 0
      ) {
        toast.error("Please Select Cost Center");
        return false;
      } else if (data.advance === "" || data.advance == null) {
        toast.error("Please Provide Advance");
        return false;
      } else if (data.expectedCost === "" || data.expectedCost == null) {
        toast.error("Please Provide Expected Cost");
        return false;
      } else if (
        getNumFromStr(data.advance) > getNumFromStr(data.expectedCost)
      ) {
        toast.error("Advance Cannot Be More Than Expected Cost");
        return false;
      } else if (
        !data.budgetHead &&
        (data.budgetHead === "" || data.budgetHead == null)
      ) {
        toast.error("Please Provide budgetHead");
        return false;
      }
    }

    if (
      (data.ExpenditureGoods === "goods" ||
        data.ExpenditureGoods === "services") &&
      data.expenditureType === "Reimbursement"
    ) {
      return validateReimbursement(data);
    }

    if (
      data.ExpenditureGoods === "DA/TA" &&
      data.expenditureType === "Reimbursement"
    ) {
      return validateDaTa(data, DATA_data);
    }

    if (
      (data.ExpenditureGoods === "goods" ||
        data.ExpenditureGoods === "services") &&
      data.expenditureType !== "Reimbursement"
    ) {
      return validateGoodsAndServices(data);
    }
    if (purpose.trim() === "") {
      toast.error("Purpose Cannot be Empty");
      return false;
    } else if (recurring && repeatUntil === null) {
      toast.error("Recurring Until should not be empty");
      return false;
    } else if (recurring && recurringPeriod === null) {
      toast.error("Recurring period cannot be empty");
      return false;
    } else if (
      data.costCenter == "" ||
      data.costCenter == null ||
      data.costCenter == 0
    ) {
      toast.error("Please Select Cost Center");
      return false;
    } else {
      return true;
    }
  };

  const validateGoodsAndServices = (data) => {
    console.log("data =", data);
    let isValidForm = true;
    if (!data.purpose || data.purpose === "") {
      toast.error("Please Enter Purpose");
      isValidForm = false;
    }
    if (!data.costCenter || data.costCenter === "0") {
      toast.error("Please Select Cost Center");
      isValidForm = false;
    }
    if (data.ExpenditureGoods === "goods") {
      if (!data.billTo) {
        toast.error("Please Select Bill To");
        isValidForm = false;
      }
      if (!data.shipTo) {
        toast.error("Please Select Ship To");
        isValidForm = false;
      }
    }

    if (recurring) {
      if (data.recurringPeriod === "") {
        toast.error("Please Select Recurring Period");
        isValidForm = false;
      }
    }

    if (recurring) {
      if (recurringDocument === null) {
        toast.error("Please Select Recurring Document");
        isValidForm = false;
      }
    }

    if (tableDataList.length === 0) {
      toast.error("Please Insert Atleast One Item");
      isValidForm = false;
    }
    if (isValidForm) {
      return isValidForm;
    }
  };

  const fetchOrgTermsAndConditionData = () => {
    TaskService.getOrgTermsAndConditions(loginUserDetail.orgId, "RFP").then(
      (response) => {
        if (response.data) {
          const tmpCondList = response.data.map((item) => ({
            ...item,
            editedTermsConditions: null,
            acceptRejectFlag: "N",
            addedByVendor: "N",
          }));
          setOrgTacList(tmpCondList);
          setSelectedConditionList(tmpCondList);
          setTermsAndConditionList(tmpCondList);
        }
      }
    );
  };

  useEffect(() => {
    if (ProcessDirect === "Direct" && vendorList.length === 0) {
      TaskService.getVendorOfOrg(loginUserDetail.orgId).then((response) => {
        if (response.data) {
          setVendorList(response.data);
        }
      });

      getStaticDDOfOrg(loginUserDetail.orgId, "TERMS_CONDITIONS_TYPE").then(
        (response) => {
          if (response) {
            setTypeList(response.data);
          } else {
            toast.error(
              "Did not found Terms And Conditions Types for This Organization"
            );
          }
        }
      );

      fetchOrgTermsAndConditionData();
    }
  }, [ProcessDirect]);

  return (
    <>
      <div className="flotHeaderPanel">
        <div class="flotHedCon">Expenditure Approval Request</div>
        <div class="flotActionBtnGrp">
          <div class="flotActBtn closeBtnD10">
            <Tooltip
              arrow
              title={isExpanded ? "Exit Full Screen" : "Enter Full Screen"}
            >
              <IconButton
                onClick={(e) => {
                  if (isExpanded) {
                    setIsExpanded(false);
                  } else {
                    setIsExpanded(true);
                  }
                }}
              >
                {isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                {/* <Expand /> */}
              </IconButton>
            </Tooltip>
          </div>
          <div class="flotActBtn closeBtnD10">
            <IconButton onClick={handleClose}>
              <XLg />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="flotContain">
        {!showPreview && (
          <div className="elementFormContainer ">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <FormLabel id="Expenditure_Type" className="radioLabel">
              Expenditure Type
            </FormLabel> */}

                <Controller
                  name="expenditureType"
                  control={control}
                  defaultValue={"ProposedExpenditure"}
                  render={({ field }) => {
                    const handleRadioChange = (e) => {
                      field.onChange(e.target.value);
                      setValue("ExpenditureGoods", "goods");
                    };
                    return (
                      <RadioGroup
                        {...field}
                        className="formRadioGroup"
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          className="formRadioField"
                          value="ProposedExpenditure"
                          control={<Radio />}
                          label="Proposed Expenditure"
                        />
                        <FormControlLabel
                          className="formRadioField"
                          value="Reimbursement"
                          control={<Radio />}
                          label="Reimbursement"
                        />
                      </RadioGroup>
                    );
                  }}
                />

                {/* <RadioGroup
                className="formRadioGroup"
                labelId="Expenditure_Type"
                name="Expenditure"
              >
                <FormControlLabel
                  className="formRadioField"
                  value="ProposedExpenditure"
                  control={<Radio checked />}
                  label="Proposed Expenditure"
                />
                <FormControlLabel
                  className="formRadioField"
                  value="Reimbursement"
                  control={<Radio />}
                  label="Reimbursement (For expenses already Done) "
                />
              </RadioGroup> */}
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <Controller
                  name="ExpenditureGoods"
                  control={control}
                  defaultValue={"goods"}
                  render={({ field }) => (
                    <RadioGroup className="formRadioGroup" {...field}>
                      <FormControlLabel
                        className="formRadioField"
                        value="goods"
                        control={<Radio />}
                        label="Goods"
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="services"
                        control={<Radio />}
                        label="Services"
                      />
                      {expenditureType !== "Reimbursement" && (
                        <FormControlLabel
                          className="formRadioField"
                          value="Manpower"
                          control={<Radio />}
                          label="Manpower"
                        />
                      )}

                      {expenditureType !== "Reimbursement" && (
                        <FormControlLabel
                          className="formRadioField"
                          value="StatutoryInsurancePayments"
                          control={<Radio />}
                          label="Statutory & Insurance Payments"
                        />
                      )}
                      {expenditureType === "Reimbursement" && (
                        <FormControlLabel
                          className="formRadioField"
                          value="DA/TA"
                          control={<Radio />}
                          label="DA/TA"
                        />
                      )}
                      {expenditureType !== "Reimbursement" && (
                        <FormControlLabel
                          className="formRadioField"
                          value="advance"
                          control={<Radio />}
                          label="Advance"
                        />
                      )}
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <Controller
                  name="ceoe"
                  control={control}
                  defaultValue={"OperatingExpenditure"}
                  render={({ field }) => (
                    <RadioGroup
                      className="formRadioGroup"
                      name="ceoe"
                      {...field}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="CapitalExpenditure"
                        control={<Radio />}
                        label="Capital Expenditure"
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="OperatingExpenditure"
                        control={<Radio />}
                        label="Operating Expenditure "
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </div>

            {/* {ExpenditureGoods !== "Manpower" && (
              <div className="formElement">
                <FormControl className="formControl">
                  <Controller
                    name="Tangible"
                    control={control}
                    defaultValue={"Tangible"}
                    render={({ field }) => (
                      <RadioGroup className="formRadioGroup" {...field}>
                        <FormControlLabel
                          className="formRadioField"
                          value="Tangible"
                          control={<Radio />}
                          label="Tangible"
                        />
                        <FormControlLabel
                          className="formRadioField"
                          value="Intangible"
                          control={<Radio />}
                          label="Intangible"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </div>
            )} */}

            {expenditureType !== "Reimbursement" &&
              ExpenditureGoods !== "Manpower" &&
              ExpenditureGoods !== "StatutoryInsurancePayments" &&
              ExpenditureGoods !== "advance" && (
                <>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <Controller
                        name="ProcessDirect"
                        control={control}
                        defaultValue={"Process"}
                        render={({ field }) => {
                          const { onChange, ...fields } = field;
                          return (
                            <RadioGroup
                              className="formRadioGroup"
                              {...fields}
                              onChange={(e) => {
                                console.log("onchange is =", e.target.value);
                                setRecurring(false);
                                setValue("recurringPeriod", "");
                                setRepeatUntill(new Date());
                                setRecurringDocument(null);
                                onChange(e);
                              }}
                            >
                              <FormControlLabel
                                className="formRadioField"
                                value="Process"
                                control={<Radio />}
                                label="Process"
                              />
                              <FormControlLabel
                                className="formRadioField"
                                value="Direct"
                                control={<Radio />}
                                label="Direct"
                              />
                            </RadioGroup>
                          );
                        }}
                      />
                    </FormControl>
                  </div>

                  {ProcessDirect === "Direct" && (
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label="Reason for purchasing directly"
                          variant="outlined"
                          className="formTextFieldArea"
                          {...register("directPurchaseReason")}
                        />
                      </FormControl>
                    </div>
                  )}
                  {/* {ProcessDirect === "Direct" && (
                    <div className="formElement">
                      <FormControl className="formControl">
                        <FormGroup className="formRadioGroup">
                          <Controller
                            name="isAdvanceNeeded"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                              <FormControlLabel
                                className="formRadioField"
                                control={<Checkbox {...field} />}
                                label="Is Advance"
                              />
                            )}
                          />
                        </FormGroup>
                      </FormControl>
                    </div>
                  )} */}
                </>
              )}

            {expenditureType !== "Reimbursement " &&
              ExpenditureGoods !== "DA/TA" &&
              ExpenditureGoods !== "Manpower" &&
              ProcessDirect !== "Direct" &&
              ExpenditureGoods !== "advance" && (
                <div className="multiFormGroup">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <FormGroup className="formRadioGroup">
                        {/* <Controller
                          name="recurring"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <FormControlLabel
                              className="formRadioField"
                              control={
                                <Checkbox {...field} checked={recurring} />
                              }
                              label="Recurring Expenditure"
                            />
                          )}
                        /> */}
                        <FormControlLabel
                          className="formRadioField"
                          control={
                            <Checkbox
                              checked={recurring}
                              onChange={(e) => {
                                setRecurring(e.target.checked);
                              }}
                            />
                          }
                          label="Recurring Expenditure"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                  {recurring === true && (
                    <div className="formElementGroup">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="selectPriority">
                            Recurring Period
                          </InputLabel>
                          <Controller
                            name="recurringPeriod"
                            control={control}
                            defaultValue={""}
                            render={({ field }) => (
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectPriority"
                                id="priority-select"
                                {...field}
                              >
                                {recurringPeriodList &&
                                  recurringPeriodList.map((period) => {
                                    return (
                                      <MenuItem value={period}>
                                        {period}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </div>

                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel
                            id="setCompletionDate"
                            className="setCompletionDate"
                          >
                            Repeat Until Date*
                          </InputLabel>
                          <ReactDatePicker
                            toggleCalendarOnIconClick
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            showMonthDropdown
                            showIcon
                            labelId="setCompletionDate"
                            className="formDatePicker"
                            minDate={new Date()}
                            dateFormat="dd/MMM/yyyy"
                            selected={repeatUntil}
                            onChange={(date) => setRepeatUntill(date)}
                          />
                        </FormControl>
                      </div>

                      <div className="formBtnElement">
                        <Button
                          variant="outlined"
                          startIcon={<AttachFile />}
                          className="comBtnOutline"
                          onClick={(e) => {
                            recurringDocumentRef.current.click();
                          }}
                        >
                          Document
                        </Button>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          id="fileInput"
                          ref={recurringDocumentRef}
                          onChange={async (e) => {
                            const files = e.target.files;
                            console.log("files are", files);
                            const data = await getFileDataObj(files[0]);
                            console.log("data=", data);
                            setRecurringDocument(data);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  autoComplete="off"
                  label="Purpose*"
                  variant="outlined"
                  className="descptionTextFild"
                  multiline
                  maxRows={3}
                  {...register("purpose")}
                />
              </FormControl>
            </div>

            <div className="formElementGroup flex-wrap">
              <div className="formElement width13">
                <FormControl className="formControl">
                  <InputLabel id="selectContract">Contract(if any)</InputLabel>
                  <Controller
                    name="contract"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectContract"
                        {...field}
                        label=" Contract(if any)"
                      >
                        <MenuItem value={""}> Select Contract </MenuItem>
                        {contractList.map((value) => {
                          return (
                            <MenuItem value={value.id}>{value.name}</MenuItem>
                          );
                        })}
                        {/* <MenuItem value={"miniontek"}>Miniontek</MenuItem>
                         */}
                      </Select>
                    )}
                  />
                  {/* <Select
                    className="formInputField"
                    variant="outlined"
                    labelId="selectContract"
                    id="select-Contract"
                    value={showContract}
                    label="Contract"
                    onChange={handlesetContract}
                  >
                    <MenuItem value={"miniontek"}>Miniontek</MenuItem>
                    <MenuItem value={"zoyel"}> Zoyel </MenuItem>
                  </Select> */}
                </FormControl>
              </div>

              <div className="formElement width13 ">
                <FormControl className="formControl">
                  <InputLabel id="selectcostCenter">Cost Center*</InputLabel>
                  <Controller
                    name="costCenter"
                    control={control}
                    defaultValue={""}
                    // value={costCenter}
                    render={({ field }) => (
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectcostCenter"
                        id="select-CostCenter"
                        {...field}
                        label="Cost Center*"
                      >
                        {costCenterList.length > 0 &&
                          costCenterList.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </div>

              {/* {ExpenditureGoods !== "DA/TA"} */}

              {ExpenditureGoods == "goods" &&
                expenditureType !== "Reimbursement" && (
                  <div className="formElement width13">
                    <FormControl className="formControl">
                      <InputLabel id="selectbillto">Bill To *</InputLabel>
                      <Controller
                        name="billTo"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <Select
                            className="formInputField"
                            variant="outlined"
                            labelId="selectbillto"
                            id="select-selectbillto"
                            {...field}
                            label="Bill To *"
                          >
                            {billToShipToList.length > 0 &&
                              billToShipToList.map((item) => (
                                <MenuItem value={item.id} key={item.id}>
                                  {`${item.unitName}, ${item.buildingNameNo}, ${item.city}`}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </div>
                )}

              {ExpenditureGoods == "goods" &&
                expenditureType !== "Reimbursement" && (
                  <div className="formElement width13">
                    <FormControl className="formControl">
                      <InputLabel id="selectshipto">
                        {ExpenditureGoods === "goods"
                          ? `Ship To *`
                          : ExpenditureGoods === "services"
                          ? "Service Accepting Position"
                          : ""}
                      </InputLabel>
                      <Controller
                        name="shipTo"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <Select
                            className="formInputField"
                            variant="outlined"
                            labelId="selectshipto"
                            id="select-selectshipto"
                            {...field}
                            label="Ship To *"
                          >
                            {billToShipToList.length > 0 &&
                              billToShipToList.map((item) => (
                                <MenuItem value={item.id} key={item.id}>
                                  {`${item.unitName}, ${item.buildingNameNo}, ${item.city}`}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </div>
                )}

              {ExpenditureGoods == "services" &&
                expenditureType !== "Reimbursement" && (
                  <div className="formElement width13">
                    <FormControl className="formControl">
                      <InputLabel id="selectshipto">
                        Service Accepting Person
                      </InputLabel>
                      <Controller
                        name="serviceAcceptingPerson"
                        control={control}
                        render={({ field }) => (
                          <Select
                            className="formInputField"
                            variant="outlined"
                            labelId="selectshipto"
                            id="select-selectshipto"
                            {...field}
                            label="Service Accepting Person"
                          >
                            {positionList &&
                              positionList.map((position) => (
                                <MenuItem value={position}>
                                  {position.position}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </div>
                )}

              {ExpenditureGoods !== "DA/TA" && (
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <InputLabel
                      id="setRepeatUntilDate"
                      className="setCompletionDate"
                    >
                      Target delivery date*
                    </InputLabel>
                    <ReactDatePicker
                      showIcon
                      labelId="setRepeatUntilDate"
                      className="formDatePicker"
                      minDate={new Date()}
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      dateFormat="dd/MMM/yyyy"
                      selected={expectedDate}
                      onChange={(date) => setExpectedDate(date)}
                    />
                  </FormControl>
                </div>
              )}
            </div>

            {ProcessDirect === "Direct" && (
              <>
                <div className="formElementGroup ">
                  <div className="formElement width13">
                    <FormControl className="formControl">
                      <Autocomplete
                        noOptionsText={"Please Type Recipient Name"}
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedVendor}
                        options={computedList}
                        getOptionLabel={(option) => option.userName}
                        inputValue={autocompleteInputValue}
                        onChange={(e, newValue) => {
                          console.log("new value = ", newValue);
                          setSelectedVendor(newValue);
                        }}
                        onInputChange={handleChangeAutoCompleteInputValue}
                        getOptionSelected={(option) => {
                          console.log("option is ", option);
                          return option.userId;
                        }}
                        renderOption={(props, option) => {
                          return (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {`${option.userName} ( ${
                                option.userDtl ? option.userDtl : ""
                              } )`}
                            </Box>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder={"Assign To Vendor"}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement width13">
                    <FormControl className="formControl">
                      <FormGroup className="formRadioGroup">
                        <FormControlLabel
                          className="formRadioField"
                          control={
                            <Checkbox
                              checked={tAcModalCheck}
                              onChange={(e) => {
                                if (e.target.checked === false) {
                                  setTacModalCheck(e.target.checked);
                                  // setShowTermsAndCondDialog(e.target.checked);
                                  return;
                                }
                                handleShowTaCDialog(e);
                              }}
                            />
                          }
                          label=" Terms And Condition"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>

                  {selectedVendor && (
                    <div className="formBtnElement width13">
                      <Button
                        className="dfultDarkSecondaryBtn "
                        onClick={() => {
                          console.log("payment Method Clicked");
                          setShowPaymentMehtod(true);
                        }}
                      >
                        Payment Method
                      </Button>
                    </div>
                  )}
                </div>

                <div className="formElementGroup ">
                  <div className="formElement width13">
                    <FormControl className="formControl">
                      <FormGroup className="formRadioGroup">
                        <FormControlLabel
                          className="formRadioField"
                          control={
                            <Checkbox
                              checked={advancedChecked}
                              onChange={(e) => {
                                setAdvanceChecked(e.target.checked);
                              }}
                            />
                          }
                          label=" Is Advance Needed?"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>

                  {advancedChecked && (
                    <div className="formElement width13">
                      <FormControl className="formControl">
                        <TextField
                          className="formTextFieldArea"
                          fullWidth
                          id="outlined-basic"
                          label="Advance Payment (in %)"
                          variant="outlined"
                          size="small"
                          value={advanceAmount}
                          onChange={(e) => {
                            const val = parseInt(e.target.value);
                            if (e.target.value === "") {
                              setAdvanceAmount("");
                              return;
                            }
                            if (isNaN(val)) {
                              console.log("in nan");
                              setAdvanceAmount("");
                            }
                            if (val < 100) {
                              setAdvanceAmount(val);
                            }
                          }}
                        />
                      </FormControl>
                    </div>
                  )}
                </div>
              </>
            )}

            {expenditureType !== "Reimbursement" &&
              (ExpenditureGoods === "goods" ||
                ExpenditureGoods === "services") && (
                <GoodsServicesForm
                  ExpenditureGoods={ExpenditureGoods}
                  ProcessDirect={ProcessDirect}
                  tableDataList={tableDataList}
                  setTableDataList={setTableDataList}
                  budgetHeadList={filteredBudgetHeadList}
                  otherCharges={otherCharges}
                  setOtherCharges={setOtherCharges}
                />
              )}
            {ExpenditureGoods === "DA/TA" && (
              <DaTaForm
                DATA_data={DATA_data}
                setDATAdata={setDATAdata}
                budgetHeadList={filteredBudgetHeadList}
              />
            )}

            {expenditureType === "Reimbursement" &&
              ExpenditureGoods !== "DA/TA" && (
                <ReimbursementForm
                  reimbursementList={reimbursementList}
                  setReimbursementList={setReimbursementList}
                  contractList={contractList}
                  costCenterList={costCenterList}
                  ExpenditureGoods={ExpenditureGoods}
                  budgetHeadList={filteredBudgetHeadList}
                />
              )}

            {ExpenditureGoods === "Manpower" && (
              <ManpowerForm
                manpowerList={manpowerList}
                setManpowerList={setManpowerList}
                budgetHeadList={filteredBudgetHeadList}
              />
            )}
            {ExpenditureGoods === "StatutoryInsurancePayments" && (
              <StatutoryAndInsurancePaymentForm
                statutoryList={statutoryList}
                setStatutoryList={setStatutoryList}
                budgetHeadList={filteredBudgetHeadList}
              />
            )}

            {ExpenditureGoods === "advance" && (
              <AdvanceForm
                budgetHeadList={filteredBudgetHeadList}
                register={register}
                control={control}
              />
            )}

            {/* <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label="Remarks"
                  variant="outlined"
                  className="descptionTextFild"
                  multiline
                  rows={3}
                  maxRows={5}
                  {...register("remarks")}
                ></TextField>
              </FormControl>
            </div> */}
          </div>
        )}

        {showPreview && (
          <FormPreview
            formData={formData}
            tableDataList={tableDataList}
            DATA_data={DATA_data}
            costCenterList={costCenterList}
            billToShipToList={billToShipToList}
            repeatUntil={repeatUntil}
            manpowerList={manpowerList}
            statutoryList={statutoryList}
            reimbursementList={reimbursementList}
            budgetHeadList={filteredBudgetHeadList}
          />
        )}
      </div>
      <div className="flotFooterPanel">
        <div className="formBtnElement">
          {!showPreview && (
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                const isValid = validateForm();
                if (isValid) {
                  getExpenseApprover(
                    loginUserDetail.userId,
                    loginUserDetail.orgId,
                    formReqDto()
                  ).then((response) => {
                    setExpenseApprover(response.data);
                    let allValues = getValues();
                    allValues.recurring = recurring;
                    allValues.recurringDocument = recurringDocument;
                    allValues.expenseApprover = response.data;
                    allValues.expectedDate = expectedDate;
                    setFormData(allValues);
                    setShowPreview(true);
                  });
                }
                // console.log("all field are ", getValues());
                // handleSubmit(onSubmit);
              }}
            >
              Preview
            </Button>
          )}

          {showPreview && (
            <Button className="dfultPrimaryBtn" onClick={handleClickSubmit}>
              Submit
            </Button>
          )}
          {!showPreview && (
            <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
              Cancel
            </Button>
          )}
          {showPreview && (
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={(e) => setShowPreview(false)}
            >
              Edit
            </Button>
          )}
        </div>
      </div>

      {showTermsAndCondDialog && (
        <CustomModal
          modalTitle="Terms And Conditions"
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowTermsAndCondDialog(false);
          }}
          className={"width90vwModel"}
        >
          <div className="modBody">
            <div className="modResptable">
              <table>
                <thead>
                  <tr>
                    <th className="minWidth160">Sl No.</th>
                    <th>Type</th>
                    <th>Heading</th>
                    <th>Terms And Condition</th>
                    <th>Accept Or Reject</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {termsAndConditionList &&
                    termsAndConditionList.map((condition, index) => (
                      <tr>
                        <td>{condition.slNo}</td>
                        <td>{condition.termsConditionsType}</td>
                        <td>{condition.termsConditionsHeading}</td>
                        <td>{condition.termsConditions}</td>

                        <td>
                          {!condition.accepted &&
                            condition.addedByVendor &&
                            condition.addedByVendor !== "Y" && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Tooltip title="Accept">
                                  <IconButton
                                    onClick={(e) => {
                                      console.log("clicked accepted value");
                                      const temp = [...termsAndConditionList];
                                      temp[index].accepted = "Y";
                                      setTermsAndConditionList(temp);
                                    }}
                                  >
                                    <Check />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Reject">
                                  <IconButton
                                    onClick={(e) => {
                                      // console.log("rejected ", props);
                                      setRejectionIndex(index);
                                      setShowAddRejectionCauseModal(true);
                                    }}
                                  >
                                    <CancelOutlined />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            )}
                        </td>

                        <td>
                          {condition.addedByVendor === "Y" && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Tooltip
                                title="Delete Terms And Conditions"
                                placement="left"
                              >
                                <Button
                                  onClick={() => {
                                    setDeleteTACData(condition);
                                    const temp = [...termsAndConditionList];
                                    temp.splice(index, 1);
                                    setTermsAndConditionList(temp);
                                    setShowDeletionConfirmationDialogue(true);
                                  }}
                                >
                                  <Trash />
                                </Button>
                              </Tooltip>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div>
              <Button
                onClick={() => {
                  setValue("slNo", termsAndConditionList.length + 1);
                  setValue("termsConditionsHeading", "");
                  setValue("termsConditions", "");
                  setEditTAC(false);
                  setEditTACData(undefined);
                  setShowAddTAC(true);
                }}
              >
                Add Vendor T & C
              </Button>
            </div>
          </div>
        </CustomModal>
      )}

      {showAddTAC && (
        <>
          <Box
            className="ModelBox "
            sx={{ boxShadow: 24, p: 4, zIndex: 10002 }}
          >
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Add Terms And Condition</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label="Sl No"
                      variant="outlined"
                      // multiline
                      // rows={3}
                      {...register("slNo")}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="setActivity">Type</InputLabel>
                    <Controller
                      name="termsConditionsType"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          className="modelSelectFild"
                          label="Activity"
                          variant="outlined"
                          labelId="setActivity"
                          id="activity-select"
                          {...field}
                        >
                          <MenuItem value={""}>{"--Select Type--"}</MenuItem>
                          {typeList &&
                            typeList.length > 0 &&
                            typeList.map((item) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                          {/* <MenuItem value={"Appointment"}>Appointment</MenuItem>
                      <MenuItem value={"Board Meeting"}>Board Meeting</MenuItem>
                      <MenuItem value={"Budget Requisition"}>
                        Budget Requisition
                      </MenuItem> */}
                        </Select>
                      )}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label="Heading"
                      variant="outlined"
                      // multiline
                      // rows={3}
                      {...register("heading")}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label="Condition"
                      variant="outlined"
                      // multiline
                      // rows={3}
                      {...register("condition")}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={handleSubmit(handleSubmitAddCondition)}
                >
                  Add
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowAddTAC(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        </>
      )}

      {showPaymentMehtod && (
        <VendorPaymentMethods
          closeModal={() => {
            setShowPaymentMehtod(false);
          }}
          selectedMethodId={selectedMethodId}
          setSelectedMethodId={setSelectedMethodId}
          vendorUserId={selectedVendor.userId}
        />
      )}
    </>
  );
}
