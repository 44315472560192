import { Add } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import {
  addCommittee,
  addRolesToCommittee,
  deactivateRole,
} from "../../../../services/AdminService";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

export default function CreateCommittee({
  handelCloseCrtCommittee,
  selectedCommittee,
  refreshCommitteeData,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [committeeData, setCommitteeData] = useState([]);

  const [committeeName, setCommitteeName] = useState("");
  const [addRoles, setAddRoles] = useState([]);
  const [newRole, setNewRole] = useState("");
  // const [refresh , setRefresh]= useState(1);

  useEffect(() => {
    if (selectedCommittee && selectedCommittee.roles) {
      const roleNames = selectedCommittee.roles.map((role) => role.roleName);
      setAddRoles(roleNames);
    }
  }, [selectedCommittee]);

  console.log("selectedCommittee", selectedCommittee);

  const handleCommitteeName = (e) => {
    console.log("handleCommitteeName", e.target.value);
    const value = e.target.value;
    if (selectedCommittee) {
      setCommitteeName(selectedCommittee);
    } else {
      setCommitteeName(value);
    }
  };

  // const handleAddRoles = () => {
  //   if (newRole.trim() !== "") {
  //     setAddRoles((prevRoles) => [...prevRoles, newRole]);
  //     setNewRole(""); // Clear the input field after adding the role
  //   }
  // };

  // useEffect(() => {
  //   getCommittees(userDetails.orgId)
  //     .then((response) => {
  //       console.log("getCommittees", response.data);
  //       setCommitteeData(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching units", error);
  //     });
  // }, [userDetails.orgId]);

  // useEffect(() => {
  //   fetchCommitteeData();
  // }, [userDetails.orgId]);

  // const fetchCommitteeData = () => {
  //   getCommittees(userDetails.orgId)
  //     .then((response) => {
  //       console.log("getCommittees", response.data);
  //       setCommitteeData(response.data);

  //       // Check if a selected committee is available
  //       if (selectedCommittee && selectedCommittee.committeeId) {
  //         const committeeId = selectedCommittee.committeeId;
  //         const selectedCommitteeData = response.data.find(
  //           (committee) => committee.committeeId === committeeId
  //         );

  //         if (selectedCommitteeData && selectedCommitteeData.roles) {
  //           const roleNames = selectedCommitteeData.roles.map(
  //             (role) => role.roleName
  //           );
  //           setAddRoles(roleNames);
  //           // setRefresh((prev) => prev + 1);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching units", error);
  //       // setLoading(false);
  //     });
  // };

  const handleAddRoles = () => {
    if (newRole.trim() === "") {
      toast.error("Please Enter A Role ", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setAddRoles((prevRoles) => [...prevRoles, newRole]);
    setNewRole("");
  };

  console.log("addRoles", addRoles);

  // const UpdateNewRoles = () => {

  //   console.log('selectedCommittee', selectedCommittee);

  //   if (selectedCommittee) {
  //     const committeeId = selectedCommittee.committeeId;
  //     const reqDto = addRoles;
  //     console.log("selectedCommittee committeeId", committeeId);
  //     console.log("selectedCommittee reqDto", reqDto);
  //     return ;
  //     addRolesToCommittee(committeeId, userDetails.userId, reqDto).then(
  //       (response) => {
  //         console.log("addRolesToCommittee response", response.data);
  //         if (response.data.returnValue === "1") {
  //           toast.success(response.data.message, {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //           // const newRoleData = response.data.role;
  //           // setAddRoles((prevRoles) => [...prevRoles, newRoleData]);
  //           // setAddRoles([]);
  //         }
  //         // setNewRole("");
  //       }
  //     );
  //   }

  // }

  const UpdateNewRoles = () => {
    if (selectedCommittee) {
      const committeeId = selectedCommittee.committeeId;

      const selectedRoles = selectedCommittee.roles.map(
        (role) => role.roleName
      );

      console.log("selectedRoles", selectedRoles);

      // console.log('addRoles', addRoles);

      const uniqueRoles = addRoles.filter(
        (role) => !selectedRoles.includes(role)
      );

      console.log("selectedCommittee committeeId", committeeId);
      // console.log("selectedCommittee reqDto", uniqueRoles);
      //  const reqDto = [...addRoles];

      //  console.log(' Update reqDto', reqDto)

      //  return ;

      addRolesToCommittee(committeeId, userDetails.userId, uniqueRoles)
        .then((response) => {
          console.log("addRolesToCommittee response", response.data);
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            // setAddRoles([]);
            refreshCommitteeData();
            handelCloseCrtCommittee();
          }
        })
        .catch((error) => {
          console.error("Error updating roles:", error);
        });
      refreshCommitteeData();
      handelCloseCrtCommittee();
    }
  };

  const handleRemoveRole = (index) => {
    console.log("index", index);
    setAddRoles((prevRoles) => prevRoles.filter((_, i) => i !== index));
  };


  // const handleDeleteSelectedCommittee = (role) => {
  //   console.log("roleId", role);
  //   deactivateRole(role.roleId, userDetails.userId).then((response) => {
  //     console.log("roleId deleted", response.data);
  //     if (response.data.returnValue === "1") {
  //       // toast.success(response.data.message, {
  //       //   position: toast.POSITION.TOP_RIGHT,
  //       // });
  //       setAddRoles((prevRoles) =>
  //         prevRoles.filter((r) => r !== role.roleName)
  //       );
  //       console.log("addRoles", addRoles);
  //     }
  //   });
  // };

  const handleDeleteSelectedCommittee = (role, index) => {
    if (role) {
      deactivateRole(role.roleId, userDetails.userId)
        .then((response) => {
          console.log("roleId deleted", response.data);
          if (response.data.returnValue === "1") {
            setAddRoles((prevRoles) =>
              prevRoles.filter((r) => r !== role.roleName)
            );
            console.log("addRoles", addRoles);
          }
        })
        .catch((error) => {
          console.error("Error deactivating role:", error);
        });
    } else {
      setAddRoles((prevRoles) => prevRoles.filter((_, i) => i !== index));
    }
  };
  

  
  

  // const handleDeleteSelectedCommittee = (role, index) => {
  //   console.log("role", role);
  //   if (role) { // Add null check for role and roleId
  //     deactivateRole(role.roleId, userDetails.userId).then((response) => {
  //       console.log("roleId deleted", response.data);
  //       if (response.data.returnValue === "1") {
  //         // Assuming role.roleName is unique and can be used for filtering
  //         setAddRoles((prevRoles) =>
  //           prevRoles.filter((r) => r.roleName !== role.roleName)
  //         );
  //       }
  //     }).catch((error) => {
  //       console.error("Error deactivating role:", error);
  //       // Handle error, maybe show a toast message or log it
  //     });
  //   } else {
  //     setAddRoles((prevRoles) => prevRoles.filter((_, i) => i !== index));
  //   }
  // };

  const finalSubmit = () => {
    setLoading(true);
    console.log("finalSubmit");

    if (committeeName.trim() === "") {
      toast.error("Please Enter Committee Name", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (addRoles.length === 0) {
      toast.error("Please add at least one role", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const reqDto = {
      committeeName: committeeName,
      roles: addRoles,
    };

    console.log("reqDto", reqDto);

    // return ;

    addCommittee(userDetails.orgId, userDetails.userId, reqDto).then(
      (response) => {
        console.log("addCommittee", response.data);
        setLoading(false);
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          refreshCommitteeData();
          setCommitteeName("");
          setAddRoles([]);
          setNewRole("");
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      }
    );
  };

  // useEffect(() => {
  //   if (selectedCommittee && selectedCommittee.committeeId) {
  //     const committeeId = selectedCommittee.committeeId;
  //     const selectedCommitteeData = committeeData.find(
  //       (committee) => committee.committeeId === committeeId
  //     );

  //     if (selectedCommitteeData && selectedCommitteeData.roles) {
  //       const roleNames = selectedCommitteeData.roles.map((role) => role.roleName);
  //       setAddRoles(roleNames);
  //       setRefresh(prev => prev+1);
  //     }
  //   }
  // }, [selectedCommittee, committeeData]);

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{selectedCommittee ? "Edit Committee" : "Add Committee"}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseCrtCommittee()}
          >
            <XLg />
          </IconButton>
        </div>
        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label="Committee  Name*"
                  variant="outlined"
                  className="formTextFieldArea"
                  value={selectedCommittee?.committeeName || committeeName}
                  onChange={handleCommitteeName}
                  disabled={selectedCommittee?.committeeName}
                />
              </FormControl>
            </div>
            <div className="formElement elementWithBtn">
              <FormControl className="formControl">
                <TextField
                  label="Add Roles"
                  variant="outlined"
                  className="formTextFieldArea"
                  value={newRole}
                  onChange={(e) => setNewRole(e.target.value)}
                />
              </FormControl>
              <Button
                className="dfultPrimaryBtn"
                startIcon={<Add />}
                onClick={handleAddRoles}
              >
                Add
              </Button>
            </div>

            <div class="elementListGrp">
              <div class="elementHeding">Roles Added</div>
              <ul class="elmListInfo">
                {addRoles.map((role, index) => (
                  <li key={index}>
                    <span className="rolsNm">{role}</span>
                    <IconButton
                      className="rmvBtn"
                      // onClick={() => handleRemoveRole(index)}
                      onClick={() =>
                        selectedCommittee
                        ? handleDeleteSelectedCommittee(selectedCommittee.roles[index], index)
                        : handleRemoveRole(index)
                    }
                    
                    >
                      <Trash />
                    </IconButton>
                  </li>
                ))}
                {/* <li>
                <span className="rolsNm">Chair Person</span>
                <IconButton className="rmvBtn">
                  <Trash />
                </IconButton>
              </li>
              <li>
                <span className="rolsNm">Treasurer</span>
                <IconButton className="rmvBtn">
                  <Trash />
                </IconButton>
              </li>
              <li>
                <span className="rolsNm">Secretary</span>
                <IconButton className="rmvBtn">
                  <Trash />
                </IconButton>
              </li>
              <li>
                <span className="rolsNm">Members</span>
                <IconButton className="rmvBtn">
                  <Trash />
                </IconButton>
              </li> */}
              </ul>
            </div>
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn"onClick={()=>finalSubmit()}>Save</Button> */}
            <Button
              className="dfultPrimaryBtn"
              onClick={() => {
                selectedCommittee ? UpdateNewRoles() : finalSubmit();
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>Submit</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseCrtCommittee()}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
