import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import {
  getCallingCodeList,
  createVendorPartner,
} from "../../../../services/AdminService";
import { validateEmail } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

export default function AddExternalUser({ handelCloseAddExternalUser , refreshExternalUserData , fetchDashBoardData}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [exUsrType, setExUsrType] = useState("");
  const [exUsrTitle, setExUsrTitle] = useState("");
  const [userFirsName, setUserFirsName] = useState("");
  const [userMiddleName, setUserMiddleName] = useState("");
  const [userlastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [empUsrPhnCC, setEmpUsrPhnCC] = useState("");
  const [cdCodeList, setCdCodeList] = useState([]);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userFaxNumber, setUserFaxNumber] = useState("");
  const [userGSTNumber, setUserGSTNumber] = useState("");
  const [phoneRegexPattern, setPhoneRegexPattern] = useState("");

  const handleChangeExUsrType = (event) => {
    console.log("handleChangeExUsrType", event.target.value);
    setExUsrType(event.target.value);
  };

  const handleChangeExUsrTitle = (event) => {
    console.log("handleChangeExUsrTitle", event.target.value);
    setExUsrTitle(event.target.value);
  };

  const handleChangeUserFirsName = (event) => {
    console.log("handleChangeUserFirsName", event.target.value);
    setUserFirsName(event.target.value);
  };

  const handleChangeUserMiddleName = (event) => {
    console.log("handleChangeUserMiddleName", event.target.value);
    setUserMiddleName(event.target.value);
  };

  const handleChangeUserLastName = (event) => {
    console.log("handleChangeUserLastName", event.target.value);
    setUserLastName(event.target.value);
  };

  const handleChangeUserEmail = (event) => {
    console.log("handleChangeUserEmail", event.target.value);
    setUserEmail(event.target.value);
  };

  useEffect(() => {
    getCallingCodeList().then((response) => {
      console.log("getCallingCodeList", response.data);
      setCdCodeList(response.data);
    });
  }, []);

  const handleChangeEmpUsrPhnCC = (event) => {
    console.log("handleChangeEmpUsrPhnCC", event.target.value);
    setEmpUsrPhnCC(event.target.value);
  
    switch (event.target.value) {
      case "+91": // India
        setPhoneRegexPattern(/^[6-9]\d{9}$/);
        break;
      case "+52": // Mexico
        setPhoneRegexPattern(/^[1-9]\d{9}$/);
        break;
      case "+971": // UAE
        setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
        break;
      default:
        setPhoneRegexPattern(/^[0-9]{10,}$/); // Default pattern for other countries
        break;
    }
  };

  const handleChangeUserPhoneNumber = (event) => {
    console.log("handleChangeUserPhoneNumber", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setUserPhoneNumber(numericValue);
  };

  const handleChangeUserAddress = (event) => {
    console.log("handleChangeUserAddress", event.target.value);
    setUserAddress(event.target.value);
  };

  const handleChangeUserFaxNumber = (event) => {
    console.log("handleChangeUserFaxNumber", event.target.value);
    setUserFaxNumber(event.target.value);
  };

  const handleChangeUserGSTNumber = (event) => {
    console.log("handleChangeUserGSTNumber", event.target.value);
    setUserGSTNumber(event.target.value);
  };

  const finalSubmit = () => {
    setLoading(true);
    console.log("finalSubmit");

    if (exUsrType.trim() === "") {
      toast.error("Please Select User Type", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (exUsrTitle.trim() === "") {
      toast.error("Please Select Title", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (userFirsName.trim() === "") {
      toast.error("Please Enter First Name", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (userlastName.trim() === "") {
      toast.error("Please Enter Last Name", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (userEmail.trim() === "") {
      toast.error("Please Enter Email Id", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (!validateEmail(userEmail)) {
      toast.error("Please check the Email Id", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (empUsrPhnCC.trim() === "") {
      toast.error("Please Select Country Code", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (userPhoneNumber.trim() === "") {
      toast.error("Please Enter Contact Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // if (userPhoneNumber.length > 10) {
    //   toast.error("Contact Number should be of 10 Digit", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    // if (!phoneRegexPattern.test(userPhoneNumber)) {
    //   toast.error("Please enter a valid Phone Number", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }
    
    if (!phoneRegexPattern.test(userPhoneNumber)) {
      toast.error(`Please enter a valid Phone Number for country code ${empUsrPhnCC}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
     }
    

    if (userAddress.trim() === "") {
      toast.error("Please Enter Address", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const reqDto = {
      title: exUsrTitle,
      firstName: userFirsName,
      middleName: userMiddleName,
      lastName: userlastName,
      officialEmailId: userEmail,
      contactNo: userPhoneNumber,
      category: exUsrType,
      faxNo: userFaxNumber,
      gstinTaxId: userGSTNumber,
      address: userAddress,
      countryCode: empUsrPhnCC
    };

    console.log("reqDto", reqDto);

    createVendorPartner(userDetails.userId, userDetails.orgId, reqDto).then(
      (response) => {
        console.log(response.data);
        if (response.data) {
          // success message
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchDashBoardData();
            refreshExternalUserData();
            setLoading(false);
            setExUsrType("");
            setExUsrTitle("");
            setUserFirsName("");
            setUserMiddleName("");
            setUserLastName("");
            setUserEmail("");
            setEmpUsrPhnCC("");
            setUserPhoneNumber("");
            setUserAddress("");
            setUserFaxNumber("");
            setUserGSTNumber("");
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        }
      }
    );
  };

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText"> {t("add_external_user")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddExternalUser()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            {loading ? (
              <div className="meetingVEContainer">
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              </div>
            ) : (
              <div className="rfContContainDetails">
                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="exUserType"> {t("select_user_type")}*</InputLabel>
                      <Select
                        labelId="exUserType"
                        id="exUserType"
                        value={exUsrType}
                        label="Select User Type"
                        onChange={handleChangeExUsrType}
                        className="formInputField"
                        variant="outlined"
                      >
                        <MenuItem value="vendor">{t("vendor")}</MenuItem>
                        <MenuItem value="partner">{t("partner")}</MenuItem>
                        <MenuItem value="Client">{t("client")}</MenuItem>
                        <MenuItem value="Adviser">{t("adviser")}</MenuItem>
                        <MenuItem value="BoardMember">{t("board_Member")}</MenuItem>
                        <MenuItem value="Consultant">{t("consultant")}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="formElementGrp">
                    <div className="formElement mxW-100">
                      <FormControl className="formControl">
                        <InputLabel id="extitle">{t("title")}*</InputLabel>
                        <Select
                          labelId="extitle"
                          id="exUserTitle"
                          value={exUsrTitle}
                          label= {t("title")}
                          onChange={handleChangeExUsrTitle}
                          className="formInputField"
                          variant="outlined"
                        >
                          <MenuItem value="Mr">{t("mr")}</MenuItem>
                          <MenuItem value="Ms">{t("ms")}</MenuItem>
                          <MenuItem value="Mrs">{t("mrs")}</MenuItem>
                          <MenuItem value="Miss">{t("miss")}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label= {t("first_name")}
                          required
                          placeholder= {t("first_name")}
                          variant="outlined"
                          value={userFirsName}
                          className="formTextFieldArea"
                          onChange={handleChangeUserFirsName}
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label= {t("middle_name")}
                          placeholder= {t("middle_name")}
                          variant="outlined"
                          value={userMiddleName}
                          className="formTextFieldArea"
                          onChange={handleChangeUserMiddleName}
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          required
                          label={t("last_name")}
                          placeholder={t("last_name")}
                          variant="outlined"
                          value={userlastName}
                          className="formTextFieldArea"
                          onChange={handleChangeUserLastName}
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div className="formElementGrp">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("official_email_id")}
                          required
                          placeholder={t("official_email_id")}
                          variant="outlined"
                          value={userEmail}
                          className="formTextFieldArea"
                          onChange={handleChangeUserEmail}
                        />
                      </FormControl>
                    </div>

                    <div className="formElement width120">
                      <FormControl className="formControl">
                        <InputLabel id="ccode">{t("country_code")}*</InputLabel>
                        <Select
                          labelId="ccode"
                          required
                          id="demo-simple-select"
                          value={empUsrPhnCC}
                          label={t("country_code")}
                          onChange={handleChangeEmpUsrPhnCC}
                          className="formInputField"
                          variant="outlined"
                        >
                          {/* <MenuItem value="91">+91</MenuItem>
                        <MenuItem value="1">+1</MenuItem>
                        <MenuItem value="374">+374</MenuItem> */}
                          {cdCodeList.map((cdCodeList) => (
                            <MenuItem key={cdCodeList} value={cdCodeList}>
                              {cdCodeList}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          required
                          label={t("phone_number")}
                          variant="outlined"
                          className="formTextFieldArea"
                          value={userPhoneNumber}
                          onChange={handleChangeUserPhoneNumber}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        // hiddenLabel
                        label={t("full_address")}
                        required
                        variant="outlined"
                        value={userAddress}
                        className="formTextFieldArea"
                        onChange={handleChangeUserAddress}
                      />
                    </FormControl>
                  </div>
                  <div className="formElementGrp">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("fax_number")}
                          variant="outlined"
                          value={userFaxNumber}
                          className="formTextFieldArea"
                          onChange={handleChangeUserFaxNumber}
                          inputProps={{ maxLength: 12 }}
                        />
                      </FormControl>
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("gst_number")}
                          variant="outlined"
                          value={userGSTNumber}
                          className="formTextFieldArea"
                          onChange={handleChangeUserGSTNumber}
                          inputProps={{ maxLength: 15 }}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => finalSubmit()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>{t("submit")}</>
                )}
              </Button>
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handelCloseAddExternalUser()}
                >
                  {t("cancel")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
