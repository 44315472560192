import {
  CalendarMonth,
  ChatBubble,
  ChatBubbleOutline,
  Email,
  EmailOutlined,
  Event,
  PlaylistAddOutlined,
  Schedule,
  Search,
  Storage,
  StorageOutlined,
  Videocam,
  VideocamOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Menu,
  Box,
  Popper,
  Paper,
  Popover,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Calendar,
  CalendarFill,
  List,
  ListTask,
  MenuApp,
} from "react-bootstrap-icons";
import { AddTabContext } from "../features/dashboard/view/DashboardPage";
import { v4 as uuidv4 } from "uuid";
import SchedulerContainer from "../features/scheduler/view/SchedulerContainer";
import { ConfirmProvider } from "material-ui-confirm";
import MeetingsTabContainer from "../features/scheduler/view/component/meetings/view/MeetingsTabContainer";
import DriveContainer from "../features/drive/view/DriveContainer";
import BottomMenuShortcut from "./BottomMenuShortcut";
import TaskTabContainer from "../features/task/view/TaskTabContainer";
import EmailContainerWrapper from "../features/email/view/EmailContainerWrapper";
import ChatContainer from "../features/chat/view/ChatContainer";
import CreateTaskContainer from "../features/task/view/CreateTaskContainer";
import { red } from "@mui/material/colors";
import AppUtils from "../utils/AppUtils";
import { getLoginUserDetails } from "../utils/Utils";
import BottomMenuAppList from "./BottomMenuAppList";
import BottomMenuQuickActions from "./BottomMenuQuickActions";
export default function BottomMenu() {
  const loginUser = getLoginUserDetails();
  const { addTab } = useContext(AddTabContext);
  const [showBottomMenu, setShowBottomMenu] = useState(true);

  const [showBottomShortcutMenu, setShowBottomShortcutMenu] = useState(false);
  useEffect(() => {
    setShowBottomMenu(true);
    setShowBottomShortcutMenu(false);
  }, []);
  // const top100Films = [
  //   { title: "UI/UX Discussion", time: "15:30Pm" },
  //   { title: "Installation of Adobe Creative Suite", time: "15:30Pm" },
  //   { title: "Anual Genaral Meeting", time: "15:30Pm" },
  // ];

  let appListSearch = [
    {
      title: "Drive",
      icon: <StorageOutlined />,
      tabContent: {
        key: uuidv4(),
        title: "Drive",
        content: <DriveContainer addTab={addTab} />,
        isDraggable: true,
      },
    },
    {
      title: "Meeting Room",
      icon: <VideocamOutlined />,
      tabContent: {
        key: uuidv4(),
        title: "Meeting Room",
        content: (
          <ConfirmProvider>
            <MeetingsTabContainer />
          </ConfirmProvider>
        ),
        isDraggable: true,
      },
    },

    {
      title: "Chat",
      icon: <ChatBubbleOutline />,
      tabContent: {
        key: uuidv4(),
        title: "Chat",
        content: <ChatContainer />,
        isDraggable: true,
      },
    },
    {
      title: "Email",
      icon: <EmailOutlined />,
      tabContent: {
        key: uuidv4(),
        title: "Email",
        content: <EmailContainerWrapper />,
        isDraggable: true,
      },
    },
    {
      title: "Tasks",
      icon: <ListTask className="btmMenuAppsIcon" />,
      tabContent: {
        key: uuidv4(),
        title: "Task",
        content: <TaskTabContainer />,
        isDraggable: true,
      },
    },
    {
      title: "Scheduler",
      icon: <CalendarMonth />,
      tabContent: {
        key: uuidv4(),
        title: "Scheduler",
        content: <SchedulerContainer />,
        isDraggable: true,
      },
    },
    {
      title: "Create new task",
      icon: <PlaylistAddOutlined />,
      tabContent: {
        key: uuidv4(),
        title: "Create new task",
        content: <CreateTaskContainer />,
        isDraggable: true,
      },
    },
    {
      title: "Create a new event",
      icon: <Event />,
      tabContent: {
        key: uuidv4(),
        title: "Scheduler",
        content: (
          <SchedulerContainer bottomShortcutDto={{ eventType: "Event" }} />
        ),
        isDraggable: true,
      },
    },
    {
      title: "Start instant meeting",
      icon: <Videocam />,
    },
  ];

  if (AppUtils.isPersonal(loginUser)) {
    appListSearch = appListSearch.filter(
      (appListDto) => appListDto.title.toLowerCase().indexOf("task") === -1
    );
  }
  // const openNewDocument = async (fileName, fileType) => {
  //   console.log("onSubmitNewFile", fileName, fileType);
  //   this.setState({ uploading: true });

  //   try {
  //     // if (!fileName || fileName.trim() === "") {
  //     //   toast.error("Please enter a valid file name", {
  //     //     position: toast.POSITION.TOP_RIGHT,
  //     //   });
  //     //   return;
  //     // }

  //     const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
  //     if (specialCharsRegex.test(fileName)) {
  //       toast.error(
  //         "Please enter a valid file name, special characters are not allowed",
  //         { position: toast.POSITION.TOP_RIGHT }
  //       );
  //       return;
  //     }

  //     const response = await getUploadedFiles(this.userDetails.userId);
  //     const filesUploadedTemp = response.data;
  //     this.setState({ ...this.state, FilesUploaded: filesUploadedTemp });

  //     // const fileExt = fileName.substring(
  //     //   fileName.lastIndexOf(".") + 1,
  //     //   fileName.length
  //     // );

  //     const fileExt = fileType;

  //     const isFileNamePresent = filesUploadedTemp.some(
  //       (fileObj) => fileObj.fileName === fileName + "." + fileExt
  //     );

  //     if (isFileNamePresent) {
  //       toast.warning(
  //         `${fileName}.${fileExt} Already Present. Please Create a File `,
  //         {
  //           position: toast.POSITION.TOP_RIGHT,
  //         }
  //       );

  //       return;
  //     }

  //     this.uploadNewFile({ fileName, fileType });
  //   } catch (error) {
  //     console.error("Error in file submission:", error.message);
  //     toast.error(error.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   } finally {
  //     this.setState({ uploading: false });
  //   }
  // };

  const anchorRef = useRef(null);

  const [anchorElRP, setAnchorElRP] = React.useState(null);

  const handleClickRP = (event) => {
    setAnchorElRP(anchorRef.current);
  };

  const handleCloseRP = () => {
    setAnchorElRP(null);
  };
  const openRP = Boolean(anchorElRP);
  const idRP = openRP ? "simple-popover-bottom-menu" : undefined;
  return (
    <>
      {showBottomMenu && (
        <div
          className="bottomNavConatiner"
          ref={anchorRef}
          onMouseLeave={() => {
            setShowBottomShortcutMenu(false);
          }}
        >
          <div className="quickAccessApp">
            {/* <Tooltip title="Word processor" arrow>
              <Button className="qaAppDtl"> W </Button>
            </Tooltip>

            <Tooltip title="Spreadsheets" arrow>
              <Button className="qaAppDtl"> X </Button>
            </Tooltip>

            <Tooltip title="Slides" arrow>
              <Button className="qaAppDtl"> S </Button>
            </Tooltip> */}

            <Tooltip title="Scheduler" arrow>
              <IconButton
                className="qaAppDtl"
                onClick={() => {
                  addTab({
                    key: uuidv4(),
                    title: "Scheduler",
                    content: <SchedulerContainer />,
                    isDraggable: true,
                  });
                }}
              >
                <CalendarMonth />
              </IconButton>
            </Tooltip>

            <Tooltip title="Meeting Room" arrow>
              <IconButton
                className="qaAppDtl"
                onClick={() => {
                  const meetingKey = uuidv4();
                  addTab({
                    key: meetingKey,
                    title: "Meeting Room",
                    content: (
                      <ConfirmProvider>
                        <MeetingsTabContainer tabKey={meetingKey} />
                      </ConfirmProvider>
                    ),
                    isDraggable: true,
                  });
                }}
              >
                <Videocam />
              </IconButton>
            </Tooltip>

            <Tooltip title="Drive" arrow>
              <IconButton className="qaAppDtl">
                <Storage
                  onClick={() => {
                    addTab({
                      key: uuidv4(),
                      title: "Drive",
                      content: <DriveContainer addTab={addTab} />,
                      isDraggable: true,
                    });
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>
          <div className="autocompleteSearch">
            {/* <Search /> */}
            <Autocomplete
              className="autocompleteSearchField"
              id="app_search_bottom_options"
              options={appListSearch}
              disableClearable
              getOptionLabel={(option) => option.title}
              PaperComponent={(props) => (
                <Paper {...props} className="custom-autocomplete-paper" />
              )}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                  onClick={() => {
                    setShowBottomMenu(false);
                    if (option.title === "Start instant meeting") {
                      const key = uuidv4();
                      const tabContent = {
                        key: key,
                        title: "Instant Meeting",
                        content: (
                          <ConfirmProvider>
                            <MeetingsTabContainer
                              startInstantMeeting={true}
                              tabKey={key}
                            />
                          </ConfirmProvider>
                        ),
                        isDraggable: true,
                        type: "Meeting",
                      };
                      option.tabContent = tabContent;
                    }
                    addTab(option.tabContent);
                  }}
                >
                  {/* <div className="bottomAppSearchOption">
                    <span className="bottomAppSearchOptionIcon">
                      {" "}
                      {option.icon}
                    </span>
                    <span className="bottomAppSearchOptionTitle">
                      {option.title}
                    </span>
                  </div> */}
                  <div class="bottomAppSearchOption">
                    <span class="bottomAppSearchOptionIcon">{option.icon}</span>
                    <span class="bottomAppSearchOptionTitle">
                      {option.title}
                    </span>
                  </div>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <Search />
                      </>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="botmMenu">
            <IconButton
              // aria-describedby={idRP}
              onClick={(event) => {
                // handleClickRP(event);
                setShowBottomShortcutMenu(!showBottomShortcutMenu);
              }}
            >
              <List />
            </IconButton>
            {showBottomShortcutMenu && <BottomMenuShortcut />}
            <Popover
              id={idRP}
              open={openRP}
              anchorEl={anchorElRP}
              onClose={handleCloseRP}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              className="bottomMnuPopoverContainerMain"
            >
              <div class="bottomMnuPopoverContainer">
                <BottomMenuAppList />
                <BottomMenuQuickActions />
              </div>
            </Popover>
          </div>
          {/* </div> */}
        </div>
      )}
    </>
  );
}
