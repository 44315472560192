import { Add, Close } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Trash, XLg } from "react-bootstrap-icons";

export default function AddSalaryTemplate({ handelCloseSalaryTemplate }) {
  const [selectComponent, setSelectComponent] = useState("Basic");
  const handleChangeAddcomponent = (event) => {
    setSelectComponent(event.target.value);
  };
  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">Add New Salary Template</div>
          <IconButton className="CloseBtn" onClick={handelCloseSalaryTemplate}>
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer payrollEditor">
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Template Name"
                variant="outlined"
                className="formTextFieldArea"
                required
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Description"
                variant="outlined"
                className="descptionTextFild"
                multiline
                rows={2}
                maxRows={4}
              />
            </FormControl>
          </div>

          <div className="tskElemHeding mt10">Salary Component</div>

          <div className="formElementGroup frmWithOnlyText">
            <div className="OnlyText">Annual CTC</div>
            <div className="formElement saIPend">
              <FormControl className="formControl">
                <TextField
                  label="Enter Amount"
                  variant="outlined"
                  className="formTextFieldArea"
                  value="0"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="prIA">
                        ₹
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
            <div className="OnlyText">per year</div>
          </div>

          <div className="formElementGroup frmWithOnlyText mt10">
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="select_component">
                  Select component to add
                </InputLabel>
                <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="select_component"
                  id="Select-component"
                  value={selectComponent}
                  label="Select component to add"
                  onChange={handleChangeAddcomponent}
                >
                  <MenuItem value="Basic">Basic</MenuItem>
                  <MenuItem value="HRA">House Rent Allowance</MenuItem>
                  <MenuItem value="Bonus">Bonus</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Button className="dfultPrimaryBtn" startIcon={<Add />}>
              Add
            </Button>
          </div>

          <div className="indentFormtable">
            <div className="indtableResponsive">
              <table>
                <thead>
                  <tr>
                    <th>COMPONENTS</th>
                    <th>CALCULATION TYPE</th>
                    <th className="textRight width100">MONTHLY</th>
                    <th className="textRight width100">ANNUAL</th>
                    <th className="width50"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="compTypRow">
                    <td colSpan={5}>Earnings</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="textOnly">Basic</div>
                    </td>
                    <td>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            variant="outlined"
                            className="formTextFieldArea"
                            value="50.00"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className="prIAauto"
                                >
                                  % of CTC
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>
                    <td className="textRight">0.00</td>
                    <td className="textRight">0.00</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="textOnly">House Rent Allowance</div>
                    </td>
                    <td>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            variant="outlined"
                            className="formTextFieldArea"
                            value="50.00"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className="prIAauto"
                                >
                                  % of Basic
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>
                    <td className="textRight">0.00</td>
                    <td className="textRight">0.00</td>
                    <td className="textRight">
                      <IconButton className="removeRowBtn">
                        <Close />
                      </IconButton>
                    </td>
                  </tr>

                  <tr className="compTypRow">
                    <td colSpan={5}>Deductions</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="textOnly">
                        EPF - Employer Contribution
                      </div>
                    </td>
                    <td>
                      <div className="textOnly">12% of Restricted PF Wage</div>
                    </td>
                    <td className="textRight">System Calculated</td>
                    <td className="textRight">System Calculated</td>
                    <td className="textRight">
                      <IconButton className="removeRowBtn">
                        <Close />
                      </IconButton>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={2}>Cost to Company</td>
                    <td className="textRight">0.00</td>
                    <td className="textRight">0.00</td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn"onClick={()=>finalSubmit()}>Save</Button> */}
            <Button className="dfultPrimaryBtn">Submit</Button>

            <Button
              className="dfultDarkSecondaryBtn"
              onClick={handelCloseSalaryTemplate}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
