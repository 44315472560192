import { Add, ArrowBack, Edit, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Modal,
  Switch,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import {
  getEmployees,
  deactivateEmployee,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../utils/DateUtils";

export default function ViewPeople({
  handelCloseViewPeople,
  handelOpenAddPeople,
  refreshViewPeople,
  handelOpenEditEmployee,
  closeAll
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));

  const [employsData, setEmploysData] = useState([]);

  const [filteredEmployData, setFilteredEmployData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [loading, setLoading] = useState(true);

  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    fetchEmployData();
  }, [refreshViewPeople]);

  const fetchEmployData = () => {
    getEmployees(userDetails.orgId)
      .then((response) => {
        console.log("getEmployees", response.data);
        setEmploysData(response.data);
        setFilteredEmployData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredData = employsData.filter((employ) => {
      const { title, firstName, lastName, middleName } = employ;
      const name = `${title} ${firstName} ${lastName} ${middleName}`;
      return name.toLowerCase().includes(query.toLowerCase());
    });

    console.log("Filtered data:", filteredData);

    setFilteredEmployData(filteredData);
  };

  // const handleSearch = (e) => {
  //   const query = e.target.value;
  //   setSearchQuery(query);

  //   const filteredData = employsData.filter(
  //     (employ) =>
  //     employ.personName &&
  //     employ.personName.toLowerCase().includes(query.toLowerCase())
  //   );

  //   setFilteredEmployData(filteredData);
  // };

  const [openModal, setOpenModal] = useState(false);

  const [employToDelete, setEmployToDelete] = useState(null);

  const handleDelete = (employUserId) => {
    console.log("handleDelete", employUserId);
    setEmployToDelete(employUserId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    console.log("selectedDate", selectedDate);
    const reqDto = DateUtils.getDateInDDMMYYYY(selectedDate);
    console.log("reqDto", reqDto);

    if (employToDelete) {
      // return;
      deactivateEmployee(userDetails.userId, employToDelete, reqDto).then(
        (response) => {
          console.log("data deleted", response.data);
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchEmployData();
            setOpenModal(false);
            setSelectedDate(new Date());
          }
        }
      );
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // const parseJoiningDate = (joiningDate) => {
  //   const [day, month, year] = joiningDate.split('-');
  //   return new Date(year, month - 1, day);
  // };

  const parseJoiningDate = (joiningDate) => {
    if (!joiningDate) return null;
    const dateParts = joiningDate.split("-");
    if (dateParts.length !== 3) return null;
    const [day, month, year] = dateParts;
    const parsedYear = parseInt(year, 10);
    const parsedMonth = parseInt(month, 10) - 1;
    const parsedDay = parseInt(day, 10);
    if (isNaN(parsedYear) || isNaN(parsedMonth) || isNaN(parsedDay))
      return null;
    return new Date(parsedYear, parsedMonth, parsedDay);
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton onClick={() => {handelCloseViewPeople(); closeAll();}}>
            <ArrowBack />
          </IconButton>
          Employee
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder="Search User"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddPeople()}
                >
                  Employee
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : employsData.length === 0 ? (
                <div class="noDataCard ">
                  <span>Oops!!</span>No Employee Define yet !!
                  <br />
                  <span className="sallMsg">
                    Click Add Employee Button to create new Employee
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>Employee Name</th>
                        <th>ID</th>
                        <th>Joining Date</th>
                        {/* <th>Gender</th> */}
                        <th>Status</th>
                        <th>Salary Type</th>
                        <th>Email Id</th>
                        <th>Function/Department</th>
                        <th>Primary Position</th>
                        <th>Reporting Head</th>
                        <th className="width100"></th>
                      </tr>
                    </thead>
                    {/* <tbody className="scrolableTableBody">
                    <tr>
                     
                      <td>
                        <div className="tskLstTaskNM">Mr. Sanket Santra</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">MT005</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">24/07/2023</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Confirmed</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Payroll</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">uiux-desiner@miniontek.com</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Information Technology</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Software Engineer</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Sk Aftabuddin</div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Edit />
                          </IconButton>
                          <IconButton>
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                    <tbody className="scrolableTableBody">
                      {filteredEmployData.map((employee) => (
                        <tr key={employee.userId}>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.title + " " + employee.personName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.employeeId}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.joiningDate}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.empStatus}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.employmentType}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.officialEmailId}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.functionName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.positionName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.reportingHeadUserName}
                            </div>
                          </td>
                          <td>
                            <div className="tblActionBtnGrp">
                              <IconButton
                                onClick={() => handelOpenEditEmployee(employee)}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDelete(employee.userId)}
                              >
                                <Trash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // width: "250px",
                    // height: "250px",
                    maxWidth: "600px",
                    minHeight: "150px",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 id="modal-modal-title">
                    Do you want to Deactivate this Employee ?
                  </h2>

                  {/* <div>If Yes, Then Please Select the date </div> */}

                  <div>Please select a date to confirm deactivation of this employee</div>

                  <ReactDatePicker
                    showIcon
                    labelId="setCompletionDate"
                    className="comDtPicker"
                    maxDate={new Date()}
                    minDate={
                      employToDelete
                        ? parseJoiningDate(
                            employsData.find(
                              (emp) => emp.userId === employToDelete
                            )?.joiningDate
                          )
                        : new Date()
                    }
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd-MMM-yyyy"
                  />

                  <br />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button className="dfultPrimaryBtn" onClick={confirmDelete}>
                      Yes
                    </Button>
                    <Button
                      className="dfultDarkSecondaryBtn"
                      onClick={handleCloseModal}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
