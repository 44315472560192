import React, { useEffect } from "react";
import asgnUimg from "../../../image/6.png";
import { useDispatch } from "react-redux";
import { modifyDashboardMsgLabels } from "../../../../../redux/reducers/dashboardMailSlice";
import EmailUtil from "../../../../email/view/EmailUtil";
import { fetchThreadList } from "../../../../../redux/reducers/mailSlice";
import userImage from "../../../image/dummyuser-100.png";

export default function EmailCard({
  mail,
  openRightMail,
  setDataNull,
  setActiveMsgId,
  active,
}) {
  const dispatch = useDispatch();
  const handleClickUnreadMail = (e) => {
    console.log("active msg id = ", setActiveMsgId);
    setActiveMsgId && setActiveMsgId(mail.messageId);
    console.log("data null = ", mail);
    setDataNull(null);
    openRightMail(true);
    dispatch(fetchThreadList(mail.threadId));
    dispatch(
      modifyDashboardMsgLabels({
        messageId: mail.messageId,
        labelsToAdd: [],
        labelsToRemove: ["UNREAD"],
      })
    );
  };

  // useEffect(() => {
  //   console.log("mail = ", mail);
  // }, []);

  return (
    <>
      {mail && (
        <div
          className={`msgCardContainer ${
            active && active === mail.messageId ? "activeContainer" : ""
          }`}
          onClick={handleClickUnreadMail}
        >
          <div className="contMxWidthLim">
            <div className="msgInOutIoc">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="20"
                viewBox="0 0 24 20"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 2.57143C0 1.88944 0.270918 1.23539 0.753154 0.753154C1.23539 0.270918 1.88944 0 2.57143 0H21.4286C22.1106 0 22.7646 0.270918 23.2468 0.753154C23.7291 1.23539 24 1.88944 24 2.57143V3.15771L12.6566 10.7211C12.4586 10.8403 12.2311 10.9015 12 10.8977C11.7689 10.9015 11.5414 10.8403 11.3434 10.7211L0 3.15771V2.57143ZM0 5.73257V17.1429C0 17.8248 0.270918 18.4789 0.753154 18.9611C1.23539 19.4434 1.88944 19.7143 2.57143 19.7143H21.4286C22.1106 19.7143 22.7646 19.4434 23.2468 18.9611C23.7291 18.4789 24 17.8248 24 17.1429V5.73257L13.8394 12.5074L13.8309 12.5143C13.2839 12.8621 12.6482 13.0449 12 13.0406C11.3571 13.0406 10.7091 12.8657 10.1691 12.5143L10.1606 12.5074L0 5.73257Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="msgInfoDtl">
              <div className="msgSunderImg">
                <img src={userImage} alt="User Image" />
                {/* <img src={asgnUimg} alt="" /> */}
              </div>
              <div className="msgSendDtl">
                <div className="msgSendNmGrp">
                  <div className="senderName">
                    {mail.senderName && mail.senderName !== ""
                      ? mail.senderName
                      : mail.senderEmail}
                  </div>
                </div>
                <div className="sendTime">
                  {EmailUtil.formatDateDDMMYY(mail.sendingDate)}
                  <span className="sndTmMM">
                    {" "}
                    {EmailUtil.formatTimeHHMMAMPM(mail.sendingDate)}
                  </span>
                </div>
                <div className="msgSendCommnt">
                  {mail.snippet && mail.snippet}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
