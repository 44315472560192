import React, { useState } from "react";
import { ZoyelDatepicker } from "./ZoyelDatepicker";
import { CustomCalender } from "./CustomCalender";

export const DatepickerContainer = () => {
  const [selected, setSelected] = useState(null);

  // const increaseMonth = () => {
  //   setStartDate((prevDate) => {
  //     const newDate = new Date(prevDate);
  //     newDate.setMonth(newDate.getMonth() + 1);
  //     return newDate;
  //   });
  // };

  // const decreaseMonth = () => {
  //   setStartDate((prevDate) => {
  //     const newDate = new Date(prevDate);
  //     newDate.setMonth(newDate.getMonth() - 1);
  //     return newDate;
  //   });
  // };

  return (
    <ZoyelDatepicker
      showIcon
      toggleCalendarOnIconClick={true}
      selectedDateTime={selected}
      handleChange={(date) => {
        setSelected(date);
      }}
    />
    // <CustomCalender />
  );
};
