import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import DashboardServices from "../../../../services/DashboardServices";
import { useDispatch } from "react-redux";
import { clearSelectedTask } from "../../../../redux/reducers/rightSideTaskPanelSlice";
import { useEffect } from "react";
import { useMemo } from "react";

const ForwardTaskModal = ({
  data,
  userList,
  handleClose,
  refreshData,
  clearStoreData,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const loginUserDetail = getLoginUserDetails();
  const [forwardReason, setForwardReason] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [assigneeList, setAssigneeList] = useState([]);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");

  const getFilteredData = () => {
    const taskCreator = data.assignByUserId;

    const filteredData = userList.filter((item) => {
      const isCreatorOrUser =
        item.userId !== loginUserDetail.userId && item.userId !== taskCreator;
      const isAlreadyAssigned = assigneeList.some((assignee) => {
        return assignee.userId === item.userId;
      });

      return isCreatorOrUser && !isAlreadyAssigned;
    });
    console.log("filtered data=", filteredData);
    return filteredData;
  };

  const filteredUsers = useMemo(getFilteredData, [
    data,
    userList,
    assigneeList,
    loginUserDetail.userId,
  ]);

  const computedList = useMemo(() => {
    let filtered = [];
    if (
      autocompleteInputValue.length > 0 &&
      autocompleteInputValue.trim() !== ""
    ) {
      filtered = filteredUsers.filter((option) =>
        option.userName
          .toLowerCase()
          .includes(autocompleteInputValue.toLowerCase())
      );
    }

    return filtered;
  }, [autocompleteInputValue, userList]);

  const handleSubmitTransferTask = () => {
    let validForm = true;
    if (forwardReason === "") {
      toast.error("Please Enter Transfer Reason", {
        position: toast.POSITION.TOP_RIGHT,
      });
      validForm = false;
    }
    if (selectedUsers === null) {
      toast.error("Please Select User", {
        position: toast.POSITION.TOP_RIGHT,
      });
      validForm = false;
    }

    if (selectedUsers && selectedUsers.userId === loginUserDetail.userId) {
      toast.error("You Cannot Select Yourself");
      validForm = false;
    }

    if (!validForm) {
      return;
    }

    const reqDto = {
      taskDetailId: data.taskId,
      fromUserId: loginUserDetail.userId,
      toUserId: selectedUsers.userId,
      transferReason: forwardReason,
    };
    console.log("submit ", reqDto);
    setLoading(true);
    DashboardServices.transferTask(reqDto).then((response) => {
      if (response.data) {
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setForwardReason("");
          // setUserList(null);
          if (clearStoreData) {
            clearStoreData();
          } else {
            dispatch(clearSelectedTask());
          }
          refreshData();
          handleClose();
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoading(false);
      }
    });
  };

  const handleChangeAutoCompleteInputValue = (event, newInputValue) => {
    setAutocompleteInputValue(newInputValue);
  };

  useEffect(() => {
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });
  }, []);

  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">Delegate Task</div>

          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  className="modelTextFild"
                  required
                  label="Reason For Delegation*"
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={forwardReason}
                  onChange={(e) => {
                    setForwardReason(e.target.value);
                  }}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="adduser">Add User</InputLabel> */}
                <Autocomplete
                  id="adduser-select"
                  className="formAutocompleteField"
                  variant="outlined"
                  noOptionsText={"Please Type Recipient Name"}
                  value={selectedUsers}
                  options={computedList}
                  getOptionLabel={(option) => option.userName}
                  inputValue={autocompleteInputValue}
                  onChange={(e, newValue) => {
                    console.log("new value = ", newValue);
                    setSelectedUsers(newValue);
                  }}
                  onInputChange={handleChangeAutoCompleteInputValue}
                  getOptionSelected={(option) => {
                    console.log("option is ", option);
                    return option.userId;
                  }}
                  renderOption={(props, option) => {
                    return (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {`${option.userName} ( ${
                          option.userDtl ? option.userDtl : ""
                        } )`}
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="modelformAutocompleteField"
                      placeholder="Assign Recipient*"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          {/* <div className="modAtchFileBtn">
          <label class="btn-up">
            <input type="file" hidden />
            Attach File
          </label>
        </div> */}
          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                handleSubmitTransferTask();
              }}
            >
              Submit
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
        {loading && (
          <div className="elementFormContainer">
            <CircularProgress />
          </div>
        )}
      </Box>
    </>
  );
};

export default ForwardTaskModal;
