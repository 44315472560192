import { Add, Edit, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Modal,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { ArrowClockwise, Trash } from "react-bootstrap-icons";
import {getCouponCodes , deactivateCouponCode , generateCouponCode} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

export default function DefineCoupon({ handelOpenCreateCouponCode , refreshCouponCode}) {

  const userDetails = jwtDecode(localStorage.getItem("token"));
 
  const [loading, setLoading] = useState(true);

  const [couponCodeData , setCouponCodeData] = useState([]);
  const [fiterCouponCodeData , setFilterCouponCodeData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [couponStatus, setCouponStatus] = useState({});

  // useEffect(()=>{
  //   getCouponCodes()
  //   .then((response)=>{
  //     console.log('getCouponCodes', response.data)
  //     const updatedData = response.data.map(coupon => ({
  //       ...coupon,
  //       expireDate: coupon.expireDate.replace(/-/g, "/"),
  //       // coustomCode: coupon.coustomCode.replace(/.(?=.*?\.)/g, "X")
  //       // coustomCode: coupon.coustomCode.charAt(0) + "XXX" + coupon.coustomCode.slice(-1)
  //     }));
  //     setCouponCodeData(updatedData);
  //     setFilterCouponCodeData(updatedData);   
  //   })
  // },[]);

  useEffect(()=>{
    fetchCouponCode()
  },[refreshCouponCode])

    const fetchCouponCode = () =>{
    getCouponCodes().then((response) => {
      const updatedData = response.data.map((coupon) => ({
        ...coupon,
        expireDate: coupon.expireDate.replace(/-/g, "/"),
      }));
      setCouponCodeData(updatedData);
      setFilterCouponCodeData(updatedData);

      // Set initial state of couponStatus to active for all custom codes
      const initialCouponStatus = {};
      updatedData.forEach((coupon) => {
        initialCouponStatus[coupon.coustomCode] = true;
      });
      setCouponStatus(initialCouponStatus);
      setLoading(false);
    });
  }


  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = couponCodeData.filter(
      (coupon) =>
      coupon.purpose &&
      coupon.purpose.toLowerCase().includes(query.toLowerCase())
    );

    setFilterCouponCodeData(filteredData);
  };

  const handleChangeCupStatus = (event, coustomCode) => {
    const isChecked = event.target.checked;
    const matchedCoupon = fiterCouponCodeData.find(coupon => coupon.coustomCode === coustomCode);
  
    if (matchedCoupon) {
      const newCouponStatus = { ...couponStatus };
      newCouponStatus[coustomCode] = matchedCoupon.activeFlag === "Y" ? isChecked : false;
      setCouponStatus(newCouponStatus);
    
      console.log('matchedCoupon', matchedCoupon);
  
      if (!isChecked) {
        // const reqDto = {
        //   purpose: matchedCoupon.purpose,
        //   discountPc: matchedCoupon.discountPc,
        //   noOfTimesUseable: matchedCoupon.noOfTimesUseable,
        //   expireDate: matchedCoupon.expireDate,
        //   couponReceiverName: matchedCoupon.couponReceiverName,
        //   couponReceiverMailId: matchedCoupon.couponReceiverMailId,
        //   transType: "ORGANIZATION_ONBOARD",
        //   coustomCode: matchedCoupon.coustomCode,
        //   activeFlag: "N" 
        // };
  
        // Call deactivateCouponCode to deactivate the coupon
        deactivateCouponCode(userDetails.userId, matchedCoupon.coustomCode)
          .then((response) => {
            console.log(response.data);
            if (response.data.returnValue === "1") {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              fetchCouponCode();
            }
          })
          .catch((error) => {
            console.error("Error deactivating coupon:", error);
          });
      }
    } else {
      console.log("No coupon found with the provided coustomCode:", coustomCode);
    }
  };
  
  


//   const handleChangeCupStatus = (event , coustomCode) => {
//     const isChecked = event.target.checked
//     console.log('handleChangeCupStatus',event.target.checked , coustomCode);
//     // setCouponStatus(event.target.checked);
//     setCouponStatus((prevStatus) => ({
//       ...prevStatus,
//       [coustomCode]: isChecked,
//     }));


//     if (!isChecked) {
//       const value = { coustomCode }; 
//       const reqDto = value.coustomCode.toString();
//       console.log('reqDto' , reqDto);

      
// } else {
//     console.log("No coupon found with the provided reqDto:", reqDto);
// }



//       // return;

//       // deactivateCouponCode(userDetails.userId, reqDto)
//       //   .then((response) => {
//       //     console.log(response.data);
//       //     if (response.data.returnValue === "1") {
//       //       toast.success(response.data.message, {
//       //         position: toast.POSITION.TOP_RIGHT,
//       //       });
//       //       fetchCouponCode();
//       //     }
//       //   })
//       //   .catch((error) => {
//       //     console.error("Error deactivating coupon:", error);
//       //     // Handle error, show error toast, etc.
//       //   });
//     }
//   };

  console.log('couponStatus' , couponStatus);
 

  return (
    <>
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          {/* <div className="cuhederTxt">Departmment List</div> */}
          <div className="searchTasklist">
            <input
              type="text"
              class="serchTaskInput"
              placeholder="Search Coupon"
              value={searchQuery}
              onChange={handleSearch}
            />
            <IconButton className="seacrhIcon">
              <Search />
            </IconButton>
          </div>

          <div className="addUnitsBtn">
            {/* <Button
              startIcon={<AccountTree />}
              className="dfultDarkSecondaryBtn"
              // onClick={() => handleOpenUnitTreeView()}
            >
              Departmment Tree
            </Button> */}
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => handelOpenCreateCouponCode()}
            >
              Add Coupon
            </Button>
          </div>
        </div>

        <div className="cuContainArea">

        {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : couponCodeData.length === 0 ? (
            <div class="noDataCard ">
              <span>Oops!!</span>No Coupon added yet !!
              <br />
              <span className="sallMsg">
                Click Add Coupon Button to create new Coupon
              </span>
            </div>
          ) : (
          <div className="TableContainer">
            <table className="taskListTable">
              <thead className="taskListtableHeader">
                <tr>
                  <th>Sl. No.</th>
                  <th>Purpose</th>
                  <th>Discount %</th>
                  <th>Exp. Date</th>
                  <th>No of Use</th>
                  <th>Name</th>
                  <th>Assigned to</th>
                  <th className="width260">Code</th>
                  <th className="width100">Status</th>
                </tr>
              </thead>
              <tbody className="scrolableTableBody">
                {fiterCouponCodeData.map((coupon, index) => (
                  <tr key={index}>
                    <td>
                      <div className="tskLstTaskNM">{index + 1}.</div>
                    </td>
                    <td>
                      <Tooltip
                        arrow
                        title={coupon.purpose}
                      >
                        <div className="tskLstTaskDescription">
                          {coupon.purpose}
                        </div>
                      </Tooltip>
                    </td>
                    <td>
                      <div className="tskLstTaskNM">{coupon.discountPc}%</div>
                    </td>
                    <td>
                      <div className="tskLstTaskNM">{coupon.expireDate}</div>
                    </td>
                    <td>
                      <div className="tskLstTaskNM">{coupon.noOfTimesUseable}(Time)</div>
                    </td>
                    <td>
                      <div className="tskLstTaskNM">{coupon.couponReceiverName}</div>
                    </td>
                    <td>
                      <div className="tskLstTaskNM">{coupon.couponReceiverMailId}</div>
                    </td>
                    <td>
                      <div className="tskLstTaskNM couponCode">
                        {/* <span>{coupon.coustomCode}</span> */}
                        <span>{coupon.coustomCode.charAt(0) + "XXX" + coupon.coustomCode.slice(-1)}</span>
                      </div>
                    </td>
                    {/* <td>
                      <div className="tblActionBtnGrp">
                        <FormControlLabel
                          className="couponStatusSwitch"
                          control={
                            <Switch
                              // checked={couponStatus}
                              checked={couponStatus[coupon.coustomCode] || false}
                              onChange={(event)=> handleChangeCupStatus(event , coupon.coustomCode)}
                              color="primary"
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          // label={couponStatus ? "Active" : "Deactive"}
                          label={couponStatus[coupon.coustomCode] ? "Active" : "Deactive"}
                        />
                      </div>
                    </td> */}
                    <td>
  <div className="tblActionBtnGrp">
    <FormControlLabel
      className="couponStatusSwitch"
      control={
        <Switch
          checked={coupon.activeFlag === "Y"} // Check if activeFlag is "Y"
          onChange={(event) => handleChangeCupStatus(event, coupon.coustomCode)}
          color="primary"
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label={coupon.activeFlag === "Y" ? "Active" : "Deactive"} // Show Active/Deactive based on activeFlag
    />
  </div>
</td>

                  </tr>
                ))}
              </tbody>
              {/* <tbody className="scrolableTableBody">
                <tr>
                  <td>
                    <div className="tskLstTaskNM">1.</div>
                  </td>
                  <td>
                    <Tooltip
                      arrow
                      title="Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,"
                    >
                      <div className="tskLstTaskDescription">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                      </div>
                    </Tooltip>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">100%</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">11/11/2024</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">1 (Time)</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">
                      sanketsantra@miniontek.com
                    </div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM couponCode">
                      <span>ZOXXXXXXXX0</span>
                      <Tooltip title="Resend Code" arrow>
                        <div className="tskLstTaskNM">
                          <Button
                            startIcon={<ArrowClockwise />}
                            className="addNRwBtn"
                          >
                            Send Code
                          </Button>
                        </div>
                      </Tooltip>
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <FormControlLabel
                        className="couponStatusSwitch"
                        control={
                          <Switch
                            couponStatus={couponStatus}
                            onChange={handleChangeCupStatus}
                            color="primary"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={couponStatus ? "Active" : "Deactive"}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="tskLstTaskNM">1.</div>
                  </td>
                  <td>
                    <Tooltip
                      arrow
                      title="Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,"
                    >
                      <div className="tskLstTaskDescription">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                      </div>
                    </Tooltip>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">100%</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">09/09/2024</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">1 (Time)</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">
                      uiux-designer@miniontek.com
                    </div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM couponCode">
                      <span>HAXXXXXXXX0</span>
                      <Tooltip title="Resend Code" arrow>
                        <div className="tskLstTaskNM">
                          <Button
                            startIcon={<ArrowClockwise />}
                            className="addNRwBtn"
                          >
                            Send Code
                          </Button>
                        </div>
                      </Tooltip>
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <FormControlLabel
                        className="couponStatusSwitch"
                        control={
                          <Switch
                            couponStatus={couponStatus}
                            onChange={handleChangeCupStatus}
                            color="primary"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={couponStatus ? "Active" : "Deactive"}
                      />
                    </div>
                  </td>
                </tr>
              </tbody> */}
            </table>
          </div>
          )}

        </div>
      </div>
    </>
  );
}
