import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import React, { useState } from "react";
import { XLg } from "react-bootstrap-icons";
// import holi from "../../../images/Holi.jpg";
import DateUtils from "../../../../../utils/DateUtils";
import { addHoliday , updateHoliday} from "../../../../../services/AdminService";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

export default function PreviewHolidayEmailer({
  showPreviewHolidayModel,
  handelClosePreviewHolidayModel,
  holidayName,
  holidayDescription,
  selectedDate,
  imageData,
  imageName,
  afterSubmission,
  selecttedHolidayData,
  handelCloseEditHoliday,
  fetchDashBoardData
}) {
  console.log('imageData', imageData);
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const holidayEmail = holidayDescription;

  console.log("holidayEmail", holidayEmail);

  const finalSubmit = () => {
    setLoading(true);
    console.log("finalSubmit");
    console.log("imageName",imageName);
    console.log("imageData",imageData);
    const reqDto = {
      holidayDate: DateUtils.getDateInDDMMYYYY(selectedDate),
      holidayName: holidayName,
      holidayEmailContent: holidayDescription,
      optional: "",
      emailFileName: imageName,
      emailFileDataLink: imageData,
    };

    console.log("reqDto", reqDto);

    // return;

    addHoliday(userDetails.orgId, userDetails.userId, reqDto).then(
      (response) => {
        if (response.data) {
          if (response.data.returnValue === "1") {
            toast.success(response.data.message , {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchDashBoardData();
            setLoading(false);
            afterSubmission();
            handelClosePreviewHolidayModel();
          }

          if (response.data.returnValue === "0") {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }

          if (response.data.returnValue === "-1") {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        }
      }
    );
  };

  const updateFinalSubmit = () => {
    setLoading(true);
    console.log("finalSubmit");

    const holidayUpdatedDate = DateUtils.getDateInDDMMYYYY(selectedDate);
    console.log('holidayUpdatedDate', holidayUpdatedDate);

    const reqDto = {
      holidayDate: DateUtils.getDateInDDMMYYYY(selectedDate),
      holidayName: holidayName,
      holidayEmailContent: holidayDescription,
      optional: "",
      emailFileName: imageName,
      emailFileDataLink: imageData,
    };

    console.log("reqDto", reqDto);

    updateHoliday(userDetails.orgId, userDetails.userId, holidayUpdatedDate, reqDto).then(
      (response) => {
        if (response.data) {
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
            afterSubmission();
            handelClosePreviewHolidayModel();
            handelCloseEditHoliday();
          }

          if (response.data.returnValue === "0") {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }

          if (response.data.returnValue === "-1") {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        }
      }
    );
  };

  return (
    <>
      <div className="modelContainer" open={showPreviewHolidayModel}>
        <Box className="modelContain animated autoWidth hideScroolBar">
          <IconButton
            className="closeModelIoc"
            onClick={handelClosePreviewHolidayModel}
          >
            <XLg />
          </IconButton>

          <div className="fwModelContainArea">
            <div className="fwModelHeading">{t("holiday_emailer_preview")}</div>        
              <div className="emailerPreview">
                {/* <div className="emailerPreviewImgContainer"> */}
                {/* <img src={holi} alt="holiday name" /> */}
                {/* </div> */}
                {imageData && (
                  <div className="emailerPreviewImgContainer">
                    <img
                      src={`data:image/png;base64,${imageData}`}
                      // src={`${imageData}`}
                      alt="Holiday"
                    />
                  </div>
                )}
                <div
                  className="emailerContain"
                  style={{ whiteSpace: "pre-line" }}
                >
                  <div className="emGrttext">{t("greeting")}</div>
                  <div className="emText">{holidayEmail}</div>
                  {/* <div className="emText">Dear Colleague,</div> */}
                  {/* <div className="emText">
                  I am writing to inform you that our organization will be
                  closed on 25-03-2024 in observance of DOL YATRA (HOLI). Our
                  system has automatically generated this email to inform you of
                  the upcoming holiday/s.
                </div> */}
                  {/* <div className="emText">
                  Please note that all company operations will be closed on this
                  day and will resume on 26-03-2024. If any urgent matter
                  arises, please contact Reporting Head who will be available to
                  attend to any emergencies.
                </div> */}
                  {/* <div className="emText">
                  We appreciate your hard work and dedication to our
                  organization and hope that you have a safe and enjoyable
                  holiday. Please donot reply to this email as this is a system
                  generated email.
                </div> */}
                </div>
              </div>
            
            <div className="modActionBtnGrp actionsCreateOrg">
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={handelClosePreviewHolidayModel}
                >
                  {t("cancel")}
                </Button>
              )}
              <Button
                className="dfultPrimaryBtn"
                disabled={loading}
                onClick={() => {selecttedHolidayData ? updateFinalSubmit () : finalSubmit()}}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>{t("submit")}</>
                )}
              </Button>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
}
