import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function CircleTimer({ eventData }) {
  const radius = 30; // radius of the circle
  const circumference = 2 * Math.PI * radius;
  const maxPercentage=90
  // const offset_old = circumference - (meetingTimer / 100) * circumference;

  // Determine the color based on the progress
  // let barColor = "#0FB900";
  // if (meetingTimer > 60 && meetingTimer <= 100) {
  //   barColor = "#FFA800";
  // } else if (meetingTimer > 100) {
  //   barColor = "#FF0000"; // red color
  // }

  const [timeElapsed, setTimeElapsed] = useState(null);
  // const []
  const [offset, setOffset] = useState(0);
  const [barColor, setBarColor] = useState("#434343");
  const [endTime, setEndTime] = useState(new Date());

  useEffect(() => {
    console.log("BottomOptionsTimer::", eventData);
    const interval = setInterval(() => {
      setTimeElapsed(calculateElapsedTime(eventData.eventStartTime));
    }, 1000);

    return () => clearInterval(interval);
  }, [eventData.eventStartTime]);

  // Function to calculate elapsed time
  const calculateElapsedTime = (startTime) => {
    const currentTime = new Date();
    // console.log("BottomOptionsTimer::", startTime, currentTime);
    const elapsedMilliseconds = currentTime - startTime;
    let minutes = Math.floor(elapsedMilliseconds / (1000 * 60));
    let seconds = Math.floor((elapsedMilliseconds % (1000 * 60)) / 1000);

    if (minutes >= 0 && seconds >= 0) {
      const eventLength = Math.floor(
        (eventData.eventEndTime - eventData.eventStartTime) / 1000
      );

      let percentCompletion = Math.floor(
        (elapsedMilliseconds / 1000 / eventLength) * 100
      );
      if (percentCompletion > maxPercentage) {
        percentCompletion = maxPercentage;
      }
      setOffset(circumference - (percentCompletion / 100) * circumference);
      if (percentCompletion > 0 && percentCompletion <= 60) {
        setBarColor("#0FB900");
      } else if (percentCompletion > 60 && percentCompletion <= 100) {
        setBarColor("#FFA800");
      } else if (percentCompletion > 100) {
        setBarColor("#FF0000");
      }

      return { minutes, seconds };
    } else {
      minutes = 0;
      seconds = 0;
      return { minutes, seconds };
    }
  };

  // Function to format time as mm:ss
  const formatTime = (time) => {
    // console.log("BottomOptionsTimer::", timeElapsed);
    if (time.minutes >= 60) {
      let hours = Math.floor(time.minutes / 60);
      let minutes = time.minutes % 60;
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${time.seconds.toString().padStart(2, "0")}`;
    } else {
      return `${time.minutes.toString().padStart(2, "0")}:${time.seconds
        .toString()
        .padStart(2, "0")}`;
    }
  };

  return (
    <>
      <svg className="radial-progress-timer" height="80" width="80">
        <circle
          className="radial-progress-bg-timer"
          cx="40"
          cy="40"
          r={radius}
        />
        <circle
          className="radial-progress-bar-timer"
          cx="40"
          cy="40"
          r={radius}
          style={{
            strokeDasharray: `${circumference} ${circumference}`,
            strokeDashoffset: offset,
            stroke: barColor,
          }}
        />
        <text
          x="40"
          y="30"
          textAnchor="middle"
          dominantBaseline="middle"
          dy="-1.6em"
        >
          {timeElapsed != null && formatTime(timeElapsed)}
        </text>
      </svg>
    </>
  );
}
