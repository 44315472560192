import { useEffect, useState } from "react";
import { $generateHtmlFromNodes } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

const OnChangePlugin = ({ fetchHtmlAndJSON, fetchText }) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener((editorState) => {
      editor.update(() => {
        const editorState = editor.getEditorState();
        const htmlString = $generateHtmlFromNodes(editor);
        console.log("textContent", htmlString);
        fetchHtmlAndJSON(editorState.toJSON(), htmlString);
      });
    });
  }, [editor, fetchHtmlAndJSON]);
  useEffect(() => {
    return editor.registerTextContentListener((textContent) => {
      // The latest text content of the editor!
      console.log("textContent", textContent);
      fetchText(textContent);
    });
  }, [editor, fetchText]);
};
export default OnChangePlugin;
