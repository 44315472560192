import React from "react";

export const TaskStatusProgress = ({ progressWidth }) => {
  return (
    <div className="taskElementGrp">
      <div className="tskElemHeding">Status</div>
      <div className="tskTimeline">
        <div className="tskTimBar">
          <div className="progress">
            <div
              // ps50 ps80 ps100
              className={`progress-done ${
                progressWidth < 50
                  ? "ps50"
                  : progressWidth < 80
                  ? "ps80"
                  : "ps100"
              }`}
              style={{ width: `${progressWidth}%`, opacity: 1 }}
            >
              <span className="pwCount">{progressWidth}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
