import { toast } from "react-toastify";

export const convertFileToBase64_Split = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL.split(",")[1]);
    };
  });
};

export const convertFileToBase64_WithOutSplit = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const downloadFileFromUrl = async (url, fileName) => {
  try {
    const image = await fetch(url);
    const imageBlob = await image.blob();
    const imageURL = URL.createObjectURL(imageBlob);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    toast.error(`Could not download ${fileName}`);
  }
};

export const convertFileToBufferArray = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = (err) => reject(err);
  });
};

export const getFileDataObj = async (file) => {
  const dto = {
    fileName: file.name,
    fileDataLink: await convertFileToBase64_Split(file),
  };
  return dto;
};
