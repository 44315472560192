import { Box, Button, IconButton, Modal, Slider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { HddFill, XLg } from "react-bootstrap-icons";
import { getOrgTotalStorage } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import DriveStripe from "../PaymentDriveStorage/DriveStripe"

export default function CreateStorage({ handleCloseCreateOrgStorage , refreshStorageData}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handlePaymentModal = () => {
    setShowPaymentModal(false)
  }

  const [TotalStorageData, setTotalStorageData] = useState([]);

  useEffect(() => {
    getOrgTotalStorage(userDetails.orgId).then((response) => {
      console.log("Total Storage", response.data);
      setTotalStorageData(response.data);
    });
  }, [userDetails.orgId]);

  const [addedStorage, setAddedStorage] = useState(null);

  function valuetext(value) {
    return `${value} GB`;
  }

  function valueLabelFormat(value) {
    setAddedStorage(value);
    return `${value} GB`;
  }

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">Add Organization Storage</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handleCloseCreateOrgStorage()}
          >
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer">
          <div className="curntStorCont">
            <div className="CurntStrData">
              <span className="curntStrgTxtlight">
                Current <br />
                Storage
              </span>
              <span className="curntStrgBold">
                {TotalStorageData.storage ? `${TotalStorageData.storage} GB` : '0 GB'} GB
              </span>
            </div>

            <div className="CurntStrData">
              <span className="curntStrgTxtlight">
                Upgraded <br />
                To
              </span>
              {/* <span className="curntStrgBold textWhie">
                {TotalStorageData.storage + addedStorage} GB
              </span> */}
              <span className="curntStrgBold textWhie">
              {TotalStorageData.storage + addedStorage ? `${TotalStorageData.storage + addedStorage} GB` : '0 GB'}
               </span>

            </div>

            <div className="curntDaImg">
              <span>
                <HddFill />
              </span>
              <span className="cuStText">Zoyel Drive</span>
            </div>
          </div>

          <div class="tskElemHeding mt10">Add More Storage</div>
          <div className="formElement mt10">
            <Slider
              className="stroageSlider"
              // defaultValue={25}
              getAriaValueText={valuetext}
              defaultValue={1}
              step={1}
              min={25}
              max={1000}
              valueLabelFormat={valueLabelFormat}
              aria-label="Default"
              valueLabelDisplay="on"
            />
          </div>

          <div className="stroageTable">
            <div className="strgItmResponsive">
              <table>
                <thead>
                  <tr>
                    <th className="taCenter">Item Name</th>
                    <th className="taRight">Unit Price ($)</th>
                    <th className="taRight">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="heit150 taCenter">
                        New Storage added &nbsp;<span> {addedStorage} GB</span>
                      </div>
                    </td>
                    <td>
                      <div className="heit150 taRight">
                        x <span>$0.025</span>
                      </div>
                    </td>
                    <td>
                      <div className="heit150 taRight">
                        <span>
                          $
                          {Math.round(
                            (0.025 * parseFloat(addedStorage) +
                              Number.EPSILON) *
                              100
                          ) / 100}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot className="crtStorgFoot">
                  <tr>
                    <td colSpan="2">
                      <div className="taRight">
                        <strong>Total</strong>
                      </div>
                    </td>
                    <td>
                      <div className="taRight">
                        <strong>
                          $
                          {Math.round(
                            (0.025 * parseFloat(addedStorage) +
                              Number.EPSILON) *
                              100
                          ) / 100}
                        </strong>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          {/* end stroage Table end */}
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button className="dfultPrimaryBtn"  onClick={() => {
                setShowPaymentModal(true);
              }}>Upgrade Now</Button>

            <Button
              className="dfultDarkSecondaryBtn"
              onClick={handleCloseCreateOrgStorage}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={showPaymentModal}
        onClose={() => {
          setShowPaymentModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            height: 300,
            //overflow: "auto",
            maxHeight: "60vh",
            // bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            backgroundColor: "#edebff",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            className="closeDilog"
            onClick={() => {
              setShowPaymentModal(false);
            }}
          >
            X
          </div>
          <DriveStripe
            itemId={"1"}
            itemHeader="STORAGE"
            itemUnits={addedStorage}
            // refreshEdition={() => refreshEdition()}
            onSuccess={() => {
              console.log("onSuccess Called");

              refreshStorageData();
            }}
            // handleNext={props.handleNext}
            handlePaymentModal={handlePaymentModal}
            handleCloseCreateOrgStorage={handleCloseCreateOrgStorage}
          />
        </Box>
      </Modal>
    </>
  );
}
