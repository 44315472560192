import React, { useEffect, useRef } from "react";
import "./css/zoeyBot.css";
import ZoeyBotIoc from "./assets/zoey_assist_ioc.svg";
import ZoeyBotLogo from "./assets/zoeyLogo.svg";
import userImg from "./assets/prahant.jpg";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import { ImageFill, MicFill, Trash, XLg } from "react-bootstrap-icons";
import { useState } from "react";
import { CopyAllOutlined, Refresh, Send } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import Skeleton from "@mui/material/Skeleton";
import DashboardServices from "../services/DashboardServices";
import { getLoginUserDetails } from "../utils/Utils";
export default function ZoeyBot() {
  const loginUserDetails = getLoginUserDetails();
  const [showZoeyBotIoc, setShowZoeyBotIoc] = useState(true);
  const [showZoey, setShowZoey] = useState(false);
  const [loadingReply, setLoadingReply] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [messagesCount, setMessagesCount] = React.useState(0);
  const [profileImage, setProfileImage] = useState(null);
  const inputRef = useRef(null);

  const handleCloseZoey = () => {
    setShowZoey(false);
    setShowZoeyBotIoc(true);
  };
  const handleShowZoey = () => {
    setShowZoey(true);
    setShowZoeyBotIoc(false);
    setMessagesCount(messagesCount + 1);
  };

  const sendMessage = async (message) => {
    const query = inputRef.current.value?.trim();
    inputRef.current.value = "";
    if (!query) {
      toast.error("Please ask a question!!");
      inputRef.current.focus();
      // inputRef.current.style.border = "2px solid red";
      return;
    }
    setMessagesCount(messagesCount + 1);

    let chatDataSession = JSON.parse(localStorage.getItem("zoyel-chat-data"));
    let sessionId = null;
    if (chatDataSession) {
      sessionId = chatDataSession.sessionId;
    } else {
      sessionId = v4();
    }

    let reqDto = {
      user_message: query,
      session_id: sessionId,
    };

    setConversation((prevList) => [
      ...prevList,
      { id: prevList.length + 1, message: reqDto.user_message, type: "user" },
    ]);

    setLoadingReply(true);
    const response = await axios.post(
      "https://aichatbot.ahlan.work/chat",
      reqDto
    );
    console.log(response.data);
    setLoadingReply(false);

    if (chatDataSession) {
      let chatHistory = chatDataSession.chatHistory;
      chatHistory.push({
        id: chatHistory.length + 1,
        message: reqDto.user_message,
        type: "user",
      });
      chatHistory.push({
        id: chatHistory.length + 1,
        message: response.data.response,
        type: "bot",
      });
      let chatData = {
        sessionId: reqDto.session_id,
        chatHistory: chatHistory,
      };
      localStorage.setItem("zoyel-chat-data", JSON.stringify(chatData));
      setConversation(chatHistory);
    } else {
      let chatHistory = [];
      chatHistory.push({
        id: chatHistory.length + 1,
        message: reqDto.user_message,
        type: "user",
      });
      chatHistory.push({
        id: chatHistory.length + 1,
        message: response.data.response,
        type: "bot",
      });
      let chatData = {
        sessionId: reqDto.session_id,
        chatHistory: chatHistory,
      };
      localStorage.setItem("zoyel-chat-data", JSON.stringify(chatData));
      setConversation(chatHistory);
    }
  };

  useEffect(() => {
    const chatDataSession = JSON.parse(localStorage.getItem("zoyel-chat-data"));
    console.log("useEffect chat", chatDataSession);
    if (chatDataSession) {
      console.log("useEffect chat", chatDataSession.chatHistory);
      setConversation(chatDataSession.chatHistory);
    }
    DashboardServices.getUserProfile(loginUserDetails.userId).then(
      (response) => {
        if (response.data && response.data.profileImageFileDataLink) {
          setProfileImage(
            "data:image/png;base64," + response.data.profileImageFileDataLink
          );
        } else {
          setProfileImage(
            "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/no-profile.png"
          );
        }
      }
    );
  }, []);

  const parseResponse = (content) => {
    let contentTemp = content.replaceAll("\n", "<br/>");
    return <div dangerouslySetInnerHTML={{ __html: contentTemp }} />;
  };

  const chatEndRef = React.useRef(null);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      console.log(
        "scroll",
        chatEndRef.current.scrollHeight,
        chatEndRef.current.scrollTop
      );
      chatEndRef.current.scrollTop = chatEndRef.current.scrollHeight;
    }
  };

  React.useEffect(() => {
    console.log("scroll", messagesCount);
    scrollToBottom();
  }, [messagesCount]);

  return (
    <>
      {showZoeyBotIoc && (
        <div className="zoeyBotInc" onClick={handleShowZoey}>
          <img src={ZoeyBotIoc} alt="zoey_bot_ioc" className="botIncIcon" />
        </div>
      )}

      {showZoey && (
        <div className="botFlotingContainerArea">
          <div className="botFlotingPanel">
            <div className="botFlotingContainer">
              <div class="botContHeader">
                <div class="botHeadText">
                  {/* <img src={ZoeyBotLogo} alt="zoyelBotLogo" /> */}
                  Zoey
                </div>
                <div class="botActionBtnGrp">
                  <div class="actionBtn closeBtnD10">
                    <IconButton
                      title="Clear conversation"
                      onClick={() => {
                        setConversation([]);
                        localStorage.removeItem("zoyel-chat-data");
                      }}
                    >
                      <Trash className="trashZoey" />
                    </IconButton>

                    <IconButton onClick={handleCloseZoey}>
                      <XLg />
                    </IconButton>
                  </div>
                </div>
              </div>
              <div className="botContContain">
                <div className="botContContainDetails" ref={chatEndRef}>
                  <div className="botMsg" key={-1}>
                    <div className="bMsg msgFrmBot">
                      <div className="msgImg">
                        <img src={ZoeyBotLogo} alt="bot_Img" />
                      </div>
                      <div className="msgBody">
                        <div className="msgUserNm">Zoey</div>
                        <div className="msgBodyTxt">
                          {parseResponse(
                            "Hi, I am Zoey, how can i help you today?"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {conversation.map((convDto) => {
                    if (convDto.type === "user") {
                      return (
                        <div className="botMsg" key={convDto.id}>
                          <div className="bMsg msgFrmUser">
                            <div className="msgImg">
                              <img src={profileImage} alt="user_Img" />
                            </div>
                            <div className="msgBody">
                              <div className="msgUserNm">You</div>
                              <div className="msgBodyTxt">
                                <p>{convDto.message}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="botMsg" key={convDto.id}>
                          <div className="bMsg msgFrmBot">
                            <div className="msgImg">
                              <img src={ZoeyBotLogo} alt="user_Img" />
                            </div>
                            <div className="msgBody">
                              <div className="msgUserNm">Zoey</div>
                              <div className="msgBodyTxt">
                                <p>{parseResponse(convDto.message)}</p>
                                <div className="zoeyActions">
                                  <Tooltip
                                    title={"Copy"}
                                    placement="top-end"
                                    arrow
                                    TransitionComponent={Zoom}
                                  >
                                    <CopyAllOutlined
                                      onClick={() => {
                                        toast.success("Response copied");
                                        navigator.clipboard.writeText(
                                          convDto.message.replaceAll(
                                            "\n",
                                            "<br/>"
                                          )
                                        );
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                  {loadingReply && (
                    <div className="botMsg" key={0}>
                      <div className="bMsg msgFrmBot">
                        <div className="msgImg">
                          <img src={ZoeyBotLogo} alt="bot_Img" />
                        </div>
                        <div className="msgBody">
                          <div className="msgUserNm">Zoey</div>
                          <div className="msgBodyTxt">
                            <Skeleton
                              variant="text"
                              sx={{
                                fontSize: "0.9rem",
                                bgcolor: "#ff9900",
                                width: "31vw",
                                lineHeight: "20px",
                              }}
                            />
                            <Skeleton
                              variant="text"
                              sx={{
                                fontSize: "1rem",
                                bgcolor: "#ff9900",
                                width: "31vw",
                                lineHeight: "20px",
                              }}
                            />
                            <Skeleton
                              variant="text"
                              sx={{
                                fontSize: "1rem",
                                bgcolor: "#ff9900",
                                width: "14vw",
                                lineHeight: "20px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="botContContain">
                <div className="botContContainDetails">
                  <div className="botMsg">
                    <div className="bMsg msgFrmUser">
                      <div className="msgImg">
                        <img src={userImg} alt="user_Img" />
                      </div>
                      <div className="msgBody">
                        <div className="msgUserNm">You</div>
                        <div className="msgBodyTxt">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="botMsg">
                    <div className="bMsg msgFrmBot">
                      <div className="msgImg">
                        <img src={ZoeyBotLogo} alt="user_Img" />
                      </div>
                      <div className="msgBody">
                        <div className="msgUserNm">Zoey</div>
                        <div className="msgBodyTxt">
                          <p>
                            What is Lorem Ipsum? Lorem Ipsum is simply dummy
                            text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text
                            ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type
                            specimen book. It has survived not only five
                            centuries, but also the leap into electronic
                            typesetting, remaining essentially unchanged. It was
                            popularised in the 1960s with the release of
                            Letraset sheets containing Lorem Ipsum passages, and
                            more recently with desktop publishing software like
                            Aldus PageMaker including versions of Lorem Ipsum.
                          </p>
                          <p>
                            Why do we use it? It is a long established fact that
                            a reader will be distracted by the readable content
                            of a page when looking at its layout. The point of
                            using Lorem Ipsum is that it has a more-or-less
                            normal distribution of letters, as opposed to using
                            'Content here, content here', making it look like
                            readable English. Many desktop publishing packages
                            and web page editors now use Lorem Ipsum as their
                            default model text, and a search for 'lorem ipsum'
                            will uncover many web sites still in their infancy.
                            Various versions have evolved over the years,
                            sometimes by accident, sometimes on purpose
                            (injected humour and the like).
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="botMsg">
                    <div className="bMsg msgFrmUser">
                      <div className="msgImg">
                        <img src={userImg} alt="user_Img" />
                      </div>
                      <div className="msgBody">
                        <div className="msgUserNm">You</div>
                        <div className="msgBodyTxt">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="botMsg">
                    <div className="bMsg msgFrmBot">
                      <div className="msgImg">
                        <img src={ZoeyBotLogo} alt="user_Img" />
                      </div>
                      <div className="msgBody">
                        <div className="msgUserNm">Zoey</div>
                        <div className="msgBodyTxt">
                          <p>
                            What is Lorem Ipsum? Lorem Ipsum is simply dummy
                            text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text
                            ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type
                            specimen book. It has survived not only five
                            centuries, but also the leap into electronic
                            typesetting, remaining essentially unchanged. It was
                            popularised in the 1960s with the release of
                            Letraset sheets containing Lorem Ipsum passages, and
                            more recently with desktop publishing software like
                            Aldus PageMaker including versions of Lorem Ipsum.
                          </p>
                          <p>
                            Why do we use it? It is a long established fact that
                            a reader will be distracted by the readable content
                            of a page when looking at its layout. The point of
                            using Lorem Ipsum is that it has a more-or-less
                            normal distribution of letters, as opposed to using
                            'Content here, content here', making it look like
                            readable English. Many desktop publishing packages
                            and web page editors now use Lorem Ipsum as their
                            default model text, and a search for 'lorem ipsum'
                            will uncover many web sites still in their infancy.
                            Various versions have evolved over the years,
                            sometimes by accident, sometimes on purpose
                            (injected humour and the like).
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="botMsg">
                    <div className="bMsg msgFrmUser">
                      <div className="msgImg">
                        <img src={userImg} alt="user_Img" />
                      </div>
                      <div className="msgBody">
                        <div className="msgUserNm">You</div>
                        <div className="msgBodyTxt">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="botMsg">
                    <div className="bMsg msgFrmBot">
                      <div className="msgImg">
                        <img src={ZoeyBotLogo} alt="user_Img" />
                      </div>
                      <div className="msgBody">
                        <div className="msgUserNm">Zoey</div>
                        <div className="msgBodyTxt">
                          <p>
                            What is Lorem Ipsum? Lorem Ipsum is simply dummy
                            text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text
                            ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type
                            specimen book. It has survived not only five
                            centuries, but also the leap into electronic
                            typesetting, remaining essentially unchanged. It was
                            popularised in the 1960s with the release of
                            Letraset sheets containing Lorem Ipsum passages, and
                            more recently with desktop publishing software like
                            Aldus PageMaker including versions of Lorem Ipsum.
                          </p>
                          <p>
                            Why do we use it? It is a long established fact that
                            a reader will be distracted by the readable content
                            of a page when looking at its layout. The point of
                            using Lorem Ipsum is that it has a more-or-less
                            normal distribution of letters, as opposed to using
                            'Content here, content here', making it look like
                            readable English. Many desktop publishing packages
                            and web page editors now use Lorem Ipsum as their
                            default model text, and a search for 'lorem ipsum'
                            will uncover many web sites still in their infancy.
                            Various versions have evolved over the years,
                            sometimes by accident, sometimes on purpose
                            (injected humour and the like).
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="botMsg">
                    <div className="bMsg msgFrmUser">
                      <div className="msgImg">
                        <img src={userImg} alt="user_Img" />
                      </div>
                      <div className="msgBody">
                        <div className="msgUserNm">You</div>
                        <div className="msgBodyTxt">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="botMsg">
                    <div className="bMsg msgFrmBot">
                      <div className="msgImg">
                        <img src={ZoeyBotLogo} alt="user_Img" />
                      </div>
                      <div className="msgBody">
                        <div className="msgUserNm">Zoey</div>
                        <div className="msgBodyTxt">
                          <p>
                            What is Lorem Ipsum? Lorem Ipsum is simply dummy
                            text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text
                            ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type
                            specimen book. It has survived not only five
                            centuries, but also the leap into electronic
                            typesetting, remaining essentially unchanged. It was
                            popularised in the 1960s with the release of
                            Letraset sheets containing Lorem Ipsum passages, and
                            more recently with desktop publishing software like
                            Aldus PageMaker including versions of Lorem Ipsum.
                          </p>
                          <p>
                            Why do we use it? It is a long established fact that
                            a reader will be distracted by the readable content
                            of a page when looking at its layout. The point of
                            using Lorem Ipsum is that it has a more-or-less
                            normal distribution of letters, as opposed to using
                            'Content here, content here', making it look like
                            readable English. Many desktop publishing packages
                            and web page editors now use Lorem Ipsum as their
                            default model text, and a search for 'lorem ipsum'
                            will uncover many web sites still in their infancy.
                            Various versions have evolved over the years,
                            sometimes by accident, sometimes on purpose
                            (injected humour and the like).
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="botContFooter">
                <div className="botInputGrp">
                  {/* <div className="botInputbtnGrp">
                    <IconButton className="botAIBtn">
                      <MicFill />
                    </IconButton>
                    <IconButton className="botAIBtn">
                      <ImageFill />
                    </IconButton>
                  </div> */}
                  <input
                    ref={inputRef}
                    type="text"
                    className="botComntInput"
                    id="botComntInput"
                    placeholder="How may I help you ?"
                    onKeyDown={(event) => {
                      if (event.keyCode === 13) {
                        sendMessage();
                      }
                    }}
                  />
                  <IconButton
                    className="botComtSndBtn"
                    onClick={() => {
                      sendMessage();
                    }}
                  >
                    <Send />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
