import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { enLang } from "./languages/en-lang";

export default i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enLang },
  },
  lng: "en",
  fallbacking: "en",
  interpolation: { escapeValue: false },
});
