import { ThumbUpAltRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function LandscapeOriantation() {
  const [visible, setVisible] = useState(false);
  const [timer, setTimer] = useState(null);

  const handleOrientationChange = (e) => {
    if (e.matches) {
      const newTimer = setTimeout(() => {
        setVisible(true);
      }, 2000);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setVisible(false);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: landscape)");
    mediaQuery.addEventListener("change", handleOrientationChange);
    return () => {
      mediaQuery.removeEventListener("change", handleOrientationChange);
      clearTimeout(timer);
    };
  }, [timer]);

  const handleClose = () => {
    setVisible(false);
    clearTimeout(timer);
  };
  return (
    <>
      {visible && (
        <div className="landscapeContainer">
          <div className="lcContain">
            <div className="warningText">
              Use portrait mode for better experience
            </div>
            <Button
              startIcon={<ThumbUpAltRounded />}
              className="gotitBtn"
              onClick={handleClose}
            >
              Got It
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
