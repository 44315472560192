import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  CheckCircle,
  Person,
  PersonAdd,
  PersonCheck,
  XLg,
} from "react-bootstrap-icons";
import lrLogin from "../images/login.png";
import {
  ArrowForward,
  Visibility,
  VisibilityOff,
  CancelOutlined,
  Cancel,
  CheckCircleOutline,
} from "@mui/icons-material";
import LoginSignupService from "../../../services/LoginSignupService";
import { toast } from "react-toastify";

import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  PERSONAL_ORG_ID,
  SELECT_MenuProps,
} from "../../../constants/const_string";
import { getEventDetailsByMeetingId } from "../../../services/MeetingService";
import { v4 } from "uuid";
import { getLoginUserDetails, validateEmail } from "../../../utils/Utils";
import { jwtDecode } from "jwt-decode";

export default function LoginMeetingLinks({ handelCloseLogRegContain }) {
  //prashant

  //
  //

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPasswordLogin = (event) => {
    event.preventDefault();
  };

  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [showForgotPassForm, setShowForgotPassForm] = useState(false);

  const [otpSU, setOtpSU] = useState(null);
  const [otpIdSU, setOtpIdSU] = useState(null);
  const [fetchingOrgListSU, setFetchingOrgListSU] = useState(false);
  const [selectOrgSU, setSelectOrgSU] = React.useState("");
  const [otpValidatedSU, setOtpValidatedSU] = useState(false);

  const handelOpenLogin = () => {
    setShowLoginForm(true);
    setShowRegistrationForm(false);
    setShowForgotPassForm(false);
  };
  const handelOpenRegistration = () => {
    setShowLoginForm(false);
    setShowRegistrationForm(true);
    setShowForgotPassForm(false);
  };

  const handelOpenForgotPassword = () => {
    setUserLoginId(null);
    setShowForgotPassForm(true);
    // setShowLoginForm(false);
    setShowRegistrationForm(false);
  };

  // const handelOpenForgotPassword = () => {
  //   setUserLoginId(null);
  //   setShowForgotPassForm(true);
  //   // setShowLoginForm(false);
  //   setShowRegistrationForm(false);
  //   const domainArray = host.split(".");
  //   console.log('domainArray' , domainArray);
  // };

  // register user password start

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userLoginIdSU, setUserLoginIdSU] = useState(null);

  const [userSignUpFullName, setUserSignUpFullName] = useState("");
  const [userSignUpEmailId, setUserSignUpEmailId] = useState("");

  const [forgtEmailValue, setForgtEmailValue] = useState("");
  const [loadingforEmailValue, setLoadingForEmailValue] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [orgListFP, setOrgListFP] = useState([]);
  const [selectOrgFP, setSelectOrgFP] = React.useState("");

  const [sendingOtp, setSendingOtp] = useState(false);
  const [validatingOtp, setValidatingOtp] = useState(false);

  const [isValid, setIsValid] = useState(false);
  const [fetchingOrgListFP, setFetchingOrgListFP] = useState(false);
  const [userLoginIdFP, setUserLoginIdFP] = useState(null);

  const [otp, setOtp] = useState(null);
  const [otpId, setOtpId] = useState(null);
  const [showSignupLink, setShowSignUpLink] = useState(false);
  const [orgNotFound, setOrgNotFound] = useState(false);

  const sendOTP = async () => {
    const selectedOrgFP = orgListFP.find((obj) => obj.orgId === selectOrgFP);
    setSendingOtp(true);
    const resOtp = await LoginSignupService.generateOtpForPasswordResetNew(
      forgtEmailValue.toLocaleLowerCase(),
      selectedOrgFP.subdomain
    );

    console.log(resOtp.data);
    if (resOtp.data.returnValue === "1") {
      setOtpId(resOtp.data.message);
      toast.info(
        "Otp has been sent successfully, please check your spam folder if not received"
      );
    } else {
      toast.error("Something went wrong!");
    }
    setSendingOtp(false);
  };

  const handleChangeSelectOrgFP = (event) => {
    console.log(event.target.value);
    if (otpId) {
      setOtpId(null);
    }

    if (otpValidated) {
      setOtpValidated(false);
      // setNewPassword(null);
      // setConfirmPassword(null);
      setOtp(null);
      setOtpId(null);
    }
    setSelectOrgFP(event.target.value);
  };

  const saveCredential = ({ username, password }) => {
    if (window.PasswordCredential && navigator.credentials) {
      const credentials = new window.PasswordCredential({
        id: username,
        password: password,
      });

      return navigator.credentials.store(credentials);
    }
  };

  const initializeExistingUser = () => {
    setUserPassword("");
    setUserLoginId("");
    setShowForgotPassForm(false);
    setOrgListFP([]);
    setSelectOrgFP("");
    setOtpId(null);
    setOtpValidated(false);
    setValidatingOtp(false);
    setIsValid(false);
    setShowConfirmPassword(false);
    setNewPassword("");
    setConfirmPassword("");
    setForgtEmailValue("");
    setShowSignUpLink(false);
    // setUserPassword("")
  };

  const getAllOrgsAssociatedWithUserFP = async () => {
    setFetchingOrgListFP(true);

    if (!userLoginIdFP || userLoginIdFP.trim() === "") {
      toast.error("Please enter your Email id");
      // loginIdRef.current.focus();
      setFetchingOrgListFP(false);
      return false;
    }

    if (!validateEmail(userLoginIdFP)) {
      toast.error("Please enter a valid Email id");
      setFetchingOrgListFP(false);
      return false;
    }

    console.log("getAllOrgsAssociatedWithUserFP", userLoginId);
    const resOrgList = await LoginSignupService.getOrgListByLoginId(
      userLoginIdFP.toLowerCase()
    );
    console.log(resOrgList.data);
    setFetchingOrgListFP(false);
    if (resOrgList.data.length > 0) {
      const selOrg = resOrgList.data.find(
        (obj) => obj.orgId === PERSONAL_ORG_ID
      );
      if (!selOrg) {
        setSelectOrgFP(resOrgList.data[0].orgId);
      } else {
        setSelectOrgFP(
          resOrgList.data.find((obj) => obj.orgId === PERSONAL_ORG_ID).orgId
        );
      }

      setOrgListFP(resOrgList.data);
    } else {
      setOrgListFP([]);
      toast.error("No accounts were found for this email id");
    }
  };

  const validateOTP = async () => {
    if (!otp || otp.trim() === "") {
      toast.error("Please enter your OTP");
      return false;
    }
    setValidatingOtp(true);
    const resOTP = await LoginSignupService.validateOtp(otpId, otp);
    if (resOTP.data.returnValue === "-1") {
      toast.error(resOTP.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtp(false);
    } else if (resOTP.data.returnValue === "0") {
      toast.error(resOTP.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtp(false);
    } else {
      toast.info(`OTP verified successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtp(false);
      setOtpValidated(true);
    }
  };

  const getDetailsOfUser = async () => {
    setLoadingForEmailValue(true);

    if (!forgtEmailValue || forgtEmailValue.trim() === "") {
      toast.error("Please enter your Email id");
      setLoadingForEmailValue(false);
      return false;
    }

    if (!validateEmail(forgtEmailValue)) {
      toast.error("Please enter a valid Email id");
      setLoadingForEmailValue(false);
      return false;
    }

    const resOrgList = await LoginSignupService.getOrgListByLoginId(
      forgtEmailValue.toLowerCase()
    );
    console.log("resOrgList ------", resOrgList.data);
    setLoadingForEmailValue(false);

    setOrgListFP(resOrgList.data);
    if (resOrgList.data.length === 0) {
      toast.error(
        "No organization associated with this email address.Please Check Email or Sign up"
      );
      setShowSignUpLink(true);
      setOrgNotFound(true);

      return;
    } else {
      setShowSignUpLink(false);
      setOrgNotFound(false);
    }
    setOtp(null);
    setOtpId(null);

    // const domainArray = host.split(".");

    // let subdomain = null;
    // if (domainArray.length === 3) {
    //   subdomain = domainArray[0];
    // }

    if (domainArray.length === 2) {
      const personalOrg = resOrgList.data.find(
        (org) => org.orgId === "ORG000000000000"
      );

      if (personalOrg) {
        setSelectOrgFP(personalOrg.orgId);
      } else {
        setShowSignUpLink(true);
        setOrgNotFound(false);
        return;
      }
    } else if (domainArray.length === 3) {
      const subdomain = domainArray[0];
      const orgDetail = resOrgList.data.find(
        (org) => org.subdomain === subdomain
      );
      if (orgDetail) {
        setSelectOrgFP(orgDetail.orgId);
      } else {
        setShowSignUpLink(true);
        setOrgNotFound(false);
        return;
      }
    }
  };

  const changePassword = async () => {
    const selectedOrgFP = orgListFP.find((obj) => obj.orgId === selectOrgFP);
    let reqDto = {
      userPassword: newPassword,
      userEmailId: forgtEmailValue.toLocaleLowerCase(),
      subdomain: selectedOrgFP.subdomain ? selectedOrgFP.subdomain : "",
    };
    const resChangePass = await LoginSignupService.changePassword(reqDto);

    if (resChangePass.data.returnValue === "0") {
      toast.error(resChangePass.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // setLoading(false);
    } else {
      toast.info(resChangePass.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

      try {
        await saveCredential({
          username: forgtEmailValue,
          password: newPassword,
        });
      } catch (e) {
        console.log(" credential save failed");
      }

      setOtpValidated(false);
      // setNewPassword(null);
      // setConfirmPassword(null);
      setOtp(null);
      setOtpId(null);
      setShowForgotPassForm(false);
      setUserLoginIdFP(null);
      setOrgListFP([]);
      setShowLoginForm(true);
    }
  };

  // to check if all password policy conditions are met
  //   useEffect(() => {
  //     const checkPasswordPolicy = () => {
  //       const hasUpperCase = newPassword.match(/[A-Z]/);
  //       const hasLowerCase = newPassword.match(/[a-z]/);
  //       const hasNumber = newPassword.match(/[0-9]/);
  //       const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
  //       const isMinLength = newPassword.length >= 8;
  //       const isMatching = newPassword === confirmPassword;

  //       return (
  //         hasUpperCase &&
  //         hasLowerCase &&
  //         hasNumber &&
  //         hasSpecialChar &&
  //         isMinLength &&
  //         isMatching
  //       );
  //     };

  //     setIsValid(checkPasswordPolicy());
  //   }, [newPassword, confirmPassword]);

  useEffect(() => {
    const checkPasswordPolicy = () => {
      const hasUpperCase = newPassword.match(/[A-Z]/);
      const hasLowerCase = newPassword.match(/[a-z]/);
      const hasNumber = newPassword.match(/[0-9]/);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
      const isMinLength = newPassword.length >= 8;
      const isMatching = newPassword === confirmPassword;

      return (
        hasUpperCase &&
        hasLowerCase &&
        hasNumber &&
        hasSpecialChar &&
        isMinLength &&
        isMatching
      );
    };

    setIsValid(checkPasswordPolicy());
  }, [newPassword, confirmPassword]);

  const signupUser = async () => {
    const domainArray = host.split(".");
    let loggedInUserDtls = null;
    let subdomain = null;
    if (domainArray.length === 3) {
      subdomain = domainArray[0];

      console.log("************ signUpUser ********************** ");
      const res = await LoginSignupService.getOrgDetailsBySubdomain(subdomain);
      setSelOrgDetails(res.data);
      loggedInUserDtls = res.data;
      console.log(
        "************ signUpUser response********************** ",
        res
      );
    }

    if (domainArray.length === 2 || host.includes("localhost")) {
      let reqDto = {
        userName:
          userName && userName !== "" && userName.replace(/\s+/g, " ").trim(),
        userEmailId: userLoginIdSU.toLowerCase(),
        userPassword: newPassword,
        userType: "ADMIN",
        loginMode: "WEB",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        linkMeetingId: state?.eventData?.eventLink,
        // referralLinkId: null != refContactData ? refContactData.inviteLink : null,
      };
      console.log("signupUser(reqDto)", reqDto);
      setIsValid(false);
      const resSignup = await LoginSignupService.signupUser(reqDto);
      if (resSignup.data.loginReturnValue === "0") {
        toast.error(resSignup.data.loginMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.info(resSignup.data.loginMessage + ", rejoining the meeting...", {
          position: toast.POSITION.TOP_RIGHT,
        });

        let reqDto = {
          username: userLoginIdSU.toLowerCase() + "~" + PERSONAL_ORG_ID,
          password: newPassword,
        };

        console.log("signupdto", reqDto, state);
        const resLogin = await LoginSignupService.userLogin(reqDto);
        localStorage.clear();
        localStorage.setItem("token", resLogin.data.token);
        const tokenData = jwtDecode(resLogin.data.token);
        localStorage.setItem("userProfileImg", tokenData.userProfileImage);
        if (eventData) {
          if (eventData.eventId) {
            navigate(`/dashboard`, {
              state: {
                eventData: eventData,
              },
            });
          } else {
            toast.error("The meeting link is invalid", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setCheckingCreds(false);
          }
        } else {
          getEventDetailsByMeetingId(searchParams.get("roomId")).then(
            (response2) => {
              console.log("joinVideoRoom", response2);
              const eventDto = {
                eventTitle: response2.data.field1,
                eventDescription: response2.data.field2,
                eventStartTime: response2.data.field3,
                eventEndTime: response2.data.field4,
                eventId: response2.data.field5,
                eventType: response2.data.field6,
                length: parseInt(response2.data.field7),
                eventLink: searchParams.get("roomId"),
              };
              if (eventDto.field5) {
                navigate(`/dashboard`, {
                  state: {
                    eventData: eventDto,
                  },
                });
              } else {
                toast.error("The meeting link is invalid", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                setCheckingCreds(false);
              }
            }
          );
        }
      }
    } else {
      let reqDto = {
        userName:
          userName && userName !== "" && userName.replace(/\s+/g, " ").trim(),
        userEmailId: userLoginIdSU.toLowerCase(),
        userPassword: newPassword,
        userType: null,
        loginMode: "WEB",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        linkMeetingId: state?.eventData?.eventLink,
        orgId: loggedInUserDtls.orgId,
        // referralLinkId: null != refContactData ? refContactData.inviteLink : null,
      };
      console.log("signupUser(reqDto)", reqDto);

      // return;
      setIsValid(false);
      const resSignup = await LoginSignupService.createAccountOrgUser(reqDto);
      if (resSignup.data.loginReturnValue === "0") {
        toast.error(resSignup.data.loginMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.info(resSignup.data.loginMessage + ", rejoining the meeting...", {
          position: toast.POSITION.TOP_RIGHT,
        });

        let reqDto = {
          username: userLoginIdSU.toLowerCase() + "~" + loggedInUserDtls.orgId,
          password: newPassword,
        };

        console.log("signupdto", reqDto, state);
        const resLogin = await LoginSignupService.userLogin(reqDto);

        try {
          await saveCredential({
            username: userLoginIdSU.toLowerCase(),
            password: newPassword,
          });
        } catch (e) {
          console.log(" credential save failed");
        }
        localStorage.clear();
        localStorage.setItem("token", resLogin.data.token);
        const tokenData = jwtDecode(resLogin.data.token);
        localStorage.setItem("userProfileImg", tokenData.userProfileImage);
        if (eventData) {
          if (eventData.eventId) {
            navigate(`/dashboard`, {
              state: {
                eventData: eventData,
              },
            });
          } else {
            toast.error("The meeting link is invalid", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setCheckingCreds(false);
          }
        } else {
          getEventDetailsByMeetingId(searchParams.get("roomId")).then(
            (response2) => {
              console.log("joinVideoRoom", response2);
              const eventDto = {
                eventTitle: response2.data.field1,
                eventDescription: response2.data.field2,
                eventStartTime: response2.data.field3,
                eventEndTime: response2.data.field4,
                eventId: response2.data.field5,
                eventType: response2.data.field6,
                length: parseInt(response2.data.field7),
                eventLink: searchParams.get("roomId"),
              };
              if (eventDto.field5) {
                navigate(`/dashboard`, {
                  state: {
                    eventData: eventDto,
                  },
                });
              } else {
                toast.error("The meeting link is invalid", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                setCheckingCreds(false);
              }
            }
          );
        }
      }
    }
  };

  const validateOTPSU = async () => {
    if (!otpSU || otpSU.trim() === "") {
      toast.error("Please enter your OTP");
      return false;
    }
    const resOTP = await LoginSignupService.validateOtp(otpIdSU, otpSU);
    if (resOTP.data.returnValue === "-1") {
      toast.error(resOTP.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (resOTP.data.returnValue === "0") {
      toast.error(resOTP.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.info(`OTP verified successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      setOtpValidatedSU(true);
    }
  };

  const getAllOrgsAssociatedWithUserSU = async () => {
    // toast.info(
    //   "Otp has been sent successfully, please check your spam folder if not received"
    // );
    setFetchingOrgListSU(true);

    if (!userLoginIdSU || userLoginIdSU.trim() === "") {
      toast.error("Please enter your Email id");
      setFetchingOrgListSU(false);
      return false;
    }

    if (!validateEmail(userLoginIdSU)) {
      toast.error("Please enter a valid Email id");
      setFetchingOrgListSU(false);
      return false;
    }

    if (!userName || userName.trim() === "") {
      toast.error("Please enter your name");
      setFetchingOrgListSU(false);
      return false;
    }

    console.log("getAllOrgsAssociatedWithUserSU", userLoginId);
    const resPerProfileExists =
      await LoginSignupService.checkIfPersonalProfileExists(
        userLoginIdSU.toLowerCase()
      );
    console.log(resPerProfileExists.data);
    if (resPerProfileExists.data.returnValue === "1") {
      toast.error(resPerProfileExists.data.message);
      setFetchingOrgListSU(false);
    } else {
      toast.info("Sending OTP, please wait....");
      const resSendOtp = await LoginSignupService.generateOtpForEmailValidation(
        userLoginIdSU.toLowerCase(),
        "NA"
      );

      if (resSendOtp.data.returnValue === "1") {
        toast.info(
          "Otp has been sent successfully, please check your spam folder if not received"
        );
        setOtpIdSU(resSendOtp.data.message);
        setFetchingOrgListSU(false);
      } else {
        toast.error("Something went wrong");
      }

      console.log(resSendOtp.data);
    }
  };

  const handleChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
    getPasswordStrength(event.target.value);
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getPasswordStrength = (password) => {
    const meterBar = document.getElementById("meter-bar");
    const meterStatus = document.getElementById("meter-status");

    let strength = 0;

    if (password.match(/[A-Z]/)) {
      strength += 1;
    }

    if (password.match(/[a-z]/)) {
      strength += 1;
    }

    if (password.match(/[0-9]/)) {
      strength += 1;
    }

    if (password.match(/[!@#$%^&*(),.?":{}|<>]/)) {
      strength += 1;
    }

    if (password.length >= 16) {
      strength += 1;
    }

    if (password.length < 8) {
      meterBar.style.background = "#a7a7a7";
      meterBar.style.width = "5%";
      meterStatus.style.color = "#a7a7a7";
      meterStatus.innerText = "Poor";
      return;
    }

    if (strength === 3) {
      meterBar.style.background = "#e6ae01";
      meterBar.style.width = "50%";
      meterStatus.style.color = "#e6ae01";
      meterStatus.innerText = "Medium";
    } else if (strength === 4) {
      meterBar.style.background = "#0f98e2";
      meterBar.style.width = "75%";
      meterStatus.style.color = "#0f98e2";
      meterStatus.innerText = "Strong";
    } else if (strength === 5) {
      meterBar.style.background = "#00ff33";
      meterBar.style.width = "100%";
      meterStatus.style.color = "#00ff33";
      meterStatus.innerText = "Very Strong";
    } else {
      meterBar.style.background = "#ff0000";
      meterBar.style.width = "25%";
      meterStatus.style.color = "#ff0000";
      meterStatus.innerText = "Weak";
    }
  };

  // register user password end

  //login section

  //login id is email id
  const [userLoginId, setUserLoginId] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [orgList, setOrgList] = useState([]);
  const [fetchingOrgList, setFetchingOrgList] = useState(false);
  const loginIdRef = useRef(null);
  const loginForgotRef = useRef(null);
  const [checkingCreds, setCheckingCreds] = useState(false);

  const getAllOrgsAssociatedWithUser = async () => {
    setFetchingOrgList(true);

    if (!userLoginId || userLoginId.trim() === "") {
      toast.error("Please enter your Email id");
      loginIdRef.current.focus();
      setFetchingOrgList(false);
      return false;
    }

    console.log("getAllOrgsAssociatedWithUser", userLoginId);
    const resOrgList = await LoginSignupService.getOrgListByLoginId(
      userLoginId.toLowerCase()
    );
    console.log(resOrgList.data);
    setFetchingOrgList(false);
    if (resOrgList.data.length > 0) {
      console.log(resOrgList.data.find((obj) => obj.orgId === PERSONAL_ORG_ID));
      setSelectOrg(
        resOrgList.data.find((obj) => obj.orgId === PERSONAL_ORG_ID).orgId
      );
      setOrgList(resOrgList.data);
    } else {
      setOrgList([]);
      toast.error("No accounts were found for this email id");
    }
  };

  const [selectOrg, setSelectOrg] = React.useState("");
  const handleChangeSelectOrg = (event) => {
    console.log(event.target.value);
    setSelectOrg(event.target.value);
  };

  const loginPersonalUser = async () => {
    if (!userLoginId || userLoginId.trim() === "") {
      toast.error("Please enter your Email id");
      loginIdRef.current.focus();
      setFetchingOrgList(false);
      return false;
    }

    if (!userPassword || userPassword.trim() === "") {
      toast.error("Please enter your password");
      return false;
    }

    if (!selectOrg) {
      toast.error("Please select an organization to login");
      return false;
    }

    setCheckingCreds(true);

    let reqDto = {
      username: userLoginId.toLowerCase() + "~" + selectOrg,
      password: userPassword,
    };
    console.log("onSubmitSignIn", reqDto);
    try {
      const resLogin = await LoginSignupService.userLogin(reqDto);

      if (resLogin.status === 401 || resLogin.data === "INVALID_CREDENTIALS") {
        toast.error("Invalid credentials", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setCheckingCreds(false);
        return;
      }

      if (selectOrg === PERSONAL_ORG_ID) {
        console.log("personal", resLogin.data);
        localStorage.clear();
        localStorage.setItem("token", resLogin.data.token);
        const tokenData = jwtDecode(resLogin.data.token);
        localStorage.setItem("userProfileImg", tokenData.userProfileImage);
        navigate(`/dashboard`, {
          state: {
            // userName: resLogin.data.userName,
            // userId: resLogin.data.userId,
            // referralLinkId:
            //   null != refContactData ? refContactData.inviteLink : null,
          },
        });
      } else {
        console.log("org user", resLogin.data);
        const selectedOrg = orgList.find((obj) => obj.orgId === selectOrg);
        toast.success("Login successful, redirecting to organization website");
        setTimeout(() => {
          window.open(
            window.location.protocol +
              "//" +
              selectedOrg.subdomain +
              "." +
              window.location.host +
              "?uId=" +
              resLogin.data.token,
            "_blank"
          );
        }, 2000);

        setCheckingCreds(false);
        setOrgList([]);
      }
    } catch (err) {
      toast.error("Invalid credentials", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setCheckingCreds(false);
    }
  };
  //
  //

  //login with room id code
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [roomId, setRoomId] = useState(null);
  const [selOrgDetails, setSelOrgDetails] = useState(null);
  const [userName, setUserName] = useState(null);
  const [eventData, setEventData] = useState(null);
  useEffect(() => {
    if (searchParams.get("roomId")) {
      setRoomId(searchParams.get("roomId"));
    } else {
      console.log("room id not found");
    }
    console.log("roomId link", searchParams.get("roomId"));

    if (subdomain) {
      LoginSignupService.getOrgDetailsBySubdomain(subdomain).then(
        (response) => {
          console.log(response.data);
          setSelOrgDetails(response.data);
        }
      );
    } else {
      setSelOrgDetails({ orgName: "Personal" });
    }

    getEventDetailsByMeetingId(searchParams.get("roomId")).then((response2) => {
      console.log("joinVideoRoom", response2);
      const eventDto = {
        eventTitle: response2.data.field1,
        eventDescription: response2.data.field2,
        eventStartTime: response2.data.field3,
        eventEndTime: response2.data.field4,
        eventId: response2.data.field5,
        eventType: response2.data.field6,
        length: parseInt(response2.data.field7),
        eventLink: searchParams.get("roomId"),
      };
      if (localStorage.getItem("token") != null) {
        // const loggedInUserDtls = getLoginUserDetails();
        console.log("joinVideoRoom", eventDto);
        if (eventDto?.eventId) {
          navigate(`/dashboard`, {
            state: {
              eventData: eventDto,
            },
          });
        } else {
          toast.error("The meeting link is invalid", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCheckingCreds(false);
        }
      }
      setEventData(eventDto);
    });
  }, []);

  let host = window.location.host.replace("www.", "");
  const domainArray = host.split(".");
  let subdomain = null;
  if (domainArray.length === 3) {
    subdomain = domainArray[0];
  }

  const loginAndJoinMeeting = async () => {
    console.log("roomId link", roomId, subdomain);
    if (!userLoginId || userLoginId.trim() === "") {
      toast.error("Please enter your Email id");
      loginIdRef.current.focus();
      setFetchingOrgList(false);
      return false;
    }

    if (!userPassword || userPassword.trim() === "") {
      toast.error("Please enter your password");
      return false;
    }

    setCheckingCreds(true);
    let orgId = PERSONAL_ORG_ID;
    if (subdomain) {
      const resGetOrgs = await LoginSignupService.getOrgListByLoginId(
        userLoginId.toLowerCase()
      );

      if (resGetOrgs.data && resGetOrgs.data.length > 0) {
        console.log(resGetOrgs.data);
        const orgDetails = resGetOrgs.data.find(
          (obj) => obj.subdomain === subdomain
        );
        if (orgDetails) {
          orgId = orgDetails.orgId;
        } else {
          toast.error("Invalid credentials, please join as guest", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCheckingCreds(false);
          return;
        }
      } else {
        toast.error("Invalid credentials, please join as guest", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setCheckingCreds(false);
        return;
      }
    }

    let reqDto = {
      username: userLoginId.toLowerCase() + "~" + orgId,
      password: userPassword,
    };
    console.log("onSubmitSignIn", reqDto);

    // setCheckingCreds(false);
    // return;
    try {
      const resLogin = await LoginSignupService.userLogin(reqDto);

      if (resLogin.status === 401 || resLogin.data === "INVALID_CREDENTIALS") {
        toast.error("Invalid credentials", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setCheckingCreds(false);
        return;
      }

      localStorage.clear();
      localStorage.setItem("token", resLogin.data.token);
      const tokenData = jwtDecode(resLogin.data.token);
      localStorage.setItem("userProfileImg", tokenData.userProfileImage);
      console.log(eventData);
      if (eventData) {
        if (eventData.eventId) {
          try {
            await saveCredential({
              username: userLoginId,
              password: userPassword,
            });
          } catch (error) {
            console.log(error);
          }

          navigate(`/dashboard`, {
            state: {
              eventData: eventData,
            },
          });
        } else {
          toast.error("The meeting link is invalid", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCheckingCreds(false);
        }
      } else {
        getEventDetailsByMeetingId(searchParams.get("roomId")).then(
          async (response2) => {
            console.log("joinVideoRoom", response2);
            const eventDto = {
              eventTitle: response2.data.field1,
              eventDescription: response2.data.field2,
              eventStartTime: response2.data.field3,
              eventEndTime: response2.data.field4,
              eventId: response2.data.field5,
              eventType: response2.data.field6,
              length: parseInt(response2.data.field7),
              eventLink: searchParams.get("roomId"),
            };
            if (eventDto.field5) {
              try {
                await saveCredential({
                  username: userLoginId,
                  password: userPassword,
                });
              } catch (error) {
                console.log(error);
              }
              navigate(`/dashboard`, {
                state: {
                  eventData: eventDto,
                },
              });
            } else {
              toast.error("The meeting link is invalid", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setCheckingCreds(false);
            }
          }
        );
      }
    } catch (err) {
      toast.error("Invalid credentials", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setCheckingCreds(false);
    }
  };

  const joinAsGuest = async () => {
    console.log("joinAsGuest");

    if (!userName || userName.trim() === "") {
      toast.error("Please enter your name");
      return false;
    }

    setCheckingCreds(true);
    let orgId = PERSONAL_ORG_ID;
    if (subdomain) {
      const resGetOrgs = await LoginSignupService.getOrgDetailsBySubdomain(
        subdomain
      );
      orgId = resGetOrgs.data.orgId;
    }

    let reqDto = {
      username: `guest_${v4()}@guest.zoyel.one~${orgId}~${userName}`,
      password: userPassword,
    };
    console.log("onSubmitSignIn", reqDto);

    // setCheckingCreds(false);
    // return;
    try {
      const resLogin = await LoginSignupService.userLogin(reqDto);
      if (resLogin.status === 401 || resLogin.data === "INVALID_CREDENTIALS") {
        toast.error("Invalid credentials", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setCheckingCreds(false);
        return;
      }
      localStorage.clear();
      localStorage.setItem("token", resLogin.data.token);
      const tokenData = jwtDecode(resLogin.data.token);
      localStorage.setItem("userProfileImg", tokenData.userProfileImage);
      if (eventData) {
        if (eventData.eventId) {
          navigate(`/dashboardG`, {
            state: {
              eventData: eventData,
            },
          });
        } else {
          toast.error("The meeting link is invalid", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCheckingCreds(false);
        }
      } else {
        getEventDetailsByMeetingId(searchParams.get("roomId")).then(
          (response2) => {
            console.log("joinVideoRoom", response2);
            const eventDto = {
              eventTitle: response2.data.field1,
              eventDescription: response2.data.field2,
              eventStartTime: response2.data.field3,
              eventEndTime: response2.data.field4,
              eventId: response2.data.field5,
              eventType: response2.data.field6,
              length: parseInt(response2.data.field7),
              eventLink: searchParams.get("roomId"),
            };
            if (eventDto.field5) {
              navigate(`/dashboardG`, {
                state: {
                  eventData: eventDto,
                },
              });
            } else {
              toast.error("The meeting link is invalid", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setCheckingCreds(false);
            }
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  //tabs login start

  const [loginActiveTab, setLoginActiveTab] = useState("userTypeGuest");

  const handleLoginTabClick = (userType) => {
    if (loginActiveTab !== userType && userType === "userTypeExisting") {
      initializeExistingUser();
    }
    setLoginActiveTab(userType);
  };
  //tabs login end

  //

  return (
    <>
      <div className="logRegFullContainer">
        <div className="closelrCon">
          <IconButton onClick={handelCloseLogRegContain}>
            <XLg />
          </IconButton>
        </div>
        <div className="logRegContain">
          <div className="lrContainer">
            <div className="lrLeftContain">
              <div className="statLrImg">
                <img src={lrLogin} alt="statLrImg" />
              </div>
            </div>
            <div className="lrRightContain">
              {showLoginForm && (
                <div className="logRegForm">
                  <div className="lrHead">Join meeting</div>

                  <div className="loginTabs">
                    <div className="loginTabs-buttonsGrp">
                      <button
                        className={
                          loginActiveTab === "userTypeGuest"
                            ? "loginTabBtn active"
                            : "loginTabBtn"
                        }
                        onClick={() => handleLoginTabClick("userTypeGuest")}
                      >
                        <Person /> Guest User
                      </button>
                      <button
                        className={
                          loginActiveTab === "userTypeExisting"
                            ? "loginTabBtn active"
                            : "loginTabBtn"
                        }
                        onClick={() => handleLoginTabClick("userTypeExisting")}
                      >
                        <PersonCheck /> Existing user
                      </button>

                      <button
                        className={
                          loginActiveTab === "userTypeSignUp"
                            ? "loginTabBtn active"
                            : "loginTabBtn"
                        }
                        onClick={() => handleLoginTabClick("userTypeSignUp")}
                      >
                        <PersonAdd /> Sign Up
                      </button>
                    </div>
                    <div className="loginTabs-content">
                      {loginActiveTab === "userTypeGuest" && (
                        <>
                          <div className="fromGroup ">
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  label="Full Name"
                                  required
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  onChange={(event) =>
                                    setUserName(event.target.value)
                                  }
                                />
                              </FormControl>
                            </div>
                          </div>
                          {checkingCreds ? (
                            <Button variant="contained" className="lrButton">
                              <CircularProgress className="loading-bar-check-creds" />
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              className="lrButton"
                              onClick={joinAsGuest}
                            >
                              Join as guest
                            </Button>
                          )}
                        </>
                      )}
                      {loginActiveTab === "userTypeExisting" && (
                        <>
                          {!showForgotPassForm ? (
                            <>
                              <div className="fromGroup ">
                                <div className="formElement">
                                  <FormControl className="formControl">
                                    <InputLabel htmlFor="userEmailId">
                                      Email Id
                                    </InputLabel>
                                    <OutlinedInput
                                      ref={loginIdRef}
                                      id="userEmailId"
                                      className="formTextFieldArea areaWbtn"
                                      type="text"
                                      label="Email Id"
                                      value={userLoginId}
                                      autoComplete="off"
                                      onChange={(event) =>
                                        setUserLoginId(event.target.value)
                                      }
                                    />
                                  </FormControl>
                                </div>

                                <div className="formElement">
                                  <FormControl className="formControl">
                                    <InputLabel htmlFor="outlined-adornment-password">
                                      Password
                                    </InputLabel>
                                    <OutlinedInput
                                      id="outlined-adornment-password"
                                      className="formTextFieldArea areaPassword"
                                      type={showPassword ? "text" : "password"}
                                      value={userPassword}
                                      onChange={(event) =>
                                        setUserPassword(event.target.value)
                                      }
                                      autoComplete="new-password"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                              handleMouseDownPasswordLogin
                                            }
                                            edge="end"
                                          >
                                            {showPassword ? (
                                              <VisibilityOff />
                                            ) : (
                                              <Visibility />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      label="Password"
                                    />
                                  </FormControl>
                                </div>
                              </div>
                              {checkingCreds ? (
                                <Button
                                  variant="contained"
                                  className="lrButton"
                                >
                                  <CircularProgress className="loading-bar-check-creds" />
                                </Button>
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    className="lrButton"
                                    onClick={loginAndJoinMeeting}
                                  >
                                    Login
                                  </Button>

                                  {/* <Button
                                    variant="contained"
                                    className="lrButton"
                                    onClick={handelOpenForgotPassword}
                                  >
                                    Forgot Password?
                                  </Button> */}

                                  <div
                                    className="fogetPass actionText"
                                    onClick={handelOpenForgotPassword}
                                  >
                                    Forgot Password?
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="fromGroup ">
                                <div className="formElement">
                                  <FormControl className="formControl">
                                    <InputLabel htmlFor="userEmailId2">
                                      Email Id
                                    </InputLabel>
                                    <OutlinedInput
                                      ref={loginForgotRef}
                                      id="userEmailId2"
                                      className="formTextFieldArea areaWbtn"
                                      type="text"
                                      label="Email Id"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          {loadingforEmailValue ? (
                                            <>
                                              <CircularProgress className="loading-bar-fetch-org" />
                                            </>
                                          ) : otpIdSU ? (
                                            <IconButton
                                              edge="end"
                                              onClick={() => {
                                                setShowForgotPassForm(false);
                                              }}
                                            >
                                              <CancelOutlined />
                                            </IconButton>
                                          ) : (
                                            <IconButton
                                              edge="end"
                                              onClick={getDetailsOfUser}
                                            >
                                              <ArrowForward />
                                            </IconButton>
                                          )}
                                        </InputAdornment>
                                      }
                                      value={forgtEmailValue}
                                      onChange={(event) => {
                                        let value = event.target.value;
                                        value = value.trim();
                                        setForgtEmailValue(value);
                                      }}
                                    />
                                  </FormControl>
                                </div>

                                <div className="formElement">
                                  {orgListFP &&
                                    orgListFP.length > 0 &&
                                    !showSignupLink && (
                                      <FormControl className="formControl">
                                        <InputLabel id="selectOrg">
                                          Select Organization
                                        </InputLabel>
                                        <Select
                                          disabled
                                          className="formInputField"
                                          variant="outlined"
                                          labelId="selectOrg"
                                          id="organization-select"
                                          value={selectOrgFP}
                                          label="Select Organization"
                                          onChange={handleChangeSelectOrgFP}
                                          MenuProps={SELECT_MenuProps}
                                        >
                                          {orgListFP.map((orgDto) => {
                                            return (
                                              <MenuItem
                                                value={orgDto.orgId}
                                                key={orgDto.orgId}
                                              >
                                                {orgDto.orgName}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    )}
                                </div>

                                {otpId && !otpValidated && !showSignupLink && (
                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <InputLabel htmlFor="userEmailId">
                                        Validate OTP
                                      </InputLabel>
                                      <OutlinedInput
                                        id="userEmailId"
                                        className="formTextFieldArea areaWbtn padR0"
                                        type="text"
                                        onChange={(event) => {
                                          setOtp(event.target.value);
                                        }}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            {validatingOtp ? (
                                              <Button edge="end">
                                                <CircularProgress className="loading-bar-fetch-org" />
                                              </Button>
                                            ) : (
                                              <Button
                                                endIcon={<CheckCircleOutline />}
                                                edge="end"
                                                className="otpBtn"
                                                onClick={validateOTP}
                                              >
                                                Validate
                                              </Button>
                                            )}
                                          </InputAdornment>
                                        }
                                        label="Validate OTP"
                                      />
                                    </FormControl>
                                  </div>
                                )}

                                {otpValidated && (
                                  <>
                                    <div className="formElement">
                                      <FormControl className="formControl">
                                        <InputLabel htmlFor="outlined-adornment-new-password">
                                          New Password
                                        </InputLabel>
                                        <OutlinedInput
                                          id="outlined-adornment-new-password"
                                          className="formTextFieldArea areaPassword"
                                          type={
                                            showNewPassword
                                              ? "text"
                                              : "password"
                                          }
                                          autoComplete="new-password"
                                          value={newPassword}
                                          onChange={handleChangeNewPassword}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                  handleClickShowNewPassword
                                                }
                                                onMouseDown={
                                                  handleMouseDownPassword
                                                }
                                                edge="end"
                                              >
                                                {showNewPassword ? (
                                                  <VisibilityOff />
                                                ) : (
                                                  <Visibility />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                          label="New Password"
                                        />
                                      </FormControl>
                                    </div>
                                    <div className="formElement">
                                      <FormControl className="formControl">
                                        <InputLabel htmlFor="outlined-adornment-confirm-password">
                                          Confirm Password
                                        </InputLabel>
                                        <OutlinedInput
                                          id="outlined-adornment-confirm-password"
                                          className="formTextFieldArea areaPassword"
                                          type={
                                            showConfirmPassword
                                              ? "text"
                                              : "password"
                                          }
                                          value={confirmPassword}
                                          autoComplete="new-password"
                                          onChange={handleChangeConfirmPassword}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                  handleClickShowConfirmPassword
                                                }
                                                onMouseDown={
                                                  handleMouseDownPassword
                                                }
                                                edge="end"
                                              >
                                                {showConfirmPassword ? (
                                                  <VisibilityOff />
                                                ) : (
                                                  <Visibility />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                          label="Confirm Password"
                                        />
                                      </FormControl>
                                    </div>
                                    <div className="passwordPolocy">
                                      <div className="meterWithText">
                                        <div className="meter" id="meter">
                                          <div
                                            className="meter-bar"
                                            id="meter-bar"
                                          ></div>
                                        </div>
                                        <div
                                          className="meter-text"
                                          id="meter-text"
                                        >
                                          <span className="meterHead">
                                            Password strength:{" "}
                                          </span>
                                          <span
                                            className="meter-status"
                                            id="meter-status"
                                          >
                                            Too Short
                                          </span>
                                        </div>
                                      </div>

                                      <div className="passwrdPlcyCheckGrp">
                                        <div className="passwrdPlcyCheckHead">
                                          Password should contain
                                        </div>

                                        <div className="passwrdPlcyCheck">
                                          <span id="one-upper-case-check">
                                            {newPassword.match(/[A-Z]/) <= 1 ? (
                                              <Cancel className="cancelIcon" />
                                            ) : (
                                              <CheckCircle className="checkIcon" />
                                            )}
                                          </span>
                                          <span>One upper case letter</span>
                                        </div>
                                        <div className="passwrdPlcyCheck">
                                          <span id="one-lower-case-check">
                                            {newPassword.match(/[a-z]/) <= 1 ? (
                                              <Cancel className="cancelIcon" />
                                            ) : (
                                              <CheckCircle className="checkIcon" />
                                            )}
                                          </span>
                                          <span>One lower case lettere</span>
                                        </div>
                                        <div className="passwrdPlcyCheck">
                                          <span id="one-number-check">
                                            {newPassword.match(/[0-9]/) < 1 ? (
                                              <Cancel className="cancelIcon" />
                                            ) : (
                                              <CheckCircle className="checkIcon" />
                                            )}
                                          </span>
                                          <span>One Number</span>
                                        </div>
                                        <div className="passwrdPlcyCheck">
                                          <span id="one-specialChar-check">
                                            {/[!@#$%^&*(),.?":{}|<>]/.test(
                                              newPassword
                                            ) < 1 ? (
                                              <Cancel className="cancelIcon" />
                                            ) : (
                                              <CheckCircle className="checkIcon" />
                                            )}
                                          </span>
                                          <span>Special character</span>
                                        </div>
                                        <div className="passwrdPlcyCheck">
                                          <span id="min-length-check">
                                            {newPassword.length < 8 ? (
                                              <Cancel className="cancelIcon" />
                                            ) : (
                                              <CheckCircle className="checkIcon" />
                                            )}
                                          </span>
                                          <span>Min 8 characters</span>
                                        </div>
                                        <div className="passwrdPlcyCheck">
                                          <span id="pass-match-check">
                                            {newPassword === "" ||
                                            newPassword !== confirmPassword ? (
                                              <Cancel className="cancelIcon" />
                                            ) : (
                                              <CheckCircle className="checkIcon" />
                                            )}
                                          </span>
                                          <span>Passwords mismatch </span>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </>
                                )}
                              </div>
                              {checkingCreds ? (
                                <Button
                                  variant="contained"
                                  className="lrButton"
                                >
                                  <CircularProgress className="loading-bar-check-creds" />
                                </Button>
                              ) : (
                                <>
                                  {!otpValidated ? (
                                    <>
                                      {
                                        <Button
                                          disabled={
                                            selectOrgFP === "" ||
                                            (otpId && !otpValidated)
                                          }
                                          variant="contained"
                                          className="lrButton"
                                          onClick={() => {
                                            sendOTP();
                                          }}
                                        >
                                          Send OTP
                                        </Button>
                                      }
                                      {showSignupLink && (
                                        <p className="lrNotes">
                                          {" "}
                                          You Cannot Change Password For This
                                          email. Consider
                                          <span
                                            className="fogetPass actionText"
                                            onClick={() => {
                                              handleLoginTabClick(
                                                "userTypeSignUp"
                                              );
                                              initializeExistingUser();
                                            }}
                                          >
                                            {" "}
                                            Sign Up{" "}
                                          </span>{" "}
                                          Or{" "}
                                          <span
                                            className="fogetPass actionText"
                                            onClick={() => {
                                              handleLoginTabClick(
                                                "userTypeGuest"
                                              );
                                              initializeExistingUser();
                                            }}
                                          >
                                            {" "}
                                            Join as Guest{" "}
                                          </span>
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      className="lrButton"
                                      disabled={!isValid}
                                      onClick={() => {
                                        changePassword();
                                        initializeExistingUser();
                                      }}
                                    >
                                      Change password
                                    </Button>
                                  )}

                                  <div
                                    className="fogetPass actionText"
                                    onClick={() => {
                                      initializeExistingUser();
                                    }}
                                  >
                                    Login to your account
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {loginActiveTab === "userTypeSignUp" && (
                        <>
                          <div className="fromGroup ">
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  disabled={otpIdSU != null}
                                  label="Full Name"
                                  required
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  onChange={(event) =>
                                    setUserName(event.target.value)
                                  }
                                />
                              </FormControl>
                            </div>

                            <div className="formElement">
                              <FormControl className="formControl">
                                <InputLabel htmlFor="userEmailId">
                                  Email Id
                                </InputLabel>
                                <OutlinedInput
                                  id="userEmailIdSU"
                                  className="formTextFieldArea areaWbtn"
                                  type="text"
                                  disabled={otpIdSU != null}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      {fetchingOrgListSU ? (
                                        <>
                                          <CircularProgress className="loading-bar-fetch-org" />
                                        </>
                                      ) : otpIdSU ? (
                                        <IconButton
                                          edge="end"
                                          onClick={() => {
                                            // setUserLoginIdSU("");
                                            // setOrgListSU([]);
                                            setNewPassword("");
                                            setConfirmPassword("");
                                            setOtpIdSU(null);
                                            setOtpValidatedSU(false);
                                            setOtpSU(null);
                                          }}
                                        >
                                          <CancelOutlined />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          edge="end"
                                          onClick={
                                            getAllOrgsAssociatedWithUserSU
                                          }
                                        >
                                          <ArrowForward />
                                        </IconButton>
                                      )}
                                    </InputAdornment>
                                  }
                                  label="Email Id"
                                  value={userLoginIdSU}
                                  onChange={(event) =>
                                    setUserLoginIdSU(event.target.value)
                                  }
                                />
                              </FormControl>
                            </div>

                            {otpIdSU && (
                              <>
                                {!otpValidatedSU && (
                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <InputLabel htmlFor="userEmailId">
                                        Validate OTP
                                      </InputLabel>
                                      <OutlinedInput
                                        id="userEmailId"
                                        className="formTextFieldArea areaWbtn padR0"
                                        type="text"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <Button
                                              endIcon={<CheckCircleOutline />}
                                              edge="end"
                                              className="otpBtn"
                                              onClick={validateOTPSU}
                                            >
                                              Validate
                                            </Button>
                                          </InputAdornment>
                                        }
                                        label="Validate OTP"
                                        onChange={(event) =>
                                          setOtpSU(event.target.value)
                                        }
                                      />
                                    </FormControl>
                                  </div>
                                )}

                                {otpValidatedSU && (
                                  <>
                                    <div className="formElement">
                                      <FormControl className="formControl">
                                        <InputLabel htmlFor="outlined-adornment-new-password">
                                          New Password
                                        </InputLabel>
                                        <OutlinedInput
                                          id="outlined-adornment-new-password"
                                          className="formTextFieldArea areaPassword"
                                          type={
                                            showNewPassword
                                              ? "text"
                                              : "password"
                                          }
                                          value={newPassword}
                                          onChange={handleChangeNewPassword}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                  handleClickShowNewPassword
                                                }
                                                onMouseDown={
                                                  handleMouseDownPassword
                                                }
                                                edge="end"
                                              >
                                                {showNewPassword ? (
                                                  <VisibilityOff />
                                                ) : (
                                                  <Visibility />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                          label="New Password"
                                        />
                                      </FormControl>
                                    </div>

                                    <div className="formElement">
                                      <FormControl className="formControl">
                                        <InputLabel htmlFor="outlined-adornment-confirm-password">
                                          Confirm Password
                                        </InputLabel>
                                        <OutlinedInput
                                          id="outlined-adornment-confirm-password"
                                          className="formTextFieldArea areaPassword"
                                          type={
                                            showConfirmPassword
                                              ? "text"
                                              : "password"
                                          }
                                          value={confirmPassword}
                                          onChange={handleChangeConfirmPassword}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                  handleClickShowConfirmPassword
                                                }
                                                onMouseDown={
                                                  handleMouseDownPassword
                                                }
                                                edge="end"
                                              >
                                                {showConfirmPassword ? (
                                                  <VisibilityOff />
                                                ) : (
                                                  <Visibility />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                          label="Confirm Password"
                                        />
                                      </FormControl>
                                    </div>

                                    <div className="passwordPolocy">
                                      <div className="meterWithText">
                                        <div className="meter" id="meter">
                                          <div
                                            className="meter-bar"
                                            id="meter-bar"
                                          ></div>
                                        </div>
                                        <div
                                          className="meter-text"
                                          id="meter-text"
                                        >
                                          <span className="meterHead">
                                            Password strength:{" "}
                                          </span>
                                          <span
                                            className="meter-status"
                                            id="meter-status"
                                          >
                                            Too Short
                                          </span>
                                        </div>
                                      </div>

                                      <div className="passwrdPlcyCheckGrp">
                                        <div className="passwrdPlcyCheckHead">
                                          Password should contain
                                        </div>

                                        <div className="passwrdPlcyCheck">
                                          <span id="one-upper-case-check">
                                            {newPassword.match(/[A-Z]/) <= 1 ? (
                                              <Cancel className="cancelIcon" />
                                            ) : (
                                              <CheckCircle className="checkIcon" />
                                            )}
                                          </span>
                                          <span>One upper case letter</span>
                                        </div>
                                        <div className="passwrdPlcyCheck">
                                          <span id="one-lower-case-check">
                                            {newPassword.match(/[a-z]/) <= 1 ? (
                                              <Cancel className="cancelIcon" />
                                            ) : (
                                              <CheckCircle className="checkIcon" />
                                            )}
                                          </span>
                                          <span>One lower case lettere</span>
                                        </div>
                                        <div className="passwrdPlcyCheck">
                                          <span id="one-number-check">
                                            {newPassword.match(/[0-9]/) < 1 ? (
                                              <Cancel className="cancelIcon" />
                                            ) : (
                                              <CheckCircle className="checkIcon" />
                                            )}
                                          </span>
                                          <span>One Number</span>
                                        </div>
                                        <div className="passwrdPlcyCheck">
                                          <span id="one-specialChar-check">
                                            {/[!@#$%^&*(),.?":{}|<>]/.test(
                                              newPassword
                                            ) < 1 ? (
                                              <Cancel className="cancelIcon" />
                                            ) : (
                                              <CheckCircle className="checkIcon" />
                                            )}
                                          </span>
                                          <span>Special character</span>
                                        </div>
                                        <div className="passwrdPlcyCheck">
                                          <span id="min-length-check">
                                            {newPassword.length < 8 ? (
                                              <Cancel className="cancelIcon" />
                                            ) : (
                                              <CheckCircle className="checkIcon" />
                                            )}
                                          </span>
                                          <span>Min 8 characters</span>
                                        </div>
                                        <div className="passwrdPlcyCheck">
                                          <span id="pass-match-check">
                                            {newPassword === "" ||
                                            newPassword !== confirmPassword ? (
                                              <Cancel className="cancelIcon" />
                                            ) : (
                                              <CheckCircle className="checkIcon" />
                                            )}
                                          </span>
                                          <span>Passwords mismatch </span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>

                          {checkingCreds ? (
                            <Button variant="contained" className="lrButton">
                              <CircularProgress className="loading-bar-check-creds" />
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              className="lrButton"
                              disabled={!isValid}
                              onClick={signupUser}
                            >
                              Sign Up
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {
                    <p className="lrNotes">
                      <span>Notes: </span>Please join as a guest or enter your
                      credentials to join the meeting with your{" "}
                      {selOrgDetails?.orgName} account on zoyel one.
                    </p>
                  }

                  {/* ////////////PRASHANT CODE BACKUP//////////// */}
                  {/* <div className="fromGroup ">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label="Full Name"
                          required
                          variant="outlined"
                          className="formTextFieldArea"
                          onChange={(event) => setUserName(event.target.value)}
                        />
                      </FormControl>
                    </div>
                  </div>
                  {checkingCreds ? (
                    <Button variant="contained" className="lrButton">
                      <CircularProgress className="loading-bar-check-creds" />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="lrButton"
                      onClick={joinAsGuest}
                    >
                      Join as guest
                    </Button>
                  )} */}
                  {/* <div className="lrOr">OR</div>
                  <div className="fromGroup ">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel htmlFor="userEmailId">Email Id</InputLabel>
                        <OutlinedInput
                          ref={loginIdRef}
                          id="userEmailId"
                          className="formTextFieldArea areaWbtn"
                          type="text"
                          label="Email Id"
                          value={userLoginId}
                          onChange={(event) =>
                            setUserLoginId(event.target.value)
                          }
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          className="formTextFieldArea areaPassword"
                          type={showPassword ? "text" : "password"}
                          value={userPassword}
                          onChange={(event) =>
                            setUserPassword(event.target.value)
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPasswordLogin}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                      </FormControl>
                    </div>
                  </div>
                  {checkingCreds ? (
                    <Button variant="contained" className="lrButton">
                      <CircularProgress className="loading-bar-check-creds" />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="lrButton"
                      onClick={loginAndJoinMeeting}
                    >
                      Login
                    </Button>
                  )} */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
