import { io } from "socket.io-client";
import { socketEndPoint } from "./constants/url_provider";
import { getLoginUserDetails } from "./utils/Utils";

const socket = io(socketEndPoint);
const dashboardSocket = io(`${socketEndPoint}/dashboard`);
const chatSocket = io(`${socketEndPoint}/main-chat`);
const mailSocket = io(`${socketEndPoint}/mail`);
const meetingSocket = io(`${socketEndPoint}/meeting`);
const schedulerSocket = io(`${socketEndPoint}/scheduler`);
const driveSocket = io(`${socketEndPoint}/drive`);

try {
  const loginUser = getLoginUserDetails();
  if (loginUser != null) {
    dashboardSocket.emit("joinDashboardUser", { userId: loginUser.userId });
    chatSocket.emit("joinChatUser", { userId: loginUser.userId });
    mailSocket.emit("joinMailUser", { userId: loginUser.userId });
    meetingSocket.emit("joinMeetingUser", { userId: loginUser.userId });
    schedulerSocket.emit("joinSchedulerUser", { userId: loginUser.userId });
    driveSocket.emit("joinDriveUser", { userId: loginUser.userId });
  }
} catch (error) {
  console.error(error);
}

export {
  socket,
  dashboardSocket,
  chatSocket,
  mailSocket,
  meetingSocket,
  schedulerSocket,
  driveSocket,
};
