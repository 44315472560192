import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";

export default function HistoryRiskFactor({ updateData, data, extraData }) {
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [since, setSince] = useState("");
  const [surgeryDate, setSurgeryDate] = useState(new Date());
  const [pastIllnessDate, setPastIllnessDate] = useState(new Date());

  const [selectedRiscFactor, setSelectedRiscFactor] = useState(null);
  const [riscFactorSearchString, setRiscFactorSearchString] = useState("");

  const [risFactorList, setRiscFactorList] = useState([]);
  const [userRiscFactorList, setUserRiscFactorList] = useState([]);

  //past illness

  const [pastIllness, setPassIllness] = useState("");
  const [userPastIllnessList, setUserPassIllnessList] = useState([]);

  const [selectedPastIllness, setSelectedPastIllness] = useState(null);
  const [pastIllnessString, setPastIllnessString] = useState("");
  const [diseaseList, setDiseaseList] = useState([]);

  //Previous procedure

  const [previousSurgery, setPreviousSurgery] = useState("");
  const [previousSurgeryList, setPreviousSurgeryList] = useState([]);

  const [procedureList, setProcedureList] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [userProcedureList, setUserProcedureList] = useState([]);
  const [procedureString, setProcedureString] = useState("");

  const [diseaseSince, setDiseaseSicne] = useState(null);

  const [headingString, setHeadingString] = useState("");

  const suggestedDiseaseList = useMemo(() => {
    const filteredList = getFilteredList(
      diseaseList,
      pastIllnessString,
      "diseaseName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [pastIllnessString, diseaseList]);

  const suggestedProcedure = useMemo(() => {
    const filteredList = getFilteredList(
      procedureList,
      procedureString,
      "procedureName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [procedureList, procedureString]);

  const getDiseases = () => {
    const diseasetring = localStorage.getItem("diseaseList");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);
        localStorage.setItem("diseaseList", JSON.stringify(response.data));
      }
    });
  };

  const getProcedures = () => {
    const lifeStyleString = localStorage.getItem("procedureList");
    if (lifeStyleString) {
      setProcedureList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = getHealthDto();
    DashboardServices.getProcedures(reqDto).then((response) => {
      if (response.data) {
        setProcedureList(response.data);
        localStorage.setItem("procedureList", JSON.stringify(response.data));
      }
    });
  };

  useEffect(() => {
    let temp = [];
    let str = userRiscFactorList
      .map((item) => `${item.riskFactor.riskFactor}( ${item.since} )`)
      .join(", ");
    temp.push(str);

    str = userPastIllnessList
      .map(
        (item) =>
          `${item.illness.diseaseName}( ${dayjs(item.date).format(
            "DD-MMM-YYYY"
          )} )`
      )
      .join(", ");
    temp.push(str);

    str = userProcedureList
      .map(
        (item) =>
          `${item.surgery.procedureName}( ${dayjs(item.date).format(
            "DD-MMM-YYYY"
          )} )`
      )
      .join(", ");
    temp.push(str);

    const tempStr = temp.join("~~");
    setHeadingString(tempStr);
  }, [userRiscFactorList, userProcedureList, userPastIllnessList]);

  useEffect(() => {
    if (!data || !data.prevSurgery) {
      if (
        extraData &&
        extraData.reqValue.procedures &&
        extraData.reqValue.procedures.length > 0
      ) {
        const tempArray = extraData.reqValue.procedures.map((item) => {
          let tempProcedure = {
            id: uuidv4(),
            procedureName: item.procedureName,
            gender: "",
            locallyAdded: true,
          };

          const temp = {
            surgery: tempProcedure,
            date: new Date(Date.parse(item.procedureDate)),
          };
          return temp;
        });

        setUserProcedureList((prev) => {
          const temp = [...prev, ...tempArray];
          updateData({ prevSurgery: temp });
          return temp;
        });
      }
    }

    if (!data || !data.pastIllness) {
      if (extraData && extraData.reqValue && extraData.reqValue.diseases) {
        const tempArray = extraData.reqValue.diseases.map((item, index) => {
          let tempPastIllness = {
            id: uuidv4(),
            diseaseName: item.diseaseName,
            icd11code: item.icd11code,
            snowmedCt: item.snowmedCt,
          };

          const temp = {
            illness: tempPastIllness,
            date: new Date(),
          };
          return temp;
        });

        setUserPassIllnessList((prev) => {
          const temp = [...prev, ...tempArray];
          updateData({ pastIllness: temp });
          return temp;
        });
      }
    }

    if (data.riskFactor) {
      setUserRiscFactorList(data.riskFactor);
    }
    if (data.prevSurgery) {
      setUserProcedureList(data.prevSurgery);
    }

    if (data.pastIllness) {
      setUserPassIllnessList(data.pastIllness);
    }
  }, []);

  const initializeForm = () => {
    setSelectedRiscFactor(null);
    setRiscFactorSearchString("");
    setSince("");
  };

  const initializePastIllness = () => {
    setSelectedPastIllness(null);
    setPastIllnessString("");
    setPastIllnessDate(new Date());
  };

  const initializeProcedure = () => {
    setSelectedProcedure(null);
    setProcedureString("");
    setSurgeryDate(new Date());
  };

  const suggestedRiscFactor = useMemo(() => {
    const filteredList = getFilteredList(
      risFactorList,
      riscFactorSearchString,
      "riskFactor"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [riscFactorSearchString, risFactorList]);

  useEffect(() => {
    getRiskFactors();
    getSymptomSince();
    getDiseases();
    getProcedures();
  }, []);

  const getRiskFactors = () => {
    const riskString = localStorage.getItem("riskList");
    if (riskString) {
      setRiscFactorList(JSON.parse(riskString));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getRiskFactors(reqDto).then((response) => {
      if (response.data) {
        setRiscFactorList(response.data);
        localStorage.setItem("riskList", JSON.stringify(response.data));
      }
    });
  };

  const getSymptomSince = () => {
    const symptomString = localStorage.getItem("symptomSince");
    if (symptomString) {
      setDiseaseSicne(JSON.parse(symptomString));
      return;
    }
    const reqDto = getHealthDto();
    DashboardServices.getSymptomSince(reqDto).then((response) => {
      if (response.data) {
        setDiseaseSicne(response.data);
        localStorage.setItem("symptomSince", JSON.stringify(response.data));
      }
    });
  };

  const handleChangeSince = (event) => {
    setSince(event.target.value);
  };
  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            History & Risk Factor:
            <span>{!accordionExpanded && headingString}</span>
            {/* <span>symptoms gose here when acordion not expend</span> */}
          </div>
        </AccordionSummary>
        <AccordionDetails className="acodDetails">
          <div className="complaintList">
            <ul>
              {userRiscFactorList &&
                userRiscFactorList.map((item, index) => {
                  console.log("*********** item =", item);
                  return (
                    <li>
                      <div className="complstDtlGrp">
                        <div className="complstDtl  ">
                          <div className="compntName">
                            {item.riskFactor.riskFactor}
                          </div>
                          {item.since && (
                            <div className="compinfoGRP">
                              <div className="compStime">
                                <span>Since:</span>
                                <span className="data">{item.since}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...userRiscFactorList];
                            temp.splice(index, 1);
                            updateData({ riskFactor: temp });
                            setUserRiscFactorList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </li>
                  );
                })}

              <li>
                <div className="addNewformGrp">
                  <div className="formElement">
                    <FormControl className="formControl">
                      {/* <TextField
                        // hiddenLabel
                        label="Search Histor and risk factor"
                        required
                        autoComplete="off"
                        placeholder="Search Histor and risk factor"
                        variant="outlined"
                        className="formTextFieldArea"
                      /> */}

                      <Autocomplete
                        freeSolo
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedRiscFactor}
                        options={suggestedRiscFactor}
                        inputValue={riscFactorSearchString}
                        onChange={(e, newValue) => {
                          setSelectedRiscFactor(newValue);
                        }}
                        onInputChange={(e, value, reason) => {
                          // if (e && e.target) {
                          //   setRiscFactorSearchString(e.target.value);
                          // }

                          if (e && reason === "input") {
                            console.log("changed input =", value);
                            setRiscFactorSearchString(e.target.value);
                          } else if (reason === "reset") {
                            setRiscFactorSearchString(value);
                          } else if (reason === "clear") {
                            setRiscFactorSearchString(value);
                          }

                          // else {
                          //   setRiscFactorSearchString("");
                          // }
                        }}
                        getOptionLabel={(option) => option.riskFactor}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {item.riskFactor}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder="Search Histor and risk factor*"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel id="since-select-label">Since</InputLabel>
                      <Select
                        labelId="since-select-label"
                        id="since-select"
                        value={since}
                        label="Since"
                        onChange={handleChangeSince}
                        className="formInputField"
                        variant="outlined"
                      >
                        {diseaseSince &&
                          diseaseSince.map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                      </Select>
                    </FormControl>
                  </div>
                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    onClick={() => {
                      let tempRiscFactor = selectedRiscFactor;
                      if (!tempRiscFactor) {
                        if (
                          !riscFactorSearchString ||
                          riscFactorSearchString === ""
                        ) {
                          toast.error("Please Provide Risk Factor");
                          return;
                        }
                        tempRiscFactor = {
                          id: uuidv4(),
                          riskFactor: riscFactorSearchString,
                          gender: "",
                          locallyAdded: true,
                        };
                      }
                      setUserRiscFactorList((prev) => {
                        const temp = [
                          ...prev,
                          { riskFactor: tempRiscFactor, since },
                        ];
                        updateData({ riskFactor: temp });
                        return temp;
                      });
                      initializeForm();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </li>
            </ul>
          </div>

          <div className="tskElemHeding hilightHeading">Past illness</div>

          <ul className="withOutList pastIllness">
            {userPastIllnessList &&
              userPastIllnessList.map((item, index) => {
                return (
                  <li>
                    {/* <div className="valuDateRow">
                      <div className="piValu">{item.illness.diseaseName}</div>
                      <div className="piDate">
                        {dayjs(item.date).format("DD-MMM-YYYY")}
                      </div>
                      <IconButton
                        className="removeItemBtn"
                        onClick={() => {
                          const temp = [...userPastIllnessList];
                          temp.splice(index, 1);
                          setUserPassIllnessList(temp);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </div> */}

                    <div className="complstDtlGrp">
                      <div className="complstDtl  ">
                        <div className="compntName">
                          {item.illness.diseaseName}
                        </div>
                        {item.date && (
                          <div className="compinfoGRP">
                            <div className="compStime">
                              <span>Since:</span>
                              <span className="data">
                                {dayjs(item.date).format("DD-MMM-YYYY")}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <IconButton
                        className="removeItemBtn"
                        onClick={() => {
                          const temp = [...userPastIllnessList];
                          temp.splice(index, 1);
                          updateData({ pastIllness: temp });
                          setUserPassIllnessList(temp);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </div>
                  </li>
                );
              })}

            <li>
              <div className="addNewformGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    {/* <TextField
                      // hiddenLabel
                      label="Past illness"
                      required
                      autoComplete="off"
                      placeholder="Add Past illness"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={pastIllness}
                      onChange={(e) => {
                        setPassIllness(e.target.value);
                      }}
                    /> */}

                    <Autocomplete
                      freeSolo
                      className="formAutocompleteField"
                      variant="outlined"
                      value={selectedPastIllness}
                      options={suggestedDiseaseList}
                      inputValue={pastIllnessString}
                      onChange={(e, newValue) => {
                        setSelectedPastIllness(newValue);
                      }}
                      onInputChange={(e, value) => {
                        setPastIllnessString(value);
                      }}
                      getOptionLabel={(option) => option.diseaseName}
                      renderOption={(props, item) => {
                        return (
                          <li {...props} key={item.id}>
                            {item.diseaseName}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField autocompFildPlaceholder"
                          placeholder="Add Past illness"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <div className="formElement mxW-150">
                  <FormControl className="formControl">
                    <InputLabel
                      id="pastIllnessDate"
                      className="setCompletionDate"
                    >
                      Select Date*
                    </InputLabel>
                    <ReactDatePicker
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      toggleCalendarOnIconClick
                      labelId="pastIllnessDate"
                      className="formDatePicker"
                      selected={pastIllnessDate}
                      maxDate={new Date()}
                      dateFormat="dd-MMM-yyyy"
                      onChange={(date) => {
                        console.log("new date = ", date);
                        setPastIllnessDate(date);
                      }}
                    />
                  </FormControl>
                </div>

                <Button
                  className="dfultPrimaryBtn "
                  startIcon={<Add />}
                  onClick={() => {
                    if (!pastIllnessString || pastIllnessString === "") {
                      toast.error("Please Provide Illness Name");
                      return;
                    }
                    let tempPastIllness = selectedPastIllness;
                    if (!tempPastIllness) {
                      tempPastIllness = {
                        id: uuidv4(),
                        diseaseName: pastIllnessString,
                        icd11code: "",
                        snowmedCt: "",
                      };
                    }
                    setUserPassIllnessList((prev) => {
                      const temp = [
                        ...prev,
                        { illness: tempPastIllness, date: pastIllnessDate },
                      ];

                      updateData({ pastIllness: temp });
                      return temp;
                    });
                    initializePastIllness();
                  }}
                >
                  Add
                </Button>
              </div>
            </li>
          </ul>

          <div className="tskElemHeding hilightHeading">Previous procedure</div>

          <ul className="withOutList pastIllness">
            {userProcedureList &&
              userProcedureList.map((item, index) => {
                return (
                  <li>
                    {/* <div>
                      <div className="valuDateRow">
                        <div className="piValu">
                          {item.surgery.procedureName}
                        </div>
                        <div className="piDate">
                          {dayjs(item.date).format("DD-MMM-YYYY")}
                        </div>
                      </div>
                      <IconButton
                        className="removeItemBtn"
                        onClick={() => {
                          const temp = [...userProcedureList];
                          temp.splice(index, 1);
                          setUserProcedureList(temp);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </div> */}
                    <div className="complstDtlGrp">
                      <div className="complstDtl  ">
                        <div className="compntName">
                          {item.surgery.procedureName}
                        </div>
                        <div className="compinfoGRP">
                          <div className="compStime">
                            <span>Since:</span>
                            <span className="data">
                              {dayjs(item.date).format("DD-MMM-YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <IconButton
                        className="removeItemBtn"
                        onClick={() => {
                          const temp = [...userProcedureList];
                          temp.splice(index, 1);
                          updateData({ prevSurgery: temp });

                          setUserProcedureList(temp);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </div>
                  </li>
                );
              })}

            <li>
              <div className="addNewformGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    {/* <TextField
                      // hiddenLabel
                      label="Previous procedure"
                      required
                      autoComplete="off"
                      placeholder="Add Previous procedure"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={previousSurgery}
                      onChange={(e) => {
                        setPreviousSurgery(e.target.value);
                      }}
                    /> */}

                    <Autocomplete
                      freeSolo
                      className="formAutocompleteField"
                      variant="outlined"
                      value={selectedProcedure}
                      options={suggestedProcedure}
                      inputValue={procedureString}
                      onChange={(e, newValue) => {
                        setSelectedProcedure(newValue);
                      }}
                      onInputChange={(e, value) => {
                        setProcedureString(value);
                      }}
                      getOptionLabel={(option) => option.procedureName}
                      renderOption={(props, item) => {
                        return (
                          <li {...props} key={item.id}>
                            {item.procedureName}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField autocompFildPlaceholder"
                          placeholder="Add Previous procedure"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <div className="formElement mxW-150">
                  <FormControl className="formControl">
                    <InputLabel
                      id="setsurgeryDate"
                      className="setCompletionDate"
                    >
                      Surgery Date*
                    </InputLabel>
                    <ReactDatePicker
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      toggleCalendarOnIconClick
                      labelId="setsurgeryDate"
                      className="formDatePicker"
                      selected={surgeryDate}
                      maxDate={new Date()}
                      dateFormat="dd-MMM-yyyy"
                      onChange={(date) => {
                        console.log("new date = ", date);
                        setSurgeryDate(date);
                      }}
                    />
                  </FormControl>
                </div>

                <Button
                  className="dfultPrimaryBtn "
                  startIcon={<Add />}
                  onClick={() => {
                    let tempProcedure = selectedProcedure;
                    if (!procedureString || procedureString === "") {
                      toast.error("Please Provide Procedure Name");
                      return;
                    }
                    if (!tempProcedure) {
                      tempProcedure = {
                        id: uuidv4(),
                        // foodName: procedureString,
                        procedureName: procedureString,
                        gender: "",
                        locallyAdded: true,
                      };
                    }
                    setUserProcedureList((prev) => {
                      const temp = [
                        ...prev,
                        { surgery: tempProcedure, date: surgeryDate },
                      ];
                      updateData({ prevSurgery: temp });
                      return temp;
                    });
                    initializeProcedure();
                  }}
                >
                  Add
                </Button>
              </div>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
