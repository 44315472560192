import React, { useEffect, useRef, useState } from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import user1 from "../../../images/2.jpg";
import user2 from "../../../images/3.jpg";
import user3 from "../../../images/4.jpg";
import ReactDatePicker from "react-datepicker";
import {
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { Eye, EyeSlash, XLg } from "react-bootstrap-icons";
import {
  getUserBasicDetails,
  getUsersEvents,
  getUsersEventsForConvenientCalendar,
} from "../../../../../services/SchedulerService";
import DateUtils, { weekDays } from "../../../../../utils/DateUtils";
import { toast } from "react-toastify";
import { EyeClosed } from "iconoir-react";
import { X } from "@mui/icons-material";
import { CustomConfirmation } from "../../../../../common/CustomConfirmation";

export default function ConvenientTimeView({
  usersList,
  meetingStartTime,
  meetingEndTime,
  startTime,
  endTime,
  rows,
  slotDuration,
  cols,
  holidayList,
  leaveList,
  defaultWorkWeek,
  viewForm,
  hideForm,
  setSelectedConvenientTime,
  handleClose,
  mode,
}) {
  const [startDate, setStartDate] = useState(meetingStartTime);
  const [userEventsData, setUserEventsData] = useState([]);
  const [mappedData, setMappedData] = useState({});
  const [tdWidth, setTdWidth] = useState(null);
  const tdRef = useRef(null);
  const [eventsToDisplay, setEventsToDisplay] = useState(new Map());
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const draggableRecRef = useRef(null);
  const [customConfirmationObj, setCustomConfirmationObj] = useState({});

  const [fromTimeList, setFromTimeList] = useState([]);
  const [toTimeList, setToTimeList] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);

  const [fromTime, setFromTime] = useState(
    new Date(meetingStartTime).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
  );
  const [toTime, setToTime] = useState(
    new Date(meetingEndTime).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
  );

  useEffect(() => {
    const fetchEventsOfUsers = async () => {
      const response = await getUsersEventsForConvenientCalendar(
        usersList,
        "15-05-2024"
      );
      console.log("fetchEventsOfUsers", response.data);
      if (response.data && response.data.length > 0) {
        setUserEventsData(response.data);
      } else {
        let mapped = new Map();
        for (let index = 0; index < usersList.length; index++) {
          const userId = usersList[index];
          await getUserBasicDetails(userId).then((response) => {
            // mapped.put(userId, {
            //   userDetails: {
            //     userName: response.data.userName,
            //     profileImage: response.data.profileImageName,
            //   },
            //   events: [],
            // });
            mapped[userId] = {
              userDetails: {
                userName: response.data.userName,
                profileImage: response.data.profileImageName,
              },
              events: [],
            };
          });
        }
        setMappedData(mapped);
        setIsDataLoaded(true);
      }
    };
    // console.log("holidayList111", holidayList);
    setIsDataLoaded(false);
    fetchEventsOfUsers();
  }, [usersList]);

  useEffect(() => {
    console.log("meetingStartTime", meetingStartTime);
    const updateTdWidth = () => {
      // if (tdRef.current) {
      //   const width = tdRef.current.clientWidth;
      //   setTdWidth(width);
      // }
      setTdWidth(250);
    };
    updateTdWidth();
    window.addEventListener("resize", updateTdWidth);
    // generateEventsDisplayMap();

    //from time  tot time
    if (DateUtils.isToday(new Date(meetingStartTime))) {
      let allowedTime =
        DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
          new Date(meetingStartTime)
        );
      setFromTimeList(allowedTime);
      setFromTime(allowedTime[0]);
    } else {
      let currentDate = new Date(meetingStartTime);
      currentDate.setHours(0, 0, 0, 0);
      setFromTimeList(
        DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
          currentDate
        )
      );
    }

    let endTimeStart = new Date(meetingStartTime);

    endTimeStart.setMinutes(endTimeStart.getMinutes() + 15);

    setToTimeList(
      DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
        meetingEndTime
      )
    );

    console.log("fromtime", fromTime, toTime);

    return () => {
      window.removeEventListener("resize", updateTdWidth);
    };
  }, [meetingStartTime]);

  useEffect(() => {
    const fetchData = async () => {
      if (userEventsData.length > 0) {
        const mapped = userEventsData.reduce((acc, item) => {
          const {
            userId,
            userName,
            profileImage,
            start,
            end,
            eventId,
            title,
            description,
            eventType,
            link,
          } = item;
          const userDetails = { userName, profileImage };
          const eventDetails = {
            start,
            end,
            eventId,
            title,
            description,
            eventType,
            link,
          };
          acc[userId] = acc[userId] || { userDetails, events: [] };
          acc[userId].events.push(eventDetails);
          return acc;
        }, {});
        console.log("mapped1", mapped);
        for (let index = 0; index < usersList.length; index++) {
          const userId = usersList[index];
          if (!(userId in mapped)) {
            await getUserBasicDetails(userId).then((response) => {
              // mapped.put(userId, {
              //   userDetails: {
              //     userName: response.data.userName,
              //     profileImage: response.data.profileImageName,
              //   },
              //   events: [],
              // });
              mapped[userId] = {
                userDetails: {
                  userName: response.data.userName,
                  profileImage: response.data.profileImageName,
                },
                events: [],
              };
            });
          }
        }

        console.log("mapped2", mapped);
        setMappedData(mapped);
        setIsDataLoaded(true);
      }
    };
    fetchData();
  }, [userEventsData]);

  useEffect(() => {
    if (isDataLoaded) {
      generateEventsDisplayMap();
    }
  }, [mappedData]);

  useEffect(() => {
    console.log("startDate--->>>", startDate);
    var match = fromTime.match(/(\d+):(\d+) ([APMapm]{2})/);

    var hours = parseInt(match[1], 10);
    var minutes = parseInt(match[2], 10);
    var period = match[3].toUpperCase();

    if (period === "PM" && hours !== 12) {
      hours += 12;
    }
    setRectangleHeight(calculateRectangleHeight(fromTime, toTime));
    setRectangleTop(((hours * 60 + minutes) / 15) * 21);
  }, [startDate]);

  const displayLeftSideTime = () => {
    const items = [];
    for (let count = startTime; count <= endTime; count++) {
      if (count === 24) {
        continue;
      }
      items.push(
        <>
          <tr>
            <td class="e-time-slots">
              <span>
                {DateUtils.tConvert(
                  count > 9 ? count + ":00" : "0" + count + ":00"
                )}
              </span>
            </td>
          </tr>
          <tr>
            <td class="e-time-slots"></td>
          </tr>
          <tr>
            <td class="e-time-slots"></td>
          </tr>
          <tr>
            <td class="e-time-cells e-time-slots"></td>
          </tr>
        </>
      );
    }
    return items;
  };
  function checkOverlap(intervals, startTime, endTime) {
    for (let interval of intervals) {
      if (
        (startTime >= interval.start && startTime < interval.end) || // Partial overlap (start time inside interval)
        (endTime > interval.start && endTime <= interval.end) ||
        (startTime <= interval.start && endTime >= interval.end)
      ) {
        // Complete containment
        return true;
      }
    }
    return false;
  }

  function generateTimeIntervalsRoundedTo15Minutes() {
    const intervals = [];
    const totalMinutesInDay = 24 * 60;

    const currentHours = 0;
    const currentMinutes = 0;

    const remainingMinutesInDay =
      totalMinutesInDay - (currentHours * 60 + currentMinutes);

    const nextMultipleOf15 = Math.ceil(currentMinutes / 15) * 15;
    // console.log(nextMultipleOf15);

    for (
      let minutes = nextMultipleOf15;
      currentHours + Math.floor(minutes / 60) <= 23 && minutes % 60 <= 45;
      minutes += 15
    ) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;

      let period = "AM";
      let formattedHours = currentHours + Math.floor(minutes / 60);

      if (formattedHours >= 12) {
        period = "PM";
        formattedHours = formattedHours === 12 ? 12 : formattedHours - 12;
      }

      const formattedTime = `${String(formattedHours).padStart(
        2,
        "0"
      )}:${String(mins).padStart(2, "0")} ${period}`;

      intervals.push({ time: formattedTime, available: true });
    }

    return intervals;
  }

  const generateEventsDisplayMap = async () => {
    let timeDivMap = new Map();

    let weekDayDateYYYYMMDD = DateUtils.getDateInYYYYMMDD(startDate);
    for (const userId in mappedData) {
      if (Object.hasOwnProperty.call(mappedData, userId)) {
        timeDivMap.set(weekDayDateYYYYMMDD + "_" + userId + "_1", {
          timeSlots: generateTimeIntervalsRoundedTo15Minutes(),
        });

        timeDivMap.set(weekDayDateYYYYMMDD + "_" + userId + "_2", {
          timeSlots: generateTimeIntervalsRoundedTo15Minutes(),
        });

        timeDivMap.set(weekDayDateYYYYMMDD + "_" + userId + "_3", {
          timeSlots: generateTimeIntervalsRoundedTo15Minutes(),
        });
      }
    }

    console.log("timeDivMap", timeDivMap);

    for (const userId in mappedData) {
      if (Object.hasOwnProperty.call(mappedData, userId)) {
        const userData = mappedData[userId];
        console.log(`User ID: ${userId}`);
        console.log("User Details:", userData.userDetails);
        console.log("Events:", userData.events);

        const filteredEvents = userData.events.filter((eventDto) => {
          const eventDate = DateUtils.getDateInYYYYMMDD(
            new Date(eventDto.start)
          );
          return eventDate === weekDayDateYYYYMMDD; // Adjust the date according to your requirement
        });

        const sortedList = [...filteredEvents].sort((a, b) => {
          return new Date(a.start) - new Date(b.start);
        });

        for (let index = 0; index < sortedList.length; index++) {
          const eventDto = sortedList[index];
          generateDayData(
            eventDto,
            0,
            weekDayDateYYYYMMDD + "_" + userId,
            timeDivMap
          );
        }
      }
    }

    generateDisplayData(timeDivMap);
  };

  useEffect(() => {
    console.log("startDate refresh", startDate, generateEventsDisplayMap);
    generateEventsDisplayMap();
  }, [startDate]);

  useEffect(() => {
    let timer = null;
    if (draggableRecRef.current) {
      timer = setTimeout(() => {
        scrollToDraggableRef();
      }, 5000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [draggableRecRef.current]);

  const generateDisplayData = (timeDivMap) => {
    const items = [];
    if (timeDivMap == null || timeDivMap.size === 0) {
      return;
    }
    // let weekIndex = 0;

    // for (const userId in mappedData) {
    //   if (Object.hasOwnProperty.call(mappedData, userId)) {
    //     const userData = mappedData[userId];
    //     console.log(`User ID: ${userId}`);
    //     console.log("User Details:", userData.userDetails);
    //     console.log("Events:", userData.events);

    //     const filteredEvents = userData.events.filter((eventDto) => {
    //       const eventDate = DateUtils.getDateInYYYYMMDD(
    //         new Date(eventDto.start)
    //       );
    //       return eventDate === weekDayDateYYYYMMDD; // Adjust the date according to your requirement
    //     });

    //     for (let index = 0; index < filteredEvents.length; index++) {
    //       const eventDto = filteredEvents[index];
    //       generateDayData(eventDto, 0, weekDayDateYYYYMMDD, timeDivMap);
    //     }
    //   }
    // }

    for (const userId in mappedData) {
      if (!Object.hasOwnProperty.call(mappedData, userId)) {
        return;
      }

      // const userData = mappedData[userId];
      const weekDay = DateUtils.getDateInYYYYMMDD(startDate) + "_" + userId;
      console.log("weekDay--------------------XXXXXXXXXXXXXXXXXXXXXX", weekDay);
      let timeSlotListFirstSlot = timeDivMap.get(weekDay + "_1")?.timeSlots;
      console.log(
        "timeSlotListFirstSlot--------------------XXXXXXXXXXXXXXXXXXXXXX",
        timeSlotListFirstSlot
      );
      const firstSlotWeekDay = [];
      let currentEventDto = null;

      for (let index = 0; index < timeSlotListFirstSlot?.length; index++) {
        const timeSlotDto = timeSlotListFirstSlot[index];
        if (
          !timeSlotDto.available &&
          (currentEventDto == null ||
            currentEventDto.eventId !== timeSlotDto.eventDtls.eventDto.eventId)
        ) {
          currentEventDto = timeSlotDto.eventDtls.eventDto;
          console.log(
            "currentEventDto",
            currentEventDto,
            timeSlotDto.eventDtls,
            `${timeSlotDto.eventDtls.topOffset}px !important`
          );

          let style = {
            top: timeSlotDto.eventDtls.topOffset + "px",
            height: timeSlotDto.eventDtls.height + "px",
            left: timeSlotDto.eventDtls.left + "px",
            width: timeSlotDto.eventDtls.width + "px",
          };

          console.log("style---->>>>>>>>", style);

          firstSlotWeekDay.push(
            <>
              <div
                className="e-appointment e-lib e-draggable  "
                style={style}
                key={weekDay + "_1"}
              >
                <Tooltip
                  title={` ${DateUtils.displayDateMonthViewToolTip(
                    currentEventDto
                  )}`}
                  arrow
                >
                  <div
                    className="e-appointment-details myCalendar"
                    onClick={() => {
                      // setSelectedEvent(timeSlotDto.eventDtls.eventDto);
                    }}
                  >
                    {/* <div
                      className="e-subject"
                      style={{
                        maxHeight: `${timeSlotDto.eventDtls.height}px !important`,
                      }}
                    >
                      {`${currentEventDto.title}`}
                    </div> */}
                    <div
                      className="e-time"
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      {DateUtils.displayDateMonthViewToolTip(currentEventDto)}
                    </div>
                  </div>
                </Tooltip>
              </div>
            </>
          );
        }
      }

      let timeSlotListSecondSlot = timeDivMap.get(weekDay + "_2")?.timeSlots;

      const secondSlotWeekDay = [];
      let currentEventDtoSec = null;
      for (let index = 0; index < timeSlotListSecondSlot?.length; index++) {
        const timeSlotDto = timeSlotListSecondSlot[index];

        if (
          !timeSlotDto.available &&
          (currentEventDtoSec == null ||
            currentEventDtoSec.eventId !==
              timeSlotDto.eventDtls.eventDto.eventId)
        ) {
          currentEventDtoSec = timeSlotDto.eventDtls.eventDto;
          console.log("currentEventDtoSec", currentEventDtoSec);
          let style = {
            top: timeSlotDto.eventDtls.topOffset + "px",
            height: timeSlotDto.eventDtls.height + "px",
            left: timeSlotDto.eventDtls.left + "px",
            width: timeSlotDto.eventDtls.width + "px",
          };
          secondSlotWeekDay.push(
            <>
              <div
                className="e-appointment e-lib e-draggable  "
                style={style}
                key={weekDay + "_2"}
              >
                <Tooltip
                  title={` ${DateUtils.displayDateMonthViewToolTip(
                    currentEventDto
                  )}`}
                  arrow
                >
                  <div
                    className="e-appointment-details myCalendar"
                    onClick={() => {
                      // setSelectedEvent(timeSlotDto.eventDtls.eventDto);
                    }}
                  >
                    {/* <div
                      className="e-subject"
                      style={{
                        maxHeight: `${timeSlotDto.eventDtls.height}px !important`,
                      }}
                    >
                      {`${currentEventDtoSec.title}`}
                    </div> */}
                    <div
                      className="e-time"
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      {DateUtils.displayDateMonthViewToolTip(
                        currentEventDtoSec
                      )}
                    </div>
                  </div>
                </Tooltip>
              </div>
            </>
          );
        }
      }

      let timeSlotListThirdSlot = timeDivMap.get(weekDay + "_3")?.timeSlots;

      const thirdSlotWeekDay = [];
      // let currentEventDtoSec = null;
      for (let index = 0; index < timeSlotListThirdSlot?.length; index++) {
        const timeSlotDto = timeSlotListThirdSlot[index];

        if (timeSlotDto.eventList && timeSlotDto.eventList.length > 0) {
          let hours = parseInt(timeSlotDto.time.split(":")[0]);
          if (timeSlotDto.time.split(" ")[1] === "PM") {
            hours += 12;
          }
          let topOffset = ((60 * hours) / 15) * 21;
          // let left = weekIndex * tdWidth;
          let left = (tdWidth - 60) / 2 + (tdWidth - 60) / 2;
          let style = {
            top: topOffset + "px",
            height: "42px",
            position: "absolute",
            // maxHeight: "42px",
            left: left + "px",
            // width: timeSlotDto.eventDtls.width + "px",
          };
          thirdSlotWeekDay.push(
            <Button
              className="e-appointment-details moreEvent"
              sx={style}
              key={weekDay + "_3"}
              // onClick={() => {
              //   if (showAdditionalEventDetails) {
              //     setShowAdditionalEventDetails(false);
              //   } else {
              //     setAdditionalEventDetails(timeSlotDto.eventList);
              //     setShowAdditionalEventDetails(true);
              //   }
              // }}
            >
              <div className="e-subject-more" style={{ maxHeight: "42px" }}>
                +{timeSlotDto.eventList.length}
              </div>
            </Button>
          );
        }
      }

      items.push(
        <>
          <td className="e-day-wrapper" key={userId}>
            <div className="e-appointment-wrapper" id="e-appointment-wrapper-0">
              {firstSlotWeekDay}
              {secondSlotWeekDay}
              {thirdSlotWeekDay}
            </div>
          </td>
        </>
      );
    }
    // console.log(
    //   "refresh---->",
    //   new Date().getMinutes(),
    //   new Date().getSeconds(),
    //   refresh,
    //   items
    // );
    setEventsToDisplay(items);
    // setRefresh(refresh + 1);
  };

  const generateDayData = (
    eventDto,
    weekIndex,
    weekDayYYYYMMDD,
    timeDivMap
  ) => {
    const items = [];
    console.log("generateDayData", weekDayYYYYMMDD);
    if (timeDivMap.has(weekDayYYYYMMDD + "_1")) {
      let timeSlotsList = timeDivMap.get(weekDayYYYYMMDD + "_1").timeSlots;
      console.log("generateDayData", timeSlotsList);
      let storedEventDetails = null;
      for (let index = 0; index < timeSlotsList.length; index++) {
        const timeSlotDto = timeSlotsList[index];

        if (storedEventDetails) {
          if (weekDayYYYYMMDD.includes("703")) {
            console.log(
              "isEventJSXStore 703",
              new Date().getTime(),
              eventDto.title,
              DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)),
              timeSlotDto.time,
              DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)) ===
                timeSlotDto.time
            );
          }

          if (
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)) ===
            timeSlotDto.time
          ) {
            timeSlotDto.available = false;
            timeSlotDto.eventDtls = storedEventDetails;

            index = timeSlotsList.length;
            break;
          } else {
            timeSlotDto.available = false;
            timeSlotDto.eventDtls = storedEventDetails;
          }
        } else {
          if (
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.start)) ===
            timeSlotDto.time
          ) {
            if (timeSlotDto.available) {
              let topOffset =
                (DateUtils.timeToMinutes(new Date(eventDto.start)) / 15) * 21;
              let height = "32px";
              if (
                DateUtils.getDateInYYYYMMDD(new Date(eventDto.start)) !==
                DateUtils.getDateInYYYYMMDD(new Date(eventDto.end))
              ) {
                var endOfDay = new Date(eventDto.start);
                endOfDay.setHours(23, 59, 0, 0);
                var difference = endOfDay - new Date(eventDto.start);

                // Convert the difference to hours, minutes, and seconds
                var minutesDifference = Math.floor(
                  (difference % (1000 * 60 * 60)) / (1000 * 60)
                );

                height = (minutesDifference / 15) * 21;
              } else {
                height =
                  (DateUtils.getDifferenceInMinutes(
                    eventDto.start,
                    eventDto.end
                  ) /
                    15) *
                  21;
              }

              // let left = weekIndex * tdWidth;

              storedEventDetails = {
                eventDto: eventDto,
                topOffset: topOffset,
                height: height,
                left: 0,
              };

              timeSlotDto.eventDtls = storedEventDetails;
              timeSlotDto.available = false;
            } else if (timeDivMap.has(weekDayYYYYMMDD + "_2")) {
              addEventInSecondSlotForTheDay(
                eventDto,
                weekIndex,
                weekDayYYYYMMDD,
                timeDivMap,
                timeSlotDto
              );
            }
          } else {
          }
        }
      }
    }

    return items;
  };

  const handleOverlapping = () => {
    const convenientMeetings = userEventsData.map((event) => ({
      start: new Date(event.start),
      end: new Date(event.end),
    }));

    const [startHours, startMinutes] = fromTime
      .split(":")
      .map((part) => parseInt(part));
    const isStartPm = fromTime.toLowerCase().includes("pm");
    const startDt = startDate.setHours(
      isStartPm ? startHours + 12 : startHours,
      startMinutes
    );

    const [endHours, endMinutes] = toTime
      .split(":")
      .map((part) => parseInt(part));
    const isEndPm = toTime.toLowerCase().includes("pm");
    const endDt = startDate.setHours(
      isEndPm ? endHours + 12 : endHours,
      endMinutes
    );

    const isOverlap = checkOverlap(convenientMeetings, startDt, endDt);
    return isOverlap;
  };

  const addEventInSecondSlotForTheDay = (
    eventDto,
    weekIndex,
    weekDayYYYYMMDD,
    timeDivMap,
    firstTimeSlotDto
  ) => {
    const items = [];

    let storedEventDetails = null;

    if (timeDivMap.has(weekDayYYYYMMDD + "_2")) {
      let timeSlotsList = timeDivMap.get(weekDayYYYYMMDD + "_2").timeSlots;

      for (let index = 0; index < timeSlotsList.length; index++) {
        const timeSlotDto = timeSlotsList[index];
        if (storedEventDetails) {
          console.log(
            "isEventJSXStore",
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)),
            timeSlotDto.time,
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)) ===
              timeSlotDto.time
          );
          if (
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.end)) ===
            timeSlotDto.time
          ) {
            timeSlotDto.available = false;
            timeSlotDto.eventDtls = storedEventDetails;

            index = timeSlotsList.length;
            break;
          } else {
            timeSlotDto.available = false;
            timeSlotDto.eventDtls = storedEventDetails;
          }
        } else {
          if (
            DateUtils.formatDateTo12HourFormat(new Date(eventDto.start)) ===
            timeSlotDto.time
          ) {
            if (timeSlotDto.available) {
              let topOffset =
                (DateUtils.timeToMinutes(new Date(eventDto.start)) / 15) * 21;

              // let height =
              //   (DateUtils.getDifferenceInMinutes(
              //     eventDto.start,
              //     eventDto.end
              //   ) /
              //     15) *
              //   21;

              let height = "32px";
              if (
                DateUtils.getDateInYYYYMMDD(new Date(eventDto.start)) !==
                DateUtils.getDateInYYYYMMDD(new Date(eventDto.end))
              ) {
                var endOfDay = new Date(eventDto.start);
                endOfDay.setHours(23, 59, 0, 0);
                var difference = endOfDay - new Date(eventDto.start);

                // Convert the difference to hours, minutes, and seconds
                var minutesDifference = Math.floor(
                  (difference % (1000 * 60 * 60)) / (1000 * 60)
                );

                height = (minutesDifference / 15) * 21;
              } else {
                height =
                  (DateUtils.getDifferenceInMinutes(
                    eventDto.start,
                    eventDto.end
                  ) /
                    15) *
                  21;
              }

              // let left = weekIndex * tdWidth;
              let left = (tdWidth - 60) / 2;
              storedEventDetails = {
                eventDto: eventDto,
                topOffset: topOffset,
                height: height,
                left: left,
                width: (tdWidth - 60) / 2,
              };
              timeSlotDto.eventDtls = storedEventDetails;
              timeSlotDto.available = false;
              firstTimeSlotDto.eventDtls.width = (tdWidth - 60) / 2;
            } else if (timeDivMap.has(weekDayYYYYMMDD + "_3")) {
              let timeSlotsList = timeDivMap.get(
                weekDayYYYYMMDD + "_3"
              ).timeSlots;
              let timeFormat = DateUtils.formatDateTo12HourFormat(
                new Date(eventDto.start)
              );

              console.log(
                "eventDto 3--->>>>>>>>",
                weekDayYYYYMMDD + "_3",
                eventDto,
                timeFormat,
                timeSlotsList
              );

              for (let index = 0; index < timeSlotsList.length; index++) {
                const thirdSlot = timeSlotsList[index];

                if (
                  thirdSlot.time.split(":")[0] === timeFormat.split(":")[0] &&
                  thirdSlot.time.split(" ")[0].split(":")[1] === "00" &&
                  ((timeFormat.split(" ")[1] === "PM" &&
                    thirdSlot.time.split(" ")[1] === "PM") ||
                    (timeFormat.split(" ")[1] === "AM" &&
                      thirdSlot.time.split(" ")[1] === "AM"))
                ) {
                  console.log(
                    "eventDto 3--->>>>>>>> 2",
                    weekDayYYYYMMDD + "_3",
                    eventDto,
                    timeFormat,
                    timeSlotsList
                  );
                  if (thirdSlot.eventList && thirdSlot.eventList.length > 0) {
                    thirdSlot.eventList.push(eventDto);
                  } else {
                    let tempList = [];
                    tempList.push(eventDto);
                    thirdSlot.eventList = tempList;
                  }
                }
              }
            }
          } else {
          }
        }
      }
    }

    return items;
  };

  const displayEvents = () => {
    return eventsToDisplay;
  };

  //draggable rectangle
  // const [startTimeRec, setStartTimeRec] = useState("00:30");
  // const [endTimeRec, setEndTimeRec] = useState("01:30");
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartPosition, setDragStartPosition] = useState(0);
  const [rectangleTop, setRectangleTop] = useState(0);
  const calendarRef = useRef(null);
  const [rectangleHeight, setRectangleHeight] = useState(21);
  // Calculate time difference in minutes

  // useEffect(() => {
  //   if (fromTime && toTime) {
  //     setRectangleHeight(calculateRectangleHeight(fromTime, toTime));
  //   }
  // }, [fromTime, toTime]);

  const calculateTimeDifference = (startTime, endTime) => {
    console.log("calculateTimeDifference", startTime, endTime);
    let startTimePeriod = startTime.split(" ")[1];
    let endTimePeriod = endTime.split(" ")[1];
    let [startHour, startMinute] = startTime
      .split(" ")[0]
      .split(":")
      .map(Number);
    let [endHour, endMinute] = endTime.split(" ")[0].split(":").map(Number);

    if (startTimePeriod === "PM" && startHour !== 12) {
      startHour += 12;
    }

    if (endTimePeriod === "PM" && endHour !== 12) {
      endHour += 12;
    }

    const startTotalMinutes = startHour * 60 + startMinute;
    const endTotalMinutes = endHour * 60 + endMinute;

    return endTotalMinutes - startTotalMinutes;
  };

  // Calculate height of the rectangle based on time difference
  const calculateRectangleHeight = (fromTime, toTime) => {
    const timeDifference = calculateTimeDifference(fromTime, toTime);
    console.log("timeDifference", timeDifference);
    return (timeDifference / 15) * 21; // Assuming each 15 minutes corresponds to 21px
  };

  const handleStartTimeChange = (event) => {
    // setStartTimeRec(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    // setEndTimeRec(event.target.value);
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStartPosition(event.clientY);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const deltaY = event.clientY - dragStartPosition;
      const newTop = rectangleTop + deltaY;
      let currentDate = startDate;
      if (DateUtils.isToday(currentDate)) {
        let presentDateTime = new Date();
        let startHour = presentDateTime.getHours();
        let startMinute = presentDateTime.getMinutes();

        if (startMinute % 15 !== 0) {
          startMinute = startMinute + (15 - (startMinute % 15));
        }

        console.log("handleMouseMove-->>>", startHour, startMinute);
        let minTop = ((startHour * 60 + startMinute) / 15) * 21;
        // minTop = minTop + 10;
        if (newTop < minTop) {
          return;
        }
      }

      setRectangleTop(newTop);

      setDragStartPosition(event.clientY);
      let numberOfMinutes = newTop / 21;
      numberOfMinutes = Math.floor(numberOfMinutes * 15);
      numberOfMinutes = numberOfMinutes - (numberOfMinutes % 15);
      adjustTimeAsPerDrag(DateUtils.convertMinutesToTime(numberOfMinutes));
    }
  };

  const adjustTimeAsPerDrag = (dragFromTime) => {
    console.log("adjustTimeAsPerDrag", dragFromTime);
    setFromTime(dragFromTime);
    // Parse the time string using a regular expression
    var match = dragFromTime.match(/(\d+):(\d+) ([APMapm]{2})/);

    if (match) {
      var hours = parseInt(match[1], 10);
      var minutes = parseInt(match[2], 10);
      var period = match[3].toUpperCase();
      console.log(hours, minutes, period);

      if (hours === 11 && minutes === 30 && period === "PM") {
        console.log("inside", hours, minutes, period);
        // let currentDate = new Date(startDate);
        // setEndDate(currentDate);
        // setEndDay(
        //   currentDate.toLocaleDateString("en-US", {
        //     weekday: "long",
        //   })
        // );
        setToTime("11:45 PM");
        setToTimeList(["11:45 PM"]);
      } else if (hours === 11 && minutes === 45 && period === "PM") {
        let currentDate = new Date(startDate);
        currentDate.setHours(0, 0, 0, 0);
        currentDate.setDate(currentDate.getDate() + 1);
        let allowedTime =
          DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
            currentDate
          );
        // setEndDate(currentDate);
        // setEndDay(
        //   currentDate.toLocaleDateString("en-US", {
        //     weekday: "long",
        //   })
        // );
        setToTime("11:45 PM");
        setToTimeList(["11:45 PM"]);
      } else {
        if (period === "PM" && hours !== 12) {
          hours += 12;
        }
        var currentDate = new Date(startDate);
        currentDate.setHours(hours, minutes, 0, 0);
        let allowedTime =
          DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
            currentDate
          );
        let numberOfMinutes = (rectangleTop + rectangleHeight) / 21;
        numberOfMinutes = Math.floor(numberOfMinutes * 15);
        numberOfMinutes = numberOfMinutes - (numberOfMinutes % 15);
        setToTime(DateUtils.convertMinutesToTime(numberOfMinutes));
        setToTimeList(allowedTime.slice(1));
        // setEndDate(currentDate);
        // setEndDay(
        //   currentDate.toLocaleDateString("en-US", {
        //     weekday: "long",
        //   })
        // );
      }
    } else {
      console.log("Invalid time format");
    }
  };

  const onChangeStartTime = (selectedTime) => {
    console.log("onChangeStartTime", selectedTime);
    setFromTime(selectedTime);
    // Parse the time string using a regular expression
    var match = selectedTime.match(/(\d+):(\d+) ([APMapm]{2})/);

    if (match) {
      var hours = parseInt(match[1], 10);
      var minutes = parseInt(match[2], 10);
      var period = match[3].toUpperCase();
      console.log(hours, minutes, period);

      if (hours === 11 && minutes === 30 && period === "PM") {
        console.log("inside", hours, minutes, period);
        // let currentDate = new Date(startDate);
        // setEndDate(currentDate);
        // setEndDay(
        //   currentDate.toLocaleDateString("en-US", {
        //     weekday: "long",
        //   })
        // );
        setToTime("11:45 PM");
        setToTimeList(["11:45 PM"]);
        setRectangleHeight(calculateRectangleHeight(selectedTime, "11:45 PM"));
        setRectangleTop(((23 * 60 + 30) / 15) * 21);
      } else if (hours === 11 && minutes === 45 && period === "PM") {
        let currentDate = new Date(startDate);
        currentDate.setHours(0, 0, 0, 0);
        currentDate.setDate(currentDate.getDate() + 1);
        let allowedTime =
          DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
            currentDate
          );
        // setEndDate(currentDate);
        // setEndDay(
        //   currentDate.toLocaleDateString("en-US", {
        //     weekday: "long",
        //   })
        // );
        setToTime(allowedTime[0]);
        setToTimeList(allowedTime);
        setRectangleHeight(
          calculateRectangleHeight(selectedTime, allowedTime[0])
        );
        setRectangleTop(((23 * 60 + 45) / 15) * 21);
      } else {
        if (period === "PM" && hours !== 12) {
          hours += 12;
        }
        var currentDate = new Date(startDate);
        currentDate.setHours(hours, minutes, 0, 0);
        let allowedTime =
          DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
            currentDate
          );
        setToTime(allowedTime[1]);
        setToTimeList(allowedTime.slice(1));
        // setEndDate(currentDate);
        // setEndDay(
        //   currentDate.toLocaleDateString("en-US", {
        //     weekday: "long",
        //   })
        // );
        console.log("calculateRectangleHeight", selectedTime, allowedTime[1]);
        setRectangleHeight(
          calculateRectangleHeight(selectedTime, allowedTime[1])
        );
        setRectangleTop(((hours * 60 + minutes) / 15) * 21);
      }
    } else {
      console.log("Invalid time format");
    }
  };

  const onChangeEndTime = (selectedTime) => {
    setToTime(selectedTime);
    setRectangleHeight(calculateRectangleHeight(fromTime, selectedTime));
  };

  const headerRef = useRef(null);
  const bodyRef = useRef(null);

  // Function to handle horizontal scroll
  const handleScroll = (refToSync, scrollValue) => {
    if (refToSync.current) {
      refToSync.current.scrollLeft = scrollValue;
    }
  };

  // Function to sync scroll between divs
  const syncScroll = (e) => {
    const { scrollLeft } = e.target;
    handleScroll(headerRef, scrollLeft);
    handleScroll(bodyRef, scrollLeft);
  };

  const scrollToDraggableRef = () => {
    console.log(
      "scrollToDraggableRef1",
      draggableRecRef?.current,
      draggableRecRef?.current?.style.top
    );
    if (draggableRecRef && draggableRecRef.current) {
      console.log(
        "scrollToDraggableRef2",
        draggableRecRef?.current,
        draggableRecRef?.current?.style.top
      );
      const rect = draggableRecRef.current.getBoundingClientRect();
      let topPosition = rect.top;
      console.log("scrollToDraggableRef3", topPosition, rectangleTop);
      draggableRecRef.current.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: rectangleTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToDraggableRef();
  }, [startDate]);

  return (
    <>
      {!isDataLoaded ? (
        <>
          {" "}
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        </>
      ) : (
        <div className="Convenient_Time_View_Container">
          <div className="convenientDateTimeFrmGrp">
            <div className="convoHeadTxt">
              {mode && mode !== "createTask"
                ? "Convenient Date Time"
                : "Date Time"}
            </div>
            <div className="convoDTFormContainer">
              <div className="formElement">
                <div className="medDateTime">
                  <div
                    className="frmDateTime"
                    style={{
                      display: "flex !important",
                      flexWrap: "wrap",
                      justifyContent: "center !important",
                    }}
                  >
                    <div className="frmDateInput">
                      <div className="frmDaysPick">
                        {weekDays[startDate.getDay()]}
                      </div>
                      {/* START TIME */}
                      <ReactDatePicker
                        className="frmdatePick"
                        selected={startDate}
                        minDate={new Date()}
                        onChange={(date) => {
                          if (DateUtils.isToday(date)) {
                            var match = fromTime.match(
                              /(\d+):(\d+) ([APMapm]{2})/
                            );

                            var hours = parseInt(match[1], 10);
                            var minutes = parseInt(match[2], 10);
                            var period = match[3].toUpperCase();

                            if (period === "PM" && hours !== 12) {
                              hours += 12;
                            }

                            let fromTimeTemp = new Date(date);
                            fromTimeTemp.setHours(hours, minutes, 0, 0);

                            if (fromTimeTemp.getTime() < new Date().getTime()) {
                              let allowedTime =
                                DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
                                  new Date()
                                );
                              setFromTimeList(allowedTime);
                              setFromTime(allowedTime[0]);
                              if ("11:45 PM" === allowedTime[0]) {
                                setToTimeList(allowedTime);
                                setToTime(allowedTime[0]);
                              } else {
                                setToTimeList(allowedTime.slice(1));
                                setToTime(allowedTime[1]);
                              }
                            } else {
                            }

                            setStartDate(date);
                          } else {
                            setStartDate(date);
                            // if (DateUtils.isToday(new Date(date))) {
                            //   setFromTimeList(
                            //     DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
                            //       new Date(date)
                            //     )
                            //   );
                            // } else {
                            //   let currentDate = new Date(date);
                            //   currentDate.setHours(0, 0, 0, 0);
                            //   setFromTimeList(
                            //     DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
                            //       currentDate
                            //     )
                            //   );
                            // }

                            let currentDate = new Date(date);
                            currentDate.setHours(0, 0, 0, 0);
                            setFromTimeList(
                              DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
                                currentDate
                              )
                            );

                            let endTimeStart = new Date(date);

                            endTimeStart.setMinutes(
                              endTimeStart.getMinutes() + 15
                            );

                            setToTimeList(
                              DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
                                endTimeStart
                              )
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="frmTimeInput">
                      <Select
                        className="slctFrmTime"
                        value={fromTime}
                        onChange={(event) => {
                          if (DateUtils.isToday(startDate)) {
                            let selectedTime = event.target.value;
                            let selectedTimePeriod = selectedTime.split(" ")[1];
                            let [selectedTimeHour, selectedTimeMinute] =
                              selectedTime.split(" ")[0].split(":").map(Number);

                            if (
                              selectedTimePeriod === "PM" &&
                              selectedTimeHour !== 12
                            ) {
                              selectedTimeHour += 12;
                            }

                            let currentDateTime = new Date();
                            currentDateTime.setHours(
                              selectedTimeHour,
                              selectedTimeMinute,
                              0,
                              0
                            );
                            if (currentDateTime < new Date()) {
                              toast.error("Please select a future time");
                              return;
                            }
                          }

                          onChangeStartTime(event.target.value);
                        }}
                        label="Date"
                      >
                        {/* <MenuItem value="2-00am">2:00AM</MenuItem>
                      <MenuItem value="2-30am">2:30AM</MenuItem> */}
                        {fromTimeList.map((fromTime) => {
                          return (
                            <MenuItem value={fromTime}>{fromTime}</MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </div>

                  <span className="spanElement">To</span>

                  <div className="toDateTime">
                    <div className="toTimeInput">
                      <Select
                        className="slctFrmTime"
                        value={toTime}
                        onChange={(event) => {
                          onChangeEndTime(event.target.value);
                        }}
                        label="Date"
                      >
                        {toTimeList.map((toTime) => {
                          return <MenuItem value={toTime}>{toTime}</MenuItem>;
                        })}
                      </Select>
                    </div>
                  </div>
                  <div className="formBtnElement">
                    <Button
                      className="dfultPrimaryBtn"
                      onClick={() => {
                        console.log(
                          "setSelectedConvenientTime",
                          startDate,
                          fromTime,
                          toTime
                        );

                        const isOverlap = handleOverlapping();

                        console.log("is overlapping =", isOverlap);

                        if (isOverlap) {
                          const temp = {
                            title:
                              "You have selected meeting that overlaps with other meeting. Do you want to proceed?",
                            positiveButtonName: "Yes",
                            negativeButtonName: "No",
                            handlePositive: () => {
                              console.log("handling positive");
                              setSelectedConvenientTime({
                                convStartDate: startDate,
                                convFromTime: fromTime,
                                convToTime: toTime,
                              });
                              viewForm("select");
                              toast.success("Convenient time selected");
                              if (handleClose) {
                                handleClose();
                              }
                              setShowConfirm(false);
                              setCustomConfirmationObj({});
                            },
                            handleNegative: () => {
                              console.log("handling Negative");
                              setShowConfirm(false);
                              setCustomConfirmationObj({});
                            },
                          };
                          setCustomConfirmationObj(temp);
                          setShowConfirm(true);
                          return;
                        }

                        setSelectedConvenientTime({
                          convStartDate: startDate,
                          convFromTime: fromTime,
                          convToTime: toTime,
                        });
                        viewForm("select");
                        toast.success("Convenient time selected");
                        if (handleClose) {
                          handleClose();
                        }
                        // let startTimePeriod = fromTime.split(" ")[1];
                        // let endTimePeriod = toTime.split(" ")[1];
                        // let [startHour, startMinute] = fromTime
                        //   .split(" ")[0]
                        //   .split(":")
                        //   .map(Number);
                        // let [endHour, endMinute] = toTime
                        //   .split(" ")[0]
                        //   .split(":")
                        //   .map(Number);

                        // if (startTimePeriod === "PM" && startHour !== 12) {
                        //   startHour += 12;
                        // }

                        // if (endTimePeriod === "PM" && endHour !== 12) {
                        //   endHour += 12;
                        // }

                        // let startDateTime = new Date(startDate);
                        // startDateTime.setHours(startHour, )
                      }}
                    >
                      Select
                    </Button>
                    {(!mode || (mode && mode !== "createTask")) && (
                      <Button
                        className="dfultDarkSecondaryBtn"
                        startIcon={hideForm ? <Eye /> : <EyeSlash />}
                        onClick={() => {
                          viewForm("open-close");
                        }}
                      >
                        {hideForm ? "Open Form" : "Close Form"}
                      </Button>
                    )}
                  </div>
                  {handleClose && (
                    <div class="flotActBtn closeBtnD10">
                      <IconButton onClick={handleClose}>
                        <XLg />
                      </IconButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="scheduler-wrapper">
            <div className="scheduler-header">
              <div className="overview-content">
                <div className="left-panel">
                  <div className="overview-scheduler">
                    <div className="e-control e-schedule e-lib schedule-overview e-keyboard">
                      <div className="e-table-container">
                        <div className="e-table-wrap e-vertical-view e-week-view e-current-panel">
                          <ScrollSync>
                            <table className="e-schedule-table e-outer-table">
                              <tbody>
                                <tr>
                                  <td className="e-left-indent">
                                    <div className="e-left-indent-wrap"> </div>
                                  </td>
                                  <td>
                                    <div className="e-date-header-container">
                                      <div
                                        className="e-date-header-wrap e-all-day-auto overflowAuto"
                                        style={{ borderRightWidth: "1px" }}
                                        ref={headerRef}
                                        onScroll={syncScroll}
                                      >
                                        <table className="e-schedule-table ">
                                          <tbody>
                                            <tr className="e-header-row">
                                              {/* {console.log(
                                              "mappedData222",
                                              mappedData
                                            )} */}
                                              {Object.keys(mappedData).map(
                                                (userId) => (
                                                  <td
                                                    colspan="1"
                                                    className="e-resource-cells e-disable-dates conView250"
                                                    // style={{ width: "250px" }}
                                                    key={userId}
                                                  >
                                                    <div>
                                                      <div className="template-wrap">
                                                        <div className="resource-image ">
                                                          <img
                                                            src={
                                                              mappedData[userId]
                                                                .userDetails
                                                                .profileImage
                                                            }
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div className="resource-details">
                                                          <div className="resource-name">
                                                            {
                                                              mappedData[userId]
                                                                .userDetails
                                                                .userName
                                                            }
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </td>
                                                )
                                              )}
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <ScrollSyncPane group="vertical">
                                      <div className="e-time-cells-wrap convoLeftRightCalHeight ">
                                        <table className="e-schedule-table ">
                                          <tbody>{displayLeftSideTime()}</tbody>
                                        </table>
                                      </div>
                                    </ScrollSyncPane>
                                  </td>
                                  <td>
                                    <ScrollSyncPane group="vertical">
                                      <div
                                        className="e-content-wrap convoLeftRightCalHeight"
                                        onMouseMove={handleMouseMove}
                                        onMouseUp={handleMouseUp}
                                        ref={bodyRef}
                                        onScroll={syncScroll}
                                      >
                                        <table
                                          className="e-schedule-table e-content-table"
                                          style={{
                                            width: `${
                                              usersList.length >= 6
                                                ? usersList.length * 250
                                                : 1310
                                            }px`,
                                          }}
                                        >
                                          <>
                                            <thead>
                                              {displayEvents()}
                                              <tr>
                                                <td
                                                  colSpan={`${usersList.length}`}
                                                  className="e-timeline-wrapper draggbleTimeSpan"
                                                >
                                                  <div
                                                    ref={draggableRecRef}
                                                    className="e-appointment e-lib e-draggable draggbleTimeSpanBorder"
                                                    style={{
                                                      height: `${rectangleHeight}px`,
                                                      top: rectangleTop,
                                                      width: `${
                                                        tdWidth *
                                                        usersList.length
                                                      }px !important`,
                                                    }}
                                                    onMouseDown={
                                                      handleMouseDown
                                                    }
                                                  ></div>
                                                </td>
                                              </tr>
                                            </thead>

                                            <tbody>
                                              {rows.map((row, rowIndex) => {
                                                // console.log(
                                                //   "row[=====>>>>>>}",
                                                //   rows
                                                // );
                                                //console.log("cols", cols);
                                                let noOfDivisions = parseInt(
                                                  60 / slotDuration
                                                );
                                                // let rownumber = row % noOfDivisions;
                                                // let rowWidth = (21 * 4) / noOfDivisions;

                                                let className =
                                                  "e-work-cells e-alternate-cells conView250";
                                                let currentTop = 0;
                                                if (noOfDivisions === 4) {
                                                  className =
                                                    "e-work-cells e-alternate-cells conView250" +
                                                    " firstSlot";
                                                  currentTop = rowIndex * 21;
                                                } else if (
                                                  noOfDivisions === 2
                                                ) {
                                                  className =
                                                    "e-work-cells e-alternate-cells conView250" +
                                                    " secondSlot";
                                                  currentTop =
                                                    rowIndex * 21 * 2;
                                                } else if (
                                                  noOfDivisions === 1
                                                ) {
                                                  className =
                                                    "e-work-cells e-alternate-cells conView250" +
                                                    " thirdSlot";
                                                  currentTop =
                                                    rowIndex * 21 * 4;
                                                }

                                                let disabledClassName = "";

                                                if (
                                                  DateUtils.isToday(startDate)
                                                ) {
                                                  let presentDateTime =
                                                    new Date();
                                                  let startHour =
                                                    presentDateTime.getHours();
                                                  let startMinute =
                                                    presentDateTime.getMinutes();

                                                  if (startMinute % 15 !== 0) {
                                                    startMinute =
                                                      startMinute +
                                                      (15 - (startMinute % 15));
                                                  }

                                                  console.log(
                                                    "handleMouseMove-->>>",
                                                    startHour,
                                                    startMinute
                                                  );
                                                  let minTop =
                                                    ((startHour * 60 +
                                                      startMinute) /
                                                      15) *
                                                    21;
                                                  // minTop = minTop + 10;
                                                  if (currentTop < minTop) {
                                                    disabledClassName =
                                                      " selectionDisabled";
                                                  }
                                                }

                                                return (
                                                  <tr>
                                                    {Object.keys(
                                                      mappedData
                                                    ).map((col, colIndex) => {
                                                      let tempClassName =
                                                        className;
                                                      let currentDay =
                                                        meetingStartTime.getFullYear() +
                                                        "-" +
                                                        (meetingStartTime.getMonth() +
                                                          1 >
                                                        9
                                                          ? meetingStartTime.getMonth() +
                                                            1
                                                          : "0" +
                                                            (meetingStartTime.getMonth() +
                                                              1)) +
                                                        "-" +
                                                        (meetingStartTime.getDate() >
                                                        9
                                                          ? meetingStartTime.getDate()
                                                          : "0" +
                                                            meetingStartTime.getDate());

                                                      let colOrgHoliday =
                                                        "bgHoliday";
                                                      let colOrgHLeave =
                                                        "bgOnLeav";
                                                      let colOrgWeekOff =
                                                        "bgWekOff";

                                                      return (
                                                        <>
                                                          <td
                                                            className={
                                                              false
                                                                ? `${tempClassName} selected`
                                                                : `${tempClassName} ${disabledClassName}`
                                                            }
                                                            aria-selected="false"
                                                            colspan="1"
                                                            // name="Hello"
                                                            ref={tdRef}
                                                            // class={tempClassName}
                                                            //style={style}
                                                          ></td>
                                                        </>
                                                      );
                                                    })}
                                                  </tr>
                                                );
                                                // } else {
                                                //   return (
                                                //     <tr>
                                                //       {cols.map((col) => {
                                                //         return (
                                                //           <>
                                                //             <td
                                                //               aria-selected="false"
                                                //               colspan="1"
                                                //               class="e-work-cells"
                                                //             ></td>
                                                //           </>
                                                //         );
                                                //       })}
                                                //     </tr>
                                                //   );
                                                // }
                                              })}
                                            </tbody>
                                          </>
                                        </table>
                                      </div>
                                    </ScrollSyncPane>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </ScrollSync>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showConfirm && (
        <CustomConfirmation
          openModal={showConfirm}
          handleCloseModal={(e) => {
            setShowConfirm(false);
          }}
          // title={"Hello"}
          // positiveButtonName={"Yes"}
          // negativeButtonName={"No"}
          // handlePositive={() => {
          //   console.log("handling positive");
          // }}
          // handleNegative={() => {
          //   console.log("handling positive");
          // }}
          {...customConfirmationObj}
        />
      )}
    </>
  );
}
