import React, { useRef } from "react";
import "../../css/onboarding.css";
import { Box, Button, Grid, IconButton } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slideOneImg from "../../images/slider/slide01.png";
import slideTwoImg from "../../images/slider/slide02.png";
import slideThreeImg from "../../images/slider/slide03.png";
import slideFourImg from "../../images/slider/slide04.png";
import slideFiveImg from "../../images/slider/slide05.png";
import { ArrowForwardIos, FileCopyOutlined } from "@mui/icons-material";
import SelectTemplate from "./SelectTemplate";
export default function OnboardingSlider({ onboardingData }) {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const settings = {
    lazyLoad: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: false,
    nextArrow: false,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Box
        className="onboardingSliderContainer"
        sx={{ bgcolor: "dark.pageBg" }}
      >
        <Grid container spacing={0} className="onboardingSliderContainArea">
          <Grid item xs={12}>
            <div className="onboardingSliderWorkSpace">
              <div className="onBoardslider">
                <Slider
                  ref={(slider) => {
                    sliderRef = slider;
                  }}
                  {...settings}
                >
                  <div>
                    <div className="sliderElement">
                      <div className="sliderImg">
                        <img src={slideOneImg} alt="slide one" />
                      </div>
                      <div className="slideTextArea">
                        <div className="slideTxtHead">
                          Welcome to Zoyel:
                          <br /> Where Ideas Thrive
                          {/* {onboardingData.orgId}
                          {onboardingData.userLoginId}
                          {onboardingData.password}
                          {onboardingData.orgWebLink} */}
                        </div>
                        <div className="slideTxte">
                          We're thrilled to have you on board and are excited to
                          enhance your experience with Zoyel.
                          {/* As you embark on
                          this journey with us, get ready to unlock a world of
                          convenience, productivity, and fun. */}
                          <p>
                            {" "}
                            Please find your details of your organization
                            website below:
                          </p>
                          <p>
                            <label>
                              <strong>Website link:</strong>{" "}
                            </label>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              className="orgSliderOrgWebLink"
                              href={`${onboardingData?.orgWebLink}`}
                            >
                              {onboardingData?.orgWebLink}
                            </a>
                            <span className="lnkCpyBtn" title="Click to copy">
                              <IconButton
                                aria-label="copy"
                                size="small"
                                className="copyLink"
                                onClick={() =>
                                  navigator.clipboard.writeText(
                                    onboardingData?.orgWebLink
                                  )
                                }
                              >
                                <FileCopyOutlined />
                              </IconButton>
                            </span>
                          </p>
                          <p>
                            <label>
                              <strong>Login ID:</strong>{" "}
                            </label>
                            <span
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  onboardingData?.userLoginId
                                )
                              }
                              title="Click to copy"
                              style={{ cursor: "pointer" }}
                            >
                              {onboardingData?.userLoginId}
                            </span>
                            <span className="lnkCpyBtn" title="Click to copy">
                              <IconButton
                                aria-label="copy"
                                size="small"
                                className="copyLink"
                                onClick={() =>
                                  navigator.clipboard.writeText(
                                    onboardingData?.userLoginId
                                  )
                                }
                              >
                                <FileCopyOutlined />
                              </IconButton>
                            </span>
                          </p>
                          <p>
                            <label>
                              <strong>Password:</strong>{" "}
                            </label>
                            <span
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  onboardingData?.password
                                )
                              }
                              title="Click to copy"
                              style={{ cursor: "pointer" }}
                            >
                              {onboardingData?.password}
                            </span>
                            <span className="lnkCpyBtn" title="Click to copy">
                              <IconButton
                                aria-label="copy"
                                size="small"
                                className="copyLink"
                                onClick={() =>
                                  navigator.clipboard.writeText(
                                    onboardingData?.password
                                  )
                                }
                              >
                                <FileCopyOutlined />
                              </IconButton>
                            </span>
                          </p>
                          {/* <p>{onboardingData.orgWebLink}</p> */}
                        </div>
                        <div className="slideBtnNxt">
                          <Button
                            variant="outlined"
                            endIcon={<ArrowForwardIos />}
                            onClick={next}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="sliderElement">
                      <div className="sliderImg">
                        <img src={slideOneImg} alt="slide one" />
                      </div>
                      <div className="slideTextArea">
                        <div className="slideTxtHead">
                          Welcome to Zoyel:
                          <br /> Where Ideas Thrive
                          {/* {onboardingData.orgId}
                          {onboardingData.userLoginId}
                          {onboardingData.password}
                          {onboardingData.orgWebLink} */}
                        </div>
                        <div className="slideTxte">
                          Welcome to our Zoyel, where collaboration transcends
                          traditional communication barriers. Say goodbye to
                          cluttered inboxes and hello to streamlined efficiency!
                          With tasks as the centerpiece of our platform, we've
                          redefined how teams work together, making
                          collaboration effortless and communication seamless.
                          Let's embark on this journey together!
                        </div>
                        <div className="slideBtnNxt">
                          <Button
                            variant="outlined"
                            endIcon={<ArrowForwardIos />}
                            onClick={next}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="sliderElement">
                      <div className="sliderImg">
                        <img src={slideTwoImg} alt="slide one" />
                      </div>
                      <div className="slideTextArea">
                        <div className="slideTxtHead">
                          Start by creating a team
                        </div>
                        <div className="slideTxte">
                          Ready to supercharge your team's productivity? Let's
                          get started! Create your team by seamlessly adding
                          members to Zoyel. Collaboration awaits as you unlock
                          the power of collective genius. Together, we'll
                          transform ideas into action and conquer challenges
                          with unparalleled efficiency. Let's build something
                          incredible, together!
                        </div>
                        <div className="slideBtnNxt">
                          <Button
                            variant="outlined"
                            endIcon={<ArrowForwardIos />}
                            onClick={next}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="sliderElement">
                      <div className="sliderImg">
                        <img src={slideThreeImg} alt="slide one" />
                      </div>
                      <div className="slideTextArea">
                        <div className="slideTxtHead">
                          Collaborating more efficiently using Tasks
                        </div>
                        <div className="slideTxte">
                          Say goodbye to endless email chains and hello to
                          streamlined efficiency! With our intuitive task-based
                          system, communication becomes lightning-fast. Assign
                          tasks, set deadlines, and track progress—all within
                          one platform. Experience the power of direct action
                          and watch productivity soar as tasks replace emails
                          for seamless collaboration.
                        </div>
                        <div className="slideBtnNxt">
                          <Button
                            variant="outlined"
                            endIcon={<ArrowForwardIos />}
                            onClick={next}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="sliderElement">
                      <div className="sliderImg">
                        <img src={slideFourImg} alt="slide one" />
                      </div>
                      <div className="slideTextArea">
                        <div className="slideTxtHead">
                          Templates for uniformity and ease
                        </div>
                        <div className="slideTxte">
                          Elevate your workflow with our extensive library of
                          customizable templates! From project plans to meeting
                          agendas, effortlessly upload and share templates with
                          your team. Streamline processes, maintain consistency,
                          and accelerate productivity with tried-and-tested
                          formats tailored to your needs. Simplify collaboration
                          and achieve greatness together.
                        </div>
                        <div className="slideBtnNxt">
                          <Button
                            variant="outlined"
                            endIcon={<ArrowForwardIos />}
                            onClick={next}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="sliderElement">
                      <div className="sliderImg">
                        <img src={slideFiveImg} alt="slide one" />
                      </div>
                      <div className="slideTextArea">
                        <div className="slideTxtHead">
                          A website which is unique as you
                        </div>
                        <div className="slideTxte">
                          Transform your ideas into stunning websites with just
                          one click! Our innovative one-click website creation
                          feature offers a curated selection of exceptional
                          templates. Choose from a variety of sleek designs
                          tailored to your vision. Effortlessly launch your
                          online presence and captivate audiences in moments.
                          Elevate your brand with ease and simplicity.
                        </div>
                        <div className="slideBtnNxt">
                          <Button
                            variant="outlined"
                            endIcon={<ArrowForwardIos />}
                            onClick={next}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="sliderElement">
                      <SelectTemplate onboardingData={onboardingData} />
                      {/* <div className="sliderImg">
                        <img src={slideFiveImg} alt="slide one" />
                      </div>
                      <div className="slideTextArea">
                        <div className="slideTxtHead">
                          A website which is unique as you
                        </div>
                        <div className="slideTxte">
                          Transform your ideas into stunning websites with just
                          one click! Our innovative one-click website creation
                          feature offers a curated selection of exceptional
                          templates. Choose from a variety of sleek designs
                          tailored to your vision. Effortlessly launch your
                          online presence and captivate audiences in moments.
                          Elevate your brand with ease and simplicity.
                        </div>
                        <div className="slideBtnNxt">
                          <Button
                            variant="outlined"
                            endIcon={<ArrowForwardIos />}
                            onClick={next}
                          >
                            Next
                          </Button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
