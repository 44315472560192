import {
  Add,
  ArrowBack,
  BrowseGallery,
  CalendarMonth,
  Edit,
  Search,
  Settings,
  Visibility,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Eye, Trash } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import {
  getHolidaysList,
  deleteHoliday,
} from "../../../../services/AdminService";
import { toast } from "react-toastify";
import AppUtils from "../../../../utils/AppUtils";

export default function ViewHolidayWorkingday({
  handelCloseViewHoliday,
  handelOpenAddHoliday,
  handelOpenAddWorkingday,
  refreshHoliday,
  handelOpenEditHoliday,
  handelOpenSelectedviewHoliday,
  handleOpenAddWorkingShift,
  closeAll
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));

  const [loading, setLoading] = useState(true);

  const [holidaysData, setHolidaysData] = useState([]);

  const [filterHolidaysData, setFilterHolidaysData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const [holidayToDelete, setHolidayToDelete] = useState(null);

  useEffect(() => {
    fetchHolidayData();
  }, [refreshHoliday]);

  const fetchHolidayData = () => {
    getHolidaysList(userDetails.orgId).then(async (response) => {
      console.log("getHolidayList", response.data);
      const sortedData = response.data.sort((a, b) => {
        const dateA = a.holidayDate.split("-").reverse().join("-");
        const dateB = b.holidayDate.split("-").reverse().join("-");
        return dateA.localeCompare(dateB);
      });
      setHolidaysData(sortedData);
      setFilterHolidaysData(sortedData);
      setLoading(false);
    });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = holidaysData.filter(
      (holiday) =>
        holiday.holidayName &&
        holiday.holidayName.toLowerCase().includes(query.toLowerCase())
    );

    setFilterHolidaysData(filteredData);
  };

  const handleDelete = (holidayDate) => {
    console.log("handleDelete", holidayDate);
    setHolidayToDelete(holidayDate);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    setOpenModal(false);
    if (holidayToDelete) {
      deleteHoliday(
        userDetails.orgId,
        userDetails.userId,
        holidayToDelete
      ).then((response) => {
        console.log("data deleted", response.data);
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchHolidayData();
          // setOpenModal(false);
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // setOpenModal(false);
        }
      });
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton onClick={() => {handelCloseViewHoliday(); closeAll();}}>
            <ArrowBack />
          </IconButton>
          Holiday / Working Day
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder="Search Holiday"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<BrowseGallery />}
                  className="dfultPrimaryBtn"
                  onClick={() => handleOpenAddWorkingShift()}
                >
                  Shift
                </Button>
                <Button
                  startIcon={<CalendarMonth />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddWorkingday()}
                >
                  Working Days
                </Button>
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddHoliday()}
                >
                  Holiday
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : holidaysData.length === 0 ? (
                <div class="noDataCard ">
                  <span>Oops!!</span>No Holiday Define yet !!
                  <br />
                  <span className="sallMsg">
                    Click Add Holiday Button to create new Holiday
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th className="width100">Sl. No.</th>
                        <th>Holiday Name</th>
                        <th>Date</th>
                        <th>Description</th>
                        <th className="width100"></th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                      {filterHolidaysData.map((holiday, index) => (
                        <tr key={index}>
                          <td>
                            <div className="tskLstTaskNM">{index + 1}.</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {holiday.holidayName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {holiday.holidayDate}
                            </div>
                          </td>
                          <td>
                            <Tooltip title={holiday.holidayEmailContent} arrow>
                              <div className="tskLstTaskDescription">
                                {holiday.holidayEmailContent}
                              </div>
                            </Tooltip>
                          </td>
                          <td>
                            <div className="tblActionBtnGrp">
                              <IconButton
                                onClick={() =>
                                  handelOpenSelectedviewHoliday(holiday)
                                }
                              >
                                <Eye />
                              </IconButton>
                              <IconButton
                                onClick={() => handelOpenEditHoliday(holiday)}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                className="removeRowBtn"
                                onClick={() =>
                                  handleDelete(holiday.holidayDate)
                                }
                              >
                                <Trash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>

                    {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">1.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">INDEPENDENCE DAY</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">15-08-2024</div>
                      </td>
                      <td>
                        <Tooltip
                          title="Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lore"
                          arrow
                        >
                          <div className="tskLstTaskDescription">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lore
                          </div>
                        </Tooltip>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Eye />
                          </IconButton>
                          <IconButton>
                            <Edit />
                          </IconButton>
                          <IconButton className="removeRowBtn">
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                  </table>
                </div>
              )}

              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // width: "250px",
                    // height: "250px",
                    maxWidth: "600px",
                    minHeight: "150px",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 id="modal-modal-title">
                    Do you want to delete this Holiday ?
                  </h2>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      // variant="contained"
                      // color="secondary"
                      className="dfultPrimaryBtn"
                      onClick={confirmDelete}
                    >
                      Yes
                    </Button>
                    <Button
                      //  variant="contained"
                      className="dfultDarkSecondaryBtn"
                      onClick={handleCloseModal}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
