import {
  ArrowBackIosNew,
  ArrowForwardIos,
  DescriptionOutlined,
  Download,
  Edit,
  HeightOutlined,
  MonitorWeightOutlined,
  Save,
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import {
  HeartPulse,
  Lungs,
  Play,
  ThermometerHalf,
} from "react-bootstrap-icons";
import BP from "../../../../../../dashboard/image/BP";
import SPO2 from "../../../../../../dashboard/image/SPO2";
import Comorbidity from "./Comorbidity";
import DrugAllergy from "./DrugAllergy";
import FamilyHistory from "./FamilyHistory";
import FoodAllergy from "./FoodAllergy";
import HistoryRiskFactor from "./HistoryRiskFactor";
import Investigations from "./Investigations";
import Medication from "./Medication";
import PossibleDiagnosis from "./PossibleDiagnosis";
import Signs from "./Signs";
import Symptoms from "./Symptoms";
import TestResult from "./TestResult";
import Diagnosis from "./Diagnosis";
import Recommendations from "./Recommendations";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import OtherOptions from "./OtherOptions";
import { downloadFileFromUrl } from "../../../../../../../utils/FileUtils";
import {
  getHealthDto,
  getVitalSvgAccordingToVitalType,
} from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";

export default function PrescriptionEdit({ updateData, data, extraData }) {
  const [vitals, setVitals] = useState([]);

  // **************************BMI CALCULATE**************************

  const audioUrl = useRef();
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [bmi, setBmi] = useState("");
  const [heightOutput, setHeightOutput] = useState("");
  const [weightOutput, setWeightOutput] = useState("");
  const [bmiClass, setBmiClass] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(1);
  const [pescriptionData, setPescriptionData] = useState({});
  const [patientType, setPatientType] = useState("statNormal");
  const [vitalUnitList, setVitalUnitList] = useState([]);

  const [vitalUnitMap, setVitalUnitMap] = useState(null);
  const [showAudioModal, setShowAudioModal] = useState(false);

  const calculateBmi = () => {
    const heightInMeters = height / 100;
    const bmiValue = parseFloat(weight / heightInMeters ** 2).toFixed(2);
    const bmiGroupIndex = [
      [0, 18.49],
      [18.5, 24.99],
      [25, 29.99],
      [30, 34.99],
      [35, 39.99],
      [40, 200],
    ].findIndex((e) => e[0] <= bmiValue && bmiValue < e[1]);

    const heightInInches = (height * 0.393700787).toFixed(0);
    const feet = Math.floor(heightInInches / 12);
    const inches = heightInInches % 12;

    setHeightOutput(`${height} cm / ${feet}' ${inches}"`);
    setWeightOutput(`${weight} kg / ${(2.2046 * weight).toFixed(2)} lb`);
    setBmi(bmiValue);
    setSelectedGroup(bmiGroupIndex);

    const bmiClasses = [
      "bmi-underweight",
      "bmi-normal",
      "bmi-preobesity",
      "bmi-obese1",
      "bmi-obese2",
      "bmi-obese3",
    ];
    setBmiClass(bmiClasses[bmiGroupIndex]);
  };

  const convertHeightInCm = (value, unit) => {
    if (unit === "m") {
      return value * 100;
    } else if (unit === "cm") {
      return value;
    }
  };

  const convertWeightInKg = (value, unit) => {
    if (unit === "kgs") {
      return value;
    } else if (unit === "lbs") {
      return value * 0.45359237;
    }
  };

  useEffect(() => {
    getVitalUnits();

    if (data && data.vitals) {
      setVitals(data.vitals);
    }

    // if (extraData.queueVitals) {
    //   let vitalList = extraData.queueVitals.split("~");
    //   if (vitalList.length > 0) {
    //     vitalList = vitalList.map((item) => {
    //       const vitalComponents = item.split("#");
    //       if (vitalComponents[vitalComponents.length - 1] === "Height") {
    //         let value = vitalComponents[1];
    //         value = parseInt(value.match(/\d+/)[0], 10);
    //         // const isKg = value.toLowerCase().includes("kg");
    //         setHeight(value);
    //       } else if (vitalComponents[vitalComponents.length - 1] === "Weight") {
    //         let value = vitalComponents[1];
    //         value = parseInt(value.match(/\d+/)[0], 10);
    //         // const isKg = value.toLowerCase().includes("kg");
    //         setWeight(value);
    //       }
    //       return vitalComponents;
    //     });
    //   }
    //   setVitals(vitalList);
    // }
    if (!data || !data.vitals) {
      if (extraData && extraData.reqValue && extraData.reqValue.vitals) {
        setVitals(extraData.reqValue.vitals);
        const tempHeight = extraData.reqValue.vitals.find(
          (item) => item.vitalCode === "HEIGHT"
        );
        const tempWeight = extraData.reqValue.vitals.find(
          (item) => item.vitalCode === "WEIGHT"
        );

        const convertedHeight = convertHeightInCm(
          tempHeight.vitalValue,
          tempHeight.unit
        );
        const convertWeight = convertWeightInKg(
          tempWeight.vitalValue,
          tempWeight.unit
        );

        setHeight(convertedHeight);
        setWeight(convertWeight);
        setVitals(extraData.reqValue.vitals);
        updateData({ vitals: extraData.reqValue.vitals });
      }
    }

    if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("opd")
    ) {
      setPatientType("statNormal");
    } else if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("urgent")
    ) {
      setPatientType("statImportant");
    } else if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("review")
    ) {
      setPatientType("statReview");
    } else if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("emergency")
    ) {
      setPatientType("statUrgent");
    }

    // calculateBmi();
  }, []);

  const getVitalUnits = () => {
    const diseasetring = localStorage.getItem("vitalUnitList");
    if (diseasetring) {
      setVitalUnitList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getVitalUnits(reqDto).then((response) => {
      if (response.data) {
        setVitalUnitList(response.data);
        localStorage.setItem("vitalUnitList", JSON.stringify(response.data));
      }
    });
  };

  useEffect(() => {
    if (height && weight) {
      calculateBmi();
    }
  }, [height, weight]);

  // document start
  const elementRef = useRef(null);
  const fileElementRef = useRef(null);
  const [filearrowDisable, setFilearrowDisable] = useState(true);
  const filehandleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setFilearrowDisable(true);
      } else {
        setFilearrowDisable(false);
      }
    }, speed);
  };

  const [fileDivWidth, setFileDivWidth] = useState(0);

  useEffect(() => {
    if (fileElementRef.current) {
      setFileDivWidth(fileElementRef.current.offsetWidth);
    }
  }, [fileElementRef]);

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  // const getVitalSvgAccordingToVitalType = (vitalType) => {
  //   if (vitalType === "BP") {
  //     return <BP />;
  //   } else if (vitalType === "Pulse") {
  //     return <HeartPulse />;
  //   } else if (vitalType === "SPO2") {
  //     return <SPO2 />;
  //   } else if (vitalType === "Temperature") {
  //     return <ThermometerHalf />;
  //   } else if (vitalType === "RR") {
  //     return <Lungs />;
  //   } else if (vitalType === "Weight") {
  //     return <MonitorWeightOutlined />;
  //   } else if (vitalType === "Height") {
  //     return <HeightOutlined />;
  //   }
  // };
  // document end

  // edit vitals
  const [editMode, setEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({});

  const handleSaveVitals = () => {
    setEditMode(!editMode);
    if (editMode) {
      const tempVitals = vitals.map((item) => ({
        ...item,
        vitalValue: item.updatedData.vitalValue,
        unit: item.updatedData.unit,
      }));
      setVitals(tempVitals);
      updateData({ vitals: tempVitals });
    } else {
      const tempVitals = vitals.map((item) => {
        let foot = 0;
        let inch = 0;
        if (item.unit === "ft~in") {
          foot = Math.floor(parseInt(item.vitalValue) / 12);
          inch = parseInt(item.vitalValue) % 12;
        }
        if (isNaN(foot)) {
          foot = 0;
        }
        if (isNaN(inch)) {
          inch = 0;
        }
        return {
          ...item,
          updatedData: {
            vitalValue: item.vitalValue,
            unit: item.unit,
            foot,
            inch,
          },
        };
      });
      setVitals(tempVitals);
    }
  };

  const mapUnitsToVitals = () => {
    const tempVitalUnitMap = {};
    vitalUnitList.forEach((item) => {
      if (!tempVitalUnitMap[item.vitalCode]) {
        tempVitalUnitMap[item.vitalCode] = [item.vitalUnit];
      } else {
        tempVitalUnitMap[item.vitalCode].push(item.vitalUnit);
      }
    });
    setVitalUnitMap(tempVitalUnitMap);
  };

  useEffect(() => {
    if (vitalUnitList && vitalUnitList.length > 0) {
      mapUnitsToVitals();
    }
  }, [vitalUnitList]);

  const handleInputChange = (index, event) => {
    // const newValues = { ...editedValues, [index]: event.target.value };
    const newValue = [...vitals];
    newValue[index].updatedData.vitalValue = event.target.value;
    setVitals(newValue);
  };

  return (
    <>
      <div className="MeetingTranscriptionContainer">
        <div className="Meeting_Transcription">
          <div className="elementFormContainer">
            <div className="tskPriotGrp">
              <div class={`tskPriot ${patientType}`}>
                <div class="priStat">{extraData.queueConsultationType}</div>
              </div>
              {/* <div class="tskPriot statImportant">
                <div class="priStat">Urgent</div>
              </div>
              <div class="tskPriot statReview">
                <div class="priStat">Review</div>
              </div>
              <div class="tskPriot statUrgent">
                <div class="priStat">Emergency</div>
              </div> */}
            </div>

            <div className="pDemography">
              <div className="pDemogInd">
                {/* <span>Name:</span> */}
                <span className="pDVlue">{extraData.queuePatientName}</span>
              </div>
              <div className="pDemogInd">
                {/* <span>Gender:</span> */}
                <span className="pDVlue">{extraData.queuePatientGender}</span>
              </div>
              <div className="pDemogInd">
                {/* <span>Age:</span> */}
                <span className="pDVlue">{extraData.queuePatientAge}</span>
              </div>
              <div className="pDemogInd">
                <span>Insurance:</span>
                <span className="pDVlue">YES</span>
              </div>
              {/* <div className="pDemogInd">
                <span>Card:</span>
                <span className="pDVlue">DADS2012541LP</span>
              </div> */}
            </div>

            <div className="taskElementsGrpWithTwo mt10">
              <div className="taskElementGrp">
                <div className="tskElemHeding">Refer By</div>
                <div className="tskElemntInfo">{extraData.queueReferredBy}</div>
              </div>
            </div>
            <div className="tskElemHeding mt10">Vitals</div>

            <div className="vitalsGroup">
              {/* {/* {vitals &&
                vitals.map((vital) => {
                  return (
                    <div className="vitalsInd">
                      <div className="vitIcon">
                        {getVitalSvgAccordingToVitalType(vital[3])}
                      </div>
                      <div className="vitInfo">
                        <div className="vitNm">{vital[0]}</div>
                        <div className="vitValue vNormal">{vital[1]}</div>
                      </div>
                    </div>
                  );
                })} */}

              {/* {extraData &&
                extraData.reqValue &&
                extraData.reqValue.vitals &&
                extraData.reqValue.vitals.map((vital) => {
                  return (
                    <div className="vitalsInd">
                      <div className="vitIcon">
                        {getVitalSvgAccordingToVitalType(vital.vitalCode)}
                      </div>
                      <div className="vitInfo">
                        <div className="vitNm">{vital.vitalName}</div>
                        <div className="vitValue vNormal">{`${vital.vitalValue} ${vital.unit}`}</div>
                      </div>
                    </div>
                  );
                })} */}

              {vitals &&
                vitals.map((vital, index) => {
                  return (
                    <div key={index} className="vitalsInd">
                      <div className="vitIcon">
                        {getVitalSvgAccordingToVitalType(vital.vitalCode)}
                      </div>
                      <div className="vitInfo">
                        <div className="vitNm">{vital.vitalName}</div>
                        <div className="vitValue vNormal">
                          {editMode ? (
                            <>
                              {vital.vitalCode !== "LMP" &&
                                vital.updatedData.unit !== "ft~in" && (
                                  <input
                                    className="editInputVitals"
                                    type="text"
                                    value={vital.updatedData.vitalValue}
                                    onChange={(event) =>
                                      handleInputChange(index, event)
                                    }
                                  />
                                )}
                              {vital.vitalCode === "LMP" && (
                                <input
                                  className="editInputVitals"
                                  type="date"
                                  value={
                                    new Date(
                                      Date.parse(vital.updatedData.vitalValue)
                                    )
                                  }
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                />
                              )}
                              {vital.unit &&
                                vital.updatedData.unit === "ft~in" && (
                                  <>
                                    <input
                                      className="editInputVitals"
                                      type="text"
                                      value={vital.updatedData.foot}
                                      onChange={(event) => {
                                        const tempVital = [...vitals];
                                        let foot = parseInt(event.target.value);
                                        if (isNaN(foot)) {
                                          foot = 0;
                                        }
                                        tempVital[
                                          index
                                        ].updatedData.vitalValue =
                                          foot * 12 +
                                          parseInt(
                                            tempVital[index].updatedData.inch
                                          );

                                        tempVital[index].updatedData.foot =
                                          foot;
                                        setVitals(tempVital);
                                        // handleInputChange(index, event);
                                      }}
                                    />

                                    <input
                                      className="editInputVitals"
                                      type="text"
                                      value={vital.updatedData.inch}
                                      onChange={(event) => {
                                        const tempVital = [...vitals];
                                        let inch = parseInt(event.target.value);
                                        if (isNaN(inch)) {
                                          inch = 0;
                                        }
                                        tempVital[
                                          index
                                        ].updatedData.vitalValue =
                                          tempVital[index].updatedData.foot *
                                            12 +
                                          inch;

                                        tempVital[index].updatedData.inch =
                                          inch;
                                        setVitals(tempVital);
                                        // handleInputChange(index, event);
                                      }}
                                    />
                                  </>
                                )}
                              {vital.unit &&
                                (vitalUnitMap[vital.vitalCode].length > 1 ? (
                                  <select
                                    className="editInputUnits"
                                    value={vital.updatedData.unit}
                                    onChange={(event) => {
                                      const newValue = [...vitals];
                                      newValue[index].updatedData.unit =
                                        event.target.value;
                                      setVitals(newValue);
                                    }}
                                  >
                                    {vitalUnitMap[vital.vitalCode].map(
                                      (item) => {
                                        return (
                                          <option key={item} value={item}>
                                            {item}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                ) : vitalUnitMap[vital.vitalCode].length ===
                                  1 ? (
                                  <>
                                    <span>
                                      {vitalUnitMap[vital.vitalCode][0]}
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                ))}
                            </>
                          ) : (
                            <>{`${vital.vitalValue}  ${
                              vital.unit
                                ? `${
                                    vital.unit === "per minute"
                                      ? "/min"
                                      : vital.unit
                                  }`
                                : ""
                            }`}</>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div>
                <Button
                  className="joinMetBtn fullHeight"
                  startIcon={<>{editMode ? <Save /> : <Edit />}</>}
                  onClick={handleSaveVitals}
                >
                  Vitals
                </Button>
              </div>

              {/* <div className="vitalsInd">
                <div className="vitIcon">
                  <HeartPulse />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">Pulse Rate</div>
                  <div className="vitValue vRisk">130/min</div>
                </div>
              </div>
              <div className="vitalsInd">
                <div className="vitIcon">
                  <SPO2 />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">SPO2</div>
                  <div className="vitValue vNormal">99%</div>
                </div>
              </div>
              <div className="vitalsInd">
                <div className="vitIcon">
                  <ThermometerHalf />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">Temperature</div>
                  <div className="vitValue vNormal">98°F</div>
                </div>
              </div>
              <div className="vitalsInd">
                <div className="vitIcon">
                  <Lungs />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">Respiration Rate</div>
                  <div className="vitValue vModarate">20/min</div>
                </div>
              </div>
              <div className="vitalsInd">
                <div className="vitIcon">
                  <MonitorWeightOutlined />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">Body Weight</div>
                  <div className="vitValue vNormal">85kg</div>
                </div>
              </div>
              <div className="vitalsInd">
                <div className="vitIcon">
                  <HeightOutlined />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">Body Height</div>
                  <div className="vitValue vNormal">182cm</div>
                </div>
              </div> */}
            </div>

            {height && weight && (
              <div className="bmiGroup">
                <div className="c-bmi__result">
                  BMI:
                  <span name="r" className={`vitValue ${bmiClass}`}>
                    {bmi}
                  </span>
                </div>
                <div className="c-bmi__groups" readOnly>
                  {[
                    "Underweight",
                    "Normal",
                    "Pre-obesity",
                    "Obese I",
                    "Obese II",
                    "Obese III",
                  ].map((label, index) => (
                    <div key={index}>
                      <input
                        type="radio"
                        className="bmiInputRadio"
                        id={`bmi-g${index}`}
                        name="g"
                        checked={selectedGroup === index}
                        readOnly
                      />
                      <label htmlFor={`bmi-g${index}`}>{label}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="taskElementGrp mt10">
              <div className="tskElemHeding">Patient complaints</div>
              <div className="tskElemntInfo">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries.
              </div>
            </div>
            <div className="taskElementGrp">
              <div className="tskElemntInfo">
                <div className="playAudioNotes">
                  <IconButton
                    variant="outlined"
                    onClick={() => {
                      setShowAudioModal(true);
                    }}
                  >
                    <Play />
                  </IconButton>
                  <span>Patient Audio Message</span>
                </div>
              </div>
            </div>

            {extraData &&
              extraData.reqValue &&
              extraData.reqValue.docs &&
              extraData.reqValue.docs.length > 0 && (
                <div className="taskElementGrp mt10">
                  <div className="tskElemHeding">Files</div>

                  <div class="elementFileListedGrp">
                    <div className="horaizonScroll">
                      <IconButton
                        className="goPrevousBtn"
                        onClick={() => {
                          filehandleHorizantalScroll(
                            fileElementRef.current,
                            25,
                            200,
                            -10
                          );
                        }}
                        disabled={filearrowDisable}
                      >
                        <ArrowBackIosNew />
                      </IconButton>

                      <div class="elemntFilsgrp" ref={fileElementRef}>
                        {extraData.reqValue.docs.map((item) => {
                          return (
                            <div className="elemntFile">
                              <Tooltip
                                title="Past medication file.pdf"
                                arrow
                                className="elmFileTolTip"
                              >
                                <span className="elemntFilIoc">
                                  <DescriptionOutlined />
                                </span>
                                <span className="elemntFilNm">
                                  {item.fileName}
                                </span>
                                <span
                                  className="elemntFilIoc"
                                  onClick={() => {
                                    downloadFileFromUrl(
                                      item.fileLink,
                                      item.fileName
                                    );
                                  }}
                                >
                                  <Download />
                                </span>
                              </Tooltip>
                            </div>
                          );
                        })}
                      </div>
                      {fileDivWidth > 370 && (
                        <IconButton
                          className="goNextBtn"
                          onClick={() => {
                            filehandleHorizantalScroll(
                              fileElementRef.current,
                              25,
                              200,
                              10
                            );
                          }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      )}
                    </div>
                    {/* <div class="elmntAddMrFiles">
                  <Button
                    className="elemntMrFileBtn"
                    variant="outlined"
                    onClick={handleAddnewFile}
                  >
                    <Plus />
                  </Button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleChangeFile}
                    style={{ display: "none" }}
                  ></input>
                </div> */}
                  </div>
                </div>
              )}
            <div className="bb"></div>

            {/* start symptoms */}
            <Symptoms
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
            {/* end symptoms */}

            {/* start Signs */}
            <Signs updateData={updateData} data={data} extraData={extraData} />
            {/* end Signs */}

            {/* start Comorbidity */}
            <Comorbidity updateData={updateData} data={data} />
            {/* end Comorbidity */}

            {/* start HistoryRiskFactor */}
            <HistoryRiskFactor
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
            {/* end HistoryRiskFactor */}

            {/* start DrugAllergy */}
            <DrugAllergy updateData={updateData} data={data} />
            {/* end DrugAllergy */}

            {/* start FoodAllergy */}
            <FoodAllergy updateData={updateData} data={data} />
            {/* end FoodAllergy */}

            {/* start FamilyHistory */}
            <FamilyHistory updateData={updateData} data={data} />
            {/* end FamilyHistory */}

            {/* start possible Diagnosis */}
            <PossibleDiagnosis updateData={updateData} data={data} />
            {/* end possible Diagnosis */}

            {/* start Investigations */}
            <Investigations updateData={updateData} data={data} />
            {/* end Investigations */}

            {/* start TestRsult */}
            <TestResult
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
            {/* end TestRsult */}

            {/* start Diagnosis */}
            <Diagnosis
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
            {/* end Diagnosis */}

            {/* start Medication */}
            <Medication updateData={updateData} data={data} />
            {/* end Medication */}

            {/* start Recommendations */}
            <Recommendations updateData={updateData} data={data} />
            {/* end Recommendations */}

            {/* start OtherOptions */}
            <OtherOptions
              updateData={updateData}
              data={data}
              extraData={extraData}
            />
            {/* end OtherOptions */}
          </div>
        </div>
      </div>
      {showAudioModal && (
        <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
          <div className="addMoreAgendaModel">
            <div className="modelTitle">Audio Message</div>
            <div className="modActionBtnGrp">
              <audio controls>
                <source src={`${audioUrl.current}`} />
              </audio>
            </div>

            <div className="modActionBtnGrp">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  setShowAudioModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      )}
    </>
  );
}
