import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Download, Trash } from "react-bootstrap-icons";
import { downloadFileFromUrl } from "../../../../../../../utils/FileUtils";

export default function TestResult({ extraData }) {
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  useEffect(() => {
    console.log("***************** extraData =", extraData);
  }, []);

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            Test Result:
            <span>{/*symptoms gose here when acordion not expend*/}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="testResult">
            <table className="testResulttable">
              <thead>
                <tr>
                  <th className="w50p">Test Name</th>
                  <th className="w15p textCenter">Value</th>
                  <th className="w15p textCenter">Unit</th>
                  <th className="w20p">Bio. Ref Interval</th>
                </tr>
              </thead>
              <tbody>
                {extraData &&
                  extraData.reqValue &&
                  extraData.reqValue.tests &&
                  extraData.reqValue.tests.map((item) => {
                    return (
                      <>
                        <tr>
                          <td colSpan={4} className="singlRow">
                            <div className="testNameH">{item.testName}</div>
                            {item.reportLink && (
                              <span
                                onClick={() => {
                                  downloadFileFromUrl(
                                    item.reportLink,
                                    "prescription"
                                  );
                                }}
                              >
                                <Download />
                              </span>
                            )}
                          </td>
                        </tr>
                        {item.parameterList &&
                          item.parameterList.map((parItem) => {
                            return (
                              <tr>
                                <td>
                                  <div className="testElmGrp">
                                    <div className="testElmNM">
                                      {parItem.parameterName}
                                    </div>
                                    <div className="testElmDtl">
                                      Method: ENZymatic
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="testValue textCenter riskValue">
                                    {parItem.parameterValue}
                                  </div>
                                </td>
                                <td>
                                  <div className="testUnit textCenter">
                                    {parItem.parameterUnit}
                                  </div>
                                </td>
                                <td>
                                  <div className="testNrmlRange">
                                    {parItem.parameterRange && (
                                      <div className="tstnRangeElm">
                                        Desirable : &lt;{" "}
                                        {parItem.parameterRange.split("-")[0]}
                                      </div>
                                    )}
                                    <div className="tstnRangeElm">
                                      Borderline: {parItem.parameterRange}
                                    </div>

                                    {parItem.parameterRange && (
                                      <div className="tstnRangeElm">
                                        High : &gt;/=
                                        {parItem.parameterRange.split("-")[1]}
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    );
                  })}
                {/* <tr>
                  <td>
                    <div className="testElmGrp">
                      <div className="testElmNM">Serum Triglycerides</div>
                      <div className="testElmDtl">Method: GPO TRINDER</div>
                    </div>
                  </td>
                  <td>
                    <div className="testValue textCenter">90</div>
                  </td>
                  <td>
                    <div className="testUnit textCenter">mg/dl</div>
                  </td>
                  <td>
                    <div className="testNrmlRange">
                      <div className="tstnRangeElm">Desirable : &lt; 150</div>
                      <div className="tstnRangeElm">
                        Borderline High: 150-199
                      </div>
                      <div className="tstnRangeElm"> High: 200-499</div>
                      <div className="tstnRangeElm">Very High : &gt; 500</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="testElmGrp">
                      <div className="testElmNM">Serum HDL Cholesterol</div>
                      <div className="testElmDtl">
                        Method: Elimination/catalase
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="testValue textCenter riskValue">39.7</div>
                  </td>
                  <td>
                    <div className="testUnit textCenter">mg/dl</div>
                  </td>
                  <td>
                    <div className="testNrmlRange">
                      <div className="tstnRangeElm">40 - 60</div>
                    </div>
                  </td>
                </tr> */}
                {/* <tr>
                  <td>
                    <div className="testElmGrp">
                      <div className="testElmNM">Total Cholesterol</div>
                      <div className="testElmDtl">
                        Method: Elimination/catalase
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="testValue textCenter riskValue">186.90</div>
                  </td>
                  <td>
                    <div className="testUnit textCenter">mg/dl</div>
                  </td>
                  <td>
                    <div className="testNrmlRange">
                      <div className="tstnRangeElm">Optimal : &lt; 100</div>
                      <div className="tstnRangeElm">
                        Near /Above Optimal: 200-239
                      </div>
                      <div className="tstnRangeElm">
                        Borderline High: 130 - 159
                      </div>
                      <div className="tstnRangeElm">High: : 160 - 189</div>
                      <div className="tstnRangeElm">Very High : &gt;/=190</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="testElmGrp">
                      <div className="testElmNM">Serum VLDL Cholesterol</div>
                      <div className="testElmDtl">Method: Calculated</div>
                    </div>
                  </td>
                  <td>
                    <div className="testValue textCenter">18.0</div>
                  </td>
                  <td>
                    <div className="testUnit textCenter">mg/dl</div>
                  </td>
                  <td>
                    <div className="testNrmlRange">
                      <div className="tstnRangeElm">06 - 30</div>
                    </div>
                  </td>
                </tr> */}
                <tr>
                  <td colSpan={4} className="emptyRow">
                    <div className="testReportText">
                      <span className="rheadtext">Inference: </span>
                      <span className="rDescptext">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </span>
                    </div>
                    <div className="testReportText">
                      <span className="rheadtext">Possible Conditions: </span>
                      <span className="rDescptext">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </span>
                    </div>
                  </td>
                </tr>
                {/* <tr>
                  <td colSpan={4} className="singlRow">
                    <div className="testNameH">Fasting Blood Sugar</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="testElmGrp">
                      <div className="testElmNM">Glucose, Fasting</div>
                      <div className="testElmDtl">Method: Hexokinase</div>
                    </div>
                  </td>
                  <td>
                    <div className="testValue textCenter riskValue">117</div>
                  </td>
                  <td>
                    <div className="testUnit textCenter">mg/dl</div>
                  </td>
                  <td>
                    <div className="testNrmlRange">
                      <div className="tstnRangeElm">70 - 100</div>
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
          <div className="bb"></div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
