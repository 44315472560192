import React from "react";
import AddPeople from "./component/AddPeople";

export default function RightFlotingAnalyticsDashboard({ children }) {
  return (
    <>
      <div className="RightFlotingContainerArea">{children}</div>
    </>
  );
}
