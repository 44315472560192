import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { getFilteredList, getHealthDto } from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export default function FamilyHistory({ updateData, data }) {
  const [selectRelationship, setSelectRelationship] = useState("");
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [selectedDisease, setSelectedDisease] = useState(null);
  const [diseaseInputString, setDiseaseInputString] = useState("");
  const [diseaseList, setDiseaseList] = useState([]);
  const [userFamilyDiseaseHistoryList, setUserFamilyDiseaseHistoryList] =
    useState([]);

  const initializeForm = () => {
    setSelectedDisease(null);
    setDiseaseInputString("");
    setSelectRelationship("");
  };

  const suggestedDiseaseList = useMemo(() => {
    const filteredList = getFilteredList(
      diseaseList,
      diseaseInputString,
      "diseaseName"
    );
    return filteredList.slice(0, 10);
  }, [diseaseInputString, diseaseList]);

  const familyDiseaseHistoryString = useMemo(() => {
    const str =
      userFamilyDiseaseHistoryList.length > 0
        ? userFamilyDiseaseHistoryList
            .map((item) => `${item.disease.diseaseName} (${item.relationShip})`)
            .join(", ")
        : "";
    return str;
  }, [userFamilyDiseaseHistoryList]);

  useEffect(() => {
    if (data.familyHistory) {
      setUserFamilyDiseaseHistoryList(data.familyHistory);
    }

    getHereditaryDiseases();
  }, []);

  const getHereditaryDiseases = () => {
    const diseasetring = localStorage.getItem("heriditaryDisease");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getHereditaryDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);
        localStorage.setItem(
          "heriditaryDisease",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const handleChangeRelationship = (event) => {
    setSelectRelationship(event.target.value);
  };
  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            Relevant Family History:
            {!accordionExpanded && <span>{familyDiseaseHistoryString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="complaintList">
            <ul>
              {userFamilyDiseaseHistoryList &&
                userFamilyDiseaseHistoryList.map((item, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp">
                        <div className="complstDtl  ">
                          <div className="compntName">
                            {item.disease.diseaseName}
                          </div>
                          <div className="compinfoGRP">
                            <div className="compSeverity">
                              <span>Relationship:</span>
                              <span className="data">{item.relationShip}</span>
                            </div>
                          </div>
                        </div>
                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...userFamilyDiseaseHistoryList];
                            temp.splice(index, 1);
                            updateData({ familyHistory: temp });
                            setUserFamilyDiseaseHistoryList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </li>
                  );
                })}

              {/* add new symptoms */}
              <li>
                <div className="addNewformGrp">
                  <div className="formElement">
                    <FormControl className="formControl">
                      {/* <TextField
                        // hiddenLabel
                        label="Family Disease History"
                        autoComplete="off"
                        placeholder="Enter Disease"
                        variant="outlined"
                        className="formTextFieldArea"
                      /> */}

                      <Autocomplete
                        freeSolo
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedDisease}
                        options={suggestedDiseaseList}
                        inputValue={diseaseInputString}
                        onChange={(e, newValue) => {
                          setSelectedDisease(newValue);
                        }}
                        onInputChange={(e, value, reason) => {
                          if (e && reason === "input") {
                            setDiseaseInputString(e.target.value);
                          } else if (reason === "reset") {
                            setDiseaseInputString(value);
                          } else if (reason === "clear") {
                            setDiseaseInputString(value);
                          }
                          // else {
                          //   setAllergicFoodSearchString("");
                          // }
                        }}
                        getOptionLabel={(option) => option.diseaseName}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {item.diseaseName}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder="Family Disease History"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel id="severity-select-label">
                        Relationship
                      </InputLabel>
                      <Select
                        labelId="severity-select-label"
                        id="severity-select"
                        value={selectRelationship}
                        label="Relationship"
                        onChange={handleChangeRelationship}
                        className="formInputField"
                        variant="outlined"
                      >
                        <MenuItem value="Father">Father</MenuItem>
                        <MenuItem value="Mother"> Mother </MenuItem>
                        <MenuItem value="Sister"> Sister </MenuItem>
                        <MenuItem value="Self">Self </MenuItem>
                        <MenuItem value="Son">Son </MenuItem>
                        <MenuItem value="Daughter">Daughter </MenuItem>
                        <MenuItem value="Spouse">Spouse </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    // onClick={() => {
                    //   const temp = [...userFamilyDiseaseHistoryList];
                    //   temp.splice(index, 1);
                    //   setUserAllergicFoodList(temp);
                    // }}

                    onClick={() => {
                      if (!diseaseInputString || diseaseInputString === "") {
                        toast.error("Please Provide Family Disease Name");
                        return;
                      }
                      if (selectRelationship === "") {
                        toast.error("Please Select Relationship");
                        return;
                      }
                      let tempFamilyHistory = selectedDisease;
                      if (!tempFamilyHistory) {
                        tempFamilyHistory = {
                          id: uuidv4(),
                          diseaseName: diseaseInputString,
                          locallyAdded: true,
                        };
                      }
                      setUserFamilyDiseaseHistoryList((prev) => {
                        const temp = [
                          ...prev,
                          {
                            disease: tempFamilyHistory,
                            relationShip: selectRelationship,
                          },
                        ];

                        updateData({ familyHistory: temp });
                        return temp;
                      });
                      initializeForm();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
