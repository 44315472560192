import React from "react";

export default function LMP() {
  return (
    <>
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.627 22C10.0216 22 8.51186 21.6943 7.09766 21.0828C5.68347 20.4704 4.45316 19.641 3.40672 18.5946C2.35943 17.5473 1.53003 16.3165 0.918536 14.9023C0.306179 13.4881 0 11.9784 0 10.373V2.77017C0 2.33092 0.1912 2.01828 0.573601 1.83225C0.956001 1.64622 1.32118 1.68067 1.66913 1.9356L6.92196 5.88363C7.4482 5.02064 8.02309 4.13527 8.64664 3.2275C9.2702 2.31973 9.95835 1.39559 10.7111 0.455094C10.9574 0.143317 11.2627 -0.00826534 11.627 0.000347283C11.9914 0.00895991 12.2971 0.160542 12.5443 0.455094C13.2798 1.39645 13.9632 2.31542 14.5945 3.21199C15.2267 4.10857 15.8059 4.99911 16.3321 5.88363L21.585 1.9356C21.9329 1.67895 22.2981 1.6445 22.6805 1.83225C23.0629 2.02001 23.2541 2.33265 23.2541 2.77017V10.373C23.2541 11.9784 22.9483 13.4881 22.3368 14.9023C21.7245 16.3165 20.8951 17.5468 19.8487 18.5933C18.8014 19.6406 17.5706 20.47 16.1564 21.0815C14.7422 21.6938 13.2324 22 11.627 22ZM3.87568 14.2486C3.87568 13.2702 4.07506 12.1708 4.47383 10.9504C4.87173 9.73089 5.46514 8.4123 6.25406 6.99466L1.29189 3.26755V10.373C1.29189 12.0162 1.65578 13.5265 2.38354 14.9036C3.11131 16.2808 4.08841 17.4125 5.31485 18.2987C4.87388 17.7518 4.52378 17.136 4.26454 16.4513C4.0053 15.7666 3.87568 15.0324 3.87568 14.2486ZM11.627 20.7081C13.4142 20.7081 14.9377 20.0781 16.1978 18.8181C17.4578 17.558 18.0874 16.0349 18.0865 14.2486C18.0865 12.7277 17.5232 10.8096 16.3967 8.49455C15.271 6.17947 13.6812 3.79119 11.627 1.32971C9.57379 3.80153 7.9839 6.19239 6.85737 8.5023C5.73084 10.8131 5.16757 12.7285 5.16757 14.2486C5.16757 16.0358 5.79759 17.5593 7.05761 18.8194C8.31764 20.0794 9.84078 20.709 11.627 20.7081ZM19.3784 14.2486C19.3784 15.0324 19.2488 15.7666 18.9895 16.4513C18.7303 17.136 18.3806 17.7518 17.9405 18.2987C19.1661 17.4116 20.1428 16.2799 20.8705 14.9036C21.5983 13.5273 21.9622 12.0171 21.9622 10.373V3.26755L17.0013 6.98691C17.7894 8.40454 18.3828 9.72314 18.7815 10.9427C19.1794 12.1631 19.3784 13.2651 19.3784 14.2486Z" />
      </svg>
    </>
  );
}
