import { Edit, KeyboardArrowDown, PendingOutlined } from "@mui/icons-material";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

export default function SetSalaryComponent({ handelOpenASC }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseADDSC = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="payrollContainArea">
        <div className="editPayrollElement width100p">
          <div className="elementFormContainer">
            <div className="salaryComponentBtnGrp">
              <div className="salaryCompHead">Salary Components</div>
              <div className="sCmenuButton">
                <Button
                  className="addNSCBtn"
                  id="addComponent-button"
                  aria-controls={open ? "addComponent-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDown />}
                >
                  Add Component
                </Button>
                <Menu
                  id="addComponent-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseADDSC}
                  MenuListProps={{
                    "aria-labelledby": "basic-addComponent",
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={() => {
                      handelOpenASC("_earnings_");
                      handleCloseADDSC();
                    }}
                  >
                    Earnings
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handelOpenASC("_deductions_");
                      handleCloseADDSC();
                    }}
                  >
                    Deductions
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handelOpenASC("_reimbursements_");
                      handleCloseADDSC();
                    }}
                  >
                    Reimbursements
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <div className="TableContainer">
              <table className="taskListTable">
                <thead className="taskListtableHeader">
                  <tr>
                    <th>Name</th>
                    <th>Components Type</th>
                    <th>Calculation Type</th>
                    <th>EPF</th>
                    <th>ESI</th>
                    <th>Status</th>
                    <th className="width50"></th>
                  </tr>
                </thead>
                <tbody className="scrolableTableBody">
                  <tr>
                    <td>
                      <div className="tdText">Basic</div>
                    </td>
                    <td>
                      <div className="tdText">Earning</div>
                    </td>
                    <td>
                      <div className="tdText">Fixed, 50% of CTC</div>
                    </td>
                    <td>
                      <div className="tdText">Yes</div>
                    </td>
                    <td>
                      <div className="tdText">Yes</div>
                    </td>
                    <td>
                      <div className="tdText">
                        <span className="active">Active</span>
                      </div>
                    </td>
                    <td>
                      <div className="tblActionBtnGrp">
                        <IconButton>
                          <Edit />
                          {/* onClick={() => handelOpenCrtCommittee()} */}
                        </IconButton>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="tdText">Basic</div>
                    </td>
                    <td>
                      <div className="tdText">Earning</div>
                    </td>
                    <td>
                      <div className="tdText">Fixed, 50% of CTC</div>
                    </td>
                    <td>
                      <div className="tdText">Yes</div>
                    </td>
                    <td>
                      <div className="tdText">Yes</div>
                    </td>
                    <td>
                      <div className="tdText ">
                        <span className="inActive">Inactive</span>
                      </div>
                    </td>

                    <td>
                      <div className="tblActionBtnGrp">
                        <IconButton>
                          <Edit />
                          {/* onClick={() => handelOpenCrtCommittee()} */}
                        </IconButton>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
