import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class WebsiteTemplateService {
  uploadTempImageBlog(reqDto) {
    return http.put(`/misc/uploadTempImageBlog`, reqDto, {
      headers: authHeader(),
    });
  }

  selectWebsiteTemplate(reqDto) {
    return http.put(`/org/selectWebsiteTemplate`, reqDto, {
      headers: authHeader(),
    });
  }
}
export default new WebsiteTemplateService();
