import React from "react";
import { getFormattedDate } from "../../../../../utils/Utils";
import { useTimeProgressor } from "../hooks/useTimerProgressor";

export const TaskTimeProgress = ({ data }) => {
  const dateRemaning = useTimeProgressor(data.createdAt, data.completionTime);
  return (
    <>
      <div className="taskElementGrp">
        <div className="tskElemHeding">Timeline</div>
        <div className="tskTimeline">
          <div className="tskTimBar">
            <div className="progress">
              <div
                // up50 up80 up100
                className={`progress-done ${
                  dateRemaning < 50
                    ? "up50"
                    : dateRemaning < 80
                    ? "up80"
                    : "up100"
                }`}
                style={{ width: `${dateRemaning}%`, opacity: 1 }}
              ></div>
            </div>
          </div>
          <div className="tskDtSE">
            <div className="tskDTStart">
              {getFormattedDate(data.taskCreationTime)}
            </div>
            <div className="tskDTEnd">
              {getFormattedDate(data.taskCompletionTime)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
