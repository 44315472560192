import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class DashboardServices {
  getDashboardData(userId) {
    return http.get(`dashboard/getDashboardData/${userId}`, {
      headers: authHeader(),
    });
  }

  getCCDataOfApprovalOfExpenditure(userId, taskDetailId, taskNature, indentId) {
    return http.get(
      `task/getCCDataOfApprovalOfExpenditure/${userId}/${taskDetailId}/${taskNature}/${indentId}`,
      {
        headers: authHeader(),
      }
    );
  }

  deleteDashboardDataById(body) {
    return http.put("dashboard/deleteDashboardDataById", body);
  }

  getContracts(orgId) {
    return http.get(`contract/getContracts/${orgId}`, {
      headers: authHeader(),
    });
  }

  getActivityList(orgId) {
    return http.get(`task/getActivityList/${orgId}`, {
      headers: authHeader(),
    });
  }

  getTemplatesOfSubActivity(taskMasterId, taskDetailId) {
    return http.get(
      `task/getTemplatesOfSubActivity/${taskMasterId}/${taskDetailId}`,
      {
        headers: authHeader(),
      }
    );
  }

  getAllTemplatesOfOrganization(orgId) {
    return http.get(`task/getAllTemplatesOfOrganization/${orgId}`, {
      headers: authHeader(),
    });
  }

  addTaskTemplate(userId, reqDto, taskType) {
    return http.post(`task/addTaskTemplate/${userId}/${taskType}`, reqDto, {
      headers: authHeader(),
    });
  }

  getOrgEmployees(orgId) {
    return http.get(`person/getOrgEmployees/${orgId}`, {
      headers: authHeader(),
    });
  }

  draftTemplate(reqDto, userId) {
    return http.put(`task/draftTemplate/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  uploadAudioBlog(reqDto) {
    return http.put(`misc/uploadAudioBlog`, reqDto, {
      headers: authHeader(),
    });
  }
  getTaskById(taskId) {
    return http.get(`task/getTaskById/${taskId}`, {
      headers: authHeader(),
    });
  }

  getTaskAssignees(taskDetailId) {
    return http.get(`task/getTaskAssignees/${taskDetailId}`, {
      headers: authHeader(),
    });
  }

  sendTaskComments(userId, reqDto) {
    return http.put(`task/sendTaskComments/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  uploadDocuments(orgId, userId, taskDetailId, reqDto) {
    return http.put(
      `task/uploadDocuments/${orgId}/${userId}/${taskDetailId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  transferTask(reqDto) {
    return http.put(`task/transferTask`, reqDto, { headers: authHeader() });
  }

  createLinkTask(userId, orgId, reqDto) {
    return http.post(`task/createLinkTask/${userId}/${orgId}`, reqDto, {
      headers: authHeader(),
    });
  }

  updateProgressOfTask(taskDetailId, userId, progress) {
    return http.put(
      `task/updateProgressOfTask/${taskDetailId}/${userId}/${progress}`,
      { headers: authHeader() }
    );
  }

  concernTask(taskDetailId, userId, reqDto) {
    return http.post(`task/concernTask/${taskDetailId}/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  rejectTask(taskDetailId, userId, reqString) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(
      `task/rejectTask/${taskDetailId}/${userId}`,
      reqString,
      config
    );
  }

  postponedTask(taskDetailId, userId, reqDto) {
    return http.put(`task/postponedTask/${taskDetailId}/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  approveTask(userId, reqDto) {
    return http.put(`task/approveTask/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  cancelTask(taskDetailId, userId, reqString) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(
      `task/cancelTask/${taskDetailId}/${userId}`,
      reqString,
      config
    );
  }

  addressConcernOfTask(taskDetailId, userId, reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.post(
      `task/addressConcernOfTask/${taskDetailId}/${userId}`,
      reqDto,
      config
    );
  }
  generatePreSignedUrlS3Object(fileName, folderName) {
    return http.get(
      `misc/generatePreSignedUrlS3Object/${fileName}/${folderName}/${3 * 3600}`,
      {
        headers: authHeader(),
      }
    );
  }

  disagreementTask(taskDetailId, userId, progress, reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.post(
      `task/disagreementTask/${taskDetailId}/${userId}/${progress}`,
      reqDto,
      config
    );
  }

  signOffTask(userId, reqDto) {
    return http.post(`task/signOffTask/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getNextTaskSuggestion(taskMasterId, subTask) {
    return http.get(`task/getNextTaskSuggestion/${taskMasterId}/${subTask}`, {
      headers: authHeader(),
    });
  }

  getTasksListByFunctionsAndActivity(orgId, funcId, actiId) {
    return http.get(
      `task/getTasksListByFunctionsAndActivity/${orgId}/${funcId}/${actiId}`,
      {
        headers: authHeader(),
      }
    );
  }

  reassignTask(userId, reqDto) {
    return http.put(`task/reassignTask/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  resumeRequest(taskDetailId, userId, reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(
      `task/resumeRequest/${taskDetailId}/${userId}`,
      reqDto,
      config
    );
  }

  getTasks(userId) {
    return http.get(`task/getTasks/${userId}`, {
      headers: authHeader(),
    });
  }

  getAssigneesOfTask(taskDetailId) {
    return http.get(`task/getAssigneesOfTask/${taskDetailId}`, {
      headers: authHeader(),
    });
  }

  openPostponedTask(taskDetailId, userId, reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.post(
      `task/openPostponedTask/${taskDetailId}/${userId}`,
      reqDto,
      config
    );
  }

  forwardApprovedTask(userId, reqDto) {
    return http.put(`task/forwardApprovedTask/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getLinkTasks(taskId, userId, requestFrom) {
    return http.get(`task/getLinkTasks/${taskId}/${userId}/${requestFrom}`, {
      headers: authHeader(),
    });
  }

  getUserProfile(userId) {
    return http.get(`person/getUserProfile/${userId}`, {
      headers: authHeader(),
    });
  }

  getBcp47CodeName() {
    return http.get(`misc/getBcp47CodeName`, {
      headers: authHeader(),
    });
  }

  addUpdateUserLanguage(userId, reqDto) {
    return http.put(`person/addUpdateUserLanguage/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  updateField(userId, reqDto) {
    return http.put(`person/updateField/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  updateUserName(userId, reqDto) {
    return http.put(`person/updateUserName/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  changePassword(reqDto) {
    return http.put(`userLogin/changePassword`, reqDto, {
      headers: authHeader(),
    });
  }

  updateProfileImage(userId, orgId, reqDto) {
    return http.put(`person/updateProfileImage/${userId}/${orgId}`, reqDto, {
      headers: authHeader(),
    });
  }

  checkImage(userId, orgId, reqDto) {
    return http.post(
      `/faceRegistration/checkImage/${orgId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  checkCollectionForImage(userId, orgId, reqDto) {
    return http.post(
      `/faceRegistration/checkCollectionForImage/${orgId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  addFaceToCollection(userId, orgId, reqDto) {
    return http.post(
      `/faceRegistration/addFaceToCollection/${orgId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  loginUsingFace(subdomain, reqDto) {
    return http.post(`/faceRegistration/loginUsingFace/${subdomain}`, reqDto, {
      headers: authHeader(),
    });
  }

  getFaceId(userId) {
    return http.get(`/faceRegistration/getFaceId/${userId}`, {
      headers: authHeader(),
    });
  }

  deleteFace(orgId, userId) {
    return http.put(`/faceRegistration/deleteFace/${orgId}/${userId}`, {
      headers: authHeader(),
    });
  }

  getFaceLivenessSession(userId) {
    return http.get(`/faceRegistration/getFaceLivenessSession/${userId}`, {
      headers: authHeader(),
    });
  }

  getFaceLivenessSessionResult(sessionId) {
    return http.get(
      `/faceRegistration/getFaceLivenessSessionResult/${sessionId}`,
      {
        headers: authHeader(),
      }
    );
  }
  getContacts(userId) {
    return http.get(`contacts/getContacts/${userId}`, {
      headers: authHeader(),
    });
  }

  addContacts(reqDto, userId) {
    return http.post(`contacts/addContacts/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }
  deleteContacts(userId, id) {
    return http.put(`contacts/deleteContact/${userId}/${id}`, {
      headers: authHeader(),
    });
  }
  sendInvite(reqDto, userId) {
    return http.put(`contacts/sendInvite/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }
  getUserProfileImageLink(userId, orgId) {
    return http.get(`misc/getUserProfileImageLink/${userId}/${orgId}`, {
      headers: authHeader(),
    });
  }
  getContactDetailsByReferralLink(link) {
    return http.get(`contacts/getContactDetailsByReferralLink/${link}`, {
      headers: authHeader(),
    });
  }
  registerWithLink(linkId) {
    return http.put(`contacts/registerWithLink/${linkId}`, {
      headers: authHeader(),
    });
  }
  getEventDetailsByMeetingId(meetingId) {
    return http.get(`meeting/getEventDetailsByMeetingId/${meetingId}`);
  }

  updateSignature(userId, orgId, reqDto) {
    return http.put(`person/updateSignature/${userId}/${orgId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getNextMeetingAndPendingTasks(userId) {
    return http.get(`userLogin/getNextMeetingAndPendingTasks/${userId}`, {
      headers: authHeader(),
    });
  }

  zhPatientVisit(visitData) {
    return http.post(`zhPatientVisit/getVisitInfoBeforeConsult`, visitData, {
      headers: authHeader(),
    });
  }

  getGenericswithRanking(doctorId) {
    return http.get(`zoyelHealthapi/getGenericswithRanking/${doctorId}`, {
      headers: authHeader(),
    });
  }

  getGenerics(reqDto) {
    return http.post(`zoyelHealthapi/getGenerics`, reqDto, {
      headers: authHeader(),
    });
  }

  getSymptoms(reqDto) {
    return http.post(`zoyelHealthapi/getSymptoms`, reqDto, {
      headers: authHeader(),
    });
  }

  getSymptomSince(reqDto) {
    return http.post(`zoyelHealthapi/getSymptomSince`, reqDto, {
      headers: authHeader(),
    });
  }

  getSymptomSeverity(reqDto) {
    return http.post(`zoyelHealthapi/getSymptomSeverity`, reqDto, {
      headers: authHeader(),
    });
  }

  getDiseases(reqDto) {
    return http.post(`zoyelHealthapi/getDiseases`, reqDto, {
      headers: authHeader(),
    });
  }

  getDiets(reqDto) {
    return http.post(`zoyelHealthapi/getDiets`, reqDto, {
      headers: authHeader(),
    });
  }

  getLifeStyles(reqDto) {
    return http.post(`zoyelHealthapi/getLifeStyles`, reqDto, {
      headers: authHeader(),
    });
  }

  getFoodAllergies(reqDto) {
    return http.post(`zoyelHealthapi/getFoodAllergies`, reqDto, {
      headers: authHeader(),
    });
  }
  getMedicalTestList(reqDto) {
    return http.post(`zoyelHealthapi/getMedicalTestList`, reqDto, {
      headers: authHeader(),
    });
  }

  getRiskFactors(reqDto) {
    return http.post(`zoyelHealthapi/getRiskFactors`, reqDto, {
      headers: authHeader(),
    });
  }

  getHereditaryDiseases(reqDto) {
    return http.post(`zoyelHealthapi/getHereditaryDiseases`, reqDto, {
      headers: authHeader(),
    });
  }

  getDurations(reqDto) {
    return http.post(`zoyelHealthapi/getDurations`, reqDto, {
      headers: authHeader(),
    });
  }

  getFrequencies(reqDto) {
    return http.post(`zoyelHealthapi/getFrequencies`, reqDto, {
      headers: authHeader(),
    });
  }

  getBrands(reqDto) {
    return http.post(`zoyelHealthapi/getBrands`, reqDto, {
      headers: authHeader(),
    });
  }

  getProcedures(reqDto) {
    return http.post(`zoyelHealthapi/getProcedures`, reqDto, {
      headers: authHeader(),
    });
  }

  getRoutes(reqDto) {
    return http.post(`zoyelHealthapi/getRoutes`, reqDto, {
      headers: authHeader(),
    });
  }

  getFormDosages(reqDto) {
    return http.post(`zoyelHealthapi/getFormDosages`, reqDto, {
      headers: authHeader(),
    });
  }

  getVitalUnits(reqDto) {
    return http.post(`zoyelHealthapi/getVitalUnits`, reqDto, {
      headers: authHeader(),
    });
  }

  getSigns(reqDto) {
    return http.post(`zoyelHealthapi/getSigns`, reqDto, {
      headers: authHeader(),
    });
  }

  submitPrescription(reqDto) {
    return http.post(`zhRx/submitPrescription`, reqDto, {
      headers: authHeader(),
    });
  }
}

export default new DashboardServices();
