import React from "react";

export default function Tablets() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.28327 6.64307L6.61229 6.61204L6.64388 6.28308C6.79799 4.67865 7.54226 3.18856 8.73244 2.10162C9.92247 1.01481 11.4736 0.408449 13.0852 0.400014C14.7546 0.396497 16.3612 1.03616 17.5714 2.18618C18.7818 3.33633 19.5025 4.90853 19.5839 6.5762C19.6653 8.24387 19.1012 9.8788 18.0086 11.1414C16.9161 12.404 15.3791 13.1971 13.7171 13.3561L13.388 13.3876L13.3569 13.7167C13.2413 14.9435 12.7789 16.112 12.0238 17.0858C11.2688 18.0596 10.2522 18.7984 9.09287 19.2159C7.93355 19.6334 6.67936 19.7124 5.47681 19.4437C4.27426 19.175 3.173 18.5696 2.3017 17.6983C1.43039 16.827 0.825017 15.7257 0.556287 14.5232C0.287558 13.3206 0.366574 12.0664 0.784104 10.9071C1.20163 9.74782 1.94044 8.73125 2.91421 7.97619C3.88797 7.22113 5.0565 6.75875 6.28327 6.64307ZM6.89403 19.28H6.89439C8.32654 19.2815 9.71453 18.7843 10.8199 17.8737L11.1602 17.5934L10.8483 17.2819L2.71208 9.15695L2.39937 8.84466L2.11971 9.18687C1.38008 10.0919 0.91221 11.1881 0.770363 12.3483C0.628516 13.5085 0.818509 14.6851 1.3183 15.7417C1.8181 16.7983 2.60721 17.6915 3.5941 18.3178C4.58099 18.944 5.72521 19.2777 6.89403 19.28ZM11.0774 17.0588L11.3891 17.3706L11.6691 17.0301C12.6444 15.8442 13.1434 14.3378 13.0689 12.8042C12.9944 11.2706 12.3517 9.81968 11.266 8.73399C10.1803 7.6483 8.72938 7.00561 7.19579 6.93109C5.6622 6.85656 4.15578 7.35556 2.96994 8.33087L2.62945 8.61091L2.94119 8.92264L11.0774 17.0588ZM13.3672 12.6499L13.3946 13.07L13.8126 13.0213C14.965 12.8871 16.0562 12.4307 16.9611 11.7046C17.866 10.9785 18.5478 10.0121 18.9285 8.91611C19.3091 7.82016 19.3731 6.6391 19.1132 5.50843C18.8532 4.37776 18.2798 3.34326 17.4587 2.52361C16.6376 1.70396 15.6021 1.13236 14.471 0.874373C13.3399 0.616388 12.1589 0.68247 11.0637 1.06504C9.96838 1.44761 9.00311 2.13116 8.2786 3.0373C7.55408 3.94344 7.09966 5.03545 6.96746 6.18807L6.9195 6.60622L7.33956 6.63284C8.9039 6.73198 10.3786 7.39745 11.4879 8.50486C12.5973 9.61226 13.2653 11.0858 13.3672 12.6499Z"
          stroke="black"
          stroke-width="0.8"
        />
      </svg>
    </>
  );
}
