import React from "react";
import "../css/landingPage.css";
import "../css/custom_landing_page.css";
import NavBarLP from "./component/NavBarLP";
import { useState } from "react";
import LoginRegister from "./component/LoginRegister";
import FooterLP from "./component/FooterLP";
import HomePage from "./component/HomePage";
import { useRef } from "react";
import PageNotFound from "./component/PageNotFound";

export default function LandingPage() {
  const [showLogReg, setShowLogReg] = useState(false);

  const handelCloseLogRegContain = () => {
    setShowLogReg(false);
  };

  const handelOpenLogRegContain = () => {
    setShowLogReg(true);
  };

  const scrollableNavRef = useRef(null);

  return (
    <>
      <div className="lp-full-container" ref={scrollableNavRef}>
        <NavBarLP
          handelOpenLogRegContain={handelOpenLogRegContain}
          scrollableNavRef={scrollableNavRef}
        />
        {showLogReg && (
          <LoginRegister handelCloseLogRegContain={handelCloseLogRegContain} />
        )}
        <HomePage />
        {/* <PageNotFound /> */}
        <FooterLP />
      </div>
    </>
  );
}
