import React, { useState } from "react";
import CreatePmsRequestForm from "./Forms/CreatePmsRequestForm";
import GoodsExpenditureApproval from "./Forms/GoodsExpenditureApproval";

export default function RightFlotingForm({
  activity,
  handleClose,
  updateFormData,
  contract,
  taskFormData,
  handleSubmitPurchaseForm,
  handleSubmitPmsRequest,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const fullScreenStyle = {
    width: "100%",
  };

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={isExpanded ? fullScreenStyle : {}}
      >
        <div className="flottingContainer">
          {activity.formId === "TASK_FORM_0000000004" && (
            <GoodsExpenditureApproval
              handleClose={handleClose}
              updateFormData={updateFormData}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              contract={contract}
              taskFormData={taskFormData}
              handleSubmitPurchaseForm={handleSubmitPurchaseForm}
            />
          )}

          {activity.formId === "TASK_FORM_0000000016" && (
            <CreatePmsRequestForm
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              handleClose={handleClose}
              handleSubmit={handleSubmitPmsRequest}
            />
          )}
        </div>
      </div>
    </>
  );
}
