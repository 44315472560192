import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import isAssignedByMe from "../../../taskUtil";
import DashboardServices from "../../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { toast } from "react-toastify";

function RightSideApproveModal({ selectedTasks, handleClose, refreshData }) {
  const [approvalData, setApprovalData] = useState();
  const loginUserDetail = getLoginUserDetails();
  const [shouldForward, setShouldForward] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    setApprovalData(
      selectedTasks.map((task) => ({
        forwardTaskUser: "",
        approvalRemark: null,
      }))
    );
    setShouldForward(selectedTasks.map((item) => false));
    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );
  }, []);

  // const getFilteredData = (index) => {
  //   const taskCreator = data.assignByUserId;

  //   const filteredData = userList.filter((item) => {
  //     return (
  //       item.userId !== loginUserDetail.userId || item.userId !== taskCreator
  //     );
  //   });
  //   return filteredData;
  // };

  const approveTask = async () => {
    const res = await Promise.all(
      selectedTasks.map((task, index) => {
        return new Promise((resolve, reject) => {
          const reqDto = {
            taskDetailId: task.taskId,
            taskForwordToUserIdAfterApproval:
              approvalData[index].forwardTaskUser.userId,
            approvalRmks: approvalData[index].approvalRemark,
          };

          DashboardServices.approveTask(loginUserDetail.userId, reqDto).then(
            (response) => {
              if (response.data && response.data.returnValue === "0") {
                resolve(null);
                toast.error(`Could not raise concern for ${task.taskName}`);
              } else {
                resolve("ok");
              }
            }
          );
        });
      })
    );
    handleClose();
    refreshData();
  };

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskTitleContrct">
              <div className="taskTitleNM">{`Approve ${selectedTasks.length} Tasks `}</div>
            </div>
            <div className="taskControlBtnGrp"></div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn closeBtnD10">
                <IconButton aria-label="close" onClick={handleClose}>
                  <Tooltip title="Close" arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>

          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {selectedTasks &&
                  selectedTasks.length > 0 &&
                  selectedTasks.map((task, index) => {
                    return (
                      <>
                        <div className="multipleUpdateTaskCard">
                          <div className="taskTitleContrct">
                            <div className="taskHeadingNM">{task.taskName}</div>
                          </div>
                          <div className="commonUpdate">
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  // sx={{ color: "white" }}
                                  label="Approval Reason*"
                                  variant="outlined"
                                  className="descptionTextFild"
                                  multiline
                                  rows={3}
                                  maxRows={7}
                                  value={
                                    approvalData &&
                                    approvalData.length > 0 &&
                                    approvalData[index].approvalRemark
                                  }
                                  // value={reason}
                                  onChange={(e) => {
                                    const temp = [...approvalData];
                                    temp[index].approvalRemark = e.target.value;
                                    setApprovalData(temp);
                                  }}
                                />
                              </FormControl>
                            </div>

                            <div className="modInputFild">
                              <FormGroup>
                                <FormControlLabel
                                  // style={{ color: "white" }}
                                  control={
                                    <Checkbox
                                      value={shouldForward[index]}
                                      onChange={(e) => {
                                        console.log(
                                          "changed value = ",
                                          shouldForward
                                        );
                                        const temp = [...shouldForward];
                                        temp[index] = e.target.checked;
                                        setShouldForward(temp);
                                      }}
                                    />
                                  }
                                  label="Forward Task After Approval?"
                                />
                              </FormGroup>
                            </div>

                            {shouldForward[index] && (
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <Autocomplete
                                    id="adduser-select"
                                    disableClearable
                                    className="formAutocompleteField"
                                    variant="outlined"
                                    // options={getFilteredData(index)}
                                    options={userList}
                                    getOptionLabel={(option) => option.userName}
                                    getOptionSelected={(option) => {
                                      console.log("option is ", option);
                                      return option.userId;
                                    }}
                                    onChange={(e, newValue) => {
                                      console.log("new value = ", newValue);
                                      const temp = [approvalData];
                                      temp[index].forwardTaskUser = newValue;
                                      setApprovalData(temp);
                                      // setForwardTaskUser(newValue);
                                    }}
                                    // renderOption={(props, option) => {
                                    //   <Box
                                    //     component="li"
                                    //     sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    //     {...props}
                                    //   >
                                    //     <img loading="lazy" src={option.profileUrl} />
                                    //     {option.userName}
                                    //   </Box>;
                                    // }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="formAutoComInputField autocompFildPlaceholder"
                                        placeholder="Search User"
                                        InputProps={{
                                          ...params.InputProps,
                                          type: "search",
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>
                            )}

                            {/* <div className="taskElementGrp">
                                <div className="tskElemHeding">Created By</div>
                                <div className="tskElemntInfo">
                                  {task && isAssignedByMe(task.createdByUserId)
                                    ? "Me"
                                    : task.createdByUserName}
                                </div>
                              </div> */}
                            <div
                              className="tskCretdByElmnt"
                              style={{ color: "#fff" }}
                            >
                              <span className="assuTag">Created By :</span>
                              <span className="assuName">
                                {task && isAssignedByMe(task.createdByUserId)
                                  ? "Me"
                                  : task.createdByUserName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={(e) => {
                  approveTask();
                  // handleClose();
                }}
              >
                Approve
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={(e) => handleClose()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RightSideApproveModal;
