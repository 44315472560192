import { AlarmOutlined, Call } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  getMyEventsInDateRanges,
  getNextEvent,
} from "../services/SchedulerService";
import { getLoginUserDetails } from "../utils/Utils";
import DateUtils from "../utils/DateUtils";
import { AddTabContext } from "../features/dashboard/view/DashboardPage";
import { v4 } from "uuid";
import { ConfirmProvider } from "material-ui-confirm";
import MeetingsTabContainer from "../features/scheduler/view/component/meetings/view/MeetingsTabContainer";
import "./css/callInvite.css";
import useSound from "use-sound";
// Your sound file path here
// import RecordSound from "./assets/media/rec_start.mp3";
export default function CallInvite({
  viewEvent,
  callInviteData,
  setCallInviteData,
  mySound,
}) {
  const { addTab } = useContext(AddTabContext);
  const [playSound, { stop }] = useSound(mySound);
  const [eventDto, setEventDto] = useState(null);
  const [play, setPlay] = useState(false);
  useEffect(() => {
    const loginUser = getLoginUserDetails();
    const processData = () => {
      console.log("callInviteData", callInviteData);
      setPlay(true);
      // setTimeout(() => {
      //   playSound();
      // }, 5000);
    };

    processData();

    return () => {
      stop();
    };
  }, [callInviteData]);

  useEffect(() => {
    if (play) {
      console.log("play sound");
      playSound();
    }
  }, [play]);

  return (
    <>
      {null !== callInviteData && (
        <div className="eventAlertWrapper">
          <div className="evnAltIcon">
            <Call />
          </div>
          <div className="evnDtlWTime">
            <span className="evdH">&nbsp;&nbsp;{callInviteData.message} </span>
            {/* <span className="evdT">{eventAlertData.content}, </span> */}
            {/* <span className="evdTime">{eventAlertData.timing}</span> */}
          </div>
          <div className="eveAltBtn joinCallBtn">
            <Button
              variant="outlined"
              onClick={() => {
                stop();
                setCallInviteData(null);
                const key = v4();
                addTab({
                  key: key,
                  title: "Instant Meeting",
                  content: (
                    <ConfirmProvider>
                      <MeetingsTabContainer
                        startInstantMeeting={false}
                        joinRoomId={callInviteData.meetingLink}
                        tabKey={key}
                      />
                    </ConfirmProvider>
                  ),
                  isDraggable: true,
                  type: "Meeting",
                });
              }}
            >
              Join
            </Button>
          </div>
          <div className="eveAltBtn dismissCallBtn">
            <Button
              variant="outlined"
              onClick={() => {
                setCallInviteData(null);
                stop();
              }}
            >
              Dismiss
            </Button>
          </div>
          {/* <div className="eveAltBtn">
            <Button variant="outlined" onClick={() => setEventAlertData(null)}>
              Snooze
            </Button>
          </div> */}
        </div>
      )}
    </>
  );
}
