import {
  ArrowBackIosNew,
  ArrowForwardIos,
  DescriptionOutlined,
  Download,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Plus } from "iconoir-react";
import React, { useContext, useRef, useState } from "react";
import DocumentTab from "../../../../common/DocumentTab";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { AddTabContext } from "../../../dashboard/view/DashboardPage";
import DocumentEditor from "../../../../utils/DocumentEditor";
import {
  apiEndPoint,
  collaboraDomain,
} from "../../../../constants/url_provider";
import { downloadFileFromUrl } from "../../../../utils/FileUtils";

const FileList = ({
  data,
  handleChangeFile,
  setShowFileUploadModal,
  allowAddFile = true,
}) => {
  const [filearrowDisable, setFilearrowDisable] = useState(true);
  const fileElementRef = useRef(null);
  const fileInputRef = useRef(null);
  const { addTab } = useContext(AddTabContext);

  const [fileDivWidth, setFileDivWidth] = useState(0);
  const [fileDownloadingFlag, setFileDownloadingFlag] = useState(null);

  useEffect(() => {
    console.log("Data =", data);
    const array = new Array(data.length).fill(false);
    setFileDownloadingFlag(array);
  }, []);

  useEffect(() => {
    if (fileElementRef.current) {
      setFileDivWidth(fileElementRef.current.offsetWidth);
    }
  }, [fileElementRef]);

  const handleAddnewFile = () => {
    // fileInputRef.current.click();
    setShowFileUploadModal(true);
  };

  const filehandleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setFilearrowDisable(true);
      } else {
        setFilearrowDisable(false);
      }
    }, speed);
  };

  const openFileInTab = (template) => {
    addTab({
      key: uuidv4(),
      title: `File_${template.fileDisplayName}`,
      content: <DocumentTab file={template} />,
      // content:(
      //   <DocumentEditor
      //     officeUrl={`${collaboraDomain}/browser/abd9205/cool.html?WOPISrc=${apiEndPoint}/wopi/files/${selectedFileToOpen.fileId}`}
      //     fileId={selectedFileToOpen.fileId}
      //     userId={userDetails.userId}
      //     sharedBy={selectedFileToOpen.sharedBy}
      //     fullScreen={selectedFileToOpen.fullScreen}
      //     fileName={selectedFileToOpen.name}
      //   />
      // ),

      isDraggable: true,
      // type: "File Viewer",
    });
  };

  return (
    <>
      <div className="taskElementGrp">
        <div className="tskElemHeding">Files</div>

        <div class="elementFileListedGrp">
          <div className="horaizonScroll">
            {allowAddFile && (
              <IconButton
                className="goPrevousBtn"
                onClick={() => {
                  filehandleHorizantalScroll(
                    fileElementRef.current,
                    25,
                    200,
                    -10
                  );
                }}
                disabled={filearrowDisable}
              >
                <ArrowBackIosNew />
              </IconButton>
            )}

            <div class="elemntFilsgrp" ref={fileElementRef}>
              {data &&
                data.documents &&
                data.documents.map((doc) => (
                  <div
                    className="elemntFile"
                    onClick={(e) => openFileInTab(doc)}
                  >
                    <Tooltip
                      title={doc.fileDisplayName}
                      arrow
                      className="elmFileTolTip"
                    >
                      <span className="elemntFilIoc">
                        <DescriptionOutlined />
                      </span>
                      <span className="elemntFilNm">{doc.fileDisplayName}</span>
                      <span
                        className="elemntFilIoc"
                        onClick={async (e) => {
                          e.stopPropagation();

                          await downloadFileFromUrl(
                            doc.fileDataLink,
                            doc.fileDisplayName
                          );
                        }}
                      >
                        <Download />
                      </span>
                    </Tooltip>
                  </div>
                ))}
            </div>
            {fileDivWidth > 370 && (
              <IconButton
                className="goNextBtn"
                onClick={() => {
                  filehandleHorizantalScroll(
                    fileElementRef.current,
                    25,
                    200,
                    10
                  );
                }}
              >
                <ArrowForwardIos />
              </IconButton>
            )}
          </div>
          <div class="elmntAddMrFiles">
            <Button
              className="elemntMrFileBtn"
              variant="outlined"
              onClick={handleAddnewFile}
            >
              <Plus />
            </Button>
            <input
              multiple
              type="file"
              ref={fileInputRef}
              onChange={handleChangeFile}
              style={{ display: "none" }}
            ></input>
          </div>
        </div>
      </div>
      {/* {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={(e) => setShowFileUploadModal(false)}
          />
        </div>
      )} */}
    </>
  );
};

export default FileList;
