import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { NumericFormatCustom } from "./NumericFormatCustom";
import { AttachFile } from "@mui/icons-material";
import DateUtils from "../../../../../utils/DateUtils";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import { Trash } from "react-bootstrap-icons";

export const DataIncomingForm = ({ state, dispatch, budgetHeadList }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [transportCost, setTransportCost] = useState(0);
  const [mealCost, setMealCost] = useState(0);
  const [accomodationCost, setAccomodationCost] = useState(0);
  const [otherExpenses, setOtherExpenses] = useState(0);
  const [advanceReceived, setadvanceReceived] = useState(0);
  const [documents, setDocuments] = useState([]);
  const docRef = useRef();
  const [budgetHead, setBudgetHead] = useState();
  const [claimOnActualBasis, setClaimOnActualBasis] = useState(true);
  const [claimOnActualRate, setClaimOnActualRate] = useState(false);

  const getSelectedBudgetHeadObj = (budgetHeadId) => {
    const budgetHeadObj = budgetHeadList.find(
      (item) => item.id === budgetHeadId
    );
    return budgetHeadObj;
  };

  useEffect(() => {
    console.log("da_ta data");
    setStartDate(
      DateUtils.convertToDate(state.formDataCopy.daTaTravelDateStart)
    );
    setEndDate(DateUtils.convertToDate(state.formDataCopy.daTaTravelDateEnd));
    setTransportCost(state.formDataCopy.daTaTransportCosts);
    setMealCost(state.formDataCopy.daTaMealCosts);
    setAccomodationCost(state.formDataCopy.daTaAccommodationCosts);
    setOtherExpenses(state.formDataCopy.daTaOtherExpensesAmt);
    setadvanceReceived(state.formDataCopy.daTaAdvanceReceived);
    setClaimOnActualBasis(
      state.formDataCopy.claimOnActalBasis === "Y" ? true : false
    );
    setClaimOnActualRate(
      state.formDataCopy.claimOnActalRate === "Y" ? true : false
    );
    // setBudgetHead(state.formDataCopy.budgetHeadId);
  }, []);

  const calculateTotalExpenditure = () => {
    const trCost = transportCost === "" ? 0 : parseInt(transportCost);
    const accCost = accomodationCost === "" ? 0 : parseInt(accomodationCost);
    const meCost = mealCost === "" ? 0 : parseInt(mealCost);
    const othrExpense = otherExpenses === "" ? 0 : parseInt(otherExpenses);
    const totalExpenditure = trCost + accCost + meCost + othrExpense;
    return totalExpenditure;
  };

  const totalExpenditure = useMemo(() => {
    const totalExpenditure = calculateTotalExpenditure();
    const temp = { ...state.formDataCopy };
    temp.daTaTotalCost = totalExpenditure;
    dispatch({ type: "dataFormUpdate", payload: temp });
    return totalExpenditure;
  }, [transportCost, accomodationCost, mealCost, otherExpenses]);

  const getFileDataObj = async (file) => {
    const dto = {
      fileName: file.name,
      fileDataLink: await convertFileToBase64_Split(file),
    };
    return dto;
  };

  return (
    <>
      <div className="formElementGroup">
        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="setCompletionDate" className="setCompletionDate">
              Travel Start Date
            </InputLabel>
            <ReactDatePicker
              showIcon
              labelId="setCompletionDate"
              className="formDatePicker"
              maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </FormControl>
        </div>
        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="setCompletionDate" className="setCompletionDate">
              Travel End Date
            </InputLabel>
            <ReactDatePicker
              showIcon
              labelId="setCompletionDate"
              className="formDatePicker"
              maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </FormControl>
        </div>
      </div>
      <div className="formElementGroup">
        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Transport Cost"
              name="numberformat"
              id="Transport_Cost"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              variant="outlined"
              defaultValue={0}
              className="formTextFieldArea"
              value={transportCost}
              onChange={(e) => {
                let val = e.target.value;
                const parsedVal = parseInt(val);
                if (!isNaN(parsedVal)) {
                  setTransportCost(parsedVal);
                  let temp = { ...state.formDataCopy };
                  temp.daTaTransportCosts = parsedVal;
                  dispatch({ type: "dataFormUpdate", payload: temp });
                }
              }}
            />
          </FormControl>
        </div>
        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Accomodation Cost"
              name="numberformat"
              id="Accomodation_Cost"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              defaultValue={0}
              variant="outlined"
              className="formTextFieldArea"
              value={accomodationCost}
              onChange={(e) => {
                let val = e.target.value;
                const parsedVal = parseInt(val);
                if (!isNaN(parsedVal)) {
                  setAccomodationCost(parsedVal);
                  let temp = { ...state.formDataCopy };
                  temp.daTaAccommodationCosts = parsedVal;
                  dispatch({ type: "dataFormUpdate", payload: temp });
                }
              }}
            />
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Meal Cost"
              name="numberformat"
              id="Meal_Cost"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              defaultValue={0}
              variant="outlined"
              className="formTextFieldArea"
              value={mealCost}
              onChange={(e) => {
                let val = e.target.value;
                const parsedVal = parseInt(val);
                if (!isNaN(parsedVal)) {
                  setMealCost(parsedVal);
                  let temp = { ...state.formDataCopy };
                  temp.daTaMealCosts = parsedVal;
                  dispatch({ type: "dataFormUpdate", payload: temp });
                }
              }}
            />
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Other Expenses"
              name="numberformat"
              id="Other_Expenses"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              defaultValue={0}
              variant="outlined"
              className="formTextFieldArea"
              value={otherExpenses}
              onChange={(e) => {
                let val = e.target.value;
                const parsedVal = parseInt(val);
                if (!isNaN(parsedVal)) {
                  setOtherExpenses(parsedVal);
                  let temp = { ...state.formDataCopy };
                  temp.daTaOtherExpensesAmt = parsedVal;
                  dispatch({ type: "dataFormUpdate", payload: temp });
                }
              }}
            />
          </FormControl>
        </div>
      </div>
      <div className="formElementGroup">
        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Total Expenses"
              name="numberformat"
              id="total_Expenses"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              variant="outlined"
              className="formTextFieldArea"
              value={totalExpenditure}
            />
          </FormControl>
        </div>
        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Advance Received"
              name="numberformat"
              id="total_Expenses"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              defaultValue={0}
              variant="outlined"
              className="formTextFieldArea"
              value={advanceReceived}
              onChange={(e) => {
                let val = e.target.value;
                const parsedVal = parseInt(val);
                if (!isNaN(parsedVal)) {
                  setadvanceReceived(parsedVal);
                  let temp = { ...state.formDataCopy };
                  temp.daTaAdvanceReceived = parsedVal;
                  dispatch({ type: "dataFormUpdate", payload: temp });
                }
              }}
            />
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="selectLedgerHead">Budget Head</InputLabel>
            <Select
              className="formInputField"
              variant="outlined"
              labelId="selectLedgerHead"
              id="select-LedgerHead"
              label="Budget Head"
              value={state.formDataCopy.budgetHeadId}
              onChange={(e) => {
                let temp = { ...state.formDataCopy };
                const val = getSelectedBudgetHeadObj(e.target.value);
                temp.budgetHeadId = val.id;
                temp.budgetHeadName = val.head;
                dispatch({ type: "dataFormUpdate", payload: temp });
              }}
            >
              {budgetHeadList.length > 0 &&
                budgetHeadList.map((itemName, index) => (
                  <MenuItem
                    value={itemName.id}
                    key={itemName.head + "_" + index}
                  >
                    {itemName.head}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="formElementGroup">
        <div className="formElement">
          <FormControl className="formControl">
            <FormGroup className="formRadioGroup">
              <FormControlLabel
                className="formRadioField"
                control={
                  <Checkbox
                    checked={claimOnActualBasis}
                    onChange={(e) => {
                      let val = e.target.checked;
                      const parsedVal = val;
                      setClaimOnActualBasis(parsedVal);
                      let temp = { ...state.formDataCopy };
                      temp.claimOnActalBasis = parsedVal ? "Y" : "N";
                      dispatch({ type: "dataFormUpdate", payload: temp });
                    }}
                  />
                }
                label="Claimed On Actual Basis"
              />
            </FormGroup>
          </FormControl>
        </div>
        <div className="formElement">
          <FormControl className="formControl">
            <FormGroup className="formRadioGroup">
              <FormControlLabel
                className="formRadioField"
                control={
                  <Checkbox
                    checked={claimOnActualRate}
                    onChange={(e) => {
                      let val = e.target.checked;
                      const parsedVal = val;
                      setClaimOnActualRate(parsedVal);
                      let temp = { ...state.formDataCopy };
                      temp.claimOnActalBasis = parsedVal ? "Y" : "N";
                      dispatch({ type: "dataFormUpdate", payload: temp });
                    }}
                  />
                }
                label="Claimed On Actual Rate"
              />
            </FormGroup>
          </FormControl>
        </div>
      </div>
      <div className="formElementGroup">
        {/* <div className={`formBtnElement`}>
          <Button
            variant="outlined"
            startIcon={<AttachFile />}
            className={`comBtnOutline  ${
              documents && documents.length > 0 ? "activeStatus" : ""
            }`}
            onClick={(e) => {
              docRef.current.click();
            }}
          >
            Attach File
          </Button>
          <input
            ref={docRef}
            multiple
            style={{ display: "none" }}
            type="file"
            onChange={async (e) => {
              const files = e.target.files;
              console.log("files are", files);
              const results = [];

              for (const file of files) {
                try {
                  const processedData = await getFileDataObj(file);
                  results.push(processedData);
                } catch (error) {
                  console.error("Error processing file:", error);
                  results.push(null);
                }
              }

              console.log("processed files are", results);
              setDocuments(results);
            }}
          />
        </div> */}
        <div className="singleUpldfile">
          <span className="rolsNm">Treasurer</span>
          <IconButton className="rmvBtn">
            <Trash />
          </IconButton>
        </div>
      </div>
    </>
  );
};
