import React, { useRef, useState } from "react";
import {
  Add,
  ArrowBack,
  ContentCopy,
  Edit,
  Search,
  Visibility,
} from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Modal } from "@mui/material";
import { Trash } from "react-bootstrap-icons";
import {
  getExternalUsersOfOrg,
  deActivateExternalUser,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { toast } from "react-toastify";

function Message({ text }) {
  return (
    <div
      style={{
        width: "max-content",
        position: "absolute",
        top: "0px",
        right: "40px",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        color: "#fff",
        padding: "10px",
        borderRadius: "5px",
        zIndex: "999",
      }}
    >
      {text}
    </div>
  );
}

export default function ViewExternalUser({
  handelCloseViewExternalUser,
  handelOpenAddExternalUser,
  refreshExternalUser,
  handelOpenEditExternaluser,
  closeAll
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));

  const [externalUserData, setExternalUserData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredExternalUserData, setFilteredExternalUserData] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [externalUserToDelete, setExternalUserToDelete] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchExternalUserData();
  }, [refreshExternalUser]);

  const fetchExternalUserData = () => {
    getExternalUsersOfOrg(userDetails.orgId)
      .then((response) => {
        console.log("getExternalUsersOfOrg", response.data);
        setExternalUserData(response.data);
        setFilteredExternalUserData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  const mailRef = useRef(null);
  const phoneRef = useRef(null);
  // const [mailMessage, setMailMessage] = useState("");
  // const [phoneMessage, setPhoneMessage] = useState("");
  const [mailMessage, setMailMessage] = useState(
    Array(externalUserData.length).fill("")
  );
  const [phoneMessages, setPhoneMessages] = useState(
    Array(externalUserData.length).fill("")
  );

  // const mailTextCopy = (value) => {
  //   if (value) {
  //     const tempInput = document.createElement("input");
  //     tempInput.value = value;
  //     document.body.appendChild(tempInput);
  //     tempInput.select();
  //     document.execCommand("copy");
  //     document.body.removeChild(tempInput);
  //     setMailMessage("Mail copied!");
  //     setTimeout(() => setMailMessage(""), 1500);
  //   }
  // };

  const mailTextCopy = (value, index) => {
    if (value) {
      const tempInput = document.createElement("input");
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);

      const updatedEmail = [...mailMessage];
      updatedEmail[index] = "Mail copied!";
      setMailMessage(updatedEmail);

      setTimeout(() => {
        const resetEmail = [...mailMessage];
        resetEmail[index] = "";
        setMailMessage(resetEmail);
      }, 1500);
    }
  };

  // const phoneTextCopy = (value) => {
  //   if (value) {
  //     const tempInput = document.createElement("input");
  //     tempInput.value = value;
  //     document.body.appendChild(tempInput);
  //     tempInput.select();
  //     document.execCommand("copy");
  //     document.body.removeChild(tempInput);
  //     setPhoneMessage("Phone copied!");
  //     setTimeout(() => setPhoneMessage(""), 1500);
  //   }
  // };

  const phoneTextCopy = (value, index) => {
    if (value) {
      const tempInput = document.createElement("input");
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);

      const updatedPhoneMessages = [...phoneMessages];
      updatedPhoneMessages[index] = "Phone copied!";
      setPhoneMessages(updatedPhoneMessages);

      setTimeout(() => {
        const resetPhoneMessages = [...phoneMessages];
        resetPhoneMessages[index] = "";
        setPhoneMessages(resetPhoneMessages);
      }, 1500);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = externalUserData.filter(
      (externalUser) =>
        externalUser.userName &&
        externalUser.userName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredExternalUserData(filteredData);
  };

  //   const handleDelete = (userId) =>{
  //  console.log('handleDelete', userId);
  //  deActivateExternalUser( userId, userDetails.userId)
  //  .then((response)=>{
  //   if (response.data.returnValue === "1") {
  //     toast.success(response.data.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     // fetchData();
  //   } else {
  //     toast.error(response.data.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  //  })
  //   };

  const handleDelete = (userId) => {
    console.log("handleDelete", userId);
    setExternalUserToDelete(userId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    if (externalUserToDelete) {
      deActivateExternalUser(externalUserToDelete, userDetails.userId).then(
        (response) => {
          console.log("data deleted", response.data);
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchExternalUserData();
            setOpenModal(false);
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton onClick={() => {handelCloseViewExternalUser(); closeAll();}}>
            <ArrowBack />
          </IconButton>
          External User
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder="Search User"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddExternalUser()}
                >
                  External User
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : externalUserData.length === 0 ? (
                <div class="noDataCard ">
                  <span>Oops!!</span>No External User Define yet !!
                  <br />
                  <span className="sallMsg">
                    Click Add External User Button to create new External User
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th className="width100">Sl. No.</th>
                        <th>User Name</th>
                        <th>Type</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>FAX</th>
                        <th>GST</th>
                        <th className="width100"></th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                      {filteredExternalUserData.map((userData, index) => (
                        <tr key={userData.userId}>
                          <td>
                            <div className="tskLstTaskNM">{index + 1}.</div>
                          </td>
                          <td>
                            {/* <div className="tskLstTaskNM">
                              {userData.title.charAt(0).toUpperCase()+ userData.title.slice(1)} {userData.firstName}{" "}
                              {userData.lastName}
                            </div> */}
                             <div className="tskLstTaskNM">
                              {userData.title+' '+userData.userName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {userData.category}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              <input
                                type="hidden"
                                ref={mailRef}
                                value={userData.emailId}
                              />
                              {/* <span>{userData.emailId}</span> */}
                              {userData.emailId !== "" && (
                              <span>{userData.emailId.charAt(0)+ "XXXXXXXXXX" + userData.emailId.slice(-3)}</span>
                              )}
                              {userData.emailId !== "" && (
                                <span className="copyIcon">
                                  <IconButton
                                    onClick={() =>
                                      mailTextCopy(userData.emailId, index)
                                    }
                                  >
                                    <ContentCopy />
                                  </IconButton>
                                  {mailMessage[index] && (
                                    <Message text={mailMessage[index]} />
                                  )}
                                </span>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              <input
                                type="hidden"
                                ref={phoneRef}
                                value={userData.contactNo}
                              />
                              {/* <span>{userData.contactNo}</span> */}
                              {userData.contactNo !== "" && (
                              <span>{userData.contactNo.charAt(0)+ "XXXXX" + userData.contactNo.slice(-2)}</span>
                              )}
                              {userData.contactNo !== "" && (
                                <span className="copyIcon">
                                  <IconButton
                                    onClick={() =>
                                      phoneTextCopy(userData.contactNo, index)
                                    }
                                  >
                                    <ContentCopy />
                                  </IconButton>
                                  {phoneMessages[index] && (
                                    <Message text={phoneMessages[index]} />
                                  )}
                                </span>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskDescription">
                              {userData.address}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">{userData.faxNo}</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {userData.gstinTaxId}
                            </div>
                          </td>
                          <td>
                            <div className="tblActionBtnGrp">
                              <IconButton onClick={()=>handelOpenEditExternaluser(userData)}>
                                <Edit />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDelete(userData.userId)}
                              >
                                <Trash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">1.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Mr. Sanket Santra</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Client</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <input type="hidden" ref={mailRef} value="sanketsantra1990@gmail.com" />
                          <span>sankexxxxxxxx@xxx.com</span>
                          <span className='copyIcon'>
                            <IconButton onClick={() => mailTextCopy(mailRef.current.value)}>
                              <ContentCopy />
                            </IconButton>
                            {mailMessage && <Message text={mailMessage} />}
                          </span>
                          
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <input type="hidden" ref={phoneRef} value="+917384979442" />
                          <span>+91-73xxxxxx42</span>
                          <span className='copyIcon'>
                            <IconButton onClick={() => phoneTextCopy(phoneRef.current.value)}>
                              <ContentCopy />
                            </IconButton>
                            {phoneMessage && <Message text={phoneMessage} />}
                          </span>
                          
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskDescription">12/1A/29 Bidhannagar, South Chowbagha Rd.,Kol 700039 India.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">+44-208-1234567</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">GSTIN-0009817</div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Edit />
                          </IconButton>
                          <IconButton>
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                  </table>
                </div>
              )}
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // width: "250px",
                    // height: "250px",
                    maxWidth: "600px",
                    minHeight: "150px",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 id="modal-modal-title">
                    Do you want to delete this External user?
                  </h2>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      // variant="contained"
                      // color="secondary"
                      className="dfultPrimaryBtn"
                      onClick={confirmDelete}
                    >
                      Yes
                    </Button>
                    <Button
                    //  variant="contained" 
                    className="dfultDarkSecondaryBtn"
                    onClick={handleCloseModal}>
                      No
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
