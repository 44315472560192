import { Button, IconButton } from "@mui/material";
import React from "react";
import { XLg } from "react-bootstrap-icons";
import DeductionComponent from "./forms/DeductionComponent";
import EarningComponent from "./forms/EarningComponent";
import ReimbursementsComponent from "./forms/ReimbursementsComponent";

export default function AddSalaryComponents({
  activeCreationMenu,
  setActiveCreationMenu,
}) {
  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">Add Salary Component</div>
          <IconButton className="CloseBtn" onClick={setActiveCreationMenu}>
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer payrollEditor">
          {activeCreationMenu === "_earnings_" && <EarningComponent />}
          {activeCreationMenu === "_deductions_" && <DeductionComponent />}
          {activeCreationMenu === "_reimbursements_" && (
            <ReimbursementsComponent />
          )}
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn"onClick={()=>finalSubmit()}>Save</Button> */}
            <Button className="dfultPrimaryBtn">Submit</Button>

            <Button
              className="dfultDarkSecondaryBtn"
              onClick={setActiveCreationMenu}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
