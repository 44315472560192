import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { XLg } from "react-bootstrap-icons";
import isAssignedByMe from "../../../taskUtil";
import { toast } from "react-toastify";

export const RightSideReassign = ({
  selectedTasks,
  handleClose,
  refreshData,
}) => {
  const [reassignData, setReassignData] = useState([]);
  const loginUserDetail = getLoginUserDetails();
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    setReassignData(
      selectedTasks.map((task) => ({
        remark: "",
        reassignTo: [],
      }))
    );

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );
  }, []);

  const reassignTasks = async () => {
    const res = await Promise.all(
      selectedTasks.map((task, index) => {
        return new Promise((resolve, reject) => {
          // const reqDto = {
          //   postponedReason: dele[index].reason,
          //   postponedTill: dele[index].postponeTill,
          // };

          const reqDto = {
            taskDetailId: task.taskId,
            userIds: reassignData[index].reassignTo.map((user) => user.userId),
            reassignReason: reassignData[index].remark,
          };
          DashboardServices.reassignTask(loginUserDetail.userId, reqDto).then(
            (response) => {
              if (response.data && response.data.returnValue === "1") {
                resolve("ok");
                handleClose();
              } else {
                resolve(null);
                toast.error(`Could not raise concern for ${task.taskName}`);
              }
            }
          );
        });
      })
    );
    refreshData();
  };

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskTitleContrct">
              <div className="taskTitleNM">{`Reassign ${selectedTasks.length} Tasks `}</div>
            </div>
            <div className="taskControlBtnGrp"></div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn closeBtnD10">
                <IconButton aria-label="close" onClick={handleClose}>
                  <Tooltip title="Close" arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>

          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {selectedTasks &&
                  selectedTasks.length > 0 &&
                  selectedTasks.map((task, index) => {
                    return (
                      <>
                        <div className="multipleUpdateTaskCard">
                          <div className="taskTitleContrct">
                            <div className="taskHeadingNM">{task.taskName}</div>
                          </div>
                          <div className="commonUpdate">
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  // sx={{ color: "white" }}
                                  label="Reassign Remark*"
                                  variant="outlined"
                                  className="descptionTextFild"
                                  multiline
                                  rows={3}
                                  maxRows={7}
                                  value={
                                    reassignData &&
                                    reassignData.length > 0 &&
                                    reassignData[index].remark
                                  }
                                  // value={reason}
                                  onChange={(e) => {
                                    const temp = [...reassignData];
                                    temp[index].remark = e.target.value;
                                    setReassignData(temp);
                                  }}
                                />
                              </FormControl>
                            </div>

                            <div className="formElement">
                              <FormControl className="formControl">
                                <Autocomplete
                                  multiple
                                  id="adduser-select"
                                  disableClearable
                                  className="formAutocompleteField"
                                  variant="outlined"
                                  value={
                                    reassignData &&
                                    reassignData.length > 0 &&
                                    reassignData[index].reassignTo
                                  }
                                  options={userList}
                                  getOptionLabel={(option) => option.userName}
                                  getOptionSelected={(option) => {
                                    console.log("option is ", option);
                                    return option.userId;
                                  }}
                                  onChange={(e, newValue) => {
                                    console.log("new value = ", newValue);
                                    const temp = [...reassignData];
                                    temp[index].reassignTo = newValue;
                                    setReassignData(temp);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      className="formAutoComInputField autocompFildPlaceholder"
                                      placeholder="Assign User*"
                                      InputProps={{
                                        ...params.InputProps,
                                        type: "search",
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </div>

                            {/* <div className="taskElementGrp">
                                <div className="tskElemHeding">Created By</div>
                                <div className="tskElemntInfo">
                                  {task && isAssignedByMe(task.createdByUserId)
                                    ? "Me"
                                    : task.createdByUserName}
                                </div>
                              </div> */}
                            <div
                              className="tskCretdByElmnt"
                              style={{ color: "#fff" }}
                            >
                              <span className="assuTag">Created By :</span>
                              <span className="assuName">
                                {task && isAssignedByMe(task.createdByUserId)
                                  ? "Me"
                                  : task.createdByUserName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={(e) => {
                  reassignTasks();
                }}
              >
                Reassign
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={(e) => handleClose()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
