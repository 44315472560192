import * as React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useState } from "react";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { Done } from "@mui/icons-material";
import { Eye } from "react-bootstrap-icons";

const drawerBleeding = 56;

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

// const Puller = styled("div")(({ theme }) => ({
//   width: 30,
//   height: 6,
//   backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
//   borderRadius: 3,
//   position: "absolute",
//   top: 8,
//   left: "calc(50% - 15px)",
// }));

function SwipeableEdgeDrawer({ window, filesWithStatus }) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    console.log("filesWithStatus ", filesWithStatus);
  }, [filesWithStatus]);

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <div className="swipUpDocMain">
        {filesWithStatus && filesWithStatus.length > 0 && (
          <Button
            className="viewUploadingFileBtn"
            variant="contained"
            onClick={toggleDrawer(true)}
            startIcon={<Eye />}
          >
            View uploading files
          </Button>
        )}
        <SwipeableDrawer
          className="swipUpDocContain"
          container={container}
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={true}
          ModalProps={{
            keepMounted: false,
          }}
        >
          <StyledBox id="swipUpDocContWSpace">
            {/* <Skeleton variant="rectangular" height="100%" /> */}
            <div className="upfileCuntHead">
              {`${filesWithStatus.length} items`}
            </div>
            {filesWithStatus && filesWithStatus.length > 0 && (
              <ul className="swipUpDocUL">
                {filesWithStatus.map((file) => {
                  return (
                    <li>
                      <div className="swipUpDocLI">
                        <span>{file.fileName}</span>
                        {file.status === "uploading" && (
                          <>
                            <span className="animeUp">
                              Uploading{" "}
                              <div class="animeUploading">
                                <div class="animeUpdot"></div>
                                <div class="animeUpdot"></div>
                                <div class="animeUpdot"></div>
                              </div>
                            </span>
                          </>
                        )}
                        {file.status === "success" && <Done />}
                      </div>
                    </li>
                  );
                })}
                <li>
                  <div className="swipUpDocLI">
                    <span>Test file</span>
                    <span className="animeUp">Waiting for Upload...</span>
                  </div>
                </li>
              </ul>
            )}
          </StyledBox>
        </SwipeableDrawer>
      </div>
    </>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
