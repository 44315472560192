import { CameraswitchRounded, HeadsetMicRounded } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { PersonFill, Search, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaceLivenessAwsAmplify } from "../../../../profile/view/FaceLiveness";
import { CustomModal } from "../../../../task/view/component/Modals/CustomModal";

export default function AddRegisterPatient({
  handelCloseAddRegisterPatient,
  faceId,
  setFaceId,
  setShowRegFace,
}) {
  const [patientTitle, setPatientTitle] = useState("");
  const [patientGender, setPatientGender] = useState("");
  const [patientMaritalStatus, setPatientMaritalStatus] = useState("");
  const [registerFace, setRegisterFace] = useState(false);
  const [dateofbirth, setDateofbirth] = useState(new Date());
  const handleDateOfBirth = (date) => {
    setDateofbirth(date);
  };
  const handleChangePatientTitle = (event) => {
    setPatientTitle(event.target.value);
  };
  const handleChangeMaritalStatus = (event) => {
    setPatientMaritalStatus(event.target.value);
  };

  const handleChangepatientGender = (event) => {
    setPatientGender(event.target.value);
  };
  const handelOpenRegisterFace = () => {
    setRegisterFace(true);
  };
  const handelCloseRegisterFace = () => {
    setRegisterFace(false);
  };

  const [bookingType, setBbookingType] = useState("");
  const selectBookingType = (event) => {
    const value = event.target.value;
    setBbookingType(value);
  };

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">Register Patient</div>
            <div className="rfcHActionBtnGrp">
              <Button
                startIcon={<HeadsetMicRounded />}
                className="raiseSupportBtn"
              >
                Raise support request
              </Button>
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddRegisterPatient()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="formElementGrp">
                  <div className="searchTasklist fullWidth100p">
                    <input
                      type="text"
                      class="serchTaskInput"
                      placeholder="Search by patient Id"
                    />
                    <IconButton className="seacrhIcon">
                      <Search />
                    </IconButton>
                  </div>
                  {/* <Button
                    onClick={() => handelOpenRegisterFace()}
                    startIcon={<PersonBoundingBox />}
                    className="faceRegisterBtn"
                  >
                    Face Register
                  </Button> */}
                </div>
                <div className="bb"></div>
                <div className="faceWithFormGrp">
                  <div className="lftFromGrp">
                    {/* <div class="tskElemHeding ">Patient Demography</div> */}

                    <div className="formElementGrp ">
                      <div className="formElement mxW-100">
                        <FormControl className="formControl">
                          <InputLabel id="patient-select-label">
                            Title
                          </InputLabel>
                          <Select
                            labelId="patient-select-label"
                            id="patient-select-title"
                            value={patientTitle}
                            label="Title"
                            onChange={handleChangePatientTitle}
                            className="formInputField"
                            variant="outlined"
                          >
                            <MenuItem value="Mr">Mr.</MenuItem>
                            <MenuItem value="Ms">Ms.</MenuItem>
                            <MenuItem value="Mrs">Mrs.</MenuItem>
                            <MenuItem value="Miss">Miss</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // hiddenLabel
                            label="First name"
                            required
                            autoComplete="off"
                            placeholder="First Name"
                            variant="outlined"
                            className="formTextFieldArea"
                          />
                        </FormControl>
                      </div>

                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // hiddenLabel
                            label="Middle name"
                            autoComplete="off"
                            placeholder="Middle Name"
                            variant="outlined"
                            className="formTextFieldArea"
                          />
                        </FormControl>
                      </div>

                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // hiddenLabel
                            required
                            autoComplete="off"
                            label="Last name"
                            placeholder="Last Name"
                            variant="outlined"
                            className="formTextFieldArea"
                          />
                        </FormControl>
                      </div>
                    </div>

                    <div className="formElementGrp">
                      <div className="formElement mxW-100">
                        <FormControl className="formControl">
                          <InputLabel id="demo-simple-select-label">
                            Gender*
                          </InputLabel>
                          <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={patientGender}
                            label="Gender*"
                            onChange={handleChangepatientGender}
                            className="formInputField"
                            variant="outlined"
                          >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <div className="formElement mxW174">
                        <FormControl className="formControl">
                          <InputLabel
                            id="employeeJoingDate"
                            className="setCompletionDate"
                          >
                            DOB *
                          </InputLabel>
                          <ReactDatePicker
                            toggleCalendarOnIconClick
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            showMonthDropdown
                            showIcon
                            labelId="employeeJoingDate"
                            className="formDatePicker"
                            selected={dateofbirth}
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => {
                              handleDateOfBirth(date);
                            }}
                          />
                        </FormControl>
                      </div>
                      <div className="patientAgeBreack">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              autoComplete="off"
                              label="Years"
                              variant="outlined"
                              className="formTextFieldArea"
                            />
                          </FormControl>
                        </div>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              autoComplete="off"
                              label="Months"
                              variant="outlined"
                              className="formTextFieldArea"
                            />
                          </FormControl>
                        </div>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              autoComplete="off"
                              label="Days"
                              variant="outlined"
                              className="formTextFieldArea"
                            />
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <div className="formElementGrp">
                      <div className="formElement mxW120">
                        <FormControl className="formControl">
                          <InputLabel id="demo-simple-select-label">
                            Civil status
                          </InputLabel>
                          <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={patientMaritalStatus}
                            label="Civil status"
                            onChange={handleChangeMaritalStatus}
                            className="formInputField"
                            variant="outlined"
                          >
                            <MenuItem value="single">Single</MenuItem>
                            <MenuItem value="married">Married</MenuItem>
                            <MenuItem value="separated">Separated</MenuItem>
                            <MenuItem value="divorced">Divorced</MenuItem>
                            <MenuItem value="widowed">Widowed</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="custContactNo">
                        <select
                          name="countryCode"
                          id="countryCode"
                          className="countryCode"
                        >
                          <option value="+91">+91</option>
                          <option value="+1">+1</option>
                          <option value="+635">+635</option>
                        </select>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              autoComplete="off"
                              label="Contact Number"
                              variant="outlined"
                              className="formTextFieldArea"
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // hiddenLabel
                            required
                            autoComplete="off"
                            label="Email Id"
                            variant="outlined"
                            className="formTextFieldArea"
                          />
                        </FormControl>
                      </div>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          autoComplete="off"
                          label="Full Address"
                          variant="outlined"
                          className="formTextFieldArea"
                        />
                      </FormControl>
                    </div>

                    <div className="formElement selectBookingType">
                      <FormControl className="formControl">
                        <RadioGroup
                          className="formRadioGroup"
                          labelId="setTasktype"
                          name="setTasktype"
                          value={bookingType}
                          onChange={selectBookingType}
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="bookConsultation"
                            control={<Radio />}
                            label="Proceed to book consultation"
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="bookAppointment"
                            control={<Radio />}
                            label="Proceed to book appointment"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  <div className="viewFaceRegisterGroup ">
                    <div className="faceIndview">
                      <Button
                        className="indImgButton"
                        onClick={() => handelOpenRegisterFace()}
                      >
                        <PersonFill />
                        <span>Click to Scan</span>
                      </Button>
                    </div>
                    <div className="faceIndview">
                      <div className="faceIndviewImg">
                        <img
                          src="https://img.freepik.com/premium-photo/young-brazilian-man-isolated-white-background-laughing_1368-362553.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1722556800&semt=sph"
                          alt=""
                        />
                      </div>
                      <span className="faceText">Present Image</span>
                    </div>
                    <div className="faceIndview">
                      <div className="faceIndviewImg">
                        <img
                          src="https://img.freepik.com/premium-photo/young-brazilian-man-isolated-white-background-laughing_1368-362553.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1722556800&semt=sph"
                          alt=""
                        />
                      </div>
                      <span className="faceText">Matched Image</span>
                    </div>
                    <div className="faceIndview">
                      <Button
                        onClick={() => handelOpenRegisterFace()}
                        startIcon={<CameraswitchRounded />}
                        className="recaptureBtn"
                      >
                        Recapture
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button className="dfultPrimaryBtn">Submit</Button>

              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseAddRegisterPatient()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>

      {registerFace && (
        <CustomModal
          closeModal={() => {
            handelCloseRegisterFace();
          }}
          className={"with30vw"}
        >
          <FaceLivenessAwsAmplify
            faceId={faceId}
            setFaceId={setFaceId}
            setShowRegFace={setShowRegFace}
          />
        </CustomModal>
      )}
    </>
  );
}
