const styles = [
  {
    ".editor-paragraph": ` 
    margin: 0;
    margin-bottom: 8px;
    position: relative;`,
  },
  {
    ".editor-paragraph:last-child": `margin-bottom: 0;`,
  },
  {
    ".editor-heading-h1": `
        font-size: 24px;
        color: rgb(5, 5, 5);
        font-weight: 400;
        margin: 0;
        margin-bottom: 12px;
        padding: 0;
      `,
  },
  {
    ".editor-heading-h2": `
        font-size: 15px;
        color: rgb(101, 103, 107);
        font-weight: 700;
        margin: 0;
        margin-top: 10px;
        padding: 0;
        text-transform: uppercase;
      `,
  },
  {
    ".editor-quote": `
        margin: 0;
        margin-left: 20px;
        font-size: 15px;
        color: rgb(101, 103, 107);
        border-left-color: rgb(206, 208, 212);
        border-left-width: 4px;
        border-left-style: solid;
        padding-left: 16px;
      `,
  },
  {
    ".editor-list-ol": ` 
        padding: 0;
        margin: 0;
        margin-left: 16px;
      `,
  },

  {
    ".editor-list-ul": `
    padding: 0;
    margin: 0;
    margin-left: 16px;
  `,
  },
  {
    ".editor-listitem": `
        margin: 8px 32px 8px 32px;
      `,
  },
  {
    ".editor-nested-listitem": `
        list-style-type: none;
      `,
  },
];

export const generateStyleTag = () => {
  const tempStyle = styles.map((obj) => {
    const keys = Object.keys(obj);
    const key = keys[0];
    const style = `${key}:{${obj[key]}}\n`;
    return style;
  });
  const styleTag = `<style>${tempStyle.join("\n")}</style>`;
  console.log("styleTag=",styleTag)
  return "";
};
