import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearTaskModalData } from "../../../../../redux/reducers/taskTab";
import TaskOutgoingOngoing from "../../../../task/view/component/TaskList/TaskOutgoingOngoing";
import TaskTabAddressConcern from "../../../../task/view/component/TaskList/TaskTabAddressConcern";
import TaskTabApprovalRequest from "../../../../task/view/component/TaskList/TaskTabApprovalRequest";
import TaskTabCancelApprovalRequest from "../../../../task/view/component/TaskList/TaskTabCancelApprovalRequest";
import TaskTabCancelRejected from "../../../../task/view/component/TaskList/TaskTabCancelRejected";
import TaskTabCompleted from "../../../../task/view/component/TaskList/TaskTabCompleted";
import TaskTabCompletedApprovalRequest from "../../../../task/view/component/TaskList/TaskTabCompletedApprovalRequest";
import TaskTabIncomingOngoing from "../../../../task/view/component/TaskList/TaskTabIncomingOngoing";
import TaskTabOutgoingOngoing from "../../../../task/view/component/TaskList/TaskTabOutgoingOngoing";
import TaskTabReopenPostponed from "../../../../task/view/component/TaskList/TaskTabReopenPostponed";
import TaskTabSignOffApprovalRequest from "../../../../task/view/component/TaskList/TaskTabSignOffApprovalRequest";
import TaskTabSignOffCompleted from "../../../../task/view/component/TaskList/TaskTabSignOffCompleted";
import TaskTabPmsCycleApprovalRequest from "../../../../task/view/component/TaskList/TaskTabPmsCycleApprovalRequest";
import PmsApprovalForm from "../../../../task/view/component/Forms/PmsApprovalForm";

const RightSideTaskTabList = ({ refreshData, handleClose }) => {
  const [showPmsForm, setShowPmsForm] = useState(false);
  const dispatch = useDispatch();
  const rightTaskData = useSelector(
    (state) => state.taskList.taskRightModalData
  );

  const removeFromStore = () => {
    dispatch(clearTaskModalData());
  };

  return (
    <>
      {rightTaskData && (
        <div className="RightFlotingContainerArea">
          {console.log("right side data = ", rightTaskData)}
          {rightTaskData.taskUIComponent === "TaskIncomingOngoing" && (
            <TaskOutgoingOngoing
              data={rightTaskData}
              refreshData={refreshData}
              handleClose={handleClose}
              removeFromStore={removeFromStore}
            />
          )}
          {rightTaskData.taskUIComponent === "TaskTabApprovalRequest" && (
            <TaskTabApprovalRequest
              data={rightTaskData}
              refreshData={refreshData}
              isCallingFromTab={true}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskTabCancelApprovalRequest" && (
            <TaskTabCancelApprovalRequest
              data={rightTaskData}
              refreshData={refreshData}
              isCallingFromTab={true}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent ===
            "TaskTabSignOffApprovalRequest" && (
            <TaskTabSignOffApprovalRequest
              data={rightTaskData}
              refreshData={refreshData}
              isCallingFromTab={true}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent ===
            "TaskTabPmsCycleApprovalRequest" && (
            <TaskTabPmsCycleApprovalRequest
              data={rightTaskData}
              refreshData={refreshData}
              isCallingFromTab={true}
              removeFromStore={removeFromStore}
              setShowPmsForm={setShowPmsForm}
            />
          )}
          {rightTaskData.taskUIComponent === "TaskTabIncomingOngoing" && (
            <TaskTabIncomingOngoing
              data={rightTaskData}
              refreshData={refreshData}
              isCallingFromTab={true}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskTabOutgoingOngoing" && (
            <TaskTabOutgoingOngoing
              data={rightTaskData}
              refreshData={refreshData}
              isCallingFromTab={true}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent ===
            "TaskTabCompletedApprovalRequest" && (
            <TaskTabCompletedApprovalRequest
              data={rightTaskData}
              refreshData={refreshData}
              isCallingFromTab={true}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskTabCompleted" && (
            <TaskTabCompleted
              data={rightTaskData}
              refreshData={refreshData}
              isCallingFromTab={true}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskTabSignOffCompleted" && (
            <TaskTabSignOffCompleted
              data={rightTaskData}
              refreshData={refreshData}
              isCallingFromTab={true}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskTabReopenPostponed" && (
            <TaskTabReopenPostponed
              data={rightTaskData}
              refreshData={refreshData}
              isCallingFromTab={true}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskTabCancelRejected" && (
            <TaskTabCancelRejected
              data={rightTaskData}
              refreshData={refreshData}
              isCallingFromTab={true}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskTabAddressConcern" && (
            <TaskTabAddressConcern
              data={rightTaskData}
              refreshData={refreshData}
              isCallingFromTab={true}
              removeFromStore={removeFromStore}
            />
          )}
        </div>
      )}

      {showPmsForm &&
        rightTaskData &&
        rightTaskData.taskNature === "PMS_APPROVAL_REQUEST" && (
          <PmsApprovalForm
            data={rightTaskData}
            handleClose={() => setShowPmsForm(false)}
            refreshData={() => {
              refreshData();
              // dispatch(clearSelectedTask());
            }}
          />
        )}
    </>
  );
};

export default RightSideTaskTabList;
