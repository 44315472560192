import {
  Add,
  CancelOutlined,
  CheckCircle,
  Search,
  ShortcutOutlined,
  Tune,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { createContext } from "react";
import { useEffect } from "react";
import DashboardServices from "../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../utils/Utils";
import { useState } from "react";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useContext } from "react";
import CreateTaskContainer from "./CreateTaskContainer";
import { useRef } from "react";
import { useMemo } from "react";
import DocumentTab from "../../../common/DocumentTab";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTaskModalData,
  setTaskModalData,
  setTaskTabList,
} from "../../../redux/reducers/taskTab";
import { v4 as uuidv4 } from "uuid";
import RightSideTaskTabList from "../../dashboard/view/component/right-panel/RightSideTaskTabList";
import { Diagram3 } from "react-bootstrap-icons";
import RightSideMultipleUpdate from "./component/Modals/RightSideMultipleUpdate";
import RightSideMultipleConcern from "./component/Modals/RightSideMultipleConcern";
import { RightSideCommonModal } from "./component/Modals/RightSideCommonModal";
import RightSideApproveModal from "./component/Modals/RightSideApproveModal";
import RightSideRejectRequest from "./component/Modals/RightSideRejectRequest";
import RightSideCloseTask from "./component/Modals/RightSideCloseTask";
import RightSidePostpone from "./component/Modals/RightSidePostpone";
import RightSideDelegate from "./component/Modals/RightSideDelegate";
import { RightSideReassign } from "./component/Modals/RightSideReassign";
import RightSideTaskForward from "./component/Modals/RightSideTaskForward";
import RightSideSignOff from "./component/Modals/RightSideSignOff";
import RightSideDisagree from "./component/Modals/RightSideDisagree";
import RightSideAddressConcern from "./component/Modals/RightSideAddressConcern";
import ConvenientTimeView from "../../scheduler/view/component/calendar/ConvenientTimeView";
import CreateConvenientMeetingWrapper from "./component/Modals/CreateConvenientMeetingWrapper";
import { createEvent } from "../../../services/SchedulerService";
import TaskService from "../../../services/TaskService";
import { AddTabContext } from "../../dashboard/view/DashboardPage";

export const taskTabContext = createContext();

export default function TaskTabContainer() {
  const { addTab } = useContext(AddTabContext);
  const loginUserDetail = getLoginUserDetails();
  const label = { inputProps: { "aria-label": "Checkbox" } };
  const [taskList, setTaskList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const dispatch = useDispatch();
  const [showRightPanel, setShowRightPanel] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [showMultipleUpdate, setShowMultipleUpdate] = useState(false);
  const [showMultipleConcern, setShowMultipleConcern] = useState(false);
  const [rightModalName, setRightModalName] = useState(null);
  const [uiButtonMap, setUiButtonMap] = useState(new Map());
  const [highlighted, setHighlited] = useState([]);

  /*---------------------  convenient meeting start --------------------- */

  const [showConvenientMeetingView, setShowConvenientMeetingView] =
    useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentWeek, setCurrentWeek] = React.useState([]);
  const [slotDuration, setSlotDuration] = React.useState(15);
  const [startTime, setStartTime] = React.useState(0); //0 for 12:00 AM
  const [endTime, setEndTime] = React.useState(24); //23 for 11:00 PM
  const [rows, setRows] = React.useState([
    ...Array((endTime - startTime) * (60 / slotDuration)).keys(),
  ]); //23 for 11:00 PM
  const [cols, setCols] = React.useState([...Array(currentWeek.length).keys()]); //23 for 11:00 PM
  const [weekStartDay, setWeekStartDay] = React.useState(1);

  const [defaultWorkWeek, setDefaultWorkWeek] = React.useState([
    {
      id: 1,
      day: `Monday`,
    },
    {
      id: 2,
      day: `Tuesday`,
    },
    {
      id: 3,
      day: `Wednesday`,
    },
    {
      id: 4,
      day: `Thursday`,
    },
    {
      id: 5,
      day: `Friday`,
    },
  ]);

  const [selectedConvenientTime, setSelectedConvenientTime] = useState(null);
  const [hideForm, setHideForm] = useState(false);

  const [meetingStartTime, meetingEndTime] = useMemo(() => {
    let time = new Date();
    let minute = time.getMinutes();
    let hour = time.getHours();
    if (minute < 15) {
      minute = 15;
    } else if (minute < 30) {
      minute = 30;
    } else if (minute < 45) {
      minute = 45;
    } else {
      minute = 0;
      hour = (hour + 1) % 24;
    }
    time.setHours(hour);
    time.setMinutes(minute);

    const meetingEndTime = new Date(time.getTime() + 30 * 60000);
    return [time, meetingEndTime];
  }, []);

  useEffect(() => {
    console.log("createConvenient meeting modal opened");
    const selectedDate = new Date();

    let day = selectedDate.getDay();
    let diff = null;
    if (weekStartDay > day) {
      diff =
        selectedDate.getDate() - day - (day === 10 ? -6 : 7 - weekStartDay);
    } else {
      diff = selectedDate.getDate() - day + (day === 10 ? -6 : weekStartDay);
    }
    let currentWeekTemp2 = [];
    let tempDate = new Date(new Date());
    currentWeekTemp2.push(new Date(tempDate.setDate(diff)));
    for (let counter = 0; counter < 6; counter++) {
      var date = new Date(currentWeekTemp2[counter]);
      date.setDate(currentWeekTemp2[counter].getDate() + 1);
      currentWeekTemp2.push(date);
    }
    console.log("currentWeek empty useEffect", currentWeekTemp2);

    setCurrentWeek(currentWeekTemp2);
    setCols([...Array(currentWeekTemp2.length).keys()]);

    return () => {
      console.log("returning");
      dispatch(clearTaskModalData());
    };
  }, []);

  const calculateTimeDifference = (startTime, endTime) => {
    // Assuming startTime and endTime are time strings in the format "hh:mm A"
    const format = " YYYY-MM-DD hh:mm A";
    const startDateTime = dayjs("2024-01-01 " + startTime, { format });
    const endDateTime = dayjs("2024-01-01 " + endTime, { format });

    // Calculate the time difference using dayjs().diff()
    const duration = endDateTime.diff(startDateTime, "minute"); // Specify 'minute' here

    console.log("duration=", duration);
    return duration;
  };

  useEffect(() => {
    if (selectedConvenientTime) {
      handleCreateConvenientMeeting();
    }
  }, [selectedConvenientTime]);

  const handleCreateConvenientMeeting = () => {
    const reqDto = {
      title: "Convenient Meeting",
      description: "",
      length: calculateTimeDifference(
        selectedConvenientTime.convFromTime,
        selectedConvenientTime.convToTime
      ),
      startTime: new Date(
        new Date(selectedConvenientTime.convStartDate).toDateString() +
          " " +
          selectedConvenientTime.convFromTime
      ),
      endTime: new Date(
        new Date(selectedConvenientTime.convStartDate).toDateString() +
          " " +
          selectedConvenientTime.convToTime
      ),
      location: " ",
      participants: selectedUsers.map((user, index) => {
        return {
          userId: user.userId,
          userName: user.userName,
          convener: index === 0 ? "Y" : "N",
        };
      }),
    };

    createEvent(loginUserDetail.orgId, loginUserDetail.userId, reqDto).then(
      (response) => {
        if (response.data.returnValue === "1") {
          const status = response.data.status;
          TaskService.addEventToTask(914, status, loginUserDetail.userId).then(
            (res) => {
              if (res.data == 1) {
                toast.success("Meeting Created Successfully", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                toast.error("Meeting Could Not Be Created", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          );
        } else {
          toast.error("Meeting Could Not Be Created");
        }
      }
    );

    console.log("convenient meeting Dto =", reqDto);
  };

  /*   ------------------------- convenient meeting ends ---------------------------    */

  function intersectionOfLists(lists) {
    if (lists.length === 0) {
      return [];
    }

    // Start with the elements of the first list
    const result = [...lists[0]];

    // Iterate through each list
    for (const list of lists.slice(1)) {
      // Keep only the elements that are present in both lists
      const intersection = result.filter((value) => list.includes(value));
      result.length = 0;
      result.push(...intersection);
    }

    return result;
  }

  const shouldCheck = () => {
    let render = true;
    if (showMultipleUpdate || showMultipleConcern) {
      render = false;
    }
    if (rightModalName) {
      render = false;
    }
    return render;
  };

  const commonButtons = useMemo(() => {
    if (selectedTasks && selectedTasks.length === 0) {
      return [];
    }
    const ui = selectedTasks.map((task) => task.taskUIComponent);
    const uiSet = Array.from(new Set(ui));
    const buttonLists = uiSet.map((item) => uiButtonMap.get(item));
    console.log("button lists=", buttonLists);
    const singleSet = intersectionOfLists(buttonLists);
    return singleSet;
  }, [selectedTasks, uiButtonMap]);

  const selectedTask = useSelector(
    (state) => state.taskList.taskRightModalData
  );

  const filteredTaskList = useMemo(() => {
    if (searchString.trim() === "") {
      return taskList;
    }
    const value = taskList.filter((task) =>
      task.taskName.toLowerCase().includes(searchString.toLowerCase())
    );
    return value;
  }, [taskList, searchString]);

  const handleChangeInputRef = (e) => {
    setSearchString(e.target.value);
  };

  const openCreateTaskTab = () => {
    console.log("create task clicked");
    addTab({
      key: uuidv4(),
      title: "Create Task",
      content: <CreateTaskContainer />,
      isDraggable: true,
    });
  };
  const fetchTasks = () => {
    DashboardServices.getTasks(loginUserDetail.userId).then((response) => {
      if (response.data) {
        const value = response.data.map((item) => ({
          ...item,
          checked: false,
        }));
        setTaskList(value);
        dispatch(setTaskTabList(response.data));
      } else {
        toast.error("Something Went Wrong. Could Not Fetch Data.");
      }
    });
  };

  const refreshData = () => {
    fetchTasks();
    setSelectedTasks([]);
    setShowMultipleConcern(false);
    setShowMultipleUpdate(false);
  };

  useEffect(() => {
    uiButtonMap.set("TaskTabApprovalRequest", [
      "approve",
      "postpone",
      "reject",
      "delegate",
      "concern",
    ]);
    uiButtonMap.set("TaskTabCancelApprovalRequest", ["cancel_task"]);
    uiButtonMap.set("TaskTabSignOffApprovalRequest", ["sign_off", "forward"]);
    uiButtonMap.set("TaskTabIncomingOngoing", [
      "update",
      "delegate",
      "concern",
    ]);
    uiButtonMap.set("TaskTabOutgoingOngoing", ["update", "reassign"]);
    uiButtonMap.set("TaskTabSignOffCompleted", [
      "sign_off",
      "disagree",
      "reassign",
    ]);
    uiButtonMap.set("TaskTabReopenPostponed", [
      "resume_on",
      "reopen",
      "cancel_task",
    ]);
    uiButtonMap.set("TaskTabCancelRejected", ["cancel_task"]);
    uiButtonMap.set("TaskTabAddressConcern", ["address_concern", "reassign"]);
    uiButtonMap.set("TaskTabClosed", ["sign_off", "disagree", "reassign"]);
    uiButtonMap.set("TaskTabCompletedApprovalRequest", []);
    uiButtonMap.set("TaskTabCompleted", []);

    fetchTasks();
  }, []);

  const handleTadkListItemClick = async (task) => {
    //
    const taskNature = task?.taskNature;
    if (
      taskNature === "CC_APPROVED_EXPENDITURE" ||
      taskNature === "CC_APPROVED_EXPENDITURE_WITH_CHANGES"
    ) {
      const values = await DashboardServices.getCCDataOfApprovalOfExpenditure(
        loginUserDetail.userId,
        task.taskId,
        taskNature,
        task.taskFormDataTransId
      );
      // console.log("Value = ", values);

      dispatch(setTaskModalData({ ...task, ...values.data }));
    } else if (taskNature === null) {
      const values = await DashboardServices.getTaskById(task.taskId);
      dispatch(setTaskModalData({ ...task, ...values.data }));
    } else {
      const values = await DashboardServices.getTaskById(task.taskId);
      dispatch(setTaskModalData({ ...task, ...values.data }));
    }
    // setSelectedTask(task.taskId);
    setShowRightPanel(true);
  };

  const calculateHighlited = (task, taskList, mode) => {
    const taskUi = task.taskUIComponent;
    const tempSet = new Set();
    let highlitedTempList = [];
    taskList.forEach((item) => {
      tempSet.add(item.taskUIComponent);
    });
    tempSet.forEach((item) => {
      const tmp = filteredTaskList.filter(
        (ele) => ele.taskUIComponent === item
      );
      if (tmp) {
        highlitedTempList = [...highlitedTempList, ...tmp];
      }
    });

    setHighlited(highlitedTempList);
    console.log("highlited List =", highlitedTempList);
  };

  const isHighlited = (taskId) => {
    const temp = highlighted.find((item) => item.taskId === taskId);
    return temp ? true : false;
  };

  const renderAppropriateIcon = (iconType) => {
    if (iconType === "incoming") {
      return (
        <>
          <div className="incomTask ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.6524 1.78286V2.77714C14.6524 3.76286 13.8525 4.56 12.874 4.56H8.12602C7.14749 4.56 6.34765 3.76286 6.34765 2.77714V1.78286C6.34765 0.797143 7.14749 0 8.12602 0H12.874C13.8525 0 14.6524 0.797143 14.6524 1.78286ZM17.5964 2.28H15.9287V2.77714C15.9287 4.46571 14.5588 5.84571 12.874 5.84571H8.12602C6.44125 5.84571 5.07131 4.46571 5.07131 2.77714V2.28H3.40357C1.5231 2.28 0 3.81429 0 5.70857V20.5714C0 22.4657 1.5231 24 3.40357 24H17.5964C19.4769 24 21 22.4657 21 20.5714V5.70857C21 3.81429 19.4769 2.28 17.5964 2.28ZM14.2061 15.2281L10.8055 18.614C10.6492 18.7694 10.4375 18.8567 10.2166 18.8567C10.2098 18.8572 10.2029 18.8572 10.196 18.8567C10.1891 18.8573 10.1822 18.8573 10.1753 18.8567C10.0659 18.8571 9.95754 18.8358 9.85647 18.7942C9.75539 18.7525 9.66363 18.6913 9.5865 18.614L6.18583 15.2281C6.03605 15.0714 5.95368 14.8627 5.9563 14.6465C5.95892 14.4302 6.04632 14.2235 6.19985 14.0705C6.35338 13.9175 6.56088 13.8303 6.77808 13.8275C6.99528 13.8247 7.20498 13.9066 7.36243 14.0556L9.35717 16.0386V9.40042C9.35717 9.18056 9.4449 8.9697 9.60104 8.81424C9.75718 8.65877 9.96896 8.57143 10.1898 8.57143C10.4106 8.57143 10.6224 8.65877 10.7785 8.81424C10.9347 8.9697 11.0224 9.18056 11.0224 9.40042V16.0571L13.0295 14.0556C13.1061 13.9754 13.1981 13.9114 13.2999 13.8672C13.4017 13.823 13.5114 13.7996 13.6225 13.7982C13.7336 13.7969 13.8438 13.8177 13.9467 13.8595C14.0496 13.9012 14.143 13.963 14.2216 14.0412C14.3001 14.1195 14.3621 14.2126 14.4039 14.3151C14.4457 14.4175 14.4665 14.5273 14.4651 14.6379C14.4637 14.7485 14.44 14.8577 14.3955 14.9591C14.3511 15.0604 14.2867 15.1519 14.2061 15.2281Z"
                fill="white"
              />
            </svg>
          </div>
        </>
      );
    } else if (iconType === "outgoing") {
      return (
        <>
          <div className="outGoingTask ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.6524 1.78286V2.77714C14.6524 3.76286 13.8525 4.56 12.874 4.56H8.12602C7.14749 4.56 6.34765 3.76286 6.34765 2.77714V1.78286C6.34765 0.797143 7.14749 0 8.12602 0H12.874C13.8525 0 14.6524 0.797143 14.6524 1.78286ZM17.5964 2.28H15.9287V2.77714C15.9287 4.46571 14.5588 5.84571 12.874 5.84571H8.12602C6.44125 5.84571 5.07131 4.46571 5.07131 2.77714V2.28H3.40357C1.5231 2.28 0 3.81429 0 5.70857V20.5714C0 22.4657 1.5231 24 3.40357 24H17.5964C19.4769 24 21 22.4657 21 20.5714V5.70857C21 3.81429 19.4769 2.28 17.5964 2.28ZM6.21527 12.2005L9.61594 8.81457C9.77218 8.65921 9.98395 8.57191 10.2048 8.57184C10.2116 8.57135 10.2185 8.57135 10.2254 8.57184C10.2323 8.57129 10.2392 8.57129 10.2461 8.57184C10.3555 8.57148 10.4639 8.59275 10.5649 8.63442C10.666 8.67608 10.7578 8.73732 10.8349 8.81457L14.2356 12.2005C14.3854 12.3571 14.4677 12.5658 14.4651 12.7821C14.4625 12.9984 14.3751 13.205 14.2216 13.358C14.068 13.511 13.8605 13.5982 13.6433 13.601C13.4261 13.6038 13.2164 13.522 13.059 13.373L11.0642 11.39V18.0281C11.0642 18.248 10.9765 18.4589 10.8204 18.6143C10.6642 18.7698 10.4524 18.8571 10.2316 18.8571C10.0108 18.8571 9.79902 18.7698 9.64288 18.6143C9.48673 18.4589 9.39901 18.248 9.39901 18.0281V11.3715L7.39187 13.373C7.31527 13.4531 7.22334 13.5172 7.1215 13.5614C7.01966 13.6055 6.90997 13.629 6.79889 13.6303C6.68781 13.6317 6.57758 13.6109 6.4747 13.5691C6.37182 13.5274 6.27837 13.4656 6.19985 13.3873C6.12133 13.3091 6.05933 13.216 6.01751 13.1135C5.97568 13.011 5.95487 12.9013 5.95631 12.7907C5.95775 12.6801 5.9814 12.5709 6.02587 12.4695C6.07035 12.3682 6.13474 12.2767 6.21527 12.2005Z"
                fill="white"
              />
            </svg>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <taskTabContext.Provider
        value={{
          setShowConvenientMeetingView,
          setSelectedUsers,
        }}
      >
        <Box className="taskContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
          <Grid container spacing={0} className="taskContainArea">
            <Grid item xs={12} className="pRelative">
              {!showConvenientMeetingView && (
                <div className="taskContainer">
                  <div className="taskListHeader">My Task</div>

                  <div className="taskListTopBtnGroup">
                    <div className="taskListTopBtnLeft">
                      <div className="searchTasklist">
                        <input
                          type="text"
                          class="serchTaskInput"
                          value={searchString}
                          placeholder="Search or find task"
                          onChange={handleChangeInputRef}
                        />
                        <IconButton className="seacrhIcon">
                          <Search />
                        </IconButton>
                      </div>
                      {/* <IconButton className="filterTaskListBtn">
                        <Tune />
                      </IconButton> */}
                    </div>
                    <div className="taskListTopBtnRight">
                      {commonButtons &&
                        selectedTasks &&
                        selectedTasks.length > 1 &&
                        commonButtons.map((button) => {
                          if (button === "reassign") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    // setShowMultipleConcern(true);
                                    setRightModalName("reassign");
                                  }
                                }}
                              >
                                Reassign
                              </Button>
                            );
                          }
                          // else if (button === "delegate") {
                          //   return (
                          //     <Button
                          //       className="dfultDarkSecondaryBtn"
                          //       // startIcon={<ShortcutOutlined />}
                          //     >
                          //       Delegate
                          //     </Button>
                          //   );
                          // }
                          else if (button === "update") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    setShowMultipleUpdate(true);
                                  }
                                }}
                              >
                                Update
                              </Button>
                            );
                          } else if (button === "cancel_task") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    setRightModalName("cancel_task");
                                    // setShowMultipleConcern(true);
                                  }
                                }}
                              >
                                Cancel
                              </Button>
                            );
                          } else if (button === "concern") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    setShowMultipleConcern(true);
                                  }
                                }}
                              >
                                Concern
                              </Button>
                            );
                          }
                          // else if (button === "link_task") {
                          //   return (
                          //     <Button
                          //       className="dfultDarkSecondaryBtn"
                          //       // startIcon={<ShortcutOutlined />}
                          //     >
                          //       Link Task
                          //     </Button>
                          //   );
                          // }
                          else if (button === "approve") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  // if (selectedTasks.length === 1) {
                                  //   handleTadkListItemClick(selectedTasks[0]);
                                  // } else {
                                  //   // setShowMultipleConcern(true);
                                  // }
                                  setRightModalName("approve");
                                }}
                              >
                                Approve
                              </Button>
                            );
                          } else if (button === "postpone") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    // setShowMultipleConcern(true);
                                    setRightModalName("postpone");
                                  }
                                }}
                              >
                                Postpone
                              </Button>
                            );
                          } else if (button === "reject") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    setRightModalName("reject");
                                    // setShowMultipleConcern(true);
                                  }
                                }}
                              >
                                Reject
                              </Button>
                            );
                          } else if (button === "delegate") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}

                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    // setShowMultipleConcern(true);
                                    setRightModalName("delegate");
                                  }
                                }}
                              >
                                Delegate
                              </Button>
                            );
                          } else if (button === "sign_off") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    // setShowMultipleConcern(true);
                                    setRightModalName("signOff");
                                  }
                                }}
                              >
                                Sign Off
                              </Button>
                            );
                          } else if (button === "disagree") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    // setShowMultipleConcern(true);
                                    setRightModalName("disagree");
                                  }
                                }}
                              >
                                Disagree
                              </Button>
                            );
                          } else if (button === "forward") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}

                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    // setShowMultipleConcern(true);
                                    setRightModalName("forward");
                                  }
                                }}
                              >
                                Forward
                              </Button>
                            );
                          } else if (button === "address_concern") {
                            return (
                              <Button
                                className="dfultDarkSecondaryBtn"
                                // startIcon={<ShortcutOutlined />}
                                onClick={(e) => {
                                  if (selectedTasks.length === 1) {
                                    handleTadkListItemClick(selectedTasks[0]);
                                  } else {
                                    setRightModalName("address_concern");
                                    // setShowMultipleConcern(true);
                                  }
                                }}
                              >
                                Address Concern
                              </Button>
                            );
                          }
                        })}
                      {/* <Button
                    disabled
                    className="dfultDarkSecondaryBtn"
                    startIcon={<CancelOutlined />}
                  >
                    Raise Concern
                  </Button>

                  <Button
                    disabled
                    className="dfultDarkSecondaryBtn"
                    startIcon={<CheckCircle />}
                  >
                    Update
                  </Button> */}

                      <Button
                        className="dfultPrimaryBtn"
                        startIcon={<Add />}
                        onClick={openCreateTaskTab}
                      >
                        Create Task
                      </Button>
                    </div>
                  </div>

                  <div className="taskListTableContainer">
                    <table className="taskListTable">
                      <thead className="taskListtableHeader">
                        <tr>
                          <th className="t-w38">
                            {/* <Checkbox
                          {...label}
                          value={selectAllChecked}
                          onChange={(e) => {
                            const chk = e.target.checked;
                            setSelectAllChecked(e.target.checked);
                            if(chk){

                            }
                          }}
                        /> */}
                          </th>
                          <th>Task</th>
                          <th>Description</th>
                          <th>Type</th>
                          <th>Contract</th>
                          <th>Status</th>
                          <th>Priority</th>
                          <th>Completion Date</th>
                          <th>Created By/Assigned By</th>
                        </tr>
                      </thead>

                      <tbody className="scrolableTableBody">
                        {filteredTaskList &&
                          filteredTaskList.length > 0 &&
                          filteredTaskList.map((task, index) => (
                            <tr
                              className={`${
                                selectedTask &&
                                selectedTask.taskId === task.taskId
                                  ? "trowSelected"
                                  : isHighlited(task.taskId)
                                  ? "highlitedTask"
                                  : ""
                              } `}
                              onClick={(e) => handleTadkListItemClick(task)}
                            >
                              <td
                                className="t-w38"
                                onClick={(e) => e.stopPropagation()}
                              >
                                {filteredTaskList.length > 1 && (
                                  <Checkbox
                                    checked={task.checked}
                                    onChange={(e) => {
                                      if (!shouldCheck()) {
                                        toast.error(
                                          "Can't Add Items With Container Opened. Close To Proceed."
                                        );
                                        return;
                                      }

                                      const temp = taskList;
                                      temp[index].checked = e.target.checked;
                                      setTaskList(temp);
                                      const alreadySelected = [
                                        ...selectedTasks,
                                      ];
                                      const position =
                                        alreadySelected.findIndex(
                                          (obj) => obj.taskId === task.taskId
                                        );
                                      if (position === -1) {
                                        alreadySelected.push(task);
                                        calculateHighlited(
                                          task,
                                          alreadySelected,
                                          "add"
                                        );
                                      } else {
                                        alreadySelected.splice(position, 1);
                                        calculateHighlited(
                                          task,
                                          alreadySelected,
                                          "remove"
                                        );
                                      }

                                      setSelectedTasks(alreadySelected);
                                      console.log(
                                        "task list value =",
                                        taskList
                                      );
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                <div className="tskLstTaskNM">
                                  <span className="inOutIcon">
                                    {renderAppropriateIcon(
                                      task.incoming === "Y"
                                        ? "incoming"
                                        : "outgoing"
                                    )}
                                  </span>
                                  <Tooltip title={task.taskName} arrow>
                                    <span className="tskListTskName">
                                      {task.taskName}
                                    </span>
                                  </Tooltip>
                                  {task.taskLink && (
                                    <IconButton className="linkTskIoc">
                                      <Diagram3 />
                                    </IconButton>
                                  )}
                                </div>
                              </td>
                              <td>
                                <Tooltip title={task.taskDescription} arrow>
                                  <div className="tskLstTaskDescription">
                                    {task.taskDescription}
                                  </div>
                                </Tooltip>
                              </td>
                              <td className="tskLstTaskTypeNm">
                                {task.taskType}
                              </td>
                              <td>
                                <div className="tskLstTaskContract">
                                  {task.contractName}
                                </div>
                              </td>
                              <td>
                                <div className="tskLstTaskStatus ">
                                  {task.progress}
                                </div>
                              </td>
                              <td>
                                {/* statRoutine statCritical statImportant */}
                                <div
                                  className={`tskLstTaskPriority ${
                                    task.priority === 0
                                      ? "statRoutine"
                                      : task.priority === 1
                                      ? "statImportant"
                                      : "statCritical"
                                  }`}
                                >
                                  {task.priority === 0
                                    ? "Regular"
                                    : task.priority === 1
                                    ? "Important"
                                    : "Critical"}
                                </div>
                              </td>
                              <td>
                                <div className="tskLstTaskCompDt">
                                  <span className="compDate">
                                    {dayjs(task.completionTime).format(
                                      "DD-MM-YY"
                                    )}
                                  </span>
                                  <span className="compTime">
                                    {" "}
                                    {dayjs(task.completionTime).format(
                                      "HH:mm A"
                                    )}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="tskLstTaskRequstBy">
                                  {task.createdByUserName}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {/* {showConvenientMeetingView && (
              <div className="taskContainer">
                <ConvenientTimeView
                  usersList={selectedUsers.map((user) => user.userId)}
                  meetingStartTime={meetingStartTime}
                  meetingEndTime={meetingEndTime}
                  // meetingStartTime={new Date()}
                  // meetingEndTime={new Date()}
                  loginUser={loginUserDetail}
                  startTime={startTime}
                  endTime={endTime}
                  rows={rows}
                  slotDuration={slotDuration}
                  cols={cols}
                  defaultWorkWeek={defaultWorkWeek}
                  viewForm={(data) => {
                    if (data === "select") {
                      setHideForm(false);
                    } else {
                      setHideForm(!hideForm);
                    }
                  }}
                  hideForm={hideForm}
                  setSelectedConvenientTime={setSelectedConvenientTime}
                />
              </div>
            )} */}

              {showConvenientMeetingView && (
                <>
                  <div className="taskContainer">
                    <div className="taskListHeader">
                      {`Create A New Meeting For ${selectedTask.taskName}`}
                    </div>
                    <CreateConvenientMeetingWrapper
                      selectedUsers={selectedUsers}
                      handleClose={() => {
                        setHideForm(false);
                        setShowConvenientMeetingView(false);
                      }}
                      hideForm={hideForm}
                      setHideForm={setHideForm}
                      setSelectedConvenientTime={setSelectedConvenientTime}
                    />
                  </div>
                </>
              )}

              {!hideForm && (
                <RightSideTaskTabList
                  refreshData={() => {
                    fetchTasks();
                    // setShowRightPanel(false);
                  }}
                  handleClose={() => {
                    setShowRightPanel(false);
                  }}
                />
              )}

              {showMultipleUpdate && (
                <RightSideMultipleUpdate
                  selectedTasks={selectedTasks}
                  handleClose={(e) => {
                    setShowMultipleUpdate(false);
                  }}
                  updateTaskList={(data) => {
                    const tempTaskList = [...taskList];
                    const tempSelectedTasks = [];
                    const updatedTaskList = tempTaskList.map((item) => {
                      const matchingItemIndex = data.findIndex(
                        (d) => d.taskId === item.taskId
                      );
                      if (matchingItemIndex < 0) {
                        return item;
                      } else {
                        tempSelectedTasks.push(data[matchingItemIndex]);
                        return data[matchingItemIndex];
                      }
                    });
                    setTaskList(updatedTaskList);
                    setSelectedTasks(tempSelectedTasks);
                  }}
                />
              )}

              {showMultipleConcern && (
                <RightSideMultipleConcern
                  selectedTasks={selectedTasks}
                  handleClose={(e) => {
                    setShowMultipleConcern(false);
                  }}
                  refreshData={refreshData}
                />
              )}

              {rightModalName && (
                <RightSideCommonModal>
                  {rightModalName === "approve" && (
                    <RightSideApproveModal
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}
                  {rightModalName === "reject" && (
                    <RightSideRejectRequest
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}
                  {rightModalName === "cancel_task" && (
                    <RightSideCloseTask
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}

                  {rightModalName === "signOff" && (
                    <RightSideSignOff
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}

                  {rightModalName === "postpone" && (
                    <RightSidePostpone
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}
                  {rightModalName === "delegate" && (
                    <RightSideDelegate
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}
                  {rightModalName === "reassign" && (
                    <RightSideReassign
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}

                  {rightModalName === "forward" && (
                    <RightSideTaskForward
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}

                  {rightModalName === "disagree" && (
                    <RightSideDisagree
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}

                  {rightModalName === "address_concern" && (
                    <RightSideAddressConcern
                      selectedTasks={selectedTasks}
                      handleClose={(e) => {
                        setRightModalName(null);
                      }}
                      refreshData={refreshData}
                    />
                  )}
                </RightSideCommonModal>
              )}
            </Grid>
          </Grid>
        </Box>
      </taskTabContext.Provider>
    </>
  );
}
