import {
  AdminPanelSettings,
  CalendarMonth,
  ChatBubble,
  ChatBubbleOutline,
  Email,
  EmailOutlined,
  Folder,
  Storage,
  StorageOutlined,
  Videocam,
  VideocamOutlined,
} from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { publicUrl } from "../constants/url_provider";
import {
  Calendar,
  CalendarFill,
  FileEarmarkPpt,
  FileEarmarkSpreadsheet,
  FileEarmarkWord,
  ListTask,
} from "react-bootstrap-icons";
import { AddTabContext } from "../features/dashboard/view/DashboardPage";
import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import SchedulerContainer from "../features/scheduler/view/SchedulerContainer";
import { ConfirmProvider } from "material-ui-confirm";
import MeetingsTabContainer from "../features/scheduler/view/component/meetings/view/MeetingsTabContainer";
import DriveContainer from "../features/drive/view/DriveContainer";
import ChatContainer from "../features/chat/view/ChatContainer";
import EmailContainerWrapper from "../features/email/view/EmailContainerWrapper";
import TaskTabContainer from "../features/task/view/TaskTabContainer";
import { getLoginUserDetails } from "../utils/Utils";
import AppUtils from "../utils/AppUtils";
import Admin from "../features/admin/view/Admin";

export default function BottomMenuAppList() {
  const { addTab } = useContext(AddTabContext);
  const loginUser = getLoginUserDetails();
  const loginUserDetail = getLoginUserDetails();

  return (
    <div class="apps">
      <h2>Apps</h2>
      <ul>
        {!AppUtils.isPersonal(loginUser) && (
          <li
            onClick={() => {
              addTab({
                key: uuidv4(),
                title: "Task",
                content: <TaskTabContainer />,
                isDraggable: true,
              });
            }}
          >
            <ListTask className="btmMenuAppsIcon" /> Task
          </li>
        )}
        <li
          onClick={() => {
            addTab({
              key: uuidv4(),
              title: "Email",
              content: <EmailContainerWrapper />,
              isDraggable: true,
            });
          }}
        >
          <EmailOutlined className="btmMenuAppsIcon" /> Email
        </li>
        <li
          onClick={() => {
            addTab({
              key: uuidv4(),
              title: "Chat",
              content: <ChatContainer />,
              isDraggable: true,
            });
          }}
        >
          <ChatBubbleOutline className="btmMenuAppsIcon" /> Chat
        </li>
        {/* <li
          onClick={() => {
            addTab({
              key: uuidv4(),
              title: "Scheduler",
              content: <SchedulerContainer />,
              isDraggable: true,
            });
          }}
        >
          <FileEarmarkPpt className="btmMenuAppsIcon" /> Presentation
        </li>
        <li
          onClick={() => {
            addTab({
              key: uuidv4(),
              title: "Scheduler",
              content: <SchedulerContainer />,
              isDraggable: true,
            });
          }}
        >
          <FileEarmarkWord className="btmMenuAppsIcon" /> Word Processor
        </li>
        <li
          onClick={() => {
            addTab({
              key: uuidv4(),
              title: "Scheduler",
              content: <SchedulerContainer />,
              isDraggable: true,
            });
          }}
        >
          <FileEarmarkSpreadsheet className="btmMenuAppsIcon" /> Spread Sheets
        </li> */}

        <li
          onClick={() => {
            addTab({
              key: uuidv4(),
              title: "Scheduler",
              content: <SchedulerContainer />,
              isDraggable: true,
            });
          }}
        >
          <CalendarMonth className="btmMenuAppsIcon" /> Scheduler
        </li>
        <li
          onClick={() => {
            addTab({
              key: uuidv4(),
              title: "Meeting Room",
              content: (
                <ConfirmProvider>
                  <MeetingsTabContainer />
                </ConfirmProvider>
              ),
              isDraggable: true,
            });
          }}
        >
          <VideocamOutlined className="btmMenuAppsIcon" /> Meeting Room
        </li>
        <li
          onClick={() => {
            addTab({
              key: uuidv4(),
              title: "Drive",
              content: <DriveContainer addTab={addTab} />,
              isDraggable: true,
            });
          }}
        >
          <StorageOutlined className="btmMenuAppsIcon" /> Drive
        </li>

        {loginUserDetail.userType === "ADMIN" &&
          !AppUtils.isPersonal(loginUser) && (
            <li
              onClick={() => {
                addTab({
                  key: uuidv4(),
                  title: "Admin",
                  content: <Admin />,
                  isDraggable: true,
                });
              }}
            >
              <AdminPanelSettings className="btmMenuAppsIcon" /> Admin
            </li>
          )}

        {/* <li>
          <AdminPanelSettings className="btmMenuAppsIcon" /> Admin
        </li> */}
        {/* <li>
          <EmailOutlined className="btmMenuAppsIcon" /> Email
        </li> */}
      </ul>
    </div>
  );
}
