import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { Trash } from "react-bootstrap-icons";
import ReactWordcloud from "react-wordcloud";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { useState } from "react";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export default function Investigations({ updateData, data }) {
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [items, setItems] = useState([
    { name: "Blood Suger" },
    { name: "Lipid Profile" },
  ]);
  const options = {
    colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
    enableTooltip: true,
    deterministic: false,
    fontFamily: "impact",
    fontSizes: [5, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 3,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
  };

  const [selectedInvestigation, setSelectedInvestigation] = useState(null);
  const [investigationSearchString, setInvestigtionSearchString] = useState("");

  const [investigationList, setInvestigationList] = useState([]);
  const [userInvestigationList, setUserInvestigationList] = useState([]);

  const initializeForm = () => {
    setSelectedInvestigation(null);
    setInvestigtionSearchString("");
  };

  const suggestedFood = useMemo(() => {
    const filteredList = getFilteredList(
      investigationList,
      investigationSearchString,
      "testName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [investigationSearchString, investigationList]);

  const testsString = useMemo(() => {
    const str =
      userInvestigationList.length > 0
        ? userInvestigationList.map((item) => `${item.testName} `).join(", ")
        : "";
    return str;
  }, [userInvestigationList]);

  useEffect(() => {
    if (data.investigation) {
      setUserInvestigationList(data.investigation);
    }
    getMedicalTestList();
  }, []);

  const getMedicalTestList = () => {
    const symptomString = localStorage.getItem("medicalTestList");
    if (symptomString) {
      setInvestigationList(JSON.parse(symptomString));
      return;
    }

    const reqDto = getHealthDto();

    DashboardServices.getMedicalTestList(reqDto).then((response) => {
      if (response.data) {
        setInvestigationList(response.data);
        localStorage.setItem("medicalTestList", JSON.stringify(response.data));
      }
    });
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            Possible Investigations:
            {!accordionExpanded && <span>{testsString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {/* <div className="wordCloud">
            <ReactWordcloud
              options={options}
              words={words}
              callbacks={{
                onWordClick: handleWordClick,
              }}
            />
          </div> */}

          <div className="complaintList ">
            <ul>
              {userInvestigationList &&
                userInvestigationList.map((item, index) => (
                  <li key={index}>
                    <div className="complstDtlGrp">
                      <div className="complstDtl">
                        <div className="compntName">{item.testName}</div>
                        <div className="compntName BrandMed">
                          {item.location}
                        </div>
                      </div>
                      <div className="d-flex">
                        <Button className="dfultDarkSecondaryBtn ">
                          Go for Test
                        </Button>
                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...userInvestigationList];
                            temp.splice(index, 1);
                            updateData({ investigation: temp });
                            setUserInvestigationList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>

          <div className="bb"></div>
          <div className="addNewformGrp mt10">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <TextField
                  // hiddenLabel
                  label="Search Investigations"
                  autoComplete="off"
                  variant="outlined"
                  className="formTextFieldArea"
                /> */}

                <Autocomplete
                  freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedInvestigation}
                  options={suggestedFood}
                  inputValue={investigationSearchString}
                  onChange={(e, newValue) => {
                    setSelectedInvestigation(newValue);
                  }}
                  onInputChange={(e, value, reason) => {
                    setInvestigtionSearchString(value);
                    // else {
                    //   setInvestigtionSearchString("");
                    // }
                  }}
                  getOptionLabel={(option) => option.testName}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.id}>
                        {item.testName}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder="Search Investigations"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <Button
              className="dfultPrimaryBtn "
              startIcon={<Add />}
              onClick={() => {
                let tempAllergicFood = selectedInvestigation;
                if (!tempAllergicFood) {
                  if (
                    !investigationSearchString ||
                    investigationSearchString === ""
                  ) {
                    toast.error("Please Provide Investigation Name");
                    return;
                  }
                  tempAllergicFood = {
                    testId: uuidv4(),
                    testName: investigationSearchString,
                    location: "",
                    view: "",
                    technique: "",
                    locationId: 0,
                    viewId: 0,
                    techinqueId: 0,
                  };
                }
                setUserInvestigationList((prev) => {
                  const tempData = [...prev, tempAllergicFood];
                  updateData({ investigation: tempData });
                  return tempData;
                });
                initializeForm();
              }}
            >
              Add
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
