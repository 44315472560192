import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

export const getMessageExchangedChats = (userId) => {
  return http.post(`chat/getMessageExchangedChats/${userId}`, {
    headers: authHeader(),
  });
};

export const getChatAllContacts = (userId, orgId) => {
  return http.get(`chat/getChatAllContacts/${userId}/${orgId}`, {
    headers: authHeader(),
  });
};

export const sendMessage = (chatId, userId, orgId, reqDto) => {
  return http.post(`chat/sendMessage/${chatId}/${userId}/${orgId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getChatMessages = (userId, chatId, unreadCount, messageId) => {
  return http.get(
    `chat/getChatMessages/${userId}/${chatId}/${unreadCount}/${messageId}`,
    {
      headers: authHeader(),
    }
  );
};

export const readMessage = (chatId, userId) => {
  return http.post(`chat/readMessage/${chatId}/${userId}`, {
    headers: authHeader(),
  });
};

export const createGroup = (userId, orgId, body) => {
  return http.post(`chat/createGroup/${userId}/${orgId}`, body, {
    headers: authHeader(),
  });
};

export const addMembersToGroup = (chatId, userId, body) => {
  return http.post(`chat/addMembersToGroup/${chatId}/${userId}`, body, {
    headers: authHeader(),
  });
};

export const getGroupChatUsers = (chatId) => {
  return http.get(`chat/getGroupChatUsers/${chatId}`, {
    headers: authHeader(),
  });
};

export const updateGroupName = (chatId, userId, newName) => {
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeader().Authorization,
    },
  };
  return http.put(`chat/updateGroupName/${chatId}/${userId}`, newName, config);
};

export const updateGroupIcon = (chatId, userId, reqDto) => {
  return http.put(`chat/updateGroupIcon/${chatId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const removeMemberOfGroup = (chatId, userId, body) => {
  const conf = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeader().Authorization,
    },
  };
  return http.post(`chat/removeMemberOfGroup/${chatId}/${userId}`, body, conf);
};

export const makeGroupAdmin = (chatId, userId, body) => {
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeader().Authorization,
    },
  };
  return http.put(`chat/makeGropuAdmin/${chatId}/${userId}`, body, config);
};

export const forwardMessages = (userId, body) => {
  return http.post(`chat/forwardMessages/${userId}`, body, {
    headers: authHeader(),
  });
};

export const deleteMessage = (messageId) => {
  return http.put(`chat/deleteMessage/${messageId}`, {
    headers: authHeader(),
  });
};

export const dismissGropuAdmin = (chatId, userId, adminId) => {
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeader().Authorization,
    },
  };
  return http.put(
    `chat/dismissGropuAdmin/${chatId}/${userId}`,
    adminId,
    config
  );
};
