import { Button } from "@mui/material";
import React from "react";
import { Facebook, Instagram, Linkedin, Twitter } from "react-bootstrap-icons";
import brandLogo from "../../images/BrandLogo.svg";

export default function FooterLP() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="zoyelFooterContainer">
        <div className="zoyelFootElementContainer">
          <div className="footerElement footerAbout">
            <div className="footElmentTitle">About Zoyel</div>
            <div className="footElmentText">
              Zoyel One integrates a wide range of functionalities into a single
              platform, eliminating the need for multiple disparate tools. This
              unified approach simplifies operations, reduces costs, and
              enhances productivity by providing everything a business needs in
              one place.
            </div>
            <div className="footElmentSocialLinks">
              <a
                href="https://www.google.com/"
                target="blank"
                className="socialIconsBtn"
              >
                <Instagram />
              </a>
              <a
                href="https://www.google.com/"
                target="blank"
                className="socialIconsBtn"
              >
                <Twitter />
              </a>
              <a
                href="https://www.google.com/"
                target="blank"
                className="socialIconsBtn"
              >
                <Linkedin />
              </a>
              <a
                href="https://www.google.com/"
                target="blank"
                className="socialIconsBtn"
              >
                <Facebook />
              </a>
            </div>
          </div>
          <div className="footerElement footerImportLink">
            <div className="footElmentTitle">Important Links</div>
            <div className="footQuickLinks">
              {/* <a
                href="https://www.google.com/"
                target="blank"
                className="quickLinksBtn"
              >
                Features
              </a>
              <a
                href="https://www.google.com/"
                target="blank"
                className="quickLinksBtn"
              >
                About Us
              </a>
              <a
                href="https://www.google.com/"
                target="blank"
                className="quickLinksBtn"
              >
                How it Works
              </a>
              <a
                href="https://www.google.com/"
                target="blank"
                className="quickLinksBtn"
              >
                What our Customers Say
              </a>
              <a
                href="https://www.google.com/"
                target="blank"
                className="quickLinksBtn"
              >
                Safe and Secure
              </a> */}
              <a
                href="https://zoyel.one/#/privacy-policy"
                target="blank"
                className="quickLinksBtn"
              >
                Privacy Policy
              </a>
              <a
                href="https://zoyel.one/#/terms-and-conditions"
                target="blank"
                className="quickLinksBtn"
              >
                Terms & Conditions
              </a>
            </div>
          </div>
          <div className="footerElement footerContact">
            <div className="footElmentTitle">Contact</div>
            <div className="footElmentAddress">
              <p className="cpNm">Zoyel Technologies LLC</p>
              <p>112-028, Bena Complex C, Dubai UAE</p>
            </div>
            <div className="footContPhoneMail">
              <a href="tel:+97143463575">(971) 43463575</a>
              <a href="mailto:info@zoyel.one">info@zoyel.one</a>
            </div>
          </div>
          <div className="footerElement footerSubscribe">
            <div className="footElmentTitle">Subscribe</div>
            <div className="footElmentForm">
              <div className="gentext">
                Subscribe to our Newsletter for latest News
              </div>
              <div className="footnewsLetrFrm">
                <input
                  type="email"
                  className="newsLetInput"
                  placeholder="Email"
                />
                <Button className="newsSubmit">Submit</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="zoyelFooterCopyright">
          <div className="footerbrandLogo">
            <img src={brandLogo} alt="Zoyel" />
          </div>
          <div className="copyrightTxt">
            Copyright &#169;{" "}
            <a href="https://zoyel.one/" target="blank">
              Zoyel.one
            </a>{" "}
            {currentYear}
          </div>
        </div>
      </div>
    </>
  );
}
