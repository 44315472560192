import {
  AssignmentLateOutlined,
  Audiotrack,
  CheckCircle,
  ListAlt,
  Send,
  ShortcutOutlined,
  VideoCall,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Modal,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { People, XLg } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import DashboardServices from "../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../utils/Utils";
import "../../css/task.css";
import RightFlotingContainer from "./RightFlotingContainer";

import { useContext } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import {
  clearSelectedTask,
  setSelectedTask,
} from "../../../../redux/reducers/rightSideTaskPanelSlice";
import TaskService from "../../../../services/TaskService";
import { homeContainerContext } from "../../../dashboard/view/component/HomeContainer";
import isAssignedByMe from "../../taskUtil";
import AssigneeModal from "./AssigneeModal";
import ConcernTaskModal from "./ConcernTaskModal";
import FileList from "./FileList";
import { IncomingFormPreview } from "./Forms/IncomingFormPreview";
import PmsApprovalForm from "./Forms/PmsApprovalForm";
import { PurchaseFormRenderer } from "./Forms/PurchaseFormRenderer";
import ForwardTaskModal from "./ForwardTaskModal";
import LinkTaskModal from "./LinkTaskModal";
import MeetingModal from "./Modals/MeetingModal";
import RightTaskFileUpload from "./RightTaskFileUpload";
import TemplateList from "./TemplateList";
import { useTimeProgressor } from "./hooks/useTimerProgressor";
import { TaskRenderer } from "./TaskRenderer";

export default function TaskPmsCycleApprovalRequest({
  data,
  refreshData,
  removeFromStore,
}) {
  const loginUserDetail = getLoginUserDetails();

  const [assigneeList, setAssigneeList] = useState([]);
  const [userList, setUserList] = useState([]);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const handleClickFormButton = () => {
    if (data.taskNature === "PMS_APPROVAL_REQUEST") {
      setShowForm(true);
      return;
    }
    TaskService.getExpenseApprovalRequest(data.formDataTransId).then(
      (response) => {
        if (response.data) {
          setFormData(response.data);
          setShowForm(true);
        }
      }
    );
  };

  const calculateDateProgress = () => {};

  useEffect(() => {
    calculateDateProgress();
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );
  }, []);

  useEffect(() => {
    if (showForm) {
      handleClickFormButton();
    }
  }, [data]);

  return (
    <>
      <TaskRenderer
        data={data}
        assigneeList={assigneeList}
        refreshData={refreshData}
        removeFromStore={removeFromStore}
      />

      {/* <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp"></div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setShowConvenientMeetingView(false);
                    convinientSelecteduser([]);
                    dispatch(clearSelectedTask());
                  }}
                >
                  <Tooltip title="Close" arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                <div className="taskTitleContrct">
                  <div className="taskTitleNM">{data && data.taskName}</div>
                </div>

                {data && data.taskDescription && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Description</div>
                    <div className="tskElemntInfo">
                      {data && data.taskDescription}
                    </div>
                  </div>
                )}

                {data && data.contractName && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Contract</div>
                    <div className="taskContractNm">
                      {data && data.contractName}
                    </div>
                  </div>
                )}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Timeline</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // up50 up80 up100
                          className={`progress-done ${
                            data && dateRemaning < 50
                              ? "up50"
                              : dateRemaning < 80
                              ? "up80"
                              : "up100"
                          }`}
                          style={{ width: `${dateRemaning}%`, opacity: 1 }}
                        ></div>
                      </div>
                    </div>
                    <div className="tskDtSE">
                      <div className="tskDTStart">
                        {getFormattedDate(data.createdAt)}
                      </div>
                      <div className="tskDTEnd">
                        {getFormattedDate(data.completionTime)}
                      </div>
                    </div>
                  </div>
                </div>

                {data &&
                  data.formDataTransId &&
                  data.formDataTransId !== "" &&
                  data.formDataTransId !== "0" && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">Form Data</div>

                      <IconButton
                        className="elemntMrFileBtn"
                        variant="outlined"
                        onClick={handleClickFormButton}
                      >
                        <ListAlt />
                      </IconButton>
                    </div>
                  )}

                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <IconButton
                      className="elemntMrFileBtn"
                      variant="outlined"
                      onClick={() => {
                        DashboardServices.generatePreSignedUrlS3Object(
                          data.audioNoteFileName,
                          loginUserDetail.orgId
                        ).then((response) => {
                          audioUrl.current = response.data;
                          setShowAudioModal(true);
                        });
                      }}
                    >
                      <Audiotrack />
                    </IconButton>
                  </div>
                )}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Priority</div>
                  <div className="tskPrioArea">
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class={`priStat `}>
                        {data.priority === 0
                          ? "Regular"
                          : data.priority === 1
                          ? "Important"
                          : "Critical"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Activity</div>
                    <div className="tskElemntInfo">{data.activityName}</div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Requested By</div>
                    <div className="tskElemntInfo">
                      {data && isAssignedByMe(data.assignByUserId)
                        ? "Me"
                        : data.assignByUserName}
                    </div>
                  </div>
                </div>

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Last updated</div>
                  <div className="tskElemntInfo">
                    {data &&
                      data.comments &&
                      data.comments.length &&
                      `${
                        data.comments[data.comments.length - 1]
                          .commentsByUserName
                      } - ${getFormattedDate(
                        data.comments[data.comments.length - 1].commentsAt
                      )}`}
                  </div>
                </div>

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>

                <div ref={scrollRef}></div>
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="tskInputGrp">
              <input
                ref={commentInputRef}
                type="text"
                className="tskComntInput"
                placeholder="Update comment here"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    console.log("Enter key pressed");
                    sendComment(event);
                  }
                }}
              />
              <IconButton className="tskComtSndBtn" onClick={sendComment}>
                <Send />
              </IconButton>
            </div>
          </div>
        </div>

        {showReject && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Reject Request</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      id="outlined-basic"
                      required
                      label="Rejection Reason"
                      variant="outlined"
                      multiline
                      rows={4}
                      maxRows={7}
                      value={rejectRemark}
                      onChange={(e) => setRejectRemark(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    if (rejectRemark.trim() === "") {
                      toast.error("Please provide rejection reason");
                      return;
                    }
                    DashboardServices.rejectTask(
                      data.taskId,
                      loginUserDetail.userId,
                      rejectRemark
                    ).then((response) => {
                      if (response.data.returnValue === "1") {
                        toast.success(response.data.message);
                        dispatch(clearSelectedTask());
                        refreshData();
                      } else {
                        toast.error(response.data.message);
                      }
                    });
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowReject(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showPostpone && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Postpone Approval Request</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      sx={{ color: "balck !important" }}
                      label="Postpone Reason*"
                      variant="outlined"
                      multiline
                      rows={4}
                      maxRows={7}
                      value={postponeRemark}
                      onChange={(e) => setPostponeRemark(e.target.value)}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <InputLabel
                    id="setCompletionDate"
                    className="setCompletionDate"
                  >
                    Postpone Till*
                  </InputLabel>
                  <ReactDatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    labelId="setCompletionDate"
                    className="formDatePicker"
                    selected={postponeTill}
                    minDate={
                      new Date(new Date().setDate(new Date().getDate() + 1))
                    }
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      console.log("new date = ", date);
                      setPostponeTill(date);
                    }}
                  />
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    

                    let validForm = true;
                    if (postponeRemark.trim() === "") {
                      toast.error("Please Provide Postpone Reason");
                      validForm = false;
                    }

                    if (!validForm) {
                      return;
                    }

                    const day = postponeTill.getDate();
                    const month = postponeTill.getMonth() + 1; 
                    const year = postponeTill.getFullYear();

                    
                    const formattedDay = String(day).padStart(2, "0");
                    const formattedMonth = String(month).padStart(2, "0");

                    
                    const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

                    const reqDto = {
                      postponedReason: postponeRemark,
                      postponedTill: formattedDate,
                    };

                    DashboardServices.postponedTask(
                      data.taskId,
                      loginUserDetail.userId,
                      reqDto
                    ).then((response) => {
                      if (response.data && response.data.returnValue === "1") {
                        toast.success(response.data.message);
                        dispatch(clearSelectedTask());
                        refreshData();
                      } else {
                        toast.error(response.data.message);
                      }
                    });
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowPostpone(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showForwardTask && (
          <ForwardTaskModal
            data={data}
            userList={userList}
            refreshData={refreshData}
            handleClose={() => {
              setShowForwardTask(false);
            }}
            
          />
        )}

        {openUserList && (
          <Modal
            open={openUserList}
            onClose={() => {
              setOpenUserList(false);
            }}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{ backgroundColor: "white", width: "50vw", height: "50vh" }}
            >
              <ul>
                {assigneeList &&
                  assigneeList.map((assignee) => {
                    return <li>{assignee.name}</li>;
                  })}
              </ul>
            </Box>
          </Modal>
        )}

        {showMeetingModal && (
          <MeetingModal
            handleClose={() => setShowMeetingsModal(false)}
            assigneeList={assigneeList}
            taskId={data.taskId}
          />
        )}

        {showCreateLinkTask && (
          <LinkTaskModal
            userList={userList}
            data={data}
            handleClose={() => setShowCreateLinkTask(false)}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}

        {showConcernModal && (
          <ConcernTaskModal
            data={data}
            refreshData={refreshData}
            handleClose={() => setShowConcernModal(false)}
            removeFromStore={removeFromStore}
          />
        )}

        {showApproveModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Approve Task</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      required
                      label="Approval Remark"
                      variant="outlined"
                      multiline
                      rows={4}
                      maxRows={7}
                      value={approvalRemark}
                      onChange={(e) => setApprovalRemark(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    if (approvalRemark === "") {
                      toast.error("Please Provide Approval Remark");
                      return;
                    }

                    const reqDto = {
                      taskDetailId: data.taskId,
                      taskForwordToUserIdAfterApproval: forwardTaskUser.userId,
                      approvalRmks: approvalRemark,
                    };

                    DashboardServices.approveTask(
                      loginUserDetail.userId,
                      reqDto
                    ).then((response) => {
                      if (response && response.data) {
                        toast.success(response.data.message);
                        dispatch(clearSelectedTask());
                        refreshData();
                      } else {
                        toast.error(response.data.message);
                      }
                    });
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowApproveModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showAssigneeList && (
          <AssigneeModal
            assigneeList={assigneeList}
            handleClose={() => setShowAssigneeList(false)}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Audio Message</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          </Box>
        )}
      </div> */}

      {/* {showAddTemplate && (
        <RightFlotingContainer
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={() => {
            setShowAddTemplate(false);
            if (addedTemplateList && addedTemplateList.length > 0) {
              const temp = [...addedTemplateList];
              temp.forEach((template) => {
                template.taskDetailId = data.taskId;
              });
              DashboardServices.addTaskTemplate(
                loginUserDetail.userId,
                addedTemplateList,
                "OLD"
              ).then((response) => {
                console.log("addTaskTemplate", response.data);
                const tempData = { ...data };
                if (tempData.templates) {
                  tempData.templates = [
                    ...tempData.templates,
                    ...addedTemplateList,
                  ];
                } else {
                  tempData.templates = [...addedTemplateList];
                }
                dispatch(setSelectedTask(tempData));
              });
            }
          }}
          mode={"old"}
        />
      )} */}

      {/* {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={() => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )} */}
    </>
  );
}
