import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../utils/DateUtils";
import {validateEmail} from "../../../../utils/Utils";
import { toast } from "react-toastify";
import {generateCouponCode} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";

export default function CreateCoupon({ handelCloseCreateCouponCode , refreshCouponCodeData }) {

  const userDetails = jwtDecode(localStorage.getItem("token"));

  const [loading , setLoading] = useState(false);


  const [couponPurpose , setCouponPurpose] = useState('');

  const handleCouponPurpose = (e) =>{
    console.log('handleCouponPurpose' , e.target.value);
    setCouponPurpose(e.target.value);
  };

  const [couponDiscountPercentage , setCouponDiscountPercentage] = useState('');

  const handleDiscountPercentage = (event) =>{
    console.log('handleDiscountPercentage' , event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setCouponDiscountPercentage(numericValue);
  };

  const [couponNumberOfUse , setCouponNumberOfUse] = useState('');

  const handleNumberOfUse = (event) =>{
    console.log('handleNumberOfUse' , event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setCouponNumberOfUse(numericValue);
  };

  const [startDate, setStartDate] = useState(new Date());

  const changeCurrentDate = (newDate) => {
    console.log("changeCurrentDate", newDate);
    setStartDate(newDate);
  };

  const [couponUserName , setCouponUserName] = useState('');

  const handleUserName = (e) =>{
    console.log('handleUserName' , e.target.value);
    setCouponUserName(e.target.value);
  };

  const [couponUserEmail , setCouponUserEmail] = useState('');

  const handleUserEmail = (e) =>{
    console.log('handleUserEmail' , e.target.value);
    setCouponUserEmail(e.target.value);
  };

  const [couponCustomCode , setCouponCustomCode] = useState('');

  const handleCustomCode = (e) =>{
    console.log('handleCustomCode' , e.target.value.toUpperCase());
    setCouponCustomCode(e.target.value.toUpperCase());
  };

  const finalSubmit = () =>{
    console.log('finalSubmit');
    setLoading(true);

    if (couponPurpose.trim() === "") {
      toast.error("Please Enter Purpose", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponDiscountPercentage.trim() === "") {
      toast.error("Please Enter Discount Precentage", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponDiscountPercentage < 1) {
      toast.error("Discount Precentage Cannot Be Less Then 1", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponDiscountPercentage > 100) {
      toast.error("Discount Precentage Cannot Be  More Then 100", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponNumberOfUse.trim() === "") {
      toast.error("Please Enter No of Use", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponNumberOfUse < 1) {
      toast.error("Useable Time Cannot Be Less Then 1", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponUserName.trim() === "") {
      toast.error("Please Enter Receiver Name", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponUserEmail.trim() === "") {
      toast.error("Please Enter Receiver Email Id", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (!validateEmail(couponUserEmail)) {
      toast.error("Please check the Receiver Email Id", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponCustomCode && couponCustomCode.trim() === "") {
      toast.error("Please Enter Custom Code", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponCustomCode && couponCustomCode.length < 5) {
      toast.error("Custom Code Cannot Be Less Then 5 Character", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponCustomCode && couponCustomCode.length > 10) {
      toast.error("Custom Code Cannot Be More Then 10 Character", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    const reqDto = {
      purpose: couponPurpose,
      discountPc: couponDiscountPercentage,
      noOfTimesUseable: couponNumberOfUse,
      expireDate:DateUtils.getDateInDDMMYYYY(startDate),
      couponReceiverName : couponUserName ,
      couponReceiverMailId: couponUserEmail,
      transType: "ORGANIZATION_ONBOARD",
      coustomCode: couponCustomCode,
      activeFlag: "Y"
    }

    console.log('reqDto',reqDto);

    // return;

    generateCouponCode(
      userDetails.userId,
      reqDto
    ).then((response) => {
      console.log(response.data);
      setLoading(false);
      if (response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        refreshCouponCodeData();
        setCouponPurpose('');
        setCouponDiscountPercentage('');
        setCouponNumberOfUse('');
        setStartDate(new Date());
        setCouponUserName('');
        setCouponUserEmail('');
        setCouponCustomCode('');
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  }


  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">Generate Discount Coupon</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseCreateCouponCode()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (

        <div className="elementFormContainer">
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Purpose"
                required
                variant="outlined"
                value={couponPurpose}
                className="formTextFieldArea"
                onChange={handleCouponPurpose}
              />
            </FormControl>
          </div>
          <div className="formElementGrp">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label="Discount Precentage"
                  required
                  variant="outlined"
                  value={couponDiscountPercentage}
                  className="formTextFieldArea"
                  onChange={handleDiscountPercentage}
                  placeholder="discount (1-100 %)"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label="No of Use"
                  required
                  variant="outlined"
                  value={couponNumberOfUse}
                  className="formTextFieldArea"
                  onChange={handleNumberOfUse}
                  placeholder="No of Use(1-1000)"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="fromDate" className="setCompletionDate">
                  Expires On
                </InputLabel>
                <ReactDatePicker
                  labelId="fromDate"
                  className="formDatePicker"
                  dateFormat="dd/MM/yyyy"
                  showIcon
                  minDate={new Date()}
                  selected={startDate}
                  onChange={(date) => changeCurrentDate(date)}
                />
              </FormControl>
            </div>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Receiver Name"
                required
                variant="outlined"
                value={couponUserName}
                className="formTextFieldArea"
                onChange={handleUserName}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Receiver Email Id"
                required
                variant="outlined"
                value={couponUserEmail}
                className="formTextFieldArea"
                onChange={handleUserEmail}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Custom Code"
                variant="outlined"
                value={couponCustomCode}
                className="formTextFieldArea"
                onChange={handleCustomCode}
                placeholder="Enter code (5-10 characters)"
              />
            </FormControl>
          </div>
        </div>

        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button className="dfultPrimaryBtn" onClick={()=>finalSubmit()}  disabled={loading}>
            {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>Save</>
              )}
              </Button>
              {!loading && (
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handelCloseCreateCouponCode()}
            >
              Cancel
            </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
