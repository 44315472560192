import { Add, ArrowBack, Edit, Search } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React from "react";
import { Trash } from "react-bootstrap-icons";

export default function ViewRegisterPatient({
  handelCloseViewRegisterPatient,
  handelOpenAddRegisterPatient,
  closeAll,
}) {
  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              handelCloseViewRegisterPatient();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          Register Patient
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder="Search Patient"
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddRegisterPatient()}
                >
                  Register Patient
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              <div className="TableContainer">
                <table className="taskListTable">
                  <thead className="taskListtableHeader">
                    <tr>
                      <th>Name</th>
                      <th>Gender</th>
                      <th>DOB</th>
                      <th>Contact No</th>
                      <th>Email Id</th>
                      <th className="width180"></th>
                    </tr>
                  </thead>
                  <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">Mr. Sanket Santra</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Male</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">25/Dec/1990</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">+01 58456XXX52</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          uixxxxxxxxxxer@zoyel.com
                        </div>
                      </td>

                      <td>
                        <div className="tblActionBtnGrp">
                          <Button className="dfultPrimaryBtn ">
                            Book Consultation
                          </Button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">Miss. Amelia</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Female</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">02/Jan/1992</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">+01 45456XXX41</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          axxxxxxxxxxxxx@zoyel.com
                        </div>
                      </td>

                      <td>
                        <div className="tblActionBtnGrp">
                          <Button className="dfultPrimaryBtn ">
                            Book Consultation
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
