import React, { useState } from "react";
import "../css/email.css";
import { Box, Grid } from "@mui/material";
import EmailContainer from "./EmailContainer";
import EmailsView from "./EmailsView";
export default function EmailContainerWrapper() {
  const [showEmailView, setShowEmailView] = useState(false);
  return (
    <>
      <Box className="emailContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
        <Grid container spacing={0} className="emailContainArea">
          <Grid item xs={12} sx={{ color: "dark.contrastText" }}>
            <EmailContainer />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
