import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export default function ConfirmDialog({
  open,
  title,
  message,
  onCancel,
  onOk,
  children,
}) {
  return (
    <>
      <Dialog open={open}>
        <DialogTitle className="confirmDlgTitle">
          <p>{title}</p>
        </DialogTitle>

        <DialogContent className="confirmDlgContent">
          <p>{message}</p>
        </DialogContent>

        <DialogContent className="confirmDlgChildren">
          <p>{children}</p>
        </DialogContent>

        <DialogActions>
          <Button onClick={onCancel} className="dfultDarkSecondaryBtn">
            Cancel
          </Button>
          <Button onClick={onOk} className="dfultPrimaryBtn">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
