import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { CustomModal } from "../../../task/view/component/Modals/CustomModal";
import { forEach } from "jszip";

export const KRAevdModal = ({
  open,
  onClose,
  kraData,
  positionData,
  updateData,
}) => {
  const [previewData, setPreviewData] = useState(null);
  const [kraDataCopy, setKraDataCopy] = useState(null);
  const [showData, setShowData] = useState(false);
  const [newKra, setNewKra] = useState(null);
  const [kraModalData, setKraModalData] = useState(null);
  const [showKraModal, setShowKraModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);

  const [kpiModalData, setKpiModalData] = useState(null);
  const [showKpiModal, setShowKpiModal] = useState(false);
  const [modalKpi, setModalKpi] = useState(null);

  const [kraSingle, setKraSingle] = useState(null);
  const [previewDataList, setPreviewDataList] = useState(null);

  // useEffect(() => {
  //   if (kraDataCopy && kraDataCopy.length > 0) {
  //     const processedData = groupByKpiAndTitle(kraDataCopy);
  //     setPreviewData(processedData);
  //   }
  // }, [kraDataCopy]);

  useEffect(() => {
    let tempData = [];
    console.log("position data =", positionData);
    if (kraData && kraData.length > 0) {
      setKraDataCopy(kraData.map((kra) => ({ ...kra, tempId: uuidv4() })));
      setShowData(true);
      tempData = kraData;
    } else {
      const newData = {
        kpiId: 0,
        positionId: positionData.positionId,
        kpi: null,
        title: null,
        target: null,
        targetId: uuidv4(),
      };
      setKraSingle(newData);
      tempData = [newData];
    }
    const processedData = groupByKpiAndTitle(tempData);
    setPreviewData(processedData);
  }, [kraData]);

  const groupByKpiAndTitle = (data) => {
    const dta = data.reduce((acc, item) => {
      const { target, title, kpi } = item;
      if (!acc[title]) {
        acc[title] = {};
        // acc[title][kpi] = kpi;
      }
      if (!acc[title][kpi]) {
        acc[title][kpi] = [];
      }
      acc[title][kpi].push(item);
      return acc;
    }, {});
    console.log("data = ", dta);
    let tempList = [];
    for (let index = 0; index < Object.keys(dta).length; index++) {
      const element = Object.keys(dta)[index];
      const kpis = Object.keys(dta[element]);
      let values = [];
      kpis.forEach((kpi) => {
        let kpiData = dta[element][kpi];
        kpiData = kpiData.map((kpiDataItem) => ({
          ...kpiDataItem,
          targetId: uuidv4(),
        }));
        values.push({ kpiId: uuidv4(), kpi: kpi, data: kpiData });
      });
      tempList.push({ titleId: uuidv4(), title: element, values: values });
    }
    console.log("tempppp", tempList);
    setPreviewDataList(tempList);
    return dta;
  };
  return (
    <>
      <div className="bgOfset">
        <div className="bgOfsetOverlay"></div>
        <Box className="ModelBox width800" sx={{ boxShadow: 24, p: 4 }}>
          <div className="addMoreAgendaModel">
            <div className="modelTitle">Job KRA</div>
            <div className="JDKRAContainer">
              <div className="jdkraHeader">
                <div className="jdkTitle">
                  Position:
                  <span>
                    {positionData.position === null ||
                    positionData.position === ""
                      ? `${positionData.designationName},${positionData.functionName}`
                      : positionData.position}
                  </span>
                </div>
                {previewData && showData && (
                  <div className="jdkActBtn">
                    <IconButton
                      onClick={() => {
                        setShowData(false);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                )}
              </div>

              {!showData && (
                <div className="JDKRAContainEdit">
                  {previewDataList && (
                    <ol className="">
                      {previewDataList.map((item, index) => {
                        const { title, values, titleId } = item;

                        // let { kpi } = previewData[title];
                        // let filteredData = previewData[title]["data"];
                        return (
                          <li className="topList" key={titleId}>
                            <div className="jdFromElement">
                              <div className="KratitleAdd">
                                <div className="formElement">
                                  <FormControl className="formControl">
                                    <TextField
                                      required
                                      className="modelTextFild"
                                      label="Enter Title"
                                      variant="outlined"
                                      multiline
                                      value={
                                        title === null || title === "null"
                                          ? ""
                                          : title
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value;

                                        const presentIndex =
                                          previewDataList.findIndex(
                                            (itm) => itm.title === value
                                          );

                                        if (presentIndex !== -1) {
                                          toast.error("key already present");
                                          setKraModalData({
                                            currentData: title,
                                            inputData: value,
                                          });
                                          setModalTitle(value);
                                          setShowKraModal(true);
                                          return;
                                        }

                                        const tempPrevList = [
                                          ...previewDataList,
                                        ];
                                        let tempTitle =
                                          title === "null" || title === null
                                            ? null
                                            : title;
                                        const titleIndex =
                                          previewDataList.findIndex(
                                            (itm) => itm.titleId === titleId
                                          );
                                        if (titleIndex >= 0) {
                                          tempPrevList[titleIndex].title =
                                            value;
                                        }
                                        setPreviewDataList(tempPrevList);
                                        setShowData(false);
                                      }}
                                    />
                                  </FormControl>
                                </div>

                                {index === previewDataList.length - 1 && (
                                  <IconButton
                                    onClick={() => {
                                      if (
                                        title === null ||
                                        title === "null" ||
                                        title === ""
                                      ) {
                                        toast.error("Please provide title");
                                        return;
                                      }

                                      const dto = {
                                        titleId: uuidv4(),
                                        title: null,
                                        values: [
                                          {
                                            kpi: null,
                                            kpiId: uuidv4(),
                                            data: [
                                              {
                                                kpiId: 0,
                                                positionId:
                                                  positionData.positionId,
                                                kpi: null,
                                                title: null,
                                                target: null,
                                                slNo: 0,
                                                targetId: uuidv4(),
                                              },
                                            ],
                                          },
                                        ],
                                      };

                                      const temp = [...previewDataList];
                                      temp.push(dto);
                                      setPreviewDataList(temp);
                                    }}
                                  >
                                    <Add />
                                  </IconButton>
                                )}
                                {previewDataList.length > 1 && (
                                  <IconButton
                                    onClick={() => {
                                      let temp = previewDataList.filter(
                                        (tempItem) =>
                                          tempItem.titleId !== titleId
                                      );
                                      setPreviewDataList(temp);
                                    }}
                                  >
                                    <Trash />
                                  </IconButton>
                                )}
                              </div>
                              <ul>
                                {values &&
                                  values.map((kpiObj, kpiIndex) => {
                                    const { kpi, kpiId } = kpiObj;
                                    const itemObj = kpiObj.data;
                                    return (
                                      <>
                                        <li key={kpiId}>
                                          <div className="subElement">
                                            <div className="subElmntHead">
                                              KPI
                                            </div>
                                            <div className="subElmntList">
                                              <div className="formElement">
                                                <FormControl className="formControl">
                                                  <TextField
                                                    required
                                                    className="modelTextFild"
                                                    label="Enter KPI"
                                                    variant="outlined"
                                                    multiline
                                                    value={
                                                      kpi === "null" ? "" : kpi
                                                    }
                                                    onChange={(e) => {
                                                      const value =
                                                        e.target.value;
                                                      const temp = [
                                                        ...previewDataList,
                                                      ];

                                                      const titleIndex =
                                                        temp.findIndex(
                                                          (item) =>
                                                            item.titleId ===
                                                            titleId
                                                        );
                                                      if (titleIndex >= 0) {
                                                        const { values } =
                                                          temp[titleIndex];
                                                        const kraItemIndex =
                                                          values.findIndex(
                                                            (item) =>
                                                              item.kpi === value
                                                          );
                                                        if (kraItemIndex >= 0) {
                                                          toast.error(
                                                            "key already present"
                                                          );
                                                          setKpiModalData({
                                                            currentData: title,
                                                            inputData: value,
                                                            kpiId,
                                                            titleId,
                                                          });
                                                          setModalKpi(value);
                                                          setShowKpiModal(true);
                                                          return;
                                                        }
                                                      }

                                                      temp.forEach(
                                                        (item, titleIndx) => {
                                                          const tempValues =
                                                            item.values;
                                                          tempValues.forEach(
                                                            (
                                                              kpiItem,
                                                              kpiIndex
                                                            ) => {
                                                              const tempKpiId =
                                                                kpiItem.kpiId;
                                                              if (item.title)
                                                                if (
                                                                  tempKpiId ===
                                                                  kpiId
                                                                ) {
                                                                  temp[
                                                                    titleIndx
                                                                  ].values[
                                                                    kpiIndex
                                                                  ].kpi = value;
                                                                }
                                                            }
                                                          );
                                                        }
                                                      );
                                                      setPreviewDataList(temp);
                                                    }}
                                                  />
                                                </FormControl>
                                              </div>

                                              <div className="jdKraBtnFlex">
                                                {kpiIndex ===
                                                  values.length - 1 && (
                                                  <Tooltip
                                                    arrow
                                                    title="Add New Targets"
                                                  >
                                                    <IconButton
                                                      onClick={() => {
                                                        if (
                                                          item.target ===
                                                            "null" ||
                                                          item.target ===
                                                            null ||
                                                          item.target === ""
                                                        ) {
                                                          toast.error(
                                                            "Please Enter target"
                                                          );
                                                          return;
                                                        }

                                                        const temp = [
                                                          ...previewDataList,
                                                        ];

                                                        const titleIndex =
                                                          temp.findIndex(
                                                            (item) =>
                                                              item.titleId ===
                                                              titleId
                                                          );
                                                        if (titleIndex >= 0) {
                                                          temp[
                                                            titleIndex
                                                          ].values.push({
                                                            kpiId: uuidv4(),
                                                            kpi: null,
                                                            data: [
                                                              {
                                                                kpiId: 0,
                                                                positionId:
                                                                  positionData.positionId,
                                                                kpi: null,
                                                                title: title,
                                                                target: null,
                                                                slNo: 0,
                                                                targetId:
                                                                  uuidv4(),
                                                              },
                                                            ],
                                                          });
                                                        }

                                                        setPreviewDataList(
                                                          temp
                                                        );
                                                      }}
                                                    >
                                                      <Add />
                                                    </IconButton>
                                                  </Tooltip>
                                                )}

                                                {values.length > 1 && (
                                                  <IconButton
                                                    onClick={() => {
                                                      const temp = [
                                                        ...previewDataList,
                                                      ];
                                                      temp.forEach(
                                                        (
                                                          titleObj,
                                                          titleIndex
                                                        ) => {
                                                          const { values } =
                                                            titleObj;

                                                          const kpiIndex =
                                                            values.findIndex(
                                                              (item) =>
                                                                item.kpiId ===
                                                                kpiId
                                                            );
                                                          if (kpiIndex >= 0) {
                                                            values.splice(
                                                              kpiIndex,
                                                              1
                                                            );
                                                          }
                                                        }
                                                      );

                                                      setKraDataCopy(temp);
                                                    }}
                                                  >
                                                    <Trash />
                                                  </IconButton>
                                                )}
                                              </div>
                                            </div>
                                            <div className="subElement">
                                              <div className="subElmntHead">
                                                Targets
                                              </div>
                                              <ul>
                                                {itemObj &&
                                                  itemObj.map(
                                                    (item, targetIndex) => {
                                                      const { targetId } = item;
                                                      return (
                                                        <>
                                                          <li
                                                            key={item.targetId}
                                                          >
                                                            <div className="subElmntList">
                                                              <div className="formElement">
                                                                <FormControl className="formControl">
                                                                  <TextField
                                                                    required
                                                                    className="modelTextFild"
                                                                    label="Enter Targets"
                                                                    variant="outlined"
                                                                    multiline
                                                                    value={
                                                                      item.target ===
                                                                        null ||
                                                                      item.target ===
                                                                        "null"
                                                                        ? ""
                                                                        : item.target
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      const value =
                                                                        e.target
                                                                          .value;
                                                                      const temp =
                                                                        [
                                                                          ...previewDataList,
                                                                        ];

                                                                      temp.forEach(
                                                                        (
                                                                          item,
                                                                          titleIndex
                                                                        ) => {
                                                                          const {
                                                                            values,
                                                                          } =
                                                                            item;
                                                                          values.forEach(
                                                                            (
                                                                              kpiObj,
                                                                              kpiIndex
                                                                            ) => {
                                                                              const {
                                                                                data,
                                                                              } =
                                                                                kpiObj;
                                                                              data.forEach(
                                                                                (
                                                                                  dataItem,
                                                                                  dataIndex
                                                                                ) => {
                                                                                  if (
                                                                                    dataItem.targetId ===
                                                                                    targetId
                                                                                  ) {
                                                                                    temp[
                                                                                      titleIndex
                                                                                    ].values[
                                                                                      kpiIndex
                                                                                    ].data[
                                                                                      dataIndex
                                                                                    ].target =
                                                                                      value;
                                                                                  }
                                                                                }
                                                                              );
                                                                            }
                                                                          );
                                                                        }
                                                                      );

                                                                      setPreviewDataList(
                                                                        temp
                                                                      );
                                                                    }}
                                                                  />
                                                                </FormControl>
                                                              </div>
                                                              <div className="jdKraBtnFlex">
                                                                {targetIndex ===
                                                                  itemObj.length -
                                                                    1 && (
                                                                  <Tooltip
                                                                    arrow
                                                                    title="Add New Targets"
                                                                  >
                                                                    <IconButton
                                                                      onClick={() => {
                                                                        if (
                                                                          item.target ===
                                                                            "null" ||
                                                                          item.target ===
                                                                            null ||
                                                                          item.target ===
                                                                            ""
                                                                        ) {
                                                                          toast.error(
                                                                            "Please Enter target"
                                                                          );
                                                                          return;
                                                                        }

                                                                        const temp =
                                                                          [
                                                                            ...previewDataList,
                                                                          ];

                                                                        temp.forEach(
                                                                          (
                                                                            titleObj,
                                                                            titleIndex
                                                                          ) => {
                                                                            const {
                                                                              values,
                                                                              title,
                                                                            } =
                                                                              titleObj;
                                                                            values.forEach(
                                                                              (
                                                                                item
                                                                              ) => {
                                                                                const {
                                                                                  data,
                                                                                } =
                                                                                  item;
                                                                                if (
                                                                                  item.kpi ===
                                                                                  kpi
                                                                                ) {
                                                                                  data.push(
                                                                                    {
                                                                                      kpiId: 0,
                                                                                      positionId:
                                                                                        positionData.positionId,
                                                                                      kpi: kpi,
                                                                                      title:
                                                                                        title,
                                                                                      target:
                                                                                        null,
                                                                                      slNo: 0,
                                                                                      targetId:
                                                                                        uuidv4(),
                                                                                    }
                                                                                  );
                                                                                }
                                                                              }
                                                                            );
                                                                          }
                                                                        );
                                                                        setPreviewDataList(
                                                                          temp
                                                                        );
                                                                      }}
                                                                    >
                                                                      <Add />
                                                                    </IconButton>
                                                                  </Tooltip>
                                                                )}

                                                                {itemObj.length >
                                                                  1 && (
                                                                  <IconButton
                                                                    onClick={() => {
                                                                      const temp =
                                                                        [
                                                                          ...previewDataList,
                                                                        ];
                                                                      temp.forEach(
                                                                        (
                                                                          titleObj,
                                                                          titleIndex
                                                                        ) => {
                                                                          const {
                                                                            values,
                                                                            title,
                                                                          } =
                                                                            titleObj;
                                                                          values.forEach(
                                                                            (
                                                                              item
                                                                            ) => {
                                                                              const {
                                                                                data,
                                                                              } =
                                                                                item;
                                                                              const dataIndex =
                                                                                data.findIndex(
                                                                                  (
                                                                                    dataItem
                                                                                  ) =>
                                                                                    dataItem.targetId ===
                                                                                    targetId
                                                                                );
                                                                              if (
                                                                                dataIndex >=
                                                                                0
                                                                              ) {
                                                                                data.splice(
                                                                                  dataIndex,
                                                                                  1
                                                                                );
                                                                              }
                                                                            }
                                                                          );
                                                                        }
                                                                      );

                                                                      setKraDataCopy(
                                                                        temp
                                                                      );
                                                                    }}
                                                                  >
                                                                    <Trash />
                                                                  </IconButton>
                                                                )}
                                                              </div>
                                                            </div>
                                                          </li>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                              </ul>
                                            </div>
                                          </div>
                                        </li>
                                      </>
                                    );
                                  })}
                              </ul>
                            </div>
                          </li>
                        );
                      })}
                    </ol>
                  )}
                  {/* {previewData && (
                    <ol className="">
                      {Object.keys(previewData).map((title, index) => {
                        let { kpi } = previewData[title];
                        let filteredData = previewData[title]["data"];
                        return (
                          <li className="topList">
                            <div className="jdFromElement">
                              <div className="KratitleAdd">
                                <div className="formElement">
                                  <FormControl className="formControl">
                                    <TextField
                                      required
                                      className="modelTextFild"
                                      label="Enter Title"
                                      variant="outlined"
                                      multiline
                                      value={
                                        title === null || title === "null"
                                          ? ""
                                          : title
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value;

                                        const keys = Object.keys(previewData);

                                        const presentIndex = keys.findIndex(
                                          (key) => key === value
                                        );
                                        if (presentIndex !== -1) {
                                          toast.error("key already present");
                                          setKraModalData({
                                            currentData: title,
                                            inputData: value,
                                          });
                                          setModalTitle(value);
                                          setShowKraModal(true);
                                          return;
                                        }

                                        const temp = [...kraDataCopy];
                                        temp.forEach((item) => {
                                          const tempTitle =
                                            title === "null" ? null : title;
                                          if (item.title === tempTitle) {
                                            item.title = value;
                                          }
                                        });
                                        setKraDataCopy(temp);
                                        setShowData(false);
                                      }}
                                    />
                                  </FormControl>
                                </div>

                                {index ===
                                  Object.keys(previewData).length - 1 && (
                                  <IconButton
                                    onClick={() => {
                                      if (
                                        title === null ||
                                        title === "null" ||
                                        title === ""
                                      ) {
                                        toast.error("Please provide title");
                                        return;
                                      }

                                      const reqDto = {
                                        kpiId: 0,
                                        positionId: positionData.positionId,
                                        kpi: null,
                                        title: null,
                                        target: null,
                                        tempId: uuidv4(),
                                      };

                                      setKraDataCopy((prev) => {
                                        const temp = [...prev];
                                        temp.push(reqDto);
                                        return temp;
                                      });
                                    }}
                                  >
                                    <Add />
                                  </IconButton>
                                )}
                                {Object.keys(previewData).length > 1 && (
                                  <IconButton
                                    onClick={() => {
                                      let temp = [...kraDataCopy].filter(
                                        (tempItem) =>
                                          !(
                                            tempItem.title === title ||
                                            (tempItem.title === null &&
                                              title === "null")
                                          )
                                      );
                                      setKraDataCopy(temp);
                                    }}
                                  >
                                    <Trash />
                                  </IconButton>
                                )}
                              </div>
                              <ul>
                                <li>
                                  <div className="subElement">
                                    <div className="subElmntHead">KPI</div>
                                    <div className="formElement">
                                      <FormControl className="formControl">
                                        <TextField
                                          required
                                          className="modelTextFild"
                                          label="Enter KPI"
                                          variant="outlined"
                                          multiline
                                          value={kpi}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            const temp = [...kraDataCopy];
                                            temp.forEach((item) => {
                                              if (
                                                item.title === title &&
                                                item.kpi === kpi
                                              ) {
                                                item.kpi = value;
                                              }
                                            });
                                            setKraDataCopy(temp);
                                          }}
                                        />
                                      </FormControl>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="subElement">
                                    <div className="subElmntHead">Targets</div>
                                    <ul>
                                      {filteredData.map(
                                        (targetItem, subindex) => {
                                          return (
                                            <li key={targetItem.tempId}>
                                              <div className="subElmntList">
                                                <div className="formElement">
                                                  <FormControl className="formControl">
                                                    <TextField
                                                      required
                                                      className="modelTextFild"
                                                      label="Enter Targets"
                                                      variant="outlined"
                                                      multiline
                                                      value={
                                                        targetItem.target ===
                                                          null ||
                                                        targetItem.target ===
                                                          "null"
                                                          ? ""
                                                          : targetItem.target
                                                      }
                                                      onChange={(e) => {
                                                        const value =
                                                          e.target.value;
                                                        const temp = [
                                                          ...kraDataCopy,
                                                        ];
                                                        temp.forEach((item) => {
                                                          const tempTitle =
                                                            title === "null"
                                                              ? null
                                                              : title;

                                                          const tempTarget =
                                                            targetItem.target ===
                                                            "null"
                                                              ? null
                                                              : targetItem.target;

                                                          if (
                                                            item.tempId ===
                                                            targetItem.tempId
                                                          ) {
                                                            item.target = value;
                                                          }
                                                        });
                                                        setKraDataCopy(temp);
                                                      }}
                                                    />
                                                  </FormControl>
                                                </div>
                                                <div className="jdKraBtnFlex">
                                                  {subindex ===
                                                    Object.keys(filteredData)
                                                      .length -
                                                      1 && (
                                                    <Tooltip
                                                      arrow
                                                      title="Add New Targets"
                                                    >
                                                      <IconButton
                                                        onClick={() => {
                                                          if (
                                                            targetItem.target ===
                                                              "null" ||
                                                            targetItem.target ===
                                                              null ||
                                                            targetItem.target ===
                                                              ""
                                                          ) {
                                                            toast.error(
                                                              "Please Enter target"
                                                            );
                                                            return;
                                                          }
                                                          const reqDto = {
                                                            kpiId: 0,
                                                            positionId:
                                                              positionData.positionId,
                                                            kpi: kpi,
                                                            title: title,
                                                            target: null,
                                                            tempId: uuidv4(),
                                                          };
                                                          setKraDataCopy(
                                                            (prev) => {
                                                              const temp = [
                                                                ...prev,
                                                              ];
                                                              temp.push(reqDto);
                                                              return temp;
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        <Add />
                                                      </IconButton>
                                                    </Tooltip>
                                                  )}

                                                  {Object.keys(filteredData)
                                                    .length > 1 && (
                                                    <IconButton
                                                      onClick={() => {
                                                        // const temp = [
                                                        //   ...kraDataCopy,
                                                        // ].filter((tempItem) => {
                                                        //
                                                        //   return !(
                                                        //     tempItem.title ===
                                                        //       title &&
                                                        //     (tempItem.target ===
                                                        //       target ||
                                                        //       (target ===
                                                        //         "null" &&
                                                        //         tempItem.target ===
                                                        //           null))
                                                        //   );
                                                        // });
                                                        //
                                                        const temp =
                                                          kraDataCopy.filter(
                                                            (tempItem) => {
                                                              //
                                                              return (
                                                                tempItem.tempId !==
                                                                targetItem.tempId
                                                              );
                                                            }
                                                          );

                                                        setKraDataCopy(temp);
                                                      }}
                                                    >
                                                      <Trash />
                                                    </IconButton>
                                                  )}
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        );
                      })}
                    </ol>
                  )} */}

                  {/* {!previewData && kraSingle && (
                    <div className="jdFromElement">
                      <div className="KratitleAdd">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              required
                              className="modelTextFild"
                              label="Enter Title"
                              variant="outlined"
                              multiline
                              value={kraSingle.title || ""}
                              onChange={(e) => {
                                const value = e.target.value;
                                setKraSingle((prev) => ({
                                  ...prev,
                                  title: value,
                                }));
                              }}
                            />
                          </FormControl>
                        </div>
                        <IconButton
                          onClick={() => {
                            let isValid = true;
                            const { title, kpi, target } = kraSingle;

                            if (
                              title === null ||
                              title === "null" ||
                              title === ""
                            ) {
                              toast.error("Please Add Title");
                              isValid = false;
                            }
                            if (kpi === null || kpi === "null" || kpi === "") {
                              toast.error("Please Add KPI");
                              isValid = false;
                            }
                            if (
                              target === null ||
                              target === "null" ||
                              target === ""
                            ) {
                              toast.error("Please add Target");
                              isValid = false;
                            }

                            if (!isValid) {
                              return;
                            }

                            setKraDataCopy([
                              kraSingle,
                              {
                                kpiId: 0,
                                positionId: positionData.positionId,
                                kpi: null,
                                title: null,
                                target: null,
                              },
                            ]);
                            setKraSingle(null);
                          }}
                        >
                          <Add />
                        </IconButton>
                      </div>
                      <ul>
                        <li>
                          <div className="subElement">
                            <div className="subElmntHead">KPI</div>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  required
                                  className="modelTextFild"
                                  label="Enter KPI"
                                  variant="outlined"
                                  multiline
                                  value={kraSingle.kpi || ""}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setKraSingle((prev) => ({
                                      ...prev,
                                      kpi: value,
                                    }));
                                  }}
                                />
                              </FormControl>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="subElement">
                            <div className="subElmntHead">Targets</div>
                            <ul>
                              <li>
                                <div className="subElmntList">
                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <TextField
                                        required
                                        className="modelTextFild"
                                        label="Enter Targets"
                                        variant="outlined"
                                        multiline
                                        value={kraSingle.target || ""}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setKraSingle((prev) => ({
                                            ...prev,
                                            target: value,
                                          }));
                                        }}
                                      />
                                    </FormControl>
                                  </div>
                                  <Tooltip arrow title="Add New Targets">
                                    <IconButton
                                      onClick={() => {
                                        let isValid = true;
                                        const { title, kpi, target } =
                                          kraSingle;

                                        if (
                                          title === null ||
                                          title === "null" ||
                                          title === ""
                                        ) {
                                          toast.error("Please Add Title");
                                          isValid = false;
                                        }
                                        if (
                                          kpi === null ||
                                          kpi === "null" ||
                                          kpi === ""
                                        ) {
                                          toast.error("Please Add KPI");
                                          isValid = false;
                                        }
                                        if (
                                          target === null ||
                                          target === "null" ||
                                          target === ""
                                        ) {
                                          toast.error("Please add Target");
                                          isValid = false;
                                        }

                                        if (!isValid) {
                                          return;
                                        }
                                        setKraDataCopy([
                                          kraSingle,
                                          {
                                            kpiId: 0,
                                            positionId: positionData.positionId,
                                            kpi: kraSingle.kpi,
                                            title: kraSingle.title,
                                            target: null,
                                          },
                                        ]);
                                        setKraSingle(null);
                                      }}
                                    >
                                      <Add />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )} */}
                </div>
              )}

              {showData && previewDataList && (
                <div className="JDKRAContainEdit">
                  <ol className="">
                    {previewDataList.map((item, index) => {
                      // let { title, values } = item;
                      // console.log("Values are", item);
                      // console.log("Values are 1", values);
                      let title = item.title;
                      let values = item.values;
                      return (
                        <>
                          <li className="topList">
                            <div className="jdFromElement">
                              <div className="KratitleAdd">
                                <div className="formElement">
                                  <p>{title}</p>
                                </div>
                              </div>
                              <ul>
                                {values.map((kpiObj) => {
                                  const kpi = kpiObj.kpi;
                                  const dataObjs = kpiObj.data;

                                  return (
                                    <>
                                      <li>
                                        <div className="subElement">
                                          <div className="subElmntHead">
                                            KPI
                                          </div>
                                          <div className="formElement">
                                            <p>{kpi}</p>
                                          </div>

                                          <div className="subElement">
                                            <div className="subElmntHead">
                                              Targets
                                            </div>
                                            <ul>
                                              {dataObjs &&
                                                dataObjs.map((dataItem) => {
                                                  return (
                                                    <li>
                                                      <div className="subElmntList">
                                                        <div className="formElement">
                                                          <p>
                                                            {dataItem.target}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  );
                                                })}
                                            </ul>
                                          </div>
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                            </div>
                          </li>
                        </>
                      );
                    })}
                    {/* <li className="topList">
                    <div className="jdFromElement">
                      <div className="KratitleAdd">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              required
                              className="modelTextFild"
                              label="Enter Title"
                              variant="outlined"
                              multiline
                              value=""
                            />
                          </FormControl>
                        </div>
                        <IconButton>
                          <Add />
                        </IconButton>
                      </div>
                      <ul>
                        <li>
                          <div className="subElement">
                            <div className="subElmntHead">KPI</div>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  required
                                  className="modelTextFild"
                                  label="Enter KPI"
                                  variant="outlined"
                                  multiline
                                  value=""
                                />
                              </FormControl>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="subElement">
                            <div className="subElmntHead">Targets</div>
                            <ul>
                              <li>
                                <div className="subElmntList">
                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <TextField
                                        required
                                        className="modelTextFild"
                                        label="Enter Targets"
                                        variant="outlined"
                                        multiline
                                        value=""
                                      />
                                    </FormControl>
                                  </div>
                                  <Tooltip arrow title="Add New Targets">
                                    <IconButton>
                                      <Add />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li> */}
                  </ol>
                </div>
              )}
            </div>
            <div className="modActionBtnGrp">
              {!showData && (
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    let isValid = true;
                    let tempData = [];

                    if (previewDataList) {
                      previewDataList.forEach((item, index) => {
                        const { title } = item;
                        if (
                          title === null ||
                          title === "" ||
                          title === "null"
                        ) {
                          isValid = false;
                          toast.error(
                            `Plese enter title at index ${index + 1}`
                          );
                        }
                      });

                      if (!isValid) {
                        return;
                      }

                      previewDataList.forEach((item, index) => {
                        const { title, values } = item;
                        values.forEach((kpiObj, kpiIndex) => {
                          const { kpi } = kpiObj;
                          if (kpi === "null" || kpi === null || kpi === "") {
                            isValid = false;
                            toast.error(`Please Provide kpi for ${title}`);
                          }
                        });
                      });

                      if (!isValid) {
                        return;
                      }

                      previewDataList.forEach((item, index) => {
                        const { title, values } = item;
                        values.forEach((kpiObj, kpiIndex) => {
                          const { kpi, data } = kpiObj;
                          data.forEach((dataObj) => {
                            if (
                              dataObj.target === "" ||
                              dataObj.target === null ||
                              dataObj.target === "null"
                            ) {
                              isValid = false;
                              toast.error("Please Provide Target");
                            }
                          });
                        });
                      });

                      if (!isValid) {
                        return;
                      }

                      previewDataList.forEach((item, index) => {
                        const { title, values } = item;
                        values.forEach((kpiObj, kpiIndex) => {
                          const { kpi, data } = kpiObj;
                          data.forEach((dataItem) => {
                            const { targetId, ...dataObj } = dataItem;
                            tempData.push({ ...dataObj, kpi, title });
                          });
                        });
                      });

                      console.log("TempData = ", tempData);
                    }

                    // if (kraSingle) {
                    //   if (
                    //     kraSingle.title === null ||
                    //     kraSingle.title === "null" ||
                    //     kraSingle.title === ""
                    //   ) {
                    //     toast.error("Please Add Title");
                    //     return;
                    //   }
                    //   if (
                    //     kraSingle.kpi === null ||
                    //     kraSingle.kpi === "null" ||
                    //     kraSingle.kpi === ""
                    //   ) {
                    //     toast.error("Please Add KPI");
                    //     return;
                    //   }
                    //   if (
                    //     kraSingle.target === null ||
                    //     kraSingle.target === "null" ||
                    //     kraSingle.target === ""
                    //   ) {
                    //     toast.error("Please add Target");
                    //     return;
                    //   }
                    // }

                    // setShowData(true);
                    // onClose()
                    // if (kraSingle) {
                    //   setKraDataCopy([
                    //     { ...kraSingle, positionId: positionData.positionId },
                    //   ]);
                    // }
                    // const tempData = kraSingle
                    //   ? [{ ...kraSingle, positionId: positionData.positionId }]
                    //   : kraDataCopy;

                    updateData([...tempData]);
                    onClose();
                  }}
                >
                  Save
                </Button>
              )}
              <Button className="dfultDarkSecondaryBtn" onClick={onClose}>
                Close
              </Button>
            </div>
          </div>
        </Box>
      </div>
      {showKraModal && (
        <CustomModal
          modalTitle="Title Conflict! Title Already Exist Consider Changing"
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowKraModal(false);
          }}
          className={""}
        >
          <div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  className="modelTextFild"
                  label="Enter Title"
                  variant="outlined"
                  multiline
                  value={modalTitle}
                  onChange={(e) => {
                    setModalTitle(e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <Button
              onClick={() => {
                if (modalTitle === kraModalData.inputData) {
                  toast.error("Title Already present");
                  return;
                } else {
                  const temp = [...previewDataList];

                  temp.forEach((item) => {
                    const tempTitle =
                      kraModalData.currentData === "null"
                        ? null
                        : kraModalData.currentData;
                    if (item.title === tempTitle) {
                      item.title = modalTitle;
                    }
                  });
                  setPreviewDataList(temp);
                  setShowKraModal(false);
                  setModalTitle(null);
                  setKraModalData(null);
                }
              }}
            >
              Save
            </Button>
          </div>
        </CustomModal>
      )}

      {showKpiModal && (
        <CustomModal
          modalTitle="KPI Already Exist. Consider Chainging KPI"
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowKpiModal(false);
          }}
          className={""}
        >
          <div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  className="modelTextFild"
                  label="Enter KPI"
                  variant="outlined"
                  multiline
                  value={modalKpi}
                  onChange={(e) => {
                    setModalKpi(e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <Button
              onClick={() => {
                if (modalTitle === kpiModalData.inputData) {
                  toast.error("Title Already present");
                  return;
                } else {
                  const temp = [...previewDataList];

                  const titleIndex = temp.findIndex(
                    (item) => item.titleId === kpiModalData.titleId
                  );
                  if (titleIndex >= 0) {
                    const kpiIndex = temp[titleIndex].values.findIndex(
                      (item) => item.kpiId === kpiModalData.kpiId
                    );
                    if (kpiIndex >= 0) {
                      temp[titleIndex].values[kpiIndex].kpi = modalKpi;
                    }
                  }
                  setPreviewDataList(temp);
                  setShowKpiModal(false);
                  setModalKpi(null);
                  setKpiModalData(null);
                }
              }}
            >
              Save
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
};
