import { Card, CardContent, Typography, Button } from "@mui/material";
import { publicUrl } from "../constants/url_provider";
import {
  Event,
  PlaylistAddOutlined,
  Videocam,
  VideocamOutlined,
} from "@mui/icons-material";
import { AddTabContext } from "../features/dashboard/view/DashboardPage";
import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import TaskTabContainer from "../features/task/view/TaskTabContainer";
import CreateTaskContainer from "../features/task/view/CreateTaskContainer";
import SchedulerContainer from "../features/scheduler/view/SchedulerContainer";
import AppUtils from "../utils/AppUtils";
import { getLoginUserDetails } from "../utils/Utils";
import { ConfirmProvider } from "material-ui-confirm";
import MeetingsTabContainer from "../features/scheduler/view/component/meetings/view/MeetingsTabContainer";
export default function BottomMenuQuickActions({ title, description }) {
  const { addTab } = useContext(AddTabContext);
  const loginUser = getLoginUserDetails();
  return (
    <div class="quick-actions">
      <h2>Quick Actions</h2>
      <ul>
        {!AppUtils.isPersonal(loginUser) && (
          <li
            onClick={() => {
              addTab({
                key: uuidv4(),
                title: "Create Task",
                content: <CreateTaskContainer />,
                isDraggable: true,
              });
            }}
          >
            <PlaylistAddOutlined className="btmMenuAppsIcon" /> Create a new
            task
          </li>
        )}
        <li
          onClick={() => {
            addTab({
              key: uuidv4(),
              title: "Scheduler",
              content: (
                <SchedulerContainer
                  bottomShortcutDto={{ eventType: "Event" }}
                />
              ),
              isDraggable: true,
            });
          }}
        >
          <Event className="btmMenuAppsIcon" /> Create a new event
        </li>
        <li
          onClick={() => {
            const key = uuidv4();
            addTab({
              key: key,
              title: "Instant Meeting",
              content: (
                <ConfirmProvider>
                  <MeetingsTabContainer
                    startInstantMeeting={true}
                    tabKey={key}
                  />
                </ConfirmProvider>
              ),
              isDraggable: true,
              type: "Meeting",
            });
          }}
        >
          <Videocam className="btmMenuAppsIcon" /> Start instant meeting
        </li>
      </ul>
    </div>
  );
}
