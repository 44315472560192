import { useDrag } from "react-dnd";

import React from "react";

const TabWrapper = ({ handleDragStart, children, isDraggable }) => {
  const [, dragTab] = useDrag({
    type: "TAB_DIV",
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  return (
    <div
      ref={isDraggable ? dragTab : null}
      onDragStart={(e) => {
        // e.preventDefault();
        if (isDraggable) {
          handleDragStart();
        }
      }}
      className="TabWrapper"
    >
      {children}
    </div>
  );
};

export default TabWrapper;
