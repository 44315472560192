import {
  Add,
  CloseFullscreen,
  Edit,
  OpenInFull,
  Visibility,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import { NumericFormatCustom } from "./NumericFormatCustom";
import { Controller, useForm } from "react-hook-form";
import { CustomModal } from "../Modals/CustomModal";
import { toast } from "react-toastify";
import { getLoginUserDetails, validateEmail } from "../../../../../utils/Utils";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import TaskService from "../../../../../services/TaskService";
import { getPositionsOfOrganization } from "../../../../../services/AdminService";
import IntegerTextField from "../../../../../common/IntegerTextField";
import DashboardServices from "../../../../../services/DashboardServices";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";

export const SIPInfoForm = ({
  taskId,
  formDataTrans,
  taskNature,
  formData,
  handleClose,
}) => {
  const fullScreenStyle = {
    width: "100%",
  };
  const initialState = { isExpanded: false };
  const reducer = (state, action) => {
    switch (action.type) {
      case "expandOrShrinkWindow":
        return { ...state, isExpanded: !state.isExpanded };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const loginUserDetail = getLoginUserDetails();
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      currentCtc: "",
      expectedCtc: "",
      emailId: "",
      contactNo: "",
    },
  });
  const [showAddCandidateModal, setShowAddCandidateModal] = useState(false);
  const [referenceType, setReferenceType] = useState("");
  const docRef = useRef();
  const currentData = useRef(null);
  const [resume, setResume] = useState(null);
  const [modeOfInterView, setModeOfInterview] = useState("");
  const [positionList, setPositionList] = useState([]);

  const [prospectiveEmployeeList, setProspectiveEmployeeList] = useState([]);
  const [referenceBy, setReferenceBy] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [preferableTiming, setPreferableTiming] = useState(new Date());
  const [showScheduleInterview, setShowScheduleInterview] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [userList, setUserList] = useState([]);
  const [selectedInterviewer, setSelectdInterviewer] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const mappedProspectiveEmployeeList = useMemo(() => {
    const map = new Map();
    prospectiveEmployeeList.forEach((employee) => {
      const value = map.get(employee.expenseDtlId);
      if (value) {
        value.push(employee);
        map.set(employee.expenseDtlId, value);
      } else {
        map.set(employee.expenseDtlId, [employee]);
      }
    });
    return map;
  }, [prospectiveEmployeeList]);

  const getFileDataObj = async (file) => {
    const dto = {
      fileName: file.name,
      fileDataLink: await convertFileToBase64_Split(file),
    };
    return dto;
  };

  const getOrgEmployees = () => {
    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setEmployeeList(response.data);
      }
    );
  };

  const addCandidate = (data) => {
    console.log("Candidate data =", data);
    //
    let isValid = true;
    if (referenceType === "") {
      toast.error("Please select reference type");
      isValid = false;
    }

    if (referenceType === "employee" && referenceBy === null) {
      toast.error("Please select employee ");
      isValid = false;
    }

    if (!resume) {
      toast.error("Please provide employee resume ");
      isValid = false;
    }

    if (modeOfInterView === "") {
      toast.error("Please select interview mode");
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    const reqdto = {
      ...data,
      referenceDtl: data.referenceDtl || "",
      referenceEmployeeId:
        referenceType !== "employee" ? "" : referenceBy.userId,
      resumeFileName: (resume && resume.fileName) || "",
      resumeFileLinkData: (resume && resume.fileDataLink) || "",
      taskDtlId: taskId,
      expenseId: formData.expenseId,
      expenseDtlId: currentData.current.expenseDtlId,
      status: "",
      interveiwMode: modeOfInterView,
      prospectiveEmployeeId: 0,
      candidatePreferableTiming: preferableTiming,
      preferableTiming: preferableTiming,
    };

    console.log("reqDto =", reqdto);

    // return;

    TaskService.addProspectiveEmployee(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      reqdto
    ).then((response) => {
      if (response.data.returnValue === "1") {
        toast.success(response.data.message);
        // console.log("response")
        fetchPerspectiveEmployees(formData.expenseId, loginUserDetail.orgId);
        setShowAddCandidateModal(false);
        // initialize
      } else {
        toast.error(response.data.message);
      }
    });

    // if(data)
  };

  const handleError = (error) => {
    console.log("error =", error);
    const keys = Object.keys(error);
    keys.forEach((key) => {
      toast.error(error[key].message);
    });
  };

  const calculateTotalCtc = (index) => {
    const { maxCtc, noOfPosition } = formData.manPowers[index];
    const totalVal = parseInt(maxCtc) * parseInt(noOfPosition);
    if (isNaN(totalVal)) {
      return 0;
    } else {
      return totalVal;
    }
  };

  const fetchPerspectiveEmployees = (expenseId, orgId) => {
    TaskService.getProspectiveEmployees(expenseId, orgId).then((response) => {
      if (response.data) {
        setProspectiveEmployeeList(response.data);
      }
    });
  };

  useEffect(() => {
    fetchPerspectiveEmployees(formData.expenseId, loginUserDetail.orgId);

    getPositionsOfOrganization(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        setPositionList(response.data);
      }
    });
    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );
    getOrgEmployees();
  }, []);

  const getAllPerspectiveEmployeeByPosition = (positionId) => {
    const expense = formData.manPowers.find((item) => {
      return item.positionId === positionId;
    });
    if (expense) {
      const tempCandidateList = mappedProspectiveEmployeeList.get(
        expense.expenseDtlId
      );
      if (tempCandidateList) {
        return tempCandidateList;
      } else {
        return [];
      }
    }
    return [];
  };

  const getPositionNameByPositionId = (positionId) => {
    const position = positionList.find(
      (item) => item.positionId === positionId
    );
    console.log("position =", position);
    if (position) {
      return position.position;
    } else {
      return "";
    }
  };

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={state.isExpanded ? fullScreenStyle : {}}
      >
        <div className="flottingContainer">
          <div className="flotHeaderPanel">
            <div class="flotHedCon">Search Man Power</div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={
                    state.isExpanded ? "Exit Full Screen" : "Enter Full Screen"
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      dispatch({ type: "expandOrShrinkWindow" });
                    }}
                  >
                    {state.isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                    {/* <Expand /> */}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="flotContain">
            <div className="elementFormContainer previewSpace ">
              <div className="indentFormtable">
                <div className="indtableResponsive">
                  <table>
                    <thead>
                      <tr>
                        <th>Purpose</th>
                        <th>Contract</th>
                        <th>Created By</th>
                        <th>Approved By</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="textOnly">{formData.purpose}</div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {formData.contractName}
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {formData.createdByName}
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {formData.approvedByName}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>

                {/* <div className="indtableResponsive">
                  <table>
                    <thead>
                      <tr>
                        <th className="widthMin120">Position</th>
                        <th className="widthMin120">Appoint Nature</th>
                        <th className="widthMin120">Budget Head</th>
                        <th className="widthMin120">JD/KRA</th>
                        <th className="widthMin120">Number Of Position</th>
                        <th className="widthMin120">Max CTC</th>
                        <th className="widthMin120">Total CTC</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData &&
                        formData.manPowers &&
                        formData.manPowers.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                <div className="textOnly">
                                  {item.positionId}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.appointmentNature}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.budgetHeadName}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">{item.jdKra}</div>
                              </td>

                              <td>
                                <div className="textOnly">
                                  {item.noOfPosition}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">{item.maxCtc}</div>
                              </td>
                              <td>{calculateTotalCtc(index)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div> */}

                {formData &&
                  formData.manPowers &&
                  formData.manPowers.map((item) => {
                    return (
                      <>
                        <div class="cuHeaderGrp">
                          <div class="cuhederTxt">
                            {getPositionNameByPositionId(item.positionId)}
                          </div>
                          <div class="addUnitsBtn">
                            <div className="formElement">
                              <div className={`formBtnElement`}>
                                <Button
                                  variant="outlined"
                                  startIcon={<Add />}
                                  className={`comBtnOutline  `}
                                  onClick={(e) => {
                                    currentData.current = item;
                                    setShowAddCandidateModal(true);
                                  }}
                                >
                                  Add Candidate
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementListGrp">
                          <ul class="elmListInfo">
                            {getAllPerspectiveEmployeeByPosition(
                              item.positionId
                            ).map((emp) => {
                              return (
                                <>
                                  <li>
                                    <span className="rolsNm">
                                      {emp.firstName}
                                    </span>
                                    {emp.status === "N" && (
                                      <Button
                                        onClick={() => {
                                          const tempEmpIdList = [
                                            emp.prospectiveEmployeeId,
                                          ];
                                          TaskService.sendResumeForFilter(
                                            formData.expenseId,
                                            taskId,
                                            loginUserDetail.orgId,
                                            loginUserDetail.userId,
                                            tempEmpIdList
                                          ).then((response) => {
                                            if (
                                              response.data.returnValue === "1"
                                            ) {
                                              toast.success(
                                                response.data.message
                                              );
                                              fetchPerspectiveEmployees(
                                                formData.expenseId,
                                                loginUserDetail.orgId
                                              );
                                            } else {
                                              toast.error(
                                                response.data.message
                                              );
                                            }
                                          });
                                        }}
                                      >
                                        Send for filter
                                      </Button>
                                    )}
                                    {emp.status === "A" && (
                                      <Button
                                        onClick={() => {
                                          setSelectedCandidate(emp);
                                          setShowScheduleInterview(true);
                                        }}
                                      >
                                        Schedule Meeting
                                      </Button>
                                    )}
                                    {/* <IconButton className="rmvBtn">
                                      <Visibility />
                                    </IconButton>
                                    <IconButton className="rmvBtn">
                                      <Edit />
                                    </IconButton>
                                    <IconButton className="rmvBtn">
                                      <Trash />
                                    </IconButton> */}
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </>
                    );
                  })}

                {/* <div className="formElementGroup">
                  <div class="tskElemHeding mt10">GRN Info</div>
                  <div className="formElement">
                    <div className={`formBtnElement`}>
                      <Button
                        variant="outlined"
                        startIcon={<Add />}
                        className={`comBtnOutline  `}
                        onClick={(e) => {
                          setShowAddCandidateModal(true);
                        }}
                      >
                        Add Candidate
                      </Button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="flotFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  // ;
                  const tempEmpIdList = prospectiveEmployeeList
                    .filter((emp) => emp.status === "N")
                    .map((emp) => emp.prospectiveEmployeeId);
                  console.log("emp pers list= ", tempEmpIdList);
                  if (tempEmpIdList && tempEmpIdList.length > 0) {
                    TaskService.sendResumeForFilter(
                      formData.expenseId,
                      taskId,
                      loginUserDetail.orgId,
                      loginUserDetail.userId,
                      tempEmpIdList
                    ).then((response) => {
                      if (response.data.returnValue === "1") {
                        toast.success(response.data.message);
                      } else {
                        toast.error(response.data.message);
                      }
                    });
                  }
                }}
              >
                Send All For Filter
              </Button>

              <Button className="dfultDarkSecondaryBtn">Edit</Button>
            </div>
          </div>
        </div>
      </div>

      {showAddCandidateModal && (
        <>
          <CustomModal
            modalTitle="Candidate Demography"
            style={{ zIndex: 10001 }}
            closeModal={() => {
              setShowAddCandidateModal(false);
            }}
            className={"width90vwModel"}
          >
            <div className="modBody">
              <div className="formElementGroup flex-wrap">
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      label="First Name"
                      name="firstName"
                      id="firstName"
                      required
                      multiline
                      variant="outlined"
                      defaultValue={""}
                      className="modelTextFild"
                      {...register("firstName", {
                        required: "First Name is required",
                      })}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      label="Middle Name"
                      name="middleName"
                      id="middleName"
                      multiline
                      variant="outlined"
                      defaultValue={""}
                      className="modelTextFild"
                      {...register("middleName")}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      label="Last Name"
                      name="lastName"
                      required
                      id="lastName"
                      multiline
                      variant="outlined"
                      defaultValue={""}
                      className="modelTextFild"
                      {...register("lastName", {
                        required: "Last Name is required",
                      })}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="formElementGroup flex-wrap">
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      label="Email Address"
                      name="emailId"
                      id="emailId"
                      required
                      multiline
                      variant="outlined"
                      defaultValue={""}
                      className="modelTextFild"
                      {...register("emailId", {
                        validate: (email) => {
                          return email.trim() === ""
                            ? "Please Provide Email"
                            : !validateEmail(email)
                            ? "PleasepProvide a valid email"
                            : 0;
                        },
                      })}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      required
                      label="Contact No"
                      name="contactNo"
                      id="contactNo"
                      multiline
                      variant="outlined"
                      defaultValue={""}
                      className="modelTextFild"
                      {...register("contactNo", {
                        required: "Please provide contact number",
                      })}
                      inputProps={{ maxLength: 15 }}
                    />
                  </FormControl>
                </div>

                <div className="formElement width13">
                  <FormControl className="formControl">
                    <Controller
                      name="currentCtc"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Current CTC is required",
                      }}
                      render={({ field }) => (
                        <IntegerTextField
                          required
                          label="Current Ctc"
                          // name="currentCtc"
                          // id="currentCtc"
                          multiline
                          variant="outlined"
                          defaultValue={""}
                          className="modelTextFild"
                          {...field}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <Controller
                      name="expectedCtc"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Expected CTC is required",
                      }}
                      render={({ field }) => (
                        <IntegerTextField
                          label="Expected Ctc"
                          name="expectedCtc"
                          id="expectedCtc"
                          multiline
                          required
                          variant="outlined"
                          defaultValue={""}
                          className="modelTextFild"
                          {...field}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <Controller
                      name="experienceInMonth"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Experiance is required",
                      }}
                      render={({ field }) => (
                        <IntegerTextField
                          label="Experience In Month"
                          name="experienceInMonth"
                          id="experienceInMonth"
                          multiline
                          required
                          variant="outlined"
                          defaultValue={""}
                          {...field}
                          className="modelTextFild"
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      label="Current Organization Name"
                      name="currentOrganization"
                      id="currentOrganization"
                      multiline
                      required
                      variant="outlined"
                      defaultValue={""}
                      className="modelTextFild"
                      {...register("currentOrganization", {
                        required: "Please provide current organization name.",
                      })}
                    />
                  </FormControl>
                </div>

                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      label="Skills"
                      name="skills"
                      id="skills"
                      multiline
                      variant="outlined"
                      defaultValue={""}
                      className="modelTextFild"
                      {...register("skills")}
                    />
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <Controller
                      name="noticePeriodInDays"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Please provide notice period",
                      }}
                      render={({ field }) => (
                        <TextField
                          label="Notice Period (in days)"
                          name="noticePeriodInDays"
                          id="noticePeriodInDays"
                          multiline
                          required
                          variant="outlined"
                          className="modelTextFild"
                          {...field}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="formElementGroup flex-wrap">
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <InputLabel id="setActivity">Reference Type</InputLabel>
                    <Select
                      className="modelSelectFild"
                      label="Activity"
                      variant="outlined"
                      labelId="setActivity"
                      id="activity-select"
                      value={referenceType}
                      onChange={(e) => setReferenceType(e.target.value)}
                      defaultValue={""}
                    >
                      <MenuItem value={""}>{"--Select Type--"}</MenuItem>
                      <MenuItem value={"onlinePortal"}>
                        {"Online Portal"}
                      </MenuItem>
                      <MenuItem value={"employee"}>{"Employee"}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {referenceType === "onlinePortal" && (
                  <div className="formElement width13">
                    <FormControl className="formControl">
                      <TextField
                        label="Reference Detail"
                        name="referenceDtl"
                        id="referenceDtl"
                        multiline
                        variant="outlined"
                        defaultValue={""}
                        required
                        className="modelTextFild"
                        {...register("referenceDtl", {
                          required: "Please provide reference details",
                        })}
                      />
                    </FormControl>
                  </div>
                )}

                {referenceType === "employee" && (
                  <div className="formElement width13">
                    <FormControl className="formControl">
                      <Autocomplete
                        id="adduser-select"
                        className="modelformAutocompleteField"
                        variant="outlined"
                        value={referenceBy}
                        options={employeeList}
                        getOptionLabel={(option) => option.userName}
                        getOptionSelected={(option) => {
                          console.log("option is ", option);
                          return option.userId;
                        }}
                        onChange={(e, newValue) => {
                          console.log("new value = ", newValue);
                          setReferenceBy(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField"
                            placeholder="Reference By Employee Name*"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                )}

                <div className="formBtnElement ">
                  <Button
                    // startIcon={<Plus />}
                    className="comBtnOutline"
                    onClick={() => {
                      docRef.current.click();
                    }}
                  >
                    Attach Resume
                  </Button>
                  <input
                    ref={docRef}
                    style={{ display: "none" }}
                    type="file"
                    onChange={async (e) => {
                      const files = e.target.files;
                      console.log("files are", files);
                      const results = [];

                      for (const file of files) {
                        try {
                          const processedData = await getFileDataObj(file);
                          results.push(processedData);
                        } catch (error) {
                          console.error("Error processing file:", error);
                          results.push(null);
                        }
                      }

                      console.log("processed files are", results);
                      setResume(results[0]);
                      e.target.value = null;
                    }}
                  />
                </div>
                {resume && (
                  <div>
                    {resume.fileName}
                    <IconButton onClick={() => setResume(null)}>
                      <Trash />
                    </IconButton>
                  </div>
                )}

                <div className="formElementGroup">
                  <div className="formElement width13">
                    <FormControl className="formControl">
                      <InputLabel id="setModeOfInterview">
                        Mode Of Interview
                      </InputLabel>
                      <Select
                        className="modelSelectFild"
                        label="Mode Of Interview"
                        variant="outlined"
                        labelId="setModeOfInterview"
                        id="interview-select"
                        value={modeOfInterView}
                        onChange={(e) => setModeOfInterview(e.target.value)}
                        defaultValue={""}
                      >
                        <MenuItem value={""}>{"--Select Type--"}</MenuItem>
                        <MenuItem value={"physical"}>{"Physical"}</MenuItem>
                        <MenuItem value={"video"}>{"Video"}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="formElement width13">
                    <FormControl className="formControl">
                      <InputLabel
                        id="setCompletionDate"
                        className="setCompletionDate"
                      >
                        Preferable time for interview
                      </InputLabel>
                      <ReactDatePicker
                        toggleCalendarOnIconClick
                        showIcon
                        showTimeSelect
                        labelId="setCompletionDate"
                        className="formDatePicker"
                        selected={preferableTiming}
                        minDate={new Date()}
                        dateFormat="dd/MMM/yyyy h:mm aa"
                        onChange={(date) => {
                          setPreferableTiming(date);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="modActionBtnGrp actionsCreateOrg">
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={(e) => {
                  setShowAddCandidateModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="dfultPrimaryBtn"
                onClick={handleSubmit(addCandidate, handleError)}
              >
                Submit
              </Button>
            </div>
          </CustomModal>
        </>
      )}
      {showScheduleInterview && (
        <CustomModal
          modalTitle="Schedule Interview"
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowScheduleInterview(false);
          }}
          className={"width90vwModel"}
        >
          <div className="modBody">
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel
                    id="setStartTime"
                    className="setCompletionDate"
                  >
                    Start Time
                  </InputLabel>
                  <ReactDatePicker
                    showIcon
                    labelId="setStartTime"
                    className="formDatePicker"
                    minDate={new Date()}
                    selected={startTime}
                    onChange={(date) => {
                      setStartTime(date);
                      let temp = dayjs(date);
                      temp = temp.add(30, "minute");

                      setEndTime(temp.toDate());
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="setEndTime" className="setCompletionDate">
                    End Time
                  </InputLabel>
                  <ReactDatePicker
                    showIcon
                    labelId="setEndTime"
                    className="formDatePicker"
                    minDate={new Date()}
                    selected={endTime}
                    onChange={(date) => {
                      setEndTime(date);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </FormControl>
              </div>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  multiple
                  noOptionsText={"Please Type Recipient Name"}
                  multipleclassName="modelformAutocompleteField"
                  variant="outlined"
                  value={selectedInterviewer}
                  options={userList}
                  getOptionLabel={(option) => option.userName}
                  onChange={(e, data) => setSelectdInterviewer(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={(e) => {
                setShowScheduleInterview(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={() => {
                let isValid = true;
                if (!selectedCandidate) {
                  toast.error("Something Wrong Happened.");
                  isValid = false;
                }
                if (startTime === null) {
                  toast.error("Please Select Start Time");
                  isValid = false;
                }

                if (endTime === null) {
                  toast.error("Please Select End Time");
                  isValid = false;
                }

                if (selectedInterviewer.length === 0) {
                  toast.error("Please Select Interviewers");
                  isValid = false;
                }

                if (!isValid) {
                  return;
                }

                const reqDto = {
                  prospectiveEmployeeId:
                    selectedCandidate.prospectiveEmployeeId,
                  interviewers: selectedInterviewer.map(
                    (intv) => intv.userId
                  ),
                  length: 0,
                  startTime: startTime.toISOString(),
                  endTime: endTime.toISOString(),
                  interviewMode:
                    selectedCandidate.interveiwMode || "physical",
                };
                console.log("reqDto =", reqDto);

                // return;

                TaskService.scheduleInterview(
                  taskId,
                  loginUserDetail.orgId,
                  loginUserDetail.userId,
                  reqDto
                ).then((response) => {
                  if (response.data.returnValue === "1") {
                    toast.success(response.data.message);
                    fetchPerspectiveEmployees(formData.expenseId, taskId);
                    setStartTime(null);
                    setEndTime(null);
                    setSelectdInterviewer([]);
                    setShowScheduleInterview(false);
                  } else {
                    toast.error(response.data.message);
                  }
                });
              }}
            >
              Add
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
};
