import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { XLg } from "react-bootstrap-icons";
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  CheckCircle,
  DownloadForOffline,
  Translate,
} from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import { Trash } from "iconoir-react";
import {
  getPrivateNotes,
  addPrivateNotes,
  deletePrivateNotes,
  getEventAudioNote,
  generatePreSignedUrlS3Object,
} from "../../../../../../services/VideoConferenceService";
import AddIcon from "@mui/icons-material/Add";
import DashboardServices from "../../../../../../services/DashboardServices";
import { useRef } from "react";

const MeetingNotes = ({ handleMenuCloseButton, eventId }) => {
  const [audioLink, setAudioLink] = useState();
  const [audioFileName, setAudioFileName] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const [currentUserId, setCurrentUserId] = useState("");
  const [privateNoteText, setPrivateNoteText] = useState("");
  const [privateNotesList, setPrivateNotesList] = useState([]);
  const audioPlayer = useRef();
  const audioUrl = useRef();

  useEffect(() => {
    const userId = userDetails.userId;
    if (userId) {
      setCurrentUserId(userId);
      fetchPrivateNotesData(userId);
      getEventAudioNotes();
    }
  }, [eventId]);

  const fetchPrivateNotesData = (uid) => {
    getPrivateNotes(uid, eventId).then((response) => {
      setPrivateNotesList(response.data);
    });
  };

  const getEventAudioNotes = () => {
    getEventAudioNote(eventId).then((response) => {
      console.log(response.data);
      setAudioFileName(response.data.field1);
      // setAudioFolderName(response.data.field2);
      generatePreSignedUrlS3Object(
        response.data.field1,
        response.data.field2
      ).then((response) => {
        console.log("response = ", response);
        audioUrl.current = response.data;
        setAudioLink(response.data);
      });
    });
  };

  const handleSubmitCreateNote = () => {
    if (privateNoteText !== "") {
      addPrivateNotes(currentUserId, eventId, privateNoteText).then(
        (response) => {
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setPrivateNoteText("");
            fetchPrivateNotesData(currentUserId);
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    } else {
      toast.error(`Please Enter Private Notes`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeletePrivateNote = (eventNoteId) => {
    deletePrivateNotes(currentUserId, eventNoteId).then((response) => {
      if (response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchPrivateNotesData(currentUserId);
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  return (
    <>
      <div className="meetRightHeader">
        <div className="metRtTitle"> Meeting Notes </div>
        <div className="metRtAction">
          <IconButton
            aria-label="Close"
            onClick={() => handleMenuCloseButton()}
          >
            <XLg />
          </IconButton>
        </div>
      </div>

      <div className="MeetingTranscriptionContainer">
        <div className="Meeting_Transcription">
          <div className="transcripHeader">
            {/* <TextField
              className="transcripAutoComplete"
              id="transcripAutoComplete"
              placeholder=" Add Private Notes"
              value={privateNoteText}
              onChange={(e) => {
                setPrivateNoteText(e.target.value);
              }}
            /> */}
            {userDetails.userType !== "GUEST" && (
              <TextField
                className="transcripAutoComplete"
                id="transcripAutoComplete"
                placeholder="Add private note"
                value={privateNoteText}
                onChange={(event) => {
                  setPrivateNoteText(event.target.value.trim());
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    console.log("onKeyDown join:", event.key);
                    handleSubmitCreateNote();
                  }
                }}
                disabled={false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={"Add private note"}>
                        <IconButton
                          aria-label="Add private note"
                          onClick={handleSubmitCreateNote}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </div>
          <div className="transcriptionContainArea">
            {privateNotesList.length > 0 && (
              <>
                <div className="pNotesHeading">Private notes</div>
                <div className="listPrivatesNotes">
                  <ul>
                    {privateNotesList.map((note) => (
                      <li key={note.eventNoteId}>
                        <div className="liGrp">
                          <div className="pNotesText">{note.notes}</div>
                          <div
                            className="pNotesDeleteIco"
                            onClick={() => {
                              handleDeletePrivateNote(note.eventNoteId);
                            }}
                          >
                            <Trash className="trashIcon" />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
            {audioFileName && audioFileName.length > 0 && audioLink && (
              <>
                <div className="pNotesHeading">Audio note:</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "8px 0",
                  }}
                >
                  {/* <div style={{ marginRight: "24px" }}>
                    {isPlaying ? `Playing Audio Note` : `Recorded Audio Note`}
                  </div> */}
                  {/* {isPlaying ? (
                    <StopIcon style={{ fontSize: 24, color: "red", cursor: "pointer" }} onClick={stop} />
                  ) : (
                    <PlayArrowRounded style={{ fontSize: 24, cursor: "pointer" }} onClick={play} />
                  )} */}
                  <audio controls>
                    <source src={`${audioLink}`} />
                  </audio>
                </div>
              </>
            )}
            {(privateNotesList.length === 0 || !audioLink) &&
              "No meeting notes present"}
          </div>
        </div>
      </div>

      <div className="MeetRightFooter">
        <div className="meetRightFootBtnGrp">
          {/* <Button
            className="downloadTranscriptionBtn"
            onClick={handleSubmitCreateNote}
          >
            Add
          </Button> */}
          <Button
            className="cancleTransBtn"
            variant="outlined"
            onClick={() => handleMenuCloseButton()}
          >
            Close
          </Button>
        </div>
      </div>
    </>
  );
};

export default MeetingNotes;
