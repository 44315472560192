import {
  Add,
  ArrowBack,
  CheckCircle,
  CheckCircleOutline,
  Contrast,
  HighlightOff,
  HomeWork,
  PowerSettingsNew,
  Search,
  SentimentSatisfiedAltOutlined,
  Visibility,
  Weekend,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";

import avijit from "../../images/avijitRoy.jpg";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiletypeXlsx } from "react-bootstrap-icons";
import AttendanceService from "../../../../services/AttendanceService";
import { getLoginUserDetails } from "../../../../utils/Utils";
import DateUtils from "../../../../utils/DateUtils";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
export default function ViewAttendance({
  handelCloseViewAttendance,
  handelOpenViewUserAttend,
}) {
  const loginUserDetails = getLoginUserDetails();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const changeCurrentDate = (newDate) => {
    console.log("changeCurrentDate", DateUtils.getDateInYYYYMM(newDate));
    setSelectedDate(newDate);
  };

  // horizontal scroll start
  const headerRef = useRef(null);
  const bodyRef = useRef(null);

  // Function to handle horizontal scroll
  const handleScroll = (refToSync, scrollValue) => {
    if (refToSync.current) {
      refToSync.current.scrollLeft = scrollValue;
    }
  };

  // Function to sync scroll between divs
  const syncScroll = (e) => {
    const { scrollLeft } = e.target;
    handleScroll(headerRef, scrollLeft);
    handleScroll(bodyRef, scrollLeft);
  };
  // horizontal scroll end

  // start popover

  const [updateEmpAttnd, setUpdateEmpAttnd] = React.useState("");
  const inputPNRef = useRef(null);
  const handleChangeAttend = (event) => {
    setUpdateEmpAttnd(event.target.value);
  };

  const [updateAttendance, setUpdateAttendance] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const handleOpenUpAttend = (event, attendanceDto) => {
    setSelectedData(attendanceDto);
    setUpdateAttendance(event.currentTarget);
  };

  const handleCloseUpAttend = () => {
    setUpdateAttendance(null);
  };

  const open = Boolean(updateAttendance);
  const id = open ? "Update Attendance" : undefined;

  // end popover

  //services for data
  const [attendanceData, setAttendanceData] = useState(null);
  useEffect(() => {
    AttendanceService.getAllAttendanceData(
      loginUserDetails.orgId,
      DateUtils.getDateInYYYYMM(selectedDate)
    ).then((response) => {
      console.log("getAllAttendanceData", response.data);
      groupUserData(response.data);
    });
  }, [open, selectedDate]);

  const groupUserData = (userData) => {
    const data = userData.reduce((acc, curr) => {
      const { userId, attendanceDate, ...rest } = curr;
      if (!acc[userId]) {
        acc[userId] = {};
      }
      if (!acc[userId][attendanceDate]) {
        acc[userId][attendanceDate] = {};
      }
      acc[userId][attendanceDate] = { ...curr };
      return acc;
    }, {});
    console.log("grouped Data", data, Object.keys(data).length);
    setAttendanceData(data);
  };

  const displayRowData = (attendanceDto) => {
    let tdDisplay = null;
    if (attendanceDto.isWeekend === "Y") {
      if (attendanceDto.status === "PRESENT") {
        if (attendanceDto.isWorkFromHome === "Y") {
          tdDisplay = (
            <td
              className="attendance-icon"
              onClick={(event) => {
                handleOpenUpAttend(event, attendanceDto);
              }}
            >
              <span className="text-success">
                <HomeWork />
              </span>
            </td>
          );
        } else {
          tdDisplay = (
            <td
              className="attendance-icon"
              onClick={(event) => {
                handleOpenUpAttend(event, attendanceDto);
              }}
            >
              <span className="text-success">
                <CheckCircleOutline />
              </span>
            </td>
          );
        }
      } else {
        tdDisplay = (
          <td
            className="attendance-icon weekend"
            onClick={(event) => {
              handleOpenUpAttend(event, attendanceDto);
            }}
          >
            <span className="text-others">
              <Weekend />
            </span>
          </td>
        );
      }
    } else if (
      attendanceDto.holidayName != null &&
      attendanceDto.holidayName !== ""
    ) {
      tdDisplay = (
        <td
          className="attendance-icon holiday"
          onClick={(event) => {
            handleOpenUpAttend(event, attendanceDto);
          }}
        >
          <span className="text-others">
            <SentimentSatisfiedAltOutlined />
          </span>
        </td>
      );
    } else if (
      attendanceDto.leaveType != null &&
      attendanceDto.leaveType !== ""
    ) {
      tdDisplay = (
        <td
          className="attendance-icon leave"
          onClick={(event) => {
            handleOpenUpAttend(event, attendanceDto);
          }}
        >
          <span className="text-others">
            <SentimentSatisfiedAltOutlined />
          </span>
        </td>
      );
    } else if (attendanceDto.status === "PRESENT") {
      if (attendanceDto.isWorkFromHome === "Y") {
        tdDisplay = (
          <td
            className="attendance-icon"
            onClick={(event) => {
              handleOpenUpAttend(event, attendanceDto);
            }}
          >
            <span className="text-success">
              <HomeWork />
            </span>
          </td>
        );
      } else {
        tdDisplay = (
          <td
            className="attendance-icon"
            onClick={(event) => {
              handleOpenUpAttend(event, attendanceDto);
            }}
          >
            <span className="text-success">
              <CheckCircleOutline />
            </span>
          </td>
        );
      }
    } else if (
      attendanceDto.status == null ||
      attendanceDto.status === "ABSENT"
    ) {
      tdDisplay = (
        <td
          className="attendance-icon"
          onClick={(event) => {
            handleOpenUpAttend(event, attendanceDto);
          }}
        >
          <span className="text-danger">
            <HighlightOff />
          </span>
        </td>
      );
    }
    return tdDisplay;
  };

  const updateAttendanceStatus = (event) => {
    // if (event.target.textContent === "Please Wait...") {
    //   return;
    // }
    console.log(updateEmpAttnd, inputPNRef.current.value);
    event.target.disabled = true;
    event.target.style.cursor = "not-allowed";
    event.target.textContent = "Please Wait...";
    if (updateEmpAttnd === "" || updateEmpAttnd == null) {
      toast.error("Please select a status");
      event.target.style.cursor = "pointer";
      event.target.textContent = "Update";
      event.target.disabled = false;
      return;
    }

    if (inputPNRef.current.value === "" || inputPNRef.current.value == null) {
      toast.error("Please give some comments");
      event.target.style.cursor = "pointer";
      event.target.textContent = "Update";
      event.target.disabled = false;
      return;
    }

    const reqDto = {
      attendanceId: selectedData.rowId,
      prevStatus: selectedData.status,
      newStatus: updateEmpAttnd,
      comments: inputPNRef.current.value,
    };

    console.log(reqDto);

    // return;

    AttendanceService.updateAttendanceStatus(
      loginUserDetails.userId,
      reqDto
    ).then((response) => {
      if (response.data && response.data.returnValue === "1") {
        toast.success(response.data.message);
        setUpdateAttendance(null);
        AttendanceService.getAllAttendanceData(
          loginUserDetails.orgId,
          DateUtils.getDateInYYYYMM(selectedDate)
        ).then((response) => {
          console.log("getAllAttendanceData", response.data);
          groupUserData(response.data);
        });
      } else {
        toast.error(response.data.message);
      }
    });
  };

  const exportExcel = () => {
    if (Object.keys(attendanceData).length === 0) {
      toast.error("Attendance data is empty, unable to export");
      return;
    }

    let processedJSONArray = [];
    Object.keys(attendanceData).forEach((userId) => {
      let empData = Object.values(attendanceData[userId])[0];
      let jsonObj = {
        EMPLOYEE_ID: empData.employeeId,
        EMPLOYEE_NAME: empData.userName,
        DEPARTMENT_NAME: empData.departmentName,
      };
      Object.keys(attendanceData[userId]).forEach((dateDto) => {
        jsonObj[[dateDto]] = attendanceData[userId][dateDto].status
          ? attendanceData[userId][dateDto].status
          : "ABSENT";
      });
      processedJSONArray.push(jsonObj);
    });
    let fileName = "ATTENDANCE_" + DateUtils.getDateInYYYYMM(selectedDate);
    const worksheet = XLSX.utils.json_to_sheet(processedJSONArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "ATTENDANCE_" + DateUtils.getDateInYYYYMM(selectedDate)
    );
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  function exportXLSX() {
    // Object.keys(attendanceData).forEach((userId) => {
    //   let dataByDate = attendanceData[userId];
    //   dataByDate.employee_id = attendanceData[userId][0].employeeId;
    //   dataByDate.employee_name = attendanceData[userId][0].userName;
    //   dataByDate.department_name = attendanceData[userId][0].departmentName;
    // });

    // const jsonData = [
    //   { name: "John Doe", age: 30, city: "New York" },
    //   { name: "Jane Smith", age: 25, city: "Los Angeles" },
    // ];

    // let fileName = new Date().getTime();

    // const worksheet = XLSX.utils.json_to_sheet(jsonData);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // XLSX.writeFile(workbook, `${fileName}.xlsx`);

    // return;

    console.log(Object.values(attendanceData)[0]);
    console.log(Object.keys(attendanceData).length);
    let dataByDate = Object.values(attendanceData)[0];
    console.log(dataByDate);
    let key = Object.keys(dataByDate)[0];
    console.log(key);
    let dataObject = {
      [key]: {
        in_time: dataByDate[key].employeeId,
        in_time_image: dataByDate[key].userName,
        out_time: dataByDate[key].outTime,
        out_time_image: dataByDate[key].departmentName,
      },
    };
    const newObject = [
      {
        ...dataObject,
        employee_id: dataByDate[key].employeeId,
        employee_name: dataByDate[key].userName,
        department_name: dataByDate[key].departmentName,
      },
    ];
    // let userId = Object.keys(attendanceData)[0];
    // dataByDate.put["employee_id"] = "1234";
    // dataByDate.employee_name =
    //   attendanceData[Object.keys(attendanceData)[0]][0].userName;
    // dataByDate.department_name =
    //   attendanceData[Object.keys(attendanceData)[0]][0].departmentName;

    console.log(newObject);

    const ws = XLSX.utils.json_to_sheet(newObject);

    // Key Step: Creating Merged Cells for Headers
    const merge = [
      { s: { r: 0, c: 3 }, e: { r: 0, c: 6 } }, // Merge cells D1:G1 for '2024-04-01'
      // ... add more merge objects for other date headers
    ];
    ws["!merges"] = merge;

    // Header Row (Adjust if needed)
    ws["A1"] = { t: "s", v: "employee_id" };
    ws["B1"] = { t: "s", v: "employee_name" };
    ws["C1"] = { t: "s", v: "employee_department" };
    ws["D1"] = { t: "s", v: "2024-04-01" };
    ws["D2"] = { t: "s", v: "in_time" };
    ws["E2"] = { t: "s", v: "in_time_image" };
    ws["F2"] = { t: "s", v: "out_time" };
    ws["G2"] = { t: "s", v: "out_time_image" };

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, "Hello" + new Date().getSeconds + ".xlsx");
  }

  const [searchKey, setSearchKey] = useState("");

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton onClick={() => handelCloseViewAttendance()}>
            <ArrowBack />
          </IconButton>
          Attendance
        </div>
        <div className="anDashCardContainArea anAttendance">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="dashHeadereleGrp">
                <div className="searchTasklist">
                  <input
                    type="text"
                    class="serchTaskInput"
                    placeholder="Search Employee"
                    onChange={(event) => {
                      setSearchKey(event.target.value);
                    }}
                  />
                  <IconButton className="seacrhIcon">
                    <Search />
                  </IconButton>
                </div>
                <div className="attendanceDatePicker">
                  <ReactDatePicker
                    showIcon
                    className="dashAttendanceDT"
                    dateFormat={"MMM-yyyy"}
                    selected={selectedDate}
                    onChange={(date) => changeCurrentDate(date)}
                    showMonthYearPicker
                    // minDate={new Date()}
                    maxDate={new Date()}
                  />
                </div>

                <div className="schedulerLeagents">
                  <div className="schLeagendsInd">
                    <div className="legHead present">
                      <CheckCircleOutline />
                    </div>
                    <div className="legLabel">Present </div>
                  </div>
                  <div className="schLeagendsInd">
                    <div className="legHead absent">
                      <HighlightOff />
                    </div>
                    <div className="legLabel">Absent</div>
                  </div>
                  <div className="schLeagendsInd">
                    <div className="legHead weekend">
                      <Weekend />
                    </div>
                    <div className="legLabel">Weekend</div>
                  </div>
                  <div className="schLeagendsInd">
                    <div className="legHead holiday">
                      <SentimentSatisfiedAltOutlined />
                    </div>
                    <div className="legLabel">Holiday</div>
                  </div>
                  <div className="schLeagendsInd">
                    <div className="legHead leave">
                      <SentimentSatisfiedAltOutlined />
                    </div>
                    <div className="legLabel">Leave</div>
                  </div>
                </div>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<FiletypeXlsx />}
                  className="dfultPrimaryBtn"
                  onClick={exportExcel}
                >
                  Download
                </Button>
              </div>
            </div>

            {attendanceData && Object.keys(attendanceData).length > 0 && (
              <div className="cuContainArea overflowHidden">
                <div className="TableContainer">
                  <div className="e-attendLib">
                    <div className="e-attend-table-container">
                      <ScrollSync>
                        <table className="table attendanceTable">
                          <tbody>
                            <tr role="row">
                              <td className="border-bottom-1 tdWith180">
                                <div className="tbHead">Employee Info</div>
                                <div className="tbHead">
                                  {selectedDate.toLocaleDateString("en-US", {
                                    month: "long",
                                    year: "numeric",
                                  })}
                                </div>
                              </td>
                              <td>
                                <div className="tableHeaderContainer">
                                  <div
                                    className="tableheader hideScroolBar"
                                    ref={headerRef}
                                    onScroll={syncScroll}
                                  >
                                    <table className="attendTableHeader">
                                      <tbody>
                                        <tr>
                                          {Object.keys(
                                            attendanceData[
                                              Object.keys(attendanceData)[0]
                                            ]
                                          ).map((date) => (
                                            <th className="border-bottom-0 cell-dimention">
                                              {date.split("-")[2]}
                                            </th>
                                          ))}
                                          <th className="border-bottom-0 headerWith120">
                                            Payable Days
                                          </th>
                                        </tr>
                                        {/* <tr>
                                        <th className="border-bottom-0 cell-dimention">
                                          1
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          2
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          3
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          4
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          5
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          6
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          7
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          8
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          9
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          10
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          11
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          12
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          13
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          14
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          15
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          16
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          17
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          18
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          19
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          20
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          21
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          22
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          23
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          24
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          25
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          26
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          27
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          28
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          29
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          30
                                        </th>
                                        <th className="border-bottom-0 cell-dimention">
                                          31
                                        </th>
                                        <th className="border-bottom-0 headerWith120">
                                          Payable Days
                                        </th>
                                      </tr> */}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="padd0">
                                <ScrollSyncPane group="vertical">
                                  <div className="attenadTableLRHeight hideScroolBar">
                                    <table className="attendEmployLstTable">
                                      <tbody>
                                        {Object.keys(attendanceData).map(
                                          (userId) => {
                                            let userDto = Object.values(
                                              attendanceData[userId]
                                            )[0];

                                            if (
                                              searchKey !== "" &&
                                              !userDto.userName
                                                .toLowerCase()
                                                .includes(
                                                  searchKey.toLowerCase()
                                                )
                                            ) {
                                              return <></>;
                                            }

                                            return (
                                              <>
                                                <tr className="atndEmpLstTbTR">
                                                  <td className="atendUserTDHeight">
                                                    <div
                                                      className="userWthImg"
                                                      onClick={() =>
                                                        handelOpenViewUserAttend(
                                                          {
                                                            userDetails:
                                                              userDto,
                                                            selectedDate:
                                                              selectedDate,
                                                            attendanceDetails:
                                                              attendanceData[
                                                                userId
                                                              ],
                                                          }
                                                        )
                                                      }
                                                    >
                                                      <span className="attnd-user">
                                                        <img
                                                          src={
                                                            userDto.userProfileImage
                                                          }
                                                          alt="user Img1"
                                                          className="msr-2 rounded-circle"
                                                        />
                                                      </span>
                                                      <div className="mt-sm-2">
                                                        <h6>
                                                          {userDto.userName}
                                                        </h6>
                                                        <div className="empId">
                                                          {userDto.employeeId}
                                                        </div>
                                                        <div className="empDep">
                                                          {
                                                            userDto.departmentName
                                                          }
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )}
                                      </tbody>
                                      {/* <tbody>
                                        <tr className="atndEmpLstTbTR">
                                          <td className="atendUserTDHeight">
                                            <div
                                              className="userWthImg"
                                              onClick={() =>
                                                handelOpenViewUserAttend()
                                              }
                                            >
                                              <span className="attnd-user">
                                                <img
                                                  src={avijit}
                                                  alt="user Img1"
                                                  className="msr-2 rounded-circle"
                                                />
                                              </span>
                                              <div className="mt-sm-2">
                                                <h6>Jacob Ryan</h6>
                                                <div className="empId">
                                                  MT005
                                                </div>
                                                <div className="empDep">IT</div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr className="atndEmpLstTbTR">
                                          <td className="atendUserTDHeight">
                                            <div className="userWthImg">
                                              <span className="attnd-user">
                                                <img
                                                  src={avijit}
                                                  alt="user Img2"
                                                  className="msr-2 rounded-circle"
                                                />
                                              </span>
                                              <div className="mt-sm-2">
                                                <h6>Jacob Ryan</h6>
                                                <div className="empId">
                                                  MT005
                                                </div>
                                                <div className="empDep">IT</div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr className="atndEmpLstTbTR">
                                          <td className="atendUserTDHeight">
                                            <div className="userWthImg">
                                              <span className="attnd-user">
                                                <img
                                                  src={avijit}
                                                  alt="user Img3"
                                                  className="msr-2 rounded-circle"
                                                />
                                              </span>
                                              <div className="mt-sm-2">
                                                <h6>Jacob Ryan</h6>
                                                <div className="empId">
                                                  MT005
                                                </div>
                                                <div className="empDep">IT</div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr className="atndEmpLstTbTR">
                                          <td className="atendUserTDHeight">
                                            <div className="userWthImg">
                                              <span className="attnd-user">
                                                <img
                                                  src={avijit}
                                                  alt="user Img4"
                                                  className="msr-2 rounded-circle"
                                                />
                                              </span>
                                              <div className="mt-sm-2">
                                                <h6>Jacob Ryan</h6>
                                                <div className="empId">
                                                  MT005
                                                </div>
                                                <div className="empDep">IT</div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr className="atndEmpLstTbTR">
                                          <td className="atendUserTDHeight">
                                            <div className="userWthImg">
                                              <span className="attnd-user">
                                                <img
                                                  src={avijit}
                                                  alt="user Img5"
                                                  className="msr-2 rounded-circle"
                                                />
                                              </span>
                                              <div className="mt-sm-2">
                                                <h6>Jacob Ryan</h6>
                                                <div className="empId">
                                                  MT005
                                                </div>
                                                <div className="empDep">IT</div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr className="atndEmpLstTbTR">
                                          <td className="atendUserTDHeight">
                                            <div className="userWthImg">
                                              <span className="attnd-user">
                                                <img
                                                  src={avijit}
                                                  alt="user Img6"
                                                  className="msr-2 rounded-circle"
                                                />
                                              </span>
                                              <div className="mt-sm-2">
                                                <h6>Jacob Ryan</h6>
                                                <div className="empId">
                                                  MT005
                                                </div>
                                                <div className="empDep">IT</div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr className="atndEmpLstTbTR">
                                          <td className="atendUserTDHeight">
                                            <div className="userWthImg">
                                              <span className="attnd-user">
                                                <img
                                                  src={avijit}
                                                  alt="user Img7"
                                                  className="msr-2 rounded-circle"
                                                />
                                              </span>
                                              <div className="mt-sm-2">
                                                <h6>Jacob Ryan</h6>
                                                <div className="empId">
                                                  MT005
                                                </div>
                                                <div className="empDep">IT</div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr className="atndEmpLstTbTR">
                                          <td className="atendUserTDHeight">
                                            <div className="userWthImg">
                                              <span className="attnd-user">
                                                <img
                                                  src={avijit}
                                                  alt="user Img8"
                                                  className="msr-2 rounded-circle"
                                                />
                                              </span>
                                              <div className="mt-sm-2">
                                                <h6>Jacob Ryan</h6>
                                                <div className="empId">
                                                  MT005
                                                </div>
                                                <div className="empDep">IT</div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr className="atndEmpLstTbTR">
                                          <td className="atendUserTDHeight">
                                            <div className="userWthImg">
                                              <span className="attnd-user">
                                                <img
                                                  src={avijit}
                                                  alt="user Img9"
                                                  className="msr-2 rounded-circle"
                                                />
                                              </span>
                                              <div className="mt-sm-2">
                                                <h6>Jacob Ryan</h6>
                                                <div className="empId">
                                                  MT005
                                                </div>
                                                <div className="empDep">IT</div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr className="atndEmpLstTbTR">
                                          <td className="atendUserTDHeight">
                                            <div className="userWthImg">
                                              <span className="attnd-user">
                                                <img
                                                  src={avijit}
                                                  alt="user Img10"
                                                  className="msr-2 rounded-circle"
                                                />
                                              </span>
                                              <div className="mt-sm-2">
                                                <h6>Jacob Ryan</h6>
                                                <div className="empId">
                                                  MT005
                                                </div>
                                                <div className="empDep">IT</div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr className="atndEmpLstTbTR">
                                          <td className="atendUserTDHeight">
                                            <div className="userWthImg">
                                              <span className="attnd-user">
                                                <img
                                                  src={avijit}
                                                  alt="user Img11"
                                                  className="msr-2 rounded-circle"
                                                />
                                              </span>
                                              <div className="mt-sm-2">
                                                <h6>Jacob Ryan</h6>
                                                <div className="empId">
                                                  MT005
                                                </div>
                                                <div className="empDep">IT</div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr className="atndEmpLstTbTR">
                                          <td className="atendUserTDHeight">
                                            <div className="userWthImg">
                                              <span className="attnd-user">
                                                <img
                                                  src={avijit}
                                                  alt="user Img12"
                                                  className="msr-2 rounded-circle"
                                                />
                                              </span>
                                              <div className="mt-sm-2">
                                                <h6>Jacob Ryan</h6>
                                                <div className="empId">
                                                  MT005
                                                </div>
                                                <div className="empDep">IT</div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody> */}
                                    </table>
                                  </div>
                                </ScrollSyncPane>
                              </td>
                              <td className="padd0">
                                <ScrollSyncPane group="vertical">
                                  <div
                                    className="attenadTableLRHeight  hideScroolBar6"
                                    ref={bodyRef}
                                    onScroll={syncScroll}
                                  >
                                    <table className="attendTableChart">
                                      <tbody>
                                        {Object.keys(attendanceData).map(
                                          (userId) => {
                                            let userDto = Object.values(
                                              attendanceData[userId]
                                            )[0];

                                            if (
                                              searchKey !== "" &&
                                              !userDto.userName
                                                .toLowerCase()
                                                .includes(
                                                  searchKey.toLowerCase()
                                                )
                                            ) {
                                              return <></>;
                                            }
                                            return (
                                              <>
                                                <tr>
                                                  {Object.keys(
                                                    attendanceData[userId]
                                                  ).map((date) => {
                                                    let attendanceStatus =
                                                      attendanceData[userId][
                                                        date
                                                      ];
                                                    return (
                                                      <>
                                                        {displayRowData(
                                                          attendanceStatus
                                                        )}
                                                      </>
                                                    );
                                                  })}
                                                  <td className="hilight twdays headerWith120">
                                                    <span className="pblDys">
                                                      24
                                                    </span>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )}
                                      </tbody>
                                      {/* <tbody>
                                        <tr>
                                          <td
                                            className="attendance-icon"
                                            onClick={handleOpenUpAttend}
                                          >
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <HomeWork />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-danger">
                                              <HighlightOff />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon leave">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="hilight twdays headerWith120">
                                            <span className="pblDys">24</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-danger">
                                              <HighlightOff />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>

                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon leave">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="hilight twdays headerWith120">
                                            <span className="pblDys">24</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-danger">
                                              <HighlightOff />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon leave">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="hilight twdays headerWith120">
                                            <span className="pblDys">24</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-danger">
                                              <HighlightOff />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon leave">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="hilight twdays headerWith120">
                                            <span className="pblDys">24</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-danger">
                                              <HighlightOff />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon leave">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="hilight twdays headerWith120">
                                            <span className="pblDys">24</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-danger">
                                              <HighlightOff />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon leave">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="hilight twdays headerWith120">
                                            <span className="pblDys">24</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-danger">
                                              <HighlightOff />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon leave">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="hilight twdays headerWith120">
                                            <span className="pblDys">24</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-danger">
                                              <HighlightOff />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon leave">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="hilight twdays headerWith120">
                                            <span className="pblDys">24</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-danger">
                                              <HighlightOff />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon leave">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="hilight twdays headerWith120">
                                            <span className="pblDys">24</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-danger">
                                              <HighlightOff />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon leave">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="hilight twdays headerWith120">
                                            <span className="pblDys">24</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-danger">
                                              <HighlightOff />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon leave">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="hilight twdays headerWith120">
                                            <span className="pblDys">24</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-danger">
                                              <HighlightOff />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon leave">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="attendance-icon holiday">
                                            <span className="text-others">
                                              <SentimentSatisfiedAltOutlined />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon">
                                            <span className="text-success">
                                              <CheckCircleOutline />
                                            </span>
                                          </td>
                                          <td className="attendance-icon weekend">
                                            <span className="text-others">
                                              <Weekend />
                                            </span>
                                          </td>
                                          <td className="hilight twdays headerWith120">
                                            <span className="pblDys">24</span>
                                          </td>
                                        </tr>
                                      </tbody> */}
                                    </table>
                                  </div>
                                </ScrollSyncPane>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ScrollSync>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {attendanceData && Object.keys(attendanceData).length === 0 && (
              // <div
              //   style={{
              //     display: "flex",
              //     color: "white",
              //     position: "relative",
              //     left: "45%",
              //     top: "45%",
              //     fontSize: "25px",
              //   }}
              // >
              //   No Data Found
              // </div>
              <div className="cuContainArea overflowHidden">
                <div className="TableContainer">
                  <div
                    className="e-attendLib"
                    style={{
                      display: "flex",
                      color: "white",
                      fontSize: "25px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No Attendance Data Found
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={updateAttendance}
        onClose={handleCloseUpAttend}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="popUpdateAttendArea">
          <div className="popAtndHead">Update Attendance</div>
          <div className="popAtndUsrDtl">
            <div className="usrNm">{selectedData?.userName}</div>
            <div className="usrADt">
              {selectedData &&
                DateUtils.getDateInYYYYMMDD(
                  new Date(selectedData?.attendanceDate)
                )}
            </div>
          </div>
          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="markAs">Mark As</InputLabel>
                <Select
                  className="modelSelectFild"
                  variant="outlined"
                  labelId="markAs"
                  id="markAs-select"
                  value={updateEmpAttnd}
                  label="Mark As"
                  onChange={handleChangeAttend}
                >
                  <MenuItem value={"PRESENT"}>Present</MenuItem>
                  <MenuItem value={"ABSENT"}> Absent </MenuItem>
                  <MenuItem value={"LEAVE"}> Leave </MenuItem>
                  {/* <MenuItem value={"WORK ON HOLIDAY"}>
                    {" "}
                    Work on Holidays{" "}
                  </MenuItem> */}
                </Select>
                <input
                  className="commentsUpdateAttendance"
                  // value={pvtNote}
                  // onChange={(event) => setPvtNote(event.target.value)}
                  type="text"
                  placeholder="Comments"
                  ref={inputPNRef}
                />
              </FormControl>
            </div>
          </div>
          <div className="popAtndFoot">
            <Button
              className="dfultPrimaryBtn"
              onClick={(event) => {
                updateAttendanceStatus(event);
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
}
