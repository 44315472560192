import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class MLService {
  generateContent(reqDto) {
    return http.post(`/ml/generateContent`, reqDto, {
      headers: authHeader(),
    });
  }
  generateImage(reqDto) {
    return http.post(`/ml/generateImage`, reqDto, {
      headers: authHeader(),
    });
  }
}
export default new MLService();
