import { AttachFile } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  addStatutoryInsurancePaymentsHead,
  getPositionsOfOrganization,
  getRightsToSelect,
} from "../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../utils/Utils";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import {
  MAX_FILE_SIZE_TASK,
  VALID_FILE_EXTENSION_TASK,
} from "../../../../constants/const_values";
import CoustomTooltip from "../../../../common/CustomTooltip";

export default function CreateTaxStatutory({
  handelClosecreateTaxStatutory,
  setRefreshTaxStatutory,
  close,
}) {
  const loginUserDetail = getLoginUserDetails();
  const [renewMonthly, setRenewMonthly] = useState(new Date());
  const [selectCatagory, setSelectCatagory] = useState("");
  const [executeOnLastDateOfMonth, setExecuteOnLastDateOfMonth] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState(null);

  const monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [selectNotifyPosition, setSelectNotifyPosition] = useState([]);

  const [selectNotifyPositionlead, setSelectNotifyPositionLead] = useState("");
  const handleChangeNotifyPositionLead = (event) => {
    setSelectNotifyPositionLead(event.target.value);
  };

  const [selectRenewDt, setSelectRenewDt] = useState("");

  const fileRef = useRef();

  const [nameList, setNameList] = useState([]);
  const [nameInput, setNameInput] = useState("");
  const [name, setName] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState(null);
  const [categoryInput, setCategoryInput] = useState("");
  const [positionList, setPositionList] = useState([]);
  const dayList = Array.from({ length: 30 }, (_, index) => index + 1 + "");
  const [monthlySelectedDay, setMonthlySelectedDay] = useState(null);

  const [firstQuarterDate, setFirstQuarterDate] = useState(new Date());
  const [secondQuarterDate, setSecondQuarterDate] = useState(new Date());
  const [thirdQuarterDate, setThirdQuarterDate] = useState(new Date());
  const [fourthQuarterDate, setFourthQuarterDate] = useState(new Date());
  const [firstTimeQuarterSelect, setFirstTimeQuarterSelect] = useState(true);
  const fileData = useRef(null);

  useEffect(() => {
    console.log("name =", nameInput);
  }, [nameInput]);

  useEffect(() => {
    getRightsToSelect("STATUTORY_INSURANCE_PAYMENTS_NAME").then((response) => {
      if (response.data) {
        setNameList(response.data);
      }
    });

    getRightsToSelect("STATUTORY_INSURANCE_PAYMENTS_CATEGORY").then(
      (response) => {
        if (response.data) {
          setCategoryList(response.data);
        }
      }
    );

    getPositionsOfOrganization(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        setPositionList(response.data);
      }
    });
  }, []);

  const getFileDataObj = async (file) => {
    const dto = {
      fileName: file.name,
      fileDataLink: await convertFileToBase64_Split(file),
    };
    return dto;
  };

  const handleFileChange = async (event) => {
    const fileList = event.target.files;
    const data = await getFileDataObj(fileList[0]);
    fileData.current = data;
    setDocument(data);
  };

  const handleChangeRenewDt = (event) => {
    setFirstTimeQuarterSelect(true);
    setSelectRenewDt(event.target.value);
  };

  const dateAdder = (selectedDate, quarterNumber, offset) => {
    let dt = selectedDate;
    let month = dt.getMonth() - (quarterNumber - offset) * 3;
    month = ((month % 12) + 12) % 12; // Handling modulo arithmetic for negative numbers
    let year = dt.getFullYear();
    let day = dt.getDate();

    // Check if the month is February and the day is greater than 28
    if (month === 1 && day > 28) {
      // Set the day to 28
      day = 28;
    } else {
      // Check if the date is valid
      if (day > new Date(year, month + 1, 0).getDate()) {
        // Adjust the day to the last day of the month
        day = new Date(year, month + 1, 0).getDate();
      }
    }

    dt = new Date(year, month, day);
    dt.setHours(0, 0, 0, 0); // Setting hours, minutes, seconds, and milliseconds to zero for start of the day
    return dt;
  };

  const setOtherQuarter = (quarterNumber, selectedDate) => {
    if (quarterNumber !== 1) {
      let dt = dateAdder(selectedDate, quarterNumber, 1);
      setFirstQuarterDate(dt);
    }
    if (quarterNumber !== 2) {
      let dt = dateAdder(selectedDate, quarterNumber, 2);
      setSecondQuarterDate(dt);
    }
    if (quarterNumber !== 3) {
      let dt = dateAdder(selectedDate, quarterNumber, 3);
      setThirdQuarterDate(dt);
    }
    if (quarterNumber !== 4) {
      let dt = dateAdder(selectedDate, quarterNumber, 4);
      setFourthQuarterDate(dt);
    }
  };

  const getMonthAndDate = (date) => {
    const day = date.getDate().toString().padStart(2);
    const month = monthArray[date.getMonth()];

    return [day, month];
  };

  const validateForm = () => {
    let isValid = true;

    if (nameInput === "") {
      toast.error("Please Provide Name");
      isValid = false;
    }
    if (categoryInput === "") {
      toast.error("Please Select Category");
      isValid = false;
    }
    if (selectRenewDt === "") {
      toast.error("Please Select Frequency");
      isValid = false;
    }
    if (
      selectRenewDt === "Monthly" &&
      !executeOnLastDateOfMonth &&
      !monthlySelectedDay
    ) {
      toast.error("Please Select Monthly Period");
      isValid = false;
    }

    if (selectRenewDt === "Quarterly" && firstTimeQuarterSelect) {
      toast.error("Please Select Quarterly Period");
      isValid = false;
    }

    if (selectNotifyPosition && selectNotifyPosition.length === 0) {
      toast.error("Please Select Notify To Position");
      isValid = false;
    }

    if (selectNotifyPositionlead === "") {
      toast.error("Please Select Lead Notify Lead Time");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmitData = () => {
    let period = "";

    //
    if (!validateForm()) {
      return;
    }

    if (selectRenewDt === "Monthly") {
      if (executeOnLastDateOfMonth) {
        period = "Last Day";
      } else {
        period = monthlySelectedDay;
      }
    }

    if (selectRenewDt === "Yearly") {
      const yearlyDate = renewMonthly;
      const day = yearlyDate.getDate().toString().padStart();
      console.log(day.toString().padStart(2, 0));
      let month = (yearlyDate.getMonth() + 1).toString().padStart(2, 0);
      month = monthArray[yearlyDate.getMonth()];
      period = `${day} ${month}`;
    }

    if (selectRenewDt === "Quarterly") {
      let [day1, month1] = getMonthAndDate(firstQuarterDate);
      let [day2, month2] = getMonthAndDate(secondQuarterDate);
      let [day3, month3] = getMonthAndDate(thirdQuarterDate);
      let [day4, month4] = getMonthAndDate(fourthQuarterDate);
      period = `${day1} ${month1},${day2} ${month2},${day3} ${month3},${day4} ${month4}`;
      console.log("month and date =");
    }

    const reqDto = {
      id: 0,
      name: nameInput,
      category: categoryInput,
      dueRenewalFrequency: selectRenewDt,
      dueRenewalPeriod: period,
      notificationLeadTime: selectNotifyPositionlead,
      notifyToPositionId:
        (selectNotifyPosition &&
          selectNotifyPosition.length > 0 &&
          selectNotifyPosition.map((pos) => pos.positionId)) ||
        [],
      notifyToPositionName:
        (selectNotifyPosition &&
          selectNotifyPosition.length > 0 &&
          selectNotifyPosition.map((pos) => pos.position).join(",")) ||
        "",
      fileDataLink: fileData.current ? fileData.current.fileDataLink : "",
      fileName: fileData.current ? fileData.current.fileName : "",
      dueRenewalDate: "",
      lastPaymentDate: "",
      lastPaymentId: 0,
      lastPaymentAmount: 0,
      dueRenewalDesc: "",
    };

    // console.log("reqdto =", reqDto);
    // return;

    addStatutoryInsurancePaymentsHead(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      reqDto
    ).then((response) => {
      if (response.data.returnValue === "1") {
        toast.success(response.data.message);
        setRefreshTaxStatutory((prev) => prev + 1);
        close();
        return;
      } else {
        toast.error(response.data.message);
      }
    });

    console.log("request dto=", reqDto);
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">Add Statutory and Insurance</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelClosecreateTaxStatutory()}
          >
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer">
          <div className="formElement">
            <FormControl className="formControl">
              <Autocomplete
                className="formAutocompleteField"
                variant="outlined"
                disablePortal
                freeSolo
                id="combo-box-demo"
                options={nameList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField autocompFildPlaceholder"
                    placeholder="Select Name*"
                  />
                )}
                getOptionLabel={(option) => option.name}
                value={name}
                onChange={(event, value) => {
                  console.log("value =", value);
                  setName(value);
                }}
                onInputChange={(e, val) => {
                  // setName(val);
                  setName(null);
                  setNameInput(val);
                }}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <Autocomplete
                className="formAutocompleteField"
                variant="outlined"
                disablePortal
                freeSolo
                id="combo-box-demo"
                options={categoryList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField autocompFildPlaceholder"
                    placeholder="Select Category*"
                  />
                )}
                getOptionLabel={(option) => option.name}
                value={category}
                onChange={(event, value) => {
                  console.log("value =", value);
                  setCategory(value);
                }}
                onInputChange={(e, val) => {
                  console.log("name element value=", val);
                  // setName(val);
                  setCategory(null);
                  setCategoryInput(val);
                }}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="select_dueRenualFq">
                Due / Renewal Date Frequency*
              </InputLabel>
              <Select
                className="formInputField"
                variant="outlined"
                labelId="select_dueRenualFq"
                id="SelectName"
                value={selectRenewDt}
                label="Due / Renewal Date Frequency"
                onChange={handleChangeRenewDt}
              >
                <MenuItem value="Monthly">Monthly</MenuItem>
                <MenuItem value="Quarterly">Quarterly</MenuItem>
                <MenuItem value="Yearly"> Yearly</MenuItem>
                <MenuItem value="No Specific Date"> No Specific Date</MenuItem>
              </Select>
            </FormControl>
          </div>
          {selectRenewDt === "Monthly" && (
            <div className="formElementGroup">
              <div className="formElement width13">
                <FormControl className="formControl">
                  <InputLabel id="renewMonthDate" className="setCompletionDate">
                    Select date
                  </InputLabel>
                  {/* <ReactDatePicker
                    showIcon
                    labelId="renewMonthDate"
                    className="formDatePicker"
                    onChange={(date) => {
                      setRenewMonthly(date);
                    }}
                    selected={renewMonthly}
                    dateFormat="dd"
                    // showMonthYearPicker
                    showMonthYearDropdown={false}
                  /> */}
                  <Select
                    disabled={executeOnLastDateOfMonth}
                    className="formInputField"
                    variant="outlined"
                    labelId="select_dueRenualFq"
                    id="SelectName"
                    value={monthlySelectedDay}
                    label="Due / Renewal Date Frequency"
                    onChange={(e) => {
                      setMonthlySelectedDay(e.target.value);
                    }}
                  >
                    {dayList.map((day) => (
                      <MenuItem value={day}>{day}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <FormGroup aria-label="position" row className="feformGroup">
                    <FormControlLabel
                      className="formCheckBox"
                      // value="Cost Center"
                      control={
                        <Checkbox
                          value={executeOnLastDateOfMonth}
                          onChange={(e) => {
                            setExecuteOnLastDateOfMonth(e.target.checked);
                            setMonthlySelectedDay(null);
                          }}
                        />
                      }
                      label="Execute On Last Date Of Month"
                      labelPlacement="End"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
          )}

          {selectRenewDt === "Quarterly" && (
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel
                    id="renewMonthDate1StQtr"
                    className="setCompletionDate"
                  >
                    1st Quarter
                  </InputLabel>
                  <ReactDatePicker
                    showIcon
                    labelId="renewMonthDate1StQtr"
                    className="formDatePicker"
                    onChange={(newValue) => {
                      setFirstQuarterDate(newValue);
                      if (firstTimeQuarterSelect) {
                        setOtherQuarter(1, newValue);
                      }
                      setFirstTimeQuarterSelect(false);
                    }}
                    selected={firstQuarterDate}
                    dateFormat="dd/MM"
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel
                    id="renewMonthDate2StQtr"
                    className="setCompletionDate"
                  >
                    2nd Quarter
                  </InputLabel>
                  <ReactDatePicker
                    showIcon
                    labelId="renewMonthDate2StQtr"
                    className="formDatePicker"
                    onChange={(newValue) => {
                      setSecondQuarterDate(newValue);
                      if (firstTimeQuarterSelect) {
                        setOtherQuarter(2, newValue);
                      }
                      setFirstTimeQuarterSelect(false);
                    }}
                    selected={secondQuarterDate}
                    dateFormat="dd/MM"
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel
                    id="renewMonthDate3StQtr"
                    className="setCompletionDate"
                  >
                    3rd Quarter
                  </InputLabel>
                  <ReactDatePicker
                    showIcon
                    labelId="renewMonthDate3StQtr"
                    className="formDatePicker"
                    onChange={(newValue) => {
                      setThirdQuarterDate(newValue);
                      if (firstTimeQuarterSelect) {
                        setOtherQuarter(3, newValue);
                      }
                      setFirstTimeQuarterSelect(false);
                    }}
                    selected={thirdQuarterDate}
                    dateFormat="dd/MM"
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel
                    id="renewMonthDate4StQtr"
                    className="setCompletionDate"
                  >
                    4th Quarter
                  </InputLabel>
                  <ReactDatePicker
                    showIcon
                    labelId="renewMonthDate4StQtr"
                    className="formDatePicker"
                    onChange={(newValue) => {
                      setFourthQuarterDate(newValue);
                      if (firstTimeQuarterSelect) {
                        setOtherQuarter(4, newValue);
                      }
                      setFirstTimeQuarterSelect(false);
                    }}
                    selected={fourthQuarterDate}
                    dateFormat="dd/MM"
                  />
                </FormControl>
              </div>
            </div>
          )}

          {selectRenewDt === "Yearly" && (
            <div className="formElementGroup">
              <div className="formElement width13">
                <FormControl className="formControl">
                  <InputLabel
                    id="renewMonthDateyear"
                    className="setCompletionDate"
                  >
                    Select Date
                  </InputLabel>
                  <ReactDatePicker
                    showIcon
                    labelId="renewMonthDateyear"
                    className="formDatePicker"
                    minDate={new Date()}
                    onChange={(date) => {
                      setRenewMonthly(date);
                    }}
                    selected={renewMonthly}
                    dateFormat="dd/MM"
                  />
                </FormControl>
              </div>
            </div>
          )}

          <div className="formElement">
            <FormControl className="formControl">
              {/* <Select
                className="formInputField"
                variant="outlined"
                labelId="select_NotPosition"
                id="Selectselect_notposition"
                value={selectNotifyPosition}
                label="Notify Position * "
                onChange={handleChangeNotifyPosition}
              >
                {positionList &&
                  positionList.map((position) => (
                    <MenuItem value={position}>{position.position}</MenuItem>
                  ))}
              </Select> */}

              <Autocomplete
                multiple
                noOptionsText="Please type to search"
                className="formAutocompleteField"
                variant="outlined"
                value={selectNotifyPosition}
                options={positionList}
                getOptionLabel={(option) => option.position}
                onChange={(e, newValue) => {
                  // console.log("new value = ", newValue);
                  setSelectNotifyPosition(newValue);
                }}
                renderOption={(props, item) => {
                  return (
                    <li {...props} key={item.name + item.id}>
                      {item.position}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField autocompFildPlaceholder"
                    placeholder="Notify to Positions *"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="select_NotleadTime">
                Notify Lead Time *
              </InputLabel>
              <Select
                className="formInputField"
                variant="outlined"
                labelId="select_NotleadTime"
                id="Selectselect_notpositionleadTime"
                value={selectNotifyPositionlead}
                label="Notify Lead Time * "
                onChange={handleChangeNotifyPositionLead}
              >
                <MenuItem value="7">7 Days</MenuItem>
                <MenuItem value="10">10 Days</MenuItem>
                <MenuItem value="15">15 Days</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div class="tskElemHeding mt10">Upload Document if any</div>
          <div className="formElement">
            <div className="formBtnElement">
              <CoustomTooltip
                title={
                  <>
                    <div className="uploadedFilesTooltip">
                      <div>
                        Allowed File Types:{" "}
                        <div>{VALID_FILE_EXTENSION_TASK.join(",")}</div>
                      </div>
                      <div>Maximum file size {MAX_FILE_SIZE_TASK} MB</div>
                    </div>
                  </>
                }
              >
                <Button
                  variant="outlined"
                  startIcon={<AttachFile />}
                  className={`comBtnOutline  ${document ? "activeStatus" : ""}`}
                  onClick={(e) => {
                    fileRef.current.click();
                  }}
                >
                  Attach File
                </Button>
              </CoustomTooltip>
              <input
                ref={fileRef}
                hidden
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                multiple
              />
            </div>
          </div>
          {document && (
            <div className="singleUpldfile">
              <span className="rolsNm">{document.fileName}</span>
              <IconButton
                className="rmvBtn"
                onClick={() => {
                  fileData.current = null;
                  setDocument(null);
                }}
              >
                <Trash />
              </IconButton>
            </div>
          )}
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button className="dfultPrimaryBtn" onClick={handleSubmitData}>
              Save
            </Button>

            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handelClosecreateTaxStatutory()}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
