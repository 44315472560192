import { AccountTree, Add } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import {
  getOrgDetails,
  getCountryList,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";

export default function DefineOrganization({
  handelOpenEditUpdateOrg,
  refreshOrganisation,
  orgDetailsData,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  // const [orgDetailsData, setOrgDetailsData] = useState(null);s
  const [loading, setLoading] = useState(false);
  const [CountryList, setCountryList] = useState("");

  const orgTypeMapping = {
    "": "None",
    OT0001: "Public Limited Company",
    OT0002: "Private Limited Company",
    OT0003: "Joint-Venture Company",
    OT0004: "Partnership Firm",
    OT0005: "One Person Company",
    OT0006: "Sole Proprietorship",
    OT0007: "Branch Office",
    OT0008: "Non-Government Organization (NGO)",
  };

  // useEffect(() => {
  //   fetchgetOrgDetails();
  // }, [refreshOrganisation]);

  // const fetchgetOrgDetails = () => {
  //   getOrgDetails(userDetails.userId)
  //     .then((response) => {
  //       console.log("getOrgDetails", response.data);
  //       setOrgDetailsData(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching getOrgDetails", error);
  //       setLoading(false);
  //     });
  // };

  useEffect(() => {
    if (orgDetailsData) {
      fetchCountry(orgDetailsData.countryCode);
    }
  }, [orgDetailsData]);

  const fetchCountry = (countryCode) => {
    getCountryList()
      .then((response) => {
        const countryValue = response.data;
        console.log("countryValue", countryValue);
        const country = countryValue.find(
          (value) => value.countryCode === countryCode
        );
        console.log("country", country);
        setCountryList(country ? country.countryName : "");
      })
      .catch((error) => {
        console.error("Error fetching country list", error);
      });
  };

  console.log("CountryList", CountryList);

  return (
    <>
      <div className="createUnitsArea">
        <div className="cuHeaderGrp">
          <div className="cuhederTxt">Organization Details</div>
        </div>
        <div className="cuContainArea">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : !orgDetailsData ? (
            <div class="noDataCard ">
              <span>Oops!!</span>No Organization details not define yet !!
              <br />
            </div>
          ) : (
            <div className="cuCardList">
              <Card
                className="cardContent widthfalf orgContainerView"
                style={{ width: "50% !important" }}
              >
                <CardContent>
                  <div className="CUcardTitle">{orgDetailsData.orgName}</div>

                  <div className="cuCheckItem">
                    Registered Office Address:{" "}
                    <span>
                      {`${
                        orgDetailsData.regBuildingNameNo &&
                        orgDetailsData.regBuildingNameNo !== ""
                          ? orgDetailsData.regBuildingNameNo + ", "
                          : ""
                      }
                      ${
                        orgDetailsData.regArea && orgDetailsData.regArea !== ""
                          ? orgDetailsData.regArea + ", "
                          : ""
                      }${
                        orgDetailsData.regCity && orgDetailsData.regCity !== ""
                          ? orgDetailsData.regCity + ", "
                          : ""
                      }${
                        orgDetailsData.regPinCd &&
                        orgDetailsData.regPinCd !== ""
                          ? orgDetailsData.regPinCd + ", "
                          : ""
                      }`}
                    </span>
                  </div>

                  <div className="cuCheckItem">
                    Corresponding Office Address:{" "}
                    <span>
                      {`${
                        orgDetailsData.corrBuildingNameNo &&
                        orgDetailsData.corrBuildingNameNo !== ""
                          ? orgDetailsData.corrBuildingNameNo + ", "
                          : ""
                      }
                      ${
                        orgDetailsData.corrArea &&
                        orgDetailsData.corrArea !== ""
                          ? orgDetailsData.corrArea + ", "
                          : ""
                      }${
                        orgDetailsData.corrCity &&
                        orgDetailsData.corrCity !== ""
                          ? orgDetailsData.corrCity + ", "
                          : ""
                      }${
                        orgDetailsData.corrPinCd &&
                        orgDetailsData.corrPinCd !== ""
                          ? orgDetailsData.corrPinCd + ", "
                          : ""
                      }`}
                    </span>
                  </div>

                  <div className="cuCheckItem">
                    Organization Email Id:{" "}
                    <span>{orgDetailsData.orgEmailId}</span>
                  </div>

                  <div className="cuCheckItem">
                    Organization Contact No.:{" "}
                    <span>{orgDetailsData.contactNo}</span>
                  </div>

                  <div className="cuCheckItem">
                    Organization Type:{" "}
                    <span>{orgTypeMapping[orgDetailsData.orgType]}</span>
                  </div>

                  <div className="cuCheckItem">
                    Date Of Incorporation: <span>{orgDetailsData.doi}</span>
                  </div>

                  <div className="cuCheckItem">
                    Authorized Person: <span>{orgDetailsData.authPerson}</span>
                  </div>

                  <div className="cuCheckItem">
                    Authorized Person Contact Number:{" "}
                    <span>{orgDetailsData.authPerContactNo}</span>
                  </div>

                  <div className="cuCheckItem">
                    Trade License : <span>{orgDetailsData.tradeLicNo}</span>
                  </div>

                  <div className="cuCheckItem">
                    Company Identification Number :{" "}
                    <span>{orgDetailsData.cin}</span>
                  </div>

                  <div className="cuCheckItem">
                    Tax Number : <span>{orgDetailsData.taxNo}</span>
                  </div>

                  {/* <div className="cuCheckItem">
                    Address For Correspondence :{" "}
                    <span>{orgDetailsData.corrAddress}</span>
                  </div> */}

                  <div className="cuCheckItem">
                    Time Zone : <span>{orgDetailsData.timeZone}</span>
                  </div>

                  {orgDetailsData &&
                    orgDetailsData.singleUnit &&
                    orgDetailsData.singleUnit === "Y" && (
                      <>
                        <div className="cuCheckItem">
                          Unit Name : <span>{orgDetailsData.unitName}</span>
                        </div>

                        <div className="cuCheckItem">
                          Balance sheet maintain by own :{" "}
                          <span>
                            {orgDetailsData.ownBalanceSheet === "Y"
                              ? "Yes"
                              : "No"}
                          </span>
                        </div>

                        <div className="cuCheckItem">
                          Statutory compliances :{" "}
                          <span>
                            {orgDetailsData.statutoryCompliances === "Y"
                              ? "Yes"
                              : "No"}
                          </span>
                        </div>

                        <div className="cuCheckItem">
                          Local Tax :{" "}
                          <span>
                            {orgDetailsData.localTa === "Y" ? "Yes" : "No"}
                          </span>
                        </div>
                        <div className="cuCheckItem">
                          Pan : <span>{orgDetailsData.pan}</span>
                        </div>
                        <div className="cuCheckItem">
                          Tan : <span>{orgDetailsData.tan}</span>
                        </div>
                        <div className="cuCheckItem">
                          Epf No : <span>{orgDetailsData.epfNo}</span>
                        </div>
                        <div className="cuCheckItem">
                          Esi No : <span>{orgDetailsData.esiNo}</span>
                        </div>
                        <div className="cuCheckItem">
                          Gst No : <span>{orgDetailsData.gstin}</span>
                        </div>
                      </>
                    )}

                  {/* <div className="cuCheckItem">
                    Country : <span>{CountryList}</span>
                  </div> */}
                </CardContent>
                <CardActions>
                  <div className="taskVDContBtn">
                    {/* <Button
                        variant="contained"
                        startIcon={<Trash />}
                        onClick={() => handleDelete(unit.unitId)}
                      >
                        Delete Unit
                      </Button> */}
                  </div>
                  <div className="taskVDContBtn">
                    <Button
                      variant="outlined"
                      startIcon={<PencilSquare />}
                      onClick={() => handelOpenEditUpdateOrg(orgDetailsData)}
                    >
                      Edit Organization
                    </Button>
                  </div>
                </CardActions>
              </Card>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
