import {
  CloseFullscreen,
  OpenInFull,
  StarBorderRounded,
  StarRounded,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import TaskService from "../../../../../services/TaskService";
import {
  getLoginUserDetails,
  getRatingList,
  renderRatingMessageIcon,
} from "../../../../../utils/Utils";
import { JdHtmlView } from "../../../../admin/view/component/JdHtmlView";
import { KraHtmlView } from "../../../../admin/view/component/KraHtmlView";
import { CustomModal } from "../Modals/CustomModal";

export default function SelfEvaluationPmsForm({
  data,
  handleClose,
  refreshData,
}) {
  const loginUser = getLoginUserDetails();
  const [isExpanded, setIsExpanded] = useState(false);

  const [userMetricsList, setUserMetricsList] = useState([]);
  const [userKPIList, setUserKPIList] = useState([]);
  const [userJDList, setUserJDList] = useState([]);

  const [showJsModel, setShowJdModel] = useState(false);
  const [showKpiModel, setShowKpiModel] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { formDataTransId } = data;
      const res1 = await TaskService.getMetricsByPmsId(formDataTransId);
      const res2 = await TaskService.getKpiOfUser(loginUser.userId);
      const res3 = await TaskService.getJdOfUser(loginUser.userId);

      if (res1 && res1.data != null) {
        const dataList = [];
        for (let i = 0; i < res1.data.length; i++) {
          const data = res1.data[i];
          data.pmsMetricsId = data.metricsId;
          data.evaluation = "";
          data.rating = 0;
          dataList.push(data);
        }
        setUserMetricsList(dataList);
      }

      if (res2 && res2.data != null) setUserKPIList(res2.data);
      if (res3 && res3.data != null) setUserJDList(res3.data);
    };

    if (data) fetchData();
  }, [data]);

  const handleClickSubmit = async () => {
    let isValid = true;

    const dataList = [];
    for (let i = 0; i < userMetricsList.length; i++) {
      const item = userMetricsList[i];
      if (item.metricsType === "text" && item.evaluation.trim() === "") {
        toast.error(`Please enter remarks of ${item.metrics}`);
        isValid = false;
      }
      if (item.metricsType === "rating" && item.rating === 0) {
        toast.error(`Please select rating of ${item.metrics}`);
        isValid = false;
      }
      if (
        item.metricsType === "both" &&
        item.evaluation.trim() === "" &&
        item.rating === 0
      ) {
        toast.error(`Please enter remarks & select rating of ${item.metrics}`);
        isValid = false;
      }
      delete item.metricsId;
      delete item.metrics;
      delete item.metricsType;
      delete item.maxRating;
      dataList.push(item);
    }

    if (!isValid) return;

    setSubmitting(true);
    const response = await TaskService.submitSelfEvaluation(
      loginUser.orgId,
      loginUser.userId,
      data.formDataTransId,
      data.taskId,
      dataList
    );
    setSubmitting(false);
    if (response && response.data != null && response.data > 0) {
      toast.success("Self evaluation PMS form Successfully submitted.");
      refreshData();
      handleClose();
    } else {
      toast.error("Something want wrong... please try again...");
    }
  };

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={{
          width: isExpanded ? "100%" : "",
        }}
      >
        <div className="flottingContainer">
          {/* header start */}
          <div className="flotHeaderPanel">
            <div class="flotHedCon">
              {data && data.taskName ? data.taskName : ""}
            </div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={isExpanded ? "Exit Full Screen" : "Enter Full Screen"}
                >
                  <IconButton onClick={(e) => setIsExpanded((r) => !r)}>
                    {isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          {/* header end */}

          {/* body start */}
          <div className="flotContain">
            <div className="elementFormContainer previewSpace ">
              <div className="showJDKRAforPosition">
                <div className="jdkraViewItem">
                  <span>Show JD for your position</span>
                  <Button
                    className="showJdKraBtn"
                    startIcon={<VisibilityOutlined />}
                    onClick={() => setShowJdModel(true)}
                  >
                    JD
                  </Button>
                </div>
                <div className="jdkraViewItem">
                  <span>Show KRA for your position</span>
                  <Button
                    className="showJdKraBtn"
                    startIcon={<VisibilityOutlined />}
                    onClick={() => setShowKpiModel(true)}
                  >
                    KRA
                  </Button>
                </div>
              </div>

              <ul className="JDKRAforPositionList">
                {userMetricsList &&
                  userMetricsList.map((item) => {
                    return (
                      <li key={item.metricsId}>
                        <div className="indMatricsDtl">
                          <div className="nameWithReatingStat">
                            <div className="matName">{item.metrics}</div>
                          </div>

                          {refreshCount >= 0 && (
                            <>
                              <div className="matRatingComment">
                                {(item.metricsType === "text" ||
                                  item.metricsType === "both") && (
                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <TextField
                                        className="descriptionTextFild"
                                        required
                                        labelId="remarks"
                                        label="Remark"
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                        maxRows={3}
                                        value={item.evaluation}
                                        onChange={(e) => {
                                          item.evaluation = e.target.value;
                                          setRefreshCount((r) => r + 1);
                                        }}
                                      />
                                    </FormControl>
                                  </div>
                                )}

                                {(item.metricsType === "rating" ||
                                  item.metricsType === "both") && (
                                  <>
                                    <div className="ratingWithTxt">
                                      <div className="star-rating">
                                        {getRatingList(item.maxRating).map(
                                          (star) => (
                                            <span
                                              key={star}
                                              className={
                                                star <= item.rating
                                                  ? "star filled"
                                                  : "star"
                                              }
                                              onClick={() => {
                                                item.rating = star;
                                                setRefreshCount((r) => r + 1);
                                              }}
                                            >
                                              {star <= item.rating ? (
                                                <StarRounded />
                                              ) : (
                                                <StarBorderRounded />
                                              )}
                                            </span>
                                          )
                                        )}
                                      </div>
                                      {renderRatingMessageIcon(item.rating)}
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          {/* body end */}

          {/* footer start */}
          <div className="flotFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  if (!submitting) handleClickSubmit();
                }}
              >
                {!submitting ? "Submit" : "Submitting..."}
              </Button>
              <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
                Close
              </Button>
            </div>
          </div>
          {/* footer end */}
        </div>
      </div>

      {showJsModel && userJDList && userJDList.length > 0 && (
        <CustomModal
          modalTitle=""
          style={{ zIndex: 10001 }}
          closeModal={() => setShowJdModel(false)}
          className={"width50vwModel"}
        >
          <JdHtmlView jdData={userJDList} />
        </CustomModal>
      )}

      {showKpiModel && userKPIList && userKPIList.length > 0 && (
        <CustomModal
          modalTitle=""
          style={{ zIndex: 10001 }}
          closeModal={() => setShowKpiModel(false)}
          className={"width50vwModel"}
        >
          <KraHtmlView kraData={userKPIList} />
        </CustomModal>
      )}
    </>
  );
}
