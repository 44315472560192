import React from "react";
import MeetingsContainer from "../../../../scheduler/view/component/meetings/view/MeetingsContainer";

export default function RightSideMeetingsContainer({
  selectedData,
  setSelectedData,
  closeFromDashboard,
  setEventData,
}) {
  return (
    <>
      <div className="dashboardMeetingsContainer">
        <MeetingsContainer
          setEventData={setEventData}
          key={selectedData.eventId}
          selectedEvent={selectedData}
          setSelectedEvent={setSelectedData}
          closeFromDashboard={closeFromDashboard}
        />
      </div>
    </>
  );
}
