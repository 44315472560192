import { HeightOutlined, MonitorWeightOutlined } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { HeartPulse, Lungs, ThermometerHalf } from "react-bootstrap-icons";
import BP from "../../../../../../dashboard/image/BP";
import Capsule from "../../../../../../dashboard/image/Capsule";
import SPO2 from "../../../../../../dashboard/image/SPO2";
import Syrup from "../../../../../../dashboard/image/Syrup";
import Tablets from "../../../../../../dashboard/image/Tablets";
import critical from "../../images/prescriptionIcons/critical.svg";
import eye from "../../images/prescriptionIcons/eye.svg";
import like from "../../images/prescriptionIcons/like.svg";
import scan from "../../images/prescriptionIcons/scan.png";
import dayjs from "dayjs";
import { getVitalSvgAccordingToVitalType } from "./consultationUtil";
import { getLoginUserDetails } from "../../../../../../../utils/Utils";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { toast } from "react-toastify";

export default function PrescriptionView({ data, extraData }) {
  const loginUserDetail = getLoginUserDetails();
  const [vitals, setVitals] = useState([]);

  const [prescriptionType, setPrescriptionType] = useState("provisional");
  const handelChangeprescriptionType = (event) => {
    setPrescriptionType(event.target.value);
  };

  // **************************BMI CALCULATE**************************
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [bmi, setBmi] = useState("");
  const [heightOutput, setHeightOutput] = useState("");
  const [weightOutput, setWeightOutput] = useState("");
  const [bmiClass, setBmiClass] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(1);
  const [pescriptionData, setPescriptionnData] = useState(null);
  const [showBMI, setShowBMI] = useState(false);

  const uploadData = (data) => {
    setPescriptionnData(data);
  };

  const calculateBmi = () => {
    const showBmi = true;

    const heightIndex = extraData.reqValue.vitals.findIndex(
      (item) => item.vitalName === "Height"
    );
    const widthIndex = extraData.reqValue.vitals.findIndex(
      (item) => item.vitalName === "Width"
    );

    if (heightIndex === -1 || widthIndex === -1) {
      setShowBMI(false);
    }

    const heightInMeters = height / 100;
    const bmiValue = parseFloat(weight / heightInMeters ** 2).toFixed(2);
    const bmiGroupIndex = [
      [0, 18.49],
      [18.5, 24.99],
      [25, 29.99],
      [30, 34.99],
      [35, 39.99],
      [40, 200],
    ].findIndex((e) => e[0] <= bmiValue && bmiValue < e[1]);

    const heightInInches = (height * 0.393700787).toFixed(0);
    const feet = Math.floor(heightInInches / 12);
    const inches = heightInInches % 12;

    setHeightOutput(`${height} cm / ${feet}' ${inches}"`);
    setWeightOutput(`${weight} kg / ${(2.2046 * weight).toFixed(2)} lb`);
    setBmi(bmiValue);
    setSelectedGroup(bmiGroupIndex);

    const bmiClasses = [
      "bmi-underweight",
      "bmi-normal",
      "bmi-preobesity",
      "bmi-obese1",
      "bmi-obese2",
      "bmi-obese3",
    ];
    setBmiClass(bmiClasses[bmiGroupIndex]);
  };

  const getReferStringFromType = (refer) => {
    switch (refer) {
      case "higherFacility":
        return "Advise higher facility";

      case "immediateHospitalization":
        return "Advise immediate hospitalization";

      case "otherSpeciality":
        return "Refer to other speciality";

      case "medicoLegal":
        return "Refer to medico legal";

      default:
        return "";
    }
  };

  useEffect(() => {
    if (extraData.queueVitals) {
      let vitalList = extraData.queueVitals.split("~");
      if (vitalList.length > 0) {
        vitalList = vitalList.map((item) => {
          const vitalComponents = item.split("#");
          if (vitalComponents[vitalComponents.length - 1] === "Height") {
            let value = vitalComponents[1];
            value = parseInt(value.match(/\d+/)[0], 10);
            // const isKg = value.toLowerCase().includes("kg");
            setHeight(value);
          } else if (vitalComponents[vitalComponents.length - 1] === "Weight") {
            let value = vitalComponents[1];
            value = parseInt(value.match(/\d+/)[0], 10);
            // const isKg = value.toLowerCase().includes("kg");
            setWeight(value);
          }
          return vitalComponents;
        });
      }
      setVitals(vitalList);
    }
    // calculateBmi();
  }, []);
  useEffect(() => {
    if (height && weight) {
      calculateBmi();
    }
  }, [height, weight]);
  return (
    <>
      <div className="MeetingTranscriptionContainer">
        <div className="Meeting_Transcription">
          <div className="elementFormContainer">
            <div className="tskElemHeding  ">Prescription Type</div>
            <div className="formElement">
              <FormControl className="formControl">
                <RadioGroup
                  className="formRadioGroup"
                  labelId="setTasktype"
                  name="setTasktype"
                  value={prescriptionType}
                  onChange={handelChangeprescriptionType}
                >
                  <FormControlLabel
                    className="formRadioField"
                    value="confirme"
                    control={<Radio />}
                    label="Confirm prescription"
                  />
                  <FormControlLabel
                    className="formRadioField"
                    value="provisional"
                    control={<Radio />}
                    label="Provisional prescription"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="pDemography mt10">
              <div className="pDemogInd">
                <span className="pDVlue">{extraData.queuePatientName}</span>
              </div>
              <div className="pDemogInd">
                <span className="pDVlue">{extraData.queuePatientGender}</span>
              </div>
              <div className="pDemogInd">
                <span className="pDVlue">{extraData.queuePatientAge}</span>
              </div>
              <div className="pDemogInd">
                <span>Insurance:</span>
                <span className="pDVlue">YES</span>
              </div>
              {/* <div className="pDemogInd">
                <span>Card No:</span>
                <span className="pDVlue">012154121541</span>
              </div> */}
            </div>

            {/* vitals area */}
            <div className="pres-container">
              <div class=" leftside">
                <div className="vitalscontainer">
                  {data &&
                    data.vitals.map((vital) => {
                      return (
                        <div className="vitals">
                          <div className="Pressurecontent">
                            <div className="Biconarea">
                              {getVitalSvgAccordingToVitalType(vital.vitalCode)}
                              {/* <BP /> */}
                            </div>

                            <div className="Btxt">
                              <div className="vitalnam">{vital.vitalName}</div>
                              {/* <div className="vitalshortfrom">BP</div> */}
                            </div>
                          </div>

                          {/* {item.vitalName === "Blood Pressure" && (
                            <div className="Bloodpresuremesurment">
                              <div className="Bunitarea">
                                <div className="rate">120</div>
                                <div className="Bunitnam">Systolic</div>
                              </div>

                              <div className="Bunitarea">
                                <div className="rate">80</div>
                                <div className="Bunitnam">Diastolic</div>
                              </div>
                            </div>
                          )} */}

                          {/* {item.vitalName !== "Blood Pressure" && (
                          )} */}
                          <div className="presuremesurment">
                            <div className="Bunitarea ">
                              <div className="rate">{`${vital.vitalValue}${
                                vital.unit ? `(${vital.unit})` : ""
                              }`}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {/* <div className="vitals Pulsert">
                    <div className="Pressurecontent">
                      <div className="Biconarea">
                        <HeartPulse />
                      </div>
                      <div className="Btxt">
                        <div className="vitalnam">Pulse Rate</div>
                        <div className="vitalshortfrom">PR/min</div>
                      </div>
                    </div>
                    <div className="presuremesurment">
                      <div className="Bunitarea warnigUnit">
                        <div className="rate">110</div>
                      </div>
                    </div>
                  </div>

                  <div className="vitals Pulsert">
                    <div className="Pressurecontent">
                      <div className="Biconarea">
                        <SPO2 />
                      </div>
                      <div className="Btxt">
                        <div className="vitalnam">Oxygen Saturation</div>
                        <div className="vitalshortfrom">SPO2%</div>
                      </div>
                    </div>
                    <div className="presuremesurment">
                      <div className="Bunitarea">
                        <div className="rate">99</div>
                      </div>
                    </div>
                  </div>

                  <div className="vitals Pulsert">
                    <div className="Pressurecontent">
                      <div className="Biconarea">
                        <Lungs />
                      </div>
                      <div className="Btxt">
                        <div className="vitalnam">Respiration Rate</div>
                        <div className="vitalshortfrom">RR/min</div>
                      </div>
                    </div>
                    <div className="presuremesurment">
                      <div className="Bunitarea dengerUnit">
                        <div className="rate">26</div>
                      </div>
                    </div>
                  </div>

                  <div className="vitals Pulsert">
                    <div className="Pressurecontent">
                      <div className="Biconarea">
                        <ThermometerHalf />
                      </div>
                      <div className="Btxt">
                        <div className="vitalnam">Temperature</div>
                        <div className="vitalshortfrom">Temp. F</div>
                      </div>
                    </div>
                    <div className="presuremesurment">
                      <div className="Bunitarea">
                        <div className="rate">97</div>
                      </div>
                    </div>
                  </div>

                  <div className="vitals Pulsert">
                    <div className="Pressurecontent">
                      <div className="Biconarea">
                        <MonitorWeightOutlined />
                      </div>
                      <div className="Btxt">
                        <div className="vitalnam">Body Weight</div>
                        <div className="vitalshortfrom">Weight Kg</div>
                      </div>
                    </div>
                    <div className="presuremesurment">
                      <div className="Bunitarea">
                        <div className="rate">85</div>
                      </div>
                    </div>
                  </div>

                  <div className="vitals Pulsert">
                    <div className="Pressurecontent">
                      <div className="Biconarea">
                        <HeightOutlined />
                      </div>
                      <div className="Btxt">
                        <div className="vitalnam">Body Height</div>
                        <div className="vitalshortfrom">Height cm</div>
                      </div>
                    </div>
                    <div className="presuremesurment">
                      <div className="Bunitarea">
                        <div className="rate">182</div>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* BMI area */}
                {height && weight && (
                  <div className="bmiGroup presBmi">
                    <div className="c-bmi__result">
                      <span className="bmiCatName">{bmiClass}</span>:
                      <span name="r" className={`vitValue ${bmiClass}`}>
                        {bmi}
                      </span>
                    </div>
                    <div className="c-bmi__groups" readOnly>
                      {[
                        "Underweight",
                        "Normal",
                        "Pre-obesity",
                        "Obese I",
                        "Obese II",
                        "Obese III",
                      ].map((label, index) => (
                        <div key={index}>
                          <input
                            type="radio"
                            className="bmiInputRadio"
                            id={`bmi-g${index}`}
                            name="g"
                            checked={selectedGroup === index}
                            readOnly
                          />
                          <label htmlFor={`bmi-g${index}`}></label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {/* end */}
              </div>
              <div className="rightside">
                <div className="vitalsactionarea">
                  <div className="vitalsinfo">
                    <div className="vitalheadngtxt">Complaints:</div>
                    <div className="complaintList">
                      <ul>
                        {data &&
                          data.symptoms &&
                          data.symptoms.map((item) => {
                            return (
                              <li>
                                <div className="complstDtlGrp">
                                  <div className="complstDtl  ">
                                    <div className="compntName">
                                      {item.selectedSymptom.symptomName}
                                    </div>
                                    <div className="compinfoGRP">
                                      <div className="compStime">
                                        {/* <span>Since:</span> */}
                                        <span className="data">
                                          {item.since}
                                        </span>
                                      </div>
                                      <div className="compSeverity">
                                        {/* <span>Severity:</span> */}
                                        <span className="data">
                                          {item.severity}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        {/* <li>
                          <div className="complstDtlGrp ">
                            <div className="complstDtl  ">
                              <div className="compntName">
                                Abdominal Colic / Cramps.
                              </div>
                              <div className="compinfoGRP">
                                <div className="compStime">
                                  <span>Since:</span>
                                  <span className="data">Yesterday</span>
                                </div>
                                <div className="compSeverity">
                                  <span>Severity:</span>
                                  <span className="data">Moderate</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li> */}
                      </ul>
                    </div>
                  </div>

                  {data && data.singObj && (
                    <div className="vitalsinfo">
                      <div className="vitalheadngtxt">For:</div>
                      <div className="complaintList">
                        <ul>
                          {data &&
                            data.singObj &&
                            data.singObj.map((item) => {
                              return (
                                <li>
                                  <div className="complstDtlGrp">
                                    <div className="complstDtl  ">
                                      <div className="compntName">
                                        {item.sign.signName}
                                      </div>
                                      <div className="compinfoGRP">
                                        <div className="compStime">
                                          <span>Note:</span>
                                          <span className="data">
                                            {item.userNote}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  )}

                  {data && data.comorbidity && (
                    <div className="vitalsinfo">
                      <div className="vitalheadngtxt">Comorbidity:</div>
                      <div className="complaintList">
                        <ul>
                          {data &&
                            data.comorbidity &&
                            data.comorbidity.map((item) => {
                              return (
                                <li>
                                  <div className="complstDtlGrp">
                                    <div className="complstDtl  ">
                                      <div className="compntName">
                                        {item.disease.diseaseName}
                                      </div>
                                      {item.since && (
                                        <div className="compinfoGRP">
                                          <div className="compStime">
                                            <span>Since:</span>
                                            <span className="data">
                                              {item.since}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  )}

                  {data && data.riskFactor && (
                    <div className="vitalsinfo">
                      <div className="vitalheadngtxt">Risk Factor:</div>

                      <ul className="vitlulinfo">
                        {data &&
                          data.riskFactor &&
                          data.riskFactor.map((item) => {
                            return (
                              <li>
                                <div className="vitalinfosubtxt">
                                  {`${item.riskFactor.riskFactor},(${item.since})`}
                                </div>
                              </li>
                            );
                          })}

                        {/* <li>
                        <div className="vitalinfosubtxt">
                          Acute trauma, Since 2 Month
                        </div>
                      </li> */}
                      </ul>
                    </div>
                  )}

                  {data && data.possibleDiagonesis && (
                    <div className="vitalsinfo">
                      <div className="vitalheadngtxt">Possible Diagonesis:</div>
                      <div className="complaintList">
                        <ul>
                          {data &&
                            data.possibleDiagonesis &&
                            data.possibleDiagonesis.map((item) => {
                              return (
                                <li>
                                  <div className="complstDtlGrp">
                                    <div className="complstDtl  ">
                                      <div className="compntName">
                                        {item.diseaseName}
                                      </div>
                                      {item.since && (
                                        <div className="compinfoGRP">
                                          <div className="compStime">
                                            {/* <span>Since:</span> */}
                                            <span className="data">
                                              {"Provisional"}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  )}

                  {data && data.investigation && (
                    <div className="vitalsinfo">
                      <div className="vitalheadngtxt">Investigations:</div>
                      <ul className="vitlulinfo">
                        {data &&
                          data.investigation &&
                          data.investigation.map((item) => {
                            return (
                              <li>
                                <div className="vitalinfosubtxt">
                                  {item.testName}
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  )}

                  {data && data.diagonesis && (
                    <div className="vitalsinfo">
                      <div className="vitalheadngtxt">Diagnosis/Condition:</div>
                      <ul className="vitlulinfo">
                        {data &&
                          data.diagonesis &&
                          data.diagonesis.map((item) => {
                            return (
                              <li>
                                <div className="vitalinfosubtxt">
                                  {`${item.diseaseObj.diseaseName} [ SNOWMED ${item.diseaseObj.snowmedCt}, ICD ${item.diseaseObj.icd11code} ]( ${item.status})`}
                                </div>
                              </li>
                            );
                          })}
                        {/* <li>
                        {" "}
                        <div className="vitalinfosubtxt">
                          Abetalipoproteinemia [ SNOWMED CT-190787008, ICD 10
                          CD-E786 ](provisional Diagnosis)
                        </div>
                      </li> */}
                      </ul>
                    </div>
                  )}

                  {data && data.procedure && (
                    <div className="vitalsinfo">
                      <div className="vitalheadngtxt">Procedure:</div>

                      <ul className="vitlulinfo">
                        {data &&
                          data.procedure &&
                          data.procedure.map((item) => {
                            return (
                              <li>
                                <div className="vitalinfosubtxt">
                                  {`${item.procedure.procedureName} (${dayjs(
                                    item.date
                                  ).format("DD-MMM-YYYY")})`}
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* table */}
            {/* end */}

            {/* doctor advice medicine */}

            {data && data.medication && (
              <div className="doctoradvicearea">
                <div className="tskElemHeding">Advice</div>
                <table className="taskListTable mt6">
                  <thead className="taskListtableHeader">
                    <tr>
                      <th className="advth"></th>
                      <th className="advth">Generic Name</th>
                      <th className="advth textCenter">Quantity/Dose</th>
                      <th className="advth textCenter">Frequency/Timing</th>
                      <th className="advth textCenter">Duration</th>
                      <th className="advth">Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.medication &&
                      data.medication.map((item) => {
                        return (
                          <tr>
                            <td>
                              <div className="mediconarea">
                                <Tablets />
                              </div>
                            </td>
                            <td>
                              <div className="complstDtl">
                                <div className="compntName">
                                  {item.genericName.generic}
                                </div>
                                <div className="compntName BrandMed">
                                  {item.brandName && item.brandName.brandName}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="mednam textCenter">
                                {item.dosage.dosage}
                              </div>
                            </td>
                            <td>
                              <div className="mednam textCenter">
                                {item.frequency.frequency}
                              </div>
                            </td>
                            <td>
                              <div className="mednam textCenter">
                                {item.duration.durationName}
                              </div>
                            </td>
                            <td>
                              <div className="mednam ">{item.note}</div>
                            </td>
                          </tr>
                        );
                      })}

                    {/* <tr>
                    <td>
                      <div className="mediconarea">
                        <Capsule />
                      </div>
                    </td>
                    <td>
                      <div className="complstDtl">
                        <div className="compntName">Calcigen D3</div>
                        <div className="compntName BrandMed"></div>
                      </div>
                    </td>
                    <td>
                      <div className="mednam textCenter">1</div>
                    </td>
                    <td>
                      <div className="mednam textCenter">OD HS</div>
                    </td>
                    <td>
                      <div className="mednam textCenter">1 month</div>
                    </td>
                    <td>
                      <div className="mednam">medicine taken notes</div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="mediconarea">
                        <Syrup />
                      </div>
                    </td>
                    <td>
                      <div className="complstDtl">
                        <div className="compntName">Ascoril LS Syrup</div>
                        <div className="compntName BrandMed"></div>
                      </div>
                    </td>
                    <td>
                      <div className="mednam textCenter">15ml</div>
                    </td>
                    <td>
                      <div className="mednam textCenter">TDS</div>
                    </td>
                    <td>
                      <div className="mednam textCenter">5 Day</div>
                    </td>
                    <td>
                      <div className="mednam">medicine taken notes</div>
                    </td>
                  </tr> */}
                  </tbody>
                </table>
              </div>
            )}
            {/* end */}

            {data && data.itemToMonito && (
              <div className="taskElementGrp mt10">
                <div className="tskElemHeding">Parameter to be Monitor</div>
                <div className="tskElemntInfo">
                  {data && data.itemToMonitor && data.itemToMonitor.join(",")}
                  {/* Blood Pressure, Pulse Rate, Oxygen Saturation, Respiration Rate */}
                </div>
              </div>
            )}

            {data && data.diet && (
              <div className="taskElementGrp mt10">
                <div className="tskElemHeding">Diet Recommendations</div>
                <div className="tskElemntInfo">
                  {data &&
                    data.diet &&
                    data.diet.map((item) => item.diet).join(", ")}
                  {/* Diabetic Diet, Dash Diet, Dash Diet */}
                </div>
              </div>
            )}

            {data && data.lifeStyle && (
              <div className="taskElementGrp mt10">
                <div className="tskElemHeding">Lifestyle Recommendations</div>
                <ul className="vitlulinfo">
                  {data &&
                    data.lifeStyle &&
                    data.lifeStyle.map((item) => {
                      return (
                        <li>
                          <div className="vitalinfosubtxt">
                            {item.lifeStyle}
                          </div>
                        </li>
                      );
                    })}
                  {/* <li>
                  <div className="vitalinfosubtxt">
                    Drink eight 8-ounce glasses water daily
                  </div>
                </li>
                <li>
                  <div className="vitalinfosubtxt">
                    Don't consume tobacco, alcohol and any others substance
                    abuse elements.
                  </div>
                </li> */}
                </ul>
              </div>
            )}

            {extraData &&
              extraData.queueReferredBy &&
              extraData.queueReferredBy !== "" && (
                <div className="taskElementsGrpWithTwo mt10">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Refered by</div>
                    <div className="tskElemntInfo">
                      {extraData.queueReferredBy}
                    </div>
                  </div>
                </div>
              )}

            {data && data.referType && (
              <div className="taskElementsGrpWithTwo mt10">
                <div className="taskElementGrp">
                  <div className="tskElemHeding">Refer</div>
                  <div className="tskElemntInfo">
                    {`${getReferStringFromType(data.referType)} ${
                      data.referDoctorSpeciality
                        ? `( ${data.referDoctorSpeciality}  ${
                            data.doctorName ? "- " + data.doctorName : ""
                          } )`
                        : ""
                    }`}
                  </div>
                </div>
              </div>
            )}
            {data && data.reviewDate && (
              <div className="taskElementsGrpWithTwo mt10">
                <div className="taskElementGrp">
                  <div className="tskElemHeding">Next Review</div>
                  <div className="tskElemntInfo">
                    {dayjs(data.reviewDate).format("DD-MMM-YYYY")}
                  </div>
                </div>
              </div>
            )}

            <Button
              variant="outlined"
              onClick={() => {
                console.log("Code Comitting");

                let procedures = [];
                let medicines = [];
                let dieseases = [];
                let lifeStyles = [];
                let diets = [];
                let paramsToMonitor = [];
                let tests = [];
                let vitals = [];
                let foodAllergies = [];
                let signs = [];
                let symptoms = [];
                let riskfactors = [];
                let medicineAllergies = [];

                if (data.procedure) {
                  procedures = data.procedure.map((item) => {
                    return {
                      procedureName: item.procedure.procedureName,
                      procedureId: item.procedure.locallyAdded
                        ? 0
                        : item.procedure.id,
                      planDate: dayjs(item.date).format("DD-MM-YYYY"),
                    };
                  });
                }

                if (data.medication) {
                  medicines = data.medication.map((item) => {
                    let brandName = "";
                    let brandId = 0;
                    if (item.brandName) {
                      brandName = item.brandName.brandName;
                      brandId = item.brandName.id;
                    }
                    return {
                      genericId: item.genericName.locallyAdded
                        ? 0
                        : item.genericName.id,
                      brandId: brandId,
                      genericName: item.genericName.generic,
                      brandName: brandName,
                      dosages: item.dosage.dosage,
                      dosagesUnit: item.dosage.dosageUnit,
                      route: item.root,
                      durationLabel: item.duration.durationName,
                      durationDays: item.duration.days,
                      timing: item.timing.join(","),
                      note: item.note,
                      adq: 0,
                      preExisting: "",
                      slNo: 0,
                    };
                  });
                }

                if (data.diagonesis) {
                  dieseases = data.diagonesis.map((item) => {
                    const diseaseId = item.diseaseObj.locallyAdded
                      ? 0
                      : item.diseaseObj.id;
                    return {
                      diseaseName: item.diseaseObj.diseaseName,
                      diseaseId,
                      nature: item.status,
                      snowmedCt: item.diseaseObj.snowmedCt,
                      icd11: item.diseaseObj.icd11code,
                    };
                  });
                }

                if (data.lifeStyle) {
                  lifeStyles = data.lifeStyle.map((item) => {
                    const lifeStyleId = item.locallyAdded ? 0 : item.id;
                    return {
                      lifeStyleName: item.lifeStyle,
                      lifeStyleId: lifeStyleId,
                    };
                  });
                }

                if (data.diet) {
                  diets = data.diet.map((item) => {
                    let dietId = item.locallyAdded ? 0 : item.id;
                    return {
                      dietName: item.diet,
                      dietId,
                    };
                  });
                }

                if (data.vitals) {
                  vitals = data.vitals.map((item) => {
                    return {
                      vitalName: item.vitalName,
                      unit: item.unit,
                      vitalValue: item.vitalValue,
                      recodedTime: item.recodedTime,
                      isNormal: item.isNormal,
                      vitalCode: item.vitalCode,
                    };
                  });
                }

                if (data.investigation) {
                  tests = data.investigation.map((item) => {
                    return {
                      testId: item.testId,
                      testName: item.testName,
                      location: item.location,
                      view: item.view,
                      technique: item.technique,
                      locationId: item.locationId,
                      viewId: item.viewId,
                      techinqueId: item.techinqueId,
                    };
                  });
                }

                if (data.itemToMonitor) {
                  paramsToMonitor = data.itemToMonitor;
                }

                if (data.allergicFood) {
                  foodAllergies = data.allergicFood.map((item) => {
                    return item.foodName;
                  });
                }

                if (data.singObj) {
                  signs = data.singObj.map((item) => {
                    return {
                      signName: item.sign.signName,
                      signId: item.sign.id,
                      signNote: item.userNote,
                      slNo: 0,
                    };
                  });
                }

                if (data.symptoms) {
                  symptoms = data.symptoms.map((item) => {
                    let symptomId = item.selectedSymptom.locallyAdded
                      ? 0
                      : item.selectedSymptom.id;
                    return {
                      symptomName: item.selectedSymptom.symptomName,
                      symptomId,
                      severity: item.severity,
                      since: item.since,
                      slNo: 0,
                    };
                  });
                }

                if (data.riskFactor) {
                  riskfactors = data.riskFactor.map((item) => {
                    let riskFactorId = item.riskFactor.locallyAdded
                      ? 0
                      : item.riskFactor.id;
                    return {
                      since: item.since,
                      riskFactorId,
                      riskFactorName: item.riskFactor.riskFactor,
                      slNo: 0,
                    };
                  });
                }

                if (data.allergicDrug) {
                  medicineAllergies = data.allergicDrug.map((item, index) => {
                    let type = "Generic";
                    let genericBrandId = 0;
                    let genericBrandName = "";
                    if (item.brand) {
                      type = "Brand";
                      genericBrandId = item.brand.id;
                      genericBrandName = item.brand.brandName;
                    } else {
                      genericBrandId = item.genericItem.locallyAdded
                        ? 0
                        : item.genericItem.id;
                      genericBrandName = item.genericItem.generic;
                    }
                    return {
                      type: type,
                      genericBrandId: genericBrandId,
                      genericBrandName: genericBrandName,
                      slNo: `${index}`,
                    };
                  });
                }

                const reqDto = {
                  visitId: extraData.queueVisitId,
                  doctorUserId: loginUserDetail.userId,
                  reviewDate: "",
                  referralNote: "",
                  doctorNote: "",
                  patientId: extraData.queuePatientId,
                  procedures,
                  medicines,
                  dieseases,
                  lifeStyles,
                  diets,
                  paramsToMonitor,
                  vitals,
                  tests,
                  foodAllergies,
                  signs,
                  symptoms,
                  riskfactors,
                  medicineAllergies,
                };
                console.log("reqDto= ", reqDto);
                // return;

                DashboardServices.submitPrescription(reqDto).then(
                  (response) => {
                    if (response.data.returnValue === "1") {
                      toast.success(response.data.message);
                    } else {
                      toast.error(response.data.message);
                    }
                  }
                );
              }}
            >
              Submit
            </Button>

            {/* <div className="actionareacontainer">
              <div className="actionarea">
                <div className="takeaction anrml">
                  <div className="actnoriconarea">
                    <img src={like} alt={"vimg"} class="acticn" />
                  </div>
                  <div className="actiotxt">Normal</div>
                </div>

                <div className="takeaction attentn">
                  <div className="actnoriconarea">
                    <img src={eye} alt={"vimg"} class="acticn" />
                  </div>
                  <div className="actiotxt">Needs Attention</div>
                </div>

                <div className="takeaction critcl">
                  <div className="actnoriconarea">
                    <img src={critical} alt={"vimg"} class="acticn" />
                  </div>
                  <div className="actiotxt">Requires Action</div>
                </div>
              </div>

              <div className="scanareacontainer">
                <div className="scaniconarea">
                  <img src={scan} alt={"vimg"} class="acticn" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
