import { AlarmOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  getMyEventsInDateRanges,
  getNextEvent,
} from "../services/SchedulerService";
import { getLoginUserDetails } from "../utils/Utils";
import DateUtils from "../utils/DateUtils";

export default function EventAlert({ viewEvent }) {
  const [eventAlertData, setEventAlertData] = useState(null);
  const [eventDto, setEventDto] = useState(null);
  useEffect(() => {
    const loginUser = getLoginUserDetails();
    try {
      const fetchData = async () => {
        await getNextEvent(loginUser.userId).then((response) => {
          console.log("EventAlert--->>>>>", response);
          if (response.data) {
            setEventDto(response.data);
            setEventAlertData({
              header: "Event Reminder",
              content: response.data.title,
              timing: DateUtils.printEventDate(
                new Date(response.data.start),
                new Date(response.data.end)
              ),
            });
          }
        });
      };
      fetchData();
    } catch (err) {}
  }, []);

  return (
    <>
      {null !== eventAlertData && (
        <div className="eventAlertWrapper">
          <div className="evnAltIcon">
            <AlarmOutlined />
          </div>
          <div className="evnDtlWTime">
            <span className="evdH">{eventAlertData.header}, </span>
            <span className="evdT">{eventAlertData.content}, </span>
            <span className="evdTime">{eventAlertData.timing}</span>
          </div>
          <div className="eveAltBtn">
            <Button variant="outlined" onClick={() => viewEvent(eventDto)}>
              View
            </Button>

            <Button variant="outlined" onClick={() => setEventAlertData(null)}>
              Dismiss
            </Button>
          </div>
          {/* <div className="eveAltBtn">
            <Button variant="outlined" onClick={() => setEventAlertData(null)}>
              Snooze
            </Button>
          </div> */}
        </div>
      )}
    </>
  );
}
