import { Add, Edit } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FilePlus, Trash } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import { getStatutoryInsurancePaymentsList } from "../../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import { toast } from "react-toastify";
import DateUtils from "../../../../../utils/DateUtils";

export const StatutoryAndPaymentIncoming = ({
  state,
  dispatch,
  budgetHeadList,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const loginUserDetail = getLoginUserDetails();
  const [allTimeFormData, setAllTimeFormData] = useState({
    type: "",
    budgetHead: "",
    category: "",
    dueDate: new Date(),
    amount: 0,
    description: "",
    document: null,
    editMode: true,
  });

  useEffect(() => {
    getStatutoryInsurancePaymentsList(loginUserDetail.orgId).then(
      (response) => {
        if (response.data) {
          setTypeList(response.data);
        }
      }
    );
  }, []);

  const getFileDataObj = async (file) => {
    const dto = {
      fileName: file.name,
      fileDataLink: await convertFileToBase64_Split(file),
    };
    return dto;
  };

  const handleAddDataToList = (data) => {
    let isValid = true;
    if (data.type === "") {
      toast.error("Please Select Type");
      isValid = false;
    }
    if (data.amount === 0) {
      toast.error("Amount Cannot Be Zero");
      isValid = false;
    }
    if (data.budgetHead === "") {
      toast.error("Please Select A Budget Head");
      isValid = false;
    }

    if (data.description === "") {
      toast.error("Please Provide Description");
      isValid = false;
    }
    if (!isValid) {
      return isValid;
    }
    const temp = {
      type: data.type.name,
      description: data.description,
      dueDate: dayjs(data.dueDate).format("DD-MM-YYYY"),
      sipFileName: data.document && data.document.fileName,
      sipFileDataLink: data.document && data.document.fileDataLink,
      amount: data.amount,
      budgetHeadId: data.budgetHead.id,
      budgetHeadName: data.budgetHead.head,
      expenseDtlId: 0,
      editMode: false,
    };
    console.log("temp data =", temp);
    dispatch({ type: "addItemToSips", payload: temp });
    return isValid;
  };

  const initializeAllTimeForeData = () => {
    setAllTimeFormData({
      type: "",
      budgetHead: "",
      category: "",
      dueDate: new Date(),
      amount: 0,
      description: "",
      document: null,
      editMode: true,
    });
  };

  return (
    <>
      <div className="indentFormtable">
        <div className="indtableResponsive">
          <table>
            <thead>
              <tr>
                <th className="indntQunt">Types</th>
                <th className="indntQunt">Budget Head</th>
                <th className="widthMin120">Due Date</th>
                <th className="indntQunt">Amount</th>
                <th className="indntQunt">Description</th>
                <th className="indntQunt">Document</th>
                <th className="mw80"></th>
              </tr>
            </thead>
            <tbody>
              {state.formDataCopy.sips.length > 0 &&
                state.formDataCopy.sips.map((item, index) => {
                  if (item.editMode) {
                    return (
                      <tr>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectItems">Types</InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectItems"
                                id="select-Items"
                                value={item.type}
                                label="Types"
                                onChange={(e) => {
                                  const temp = [...state.formDataCopy.sips];
                                  temp[index].type = dispatch({
                                    type: "addItemToSips",
                                    payload: temp,
                                  });
                                  //   const data = e.target.value;
                                  //   let dateParts =
                                  //     data.dueRenewalDate.split("-");
                                  //   let dayObject = new Date(
                                  //     dateParts[2],
                                  //     dateParts[1] - 1,
                                  //     dateParts[0]
                                  //   );
                                  //   temp[index].dueDate = dayObject;
                                  //   temp[index].type = data;
                                  //   setStatutoryList(temp);
                                }}
                              >
                                {typeList &&
                                  typeList.map((type) => {
                                    return (
                                      <MenuItem value={type.name}>
                                        {type.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectLedgerHead">
                                Budget Head
                              </InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectLedgerHead"
                                id="select-LedgerHead"
                                label="Budget Head"
                                value={item.budgetHeadId}
                                // onChange={(e) => {
                                //   const temp = [...statutoryList];
                                //   temp[index].budgetHead = e.target.value;
                                //   setStatutoryList(temp);
                                // }}
                              >
                                {budgetHeadList.length > 0 &&
                                  budgetHeadList.map((itemName, index) => (
                                    <MenuItem
                                      value={itemName.id}
                                      key={itemName.head + "_" + index}
                                    >
                                      {itemName.head}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel
                                id="setRepeatUntilDate"
                                className="setCompletionDate"
                              >
                                Due Date
                              </InputLabel>
                              <ReactDatePicker
                                showIcon
                                labelId="setRepeatUntilDate"
                                className="formDatePicker"
                                minDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                                selected={DateUtils.convertToDate(
                                  state.formDataCopy.sips[index].dueDate
                                )}
                                // onChange={(newDate) => {
                                //   const temp = [...statutoryList];
                                //   temp[index].dueDate = newDate;
                                //   setStatutoryList(temp);
                                // }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Amount"
                                variant="outlined"
                                className="formTextFieldArea"
                                fullWidth
                                size="small"
                                autoComplete="off"
                                value={state.formDataCopy.sips[index].amount}
                                // onChange={(e) => {
                                //   const temp = [...statutoryList];
                                //   let value = e.target.value;
                                //   if (isNaN(value)) {
                                //     return;
                                //   }
                                //   temp[index].amount = value;
                                //   setStatutoryList(temp);
                                // }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Decription"
                                variant="outlined"
                                className="formTextFieldArea"
                                fullWidth
                                size="small"
                                autoComplete="off"
                                value={
                                  state.formDataCopy.sips[index].description
                                }
                                // onChange={(e) => {
                                //   const temp = [...statutoryList];
                                //   temp[index].description = e.target.value;
                                //   setStatutoryList(temp);
                                // }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <IconButton
                            aria-label="addFile"
                            onClick={(e) => {
                              console.log("icon button clicked");
                              const inputElement = document.querySelector(
                                `#file_input_${index}`
                              );
                              if (inputElement) {
                                inputElement.click();
                              }
                            }}
                          >
                            <FilePlus />
                          </IconButton>
                          {item.sipFileName && (
                            <div>
                              <span>{item.sipFileDataLink}</span>
                              <IconButton
                                onClick={() => {
                                  // const temp=[...temp]
                                }}
                              >
                                <Trash />
                              </IconButton>
                            </div>
                          )}

                          <input
                            style={{ display: "none" }}
                            type="file"
                            id={`file_input_${index}`}
                            // onChange={async (e) => {
                            //   const files = e.target.files;
                            //   const data = await getFileDataObj(files[0]);
                            //   const temp = [...statutoryList];
                            //   temp[index].document = data;
                            //   setStatutoryList(temp);
                            // }}
                          />
                        </td>
                        <td>
                          <div className="iconBtnGroup">
                            {item.editMode === true && (
                              <Button
                                className="saveBtn"
                                onClick={(e) => {
                                  setIsEditing(false);
                                  dispatch({
                                    type: "editStatutory",
                                    payload: index,
                                  });
                                }}
                              >
                                Save
                              </Button>
                            )}
                            <IconButton
                              onClick={() => {
                                dispatch({
                                  type: "removeSIPRowByIndex",
                                  payload: index,
                                });
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td>
                          <div className="textOnly">{item.type}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.budgetHeadName}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.dueDate}</div>
                        </td>

                        <td>
                          <div className="textOnly">{item.amount}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.description}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.sipFileName}</div>
                        </td>
                        <td>
                          {console.log(
                            "***********  disabled = **********",
                            isEditing && item.editMode === false
                          )}

                          <div className="iconBtnGroup">
                            {item.editMode === false && (
                              <Tooltip title={"Edit Item"} placement="left">
                                <IconButton
                                  className={
                                    item.editMode === false &&
                                    !isEditing &&
                                    `deactivatedEditButton`
                                  }
                                  disabled={
                                    isEditing && item.editMode === false
                                  }
                                  onClick={() => {
                                    setIsEditing(true);
                                    const temp = [...state.formDataCopy.sips];
                                    temp[index].editMode = true;
                                    dispatch({
                                      type: "updateSip",
                                      payload: temp,
                                    });
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )}
                            <IconButton
                              onClick={() => {
                                if (item.editMode === true) {
                                  setIsEditing(false);
                                }
                                dispatch({
                                  type: "removeSIPRowByIndex",
                                  payload: index,
                                });
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })}

              <tr>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectItems">Types</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectItems"
                        id="select-Items"
                        value={allTimeFormData.type}
                        label="Type"
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          const data = e.target.value;
                          let dayObject = "";
                          if (data.dueRenewalDate) {
                            let dateParts = data.dueRenewalDate.split("-");
                            dayObject = new Date(
                              dateParts[2],
                              dateParts[1] - 1,
                              dateParts[0]
                            );
                          }
                          temp.dueDate = dayObject;

                          temp.type = data;
                          setAllTimeFormData(temp);
                        }}
                      >
                        {typeList &&
                          typeList.map((type) => {
                            return (
                              <MenuItem value={type}>{type.name}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectLedgerHead">Budget Head</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectLedgerHead"
                        id="select-LedgerHead"
                        label="Budget Head"
                        value={allTimeFormData.budgetHead}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.budgetHead = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      >
                        {budgetHeadList.length > 0 &&
                          budgetHeadList.map((itemName, index) => (
                            <MenuItem
                              value={itemName}
                              key={itemName.head + "_" + index}
                            >
                              {itemName.head}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel
                        id="setRepeatUntilDate"
                        className="setCompletionDate"
                      >
                        Due Date
                      </InputLabel>
                      <ReactDatePicker
                        showIcon
                        labelId="setRepeatUntilDate"
                        className="formDatePicker"
                        minDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        selected={allTimeFormData.dueDate}
                        onChange={(newDate) => {
                          const temp = { ...allTimeFormData };
                          temp.dueDate = newDate;
                          setAllTimeFormData(temp);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Amount"
                        variant="outlined"
                        className="formTextFieldArea"
                        fullWidth
                        size="small"
                        autoComplete="off"
                        value={allTimeFormData.amount}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          let value = e.target.value;
                          if (isNaN(value)) {
                            return;
                          }
                          temp.amount = value;
                          setAllTimeFormData(temp);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Decription"
                        variant="outlined"
                        className="formTextFieldArea"
                        fullWidth
                        size="small"
                        autoComplete="off"
                        value={allTimeFormData.description}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.description = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      aria-label="addFile"
                      onClick={(e) => {
                        console.log("icon button clicked");
                        const inputElement =
                          document.querySelector(`#file_input_`);
                        if (inputElement) {
                          inputElement.click();
                        }
                      }}
                    >
                      <FilePlus />
                    </IconButton>
                    {allTimeFormData.document && (
                      <div>
                        <span>{allTimeFormData.document.fileName}</span>
                        <IconButton
                          onClick={() => {
                            const temp = { ...allTimeFormData };
                            temp.document = null;
                            setAllTimeFormData(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    )}
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id={`file_input_`}
                      onChange={async (e) => {
                        const files = e.target.files;
                        console.log("files are", files);
                        const data = await getFileDataObj(files[0]);
                        const temp = { ...allTimeFormData };
                        temp.document = data;
                        setAllTimeFormData(temp);
                        e.target.value = null;
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className="iconBtnGroup">
                    <Button
                      className="saveBtn"
                      onClick={(e) => {
                        const temp = { ...allTimeFormData, editMode: false };
                        const tempBool = handleAddDataToList(temp);

                        tempBool && initializeAllTimeForeData();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
