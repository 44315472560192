import {
  ArrowForward,
  Cancel,
  CancelOutlined,
  CheckCircleOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CheckCircle } from "react-bootstrap-icons";
import { validateEmail } from "../../../utils/Utils";
import { toast } from "react-toastify";
import LoginSignupService from "../../../services/LoginSignupService";
import { SELECT_MenuProps } from "../../../constants/const_string";

export const ForgotPassword = ({
  handleSuccess,
  loginActiveTab,
  setLoginActiveTab,
}) => {
  const loginIdRef = useRef(null);

  const [forgtEmailValue, setForgtEmailValue] = useState("");
  const [loadingforEmailValue, setLoadingForEmailValue] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [orgListFP, setOrgListFP] = useState([]);
  const [selectOrgFP, setSelectOrgFP] = React.useState("");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userLoginIdSU, setUserLoginIdSU] = useState(null);

  const [otpSU, setOtpSU] = useState(null);
  const [otpIdSU, setOtpIdSU] = useState(null);
  const [fetchingOrgListSU, setFetchingOrgListSU] = useState(false);
  const [selectOrgSU, setSelectOrgSU] = React.useState("");
  const [otpValidatedSU, setOtpValidatedSU] = useState(false);

  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [showForgotPassForm, setShowForgotPassForm] = useState(false);

  const [sendingOtp, setSendingOtp] = useState(false);
  const [validatingOtp, setValidatingOtp] = useState(false);

  const [checkingCreds, setCheckingCreds] = useState(false);
  const [otpId, setOtpId] = useState(null);
  const [otp, setOtp] = useState(null);
  const [userLoginIdFP, setUserLoginIdFP] = useState(null);
  const [showSignupLink, setShowSignUpLink] = useState(false);
  const [orgNotFound, setOrgNotFound] = useState(false);

  const [isValid, setIsValid] = useState(false);

  let host = window.location.host.replace("www.", "");
  const domainArray = host.split(".");
  let subdomain = null;
  if (domainArray.length === 3) {
    subdomain = domainArray[0];
  }

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const getPasswordStrength = (password) => {
    const meterBar = document.getElementById("meter-bar");
    const meterStatus = document.getElementById("meter-status");

    let strength = 0;

    if (password.match(/[A-Z]/)) {
      strength += 1;
    }

    if (password.match(/[a-z]/)) {
      strength += 1;
    }

    if (password.match(/[0-9]/)) {
      strength += 1;
    }

    if (password.match(/[!@#$%^&*(),.?":{}|<>]/)) {
      strength += 1;
    }

    if (password.length >= 16) {
      strength += 1;
    }

    if (password.length < 8) {
      meterBar.style.background = "#a7a7a7";
      meterBar.style.width = "5%";
      meterStatus.style.color = "#a7a7a7";
      meterStatus.innerText = "Poor";
      return;
    }

    if (strength === 3) {
      meterBar.style.background = "#e6ae01";
      meterBar.style.width = "50%";
      meterStatus.style.color = "#e6ae01";
      meterStatus.innerText = "Medium";
    } else if (strength === 4) {
      meterBar.style.background = "#0f98e2";
      meterBar.style.width = "75%";
      meterStatus.style.color = "#0f98e2";
      meterStatus.innerText = "Strong";
    } else if (strength === 5) {
      meterBar.style.background = "#00ff33";
      meterBar.style.width = "100%";
      meterStatus.style.color = "#00ff33";
      meterStatus.innerText = "Very Strong";
    } else {
      meterBar.style.background = "#ff0000";
      meterBar.style.width = "25%";
      meterStatus.style.color = "#ff0000";
      meterStatus.innerText = "Weak";
    }
  };

  useEffect(() => {
    const checkPasswordPolicy = () => {
      const hasUpperCase = newPassword.match(/[A-Z]/);
      const hasLowerCase = newPassword.match(/[a-z]/);
      const hasNumber = newPassword.match(/[0-9]/);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
      const isMinLength = newPassword.length >= 8;
      const isMatching = newPassword === confirmPassword;

      return (
        hasUpperCase &&
        hasLowerCase &&
        hasNumber &&
        hasSpecialChar &&
        isMinLength &&
        isMatching
      );
    };

    setIsValid(checkPasswordPolicy());
  }, [newPassword, confirmPassword]);

  const changePassword = async () => {
    const selectedOrgFP = orgListFP.find((obj) => obj.orgId === selectOrgFP);
    let reqDto = {
      userPassword: newPassword,
      userEmailId: forgtEmailValue.toLocaleLowerCase(),
      subdomain: selectedOrgFP.subdomain ? selectedOrgFP.subdomain : "",
    };
    const resChangePass = await LoginSignupService.changePassword(reqDto);

    if (resChangePass.data.returnValue === "0") {
      toast.error(resChangePass.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // setLoading(false);
    } else {
      toast.info(resChangePass.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

      try {
        await saveCredential({
          username: forgtEmailValue,
          password: newPassword,
        });
      } catch (e) {
        console.log(" credential save failed");
      }

      setOtpValidated(false);
      // setNewPassword(null);
      // setConfirmPassword(null);
      setOtp(null);
      setOtpId(null);
      setShowForgotPassForm(false);
      setUserLoginIdFP(null);
      setOrgListFP([]);
      setShowLoginForm(true);
      handleSuccess();
    }
  };

  const saveCredential = ({ username, password }) => {
    if (window.PasswordCredential && navigator.credentials) {
      const credentials = new window.PasswordCredential({
        id: username,
        password: password,
      });

      return navigator.credentials.store(credentials);
    }
  };

  const initializeExistingUser = () => {
    setShowForgotPassForm(false);
    setOrgListFP([]);
    setSelectOrgFP("");
    setOtpId(null);
    setOtpValidated(false);
    setValidatingOtp(false);
    setIsValid(false);
    setShowConfirmPassword(false);
    setNewPassword("");
    setConfirmPassword("");
    setForgtEmailValue("");
    setShowSignUpLink(false);
  };

  const sendOTP = async () => {
    const selectedOrgFP = orgListFP.find((obj) => obj.orgId === selectOrgFP);
    setSendingOtp(true);
    const resOtp = await LoginSignupService.generateOtpForPasswordResetNew(
      forgtEmailValue.toLocaleLowerCase(),
      selectedOrgFP.subdomain
    );

    console.log(resOtp.data);
    if (resOtp.data.returnValue === "1") {
      setOtpId(resOtp.data.message);
      toast.info(
        "Otp has been sent successfully, please check your spam folder if not received"
      );
    } else {
      toast.error("Something went wrong!");
    }
    setSendingOtp(false);
  };

  const handleChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
    getPasswordStrength(event.target.value);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginTabClick = (userType) => {
    if (loginActiveTab !== userType && userType === "userTypeExisting") {
      initializeExistingUser();
    }
    setLoginActiveTab(userType);
  };

  const validateOTP = async () => {
    if (!otp || otp.trim() === "") {
      toast.error("Please enter your OTP");
      return false;
    }
    setValidatingOtp(true);
    const resOTP = await LoginSignupService.validateOtp(otpId, otp);
    if (resOTP.data.returnValue === "-1") {
      toast.error(resOTP.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtp(false);
    } else if (resOTP.data.returnValue === "0") {
      toast.error(resOTP.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtp(false);
    } else {
      toast.info(`OTP verified successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtp(false);
      setOtpValidated(true);
    }
  };

  const getDetailsOfUser = async () => {
    setLoadingForEmailValue(true);

    if (!forgtEmailValue || forgtEmailValue.trim() === "") {
      toast.error("Please enter your Email id");
      setLoadingForEmailValue(false);
      return false;
    }

    if (!validateEmail(forgtEmailValue)) {
      toast.error("Please enter a valid Email id");
      setLoadingForEmailValue(false);
      return false;
    }

    const resOrgList = await LoginSignupService.getOrgListByLoginId(
      forgtEmailValue.toLowerCase()
    );
    console.log("resOrgList ------", resOrgList.data);
    setLoadingForEmailValue(false);

    setOrgListFP(resOrgList.data);

    setOrgListFP(resOrgList.data);
    if (resOrgList.data.length === 0) {
      toast.error(
        "No organization associated with this email address.Please Check Email or Sign up"
      );
      setShowSignUpLink(true);
      setOrgNotFound(true);

      return;
    } else {
      setShowSignUpLink(false);
      setOrgNotFound(false);
    }
    setOtp(null);
    setOtpId(null);

    // const domainArray = host.split(".");

    // let subdomain = null;
    // if (domainArray.length === 3) {
    //   subdomain = domainArray[0];

    if (domainArray.length === 2) {
      const personalOrg = resOrgList.data.find(
        (org) => org.orgId === "ORG000000000000"
      );

      if (personalOrg) {
        setSelectOrgFP(personalOrg.orgId);
      } else {
        setShowSignUpLink(true);
        setOrgNotFound(false);
        return;
      }
    } else if (domainArray.length === 3) {
      const subdomain = domainArray[0];
      const orgDetail = resOrgList.data.find(
        (org) => org.subdomain === subdomain
      );
      if (orgDetail) {
        setSelectOrgFP(orgDetail.orgId);
      } else {
        setShowSignUpLink(true);
        setOrgNotFound(false);
        return;
      }
    }
  };

  return (
    <>
      <div className="fromGroup ">
        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel htmlFor="userEmailId">Email Id</InputLabel>
            <OutlinedInput
              ref={loginIdRef}
              id="userEmailId"
              className="formTextFieldArea areaWbtn"
              type="text"
              label="Email Id"
              endAdornment={
                <InputAdornment position="end">
                  {loadingforEmailValue ? (
                    <>
                      <CircularProgress className="loading-bar-fetch-org" />
                    </>
                  ) : otpIdSU ? (
                    <IconButton
                      edge="end"
                      onClick={() => {
                        setShowForgotPassForm(false);
                      }}
                    >
                      <CancelOutlined />
                    </IconButton>
                  ) : (
                    <IconButton edge="end" onClick={getDetailsOfUser}>
                      <ArrowForward />
                    </IconButton>
                  )}
                </InputAdornment>
              }
              value={forgtEmailValue}
              onChange={(event) => {
                let value = event.target.value;
                value = value.trim();
                setForgtEmailValue(value);
              }}
            />
          </FormControl>
        </div>

        <div className="formElement">
          {orgListFP && orgListFP.length > 0 && !showSignupLink && (
            <FormControl className="formControl">
              <InputLabel id="selectOrg">Select Organization</InputLabel>
              <Select
                disabled
                className="formInputField"
                variant="outlined"
                labelId="selectOrg"
                id="organization-select"
                value={selectOrgFP}
                label="Select Organization"
                // onChange={handleChangeSelectOrgFP}
                MenuProps={SELECT_MenuProps}
              >
                {orgListFP.map((orgDto) => {
                  return (
                    <MenuItem value={orgDto.orgId} key={orgDto.orgId}>
                      {orgDto.orgName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </div>

        {otpId && !otpValidated && !showSignupLink && (
          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel htmlFor="userEmailId">Validate OTP </InputLabel>
              <OutlinedInput
                id="userEmailId"
                className="formTextFieldArea areaWbtn padR0"
                type="text"
                onChange={(event) => {
                  setOtp(event.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    {validatingOtp ? (
                      <Button edge="end">
                        <CircularProgress className="loading-bar-fetch-org" />
                      </Button>
                    ) : (
                      <Button
                        endIcon={<CheckCircleOutline />}
                        edge="end"
                        className="otpBtn"
                        onClick={validateOTP}
                      >
                        Validate
                      </Button>
                    )}
                  </InputAdornment>
                }
                label="Validate OTP"
              />
            </FormControl>
          </div>
        )}

        {otpValidated && (
          <>
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel htmlFor="outlined-adornment-new-password">
                  New Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-new-password"
                  className="formTextFieldArea areaPassword"
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={handleChangeNewPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel htmlFor="outlined-adornment-confirm-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-confirm-password"
                  className="formTextFieldArea areaPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={handleChangeConfirmPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                />
              </FormControl>
            </div>
            <div className="passwordPolocy">
              <div className="meterWithText">
                <div className="meter" id="meter">
                  <div className="meter-bar" id="meter-bar"></div>
                </div>
                <div className="meter-text" id="meter-text">
                  <span className="meterHead">Password strength: </span>
                  <span className="meter-status" id="meter-status">
                    Too Short
                  </span>
                </div>
              </div>

              <div className="passwrdPlcyCheckGrp">
                <div className="passwrdPlcyCheckHead">
                  Password should contain
                </div>

                <div className="passwrdPlcyCheck">
                  <span id="one-upper-case-check">
                    {newPassword.match(/[A-Z]/) <= 1 ? (
                      <Cancel className="cancelIcon" />
                    ) : (
                      <CheckCircle className="checkIcon" />
                    )}
                  </span>
                  <span>One upper case letter</span>
                </div>
                <div className="passwrdPlcyCheck">
                  <span id="one-lower-case-check">
                    {newPassword.match(/[a-z]/) <= 1 ? (
                      <Cancel className="cancelIcon" />
                    ) : (
                      <CheckCircle className="checkIcon" />
                    )}
                  </span>
                  <span>One lower case lettere</span>
                </div>
                <div className="passwrdPlcyCheck">
                  <span id="one-number-check">
                    {newPassword.match(/[0-9]/) < 1 ? (
                      <Cancel className="cancelIcon" />
                    ) : (
                      <CheckCircle className="checkIcon" />
                    )}
                  </span>
                  <span>One Number</span>
                </div>
                <div className="passwrdPlcyCheck">
                  <span id="one-specialChar-check">
                    {/[!@#$%^&*(),.?":{}|<>]/.test(newPassword) < 1 ? (
                      <Cancel className="cancelIcon" />
                    ) : (
                      <CheckCircle className="checkIcon" />
                    )}
                  </span>
                  <span>Special character</span>
                </div>
                <div className="passwrdPlcyCheck">
                  <span id="min-length-check">
                    {newPassword.length < 8 ? (
                      <Cancel className="cancelIcon" />
                    ) : (
                      <CheckCircle className="checkIcon" />
                    )}
                  </span>
                  <span>Min 8 characters</span>
                </div>
                <div className="passwrdPlcyCheck">
                  <span id="pass-match-check">
                    {newPassword === "" || newPassword !== confirmPassword ? (
                      <Cancel className="cancelIcon" />
                    ) : (
                      <CheckCircle className="checkIcon" />
                    )}
                  </span>
                  <span>Passwords mismatch </span>
                </div>
              </div>
            </div>{" "}
          </>
        )}
      </div>
      {checkingCreds ? (
        <Button variant="contained" className="lrButton">
          <CircularProgress className="loading-bar-check-creds" />
        </Button>
      ) : (
        <>
          {!otpValidated ? (
            <>
              <Button
                disabled={selectOrgFP === "" || (otpId && !otpValidated)}
                variant="contained"
                className="lrButton"
                onClick={() => {
                  sendOTP();
                }}
              >
                Send OTP
              </Button>

              {showSignupLink && (
                <>
                  {/* {!orgNotFound &&
                                         } */}
                  <p className="lrNotes">
                    {" "}
                    You Cannot Change Password For This email.
                  </p>
                  {/* {orgNotFound && 
                                         <p className="lrNotes">
                                         {" "}
                                         You Cannot Change Password For This
                                         email. Consider
                                         <span className="fogetPass actionText">
                                           {" "}
                                           Sign Up{" "}
                                         </span>{" "}
                                         Or{" "}
                                         <span className="fogetPass actionText">
                                           {" "}
                                           Join as Guest{" "}
                                         </span>
                                       </p>
                                         } */}
                </>
              )}
            </>
          ) : (
            <Button
              variant="contained"
              className="lrButton"
              disabled={!isValid}
              onClick={changePassword}
            >
              Change password
            </Button>
          )}
        </>
      )}
      <div
        className="fogetPass actionText"
        onClick={() => {
          // initializeExistingUser();
          handleSuccess();
        }}
      >
        Login to your account
      </div>
    </>
  );
};
