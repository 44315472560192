import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Person, PersonCheck, XLg } from "react-bootstrap-icons";
import lrLogin from "../images/login.png";
import {
  ArrowForward,
  Visibility,
  VisibilityOff,
  CancelOutlined,
} from "@mui/icons-material";
import LoginSignupService from "../../../services/LoginSignupService";
import { toast } from "react-toastify";

import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  PERSONAL_ORG_ID,
  SELECT_MenuProps,
} from "../../../constants/const_string";
import { getEventDetailsByMeetingId } from "../../../services/MeetingService";
import { v4 } from "uuid";
import { getLoginUserDetails } from "../../../utils/Utils";
import { jwtDecode } from "jwt-decode";
import { getAllDetailsByShareHash } from "../../../services/DriveService";
import { ForgotPassword } from "./ForgotPassword";

export default function OpenSharedFileDrive({ handelCloseLogRegContain }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPasswordLogin = (event) => {
    event.preventDefault();
  };

  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [showForgotPassForm, setShowForgotPassForm] = useState(false);

  const handelOpenLogin = () => {
    setShowLoginForm(true);
    setShowRegistrationForm(false);
    setShowForgotPassForm(false);
  };
  const handelOpenRegistration = () => {
    setShowLoginForm(false);
    setShowRegistrationForm(true);
    setShowForgotPassForm(false);
  };

  const handelOpenForgotPassword = () => {
    setUserLoginId(null);
    setShowForgotPassForm(true);
    // setShowLoginForm(false);
    setShowRegistrationForm(false);
  };

  // register user password start

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isValid, setIsValid] = useState(false);

  // to check if all password policy conditions are met
  //   useEffect(() => {
  //     const checkPasswordPolicy = () => {
  //       const hasUpperCase = newPassword.match(/[A-Z]/);
  //       const hasLowerCase = newPassword.match(/[a-z]/);
  //       const hasNumber = newPassword.match(/[0-9]/);
  //       const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
  //       const isMinLength = newPassword.length >= 8;
  //       const isMatching = newPassword === confirmPassword;

  //       return (
  //         hasUpperCase &&
  //         hasLowerCase &&
  //         hasNumber &&
  //         hasSpecialChar &&
  //         isMinLength &&
  //         isMatching
  //       );
  //     };

  //     setIsValid(checkPasswordPolicy());
  //   }, [newPassword, confirmPassword]);

  const handleChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
    getPasswordStrength(event.target.value);
  };

  const saveCredential = ({ username, password }) => {
    if (window.PasswordCredential && navigator.credentials) {
      const credentials = new window.PasswordCredential({
        id: username,
        password: password,
      });

      return navigator.credentials.store(credentials);
    }
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getPasswordStrength = (password) => {
    const meterBar = document.getElementById("meter-bar");
    const meterStatus = document.getElementById("meter-status");

    let strength = 0;

    if (password.match(/[A-Z]/)) {
      strength += 1;
    }

    if (password.match(/[a-z]/)) {
      strength += 1;
    }

    if (password.match(/[0-9]/)) {
      strength += 1;
    }

    if (password.match(/[!@#$%^&*(),.?":{}|<>]/)) {
      strength += 1;
    }

    if (password.length >= 16) {
      strength += 1;
    }

    if (password.length < 8) {
      meterBar.style.background = "#a7a7a7";
      meterBar.style.width = "5%";
      meterStatus.style.color = "#a7a7a7";
      meterStatus.innerText = "Poor";
      return;
    }

    if (strength === 3) {
      meterBar.style.background = "#e6ae01";
      meterBar.style.width = "50%";
      meterStatus.style.color = "#e6ae01";
      meterStatus.innerText = "Medium";
    } else if (strength === 4) {
      meterBar.style.background = "#0f98e2";
      meterBar.style.width = "75%";
      meterStatus.style.color = "#0f98e2";
      meterStatus.innerText = "Strong";
    } else if (strength === 5) {
      meterBar.style.background = "#00ff33";
      meterBar.style.width = "100%";
      meterStatus.style.color = "#00ff33";
      meterStatus.innerText = "Very Strong";
    } else {
      meterBar.style.background = "#ff0000";
      meterBar.style.width = "25%";
      meterStatus.style.color = "#ff0000";
      meterStatus.innerText = "Weak";
    }
  };

  // register user password end

  //login section

  //login id is email id
  const [userLoginId, setUserLoginId] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [orgList, setOrgList] = useState([]);
  const [fetchingOrgList, setFetchingOrgList] = useState(false);
  const loginIdRef = useRef(null);
  const [checkingCreds, setCheckingCreds] = useState(false);

  const [selectOrg, setSelectOrg] = React.useState("");

  //login with room id code
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [shareHash, setShareHash] = useState(null);
  const [selOrgDetails, setSelOrgDetails] = useState(null);
  const [userName, setUserName] = useState(null);
  const [driveFileDetails, setDriveFileDetails] = useState(null);

  useEffect(() => {
    if (searchParams.get("sh")) {
      setShareHash(searchParams.get("sh"));
    }
    console.log("shareHash link", searchParams.get("sh"));

    if (subdomain) {
      LoginSignupService.getOrgDetailsBySubdomain(subdomain).then(
        (response) => {
          console.log(response.data);
          setSelOrgDetails(response.data);
        }
      );
    } else {
      setSelOrgDetails({ orgName: "Personal" });
    }

    getAllDetailsByShareHash(searchParams.get("sh")).then((response) => {
      console.log("getAllDetailsByShareHash", response.data);
      setDriveFileDetails(response.data);
      if (localStorage.getItem("token") != null) {
        const userDetails = jwtDecode(localStorage.getItem("token"));
        if (userDetails.userId === response.data.userId) {
          if (response.data) {
            navigate(`/dashboard`, {
              state: {
                driveFileDetails: response.data,
              },
            });
          } else {
            toast.error("The file link is invalid", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setCheckingCreds(false);
          }
        } else {
          toast.error(
            "The logged in user does not have permission to view the file, please login with the mail id where the file link was received",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      }
    });
  }, []);

  let host = window.location.host.replace("www.", "");
  const domainArray = host.split(".");
  let subdomain = null;
  if (domainArray.length === 3) {
    subdomain = domainArray[0];
  }

  const loginAndJoinMeeting = async () => {
    console.log("roomId link", shareHash, subdomain, driveFileDetails);
    // return;
    if (!userLoginId || userLoginId.trim() === "") {
      toast.error("Please enter your Email id");
      loginIdRef.current.focus();
      // setFetchingOrgList(false);
      return false;
    }

    if (!userPassword || userPassword.trim() === "") {
      toast.error("Please enter your password");
      return false;
    }

    setCheckingCreds(true);
    let orgId = PERSONAL_ORG_ID;
    if (subdomain) {
      const resGetOrgs = await LoginSignupService.getOrgListByLoginId(
        userLoginId.toLowerCase()
      );

      if (resGetOrgs.data && resGetOrgs.data.length > 0) {
        console.log(resGetOrgs.data);
        const orgDetails = resGetOrgs.data.find(
          (obj) => obj.subdomain === subdomain
        );
        if (orgDetails) {
          orgId = orgDetails.orgId;
        } else {
          toast.error("Invalid credentials...", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCheckingCreds(false);
          return;
        }
      } else {
        toast.error("Invalid credentials...", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setCheckingCreds(false);
        return;
      }
    }

    let reqDto = {
      username: userLoginId.toLowerCase() + "~" + orgId,
      password: userPassword,
    };
    console.log("onSubmitSignIn", reqDto);
    try {
      const resLogin = await LoginSignupService.userLogin(reqDto);

      if (resLogin.status === 401 || resLogin.data === "INVALID_CREDENTIALS") {
        toast.error("Invalid credentials", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setCheckingCreds(false);
        return;
      }

      try {
        await saveCredential({ username: userLoginId, password: userPassword });
      } catch (e) {
        console.log("Error saving credentials");
      }

      localStorage.clear();
      const userDetails = jwtDecode(resLogin.data.token);
      if (userDetails.userId !== driveFileDetails.userId) {
        toast.error(
          "The credentials used does not have permission to view the file, please login with the credentials of the mail id where the file link was received",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setCheckingCreds(false);
        return;
      }
      localStorage.setItem("token", resLogin.data.token);
      const tokenData = jwtDecode(resLogin.data.token);
      localStorage.setItem("userProfileImg", tokenData.userProfileImage);
      if (driveFileDetails) {
        if (driveFileDetails.fileId) {
          navigate(`/dashboard`, {
            state: {
              driveFileDetails: driveFileDetails,
            },
          });
        } else {
          toast.error("The meeting link is invalid", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCheckingCreds(false);
        }
      } else {
        getAllDetailsByShareHash(searchParams.get("sh")).then((response2) => {
          console.log("joinVideoRoom", response2.data);
          if (response2.data) {
            navigate(`/dashboard`, {
              state: {
                driveFileDetails: response2.data,
              },
            });
          } else {
            toast.error("The meeting link is invalid", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setCheckingCreds(false);
          }
        });
      }
    } catch (err) {
      toast.error("Invalid credentials", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setCheckingCreds(false);
    }
  };

  //tabs login start

  const [loginActiveTab, setLoginActiveTab] = useState("userTypeExisting");

  const handleLoginTabClick = (userType) => {
    setLoginActiveTab(userType);
  };
  //tabs login end

  //

  return (
    <>
      <div className="logRegFullContainer">
        <div className="closelrCon">
          <IconButton onClick={handelCloseLogRegContain}>
            <XLg />
          </IconButton>
        </div>
        <div className="logRegContain">
          <div className="lrContainer">
            <div className="lrLeftContain">
              <div className="statLrImg">
                <img src={lrLogin} alt="statLrImg" />
              </div>
            </div>
            <div className="lrRightContain">
              {showLoginForm && (
                <div className="logRegForm">
                  <div className="lrHead">Open File</div>

                  <div className="loginTabs">
                    <div className="loginTabs-buttonsGrp">
                      {/* <button
                        className={
                          loginActiveTab === "userTypeGuest"
                            ? "loginTabBtn active"
                            : "loginTabBtn"
                        }
                        onClick={() => handleLoginTabClick("userTypeGuest")}
                      >
                        <Person /> Guest User
                      </button> */}
                      <button
                        className={
                          loginActiveTab === "userTypeExisting"
                            ? "loginTabBtn active"
                            : "loginTabBtn"
                        }
                        onClick={() => handleLoginTabClick("userTypeExisting")}
                      >
                        <PersonCheck /> Login
                      </button>
                    </div>
                    <div className="loginTabs-content">
                      {/* {loginActiveTab === "userTypeGuest" && (
                        <>
                          <div className="fromGroup ">
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  label="Full Name"
                                  required
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  onChange={(event) =>
                                    setUserName(event.target.value)
                                  }
                                />
                              </FormControl>
                            </div>
                          </div>
                          {checkingCreds ? (
                            <Button variant="contained" className="lrButton">
                              <CircularProgress className="loading-bar-check-creds" />
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              className="lrButton"
                              onClick={joinAsGuest}
                            >
                              Join as guest
                            </Button>
                          )}
                        </>
                      )} */}
                      {loginActiveTab === "userTypeExisting" && (
                        <>
                          {console.log("Passform is ...", showForgotPassForm)}
                          {!showForgotPassForm ? (
                            <>
                              <div className="fromGroup ">
                                <div className="formElement">
                                  <FormControl className="formControl">
                                    <InputLabel htmlFor="userEmailId">
                                      Email Id
                                    </InputLabel>
                                    <OutlinedInput
                                      ref={loginIdRef}
                                      id="userEmailId"
                                      className="formTextFieldArea areaWbtn"
                                      type="text"
                                      label="Email Id"
                                      value={userLoginId}
                                      onChange={(event) =>
                                        setUserLoginId(event.target.value)
                                      }
                                      autoComplete="new-password"
                                    />
                                  </FormControl>
                                </div>

                                <div className="formElement">
                                  <FormControl className="formControl">
                                    <InputLabel htmlFor="outlined-adornment-password">
                                      Password
                                    </InputLabel>
                                    <OutlinedInput
                                      id="outlined-adornment-password"
                                      className="formTextFieldArea areaPassword"
                                      type={showPassword ? "text" : "password"}
                                      value={userPassword}
                                      onChange={(event) =>
                                        setUserPassword(event.target.value)
                                      }
                                      autoComplete="new-password"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                              handleMouseDownPasswordLogin
                                            }
                                            edge="end"
                                          >
                                            {showPassword ? (
                                              <VisibilityOff />
                                            ) : (
                                              <Visibility />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      label="Password"
                                    />
                                  </FormControl>
                                </div>
                              </div>
                              {checkingCreds ? (
                                <Button
                                  variant="contained"
                                  className="lrButton"
                                >
                                  <CircularProgress className="loading-bar-check-creds" />
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  className="lrButton"
                                  onClick={loginAndJoinMeeting}
                                >
                                  Login
                                </Button>
                              )}
                            </>
                          ) : (
                            <>
                              <ForgotPassword
                                handleSuccess={() => {
                                  setShowForgotPassForm(false);
                                  setUserPassword("");
                                  setUserLoginId("");
                                }}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {!showForgotPassForm && (
                    <div
                      className="fogetPass actionText"
                      onClick={handelOpenForgotPassword}
                    >
                      Forgot Password?
                    </div>
                  )}

                  <p className="lrNotes">
                    <span>Notes: </span>Please enter the credentials with your{" "}
                    {selOrgDetails?.orgName} account on Zoyel One in which the
                    mail was received to view the file.
                  </p>

                  {/* ////////////PRASHANT CODE BACKUP//////////// */}
                  {/* <div className="fromGroup ">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label="Full Name"
                          required
                          variant="outlined"
                          className="formTextFieldArea"
                          onChange={(event) => setUserName(event.target.value)}
                        />
                      </FormControl>
                    </div>
                  </div>
                  {checkingCreds ? (
                    <Button variant="contained" className="lrButton">
                      <CircularProgress className="loading-bar-check-creds" />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="lrButton"
                      onClick={joinAsGuest}
                    >
                      Join as guest
                    </Button>
                  )} */}
                  {/* <div className="lrOr">OR</div>
                  <div className="fromGroup ">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel htmlFor="userEmailId">Email Id</InputLabel>
                        <OutlinedInput
                          ref={loginIdRef}
                          id="userEmailId"
                          className="formTextFieldArea areaWbtn"
                          type="text"
                          label="Email Id"
                          value={userLoginId}
                          onChange={(event) =>
                            setUserLoginId(event.target.value)
                          }
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          className="formTextFieldArea areaPassword"
                          type={showPassword ? "text" : "password"}
                          value={userPassword}
                          onChange={(event) =>
                            setUserPassword(event.target.value)
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPasswordLogin}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                      </FormControl>
                    </div>
                  </div>
                  {checkingCreds ? (
                    <Button variant="contained" className="lrButton">
                      <CircularProgress className="loading-bar-check-creds" />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="lrButton"
                      onClick={loginAndJoinMeeting}
                    >
                      Login
                    </Button>
                  )} */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
