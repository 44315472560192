import React from "react";
import PatientQueueCard from "../cards/PatientQueueCard";

export default function PatientQueueMenuComponent({
  handelOpenRightPQueue,
  dataList,
}) {
  return (
    <>
      <div className="mnuCompoContainer">
        <div className="mnuCompoIncomingInstanse">
          {dataList &&
            dataList.length > 0 &&
            dataList.map((patientQueueItem) => (
              <PatientQueueCard
                handelOpenRightPQueue={(data) => {
                  handelOpenRightPQueue(data);
                }}
                data={patientQueueItem}
              />
            ))}
        </div>
      </div>
    </>
  );
}
