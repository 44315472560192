import React, { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import "../css/WebinarChat.css";
import { meetingSocket as socketRTU } from "../../../../../../socket";
import DateUtils from "../../../../../../utils/DateUtils";
import WebinarService from "../../../../../../services/WebinarService";
import { Cancel, DragHandle, Send } from "@mui/icons-material";
import { getLoginUserDetails } from "../../../../../../utils/Utils";
import { toast } from "react-toastify";
import { XLg } from "react-bootstrap-icons";

const WebinarChat = (props) => {
  React.useEffect(() => {}, []);
  const userDetails = getLoginUserDetails();
  const [webinarChatData, setWebinarChatData] = useState([]);

  useEffect(() => {
    scrollToBottom();
  }, [webinarChatData]);

  React.useEffect(() => {
    WebinarService.getWebinarChat(props.roomId).then((response) => {
      setWebinarChatData(response.data);
      scrollToBottom();
    });

    document.addEventListener("keydown", handleKeyPress);
    socketRTU.emit("joinChatRoom", { roomId: props.roomId });
    socketRTU.on("receiveChatMessage", function (message, sentUserDtls) {
      console.log("receiveChatMessage", message, sentUserDtls);
      if (sentUserDtls.userId !== userDetails.userId) {
        let htmlOtgMsg =
          '<div class="message-wrapper incoming-msg">' +
          '<div class="message">' +
          "<span>" +
          message +
          "</span>" +
          '<div class="messageMeta">' +
          "<span>" +
          sentUserDtls.userName +
          "</span> &nbsp" +
          '<span class="chatDT">' +
          DateUtils.getDateInDDMMYYYYHH12MM(new Date()) +
          "</span>" +
          "</div>" +
          "</div>" +
          "</div>";
        console.log(navTabRef.current);
        // navTabRef?.current.append(htmlOtgMsg);
        navTabRef.current.innerHTML += htmlOtgMsg;
        scrollToBottom();
        //$("#nav-chat").append(htmlOtgMsg);
      }

      // socket.emit("my other event", { my: "data" });
    });
    return () => {
      socketRTU?.removeAllListeners(`receiveChatMessage`);
    };
  }, []); // Empty dependency array ensures the effect runs only on mount and unmount

  // const chatEndRef = React.useRef(null);

  const navTabRef = React.useRef(null);
  const inputMessage = React.useRef(null);

  const scrollToBottom = () => {
    if (navTabRef.current) {
      navTabRef.current.scrollTop = navTabRef.current.scrollHeight;
    }
  };
  const sendChatMessage = () => {
    const typedMess = inputMessage.current.value;
    console.log("sendChatMessage", typedMess);
    if (typedMess === "" || typedMess.trim() === "") {
      toast.error("Please enter some message", {
        position: toast.POSITION.TOP_RIGHT,
      });
      inputMessage.current.focus();
      return;
    }

    // return;
    // let userDetails = parseJwt(localStorage.getItem("webinar-token"));
    // console.log("sendChatMessage", typedMess, userDetails);
    let htmlOtgMsg =
      '<div class="message-wrapper outgoing-msg">' +
      '<div class="message">' +
      "<span>" +
      typedMess +
      "</span>" +
      '<div class="messageMeta">' +
      "<span>" +
      userDetails.userName +
      "</span> &nbsp" +
      '<span class="chatDT">' +
      DateUtils.getDateInDDMMYYYYHH12MM(new Date()) +
      "</span>" +
      "</div>" +
      "</div>" +
      "</div>";
    // document.getElementById("nav-chat").append(htmlOtgMsg);

    let reqDto = {
      userId: userDetails.userId,
      userName: userDetails.userName,
      chatText: typedMess,
      roomId: props.roomId,
      activeFlag: "Y",
    };

    WebinarService.addWebinarChat(reqDto).then((response) => {
      console.log("addWebinarChat response", response.data);
    });

    navTabRef.current.innerHTML += htmlOtgMsg;
    socketRTU.emit("sendChatMessage", {
      roomId: props.roomId,
      message: typedMess,
      userDetails: userDetails,
    });
    inputMessage.current.value = "";
    scrollToBottom();
  };

  const handleKeyPress = (event) => {
    // look for the `Enter` keyCode
    console.log("handleKeyPress-->>>>>", event.keyCode, event.which);
    // alert(1);
    if (event.keyCode === 13 || event.which === 13) {
      sendChatMessage();
    }
  };
  return (
    <>
      <div class="app-wrapper" id="app-wrapper">
        <div class="app-header nav nav-tabs" id="nav-tab" role="tablist">
          {/* <div className="nav-tab-header">Chat</div> */}
          <div className="metRtTitle"> Webinar Public Chat </div>
          <div className="metRtAction">
            <IconButton
              aria-label="Close"
              onClick={() => props.handleMenuCloseButton()}
            >
              <XLg />
            </IconButton>
          </div>
        </div>
        <div class="tab-content message-group" id="nav-tabConten">
          <div
            class="app-message-body tab-pane active show"
            id="nav-chat"
            role="tabpanel"
            aria-labelledby="nav-chat-tab"
            ref={navTabRef}
          >
            {webinarChatData?.map((webinarDto) => {
              if (userDetails.userId === webinarDto.userId) {
                return (
                  <>
                    <div class="message-wrapper outgoing-msg">
                      <div class="message">
                        <span>{webinarDto.chatText}</span>

                        <div class="messageMeta">
                          <span>{webinarDto.userName}</span> &nbsp;
                          <span class="chatDT">
                            {DateUtils.getDateInDDMMYYYYHH12MM(
                              webinarDto.createdDate
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <>
                      <div class="message-wrapper incoming-msg">
                        <div class="message">
                          <span>{webinarDto.chatText}</span>

                          <div class="messageMeta">
                            <span>{webinarDto.userName}</span> &nbsp;
                            <span class="chatDT">
                              {DateUtils.getDateInDDMMYYYYHH12MM(
                                webinarDto.createdDate
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  </>
                );
              }
            })}
          </div>
        </div>

        {/* <div class="app-input-area" id="app-input-area">
          <input
            type="text"
            placeholder="Your message here..."
            id="typedMessage"
            ref={inputMessage}
          />
          <button
            type="button"
            onClick={() => sendChatMessage()}
            // onKeyDown={() => handleKeyPress()}
          >
            <svg
              viewBox="0 0 512 512"
              x="0px"
              y="0px"
              //   style="enable-background: new 0 0 512 512"
              width="25"
              height="25"
              title="paper-plane"
            >
              <path
                fill=""
                d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
              />
            </svg>
          </button>
        </div> */}
        <div className="chatRegularFoot webinarChat">
          <div className="chatInputBox">
            <input
              type="text"
              placeholder="Type your message here!"
              // value={inputText}
              // onChange={(e) => {
              //   // setEmojiInputText(e.target.value);
              //   setInputText(e.target.value);
              // }}
              // onKeyDown={(e) => {
              //   if (e.key.toLowerCase() === "enter") {
              //     e.preventDefault();
              //     handleSendMessage();
              //   }
              // }}
              ref={inputMessage}
            />
          </div>
          <Send
            height={24}
            width={24}
            color="white"
            className="webinarChatSendMsg"
            onClick={sendChatMessage}
          />
        </div>
      </div>
    </>
  );
};

export default WebinarChat;
