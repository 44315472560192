import { Button, Modal } from "@mui/material";
import React, { useEffect } from "react";

export const CustomConfirmation = (
  //   {
  //   openModal,
  //   handleCloseModal,
  //   title,
  //   body,
  //   positiveButtonName,
  //   negativeButtonName,
  //   handlePositive,
  //   handleNegative,
  // }
  props
) => {
  const {
    openModal,
    handleCloseModal,
    title,
    body,
    positiveButtonName,
    negativeButtonName,
    handlePositive,
    handleNegative,
  } = props;

  useEffect(() => {
    console.log("Props value =", props);
  }, []);

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {((title && title !== "") || body) && (
        <div
          style={{
            // width: "250px",
            // height: "250px",
            maxWidth: "600px",
            minHeight: "150px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <h2 id="modal-modal-title">{title}</h2>
          {body && <>{body}</>}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button className="dfultPrimaryBtn" onClick={handlePositive}>
              {positiveButtonName}
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={handleNegative}>
              {negativeButtonName}
            </Button>
          </div>
        </div>
      )}
      {/* {(!title || (title && title === "")) && !body && (
        <div
          style={{
            // width: "250px",
            // height: "250px",
            maxWidth: "600px",
            minHeight: "150px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <h2 id="modal-modal-title">
            You have neigher provided Title or Body
          </h2>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button className="dfultPrimaryBtn" onClick={handleCloseModal}>
              Close
            </Button>
          </div>
        </div>
      )} */}
    </Modal>
  );
};
