import { Add, CloseFullscreen, OpenInFull } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CheckCircle, Eye, Trash, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import DashboardServices from "../../../../../services/DashboardServices";
import TaskService from "../../../../../services/TaskService";
import DateUtils from "../../../../../utils/DateUtils";
import { LinkedList } from "../../../../../utils/LinkedList";
import { getLoginUserDetails } from "../../../../../utils/Utils";

export default function PmsApprovalForm({ data, handleClose, refreshData }) {
  const loginUser = getLoginUserDetails();
  const [isExpanded, setIsExpanded] = useState(false);

  const [pmsPeriodFrom, setPmsPeriodFrom] = useState(null);
  const [pmsPeriodTo, setPmsPeriodTo] = useState(null);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [joinedBefore, setJoinedBefore] = useState(null);
  const [selfEvolutionLastDate, setSelfEvolutionLastDate] = useState(null);
  const [managerReviewLastDate, setManagerReviewLastDate] = useState(null);

  const [metricsList] = useState(new LinkedList());
  const [metricRefreshCount, setMetricRefreshCount] = useState(0);

  const [employeeStatusList, setEmployeeStatusList] = useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState([]);

  const [effectedEmployeeList, setEffectedEmployeeList] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const [metricNameText, setMetricNameText] = useState("");
  const [totalCurrentCtc, setTotalCurrentCtc] = useState(0);
  const [incrementPercent, setIncrementPercent] = useState(0);
  const [maxRating, setMaxRating] = useState(5);

  const [pmsApprovalData, setPmsApprovalData] = useState(null);
  const [showRejectModel, setShowRejectModel] = useState(false);
  const [showPostponeModel, setShowPostponeModel] = useState(false);

  const [remarkText, setRemarkText] = useState("");
  const [postponeTill, setPostponeTill] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      TaskService.getPmsById(data.formDataTransId)
        .then((response) => {
          if (response && response.data != null) {
            const d = response.data;
            setPmsPeriodFrom(DateUtils.convertToDate(d.cycleStartDate));
            setPmsPeriodTo(DateUtils.convertToDate(d.cycleEndDate));
            setEffectiveDate(
              _nextMonth1stDay(DateUtils.convertToDate(d.cycleEndDate))
            );
            setJoinedBefore(DateUtils.convertToDate(d.cycleStartDate));
            if (d.metrics && d.metrics.length > 0) {
              for (let i = 0; i < d.metrics.length; i++) {
                metricsList.add(d.metrics[i]);
              }
              setMaxRating(d.metrics[0].maxRating);
              setMetricRefreshCount((r) => r + 1);
            }
          }
        })
        .catch((err) => console.error(err.toString()));

      TaskService.getEmployeeStatusList(loginUser.orgId)
        .then((response) => {
          if (response && response.data != null) {
            setEmployeeStatusList(response.data);
            setSelectedStatusList(response.data);
          }
        })
        .catch((err) => console.error(err.toString()));
    };

    if (data.formDataTransId && data.formDataTransId !== "") {
      fetchData();
      const today = new Date();
      setSelfEvolutionLastDate(
        new Date(new Date().setDate(today.getDate() + 7))
      );
      setManagerReviewLastDate(
        new Date(new Date().setDate(today.getDate() + 14))
      );
    }

    return () => {
      metricsList.clear();
    };
  }, [data]);

  const _nextMonth1stDay = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 1);
  };

  const checkUncheckEmpStatus = (item) => {
    let tempList = [...selectedStatusList];
    if (selectedStatusList.includes(item)) {
      tempList = tempList.filter((x) => x !== item);
    } else {
      tempList.push(item);
    }
    setSelectedStatusList(tempList);
  };

  useEffect(() => {
    // const timer = setTimeout(() => {
    if (joinedBefore && selectedStatusList && selectedStatusList.length > 0) {
      const reqDto = {
        joingingDateBefore: DateUtils.getDateInDDMMYYYY(joinedBefore),
        empStatus: selectedStatusList,
      };
      TaskService.getPmsEmployees(loginUser.orgId, reqDto)
        .then((response) => {
          if (response && response.data != null) {
            let effectedList = [];
            for (let i = 0; i < response.data.length; i++) {
              const data = response.data[i];
              if (data.type === "A") {
                data["checked"] = true;
                effectedList.push(data);
              }
            }
            setEffectedEmployeeList(effectedList);
          }
        })
        .catch((err) => console.error(err.toString()));
    }
    // }, 2000);

    return () => {
      // clearTimeout(timer);
      setEffectedEmployeeList([]);
    };
  }, [joinedBefore, selectedStatusList]);

  useEffect(() => {
    if (employeeStatusList && employeeStatusList.length > 0) {
      const sum = effectedEmployeeList.reduce(
        (acc, { currentCtc, checked }) => (checked ? acc + currentCtc : acc),
        0
      );
      setTotalCurrentCtc(sum);
    }
  }, [effectedEmployeeList]);

  const _renderMetricsUiComponent_ = () => {
    const items = [];
    if (metricsList.size > 0) {
      var curr = metricsList.head;
      while (curr) {
        let item = curr.element;
        items.push(
          <div className="formElementGroup pmsMatricsList">
            <div className="formElement">
              <FormControl className="formControl">
                <div className="matrixHeadFlex">
                  <span>{item.metrics}</span>
                  <Tooltip title="Remove this metrics" arrow>
                    <IconButton
                      className="removeRowBtn "
                      onClick={() => {
                        metricsList.removeElement(item);
                        setMetricRefreshCount((r) => r + 1);
                      }}
                    >
                      <Trash />
                    </IconButton>
                  </Tooltip>
                </div>
                <RadioGroup
                  className="formRadioGroup"
                  labelId="setTasktype"
                  name="setTasktype"
                  value={item.metricsType}
                  onChange={(e) => {
                    const value = e.target.value;
                    item.metricsType = value;
                    if (value === "rating" || value === "both") {
                      item.maxRating = 5;
                    } else {
                      item.maxRating = 0;
                    }
                    setMetricRefreshCount((r) => r + 1);
                  }}
                >
                  <FormControlLabel
                    className="formRadioField"
                    value="rating"
                    control={<Radio />}
                    label="Rating"
                  />
                  <FormControlLabel
                    className="formRadioField"
                    value="text"
                    control={<Radio />}
                    label="Feedback"
                  />
                  <FormControlLabel
                    className="formRadioField"
                    value="both"
                    control={<Radio />}
                    label="Both"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        );
        curr = curr.next;
      }
    }
    return items;
  };

  const handleClickApprove = () => {
    if (metricsList && metricsList.size === 0) {
      toast.error("Please add metrics");
      return;
    }

    if (selectedStatusList && selectedStatusList.length === 0) {
      toast.error("Please select employees status");
      return;
    }

    const metrics = [];
    const userIds = [];

    let curr = metricsList.head;
    while (curr) {
      const data = curr.element;
      data.maxRating = maxRating;
      metrics.push(data);
      curr = curr.next;
    }

    for (let i = 0; i < effectedEmployeeList.length; i++) {
      const { checked, userId } = effectedEmployeeList[i];
      if (checked) userIds.push(userId);
    }

    if (userIds && userIds.length === 0) {
      toast.error("Please select at list one user for PMS.");
      return;
    }

    const obj = {
      pmsId: data.formDataTransId,
      cycleStartDate: DateUtils.getDateInDDMMYYYY(pmsPeriodFrom),
      cycleEndDate: DateUtils.getDateInDDMMYYYY(pmsPeriodTo),
      effectiveDate: DateUtils.getDateInDDMMYYYY(effectiveDate),
      applicableJoiningDate: DateUtils.getDateInDDMMYYYY(joinedBefore),
      selfEvaluationEndDate: DateUtils.getDateInDDMMYYYY(selfEvolutionLastDate),
      managerReviewEndDate: DateUtils.getDateInDDMMYYYY(managerReviewLastDate),
      metrics: metrics,
      userIds: userIds,
    };

    setPmsApprovalData(obj);
  };

  const handleConfirmApprove = async () => {
    if (remarkText === "") {
      toast.error("Please Provide Approval Remark");
      return;
    }

    const taskRequestObj = {
      taskDetailId: data.taskId,
      approvalRmks: remarkText,
    };

    const pmsRequestObj = {
      ...pmsApprovalData,
      approvalRmks: remarkText,
      taskDetailId: data.taskId,
    };

    setDataLoading(true);
    const pmsResponse = await TaskService.approvePMSRequest(
      loginUser.userId,
      loginUser.orgId,
      pmsRequestObj
    );
    if (
      pmsResponse &&
      pmsResponse.data &&
      pmsResponse.data.returnValue === "1"
    ) {
      taskRequestObj.additionalInfo = {
        PMS_ID: data.formDataTransId,
      };
    } else {
      toast.error(pmsResponse.data.message);
      setDataLoading(false);
      return;
    }

    DashboardServices.approveTask(loginUser.userId, taskRequestObj).then(
      (response) => {
        if (response && response.data) {
          toast.success(response.data.message);
          setPmsApprovalData(null);
          refreshData();
          handleClose();
        } else {
          setDataLoading(false);
          toast.error(response.data.message);
        }
      }
    );
  };

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={{
          width: isExpanded ? "100%" : "",
        }}
      >
        <div className="flottingContainer">
          {/* header start */}
          <div className="flotHeaderPanel">
            <div class="flotHedCon">PMS approval</div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={isExpanded ? "Exit Full Screen" : "Enter Full Screen"}
                >
                  <IconButton onClick={(e) => setIsExpanded((r) => !r)}>
                    {isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          {/* header end */}

          {/* body start */}
          <div className="flotContain">
            <div className="elementFormContainer ">
              {/* pms period from and to start */}
              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel
                      id="pmsPeriodFrom"
                      className="setCompletionDate"
                    >
                      PMS period from
                    </InputLabel>
                    <ReactDatePicker
                      showIcon
                      toggleCalendarOnIconClick
                      labelId="pmsPeriodFrom"
                      className="formDatePicker"
                      selected={pmsPeriodFrom}
                      minDate={new Date().setFullYear(
                        new Date().getFullYear - 2
                      )}
                      maxDate={new Date()}
                      dateFormat="dd-MMM-yyyy"
                      onChange={(date) => setPmsPeriodFrom(date)}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="pmsPeriodTo" className="setCompletionDate">
                      PMS period to
                    </InputLabel>
                    <ReactDatePicker
                      showIcon
                      toggleCalendarOnIconClick
                      labelId="pmsPeriodTo"
                      className="formDatePicker"
                      selected={pmsPeriodTo}
                      minDate={pmsPeriodFrom}
                      maxDate={new Date().setFullYear(
                        new Date().getFullYear + 2
                      )}
                      dateFormat="dd-MMM-yyyy"
                      onChange={setPmsPeriodTo}
                    />
                  </FormControl>
                </div>
              </div>
              {/* pms period from and to end */}

              {/* effective data and joined date start */}
              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel
                      id="effectiveDate"
                      className="setCompletionDate"
                    >
                      Expected effective date
                    </InputLabel>
                    <ReactDatePicker
                      showIcon
                      toggleCalendarOnIconClick
                      labelId="effectiveDate"
                      className="formDatePicker"
                      selected={effectiveDate}
                      minDate={pmsPeriodTo}
                      maxDate={new Date().setFullYear(
                        new Date().getFullYear + 2
                      )}
                      dateFormat="dd-MMM-yyyy"
                      onChange={setEffectiveDate}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel
                      id="effectiveDate"
                      className="setCompletionDate"
                    >
                      Applicable for employees who joined before
                    </InputLabel>
                    <ReactDatePicker
                      showIcon
                      toggleCalendarOnIconClick
                      labelId="effectiveDate"
                      className="formDatePicker"
                      selected={joinedBefore}
                      minDate={new Date().setFullYear(
                        new Date().getFullYear - 2
                      )}
                      maxDate={new Date()}
                      dateFormat="dd-MMM-yyyy"
                      onChange={setJoinedBefore}
                    />
                  </FormControl>
                </div>
              </div>
              {/* effective data and joined date end */}

              {/* self evolution and manager review date start */}
              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel
                      id="effectiveDate"
                      className="setCompletionDate"
                    >
                      Self evolution last date
                    </InputLabel>
                    <ReactDatePicker
                      showIcon
                      toggleCalendarOnIconClick
                      labelId="effectiveDate"
                      className="formDatePicker"
                      selected={selfEvolutionLastDate}
                      minDate={new Date()}
                      maxDate={new Date().setFullYear(
                        new Date().getFullYear + 2
                      )}
                      dateFormat="dd-MMM-yyyy"
                      value={selfEvolutionLastDate}
                      onChange={(date) => {
                        setSelfEvolutionLastDate(date);
                        setManagerReviewLastDate(
                          date.setDate(date.getDate() + 7)
                        );
                      }}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel
                      id="effectiveDate"
                      className="setCompletionDate"
                    >
                      Manager review last date
                    </InputLabel>
                    <ReactDatePicker
                      showIcon
                      toggleCalendarOnIconClick
                      labelId="effectiveDate"
                      className="formDatePicker"
                      selected={managerReviewLastDate}
                      minDate={pmsPeriodFrom}
                      dateFormat="dd-MMM-yyyy"
                      onChange={setManagerReviewLastDate}
                    />
                  </FormControl>
                </div>
              </div>
              {/* self evolution and manager review date end */}

              {/* select employee status start */}
              <div className="tskElemHeding">Employee status</div>
              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <div className="formRadioGroup">
                      {employeeStatusList && employeeStatusList.length > 0 && (
                        <>
                          {employeeStatusList.map((item) => {
                            return (
                              <FormControlLabel
                                className="formRadioField"
                                value={item}
                                control={
                                  <Checkbox
                                    checked={selectedStatusList.includes(item)}
                                    onChange={() => checkUncheckEmpStatus(item)}
                                  />
                                }
                                label={item}
                              />
                            );
                          })}
                        </>
                      )}
                    </div>
                  </FormControl>
                </div>
              </div>
              {/* select employee status end */}

              {/* show number of effected employees and show model start */}
              <div
                className="formElement"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="fontColorWhite">
                  Numbers of effected employees :{" "}
                  {
                    effectedEmployeeList.filter(
                      ({ checked }) => checked === true
                    ).length
                  }
                </p>
                <IconButton
                  className="qaAppDtl"
                  onClick={() => setShowTable(true)}
                >
                  <Eye />
                </IconButton>
              </div>
              {/* show number of effected employees and show model start */}

              {/* show excluded  effected employees list start */}
              {effectedEmployeeList.filter(({ checked }) => checked === false)
                .length > 0 && (
                <div className="formElement">
                  <p className="fontColorWhite">
                    Excluded employees (
                    {
                      effectedEmployeeList.filter(
                        ({ checked }) => checked === false
                      ).length
                    }
                    )
                  </p>
                  <ol>
                    {effectedEmployeeList
                      .filter(({ checked }) => checked === false)
                      .map((user) => (
                        <li className="fontColorWhite">{`${user.personName} (${user.functionName})`}</li>
                      ))}
                  </ol>
                </div>
              )}
              {/* show excluded  effected employees list end */}

              {/* calculate ctc by effected employees start */}
              <div
                className="formElement"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p className="fontColorWhite">
                  The current total CTC {totalCurrentCtc} increases by{" "}
                </p>
                <div className="formElement" style={{ width: "100px" }}>
                  <FormControl className="formControl">
                    <TextField
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder="Percent"
                      label="Enter percent"
                      value={incrementPercent}
                      type="number"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          setIncrementPercent(e.target.value);
                        }
                      }}
                    />
                  </FormControl>
                </div>
                <p className="fontColorWhite">
                  {" "}
                  the new CTC will be{" "}
                  {totalCurrentCtc + (totalCurrentCtc * incrementPercent) / 100}
                </p>
              </div>
              {/* calculate ctc by effected employees end */}

              {/* metrics start */}
              <div className="tskElemHeding">PMS metrics</div>

              {/* show and change max rating dropdown start */}
              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="compTime">Select max rating</InputLabel>
                    <Select
                      className="formInputField"
                      variant="outlined"
                      labelId="compTime"
                      id="compTime-select"
                      label="Select max rating"
                      value={maxRating}
                      onChange={(e) => setMaxRating(e.target.value)}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/* show and change max rating dropdown end */}

              {/* add new metrics input start */}
              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder="Enter metric name"
                      label="Enter metric name"
                      value={metricNameText}
                      onChange={(e) => setMetricNameText(e.target.value)}
                    />
                  </FormControl>
                </div>

                <Button
                  className="dfultPrimaryBtn"
                  startIcon={<Add />}
                  onClick={() => {
                    if (metricNameText !== "") {
                      const data = {
                        maxRating: maxRating,
                        metrics: metricNameText,
                        metricsType: "rating",
                        metricsId: 0,
                      };
                      metricsList.insertAt(data, 0);
                      setMetricNameText("");
                      setMetricRefreshCount((r) => r + 1);
                    }
                  }}
                >
                  Add
                </Button>
              </div>
              {/* add new metrics input end */}

              {metricRefreshCount > 0 && _renderMetricsUiComponent_()}
              {/* metrics end */}
            </div>
          </div>
          {/* body end */}

          {/* footer start */}
          <div className="flotFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                startIcon={<CheckCircle />}
                onClick={handleClickApprove}
              >
                Approve
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => setShowRejectModel(true)}
              >
                Reject
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => setShowPostponeModel(true)}
              >
                Postpone
              </Button>
            </div>
          </div>
          {/* footer end */}

          {/* show approval model start */}
          {pmsApprovalData && (
            <Box className="ModelBox" sx={{ boxShadow: 24, p: 4, zIndex: 10 }}>
              <div className="addMoreAgendaModel">
                <div className="modelTitle">Approve Task</div>

                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        className="modelTextFild"
                        required
                        label="Approval Remark"
                        variant="outlined"
                        multiline
                        rows={4}
                        maxRows={7}
                        value={remarkText}
                        onChange={(e) => setRemarkText(e.target.value)}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={() => {
                      if (!dataLoading) handleConfirmApprove();
                    }}
                  >
                    {!dataLoading ? "Submit" : "Submitting..."}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() => {
                      setRemarkText("");
                      setPmsApprovalData(null);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Box>
          )}
          {/* show approval model end */}

          {/* show reject model start */}
          {showRejectModel && (
            <Box className="ModelBox" sx={{ boxShadow: 24, p: 4, zIndex: 10 }}>
              <div className="addMoreAgendaModel">
                <div className="modelTitle">Reject Request</div>

                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        className="modelTextFild"
                        id="outlined-basic"
                        required
                        label="Rejection Reason"
                        variant="outlined"
                        multiline
                        rows={4}
                        maxRows={7}
                        value={remarkText}
                        onChange={(e) => setRemarkText(e.target.value)}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={() => {
                      if (!dataLoading) {
                        if (remarkText.trim() === "") {
                          toast.error("Please provide rejection reason");
                          return;
                        }
                        setDataLoading(true);
                        DashboardServices.rejectTask(
                          data.taskId,
                          loginUser.userId,
                          remarkText
                        ).then((response) => {
                          setDataLoading(false);
                          if (response.data.returnValue === "1") {
                            toast.success(response.data.message);
                            refreshData();
                            handleClose();
                          } else {
                            toast.error(response.data.message);
                          }
                        });
                      }
                    }}
                  >
                    {!dataLoading ? "Submit" : "Submitting..."}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() => {
                      setRemarkText("");
                      setShowRejectModel(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Box>
          )}
          {/* show reject model end */}

          {/* show postpone model start */}
          {showPostponeModel && (
            <Box className="ModelBox" sx={{ boxShadow: 24, p: 4, zIndex: 10 }}>
              <div className="addMoreAgendaModel">
                <div className="modelTitle">Postpone Approval Request</div>

                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        className="modelTextFild"
                        sx={{ color: "balck !important" }}
                        label="Postpone Reason*"
                        variant="outlined"
                        multiline
                        rows={4}
                        maxRows={7}
                        value={remarkText}
                        onChange={(e) => setRemarkText(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel
                        id="setCompletionDate"
                        className="setCompletionDate"
                      >
                        Postpone Till*
                      </InputLabel>
                      <ReactDatePicker
                        showIcon
                        toggleCalendarOnIconClick
                        labelId="setCompletionDate"
                        className="formDatePicker"
                        selected={postponeTill}
                        minDate={
                          new Date(new Date().setDate(new Date().getDate() + 1))
                        }
                        dateFormat="dd/MM/yyyy"
                        onChange={setPostponeTill}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={() => {
                      if (!dataLoading) {
                        if (remarkText.trim() === "") {
                          toast.error("Please Provide Postpone Reason");
                          return;
                        }

                        const day = postponeTill.getDate();
                        const month = postponeTill.getMonth() + 1; // Months are zero-based
                        const year = postponeTill.getFullYear();

                        // Pad single-digit day and month with leading zeros
                        const formattedDay = String(day).padStart(2, "0");
                        const formattedMonth = String(month).padStart(2, "0");

                        // Create the formatted date string in "dd-mm-yyyy" format
                        const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

                        const reqDto = {
                          postponedReason: remarkText,
                          postponedTill: formattedDate,
                        };
                        setDataLoading(true);
                        DashboardServices.postponedTask(
                          data.taskId,
                          loginUser.userId,
                          reqDto
                        ).then((response) => {
                          setDataLoading(false);
                          if (
                            response.data &&
                            response.data.returnValue === "1"
                          ) {
                            toast.success(response.data.message);
                            refreshData();
                            handleClose();
                          } else {
                            toast.error(response.data.message);
                          }
                        });
                      }
                    }}
                  >
                    {!dataLoading ? "Submit" : "Submitting..."}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() => {
                      setRemarkText("");
                      setShowPostponeModel(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Box>
          )}
          {/* show postpone model end */}
        </div>
      </div>

      {/* show users in table start */}
      {showTable && (
        <div className="bgOfset">
          <div className="bgOfsetOverlay"></div>
          <Box className="ModelBox pmsModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Numbers of effected employees</div>

              <div className="elementFormContainer">
                <div className="indentFormtable">
                  <div className="modResptable mxH-55vh">
                    <table>
                      <thead>
                        <tr>
                          <th className="mw80">
                            {effectedEmployeeList && (
                              <Checkbox
                                size="small"
                                defaultChecked={true}
                                onClick={(e) => {
                                  let tempList = [...effectedEmployeeList];
                                  for (let i = 0; i < tempList.length; i++) {
                                    tempList[i].checked = e.target.checked;
                                  }
                                  setEffectedEmployeeList(tempList);
                                }}
                              />
                            )}
                          </th>
                          <th className="widthMin120">Name</th>
                          <th className="widthMin120">Position</th>
                          <th className="widthMin120">Joining Date</th>
                          <th className="widthMin120">Current CTC</th>
                          <th className="widthMin120">Last Increment Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {effectedEmployeeList.map((user, index) => (
                          <tr>
                            <td>
                              <div className="textOnly">
                                <Checkbox
                                  size="small"
                                  checked={user.checked}
                                  onClick={() => {
                                    let tempList = [...effectedEmployeeList];
                                    tempList[index].checked =
                                      !tempList[index].checked;
                                    setEffectedEmployeeList(tempList);
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="textOnly">{user.personName}</div>
                            </td>
                            <td>
                              <div className="textOnly">{user.position}</div>
                            </td>

                            <td>
                              <div className="textOnly">{user.joiningDate}</div>
                            </td>
                            <td>
                              <div className="textOnly">{user.currentCtc}</div>
                            </td>
                            <td>
                              <div className="textOnly">
                                {user.lastIncrementDate}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => setShowTable(false)}
                >
                  Save & Close
                </Button>
              </div>
            </div>
          </Box>
        </div>
      )}
      {/* show users in table end */}
    </>
  );
}
