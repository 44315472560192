import { Add, CloseFullscreen, Edit, OpenInFull } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PreviewLOI from "./model/PreviewLOI";
import SalaryHeadAdd from "./model/SalaryHeadAdd";
import { useEffect } from "react";
import { getLoginUserDetails, validateEmail } from "../../../../utils/Utils";
import {
  getAllDepartment,
  getCountryList,
  getPositionsOfOrganization,
  getRoutineActivity,
  getStateList,
  getStaticDDOfOrg,
} from "../../../../services/AdminService";
import { Controller, useForm } from "react-hook-form";
import { NumericFormatCustom } from "../../../task/view/component/Forms/NumericFormatCustom";
import { SalaryTable } from "./SalaryTable";
import { toast } from "react-toastify";
import { LOIForm } from "./LOIForm";
import { AppoinmentLetter } from "./AppoinmentLetter";
import PreviewAppointment from "./model/PreviewAppointment";
// import PreviewAppointment from "./model/PreviewAppointment";

export default function AddRoutineWork({ handelCloseAddRoutineWork }) {
  const loginUserDetails = getLoginUserDetails();
  const [selectActivity, setSelectActivity] = useState("");
  const [routineActivityList, setRoutineActivityList] = useState([]);
  const [staticDodList, setStaticDodList] = useState([]);
  const [showFutureCheckbox, setShowFutureCheckbox] = useState(false);
  const [salaryInfoList, setSalaryInfoList] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [previewData, setPreviewData] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [countryList, setCountryList] = useState([{}]);
  const [stateList, setStateList] = useState([]);
  const [formData, setFormData] = useState();
  const [showAddSalaryHeadModel, setAddSalaryHeadModel] = useState(false);
  const [showAppoinmentPreview, setAppoinmentPreview] = useState(false);
  const loiRef = useRef();
  const appointRef = useRef();

  const { register, handleSubmit, watch, control, getValues } = useForm();

  const { country } = watch();

  useEffect(() => {
    if (country && country.countryCode) {
      getStateList(country.countryCode).then((response) => {
        if (response.data) {
          setStateList(response.data);
        }
      });
    }
  }, [country]);

  const handleChangeSelectActivity = (event) => {
    setSelectActivity(event.target.value);
  };

  const [showPreviewLOIModel, setPreviewLOIModel] = useState(false);
  const handelClosePreviewLOI = () => {
    setPreviewLOIModel(false);
  };
  const handleClosePreviewApoinment = () => {
    setAppoinmentPreview(false);
  };
  const handelOpenPreviewLOI = (data) => {
    let isValid = true;
    if (data.firstName === "") {
      toast.error("Please Enter First Name");
      isValid = false;
    }
    // if (data.middleName === "") {
    //   toast.error("Please Enter Middle Name");
    //   isValid = false;
    // }
    if (data.lastName === "") {
      toast.error("Please Enter Last Name");
      isValid = false;
    }
    if (data.address1 === "") {
      toast.error("Please Enter Address 1");
      isValid = false;
    }
    if (data.address2 === "") {
      toast.error("Please Enter Address 2");
      isValid = false;
    }
    if (data.city === "") {
      toast.error("Please Enter City");
      isValid = false;
    }
    if (!data.state || data.state === "") {
      toast.error("Please Enter State");
      isValid = false;
    }
    if (!data.country || data.country === "") {
      toast.error("Please Enter Country");
      isValid = false;
    }
    if (data.pin === "") {
      toast.error("Please Enter Pin");
      isValid = false;
    }
    if (!data.department || data.department === "") {
      toast.error("Please Enter Department");
      isValid = false;
    }

    if (!data.position || data.position === "") {
      toast.error("Please Enter Position");
      isValid = false;
    }

    if (data.email === "") {
      toast.error("Please Enter Email");
      isValid = false;
    }

    if (data.email !== "" && !validateEmail(data.email)) {
      toast.error("Please Enter Valid EmailId");
      isValid = false;
    }

    if (data.gpm === "") {
      toast.error("Please Enter Gross Per Month");
      isValid = false;
    }

    if (data.ctc === "") {
      toast.error("Please Enter CTC");
      isValid = false;
    }

    if (data.salaryInfoList.lengh === 0) {
      toast.error("Please Provide Salary Informations");
      isValid = false;
    }

    if (isValid) {
      setPreviewLOIModel(true);
    }
  };

  const handelCloseSalaryHeadModel = () => {
    setAddSalaryHeadModel(false);
  };
  const handelOpenAppointmentPreview = (data) => {
    // showAddSalaryHeadModel(true);
    //
    console.log("data value =", data);
    let isValid = true;
    if (data.firstName === "") {
      toast.error("Please Enter First Name");
      isValid = false;
    }
    // if (data.middleName === "") {
    //   toast.error("Please Enter Middle Name");
    //   isValid = false;
    // }
    if (data.lastName === "") {
      toast.error("Please Enter Last Name");
      isValid = false;
    }
    if (data.address1 === "") {
      toast.error("Please Enter Address 1");
      isValid = false;
    }
    if (data.address2 === "") {
      toast.error("Please Enter Address 2");
      isValid = false;
    }
    if (data.city === "") {
      toast.error("Please Enter City");
      isValid = false;
    }
    if (!data.state || data.state === "") {
      toast.error("Please Enter State");
      isValid = false;
    }
    if (!data.country || data.country === "") {
      toast.error("Please Enter Country");
      isValid = false;
    }
    if (data.pin === "") {
      toast.error("Please Enter Pin");
      isValid = false;
    }
    if (!data.department || data.department === "") {
      toast.error("Please Select Department");
      isValid = false;
    }

    if (!data.position || data.position === "") {
      toast.error("Please Select Position");
      isValid = false;
    }
    if (!data.location || data.location === "") {
      toast.error("Please Select Location");
      isValid = false;
    }

    if (data.email === "") {
      toast.error("Please Enter Email");
      isValid = false;
    }

    if (data.email !== "" && !validateEmail(data.email)) {
      toast.error("Please Enter Valid EmailId");
      isValid = false;
    }

    if (!data.reportingHead) {
      toast.error("Please Select Reporting Head");
      isValid = false;
    }

    if (data.probationDay === "") {
      toast.error("Please Select Probation Time");
      isValid = false;
    }

    if (data.priorAddressChangeDays === "") {
      toast.error("Please Select Address Change Notice Period");
      isValid = false;
    }
    if (data.priorLeaveNoticeDays === "") {
      toast.error("Please Select Leave Notice Period");
      isValid = false;
    }

    if (
      data.corAddress.cor_address1 === "" ||
      data.corAddress.cor_address1 === undefined
    ) {
      toast.error("Please Properly fill the corresponding address");
      isValid = false;
    }
    if (
      data.corAddress.cor_address2 === "" ||
      data.corAddress.cor_address2 === undefined
    ) {
      toast.error("Please fill  corresponding address1");
      isValid = false;
    }
    if (
      data.corAddress.cor_pin === "" ||
      data.corAddress.cor_pin === undefined
    ) {
      toast.error("Please fill  corresponding address1");
      isValid = false;
    }
    if (
      data.corAddress.cor_city === "" ||
      data.corAddress.cor_city === undefined
    ) {
      toast.error("Please fill the corresponding city");
      isValid = false;
    }
    if (
      data.corAddress.cor_country === "" ||
      data.corAddress.cor_country === undefined
    ) {
      toast.error("Please fill the corresponding country");
      isValid = false;
    }
    if (
      data.corAddress.cor_state === "" ||
      data.corAddress.cor_state === undefined
    ) {
      toast.error("Please fill the corresponding state");
      isValid = false;
    }

    if (data.gpm === "") {
      toast.error("Please Enter Gross Per Month");
      isValid = false;
    }

    if (data.ctc === "") {
      toast.error("Please Enter CTC");
      isValid = false;
    }
    if (data.salaryInfoList.lengh === 0) {
      toast.error("Please Provide Salary Informations");
      isValid = false;
    }
    if (data.absentDays === "" || data.absentDays === undefined) {
      toast.error("Please Provide Absent Days");
      isValid = false;
    }
    if (data.noticePeriod === "" || data.noticePeriod === undefined) {
      toast.error("Please Provide Notice Period");
      isValid = false;
    }
    // if (data.salaryLieu === "" || data.salaryLieu === undefined) {
    //   toast.error("Please Provide Salary In Lieu");
    //   isValid = false;
    // }
    if (isValid) {
      setAppoinmentPreview(true);
    }
  };

  useEffect(() => {
    getRoutineActivity(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setRoutineActivityList(response.data);
      }
    });

    getStaticDDOfOrg(loginUserDetails.orgId, "LOI_DOC").then((response) => {
      if (response.data) {
        setStaticDodList(response.data);
      }
    });

    getPositionsOfOrganization(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setPositionList(response.data);
      }
    });
    getAllDepartment(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setDepartmentList(response.data);
      }
    });
    getCountryList(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setCountryList(response.data);
        // setCountryList([
        //   {
        //     countryCode: "4",
        //     countryName: "Afghanistan",
        //     currencyAlphaCd: null,
        //     currencyNumCd: null,
        //     callingCd: null,
        //     timezone: null,
        //   },
        // ]);
      }
    });
  }, []);

  const onSubmit = (data) => {
    console.log("data =", data);
  };

  const [minprovp, setMinprovp] = useState("");

  const handleChangeMinProvp = (event) => {
    setMinprovp(event.target.value);
  };
  const [maxprovp, setMaxprovp] = useState("");

  const handleChangeMaxProvp = (event) => {
    setMaxprovp(event.target.value);
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const fullScreenStyle = {
    width: "100%",
  };

  return (
    <>
      <div
        className="rightFlotingPanel"
        // style={isExpanded ? fullScreenStyle : {}}
      >
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">Add Routine Work</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={isExpanded ? "Exit Full Screen" : "Enter Full Screen"}
                >
                  <IconButton
                    onClick={(e) => {
                      if (isExpanded) {
                        setIsExpanded(false);
                      } else {
                        setIsExpanded(true);
                      }
                    }}
                  >
                    {/* {isExpanded ? <CloseFullscreen /> : <OpenInFull />} */}
                  </IconButton>
                </Tooltip>
              </div>
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddRoutineWork()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="SelectActivity">Select Activity</InputLabel>
                    <Select
                      labelId="SelectActivity"
                      id="Select-Activity"
                      value={selectActivity}
                      label="Select Activity"
                      onChange={handleChangeSelectActivity}
                      className="formInputField"
                      variant="outlined"
                    >
                      {routineActivityList &&
                        routineActivityList.map((activity) => (
                          <MenuItem value={activity}>{activity.name}</MenuItem>
                        ))}
                      {/* <MenuItem value="AppointmentLetter">
                        Appointment Letter
                      </MenuItem> */}
                    </Select>
                  </FormControl>
                </div>

                {selectActivity.formId === "TASK_FORM_0000000014" && (
                  <LOIForm
                    ref={loiRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      console.log("handlePreview =", data);
                      setPreviewData(data);
                      handelOpenPreviewLOI(data);
                    }}
                  />
                )}

                {selectActivity.formId === "TASK_FORM_0000000015" && (
                  <AppoinmentLetter
                    ref={appointRef}
                    formData={formData}
                    setFormData={setFormData}
                    handleShowPreview={(data) => {
                      setPreviewData(data);
                      // handelOpenPreviewLOI(data);
                      handelOpenAppointmentPreview(data);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  // let data = getValues();
                  // data.salaryInfoList = salaryInfoList;
                  // data.staticDodList = staticDodList;
                  // data.joiningdate = joiningdate;
                  // data.loiexpdate = loiexpdate;

                  if (selectActivity.formId === "TASK_FORM_0000000015") {
                    appointRef.current.click();
                  } else if (selectActivity.formId === "TASK_FORM_0000000014") {
                    loiRef.current.click();
                  }
                  // setPreviewData(data);
                  // handelOpenPreviewLOI(data);
                }}
              >
                Preview
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseAddRoutineWork()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showPreviewLOIModel && (
        <>
          <PreviewLOI
            activity={selectActivity}
            showPreviewLOIModel={showPreviewLOIModel}
            handelClosePreviewLOI={handelClosePreviewLOI}
            previewData={previewData}
            onSubmitSuccess={() => {
              setPreviewLOIModel(false);
              setPreviewData([]);
              setSelectActivity("");
            }}
          />
        </>
      )}
      {showAppoinmentPreview && (
        <PreviewAppointment
          activity={selectActivity}
          handleClosePreviewApoinment={handleClosePreviewApoinment}
          previewData={previewData}
          onSubmitSuccess={() => {
            setAppoinmentPreview(false);
            setPreviewData([]);
            setSelectActivity("");
          }}
        />
      )}
      {showAddSalaryHeadModel && (
        <SalaryHeadAdd
          showAddSalaryHeadModel={showAddSalaryHeadModel}
          handelCloseSalaryHeadModel={handelCloseSalaryHeadModel}
        />
      )}
    </>
  );
}
