import { Add, Edit, PostAdd } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React from "react";

export default function SetSalaryTemplate({ handelOpenSalaryTemplate }) {
  return (
    <>
      <div className="payrollContainArea">
        <div className="editPayrollElement width100p">
          <div className="elementFormContainer">
            <div className="salaryComponentBtnGrp">
              <div className="salaryCompHead">List of Salary Template</div>
              <div className="sCmenuButton">
                <Button
                  className="addNSCBtn"
                  startIcon={<PostAdd />}
                  onClick={handelOpenSalaryTemplate}
                >
                  New Template
                </Button>
              </div>
            </div>
            <div className="TableContainer">
              <table className="taskListTable">
                <thead className="taskListtableHeader">
                  <tr>
                    <th>Template Name</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th className="width50"></th>
                  </tr>
                </thead>
                <tbody className="scrolableTableBody">
                  <tr>
                    <td>
                      <div className="tdText">Freshers</div>
                    </td>
                    <td>
                      <div className="tdText">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </div>
                    </td>

                    <td>
                      <div className="tdText">
                        <span className="active">Active</span>
                      </div>
                    </td>
                    <td>
                      <div className="tblActionBtnGrp">
                        <IconButton>
                          <Edit />
                          {/* onClick={() => handelOpenCrtCommittee()} */}
                        </IconButton>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
