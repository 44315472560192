import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title,
  Filler,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Line, Pie } from "react-chartjs-2";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { getDashboardDataOfItem } from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
// import faker from "faker";

export default function CardBudget({
  handelOpenAddBudget,
  chartDataOfItem,
  handelOpenViewBudget,
  menuModuleList,
  userMenuList,
}) {
  console.log("menuModuleList", menuModuleList);
  const { t } = useTranslation();
  // const userDetails = jwtDecode(localStorage.getItem("token"));

  // const [chartDataOfItem, setChartDataOfItem] = useState([]);

  // useEffect(() => {
  //   getDashboardDataOfItem(userDetails.orgId, userDetails.userId).then(
  //     (response) => {
  //       console.log("getDashboardDataOfItem", response.data);
  //       setChartDataOfItem(response.data);
  //     }
  //   );
  // }, [userDetails.orgId, userDetails.userId]);

  ChartJS.register(
    ArcElement,
    Tooltip,
    Title,
    Filler,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  let data;

  if (chartDataOfItem.capexAmount === 0 && chartDataOfItem.opexAmount === 0) {
    data = {
      labels: ["No Data"],
      datasets: [
        {
          data: [1],
          backgroundColor: ["#A9A9A9"],
          borderWidth: 0,
        },
      ],
    };
  } else {
    data = {
      labels: ["capexAmount", "opexAmount"],
      datasets: [
        {
          // label: '# FY2024',
          data: [chartDataOfItem.capexAmount, chartDataOfItem.opexAmount],
          backgroundColor: ["#9B59B6", "#F39C12", "#00C2D7", "#FF6B6B"],
          hoverBackgroundColor: ["#9B59B6", "#F39C12", "#00C2D7", "#FF6B6B"],
          borderWidth: 0,
          type: "pie",
        },
      ],
    };
  }

  const options = {
    cutout: "80%",

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  // curent People status bar and line chart combo start

  const optionsLeave = {
    responsive: true,
    borderRadius: 4,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 12,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
      title: {
        display: false,
        text: "FYQ 2nd",
      },
    },
  };

  const labels = ["January", "February", "March"];
  const budgetData = chartDataOfItem.budgetVsActual || [];
  console.log("budgetData", budgetData);

  const dataLeave = {
    labels: budgetData.map((item) => item.budgetHead),
    datasets: [
      {
        label: "Budget Amount",
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255, 99, 132, 0.8)",
        hoverBorderColor: "rgba(255, 99, 132, 1)",
        data: budgetData.map((item) => item.budgetAmount),
      },
      {
        label: "Actual Amount",
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(54, 162, 235, 0.8)",
        hoverBorderColor: "rgba(54, 162, 235, 1)",
        data: budgetData.map((item) => item.actualAmount),
      },
    ],
  };

  // const dataLeave = {
  //   labels,
  //   datasets: [
  //     {
  //       fill: false,
  //       label: "Expense",
  //       data: [100, 231, 140],
  //       borderColor: "rgb(255, 255, 255)",
  //       backgroundColor: "rgba(255, 241, 195, 0.5)",
  //     },
  //     {
  //       label: "Income",
  //       data: [400, 500, 340],
  //       backgroundColor: "rgba(0, 194, 215, 0.8)",
  //       type: "bar",
  //       barThickness: 16,
  //     },
  //     {
  //       label: "Spend",
  //       data: [200, 300, 280],
  //       backgroundColor: "rgba(243, 153, 18, 0.8)",
  //       type: "bar",
  //       barThickness: 10,
  //     },
  //   ],
  // };
  // curent People status bar and line chart combo end

  return (
    <>
      <div className="anDashIndCard">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">{t("budget")}</div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition">
                Salary: <span className="fbold">42%</span>
              </span> */}

              {/* <span title="Tenure">
                FYQ: <span className="fbold">2nd</span>
              </span> */}
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="pie"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount">
                    {chartDataOfItem.budgetAmount}
                  </span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf">
              <Bar
                className="hw100"
                type="bar"
                data={dataLeave}
                options={optionsLeave}
              />
              {/* <Line
                className="hw100"
                type="line"
                data={dataLeave}
                options={optionsLeave}
              /> */}
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>
          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find((item) => item.menuName === "Budget View")
                  .menuId
              ) && (
                <Button
                  className="dfultPrimaryBtn "
                  onClick={() => handelOpenViewBudget()}
                >
                  {t("view")}
                </Button>
              )}

            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find((item) => item.menuName === "Budget Add")
                  .menuId
              ) && (
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn "
                  onClick={() => handelOpenAddBudget()}
                >
                  {t("add")}
                </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
