import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function OtherOptions({ updateData, data, extraData }) {
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [parameterMonitor, setParameterMonitor] = useState([]);
  const [hospitalization, setHospitalization] = useState("NO");
  const [surgeryDate, setSurgeryDate] = useState(new Date());
  const [reviewDate, setReviewDate] = useState(new Date());
  const [nextReview, setNextReview] = useState(true);
  const [referPatient, setReferPatient] = useState("");
  const [referSpeciality, setReferSpeciality] = useState("");

  const [procedureList, setProcedureList] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [userProcedureList, setUserProcedureList] = useState([]);
  const [procedureString, setProcedureString] = useState("");

  const [monitorNames, setMonitorNames] = useState([]);
  const [doctorName, setDoctorName] = useState("");

  const initializeForm = () => {
    setSelectedProcedure(null);
    setProcedureString("");
    setSurgeryDate(new Date());
  };

  const handleChangeReferPatient = (event) => {
    setReferPatient(event.target.value);
    updateData({
      referType: event.target.value,
    });
  };

  const handleChangeReferSpeciality = (event) => {
    setReferSpeciality(event.target.value);
    updateData({
      referDoctorSpeciality: event.target.value,
    });
  };

  const handelChangehospitalization = (event) => {
    setHospitalization(event.target.value);
  };

  const suggestedProcedure = useMemo(() => {
    const filteredList = getFilteredList(
      procedureList,
      procedureString,
      "procedureName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [procedureList, procedureString]);

  const getProcedures = () => {
    const lifeStyleString = localStorage.getItem("procedureList");
    if (lifeStyleString) {
      setProcedureList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = getHealthDto();
    DashboardServices.getProcedures(reqDto).then((response) => {
      if (response.data) {
        setProcedureList(response.data);
        localStorage.setItem("procedureList", JSON.stringify(response.data));
      }
    });
  };

  const computeMonitors = () => {
    let investigationList = [];
    let vitalsList = [];
    if (data.investigation) {
      investigationList = data.investigation.map((item) => item.testName);
    }
    const vitalNames = [
      "TEMPERATURE",
      "BP",
      "HEART_RATE",
      "RESPIRATION_RATE",
      "SPO2",
    ];
    if (extraData.reqValue.vitals) {
      vitalsList = extraData.reqValue.vitals
        .filter((item) => {
          const isValid = vitalNames.some(
            (element) => element === item.vitalCode
          );
          return isValid;
        })
        .map((item) => item.vitalName);
    }

    setMonitorNames([...vitalsList, ...investigationList]);
  };

  useEffect(() => {
    computeMonitors();
  }, [data.investigation, extraData]);

  useEffect(() => {
    computeMonitors();
    if (data && data.procedure) {
      setUserProcedureList(data.procedure);
    }
    if (data && data.referType) {
      setReferPatient(data.referType);
    }

    if (data && data.itemToMonitor) {
      setParameterMonitor(data.itemToMonitor);
    }

    if (data && data.referDoctorSpeciality) {
      setReferSpeciality(data.referDoctorSpeciality);
    }

    // if (!data || !data.procedure) {
    //   if (
    //     extraData &&
    //     extraData.reqValue.procedures &&
    //     extraData.reqValue.procedures.length > 0
    //   ) {
    //     const tempArray = extraData.reqValue.procedures.map((item) => {
    //       let tempProcedure = {
    //         id: uuidv4(),
    //         procedureName: item.procedureName,
    //         gender: "",
    //         locallyAdded: true,
    //       };

    //       const temp = {
    //         procedure: tempProcedure,
    //         date: new Date(Date.parse(item.procedureDate)),
    //       };
    //       return temp;
    //     });

    //     setUserProcedureList((prev) => {
    //       const temp = [...prev, ...tempArray];
    //       updateData({ procedure: temp });
    //       return temp;
    //     });
    //   }
    // }

    getProcedures();
  }, []);

  const handleChangeparameterMonitor = (event) => {
    const {
      target: { value },
    } = event;
    setParameterMonitor(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    updateData({
      itemToMonitor: typeof value === "string" ? value.split(",") : value,
    });
  };
  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            Others Options:
            <span>{/*symptoms gose here when acordion not expend*/}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="acodDetails">
          <div className="tskElemHeding ">Parameter to be Monitor</div>

          <div className="formElement ">
            <FormControl className="formControl">
              <InputLabel id="ParameterMonitor">Select Parameters</InputLabel>
              <Select
                labelId="ParameterMonitor"
                id="Parameter-Monitor"
                multiple
                value={parameterMonitor}
                onChange={handleChangeparameterMonitor}
                input={<OutlinedInput label="Select Parameters" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="formInputField"
                variant="outlined"
              >
                {monitorNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={parameterMonitor.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="tskElemHeding ">Doctor Note</div>

          <div className="formElement mt10">
            <FormControl className="formControl">
              <TextField
                label="Doctor Note*"
                variant="outlined"
                className="descptionTextFild"
                multiline
                rows={4}
                maxRows={6}
              ></TextField>
            </FormControl>
          </div>

          {/* <div className="tskElemHeding mt10 mb0">Hospitalization</div>
          <div className="formElement">
            <FormControl className="formControl">
              <RadioGroup
                className="formRadioGroup"
                labelId="setTasktype"
                name="setTasktype"
                value={hospitalization}
                onChange={handelChangehospitalization}
              >
                <FormControlLabel
                  className="formRadioField"
                  value="YES"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  className="formRadioField"
                  value="NO"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </div> */}
          <div className="bb"></div>
          <div className="tskElemHeding mt10 ">Procedure/Surgery</div>
          <div className="complaintList  ">
            <ul className=" pastIllness">
              {userProcedureList &&
                userProcedureList.map((item, index) => {
                  return (
                    <li>
                      <div className="valuDateRow complstDtlGrp">
                        <div className="piValu">
                          {item.procedure.procedureName}
                        </div>
                        <div className="d-flex">
                          <div className="piDate">
                            {dayjs(item.date).format("DD-MMM-YYYY")}
                          </div>
                          <IconButton
                            className="removeItemBtn"
                            onClick={() => {
                              const temp = [...userProcedureList];
                              temp.splice(index, 1);
                              setUserProcedureList(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      </div>
                    </li>
                  );
                })}

              <li className="naLstTyp">
                <div className="addNewformGrp">
                  <div className="formElement">
                    <FormControl className="formControl">
                      {/* <TextField
                        // hiddenLabel
                        label="Procedure/Surgery"
                        required
                        autoComplete="off"
                        placeholder="Add Procedure/Surgery"
                        variant="outlined"
                        className="formTextFieldArea"
                      /> */}

                      <Autocomplete
                        freeSolo
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedProcedure}
                        options={suggestedProcedure}
                        inputValue={procedureString}
                        onChange={(e, newValue) => {
                          setSelectedProcedure(newValue);
                        }}
                        onInputChange={(e, value) => {
                          setProcedureString(value);
                        }}
                        getOptionLabel={(option) => option.procedureName}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {item.procedureName}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder="Add Previous procedure"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel
                        id="setsurgeryDate"
                        className="setCompletionDate"
                      >
                        Select Date*
                      </InputLabel>
                      <ReactDatePicker
                        showIcon
                        toggleCalendarOnIconClick
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        labelId="setsurgeryDate"
                        className="formDatePicker"
                        selected={surgeryDate}
                        minDate={new Date()}
                        dateFormat="dd-MMM-yyyy"
                        onChange={(date) => {
                          console.log("new date = ", date);
                          setSurgeryDate(date);
                        }}
                      />
                    </FormControl>
                  </div>

                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    onClick={() => {
                      let tempProcedure = selectedProcedure;
                      if (!tempProcedure) {
                        if (!procedureString || procedureString === "") {
                          toast.error("Please Provide Food Name");
                          return;
                        }
                        tempProcedure = {
                          id: uuidv4(),
                          procedureName: "procedureString",
                          gender: "",
                          locallyAdded: true,
                        };
                      }
                      setUserProcedureList((prev) => {
                        const temp = [
                          ...prev,
                          {
                            procedure: tempProcedure,
                            date: surgeryDate,
                          },
                        ];
                        updateData({ procedure: temp });
                        return temp;
                      });
                      initializeForm();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </li>
            </ul>
          </div>
          <div className="bb"></div>
          <div className="tskElemHeding mt10 ">Refer</div>

          <div className="formElement ">
            <FormControl className="formControl">
              <InputLabel id="Refer-select-label">Refer Type</InputLabel>
              <Select
                labelId="Refer-select-label"
                id="Refer-select"
                value={referPatient}
                label="Refer Patient"
                onChange={handleChangeReferPatient}
                className="formInputField"
                variant="outlined"
              >
                <MenuItem value={null}>--Select Refer Type--</MenuItem>
                <MenuItem value="higherFacility">
                  Advise higher facility
                </MenuItem>
                <MenuItem value="immediateHospitalization">
                  Advise immediate hospitalization
                </MenuItem>

                <MenuItem value="otherSpeciality">
                  Refer to other speciality
                </MenuItem>
                <MenuItem value="medicoLegal"> Refer to medico legal </MenuItem>
              </Select>
            </FormControl>
          </div>

          {referPatient === "immediateHospitalization" && (
            <>
              <div className="formElement mt10">
                <FormControl className="formControl">
                  <TextField
                    // hiddenLabel
                    label="Remarks"
                    required
                    autoComplete="off"
                    placeholder="Enter Remarks here"
                    variant="outlined"
                    className="formTextFieldArea"
                  />
                </FormControl>
              </div>

              <div className="formElement">
                <FormControl className="formControl pRelative">
                  <TextField
                    label="Treatment Plane*"
                    variant="outlined"
                    className="descptionTextFild"
                    multiline
                    rows={4}
                    maxRows={6}
                  ></TextField>
                </FormControl>
              </div>
            </>
          )}
          {referPatient === "otherSpeciality" && (
            <>
              <div className="addNewformGrp mt10">
                <div className="formElement ">
                  <FormControl className="formControl">
                    <InputLabel id="speciality-select-label">
                      Select Speciality
                    </InputLabel>
                    <Select
                      labelId="speciality-select-label"
                      id="speciality-select"
                      value={referSpeciality}
                      label="Select Speciality"
                      onChange={handleChangeReferSpeciality}
                      className="formInputField"
                      variant="outlined"
                      required
                    >
                      <MenuItem value="AcuteCare">Acute Care</MenuItem>
                      <MenuItem value="Anaesthesia">Anaesthesia</MenuItem>
                      <MenuItem value="GeneralSurgeon">
                        General Surgeon
                      </MenuItem>
                      <MenuItem value="CVTS">CVTS</MenuItem>
                      <MenuItem value="Cardiologist">Cardiologist</MenuItem>
                      <MenuItem value="CardiacSurgeon">
                        Cardiologist/ Cvs/ Cardiac Surgeon
                      </MenuItem>
                      <MenuItem value="ChiefCardio">
                        Chief Cardio Vascular Thoracic Surgeon
                      </MenuItem>
                      <MenuItem value="ClinicalCardiologist">
                        Clinical Cardiologist
                      </MenuItem>
                      <MenuItem value="Psychologist">
                        Clinical Psychologist
                      </MenuItem>
                      <MenuItem value="dermatologist">
                        Dermatologist & Hair transplant Surgeon
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="formElement   ">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label="Doctor Name (Optional)"
                      autoComplete="off"
                      placeholder="Enter Doctor Name"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={doctorName}
                      onChange={(e) => {
                        setDoctorName(e.target.value);
                        updateData({ doctorName: e.target.value });
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            </>
          )}

          <div className="bb"></div>

          <div className="tskElemHeding mt10 ">Review</div>
          <div className="d-flex">
            <div className="formElement wAuto">
              <FormControl className="formControl">
                <FormGroup className="formRadioGroup">
                  <FormControlLabel
                    className="formRadioField"
                    control={
                      <Checkbox
                        checked={nextReview}
                        onChange={(e) => {
                          setNextReview(e.target.checked);
                          if (e.target.checked) {
                            reviewDate.setDate(new Date().getDate() + 7);
                            updateData({
                              reviewDate: reviewDate,
                            });
                          } else {
                            updateData({
                              reviewDate: null,
                            });
                          }
                        }}
                      />
                    }
                    label="Next Review"
                  />
                </FormGroup>
              </FormControl>
            </div>
            {nextReview === true && (
              <>
                <div className="formElement mxW-160">
                  <FormControl className="formControl">
                    <InputLabel
                      id="setreviewDate"
                      className="setCompletionDate"
                    >
                      Review Date*
                    </InputLabel>
                    <ReactDatePicker
                      showIcon
                      toggleCalendarOnIconClick
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      labelId="setreviewDate"
                      className="formDatePicker"
                      selected={reviewDate}
                      minDate={new Date()}
                      dateFormat="dd-MMM-yyyy"
                      onChange={(date) => {
                        console.log("new date = ", date);
                        setReviewDate(date);
                        updateData({
                          reviewDate: date,
                        });
                      }}
                    />
                  </FormControl>
                </div>
              </>
            )}
          </div>
          <div className="bb"></div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
