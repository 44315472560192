import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import DashboardServices from "../../../../../../../services/DashboardServices";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";
import { Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export default function Recommendations({ updateData, data }) {
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [selectedDiet, setSelectedDiet] = useState(null);
  const [dietList, setDietList] = useState([]);
  const [dietInputString, setDietInputString] = useState("");
  const [recomandedDietList, setRecommandedDietList] = useState([]);
  const [lifeStyleList, setLifeStyleList] = useState([]);
  const [selectedLifeStyle, setSelectedLifeStyle] = useState(null);
  const [lifeStyleString, setLifeStyleString] = useState("");
  const [recommandedLifeStyle, setRecomandedLifeStyle] = useState([]);

  const [recommandationString, setRecommandationString] = useState("");

  const getFilteredDietList = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      return data.slice(0, MAX_SUGGESTED_RESULT);
    }
    const startsWithList = [];
    const containsList = [];

    data.forEach((element) => {
      if (element.diet.toLowerCase().startsWith(searchString.toLowerCase())) {
        startsWithList.push(element);
      } else if (
        element.diet.toLowerCase().includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });
    return [...startsWithList, ...containsList];
  };

  const initializeDiet = () => {
    setSelectedDiet(null);
    setDietInputString("");
  };

  const initializeLifeStyle = () => {
    setLifeStyleString("");
    setSelectedLifeStyle(null);
  };

  const suggestedDietList = useMemo(() => {
    const filteredList = getFilteredDietList(dietList, dietInputString);
    // if (!filteredList || filteredList.length === 0) {
    //   return [{ id: uuidv4(), diet: dietInputString }];
    // }
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [dietInputString, dietList]);

  const suggestedLifeStyleList = useMemo(() => {
    const filteredList = getFilteredList(
      lifeStyleList,
      lifeStyleString,
      "lifeStyle"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [lifeStyleString, lifeStyleList]);

  useEffect(() => {
    if (data.diet) {
      setRecommandedDietList(data.diet);
    }

    if (data.lifeStyle) {
      setRecomandedLifeStyle(data.lifeStyle);
    }

    getDiets();
    getLifeStyles();
  }, []);

  const getDiets = () => {
    const reqDto = getHealthDto();
    const dietString = localStorage.getItem("dietList");
    if (dietString) {
      setDietList(JSON.parse(dietString));
      return;
    }
    toast.error("Could not found diet list. Fetching it.");
    DashboardServices.getDiets(reqDto).then((response) => {
      if (response.data) {
        localStorage.setItem("dietList", JSON.stringify(response.data));
        setDietList(response.data);
      }
    });
  };

  const getLifeStyles = () => {
    const lifeStyleString = localStorage.getItem("lifeStyleList");
    if (lifeStyleString) {
      setLifeStyleList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = getHealthDto();
    DashboardServices.getLifeStyles(reqDto).then((response) => {
      if (response.data) {
        setLifeStyleList(response.data);
        localStorage.setItem("lifeStyleList", JSON.stringify(response.data));
      }
    });
  };

  useEffect(() => {
    const temp = [];
    let str = "";
    if (recomandedDietList.length > 0) {
      str = recomandedDietList.map((item) => item.diet).join(", ");
      temp.push(str);
    }
    if (recommandedLifeStyle.length > 0) {
      str = recommandedLifeStyle.map((item) => item.lifeStyle).join(", ");
      temp.push(str);
    }
    setRecommandationString(temp.join("~"));
  }, [recomandedDietList, recommandedLifeStyle]);

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            Recommendations:
            <span> {!accordionExpanded && recommandationString}</span>
            <span>{/*symptoms gose here when acordion not expend*/}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="acodDetails">
          <div className="tskElemHeding hilightHeading">
            Diet Recommendations
          </div>

          <ul className="withOutList pastIllness">
            {recomandedDietList &&
              recomandedDietList.map((dietObj, index) => {
                return (
                  <li>
                    <div className="complstDtlGrp ">
                      <div className="complstDtl">
                        <div className="compntName ">{dietObj.diet}</div>
                      </div>
                      <IconButton
                        className="removeItemBtn"
                        onClick={() => {
                          const temp = [...recomandedDietList];
                          temp.splice(index, 1);
                          updateData({ diet: temp });

                          setRecommandedDietList(temp);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </div>

                    {/* <div className="valuDateRow">
                      <div className="piValu">{dietObj.diet}</div>
                    </div>
                    <IconButton
                      className="removeItemBtn"
                      onClick={() => {
                        const temp = [...recomandedDietList];
                        temp.splice(index);
                        setRecommandedDietList(temp);
                      }}
                    >
                      <Trash />
                    </IconButton> */}
                  </li>
                );
              })}

            {/* <li>
              <div className="valuDateRow">
                <div className="piValu">Dash Diet </div>
              </div>
            </li>
            <li>
              <div className="valuDateRow">
                <div className="piValu">Clear Liquid Diet</div>
              </div>
            </li> */}
            <li>
              <div className="addNewformGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    <Autocomplete
                      className="formAutocompleteField"
                      variant="outlined"
                      freeSolo
                      value={selectedDiet}
                      options={suggestedDietList}
                      inputValue={dietInputString}
                      onChange={(e, newValue) => {
                        setSelectedDiet(newValue);
                      }}
                      onInputChange={(e, value) => {
                        setDietInputString(value);
                      }}
                      getOptionLabel={(option) => option.diet}
                      renderOption={(props, item) => {
                        return (
                          <li {...props} key={item.id}>
                            {item.diet}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField autocompFildPlaceholder"
                          placeholder="Diet Name"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <Button
                  className="dfultPrimaryBtn "
                  startIcon={<Add />}
                  onClick={() => {
                    if (!dietInputString || dietInputString === "") {
                      toast.error("Diet Recommandation Cannot be empty");
                      return;
                    }
                    let tempDiet = selectedDiet;
                    if (!tempDiet) {
                      tempDiet = {
                        id: uuidv4(),
                        diet: dietInputString,
                        locallyAdded: true,
                      };
                    }
                    setRecommandedDietList((prev) => {
                      const temp = [...prev, tempDiet];

                      updateData({ diet: temp });
                      return temp;
                    });
                    initializeDiet();
                  }}
                >
                  Add
                </Button>
              </div>
            </li>
          </ul>

          <div className="tskElemHeding hilightHeading">
            Lifestyle Recommendations
          </div>

          <ul className="withOutList pastIllness">
            {recommandedLifeStyle &&
              recommandedLifeStyle.map((item, index) => {
                return (
                  <li>
                    <div className="complstDtlGrp ">
                      <div className="complstDtl">
                        <div className="compntName ">{item.lifeStyle}</div>
                      </div>
                      <IconButton
                        className="removeItemBtn"
                        onClick={() => {
                          const temp = [...recommandedLifeStyle];
                          temp.splice(index, 1);
                          updateData({ lifeStyle: temp });

                          setRecomandedLifeStyle(temp);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </div>
                  </li>
                );
              })}
            {/* <li>
              <div className="valuDateRow">
                <div className="piValu">Do not drink alcohol</div>
              </div>
            </li> */}

            <li>
              <div className="addNewformGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    <Autocomplete
                      freeSolo
                      className="formAutocompleteField"
                      variant="outlined"
                      value={selectedLifeStyle}
                      options={suggestedLifeStyleList}
                      inputValue={lifeStyleString}
                      onChange={(e, newValue) => {
                        setSelectedLifeStyle(newValue);
                      }}
                      onInputChange={(e, value) => {
                        setLifeStyleString(value);
                        // else {
                        //   setSymptomSearchString("");
                        // }
                      }}
                      getOptionLabel={(option) => option.lifeStyle}
                      renderOption={(props, item) => {
                        return (
                          <li {...props} key={item.id}>
                            {item.lifeStyle}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField autocompFildPlaceholder"
                          placeholder="Life Style Name"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <Button
                  className="dfultPrimaryBtn "
                  startIcon={<Add />}
                  onClick={() => {
                    if (!lifeStyleString || lifeStyleString === "") {
                      toast.error("Please Provide Lifestyle");
                      return;
                    }
                    let tempLifeStyle = selectedLifeStyle;

                    if (!tempLifeStyle) {
                      tempLifeStyle = {
                        id: uuidv4(),
                        locallyAdded: true,
                        lifeStyle: lifeStyleString,
                      };
                    }
                    setRecomandedLifeStyle((prev) => {
                      const temp = [...prev, tempLifeStyle];
                      updateData({ lifeStyle: temp });
                      return temp;
                    });
                    initializeLifeStyle();
                  }}
                >
                  Add
                </Button>
              </div>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
