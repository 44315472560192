import {
  Add,
  ExpandMoreRounded,
  MonitorHeartRounded,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export default function Signs({ updateData, data, extraData }) {
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [selectedSign, setSelectedSign] = useState(null);
  const [signInputString, setSignInputString] = useState("");

  const [signList, setSignList] = useState([]);
  const [userSignList, setUserSignList] = useState([]);

  const [userNote, setUserNote] = useState("");

  const initializeForm = () => {
    setSelectedSign(null);
    setSignInputString("");
    setUserNote("");
  };

  const suggestedSign = useMemo(() => {
    const signIdsList = userSignList.map((item) => item.sign.id);

    let filteredData = signList.filter((sign) => {
      let symptomVal = "C";
      if (extraData.queuePatientGender === "Male") {
        symptomVal = "M";
      }
      if (extraData.queuePatientGender === "Female") {
        symptomVal = "F";
      }
      const alreadyTaken = signIdsList.some((id) => id === sign.id);
      return (
        !alreadyTaken && (sign.gender === symptomVal || sign.gender === "C")
      );
    });

    const filteredList = getFilteredList(
      filteredData,
      signInputString,
      "signName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [signInputString, signList, userSignList]);

  const symptomString = useMemo(() => {
    const str =
      userSignList.length > 0
        ? userSignList
            .map((item) => `${item.sign.signName} (${item.userNote})`)
            .join(", ")
        : "";
    return str;
  }, [userSignList]);

  useEffect(() => {
    if (data.singObj) {
      setUserSignList(data.singObj);
    }
    getSigns();
  }, []);

  const getSigns = () => {
    const symptomString = localStorage.getItem("signList");
    if (symptomString) {
      setSignList(JSON.parse(symptomString));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getSigns(reqDto).then((response) => {
      if (response.data) {
        setSignList(response.data);
        localStorage.setItem("signList", JSON.stringify(response.data));
      }
    });
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            For:
            {!accordionExpanded && <span>{symptomString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="complaintList">
            <ul>
              {userSignList &&
                userSignList.map((sign, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp">
                        <div className="complstDtl  ">
                          <div className="compntName">{sign.sign.signName}</div>

                          {sign.userNote && sign.userNote !== "" && (
                            <div className="compinfoGRP">
                              <div className="compSeverity">
                                <span>Note:</span>
                                <span className="data">{sign.userNote}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...userSignList];
                            temp.splice(index, 1);
                            updateData({ singObj: temp });
                            setUserSignList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </li>
                  );
                })}
              {/* <li>
                <div className="complstDtlGrp ">
                  <div className="complstDtl  ">
                    <div className="compntName">Abdominal Colic / Cramps.</div>
                    <div className="compinfoGRP">
                      <div className="compSeverity">
                        <span>Severity:</span>
                        <span className="data">Moderate</span>
                      </div>
                    </div>
                  </div>
                  <IconButton className="removeItemBtn">
                    <Trash />
                  </IconButton>
                </div>
              </li> */}
              {/* add new symptoms */}
              <li>
                <div className="addNewformGrp">
                  <div className="formElement">
                    <FormControl className="formControl">
                      {/* <TextField
                        // hiddenLabel
                        label="Signs"
                        required
                        autoComplete="off"
                        placeholder="Enter Signs"
                        variant="outlined"
                        className="formTextFieldArea"
                      /> */}

                      <Autocomplete
                        freeSolo
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedSign}
                        options={suggestedSign}
                        inputValue={signInputString}
                        onChange={(e, newValue) => {
                          setSelectedSign(newValue);
                        }}
                        onInputChange={(e, value, reason) => {
                          if (e && reason === "input") {
                            setSignInputString(e.target.value);
                          } else if (reason === "reset") {
                            setSignInputString(value);
                          } else if (reason === "clear") {
                            setSignInputString(value);
                          }

                          // else {
                          //   setSignInputString("");
                          // }
                        }}
                        getOptionLabel={(option) => option.signName}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {`${item.signName} (${item.gender})`}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder="Enter Sign*"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        // hiddenLabel
                        value={userNote}
                        label="Notes"
                        autoComplete="off"
                        placeholder="Enter Notes"
                        variant="outlined"
                        className="formTextFieldArea"
                        onChange={(e) => {
                          setUserNote(e.target.value);
                        }}
                      />
                    </FormControl>
                  </div>
                  {/* <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel id="severity-select-label">
                        Severity
                      </InputLabel>
                      <Select
                        labelId="severity-select-label"
                        id="severity-select"
                        value={severity}
                        label="Severity"
                        onChange={handleChangeSeverity}
                        className="formInputField"
                        variant="outlined"
                      >
                        <MenuItem value="severe">Severe </MenuItem>
                        <MenuItem value="moderate">Moderate</MenuItem>
                        <MenuItem value="mild"> Mild </MenuItem>
                      </Select>
                    </FormControl>
                  </div> */}

                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    onClick={() => {
                      let tempUserSign = selectedSign;
                      if (!tempUserSign) {
                        if (!signInputString || signInputString === "") {
                          toast.error("Please Provide Sign Name");
                          return;
                        }
                        if (userNote === "") {
                          toast.error("Please Provide User Note");
                          return;
                        }
                        tempUserSign = {
                          id: uuidv4(),
                          signName: signInputString,
                          locallyAdded: true,
                        };
                      }
                      setUserSignList((prev) => {
                        const temp = [
                          ...prev,
                          { sign: tempUserSign, userNote },
                        ];
                        updateData({ singObj: temp });
                        return temp;
                      });
                      initializeForm();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
