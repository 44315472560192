import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Microphone } from "iconoir-react";
import { ConfirmProvider } from "material-ui-confirm";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import RecordAudio from "../../../../common/RecordAudio";
import ReactDatePicker from "react-datepicker";
import { useEffect } from "react";
import DashboardServices from "../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import { clearSelectedTask } from "../../../../redux/reducers/rightSideTaskPanelSlice";
import DateUtils from "../../../../utils/DateUtils";
import { useDispatch } from "react-redux";

const SignOff = ({
  handleClose,
  userList,
  data,
  previousAssigness,
  refreshData,
  removeFromStore,
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const loginUserDetail = getLoginUserDetails();
  const dispatch = useDispatch();

  const [signOffComment, setSignOffComment] = useState("");
  const [radioGroupValue, setRadioGroupValue] = useState("new task");
  const [newTaskName, setNewTaskName] = useState("");
  const [showRecAudio, setShowRecAudio] = useState(false);
  const [taskDescription, setTaskDescription] = useState("");
  const tempAudioChunk = useRef([]);
  const [recordingLength, setRecordingLength] = useState(0);
  const [recordingLink, setRecordingLink] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [timeSlots, setTimeSlots] = useState([]);
  const [compTime, setCompTime] = useState("20:00");
  const [priority, setPriority] = useState("0");
  const [completion, setCompletion] = useState(new Date());
  const [activityList, setActivityList] = useState([]);
  const [suggestedTaskList, setSuggestedTaskList] = useState([]);
  const [activity, setActivity] = useState("");
  const [selectedSuggestionObj, setSelectedSuggestionObj] = useState({});

  useEffect(() => {
    DashboardServices.getActivityList(loginUserDetail.orgId).then(
      (response) => {
        if (response.data) {
          setActivityList(response.data);
        }
      }
    );
    DashboardServices.getNextTaskSuggestion(
      data.activityId,
      data.taskName
    ).then((response) => {
      if (response.data && response.data.length > 0) {
        setSuggestedTaskList(response.data);
      }
    });
    setTimeSlots(generateTimeSlots());
    setCompTime("20:00");
  }, []);

  function generateTimeSlots() {
    const slots = [];
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0); // Set start time to midnight

    for (let i = 0; i < 48; i++) {
      // 48 slots for 30-minute intervals in a day
      const currentSlot = new Date(startDate.getTime() + i * 30 * 60 * 1000);
      const hours = currentSlot.getHours().toString().padStart(2, "0");
      const minutes = currentSlot.getMinutes().toString().padStart(2, "0");
      const timeString = `${hours}:${minutes}`;
      slots.push(timeString);
    }

    return slots;
  }

  const handleChangeSelectSuggestion = (e) => {
    const val = e.target.value;
    if (!e.target.value) {
      return;
    }
    setSelectedSuggestionObj(val);
    // setDepartment(val.functionId);
    setActivity(val.activityId); // set the value of activity
    setNewTaskName(val.subTask); // set task text
    // fetch the list of work and set the value
    DashboardServices.getTasksListByFunctionsAndActivity(
      loginUserDetail.orgId,
      val.functionId,
      val.activityId
    ).then((response) => {
      //   setWorkList(response.data);
      //   setWork(val.taskMasterId);
    });
    setSelectedUsers(val.assigness); // set assignees
    // setCurrentDateTime(dayjs(new Date(val.scheduleCompletionTime)));
    // setScheduleCompletionTime(val.scheduleCompletionTime);
    setPriority(val.priority); // set priority
    // val.priority > 0 && setShowPriorityBar(true);
  };

  const selectCompTime = (event) => {
    setCompTime(event.target.value);
  };
  const selectPriority = (event) => {
    setPriority(event.target.value);
  };
  const selectActivity = (event) => {
    setActivity(event.target.value);
  };

  const shouldDisable = (complTime, time) => {
    const now = new Date();
    const [hours, minutes] = time.split(":").map(Number);
    complTime.setHours(hours, minutes, 0, 0);
    if (now - complTime > 0) {
      return true;
    }
    return false;
  };

  const changeRadioGroupValue = (e) => {
    setRadioGroupValue(e.target.value);
  };

  const submitSignOff = () => {
    let isValidForm = true;
    if (signOffComment === "") {
      toast.error("Please Provide a Sign Off Comment");
      isValidForm = false;
    }
    if (radioGroupValue === "new task") {
      if (activity === "") {
        toast.error("Please Select a Activity");
        isValidForm = false;
      }
      if (compTime === null) {
        toast.error("Please Select a Completion Time");
        isValidForm = false;
      }
      if (selectedUsers.length == 0) {
        toast.error("Please Select Atleast One User");
        isValidForm = false;
      }
      if (newTaskName.trim() === "") {
        toast.error("Please Provide a Task Name");
        isValidForm = false;
      }
      if (
        taskDescription.trim() === "" &&
        (!tempAudioChunk.current || tempAudioChunk.current.length === 0)
      ) {
        toast.error("Please Provide a Task Description");
        isValidForm = false;
      }
    }
    let tempPostPoneTill = completion;
    const [hours, minutes] = compTime.split(":").map(Number);
    tempPostPoneTill.setHours(hours, minutes, 0, 0);

    const now = new Date();
    if (now - tempPostPoneTill > 0) {
      toast.error("The Selected Time has Already Passed.");
      isValidForm = false;
    }
    if (isValidForm === false) {
      return;
    }

    let newTaskDto = {
      taskMasterId: activity,
      subTask: newTaskName,
      scheduleCompletionTime: tempPostPoneTill.toISOString(),
      taskDescription: taskDescription,
      priority: 0,
      assigness: selectedUsers.map((user) => user.userId),
    };

    let reqDto = {
      taskDetailId: data.taskId,
      comments: signOffComment,
      createNextTask: radioGroupValue === "new task" ? "Y" : "N",
    };

    if (radioGroupValue === "new task") {
      reqDto = { ...reqDto, nextTaskDto: newTaskDto };
    }

    //  reqDto = {
    //   taskDetailId: data.taskDetailId,
    //   progress: data.progress,
    //   comments: signOffComment,
    //   orgId: loginUserDetail.orgId,
    //   previousAssigness,
    //   currentAssigness: previousAssigness,
    //   documents: data.documents,
    //   createNextTask: "Y",
    //   nextTaskDto: {
    //     taskMasterId: parseInt(activity),
    //     subTask: newTaskName,
    //     scheduleCompletionTime: tempPostPoneTill,
    //     taskDescription: taskDescription,
    //     priority: priority,
    //     assigness: selectedUsers.map((item) => item.userId),
    //   },
    // };

    console.log("req Dto =", reqDto);

    // return;
    setLoading(true);
    DashboardServices.signOffTask(loginUserDetail.userId, reqDto).then(
      (response) => {
        if (response.data.returnValue === "1") {
          toast.success(response.data.message);
          if (removeFromStore) {
            removeFromStore();
          }
          refreshData();
        } else {
          toast.error(response.data.message);
        }
        setLoading(false);
      }
    );
  };

  const getSingleBlob = () => {
    const promises = [];

    for (const blob of tempAudioChunk.current) {
      const promise = blob.arrayBuffer();
      promises.push(promise);
    }

    Promise.all(promises)
      .then((arrayBuffers) => {
        const concatenatedArrayBuffer = arrayBuffers.reduce((acc, buffer) => {
          const totalLength = acc.byteLength + buffer.byteLength;
          const combined = new Uint8Array(totalLength);
          combined.set(new Uint8Array(acc), 0);
          combined.set(new Uint8Array(buffer), acc.byteLength);
          return combined.buffer;
        }, new ArrayBuffer(0));

        const combinedBlob = new Blob([concatenatedArrayBuffer], {
          type: "audio/wav",
        });
        tempAudioChunk.current = [combinedBlob];
        console.log(tempAudioChunk.current);
      })
      .catch((error) => {
        console.error("Error occurred while combining Blobs:", error);
      });
  };

  const handleShowRecAudioClose = () => {
    console.log(tempAudioChunk.current);
    if (tempAudioChunk.current && tempAudioChunk.current.length > 0) {
      getSingleBlob();
    }
    setShowRecAudio(false);
  };

  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">Sign Off Confirmation</div>

          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <RadioGroup
                  className="formRadioGroup"
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={radioGroupValue}
                  onChange={changeRadioGroupValue}
                >
                  <FormControlLabel
                    className="formRadioField"
                    value="new task"
                    control={<Radio />}
                    label="Create Next Task"
                  />
                  <FormControlLabel
                    className="formRadioField"
                    value="close task"
                    control={<Radio />}
                    label="There is no next task"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  className="modelTextFild"
                  label="Comments"
                  variant="outlined"
                  multiline
                  rows={3}
                  maxRows={7}
                  value={signOffComment}
                  onChange={(e) => {
                    setSignOffComment(e.target.value);
                  }}
                  autoComplete="off"
                />
              </FormControl>
            </div>

            {radioGroupValue === "new task" && (
              <>
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="setActivity">Activity*</InputLabel>
                    <Select
                      className="modelSelectFild"
                      label="Activity"
                      variant="outlined"
                      labelId="setActivity"
                      id="activity-select"
                      value={activity}
                      onChange={selectActivity}
                    >
                      <MenuItem value={""}>{"--Select Activity--"}</MenuItem>
                      {activityList &&
                        activityList.length > 0 &&
                        activityList.map((item) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                      {/* <MenuItem value={"Appointment"}>Appointment</MenuItem>
                      <MenuItem value={"Board Meeting"}>Board Meeting</MenuItem>
                      <MenuItem value={"Budget Requisition"}>
                        Budget Requisition
                      </MenuItem> */}
                    </Select>
                  </FormControl>
                </div>

                {suggestedTaskList && suggestedTaskList.length > 0 && (
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="setActivity">
                        Suggested Activity
                      </InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="setActivity"
                        id="activity-select"
                        value={selectedSuggestionObj}
                        label="Activity"
                        onChange={handleChangeSelectSuggestion}
                      >
                        <MenuItem value={""}>{"--Select Activity--"}</MenuItem>
                        {suggestedTaskList.length > 0 &&
                          suggestedTaskList.map((value) => {
                            return (
                              <MenuItem
                                value={value}
                                key={JSON.stringify(value)}
                              >
                                {`${value.activityName} || ${
                                  value.subTask
                                } || ${DateUtils.formatddMMMYYHM(
                                  value.scheduleCompletionTime
                                )} || ${value.assigneeNames}`}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                )}
                <div className="formElementGroup">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel
                        id="setCompletionDate"
                        className="setCompletionDate"
                      >
                        Completion Date
                      </InputLabel>
                      <ReactDatePicker
                        showIcon
                        labelId="setCompletionDate"
                        className="formDatePicker"
                        selected={completion}
                        minDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => {
                          console.log("new date = ", date);
                          setCompletion(date);
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="compTime">Completion Time</InputLabel>
                      <Select
                        className="modelSelectFild"
                        variant="outlined"
                        labelId="compTime"
                        id="compTime-select"
                        value={compTime}
                        label="Completion Time"
                        onChange={selectCompTime}
                      >
                        {timeSlots &&
                          timeSlots.map((time) => (
                            <MenuItem
                              value={time}
                              disabled={shouldDisable(completion, time)}
                            >
                              {time}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectPriority">Priority</InputLabel>
                      <Select
                        className="modelSelectFild"
                        variant="outlined"
                        labelId="selectPriority"
                        id="priority-select"
                        value={priority}
                        label="Priority"
                        onChange={selectPriority}
                      >
                        <MenuItem value={"0"}>Routine</MenuItem>
                        <MenuItem value={"1"}> Important </MenuItem>
                        <MenuItem value={"2"}> Critical </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      required
                      className="modelTextFild"
                      label="Task Name"
                      variant="outlined"
                      multiline
                      value={newTaskName}
                      onChange={(e) => {
                        setNewTaskName(e.target.value);
                      }}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <Autocomplete
                      multiple
                      id="adduser-select"
                      disableClearable
                      className="modelformAutocompleteField"
                      variant="outlined"
                      value={selectedUsers}
                      options={userList}
                      getOptionLabel={(option) => option.userName}
                      getOptionSelected={(option) => {
                        console.log("option is ", option);
                        return option.userId;
                      }}
                      onChange={(e, newValue) => {
                        console.log("new value = ", newValue);
                        setSelectedUsers(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField"
                          placeholder="Assign User*"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      required
                      className="modelTextFild"
                      label="Description"
                      variant="outlined"
                      multiline
                      rows={4}
                      maxRows={7}
                      value={taskDescription}
                      onChange={(e) => setTaskDescription(e.target.value)}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>
              </>
            )}
          </div>

          <div className="modActionBtnGrp">
            <Button className="dfultPrimaryBtn" onClick={submitSignOff}>
              Submit
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>

        {showRecAudio && (
          <>
            <Modal
              open={showRecAudio}
              handleClose={() => handleShowRecAudioClose()}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="coustomModel"
            >
              <Box
                className="createEvent responsiveModelAdmin"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxHeight: "70vh",
                  bgcolor: "background.paper",
                  width: "400px",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <ConfirmProvider>
                  <RecordAudio
                    closeDialog={() => handleShowRecAudioClose()}
                    tempAudioChunk={tempAudioChunk}
                    recordingLength={recordingLength}
                    setRecordingLength={setRecordingLength}
                    recordingLink={recordingLink}
                    setRecordingLink={setRecordingLink}
                  />
                </ConfirmProvider>
              </Box>
            </Modal>
          </>
        )}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
      </Box>
    </>
  );
};

export default SignOff;
